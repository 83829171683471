import React from 'react'
import Well from 'react-bootstrap/lib/Well'
import './errors.css'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'

const Logout = ({ state }) => {
  document.title = `Logout | ${process.env.REACT_APP_PAGE_TITLE}`
  const { isAuthenticated } = useAuth0()
  console.log(isAuthenticated)
  return (
    <div className='errorView'>
      <Well>
        <div className='errorView-container'>
          <h2>You've been logged out.</h2>
          {state && state.error &&
            <div>
              <h3>{state.error}</h3>
              <p>{state.error_description}</p>
            </div>}
          <p>
            You can close this window or, if this was a mistake, you can <a href='/login'>log back in</a>.
          </p>
        </div>
      </Well>
    </div>
  )
}

Logout.propTypes = {
  state: PropTypes.object
}

export default Logout
