import Alert from 'react-bootstrap/lib/Alert'
import Button from 'react-bootstrap/lib/Button'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import { Link } from 'react-router-dom'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Modal from 'react-bootstrap/lib/Modal'
import QRCodeSVG from 'qrcode.react'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Table from 'react-bootstrap/lib/Table'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'

import { askOuterSpatialAQuestion } from 'components/helpScout'
import { CenteredLoader } from '../../../../components/centeredLoader'
import { Mutations, Queries } from '../CampaignsOperations'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import HelpDropdown from 'components/helpDropdown'

import './campaigns.css'

const Campaigns = ({ history, isOrganizationAdmin, isSuperAdmin, match }) => {
  const currentUser = useCurrentUser()
  const linkStyle = {
    color: '#41A7D0',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
  const organization = useCurrentOrganization()
  const { data, error, loading } = useQuery(Queries.GetCampaignsByOrganizationId, {
    variables: {
      organizationId: organization.id
    }
  })
  const [deleteCampaign, { loading: deleteIsLoading }] = useMutation(Mutations.DeleteCampaignById, {
    onCompleted: () => {
      toast.success('Campaign deleted.')
    },
    onError: () => {
      toast.error('The campaign could not be deleted.')
    },
    update: (cache, response) => {
      cache.modify({
        fields: {
          campaigns (existingCampaigns = []) {
            return existingCampaigns.filter((t) => {
              return t.__ref !== `campaigns:${response.data.delete_campaigns.returning[0].id}`
            })
          }
        }
      })
    }
  })
  const [show, setShow] = React.useState(false)
  const [selectedCampaign, setSelectedCampaign] = React.useState('')
  const handleCloseModal = () => setShow(false)
  const handleDownloadQrCode = (imageType) => {
    const downloadLink = document.createElement('a')
    const fileName = selectedCampaign.utm_campaign.replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '-').toLowerCase()
    const svg = document.querySelector('.modal-body > svg')
    const uriData = `data:image/svg+xml;base64,${window.btoa(new window.XMLSerializer().serializeToString(svg))}`

    downloadLink.download = `${fileName}-qr-code`

    if (imageType === 'svg') {
      downloadLink.href = uriData
      downloadLink.click()
      downloadLink.remove()
    } else {
      const img = new window.Image()
      img.src = uriData
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.height = 256
        canvas.width = 256
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, 256, 256)
        downloadLink.href = canvas.toDataURL(`image/${imageType}`, 1.0)
        downloadLink.append(canvas)
        downloadLink.click()
        downloadLink.remove()
      }
    }
  }
  const handleNewCampaign = () => {
    history.push(`${match.path}/campaigns/new`)
  }
  const handleShowModal = () => setShow(true)
  const handleSignUpClick = () => {
    if (organization.license_tier === 'free') {
      history.push('../organization/billing')
    } else {
      askOuterSpatialAQuestion(
        'Publish Organization Into a Community',
        null,
        currentUser
      )
    }
  }
  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '72', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/72-campaigns' }).click()
    }
  }
  const onDelete = (campaign) => {
    const conf = window.confirm('Are you sure you want to permanently delete this campaign?')
    const id = campaign.id

    if (conf) {
      deleteCampaign({
        variables: { id }
      })
    }
  }
  const onEdit = (campaign) => {
    history.push(`${match.path}/campaigns/${campaign.id}`)
  }
  const showQrCode = (campaign) => {
    setSelectedCampaign(campaign)
    handleShowModal()
  }

  useEffect(() => {
    document.title = `Campaigns | Organization | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  return (
    <div className='campaigns'>
      <div className='campaigns-titleBar'>
        <div className='campaigns-actions'>
          <HelpDropdown
            handleViewHelpSelect={handleViewHelpClick}
          />
          {organization && organization.communities && organization.communities.length > 0 &&
            <span>
              &nbsp;
              <Button bsStyle='primary' onClick={handleNewCampaign}>Create Campaign</Button>
            </span>}
        </div>
      </div>
      <Modal onHide={handleCloseModal} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          {selectedCampaign && selectedCampaign.deep_link_url && (
            <QRCodeSVG
              renderAs='svg'
              size={256}
              value={selectedCampaign.deep_link_url}
            />)}
        </Modal.Body>
        <Modal.Footer>
          {selectedCampaign && selectedCampaign.deep_link_url && (
            <>
              <DropdownButton title='Download' id='download' bsStyle='primary'>
                <MenuItem eventKey='svg' onClick={() => handleDownloadQrCode('svg')}>SVG</MenuItem>
                <MenuItem eventKey='png' onClick={() => handleDownloadQrCode('png')}>PNG</MenuItem>
                <MenuItem eventKey='jpeg' onClick={() => handleDownloadQrCode('jpeg')}>JPEG</MenuItem>
              </DropdownButton>
              &nbsp;
            </>)}
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {organization && organization.license_tier === 'free' &&
        <Panel>
          <Panel.Body>
            <div className='empty-message text-center'>
              <h3>Campaigns are not available because your organization is on the "Free" plan.</h3>
              {(isOrganizationAdmin || isSuperAdmin) &&
                <h3><span onClick={handleSignUpClick} style={linkStyle}>Sign up for a license</span> to access Campaigns now.</h3>}
            </div>
          </Panel.Body>
        </Panel>}
      {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length === 0 &&
        <Panel>
          <Panel.Body>
            <div className='empty-message text-center'>
              <h3>Campaigns are not available because your organization has not been added to a community.</h3>
              <h3><span onClick={handleSignUpClick} style={linkStyle}>Reach out to customer success</span> to publish your organization into its first community.</h3>
            </div>
          </Panel.Body>
        </Panel>}
      {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length > 0 &&
        <div>
          <div className='campaigns-list'>
            {(deleteIsLoading || loading) &&
              <Panel>
                <Panel.Body>
                  <CenteredLoader overlay />
                </Panel.Body>
              </Panel>}
            {!deleteIsLoading && !loading && error && error.message &&
              <Panel>
                <Panel.Body>
                  <Alert bsStyle='danger'>{error.message}</Alert>
                </Panel.Body>
              </Panel>}
            {!deleteIsLoading && !loading && (!data || !data.campaigns || !data.campaigns.length) &&
              <Panel>
                <Panel.Body>
                  <h3>No campaigns to display.</h3>
                </Panel.Body>
              </Panel>}
            {!deleteIsLoading && !loading && (data && data.campaigns && data.campaigns.length > 0) &&
              <Panel>
                <Table striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Entity Type</th>
                      <th>Deep Link</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {(deleteIsLoading || loading) &&
                      <CenteredLoader overlay />}
                    {data && data.campaigns && data.campaigns.length > 0 &&
                      data.campaigns.map((campaign, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Link to={`${match.path}/campaigns/${campaign.id}`}>{campaign.utm_campaign}</Link>
                            </td>
                            <td>
                              {(campaign.entity_type === 'FeaturedContent') ? 'Article' : campaign.entity_type}
                            </td>
                            <td>
                              {campaign.deep_link_url}
                            </td>
                            <td className='campaigns--actions'>
                              <DropdownButton title='Actions' id='actions' bsSize='small' pullRight>
                                <MenuItem eventKey='edit' onClick={() => onEdit(campaign)}>Edit</MenuItem>
                                <MenuItem eventKey='delete' onClick={() => onDelete(campaign)}>Delete</MenuItem>
                                <MenuItem eventKey='qr' onClick={() => showQrCode(campaign)}>View QR Code</MenuItem>
                              </DropdownButton>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </Panel>}
          </div>
        </div>}
    </div>
  )
}

Campaigns.propTypes = {
  history: PropTypes.object,
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  match: PropTypes.object
}

export default Campaigns
