import PropTypes from 'prop-types'
import React from 'react'
import ReactSelect from 'react-select'

const Select = (props) => {
  const { onChange, clearable, id, options, value } = props
  const change = (value) => {
    const setValue = value && value.value !== undefined ? value.value : value
    if (typeof onChange === 'function') {
      onChange(setValue)
    }
  }

  const isClearable = clearable || false

  return (
    <div className='select-wrap'>
      <ReactSelect
        clearable={isClearable}
        name={id}
        onChange={change}
        options={options}
        value={options.filter((option) => option.value === value)}
        contentEditable={props.contentEditable}
      />
    </div>
  )
}

Select.propTypes = {
  // props
  clearable: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  contentEditable: PropTypes.bool
}

export default Select
