import { gql } from '@apollo/client'

export const Fragments = {
  AreaDetails: gql`
    fragment AreaDetails on areas {
      id
      name
      size {
        acres
      }
      tags {
        id
        key
        tag_descriptor {          
          category {
            id
            name
          }
          key
          id
          name
        }
        value
      }
      visibility
    }
  `,
  FeatureOutingDetails: gql`
    fragment FeatureOutingDetails on outings {
      difficulty
      id
      name
      parent_areas: outing_areas {
        area {
          id
          name
        }
      }
      route {
        length_meters
      }
      route_type
      tags {
        id
        value
        key
        feature_type
        feature_id
        tag_descriptor {          
          category {
            id
            name
          }
          key
          id
          name
        }
      }
      visibility
    }
  `,
  PointOfInterestDetails: gql`
    fragment PointOfInterestDetails on points_of_interest {
      area {
        name
      }
      id
      name
      point_type
      tags {
        id
        value
        key
        feature_type
        feature_id
        tag_descriptor {          
          category {
            id
            name
          }
          key
          id
          name
        }
      }
      visibility
    }
  `,
  TrailDetails: gql`
    fragment TrailDetails on trails {
      area {
        name
      }
      id
      length_meters: cached_length
      name
      tags {
        id
        value
        key
        feature_type
        feature_id
        tag_descriptor {          
          category {
            id
            name
          }
          key
          id
          name
        }
      }
      trail_only_tags {
        id
        value
        key
        feature_type
        feature_id
        tag_descriptor {          
          category {
            id
            name
          }
          key
          id
          name
        }
      } 
      visibility
    }
  `
}

export const Queries = {
  GetFeatureStatusOptions: gql`
    query GetFeatureStatusOptions($feature_type: String!) {
      tag_categories(
        where: {name: {_eq: "Status"}}
      ) {
        name
        id
        description
        tag_descriptors(
          where: {feature_type: {_eq: $feature_type}}
        ) {
          id
          name
          key
        }
      }
    }
  `,
  GetNumberOfAreasByOrganization: gql`
    query GetNumberOfAreasByOrganization($organizationId: Int!) {
      aggregate: areas_aggregate(
        where: { stewardships: { organization_id: { _eq: $organizationId } } }
      ) {
        aggregate {
          totalCount: count
        }
      }
    }
  `,
  GetNumberOfPointsOfInterestByOrganization: gql`
    query GetNumberOfPointsOfInterestByOrganization($organizationId: Int!) {
      aggregate: points_of_interest_aggregate(
        where: { stewardships: { organization_id: { _eq: $organizationId } } }
      ) {
        aggregate {
          totalCount: count
        }
      }
    }
  `,
  GetNumberOfTrailsByOrganization: gql`
    query GetNumberOfTrailsByOrganization($organizationId: Int!) {
      aggregate: trails_aggregate(
        where: { stewardships: { organization_id: { _eq: $organizationId } } }
      ) {
        aggregate {
          totalCount: count
        }
      }
    }
  `,
  GetAreasByOrganization: gql`
    query GetAreasByOrganization(
      $organizationId: Int!,
      $orderBy: [areas_order_by!] = [{name: asc}, {id: asc}],
      $limit: Int,
      $offset: Int
    ) {
      areas(
        where: {
          stewardships: { organization_id: { _eq: $organizationId } }
        }
        order_by: $orderBy,
        limit: $limit,
        offset: $offset
      ) {
        ...AreaDetails
        stewardships(
          where: {
            organization_id: { _eq: $organizationId }
          }
        ) {
          role
        }
      }
    }
    ${Fragments.AreaDetails}
  `,
  GetFeaturesFromParentAreaId: gql`
    query GetFeaturesFromParentAreaId($areaId: Int!, $organizationId: Int!) {
      outings(
        order_by: [{
          name: asc
        },{
          id: asc
        }],
        where: {
          outing_areas: {
            area: {
              id: {
                _eq: $areaId
              }
            }
          },
          stewardships: {
            organization_id: {
              _eq: $organizationId
            }
          }
        }
      ) {
        ...FeatureOutingDetails
      }
      points_of_interest(
        order_by: [{
          name: asc
        },{
          id: asc
        }],
        where: {
          area_id: {
            _eq: $areaId
          },
          stewardships: {
            organization_id: {
              _eq: $organizationId
            }
          }
        }
      ) {
        ...PointOfInterestDetails
      }
      trails(
        order_by: [{
          name: asc
        },{
          id: asc
        }],
        where: {
          area_id: {
            _eq: $areaId
          },
          stewardships: {
            organization_id: {
              _eq: $organizationId
            }
          }
        }
      ) {
        ...TrailDetails
      }
    }
    ${Fragments.FeatureOutingDetails}
    ${Fragments.PointOfInterestDetails}
    ${Fragments.TrailDetails}
  `,
  GetPointsOfInterestByOrganization: gql`
    query GetPointsOfInterestByOrganization(
      $organizationId: Int!,
      $orderBy: [points_of_interest_order_by!] = [{name: asc}, {id: asc}],
      $limit: Int,
      $offset: Int
    ) {
      points_of_interest(
        where: {stewardships: {organization: {id: {_eq: $organizationId}}}},
        order_by: $orderBy,
        limit: $limit,
        offset: $offset
      ) {
        ...PointOfInterestDetails
        stewardships(
          where: {
            organization_id: { _eq: $organizationId }
          }
        ) {
          role
        }
      }
    }
    ${Fragments.PointOfInterestDetails}
  `,
  GetTrailsByOrganization: gql`
    query GetTrailsByOrganization(
      $organizationId: Int!,
      $orderBy: [trails_order_by!] = [{name: asc}, {id: asc}],
      $limit: Int,
      $offset: Int
    ) {
      trails(
        where: {
          stewardships: {organization_id: {_eq: $organizationId } }
        },
        order_by: $orderBy,
        limit: $limit,
        offset: $offset
      ) {
        ...TrailDetails
        stewardships(
          where: {
            organization_id: { _eq: $organizationId }
          }
        ) {
          role
        }
      }
    }
    ${Fragments.TrailDetails}
  `
}

export const Mutations = {
  DeleteTrail: gql`
    mutation DeleteTrail($trailId: Int!) {
      delete_trail_segment_stewardships(where: {trail_segment: {trail_sections: {trail_id: {_eq: $trailId}}}}) {
        affected_rows
      }
      delete_trail_segments(where: {trail_sections: {trail_id: {_eq: $trailId}}}) {
        affected_rows
      }
      delete_stewardships(where: {stewardable_id: {_eq: $trailId}, stewardable_type: {_eq: "Trail"}}) {
        affected_rows
      }
      delete_trails(where: {id: {_eq: $trailId}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  UpdateVisibilityForArea: gql`
    mutation UpdateVisibilityForArea(
      $areaId: Int!,
      $visibility: String!
    ) {
      update_areas(
        where: {id: {_eq: $areaId}},
        _set: {
          visibility: $visibility,
          updated_at: "now()"

        }
      ) {
        affected_rows
        returning {
          id
          visibility
        }
      }
    }
  `,
  UpdateVisibilityForTrail: gql`
    mutation UpdateVisibilityForTrail(
      $trailId: Int!,
      $visibility: String!
    ) {
      update_trails(
        where: {id: {_eq: $trailId}},
        _set: {
          visibility: $visibility,
          updated_at: "now()"
        }
      ) {
        affected_rows
        returning {
          id
          visibility
        }
      }
    }
  `,
  UpdateVisibilityForPOI: gql`
    mutation UpdateVisibilityForPOI(
      $poiId: Int!,
      $visibility: String!
    ) {
      update_points_of_interest(
        where: {id: {_eq: $poiId}},
        _set: {
          visibility: $visibility,
          updated_at: "now()"
        }
      ) {
        affected_rows
        returning {
          id
          visibility
        }
      }
    }
  `
}
