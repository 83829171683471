import { Form, Formik } from 'formik'
import { Mutations, Queries } from '../ChallengesOperations'
import { useMutation } from '@apollo/client'
import Button from 'react-bootstrap/lib/Button'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import Table from 'react-bootstrap/lib/Table'
import toast from 'react-hot-toast'

import { buildImageUploadUrl } from 'services/Images'
import { CenteredLoader } from 'components/centeredLoader'
import ChallengeTaskModal from './ChallengeTaskModal'
import debugMessage from 'services/Debug'
import FormikGroup from 'components/fieldGroup/FormikGroup'

import './challenge.css'

const getImageUrl = (image) => {
  let imageUrl = null

  if (image?.id && image?.uploaded_file) {
    imageUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'small')
  }

  return imageUrl
}

const ChallengeTasks = (props) => {
  const {
    challenge
  } = props
  const formRef = useRef()
  let sortedTasks = []
  const tasksToCompleteOptions = [{ label: 'All', value: 'all' }]

  const [editTask, setEditTask] = useState()
  const [deleteTask] = useMutation(Mutations.DeleteChallengeTask, { refetchQueries: [Queries.GetChallengeById] })
  const [updateChallengeTasksToComplete, { loading: updateChallengeTasksToCompleteIsLoading }] = useMutation(
    Mutations.UpdateChallengeTasksToComplete
  )

  const handleClose = () => {
    setEditTask(null)
  }
  const handleDelete = (task) => {
    if (task.type === 'Questionnaire') {
      window.alert('Survey challenge tasks cannot be edited in Manager. Please contact customer success.')
    } else {
      const conf = window.confirm('Are you sure you want to delete this task?\n\nIf you delete the task, it will be gone permanently and cannot be recovered.')

      if (conf) {
        deleteTask({
          variables: {
            id: task.id
          }
        })
          .then(() => {
            toast.success('Task deleted.')
          }, error => {
            debugMessage(error)
            toast.error('The task could not be deleted. Please try again.')
          })
      }
    }
  }
  const handleEdit = (task) => {
    if (task.type === 'Questionnaire') {
      window.alert('Survey challenge tasks cannot be edited in Manager. Please contact customer success.')
    } else {
      setEditTask(task.fullTask)
    }
  }
  const handleNewTaskClick = () => {
    if (challenge && challenge.tasks && challenge.tasks[0] && challenge.tasks[0].task_type === 'Questionnaire') {
      window.alert('Survey challenge tasks cannot be created in Manager. Please contact customer success.')
    } else {
      setEditTask({ challenge_id: challenge.id })
    }
  }
  const processSubmitForm = (challengeTasksToComplete) => {
    updateChallengeTasksToComplete({
      variables: {
        id: challenge.id,
        tasksToComplete: challengeTasksToComplete
      }
    })
      .then((response) => {
        toast.success('Number of tasks to complete updated.')
      })
      .catch(e => {
        toast.success('Number of tasks to complete could not be updated. Please try again.')
        debugMessage(e)
      })
  }

  if (challenge && challenge.tasks && challenge.tasks.length) {
    sortedTasks = []

    challenge.tasks.forEach(task => {
      let subtitle = null
      let title = null

      if (task.task_type === 'Questionnaire') {
        subtitle = task.subtitle
        title = task.title
      } else {
        let location

        if (task.areas[0]) {
          location = task.areas[0].area
          subtitle = 'Area'
        } else if (task.outings[0]) {
          location = task.outings[0].outing

          if (task.outings[0].outing.parent_areas.length) {
            subtitle = `Outing in ${task.outings[0].outing.parent_areas[0].area.name}`
          } else {
            subtitle = 'Outing'
          }
        } else if (task.points_of_interest[0]) {
          location = task.points_of_interest[0].point_of_interest

          if (task.points_of_interest[0].point_of_interest.parent_area) {
            // TODO: point_type

            subtitle = `${task.points_of_interest[0].point_of_interest.point_of_interest_type.name} in ${task.points_of_interest[0].point_of_interest.parent_area.name}`
          } else {
            subtitle = task.points_of_interest[0].point_of_interest.point_of_interest_type.name
          }
        } else if (task.trails[0]) {
          location = task.trails[0].trail

          if (task.trails[0].trail.parent_area) {
            subtitle = `Trail in ${task.trails[0].trail.parent_area.name}`
          } else {
            subtitle = 'Trail'
          }
        }

        if (location) {
          title = location.name
        } else {
          title = 'Unnamed'
        }
      }

      sortedTasks.push({
        fullTask: task,
        id: task.id,
        subtitle: subtitle,
        title: title,
        type: task.task_type
      })
    })

    if (sortedTasks[0].type === 'Questionnaire') {
      sortedTasks.sort((a, b) => a.id - b.id)
    } else {
      sortedTasks.sort((a, b) => {
        const locationNameA = a.title
        const locationNameB = b.title

        if (locationNameA < locationNameB) {
          return -1
        }

        if (locationNameA > locationNameB) {
          return 1
        }

        return 0
      })
    }

    for (var i = 0; i < sortedTasks.length; i++) {
      tasksToCompleteOptions.push({ label: `${i + 1}`, value: `${i + 1}` })
    }
  }

  return (
    <div className='challengeTasks-wrap'>
      <Panel>
        <Panel.Heading>
          Tasks
          <div style={{ position: 'absolute', right: '15px', top: '6px' }}>
            <Button
              bsStyle='primary'
              className='shadow'
              disabled={!challenge.id}
              onClick={handleNewTaskClick}
            >
              New Task
            </Button>
          </div>
        </Panel.Heading>
        <Panel.Body>
          {!challenge.id && (
            <p className='empty-text'>You must save the challenge before adding tasks.</p>
          )}
          {challenge.id > 0 && sortedTasks.length === 0 && (
            <p className='empty-text'>No challenge tasks available. Click "New Task" to add the first task.</p>)}
          {challenge.id > 0 && sortedTasks.length > 0 && (
            <div>
              <div className='challengeTasks-form'>
                {updateChallengeTasksToCompleteIsLoading && (
                  <CenteredLoader overlay />)}
                <Formik
                  initialValues={{ tasks_to_complete: challenge.tasks_to_complete }}
                  innerRef={formRef}
                >
                  {(formikProps) => {
                    return (
                      <>
                        {formikProps.isSubmitting &&
                          <CenteredLoader overlay />}
                        <Form noValidate>
                          <FormikGroup
                            help='The number of tasks visitors must check off to complete the challenge.'
                            label='Number of Tasks to Complete'
                            name='tasks_to_complete'
                            onChange={(value) => processSubmitForm(value)}
                            options={tasksToCompleteOptions}
                            type='select'
                            value={formikProps.values.tasks_to_complete}
                          />
                        </Form>
                      </>
                    )
                  }}
                </Formik>
              </div>
              <div className='challengeTasks-table'>
                <Table striped>
                  <thead>
                    <tr>
                      <th className='challengeTasks-table-title'>Title</th>
                      <th>Type</th>
                      <th>Badge</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {sortedTasks.map((task) => {
                      return (
                        <tr
                          className='challengeTasksTable-tableRow'
                          key={task.id}
                        >
                          <td>
                            {task.title}
                            {task.subtitle && (
                              <span
                                className='subtitle'
                              >
                                <br />{task.subtitle}
                              </span>
                            )}
                          </td>
                          <td>{task.type === 'Questionnaire' ? 'Survey' : 'Check-in'}</td>
                          <td
                            className='challengeTasks-table-badgeImage'
                          >
                            {task.fullTask.badge_image && (
                              <img
                                className='challengeTasks-table-badgeImage'
                                src={getImageUrl(task.fullTask.badge_image)}
                              />
                            )}
                          </td>
                          <td>
                            <DropdownButton
                              id='actions'
                              pullRight
                              title='Actions'
                            >
                              <MenuItem onClick={() => handleEdit(task)}>Edit</MenuItem>
                              <MenuItem onClick={() => handleDelete(task)}>Delete</MenuItem>
                            </DropdownButton>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>)}
        </Panel.Body>
      </Panel>
      {editTask && (
        <ChallengeTaskModal
          onClose={handleClose}
          show={editTask !== null}
          task={editTask}
        />)}
    </div>
  )
}

ChallengeTasks.propTypes = {
  challenge: PropTypes.object.isRequired
}

export default ChallengeTasks
