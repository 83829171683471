import React from 'react'
import PropTypes from 'prop-types'

import './pageLayout.css'

const PageLayout = ({ children }) => {
  return (
    <div className='pageLayout'>
      {children}
    </div>
  )
}

PageLayout.propTypes = {
  children: PropTypes.any
}

export default PageLayout
