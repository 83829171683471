import React, { useState } from 'react'
import Alert from 'react-bootstrap/lib/Alert'
import Button from 'react-bootstrap/lib/Button'
import { useQuery, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import ContentBundlesTable from './table/ContentBundlesTable'
import { CenteredLoader } from '../../../components/centeredLoader'
import { Queries, Mutations } from '../ContentBundlesOperations'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useHistory, useRouteMatch } from 'react-router'
import debugMessage from 'services/Debug'

import './list.scss'

const ContentBundlesList = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const organizationId = organization.id
  const [contentBundles, setContentBundles] = useState([])

  const naturallySortContentBundlesByName = (contentBundles) => {
    setContentBundles([...contentBundles].sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
  }

  const { loading: loadingContentBundles, error: errorLoadingContentBundles } = useQuery(
    Queries.GetContentBundles, {
      displayName: 'getContentBundles',
      skip: (organizationId === null || organizationId === -1),
      variables: { organizationId },
      onCompleted: (data) => naturallySortContentBundlesByName(data.content_bundles)
    }
  )

  const [deleteArticle] = useMutation(Mutations.DeleteContentBundle, {
    onCompleted () {
      toast.success('Deleted Article successfully.')
    },
    onError (error) {
      debugMessage(error)
    },
    update (cache, response) {
      // If there are no affected_rows (Hasura permission check filtered out all rows before mutation), no-op
      if (response.data.delete_content_bundles.affected_rows) {
        const deletedContentBundleId = response.data.delete_content_bundles.returning[0].id
        cache.modify({
          fields: {
            content_bundles (existingContentBundles = []) {
              return existingContentBundles.filter((t) => {
                return t.__ref !== `content_bundles:${deletedContentBundleId}`
              })
            }
          }
        })
        naturallySortContentBundlesByName(
          contentBundles.filter((contentBundle) => {
            return contentBundle.id !== deletedContentBundleId
          })
        )
      }
    }
  })

  const [updateVisibility] = useMutation(
    Mutations.UpdateVisibilityForContentBundle, {
      onCompleted (data) {
        toast.success('Article visibility updated.')
        // If there are no affected_rows (Hasura permission check filtered out all rows before mutation), no-op
        if (data.update_content_bundles.affected_rows) {
          const updatedContentBundle = data.update_content_bundles.returning[0]
          const updatedContentBundles = contentBundles.map(cb => {
            if (cb.id === updatedContentBundle.id) {
              return { ...cb, visibility: updatedContentBundle.visibility }
            } else {
              return cb
            }
          })
          setContentBundles(updatedContentBundles)
        }
      },
      onError (updateError) {
        toast.error(updateError)
      }
    }
  )

  const handleArchived = (contentBundle) => {
    if (window.confirm('Are you sure you want to archive the Article?')) {
      updateVisibility({
        variables: {
          contentBundleId: contentBundle.id,
          visibility: 'Archived'
        }
      })
    }
  }

  const handleDelete = (contentBundleId) => {
    const confirmationMessage = 'Are you sure you want to delete the Article rather than archive it?\n\nDeleting the Article means it will be gone permanently and you will no longer be able to view any analytics for it. Deleted Articles cannot be recovered. Archiving the Article will take it out of all visitor-facing contexts, but will preserve analytics and allow you to restore it if desired.'
    const conf = window.confirm(confirmationMessage)
    if (conf) {
      deleteArticle({ variables: { contentBundleId: contentBundleId } })
    }
  }

  const handlePublished = (contentBundle) => {
    if (window.confirm('Are you sure you want to publish this Article?')) {
      updateVisibility({
        variables: {
          contentBundleId: contentBundle.id,
          visibility: 'Published'
        }
      })
    }
  }

  const handleOpenArticle = (contentBundle) => {
    history.push(`${match.url}/${contentBundle.id}`)
  }

  const createNew = () => {
    history.push(`${match.url}/new`)
  }

  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '71', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/71-articles' }).click()
    }
  }

  document.title = `Articles | ${process.env.REACT_APP_PAGE_TITLE}`

  return (
    <div className='contentBundlesList-wrap'>
      <div className='contentBundlesActionBar-wrap'>
        <div className='contentBundlesActionBar-actions'>
          <Button
            bsStyle='primary'
            className='shadow'
            onClick={createNew}
          >
            New Article
          </Button>
          &nbsp;
          <Button
            bsStyle='primary'
            className='shadow'
            onClick={handleViewHelpClick}
          >
            View Help
          </Button>
        </div>
      </div>
      <div className='contentBundlesList-content'>
        <div className='contentBundlesList-table'>
          {loadingContentBundles &&
            <CenteredLoader />}
          {!loadingContentBundles &&
            <div>
              {!!errorLoadingContentBundles && errorLoadingContentBundles.message && <Alert bsStyle='danger'>{errorLoadingContentBundles.message}</Alert>}
              <ContentBundlesTable
                {...{ contentBundles, match }}
                onArchived={handleArchived}
                onDelete={handleDelete}
                onEdit={handleOpenArticle}
                onPublished={handlePublished}
              />
            </div>}
        </div>
      </div>
    </div>
  )
}

export default ContentBundlesList
