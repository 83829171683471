import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'formik'
import { featureByTypename } from '../../../../../components/entities'
import { HelpBlock } from 'react-bootstrap'
import AreaDestinationSet from 'components/areaDestinationSet'
import Cleave from 'cleave.js/dist/cleave-react'
// eslint-disable-next-line
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us'
import LocationSelect from 'components/locationSelect'

const classMatch = (classes, feature) => classes.indexOf(feature.class_name) > -1

const LocationContact = ({ areaDestinations, feature, handleAddAreaDestination, handleDestroyAreaDestination }) => {
  const featureTypeName = featureByTypename(feature.__typename).singular

  const handleAddAreaDestinationInner = ({ pointOfInterest }) => {
    handleAddAreaDestination(pointOfInterest)
  }

  const handleDestroyAreaDestinationInner = (areaDestination) => {
    handleDestroyAreaDestination(areaDestination)
  }

  if (!classMatch(['Area', 'Trail'], feature)) {
    // Shortcut this and do not output anything since Points of Interest don't have the following data
    return
  }

  return (
    <div className='featureSection-content featureSection--basicInfo'>
      <div className='formGroup--double'>
        <Field name='website'>
          {({ field, meta }) => (
            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
              <label className='control-label' htmlFor='website'>Web Page</label>
              <input className='form-control' id='website' type='text' {...field} />
              <HelpBlock>A link to a web page that contains more information about this {featureTypeName.toLowerCase()}.</HelpBlock>
              {meta.error && <div className='error-message'>{meta.error}</div>}
            </div>
          )}
        </Field>
        <Field name='phone_number'>
          {({ field, form, meta }) => (
            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
              <label className='control-label' htmlFor='phone_number'>Phone</label>
              <Cleave
                value={field.value}
                {...field}
                id='phone_number'
                className='form-control'
                onChange={event => {
                  form.setFieldValue(field.name, event.target?.value)
                }}
                options={{
                  delimiter: '-',
                  phone: true,
                  phoneRegionCode: 'US'
                }}
              />
              <HelpBlock>A phone number visitors can call to get more information about this {featureTypeName.toLowerCase()}.</HelpBlock>
              {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
            </div>
          )}
        </Field>
      </div>
      {classMatch(['Area'], feature) &&
        <>
          <Field name='address'>
            {({ field, form, meta }) => (
              <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                <label className='control-label' htmlFor='address'>Address</label>
                <LocationSelect
                  clearable
                  id='address'
                  name='address'
                  onChange={(value) => {
                    form.setFieldValue(field.name, value)
                  }}
                  value={field.value}
                />
                <HelpBlock>A routable address for this area. Used when routing visitors with their provider of choice.</HelpBlock>
                {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
              </div>
            )}
          </Field>
          <div style={{ marginBottom: '15px' }}>
            <label className='control-label'>
              Area Destinations
            </label>
            <AreaDestinationSet
              areaDestinations={areaDestinations}
              areaId={feature.id}
              onAdd={handleAddAreaDestinationInner}
              onRemove={handleDestroyAreaDestinationInner}
            />
            <HelpBlock>Points of interest within this area you want to direct visitors to when routing with their provider of choice.</HelpBlock>
          </div>
        </>}
    </div>
  )
}

LocationContact.propTypes = {
  areaDestinations: PropTypes.array,
  feature: PropTypes.object,
  handleAddAreaDestination: PropTypes.func,
  handleDestroyAreaDestination: PropTypes.func
}

export default LocationContact
