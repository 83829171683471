import React from 'react'
import { Formik, Form } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { gql, useMutation } from '@apollo/client'
import FormikGroup from 'components/fieldGroup/FormikGroup'
import Button from 'react-bootstrap/lib/Button'
import PropTypes from 'prop-types'
import Alert from 'react-bootstrap/lib/Alert'

const UpdateProfileMutation = gql`
  mutation UpdateProfile($first_name: String!, $last_name: String!, $user_id: Int!) {      
    update_member_profiles(
      where: {
        user_id: {
          _eq: $user_id
        }
      },
      _set: {
        first_name: $first_name,
        last_name: $last_name
      }
    ) {
      affected_rows
    }
  }
`

const UpdateProfileForm = ({ currentUser }) => {
  const [updateProfileMutation] = useMutation(UpdateProfileMutation)
  const formikProps = {
    enableReinitialize: false,
    initialValues: {
      first_name: '',
      last_name: ''
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(100, 'First name must be 100 characters or less.')
        .required('First name is required.'),
      last_name: Yup.string()
        .max(100, 'Last name must be 100 characters or less.')
        .required('Last name is required.')
    }),
    onSubmit: values => {
      saveForm(values)
    }
  }
  const saveForm = (values) => {
    updateProfileMutation({
      refetchQueries: [
        'GetTokenUser'
      ],
      variables: {
        ...values,
        user_id: currentUser.id
      }
    }).then(() => {
      toast.success('Your profile was updated!')
    }).catch(() => {
      toast.error('Your profile could not be updated. Please try again.')
    })
  }

  return (
    <div>
      <Alert bsStyle='info'>
        <p>Update your profile with your first and last name before continuing.</p>
      </Alert>
      <Formik {...formikProps}>
        {(formik) => (
          <Form>
            <FormikGroup
              type='text'
              id='first_name'
              label='First Name *'
              name='first_name'
            />
            <FormikGroup
              type='text'
              id='last_name'
              label='Last Name *'
              name='last_name'
            />
            <Button
              bsStyle='primary'
              disabled={!(formik.dirty && formik.isValid)}
              onClick={formik.handleSubmit}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </div>)
}

UpdateProfileForm.propTypes = {
  currentUser: PropTypes.object
}

export default UpdateProfileForm
