import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import EventView from './event/EventView'
import EventsList from './events/EventsList'

const Events = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route
        exact
        path={match.url}
        component={EventsList}
      />
      <Route
        path={`${match.url}/:eventId`}
        component={EventView}
      />
    </Switch>
  )
}

export default Events
