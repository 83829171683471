import { Well } from 'react-bootstrap'
import React from 'react'

export default function Login () {
  return (
    <div className='errorView'>
      <Well>
        <div className='errorView-container' style={{ textAlign: 'left' }}>
          <h2>Welcome to OuterSpatial Manager!</h2>
          <h3>For Visitors</h3>
          <p>
            Looking for our library of outdoor recreation information? Check out the free OuterSpatial <a href='https://www.outerspatial.com'>website</a> and app (<a href='https://apps.apple.com/us/app/outerspatial/id1254161962'>iOS</a> and <a href='https://play.google.com/store/apps/details?id=com.trailheadlabs.outerspatial'>Android</a>).
          </p>
          <h3>For Organizations</h3>
          <p>
            Work for an outdoor recreation or direct marketing organization? You're in the right place!
          </p>
          <ul>
            <li>If you're new to OuterSpatial and would like to add your organization to the platform, <a href='/login?screen_hint=signup'>sign up</a> for an account to get started for free.</li>
            <li>Or, if you already have an OuterSpatial account, <a href='/login'>log in</a> to access your organization in OuterSpatial.</li>
          </ul>
        </div>
      </Well>
    </div>
  )
}
