import { gql } from '@apollo/client'

export const DeletePostImageAttachment = gql`
    mutation DeletePostImageAttachment($id: Int!) {
        delete_image_attachments_by_pk(id: $id){
            id
            created_at
            
        }
    }
`

export default DeletePostImageAttachment
