import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import Alert from 'react-bootstrap/lib/Alert'
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { CenteredLoader } from '../../../components/centeredLoader'
import { Mutations, Queries } from './FeatureOperations'
import { PageContent, PageHeader, PageLayout } from '../../../components/pageLayout'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import debugMessage from 'services/Debug'
import Error404 from '../../errors/404'
import FeatureDocuments from './featureDocuments/FeatureDocuments'
import FeatureHeader from './FeatureHeader'
import FeatureLocations from './featureLocations/FeatureLocations'
import FeatureMaps from './featureMaps/FeatureMaps'
import FeaturePosts from './featurePosts/FeaturePosts'
import FeaturesFeatureInfo from './featureInfo/FeaturesFeatureInfo'
import './feature.css'

const Area = (props) => {
  const formRef = useRef()
  const history = useHistory()
  const latestFeatureData = useRef()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const featureId = isNaN(match.params.area_id) ? -1 : parseInt(match.params.area_id)
  const showSave = window.location.pathname.indexOf('info') > -1
  const [featureData, setFeatureData] = useState({
    description: '',
    id: -1,
    invalidId: false,
    name: '',
    visibility: 'Published'
  })

  const { error: areaQueryError, loading: areaIsLoading } = useQuery(Queries.GetArea, {
    skip: featureId === -1,
    variables: {
      areaId: featureId
    },
    onCompleted: (data) => {
      if (data.areas_by_pk) {
        const area = data.areas_by_pk
        const contentBlocks = area.content_blocks.map(cb => ({
          body: cb.content_block.body,
          id: cb.id,
          position: cb.content_block.position,
          title: cb.content_block.title
        }))

        setFeatureData({
          ...area,
          // area_destinations: area.area_destinations,
          class_name: 'Area',
          content_blocks: contentBlocks,
          geojson: area.boundary_simplified.geometry,
          stewardships: area.stewardships,
          table_name: area.__typename,
          tags: area.tags
        })
      } else {
        setFeatureData({
          ...latestFeatureData.current,
          invalidId: true
        })
      }
    }
  })

  const [updateArea, { loading: updateIsLoading }] = useMutation(Mutations.UpdateArea, {
    onCompleted: (data) => {
      if (data.update_areas_by_pk) {
        const area = data.update_areas_by_pk
        let tags = latestFeatureData.current.tags

        if (data.delete_tags?.affected_rows) {
          tags.filter(t => data.delete_tags.returning.find(dt => dt.key !== t.key))
        }

        if (data.insert_tags?.affected_rows) {
          tags = [
            ...tags,
            ...data.insert_tags.returning
          ]
        }

        let updatedAreaDestinations = latestFeatureData.current.area_destinations.filter(a =>
          !data.delete_area_destinations.returning.map(b => b.id).includes(a.id)
        )

        updatedAreaDestinations = [
          ...updatedAreaDestinations,
          ...data.insert_area_destinations.returning
        ]

        let updatedStewardships = latestFeatureData.current.stewardships.filter(s =>
          !data.delete_stewardships.returning.map(ds => ds.id).includes(s.id)
        )

        updatedStewardships = [
          ...updatedStewardships,
          ...data.insert_stewardships.returning
        ]

        setFeatureData({
          ...latestFeatureData.current,
          accessibility_description: area.accessibility_description,
          address: area.address,
          area_destinations: updatedAreaDestinations,
          description: area.description,
          name: area.name,
          phone_number: area.phone_number,
          stewardships: updatedStewardships,
          tags: tags,
          website: area.website
        })
        toast.success('The area was updated.')
      }
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The area could not be updated.')
    }
  })

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handleSubmitInfoForm = (newFeature, formikBag) => {
    const feature = {
      accessibility_description: stringIsNilOrWhitespace(newFeature.accessibility_description) ? null : newFeature.accessibility_description.trim(),
      address: stringIsNilOrWhitespace(newFeature.address) ? null : newFeature.address.trim(),
      description: stringIsNilOrWhitespace(newFeature.description) ? null : newFeature.description.trim(),
      keyValuesOfTagsToDelete: newFeature.keyValuesOfTagsToDelete,
      name: newFeature.name.trim(),
      newAreaDestinations: sanitizeAreaDestinationsForMutation(newFeature.areaDestinationChanges.added),
      newStewardships: sanitizeStewardshipsForMutation(newFeature.stewardshipChanges.added),
      newTags: newFeature.newTags,
      phone_number: stringIsNilOrWhitespace(newFeature.phone_number) ? null : newFeature.phone_number.trim(),
      removedAreaDestinationIds: newFeature.areaDestinationChanges.removed,
      removedStewardshipIds: newFeature.stewardshipChanges.removed,
      website: stringIsNilOrWhitespace(newFeature.website) ? null : newFeature.website.trim()
    }

    updateArea({
      variables: {
        ...feature,
        areaId: featureData.id
      }
    }).finally(() => {
      formikBag.setSubmitting(false)
    })
  }

  const sanitizeAreaDestinationsForMutation = (areaDestinations) => {
    return areaDestinations.map(a => {
      return {
        area_id: a.area_id,
        point_of_interest_id: a.point_of_interest_id
      }
    })
  }

  const sanitizeStewardshipsForMutation = (stewardships) => {
    return stewardships.map(s => {
      return {
        organization_id: s.organization_id,
        role: s.role,
        stewardable_id: s.stewardable_id,
        stewardable_type: 'Area'
      }
    })
  }

  const stringIsNilOrWhitespace = (input) => {
    // Handle weird Redactor bug.
    if (input === '<p><br></p>') {
      return true
    }

    return (input?.trim()?.length || 0) === 0
  }

  // Set latestFeatureData.current so it can be accessed instead of "stale" state in onComplete callbacks.
  useEffect(() => {
    latestFeatureData.current = featureData
    document.title = `${stringIsNilOrWhitespace(featureData.name) ? 'Unnamed' : featureData.name} | Areas | Locations | ${process.env.REACT_APP_PAGE_TITLE}`
  }, [featureData])

  if (areaQueryError?.message) {
    return <Alert bsStyle='danger'>{areaQueryError.message}</Alert>
  } else if (areaIsLoading || (featureId !== -1 && featureData?.id === -1 && !featureData.invalidId)) {
    return <CenteredLoader />
  } else if (featureData?.invalidId) {
    return <Alert bsStyle='warning'>There is no location with the requested ID.</Alert>
  } else if (featureData?.id !== -1 && featureData?.invalidId) {
    return <Error404 />
  }

  return (
    <div className='feature-wrap'>
      {(featureData.id === -1 || updateIsLoading) &&
        <CenteredLoader overlay />}

      {featureData.id !== -1 &&
        <PageLayout>
          <PageHeader>
            <FeatureHeader
              feature={featureData}
              match={match}
              onSaveClick={handleSave}
              showSaveButton={showSave}
            />
          </PageHeader>
          <PageContent>
            <Switch>
              <Route
                exact
                path={match.url}
                render={() => <Redirect to={`${match.url}/info`} />}
              />
              <Route
                path={`${match.url}/info`}
                render={(props) => {
                  return (
                    <FeaturesFeatureInfo
                      formRef={formRef}
                      feature={featureData}
                      handleSubmitForm={handleSubmitInfoForm}
                      organization={organization}
                    />
                  )
                }}
              />
              <Route
                path={`${match.url}/locations`}
                render={({ match }) => <FeatureLocations feature={featureData} match={match} />}
              />
              <Route
                path={`${match.url}/documents`}
                render={({ match }) => <FeatureDocuments feature={featureData} match={match} />}
              />
              <Route
                path={`${match.url}/maps`}
                render={({ match }) => <FeatureMaps feature={featureData} history={history} match={match} />}
              />
              <Route
                path={`${match.url}/social`}
                render={({ match }) => <FeaturePosts feature={featureData} history={history} match={match} />}
              />
            </Switch>
          </PageContent>
        </PageLayout>}
    </div>
  )
}

export default Area
