import { format } from 'date-fns'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import MenuItem from 'react-bootstrap/lib/MenuItem'

import './heroItemsTableRow.css'

const HeroItemsTableRow = ({ heroItem, match, onDelete }) => {
  const orgId = match.url.split('/')[1]
  const heroItemUrl = `/${orgId}/bulletin-board/${heroItem.id}`
  const title = heroItem.title
  const titleClass = 'heroItemsTab-tableRow__name' + (title === 'Untitled' ? ' none' : '')

  return (
    <tr className='heroItemsTable-tableRow'>
      <td className={titleClass}>
        <Link to={heroItemUrl}>{title}</Link>
      </td>
      <td>
        {format(new Date(heroItem.created_at), "LLL d, yyyy 'at' h':'maaa")}
      </td>
      <td>
        <DropdownButton
          id='actions'
          pullRight
          title='Actions'
        >
          <LinkContainer to={heroItemUrl}>
            <MenuItem>Edit</MenuItem>
          </LinkContainer>
          <MenuItem
            onClick={() => onDelete(heroItem)}
          >
            Delete
          </MenuItem>
        </DropdownButton>
      </td>
    </tr>
  )
}

HeroItemsTableRow.propTypes = {
  heroItem: PropTypes.object,
  match: PropTypes.object,
  onDelete: PropTypes.func
}

export default HeroItemsTableRow
