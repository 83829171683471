import Button from 'react-bootstrap/lib/Button'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React from 'react'

import { useFormikContext } from 'formik'
import FormikGroup from '../../../components/fieldGroup/FormikGroup'
import HelpDropdown from '../../../components/helpDropdown'

const OrganizationProfileContact = ({ isOrganizationAdmin, isSuperAdmin }) => {
  const { dirty, isValid, setFieldValue, submitForm, setTouched } = useFormikContext()

  const handleWalkthroughVideoSelect = () => {
    if (window.Beacon) {
      const beaconInfo = window.Beacon('info')
      // Organizations
      var messageId = 'eabc8ca6-b023-491c-b4bc-7829bc1ed142'

      if (beaconInfo && beaconInfo.beaconId !== '8bdf21e5-c4fc-43d1-8bd3-e17a0cf162f0') {
        // Sales
        messageId = '0e48e9ee-1a83-4920-b9a7-de993fc55cf0'
      }

      window.Beacon && window.Beacon('show-message', messageId, {
        force: true
      })
    }
  }

  return (
    <div>
      <Panel>
        <Panel.Heading>
          <div
            className='pull-right'
          >
            <HelpDropdown
              handleWalkthroughVideoSelect={handleWalkthroughVideoSelect}
            />
            {(isOrganizationAdmin || isSuperAdmin) && (
              <Button
                bsStyle='primary'
                disabled={!(dirty && isValid)}
                form='organization-contact-form'
                onClick={submitForm}
              >Save
              </Button>
            )}
          </div>
        </Panel.Heading>
        <Panel.Body>
          <div className='formGroup--double'>
            <FormikGroup
              disabled={!isOrganizationAdmin && !isSuperAdmin}
              id='email'
              label='Email'
              name='email'
              type='email'
            />
            <FormikGroup
              disabled={!isOrganizationAdmin && !isSuperAdmin}
              id='phone'
              label='Phone'
              name='phone'
              type='phone'
            />
          </div>
          <FormikGroup
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            id='website'
            label='Website'
            messages={{
              url: 'Please enter a valid web URL. Must start with http:// or https://.'
            }}
            name='website'
            type='text'
          />
          <FormikGroup
            clearable
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            id='address'
            label='Address'
            name='address'
            onChange={(e) => { setFieldValue('address', e.value); setTouched('address', true) }}
            type='location'
          />
        </Panel.Body>
      </Panel>
    </div>
  )
}

OrganizationProfileContact.propTypes = {
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default OrganizationProfileContact
