import React from 'react'
import t from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import './documentsTableRow.css'

const TableRow = ({ document, documentType, onEdit, onDelete, feature }) => {
  const match = useRouteMatch()
  const viewLink = `${match.url}/${document.id}`
  return (
    <tr className='documentsTable-tableRow'>
      <td>
        <Link to={viewLink}>
          {!!document.name && document.name.length > 0 &&
            <span>{document.name}</span>}
          {(!document.name || !document.name.length) &&
            <span className='muted'>Unnamed Document</span>}
        </Link>
      </td>
      <td>
        <DropdownButton title='Actions' id='actions' bsSize='small' pullRight>
          <MenuItem eventKey='view' target='_self' href={document.uploaded_file} download>Download</MenuItem>
          <MenuItem eventKey='delete' onClick={() => onDelete(document)}>Delete</MenuItem>
        </DropdownButton>
      </td>
    </tr>
  )
}

TableRow.propTypes = {
  document: t.object,
  documentType: t.string,
  onEdit: t.func,
  onDelete: t.func,
  feature: t.object
}

export default TableRow
