import { Field, Form, Formik } from 'formik'
import { parseJSON } from 'date-fns'
import Button from 'react-bootstrap/lib/Button'
import Checkbox from 'react-bootstrap/lib/Checkbox'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import * as Yup from 'yup'
import { buildImageUploadUrl } from 'services/Images'
import { CenteredLoader } from 'components/centeredLoader'
import { handleContentStyleGuideClick } from 'components/helpScout'
import { timezones as timezoneOptions } from 'models/time.model'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import Editor from 'components/editorWithHooks'
import FormikGroup from 'components/fieldGroup/FormikGroup'
import MediaModal from 'components/media/modal/MediaModal'

const getImageUrl = (image) => {
  let imageUrl = null

  if (image?.id && image?.uploaded_file) {
    imageUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'small')
  }

  return imageUrl
}
const stringIsNilOrWhitespace = (input) => {
  // Handle weird Redactor bug.
  if (input === '<p><br></p>') {
    return true
  }

  return (input?.trim()?.length || 0) === 0
}

const ChallengeInfo = (props) => {
  const { challenge, formRef, handleSubmitForm } = props
  const organization = useCurrentOrganization()

  const [badgeImageId, setBadgeImageId] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [startDateTime, setStartDateTime] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [timezone, setTimezone] = useState(null)
  const [timezoneOption, setTimezoneOption] = useState(null)

  const initialValues = {
    description: challenge.description || '',
    enable_location_validation: challenge.enable_location_validation || false,
    id: challenge.id,
    name: challenge.name || '',
    onboarding: challenge.onboarding?.content || '',
    requires_email_sharing: challenge.requires_email_sharing || false,
    visibility: challenge.visibility || 'public'
  }
  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .trim('Cannot include leading or trailing spaces.')
      .strict(true)
      .required('This field is required.'),
    enable_location_validation: Yup.boolean(),
    name: Yup.string()
      .trim('Cannot include leading or trailing spaces.')
      .strict(true)
      .min(2, 'Must have at least two characters.')
      .max(500, 'Must not be more than 500 characters.')
      .required('This field is required.'),
    onboarding: Yup.string()
      .trim('Cannot include leading or trailing spaces.')
      .strict(true)
      .required('This field is required.'),
    requires_email_sharing: Yup.boolean()
  })

  const closeMediaModal = () => {
    setShowMediaModal(false)
  }
  const openMediaModal = () => {
    setShowMediaModal(true)
  }
  const processSubmitForm = (values, formikBag) => {
    const newChallenge = values

    if (badgeImageId) {
      newChallenge.badge_image_id = badgeImageId
    }

    newChallenge.description = stringIsNilOrWhitespace(newChallenge.description) ? null : newChallenge.description.trim()

    if (endDateTime === null) {
      newChallenge.end_date = null
    } else {
      newChallenge.end_date = parseJSON(endDateTime).toISOString().slice(0, -1)
    }

    newChallenge.name = stringIsNilOrWhitespace(newChallenge.name) ? null : newChallenge.name.trim()
    newChallenge.onboarding = stringIsNilOrWhitespace(newChallenge.onboarding) ? null : newChallenge.onboarding.trim()

    if (startDateTime === null) {
      newChallenge.start_date = null
    } else {
      newChallenge.start_date = parseJSON(startDateTime).toISOString().slice(0, -1)
    }

    handleSubmitForm(newChallenge, formikBag)
  }
  const setDateTime = (fieldId, value) => {
    const updatedValue = typeof value.toDate === 'function' ? value.toDate() : value

    if (fieldId === 'end_date') {
      setEndDateTime(updatedValue)
    } else if (fieldId === 'start_date') {
      setStartDateTime(updatedValue)
    }
  }

  if (challenge.badge_image && imageUrl === null) {
    setBadgeImageId(challenge.badge_image.id)
    setImageUrl(getImageUrl(challenge.badge_image))
  }

  if (challenge.end_date && endDateTime === null) {
    setEndDateTime(parseJSON(challenge.end_date))
  }

  if (challenge.start_date && startDateTime === null) {
    setStartDateTime(parseJSON(challenge.start_date))
  }

  if ((challenge.time_zone || organization.time_zone) && timezone === null) {
    setTimezone(challenge.time_zone || organization.time_zone)
    setTimezoneOption(timezoneOptions.find((option) => option.value === (challenge.time_zone || organization.time_zone)))
  }

  return (
    <div className='challengeInfo-wrap'>
      <div className='challengeInfo-content'>
        <Formik
          initialValues={{ ...initialValues }}
          innerRef={formRef}
          onSubmit={(values, formikBag) => {
            processSubmitForm(values, formikBag)
          }}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            return (
              <>
                {formikProps.isSubmitting &&
                  <CenteredLoader overlay />}
                <Form noValidate>
                  <Panel>
                    <Panel.Heading>
                      Info
                      <div style={{ position: 'absolute', right: '15px', top: '6px' }}>
                        <Button
                          bsStyle='link'
                          onClick={handleContentStyleGuideClick}
                        >
                          Content Style Guide
                        </Button>
                      </div>
                    </Panel.Heading>
                    <Panel.Body>
                      <div>
                        <label className='control-label'>Badge Image</label>
                        <div className={'form-group challengeSection-image ' + (!imageUrl ? 'challengeImage--hasLogo' : '')}>
                          <Button className='challengeImage-imageButton' onClick={() => openMediaModal()} bsSize='small'>
                            Update
                          </Button>
                          {!!imageUrl &&
                            <div
                              className='challengeSection-badgeImage'
                              style={{
                                backgroundImage: `url(${imageUrl})`
                              }}
                            />}
                        </div>
                      </div>
                      <Field name='name'>
                        {({ field, meta }) => (
                          <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                            <label className='control-label' htmlFor='name'>Name *</label>
                            <input className='form-control' id='name' type='text' {...field} />
                            {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                          </div>
                        )}
                      </Field>
                      <Field name='requires_email_sharing'>
                        {({ field, form, meta }) => (
                          <div>
                            <Checkbox
                              {...field}
                              checked={field.value}
                              disabled={challenge.id > 0}
                            >
                              Email sharing required?
                            </Checkbox>
                            <span className='help-block'>If checked, the email address of participants will be available in the challenge reports. This field cannot be changed after the challenge is created.</span>
                          </div>
                        )}
                      </Field>
                      <Field name='enable_location_validation'>
                        {({ field, form, meta }) => (
                          <div>
                            <Checkbox
                              {...field}
                              checked={field.value}
                              disabled={challenge.id > 0}
                            >
                              Enable location validation?
                            </Checkbox>
                            <span className='help-block'>If checked, participants will only be able to check-in to locations they are close to. This field cannot be changed after the challenge is created.</span>
                          </div>
                        )}
                      </Field>
                      <Field name='description'>
                        {({ field, form, meta }) => (
                          <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                            <label className='control-label' htmlFor='description'>Description *</label>
                            <Editor
                              className='form-control'
                              id={field.name}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              value={field.value}
                            />
                            {meta.touched && meta.error && (<div className='error-message'>{meta.error}</div>)}
                          </div>
                        )}
                      </Field>
                      <div className='challengeForm-dateRow'>
                        <FormikGroup
                          displayTimeZone={timezone}
                          help={`The date and time the challenge will start, in the ${timezoneOption?.label || 'local'} time zone. Leave empty if you want this challenge to start immediately.`}
                          id='start_date'
                          input
                          label='Start Date/Time'
                          name='start_date'
                          onChange={(value) => setDateTime('start_date', value)}
                          type='datetime'
                          value={startDateTime}
                        />
                        <FormikGroup
                          clearable
                          displayTimeZone={timezone}
                          help={`The date and time the challenge will end, in the ${timezoneOption?.label || 'local'} time zone. Leave empty if this is an open-ended challenge.`}
                          id='end_date'
                          input
                          label='End Date/Time'
                          name='end_date'
                          onChange={(value) => setDateTime('end_date', value)}
                          type='datetime'
                          value={endDateTime}
                        />
                        {/* }
                        <fieldset className='eventForm-dateRow--timezone'>
                          <label htmlFor='timezone'>Timezone</label>
                          <Select
                            className='eventForm-timezoneSelect'
                            isClearable={false}
                            name='timezone'
                            onChange={setTimezone}
                            options={timezoneOptions}
                            value={timezoneOptions.filter(({ value }) => value === timezone)}
                          />
                        </fieldset>
                        { */}
                      </div>
                      <Field name='onboarding'>
                        {({ field, form, meta }) => (
                          <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                            <label className='control-label' htmlFor='onboarding'>Rules *</label>
                            <Editor
                              className='form-control'
                              id={field.name}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              value={field.value}
                            />
                            {meta.touched && meta.error && (<div className='error-message'>{meta.error}</div>)}
                            <span className='help-block'>Rules for the challenge, including any "Terms & Conditions". The visitor will be asked to acknowledge and accept these rules before participating in the challenge.</span>
                          </div>
                        )}
                      </Field>
                      <FormikGroup
                        help='If you set this to "Private", visitors will have to follow a deep link to start participating in the challenge and the challenge will be hidden for non-participants.'
                        label='Visibility'
                        name='visibility'
                        options={[{ label: 'Public', value: 'public' }, { label: 'Private', value: 'private' }]}
                        type='select'
                        value={formikProps.values.visibility}
                      />
                    </Panel.Body>
                  </Panel>
                </Form>
              </>
            )
          }}
        </Formik>
      </div>
      <MediaModal
        allowSelection
        onClose={closeMediaModal}
        onSubmit={(images) => {
          const image = images[0]
          setBadgeImageId(image.id)
          setImageUrl(getImageUrl(image))
          closeMediaModal()
        }}
        show={showMediaModal}
        singleSelection
      />
    </div>
  )
}

ChallengeInfo.propTypes = {
  challenge: PropTypes.object.isRequired,
  formRef: PropTypes.object,
  handleSubmitForm: PropTypes.func
}

export default ChallengeInfo
