import { useFormikContext } from 'formik'
import t from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import { useRouteMatch } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import buildMediaFileUrl from 'services/MediaFiles'

const DocumentHeader = ({ model = {}, onSaveClick, onDeleteClick }) => {
  const match = useRouteMatch()
  let backPath = match.path.split(':')[0]
  backPath = backPath.slice(0, backPath.length - 1)
  const formik = useFormikContext()
  const mediaFileUrl = buildMediaFileUrl(model.id, model.uploaded_file)

  return (
    <div className='document-header'>
      <div className='document-header__title'>
        <div className='document-header__titleText'>
          <LinkContainer to={backPath}>
            <Button bsStyle='link'>
              <Glyphicon glyph='chevron-left' />Back to Documents
            </Button>
          </LinkContainer>

          <h1>{model.name}</h1>
        </div>

        <div className='document-header__titleActions'>
          <Button bsStyle='default' target='_self' href={mediaFileUrl} download>Download</Button>
          <Button bsStyle='primary' className='btn-danger' onClick={onDeleteClick}>Delete</Button>
          <Button
            bsStyle='primary'
            onClick={onSaveClick}
            disabled={(!formik.dirty || !formik.isValid)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

DocumentHeader.propTypes = {
  model: t.object,
  onSaveClick: t.func,
  onDeleteClick: t.func
}

export default DocumentHeader
