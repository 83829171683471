import React from 'react'
import t from 'prop-types'
import Dropdown from 'react-bootstrap/lib/Dropdown'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { buildImageUploadUrl } from 'services/Images'
const OrganizationDropdown = ({ organization = {}, memberships = [] }) => {
  let imageUrl = null

  if (organization.logo_image && organization.logo_image.id) {
    const image = organization.logo_image

    if (image.uploaded_file) {
      imageUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'thumb_square')
    }
  }

  // Sort by organization name for use in the dropdown
  memberships = _.sortBy(memberships, (member) => member.organization.name.toLowerCase())

  return (
    <div className='sidebarLayout-organizationDropdown'>
      {memberships.length > 1 &&
        <Dropdown id='organization-dropdown'>
          <Dropdown.Toggle bsStyle='link'>
            <span className='orgDropdown-button'>
              {organization.logo_image && imageUrl &&
                <span className='organizationSingle-logo' style={{ backgroundImage: `url(${imageUrl})` }} />}
              <span className='orgDropdown-button__title'>{organization.name}</span>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {memberships.map((membership) => {
              return (
                <li key={membership.organization.id}>
                  <Link
                    to={`/${membership.organization.id}`}
                  >
                    {membership.organization.name}
                  </Link>
                </li>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>}
      {memberships.length === 1 && organization.id &&
        <div className='sidebarLayout-organizationSingle'>
          {organization.logo_image && imageUrl &&
            <span className='organizationSingle-logo' style={{ backgroundImage: `url(${imageUrl})` }} />}
          <h4>{organization.name}</h4>
        </div>}
    </div>
  )
}

OrganizationDropdown.propTypes = {
  memberships: t.array,
  organization: t.object
}

export default OrganizationDropdown
