import { Route } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import React from 'react'

import { CenteredLoader } from 'components/centeredLoader'

const ProtectedRoute = ({ component, args }) => {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <CenteredLoader overlay />
      })}
      {...args}
    />
  )
}

ProtectedRoute.propTypes = {
  args: PropTypes.object,
  children: PropTypes.object,
  component: PropTypes.func
}

export default ProtectedRoute
