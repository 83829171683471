import { gql } from '@apollo/client'

export const Fragments = {
  HeroItemDetails: gql`
    fragment HeroItemDetails on hero_items {
      id
      feature_id
      feature_type
      description
      title
      link_url
      link_type
      link_text
      link_id
      position
      image {
        uploaded_file
        id
      }
    }
  `
}

export const Queries = {
  GetHeroItemById: gql`
    query GetHeroItemById($heroItemId: Int!, $limit: Int! = 1) {
      hero_items(where: {id: {_eq: $heroItemId}}) {
        ...HeroItemDetails
      }
    }
    ${Fragments.HeroItemDetails}
  `,
  GetMaxPositionOfHeroItems: gql`
    query GetMaxPositionOfHeroItems($organizationId: Int!) {
      organizations(where: {id: {_eq: $organizationId}}) {
        hero_items_aggregate {
          aggregate {
            max {
              position
            }
          }
        }
      }
    }
  `
}

export const Mutations = {
  InsertHeroItem: gql`
    mutation InsertHeroItem(
      $feature_id: Int!,
      $feature_type: String! = "Organization",
      $image_id: Int!,
      $link_id: Int,
      $link_type: String,
      $link_url: String,
      $title: String!,
      $position: Int!
    ) {
      insert_hero_items(objects: {
        feature_id: $feature_id,
        feature_type: $feature_type,
        image_id: $image_id,
        link_id: $link_id,
        link_type: $link_type,
        link_url: $link_url,
        title: $title,
        position: $position
      }) {
        returning {
          ...HeroItemDetails
        }
      }
    }
    ${Fragments.HeroItemDetails}
  `,
  UpdateHeroItem: gql`
    mutation UpdateHeroItem(
      $id: Int!,
      $image_id: Int!,
      $link_id: Int,
      $link_url: String,
      $title: String,
      $position: Int
    ) {
      update_hero_items(
        where: {id: {_eq: $id}}
        _set: {
          image_id: $image_id,
          link_id: $link_id,
          link_url: $link_url,
          title: $title,
          position: $position
        }
      ) {
        returning {
          ...HeroItemDetails
        }
      }
    }
    ${Fragments.HeroItemDetails}
  `,
  DeleteHeroItem: gql`
    mutation DeleteHeroItem($id: Int!) {
      delete_hero_items(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
  `
}
