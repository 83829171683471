import { ApolloProvider } from '@apollo/client'
import { React, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import 'core-js/es/object/from-entries'

import { AuthContext } from 'contexts/AuthContext'
import { buildGraphqlClient } from 'services/GraphQLClients'
import AppRoutes from './AppRoutes'
import debugMessage from 'services/Debug'
import trackPage from 'services/TrackPage'

import 'services/Time'

const App = () => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    logout
  } = useAuth0()
  const [accessToken, setAccessToken] = useState()
  const [client, setClient] = useState()
  const history = useHistory()

  useEffect(() => {
    window.Chargebee.registerAgain()
  }, [])

  useEffect(() => {
    return history.listen((location) => {
      trackPage(location)

      if (document.title && location.href) {
        window.Beacon && window.Beacon('event', {
          title: document.title,
          type: 'page-viewed',
          url: location.href
        })
      }
    })
  }, [history])

  useEffect(() => {
    try {
      if (isAuthenticated && !accessToken) {
        getAccessTokenSilently().then((data) => {
          setAccessToken(data)
          setClient(buildGraphqlClient(data, logout))
        })
      }
    } catch (error) {
      debugMessage(error)
      logout({ returnTo: `${window.location.origin}/logout?error=${error}` })
      window.Beacon && window.Beacon('logout')
      Sentry.setUser(null)
    }
  })

  // TODO: Wrap ApolloProvider in <ThemeProvider theme={theme}>< CssBaseline />

  if (accessToken && client) {
    return (
      <ApolloProvider client={client}>
        <AuthContext.Provider value={{ accessToken }}>
          <AppRoutes />
        </AuthContext.Provider>
      </ApolloProvider>
    )
  } else {
    return <></>
  }
}

export default App
