import { useMutation, useQuery } from '@apollo/client'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/lib/Dropdown'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import debugMessage from 'services/Debug'
import toast from 'react-hot-toast'
import { featureByType } from '../../models/feature/feature.lang'
import './featureStatusDropdown.css'
import { Mutations, Queries } from './FeatureStatusOperations'

const STATUS_CATEGORY_KEY = 'Status'

const FeatureStatusDropdown = (props) => {
  const { bsSize, feature } = props
  const [fetchedTags, setFetchedTags] = useState(false)
  const [featureStatusOptions, setFeatureStatusOptions] = useState([])
  const [featureStatusValue, setFeatureStatusValue] = useState([])
  const [touchArea] = useMutation(Mutations.TouchArea)
  const [touchTrail] = useMutation(Mutations.TouchTrail)
  const [touchPointOfInterest] = useMutation(Mutations.TouchPOI)
  const [touchOuting] = useMutation(Mutations.TouchOuting)

  const renderValue = (featureStatusValue) => {
    return featureStatusValue.length ? featureStatusValue[0] : { descriptorName: 'Open', key: 'open' }
  }

  const touchFeature = () => {
    const featureType = featureByType(feature.__typename).classSingular
    const featureId = feature.id
    switch (featureType) {
      case 'Area':
        return touchArea({ variables: { id: featureId } })
      case 'Trail':
        return touchTrail({ variables: { id: featureId } })
      case 'PointOfInterest':
        return touchPointOfInterest({ variables: { id: featureId } })
      case 'Outing':
        return touchOuting({ variables: { id: featureId } })
      default:
        console.log('Could not find feature type')
    }
  }

  const { loading: areaIsLoading } = useQuery(Queries.GetFeatureStatusOptions, {
    skip: feature.id === -1 || !feature.__typename,
    variables: {
      feature_type: feature.__typename ? featureByType(feature.__typename).classSingular : undefined
    },
    onCompleted: (data) => {
      if (data?.tag_categories) {
        const featureType = featureByType(feature.__typename).classSingular
        const tags = featureType === 'Trail' ? feature.trail_only_tags : feature.tags
        const featureStatusValue = tags
          .filter((tag) => tag.tag_descriptor?.category?.name === STATUS_CATEGORY_KEY)
          .map((tag) => {
            const descriptor = _.find(data.tag_categories[0].tag_descriptors, {
              key: tag.key
            })
            return {
              ...tag,
              descriptor,
              descriptorName: descriptor ? descriptor.name : null,
              descriptorId: descriptor ? descriptor.id : null
            }
          })

        const featureStatusOptions = data.tag_categories[0].tag_descriptors
          .map((descriptor) => {
            return {
              descriptor,
              descriptorName: descriptor.name,
              descriptorId: descriptor.id,
              key: descriptor.key,
              value: 'yes'
            }
          })

        setFetchedTags(true)
        setFeatureStatusOptions(featureStatusOptions)
        setFeatureStatusValue(featureStatusValue)
      }
    },
    onError: (error) => {
      debugMessage(error)
    }
  })

  const [insertFeatureClosedStatus] = useMutation(Mutations.InsertFeatureClosedStatus, {
    onCompleted: (data) => {
      if (data.insert_tags?.affected_rows > 0) {
        touchFeature().then(() => {
          debugMessage('Feature touched')
        })
        toast.success('The location was successfully closed.')
      }
    },
    onError: (error) => {
      // Revert status to Open since the GraphQL Mutation failed
      setFeatureStatusValue(featureStatusOptions.filter(option => option.key === 'open'))
      debugMessage(error)
      toast.error('The location could not be closed.')
    }
  })

  const [deleteFeatureClosedStatus] = useMutation(Mutations.DeleteFeatureClosedStatus, {
    onCompleted: (data) => {
      if (data.delete_tags?.affected_rows > 0) {
        touchFeature().then(() => {
          debugMessage('Feature touched')
        })
        toast.success('The location was successfully opened.')
      }
    },
    onError: (error) => {
      // Revert status to Open since the GraphQL Mutation failed
      setFeatureStatusValue(featureStatusOptions.filter(option => option.key === 'closed'))
      debugMessage(error)
      toast.error('The location could not be opened.')
    }
  })

  const updateFeatureStatus = (statusKey) => {
    if (statusKey === 'closed') {
      insertFeatureClosedStatus({
        variables: {
          featureId: feature.id,
          featureType: featureByType(feature.__typename).classSingular
        }
      })
    } else {
      deleteFeatureClosedStatus({
        variables: {
          featureId: feature.id,
          featureType: featureByType(feature.__typename).classSingular
        }
      })
    }
  }

  const onChange = (value) => {
    // don't change value if the same value was selected
    if (value.descriptorName === renderValue(featureStatusValue).descriptorName) { return false }

    let conf = true

    if (value.key === 'closed') {
      conf = window.confirm('Are you sure you want to close this location?')
    }

    if (conf) {
      updateFeatureStatus(value.key)
      setFeatureStatusValue(featureStatusOptions.filter((status) => status.key === value.key))
    }
  }

  if (!areaIsLoading && fetchedTags) {
    const value = renderValue(featureStatusValue)

    return (
      <Dropdown className='featureStatusDropdown' id={'feature-status-' + (feature.id)} pullRight>
        <Dropdown.Toggle bsStyle='default' bsSize={bsSize}>
          <span className={'featureStatus-icon featureStatus-icon--' + (value.key)} />
          {value.descriptorName}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {featureStatusOptions.map((option) => {
            return (
              <MenuItem
                key={option.key}
                onClick={() => onChange(option)}
              >
                <span className={'featureStatus-icon featureStatus-icon--' + (option.key)} />
                {option.descriptorName}
              </MenuItem>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  } else return null
}

FeatureStatusDropdown.propTypes = {
  feature: PropTypes.object,
  bsSize: PropTypes.string
}

export default FeatureStatusDropdown
