import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import _ from 'lodash'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { CenteredLoader } from '../../../../../../components/centeredLoader'
import AdditionalInfoModal from './AdditionalInfoModal'

import './additionalInfo.css'

const SortableItem = ({ contentBlock, onDestroy, onEdit }) => {
  const {
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: contentBlock.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div
      className='additionalInfo-section'
      ref={setNodeRef}
      style={style}
    >
      <div
        className='additionalInfo-handle'
        ref={setActivatorNodeRef}
        {...listeners}
      >
        <Button
          bsSize='small'
          bsStyle='link'
          className='waypoint-handle'
        >
          <Glyphicon glyph='menu-hamburger' />
        </Button>
      </div>
      <div className='additionalInfo-section__title'>
        {contentBlock.title.length > 0 && <label>{contentBlock.title}</label>}
        <div className='additionalInfo-section__actions'>
          <Button bsStyle='link' onClick={() => onDestroy(contentBlock.id)}><Glyphicon glyph='trash' /></Button>
          <Button bsStyle='link' onClick={() => onEdit(contentBlock)}><Glyphicon glyph='pencil' /></Button>
        </div>
      </div>
      {contentBlock.body.length > 0 &&
        <div className='additionalInfo-section__content'>
          <div dangerouslySetInnerHTML={{ __html: contentBlock.body }} />
        </div>}
    </div>
  )
}

SortableItem.propTypes = {
  contentBlock: PropTypes.object.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
}

const AdditionalInfo = (props) => {
  const { contentBlocks, onDelete, onSave, onOrderUpdate, updating } = props
  const [showModal, setShowModal] = useState(false)
  const [selectedContentBlock, setSelectedContentBlock] = useState(null)
  const [sortedContentBlocks, setSortedContentBlocks] = useState([])
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const closeModal = () => {
    setShowModal(false)
    setSelectedContentBlock(null)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = sortedContentBlocks.findIndex(item => item.id === active.id)
      const newIndex = sortedContentBlocks.findIndex(item => item.id === over.id)
      onOrderUpdate(oldIndex, newIndex)
    }
  }

  const handleEdit = (contentBlockToEdit) => {
    setSelectedContentBlock({ ...contentBlockToEdit })
    openModal()
  }

  const openModal = () => {
    setShowModal(true)
  }

  const saveCompletedContentBlockCallback = (formikBag) => {
    formikBag.setSubmitting(false)
    setShowModal(false)
    setSelectedContentBlock(null)
  }

  const saveModal = (values, formikBag) => {
    onSave(values, () => saveCompletedContentBlockCallback(formikBag))
  }

  useEffect(() => {
    setSortedContentBlocks(_.sortBy(contentBlocks, 'position'))
  }, [contentBlocks])

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className='featureSection-content featureSection--additionalInfo'>
        {updating && <CenteredLoader overlay />}
        <div className='additionalInfo-sections'>
          <SortableContext
            items={sortedContentBlocks}
            strategy={verticalListSortingStrategy}
          >
            {sortedContentBlocks.map(contentBlock =>
              <SortableItem
                contentBlock={contentBlock}
                id={contentBlock.id}
                key={contentBlock.id}
                onDestroy={onDelete}
                onEdit={handleEdit}
              />
            )}
          </SortableContext>
        </div>
        <div className='additionalInfo-footer'>
          <Button onClick={openModal}>
            <Glyphicon glyph='plus' />
            Additional Info
          </Button>
        </div>
        <AdditionalInfoModal
          contentBlock={selectedContentBlock}
          onClose={closeModal}
          onSave={saveModal}
          show={showModal}
        />
      </div>
    </DndContext>
  )
}

AdditionalInfo.propTypes = {
  contentBlocks: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOrderUpdate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  updating: PropTypes.bool
}

export default AdditionalInfo
