import * as Yup from 'yup'
import Button from 'react-bootstrap/lib/Button'
import fetch from 'node-fetch'
import { Field, Form, Formik } from 'formik'
import { format } from 'date-fns'
import moment from 'moment'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import { React, useEffect, useState } from 'react'
import ReactSelect from 'react-select'

import { askOuterSpatialAQuestion } from 'components/helpScout'
import { CenteredLoader } from 'components/centeredLoader'
import { PageLayout } from 'components/pageLayout'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import HelpDropdown from 'components/helpDropdown'
import { useAuth } from 'contexts/AuthContext'
import './analytics.css'

const Analytics = ({ history, isOrganizationAdmin, isSuperAdmin, match }) => {
  const { accessToken } = useAuth()
  const currentUser = useCurrentUser()
  const linkStyle = {
    color: '#41A7D0',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
  const organization = useCurrentOrganization()
  const [endMonthYear, setEndMonthYear] = useState(moment().endOf('month').format('YYYY-MM'))
  const [startMonthYear, setStartMonthYear] = useState(moment().startOf('month').format('YYYY-MM'))
  const [state, setState] = useState({
    brandedAppSelected: false,
    submitting: false
  })
  const applicationOptions = (() => {
    const options = [{ label: 'OuterSpatial', value: 'outerspatial' }]

    organization.communities.forEach((community) => {
      if (
        community.community.name === 'Trails LA County'
      ) {
        options.push({
          label: community.community.name,
          value: community.community.name.replace(/ /g, '').toLowerCase()
        })
      }
    })

    return options
  })()
  const brandedAppSelected = state.brandedAppSelected
  const communityOptions = (() => {
    const options = []

    organization.communities.forEach((community) => {
      options.push({
        label: community.community.name,
        value: community.community.id
      })
    })

    return options
  })()
  const typeOptions = [
    { label: 'Community: Usage', value: 'community-usage' },
    { label: 'Organization: Campaigns', value: 'organization-campaigns' },
    { label: 'Organization: Screen Engagement', value: 'organization-screen-engagement' }
  ]

  const handleSignUpClick = () => {
    if (organization.license_tier === 'free') {
      history.push(`${match.path}/billing`)
    } else {
      askOuterSpatialAQuestion(
        'Publish Organization Into a Community',
        null,
        currentUser
      )
    }
  }
  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '67', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: 'https://organizations.outerspatial.help/article/67-visitor-analytics'
      }).click()
    }
  }
  const validateApplicationId = (value) => {
    if (applicationOptions.length === 1) {
      return Yup.string()
        .nullable(true)
        .validate(value, { abortEarly: false })
        .then((result) => null)
        .catch((error) => {
          return error.errors
        })
    } else {
      return Yup.string()
        .nullable(false)
        .required('Application is required.')
        .validate(value, { abortEarly: false })
        .then((result) => null)
        .catch((error) => {
          return error.errors
        })
    }
  }
  const validateCommunityId = (value) => {
    if (brandedAppSelected === false) {
      return Yup.number()
        .positive('Community selection is required.')
        .required('Community selection is required.')
        .validate(value, { abortEarly: false })
        .then((result) => null)
        .catch((error) => {
          return error.errors
        })
    }
  }
  const validateEndMonth = (value) => {
    // TODO: Hook up.
    return null
  }
  const validateStartMonth = (value) => {
    // TODO: Hook up.
    return null
  }
  const validateType = (value) => {
    return Yup.string()
      .required('Type selection is required.')
      .validate(value, { abortEarly: false })
      .then((result) => null)
      .catch((error) => {
        return error.errors
      })
  }

  useEffect(() => {
    document.title = `Visitor Analytics | Organization | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  return (
    <div className='analytics-wrap'>
      <PageLayout>
        <div className='analytics-titleBar'>
          <div className='analytics-actions'>
            <HelpDropdown handleViewHelpSelect={handleViewHelpClick} />
          </div>
        </div>
        <Panel className='analytics-form'>
          <Panel.Body>
            {organization && organization.license_tier === 'free' && (
              <div className='empty-message text-center'>
                <h3>
                  Visitor Analytics are not available because your organization
                  is on the "Free" plan.
                </h3>
                {(isOrganizationAdmin || isSuperAdmin) && (
                  <h3>
                    <span onClick={handleSignUpClick} style={linkStyle}>
                      Sign up for a license
                    </span>{' '}
                    to access Visitor Analytics now.
                  </h3>
                )}
              </div>
            )}
            {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length === 0 && (
              <div className='empty-message text-center'>
                <h3>
                  Visitor Analytics are not available because your
                  organization has not been added to a community.
                </h3>
                <h3>
                  <span onClick={handleSignUpClick} style={linkStyle}>
                    Reach out to customer success
                  </span>{' '}
                  to publish your organization into its first community.
                </h3>
              </div>
            )}
            {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length > 0 && (
              <div className='campaign-form'>
                {state.submitting && <CenteredLoader overlay />}
                <Formik
                  initialValues={{
                    application: 'outerspatial',
                    community_id: -1,
                    end_month: endMonthYear,
                    start_month: startMonthYear,
                    type: ''
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    const apiEndpoint = (() => {
                      switch (values.type) {
                        case 'community-usage':
                          return 'usage'
                        case 'organization-campaigns':
                          return 'campaigns'
                        case 'organization-screen-engagement':
                          return 'engagement'
                      }

                      return null
                    })()
                    const application = values.application || 'outerspatial'

                    if (
                      apiEndpoint === 'campaigns' &&
                        application !== 'outerspatial'
                    ) {
                      window.alert(
                        'Campaign analytics are not yet available for branded apps. Please reach out to our customer success team to request this report.'
                      )
                    } else {
                      const momentEndMonthYear = moment(endMonthYear).endOf('month')
                      const momentStartMonthYear = moment(startMonthYear).startOf('month')

                      if (momentStartMonthYear > momentEndMonthYear) {
                        window.alert('Start month must before or equal to end month')
                      } else {
                        const body = Object.assign({}, values, {
                          end_date: momentEndMonthYear.toISOString(),
                          organization_id: organization.id,
                          start_date: momentStartMonthYear.toISOString()
                        })

                        body.application = application

                        if (brandedAppSelected === true) {
                          body.community_id = organization.communities.find(
                            (community) =>
                              community.community.name
                                .replace(/ /g, '')
                                .toLowerCase() === values.application
                          ).community.id
                        }

                        // TODO: Support showing analytics for each community an organization is in in the reports.

                        setState({
                          ...state,
                          submitting: true
                        })
                        setSubmitting(true)
                        fetch(
                          `${process.env.REACT_APP_API_SERVICE_URL}/analytics/${apiEndpoint}`,
                          {
                            body: JSON.stringify(body),
                            headers: {
                              Accept: 'text/csv',
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${accessToken}`
                            },
                            method: 'post'
                          }
                        )
                          .then((response) => response.blob())
                          .then((response) => {
                            const a = document.createElement('a')
                            const blob = new window.Blob([response], {
                              type: 'application/csv'
                            })
                            const downloadUrl = URL.createObjectURL(blob)
                            const startDateString = format(
                              momentStartMonthYear.toDate(),
                              'MMMyyyy'
                            )
                            const endDateString = format(
                              momentEndMonthYear.toDate(),
                              'MMMyyyy'
                            )
                            const fileName =
                              startDateString === endDateString
                                ? startDateString
                                : `${startDateString}-${endDateString}`

                            a.download = `${application}_community-${body.community_id}_organization-${organization.id}_${fileName}_${values.type}.csv`
                            a.href = downloadUrl
                            document.body.appendChild(a)
                            a.click()
                            setState({
                              ...state,
                              submitting: false
                            })
                            setSubmitting(false)
                          })
                      }
                    }
                  }}
                >
                  {({ isValid }) => {
                    return (
                      <Form>
                        {applicationOptions.length > 1 && (
                          <Field
                            name='application'
                            validate={validateApplicationId}
                          >
                            {({ field, form, meta }) => (
                              <div
                                className={`form-group ${
                                    meta.touched && meta.error
                                      ? 'has-error'
                                      : ''
                                }`}
                              >
                                <label
                                  className='control-label'
                                  htmlFor='application'
                                >
                                  Application
                                </label>
                                <div className='select-wrap'>
                                  <ReactSelect
                                    isClearable={false}
                                    name='application'
                                    onChange={(option) => {
                                      setState({
                                        ...state,
                                        brandedAppSelected: option.value !== 'outerspatial'
                                      })
                                      form.setFieldValue(
                                        field.name,
                                        option.value
                                      )
                                    }}
                                    options={applicationOptions}
                                    value={applicationOptions.find(
                                      (option) => option.value === field.value
                                    )} // Trigger onChange for first load.
                                  />
                                </div>
                                {meta.touched && meta.error && (
                                  <div className='error-message'>
                                    {meta.error}
                                  </div>
                                )}
                              </div>
                            )}
                          </Field>
                        )}
                        {brandedAppSelected === false && (
                          <Field
                            name='community_id'
                            validate={validateCommunityId}
                          >
                            {({ field, form, meta }) => (
                              <div
                                className={`form-group ${
                                    meta.touched && meta.error
                                      ? 'has-error'
                                      : ''
                                }`}
                              >
                                <label
                                  className='control-label'
                                  htmlFor='community_id'
                                >
                                  Community
                                </label>
                                <div className='select-wrap'>
                                  <ReactSelect
                                    className='communityIdSelect'
                                    classNamePrefix='communityIdSelect'
                                    display='none'
                                    isClearable={false}
                                    name='community_id'
                                    onChange={(option) => {
                                      form.setFieldValue(
                                        field.name,
                                        option.value
                                      )
                                    }}
                                    options={communityOptions}
                                    value={communityOptions.find(
                                      (option) => option.value === field.value
                                    )} // Trigger onChange for first load.
                                  />
                                </div>
                                {meta.touched && meta.error && (
                                  <div className='error-message'>
                                    {meta.error}
                                  </div>
                                )}
                              </div>
                            )}
                          </Field>
                        )}
                        <div>
                          <span className='help-block'>Manually select a start and end month or click one of the month range preset buttons to populate the start/end month automatically.</span>
                        </div>
                        <div className='month-preset-buttons'>
                          <Button
                            bsStyle='secondary'
                            onClick={() => {
                              setStartMonthYear(moment().startOf('month').format('YYYY-MM'))
                              setEndMonthYear(moment().endOf('month').format('YYYY-MM'))
                            }}
                          >
                            This Month
                          </Button>
                          <Button
                            bsStyle='secondary'
                            onClick={() => {
                              setStartMonthYear(moment().subtract(2, 'month').startOf('month').format('YYYY-MM'))
                              setEndMonthYear(moment().endOf('month').format('YYYY-MM'))
                            }}
                          >
                            Past 3 Months
                          </Button>
                          <Button
                            bsStyle='secondary'
                            onClick={() => {
                              setStartMonthYear(moment().subtract(5, 'month').startOf('month').format('YYYY-MM'))
                              setEndMonthYear(moment().endOf('month').format('YYYY-MM'))
                            }}
                          >
                            Past 6 Months
                          </Button>
                          <Button
                            bsStyle='secondary'
                            onClick={() => {
                              setStartMonthYear(moment().subtract(12, 'month').startOf('month').format('YYYY-MM'))
                              setEndMonthYear(moment().endOf('month').format('YYYY-MM'))
                            }}
                          >
                            Past Year
                          </Button>
                          <Button
                            bsStyle='secondary'
                            onClick={() => {
                              setStartMonthYear('2018-01')
                              setEndMonthYear(moment().endOf('month').format('YYYY-MM'))
                            }}
                          >
                            All Time
                          </Button>
                        </div>
                        <div className='analyticsForm-monthRow'>
                          <Field
                            name='start_month'
                            validate={validateStartMonth}
                          >
                            {({ field, form, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.error ? 'has-error' : ''
                                }`}
                              >
                                <label className='control-label' htmlFor='start_month'>
                                  Start Month
                                </label>
                                <div>
                                  <input
                                    className='form-control'
                                    id={field.name}
                                    max={moment().endOf('month').format('YYYY-MM')}
                                    min={moment('01-01-2018T00:00.00').startOf('year').format('YYYY-MM')}
                                    onChange={(value) => {
                                      setStartMonthYear(value.target.value)
                                      form.setFieldValue(field.name, value.target.value)
                                    }}
                                    type='month'
                                    value={startMonthYear}
                                  />
                                </div>
                                {meta.touched && meta.error && (
                                  <div className='error-message'>
                                    {meta.error}
                                  </div>
                                )}
                              </div>
                            )}
                          </Field>
                          <Field
                            name='end_month'
                            validate={validateEndMonth}
                          >
                            {({ field, form, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.error ? 'has-error' : ''
                                }`}
                              >
                                <label className='control-label' htmlFor='end_month'>
                                  End Month
                                </label>
                                <div>
                                  <input
                                    className='form-control'
                                    id={field.name}
                                    max={moment().endOf('month').format('YYYY-MM')}
                                    min={moment('01-01-2018T00:00.00').startOf('year').format('YYYY-MM')}
                                    onChange={(value) => {
                                      setEndMonthYear(value.target.value)
                                      form.setFieldValue(field.name, value.target.value)
                                    }}
                                    type='month'
                                    value={endMonthYear}
                                  />
                                </div>
                                {meta.touched && meta.error && (
                                  <div className='error-message'>
                                    {meta.error}
                                  </div>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <Field name='type' validate={validateType}>
                          {({ field, form, meta }) => (
                            <div
                              className={`form-group ${
                                  meta.touched && meta.error ? 'has-error' : ''
                              }`}
                            >
                              <label className='control-label' htmlFor='type'>
                                Type
                              </label>
                              <div className='select-wrap'>
                                <ReactSelect
                                  className='typeSelect'
                                  classNamePrefix='typeSelect'
                                  isClearable={false}
                                  name='type'
                                  onChange={(option) => {
                                    form.setFieldValue(
                                      field.name,
                                      option.value
                                    )
                                  }}
                                  options={typeOptions}
                                  value={typeOptions.find(
                                    (option) => option.value === field.value
                                  )} // Trigger onChange for first load.
                                />
                              </div>
                              {meta.touched && meta.error && (
                                <div className='error-message'>
                                  {meta.error}
                                </div>
                              )}
                            </div>
                          )}
                        </Field>
                        <Button
                          block
                          bsStyle='primary'
                          disabled={!isValid || state.submitting}
                          type='submit'
                        >
                          Download
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            )}
          </Panel.Body>
        </Panel>
      </PageLayout>
    </div>
  )
}

Analytics.propTypes = {
  history: PropTypes.object,
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  match: PropTypes.object
}

export default Analytics
