import Button from 'react-bootstrap/lib/Button'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import Nav from 'react-bootstrap/lib/Nav'
import NavItem from 'react-bootstrap/lib/NavItem'
import PropTypes from 'prop-types'
import React from 'react'

import FeatureStatusDropdown from 'components/featureStatusDropdown'
import MoreDropdown from 'components/moreDropdown'
import VisibilityDropdown from 'components/visibilityDropdown'

const propTypes = {
  match: PropTypes.object.isRequired,
  onSaveClick: PropTypes.func,
  outing: PropTypes.object
}
const defaultProps = {}

const OutingHeader = ({
  match,
  onSaveClick,
  outing = {}
}) => {
  let backPath = match.path.split(':')[0]
  const baseUrl = match.url
  const isNew = match.params.outing_id === 'new'

  backPath = backPath.slice(0, backPath.length - 1)

  const componentsJoin = (components, separator) => {
    return components.reduce(
      (acc, curr) => (acc.length ? [...acc, separator, curr] : [curr]),
      []
    )
  }
  const getAreaLink = (area) => {
    return `/${match.url.split('/')[1]}/locations/areas/${area.feature.id}`
  }
  const getParentAreasHtml = () => {
    const parentAreas = outing.outing_areas

    if (parentAreas.length === 1) {
      return (
        <span>&nbsp;in&nbsp;
          <Link to={getAreaLink(outing.outing_areas[0])}>
            {outing.outing_areas[0].feature.name}
          </Link>
        </span>
      )
    } else {
      const spans = []

      parentAreas.forEach((area, index) => {
        spans.push(
          <Link to={getAreaLink(area)}>
            {area.feature.name}
          </Link>
        )
      })

      return [
        <span
          key={0}
        >
          &nbsp;in&nbsp;
        </span>
      ].concat(componentsJoin(spans, ', '))
    }
  }

  return (
    <div className='feature-header'>
      <div className='feature-header__title'>
        <div className='feature-header__titleText'>
          <h1>
            {isNew ? 'New Outing' : outing.name}
          </h1>
          <h2 className='feature-header__breadcrumbs'>
            <Link to={backPath}>
              Outing
            </Link>
            {outing.outing_areas && outing.outing_areas.length > 0 && (
              getParentAreasHtml()
            )}
          </h2>
        </div>
        <div className='feature-header__titleActions'>
          {!isNew &&
            <FeatureStatusDropdown
              feature={outing}
            />}
          <VisibilityDropdown
            entityId={outing.id}
            entityType='Outing'
            initialValue={outing.visibility}
            outingHasEmptyRouteGeometry={outing.route === null || outing.route?.geometry === null}
          />
          <MoreDropdown
            feature={outing}
            featureType='outings'
          />
          <Button
            bsStyle='primary'
            // FIXME: In order to enable/disable the Save button, we need to:
            //    Pass the OutingInfo form pristine and valid status
            //    Pass the OutingRoute modified state (after mananging it locally)
            // disabled={form.$form.pristine || !form.$form.valid}
            onClick={onSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
      <div className='feature-tabs'>
        <Nav bsStyle='pills' className='nav-borders'>
          <LinkContainer to={`${baseUrl}/info`}>
            <NavItem>Info</NavItem>
          </LinkContainer>
          {!isNew && (
            <LinkContainer to={`${baseUrl}/route`}>
              <NavItem>Route</NavItem>
            </LinkContainer>
          )}
          {!isNew && (
            <LinkContainer to={`${match.url}/social`}>
              <NavItem>Social</NavItem>
            </LinkContainer>
          )}
          {!isNew && (
            <LinkContainer to={`${baseUrl}/documents`}>
              <NavItem>Documents</NavItem>
            </LinkContainer>
          )}
          {!isNew && (
            <LinkContainer to={`${baseUrl}/maps`}>
              <NavItem>Maps</NavItem>
            </LinkContainer>
          )}
        </Nav>
      </div>
    </div>
  )
}

OutingHeader.propTypes = propTypes
OutingHeader.defaultProps = defaultProps

export default OutingHeader
