import React from 'react'
import t from 'prop-types'
import MediaListItem from './MediaListItem'
import { Panel, Table } from 'react-bootstrap'
import './mediaList.css'
import Moment from 'react-moment'

const MediaTable = (props) => {
  const {
    allowEdit = true,
    allowSelection = false,
    media,
    onClick,
    selectedMedia,
    updating
  } = props

  return (
    <Panel className='mediaList-table-panel'>
      <Panel.Body>
        <Table className='mediaList-table' striped>
          <thead>
            <tr>
              <th>Image</th>
              <th>Attached Locations</th>
              <th>Upload Date</th>
              <th>Caption</th>
            </tr>
          </thead>
          <tbody>
            {media.map((mediaItem, index) => {
              const locations = [].concat(
                mediaItem.trail_image_attachments.map(item => {
                  return item.trail?.name
                })
              ).concat(
                mediaItem.area_image_attachments.map(item => {
                  return item.area?.name
                })
              ).concat(
                mediaItem.point_of_interest_image_attachments.map(item => {
                  return item.point_of_interest?.name
                })
              ).sort()
              const locationCount = locations.length

              return (
                <tr key={mediaItem.id}>
                  <td>
                    <div className='mediaList-listItem' key={mediaItem.id + '-' + index}>
                      <MediaListItem
                        allowEdit={allowEdit}
                        allowSelection={allowSelection}
                        image={mediaItem}
                        onClick={onClick}
                        selectedMedia={selectedMedia}
                        updating={updating === mediaItem.id}
                      />
                    </div>
                  </td>
                  <td className='nowrap'>
                    {locationCount > 0 &&
                        `${locations[0]}`}
                    {locationCount > 1 &&
                      <div style={{ fontStyle: 'italic' }}>{`+ ${locationCount - 1} More`}</div>}
                    {locationCount <= 0 &&
                      <span className='mediaTable-none'>None</span>}
                  </td>
                  <td className='nowrap'>
                    <Moment format='MMMM D, YYYY'>{mediaItem.created_at}</Moment>
                  </td>
                  <td>
                    {mediaItem.caption && mediaItem.caption.length > 0 &&
                        `${mediaItem.caption}`}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Panel.Body>
    </Panel>
  )
}

MediaTable.propTypes = {
  allowEdit: t.bool,
  allowSelection: t.bool,
  media: t.array,
  onClick: t.func,
  selectedMedia: t.array,
  updating: t.any
}

export default MediaTable
