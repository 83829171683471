import PropTypes from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import Panel from 'react-bootstrap/lib/Panel'
import Table from 'react-bootstrap/lib/Table'

import MapsTableRow from './MapsTableRow'
import Pagination from '../../../../components/pagination'
import { useCurrentUser } from 'contexts/CurrentUserContext'

import './mapsTable.css'

const SortableTH = ({ field, label, onChangeQuery, query }) => {
  return (
    <th className={`mapsTable--${field}`}>
      <Button
        bsStyle='link'
        onClick={() => onChangeQuery({
          order: field,
          direction: query.direction === 'asc' ? 'desc' : 'asc'
        }
        )}
      >
        {label}
        {query.order === field && query.direction !== 'desc' &&
          <Glyphicon glyph='chevron-up' />}
        {query.order === field && query.direction === 'desc' &&
          <Glyphicon glyph='chevron-down' />}
      </Button>
    </th>
  )
}

SortableTH.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onChangeQuery: PropTypes.func,
  query: PropTypes.object
}

const MapsTable = ({
  maps,
  mapType,
  onDelete,
  match,
  paging,
  feature,
  onPageChange,
  onChangeQuery,
  query
}) => {
  const isSuperAdmin = (user) => {
    return user.roles.find((item) => { return item.role.name === 'admin' })
  }
  const user = useCurrentUser()

  return (
    <div className='mapsTable'>
      {!maps.length && (
        <Panel className='mapsTable-empty'>
          <Panel.Body>
            {mapType === 'paper' && (
              <h3>No paper maps available.</h3>
            )}
            {mapType === 'builder' && (
              <h3>No builder maps available.</h3>
            )}
          </Panel.Body>
        </Panel>
      )}
      {!!maps.length && (
        <div>
          <Panel>
            <Table striped>
              <thead>
                <tr>
                  <SortableTH
                    field='name'
                    label='Name'
                    onChangeQuery={onChangeQuery}
                    query={query}
                  />
                  {isSuperAdmin(user) && (
                    <th>
                      <Glyphicon glyph='star' />{' '}Status
                    </th>
                  )}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {maps.map(map => {
                  return (
                    <MapsTableRow
                      key={map.id}
                      {...{
                        map,
                        mapType,
                        onDelete,
                        match,
                        feature
                      }}
                    />
                  )
                })}
              </tbody>
            </Table>
          </Panel>
          {paging && paging.total_pages && paging.total_pages > 1 &&
            <div className='mapsTable-pagination'>
              <Pagination
                activePage={parseInt(paging.current_page, 10)}
                handleOnSelect={onPageChange}
                numberOfPages={paging.total_pages}
              />
            </div>}
        </div>
      )}
    </div>
  )
}

MapsTable.propTypes = {
  feature: PropTypes.object,
  mapType: PropTypes.string,
  maps: PropTypes.array,
  match: PropTypes.object,
  onDelete: PropTypes.func,
  onChangeQuery: PropTypes.func,
  onPageChange: PropTypes.func,
  paging: PropTypes.object,
  query: PropTypes.object
}

export default MapsTable
