import { gql } from '@apollo/client'

export const InsertPostImageAttachment = gql`
    mutation InsertPostImageAttachment($post_id: Int!,$image_id: Int!,$position: Int!) {
        insert_image_attachments_one(object: {
                image_id: $image_id, 
                position: $position, 
                attacheable_type: "Post",
                attacheable_id: $post_id
            }){
            id
            created_at
            
        }
    }
`

export default InsertPostImageAttachment
