import t from 'prop-types'
import React from 'react'
import Document from '../../../../views/documents/document/Document'

const FeatureDocument = (props) => {
  const { match, feature } = props
  return (
    <div className='featureDocument'>
      <Document feature={feature} match={match} />
    </div>
  )
}

FeatureDocument.propTypes = {
  match: t.object,
  feature: t.object
}

export default FeatureDocument
