/* globals L */

const defaultSvgPaths = {
  deleteFromEnd: '<path d="M0 0v6l5-3-5-3zm5 3v3h2v-6h-2v3z" transform="translate(0 1)" />',
  deleteFromStart: '<path d="M0 0v6h2v-6h-2zm2 3l5 3v-6l-5 3z" transform="translate(0 1)" />',
  pencil: '<path d="M6 0l-1 1 2 2 1-1-2-2zm-2 2l-4 4v2h2l4-4-2-2z" />',
  plus: '<path d="M3 0v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z" />',
  trash: '<path d="M3 0c-.55 0-1 .45-1 1h-1c-.55 0-1 .45-1 1h7c0-.55-.45-1-1-1h-1c0-.55-.45-1-1-1h-1zm-2 3v4.81c0 .11.08.19.19.19h4.63c.11 0 .19-.08.19-.19v-4.81h-1v3.5c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-3.5h-1v3.5c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-3.5h-1z" />',
  undo: '<path d="M4.5 0c-1.93 0-3.5 1.57-3.5 3.5v.5h-1l2 2 2-2h-1v-.5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5c0-1.93-1.57-3.5-3.5-3.5z" transform="translate(0 1)" />'
}

export const SvgControl = L.Control.extend({
  initialize: function (options) {
    this.options = options

    return this
  },
  onAdd: function () {
    const container = L.DomUtil.create('div', 'leaflet-bar leaflet-bar-single leaflet-control leaflet-control-' + this.options.icon)
    const button = L.DomUtil.create('button', '', container)

    button.innerHTML = '' +
      '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 8 8">' + defaultSvgPaths[this.options.icon] + '</svg>' +
    ''
    L.DomEvent.disableClickPropagation(button)
      .on(button, 'click', L.DomEvent.preventDefault)
      .on(button, 'click', this.options.onClick)

    if (this.options.hidden) {
      container.style.display = 'none'
    }

    if (this.options.title) {
      button.setAttribute('title', this.options.title)
    }

    return container
  }
})
