import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/lib/Button'
import Modal from 'react-bootstrap/lib/Modal'
import MediaForm from './MediaForm'
import './mediaModal.css'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { buildImageUploadUrl } from 'services/Images'

const UpdateImageQuery = gql`
  mutation UpdateImageQuery($attribution_text: String, $attribution_url: String, $caption: String, $id: Int!) {
    update_images_by_pk(pk_columns: {id: $id}, _set: {caption: $caption, attribution_text: $attribution_text, attribution_url: $attribution_url}) {
      id
      attribution_text
      attribution_url
      caption
    }
  }
`

const EditMediaModal = ({
  allowAssociations,
  model,
  show,
  onClose,
  onDelete,
  deleteText,
  onSave,
  updating
}) => {
  const [updateImageMutation] = useMutation(UpdateImageQuery)
  const client = useApolloClient()

  const handleSave = (values) => {
    updateImageMutation({
      variables: {
        id: model.id,
        caption: values.caption,
        attribution_text: values.attribution_text,
        attribution_url: values.attribution_url
      }
    }).then(() => {
      client.refetchQueries({ include: ['OrganizationImagesQuery'] })
    })

    if (onSave) {
      onSave(values)
    }
  }

  const formikProps = {
    initialValues: {
      caption: model?.caption || '',
      attribution_text: model?.attribution_text || '',
      attribution_url: model?.attribution_url || ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      caption: Yup.string()
        .max(200, 'Must be 200 characters or less').nullable(),
      attribution_text: Yup.string().nullable(),
      attribution_url: Yup.string().url('Please enter a valid web URL. Must start with http:// or https://.').nullable()
    }),
    onSubmit: values => {
      handleSave(values)
    }
  }

  return (
    <Formik
      {...formikProps}
    >
      {formik => (
        <div className='editMediaModal'>

          <Modal backdrop='static' keyboard={false} show={show} onHide={onClose} dialogClassName='mediaModal-dialog'>
            <Modal.Header className='mediaModal-titleBar'>
              <div className='mediaModal-titleBar__content'>
                <Modal.Title>Update Media</Modal.Title>
                <div className='mediaModal-actions'>
                  <Button bsStyle='primary' className='btn-danger' onClick={onDelete}>{deleteText}</Button>
                  {model && model.uploaded_file &&
                    <Button bsStyle='primary' href={buildImageUploadUrl(model.id, model.uploaded_file)} target='_blank'>Download</Button>}
                  <Button bsStyle='primary' onClick={onClose}>Close</Button>
                  <Button bsStyle='primary' disabled={!(formik.dirty && formik.isValid)} onClick={formik.handleSubmit}>Save &amp; Close</Button>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <MediaForm
                allowAssociations={allowAssociations}
                model={model}
                updating={updating}
              />
            </Modal.Body>
          </Modal>
        </div>)}
    </Formik>
  )
}

EditMediaModal.propTypes = {
  allowAssociations: PropTypes.bool,
  model: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  deleteText: PropTypes.string,
  onSave: PropTypes.func,
  updating: PropTypes.any
}

export default EditMediaModal
