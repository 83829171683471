import { gql } from '@apollo/client'

export const InsertPost = gql`
    mutation InsertPost(
        $post_text: String = "", 
        $feature_type: String,        
        $feature_id: Int,
        $organization_id: Int,
        $publish_start_date: date,
        $publish_end_date: date,
        $is_alert: Boolean = false
        ) {
        insert_posts_one(object: {
            feature_type: $feature_type,
            feature_id: $feature_id,
            organization_id: $organization_id,
            post_text: $post_text,                         
            publish_start_date: $publish_start_date,
            publish_end_date: $publish_end_date
            is_alert: $is_alert,
            is_admin: true,
            visibility: "public",
            post_type: "post"}){
            id
            created_at
            visibility
            post_text
            feature_id
            feature_type
            organization_id
            user_id
            user{
                id
                profile {
                    id
                    name
                    photo_file
                }
            }
        }
    }
`

export default InsertPost
