import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/lib/Alert'
import { useRouteMatch } from 'react-router'
import debugMessage from 'services/Debug'
import toast from 'react-hot-toast'
import { CenteredLoader } from '../../../components/centeredLoader'
import { featureByType } from '../../../models/feature/feature.lang'
import ActionBar from '../../../views/features/list/actionBar/FeaturesActionBar'
import { Mutations, Queries } from '../FeaturesOperations'
import './list.css'
import FeaturesTable from './table/FeaturesTable'
import { useCurrentUser } from 'contexts/CurrentUserContext'

const getQuery = (featureType) => {
  const graphQL = {
    aggregate: `${featureType}_aggregate`,
    name: null,
    query: null
  }

  switch (featureType) {
    case 'areas':
      graphQL.query = Queries.GetAreasByOrganization
      graphQL.name = 'getAreasByOrganization'
      break
    case 'trails':
      graphQL.query = Queries.GetTrailsByOrganization
      graphQL.name = 'getTrailsByOrganization'
      break
    case 'points_of_interest':
    case 'points-of-interest':
      graphQL.query = Queries.GetPointsOfInterestByOrganization
      graphQL.name = 'getPointsOfInterestByOrganization'
      break
    default:
      console.log('Invalid feature type specified.')
  }

  return graphQL
}

const FeaturesList = ({
  handlePageChange,
  onSelectSearchResult,
  organizationId,
  paging
}) => {
  const match = useRouteMatch()
  const me = useCurrentUser()
  const isSuperAdmin = me.roles.find((item) => item.role.name === 'admin')
  const featureType = match.params.feature_type
  const graphQL = getQuery(featureType)
  const [features, setFeatures] = useState([])

  const { error, loading: loadingFeatures } = useQuery(graphQL.query, {
    displayName: graphQL.name,
    fetchPolicy: 'cache-and-network',
    onCompleted (data) {
      let featureData = []

      if (featureType === 'areas') {
        featureData = data.areas
      } else if (featureType === 'trails') {
        featureData = data.trails
      } else if (featureType === 'points_of_interest' || featureType === 'points-of-interest') {
        featureData = data.points_of_interest
      }

      setFeatures(featureData)
    },
    onError () {
      debugMessage(error)
    },
    skip: !organizationId,
    variables: {
      limit: paging.per_page,
      offset: (paging.current_page - 1) * paging.per_page,
      organizationId: organizationId
    }
  })

  const [deleteTrail] = useMutation(

    // TODO: Show a confirmation dialog that lists posts, images, etc. that will be deleted as well.

    Mutations.DeleteTrail, {
      onCompleted (data) {
        const deletedTrailId = data.delete_trails.returning[0].id
        const updatedFeatures = features.filter(trail => deletedTrailId !== trail.id)
        setFeatures(updatedFeatures)
        toast.success('Trail deleted.')
      },
      onError (deleteError) {
        toast.error(deleteError)
        toast.error('The trail could not be deleted.')
      }
    }
  )

  const [updateAreaVisibility] = useMutation(
    Mutations.UpdateVisibilityForArea, {
      onCompleted (data) {
        const updatedArea = data.update_areas.returning[0]
        const updatedFeatures = features.map(area => {
          if (updatedArea.id === area.id) {
            return { ...area, visibility: updatedArea.visibility }
          } else {
            return area
          }
        })
        setFeatures(updatedFeatures)
        toast.success('Area visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  const [updatePOIVisibility] = useMutation(
    Mutations.UpdateVisibilityForPOI, {
      onCompleted (data) {
        const updatedPOI = data.update_points_of_interest.returning[0]
        const updatedFeatures = features.map(poi => {
          if (updatedPOI.id === poi.id) {
            return { ...poi, visibility: updatedPOI.visibility }
          } else {
            return poi
          }
        })
        setFeatures(updatedFeatures)
        toast.success('Point of Interest visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  const [updateTrailVisibility] = useMutation(
    Mutations.UpdateVisibilityForTrail, {
      onCompleted (data) {
        const updatedTrail = data.update_trails.returning[0]
        const updatedFeatures = features.map(trail => {
          if (updatedTrail.id === trail.id) {
            return { ...trail, visibility: updatedTrail.visibility }
          } else {
            return trail
          }
        })
        setFeatures(updatedFeatures)
        toast.success('Trail visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  const handleArchive = (feature) => {
    onVisibilityChange(feature, 'Archive')
  }

  const handleDeleteTrail = (feature) => {
    if (!isSuperAdmin) {
      toast.error('You do not have permission to delete trails.')
      return
    }

    if (window.confirm('Are you sure you want to delete this trail?')) {
      deleteTrail({
        variables: {
          trailId: feature.id
        }
      })
    }
  }

  const handlePublish = (feature) => {
    onVisibilityChange(feature, 'Publish')
  }

  const onVisibilityChange = (feature, option) => {
    if (window.confirm('Are you sure you want to update the visibility of this location?')) {
      const visibility = (() => {
        if (option === 'Archive') {
          return 'Archived'
        } else {
          return 'Published'
        }
      })()

      if (feature.__typename === 'points_of_interest') {
        updatePOIVisibility({
          variables: {
            poiId: feature.id,
            visibility: visibility
          }
        })
      } else if (feature.__typename === 'trails') {
        updateTrailVisibility({
          variables: {
            trailId: feature.id,
            visibility: visibility
          }
        })
      } else if (feature.__typename === 'areas') {
        updateAreaVisibility({
          variables: {
            areaId: feature.id,
            visibility: visibility
          }
        })
      }
    }
  }

  document.title = `${featureByType(featureType).plural} | ${process.env.REACT_APP_PAGE_TITLE}`

  return (
    <div className='featuresList-wrap'>
      <div className='featuresList-actionBar'>
        <ActionBar
          featureType={featureType}
          fetching={loadingFeatures}
          onSelectSearchResult={onSelectSearchResult}
        />
      </div>
      <div className='featuresList-content'>
        <div className='featuresList-table'>
          {loadingFeatures &&
            <div className='featuresList-loader'>
              <CenteredLoader />
            </div>}
          {!loadingFeatures &&
            <div>
              {!!error?.message &&
                <Alert bsStyle='danger'>{error.message}</Alert>}
              <FeaturesTable
                {...{
                  features,
                  featureType,
                  organizationId,
                  paging
                }}
                isSuperAdmin={isSuperAdmin}
                onArchived={handleArchive}
                onDeleteTrail={handleDeleteTrail}
                onEdit={onSelectSearchResult}
                onPageChange={handlePageChange}
                onPublished={handlePublish}
              />
            </div>}
        </div>
      </div>
    </div>
  )
}

FeaturesList.propTypes = {
  handlePageChange: PropTypes.func,
  onSelectSearchResult: PropTypes.func,
  organizationId: PropTypes.number,
  paging: PropTypes.object
}

export default FeaturesList
