import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import PropTypes from 'prop-types'
import React from 'react'

import { types as postTypeOptions, times as postTimeOptions } from '../../../models/post/post.model'
import HelpDropdown from '../../../components/helpDropdown'
import Select from '../../../components/select'

import './postsListHeader.css'

const PostsListHeader = (props) => {
  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '80', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/80-social' }).click()
    }
  }

  const handleWalkthroughVideoSelect = () => {
    if (window.Beacon) {
      const beaconInfo = window.Beacon('info')
      // Organizations
      var messageId = 'a2be7e1f-eb62-4786-84a3-93889e5aeb2c'

      if (beaconInfo && beaconInfo.beaconId !== '8bdf21e5-c4fc-43d1-8bd3-e17a0cf162f0') {
        // Sales
        messageId = '4b13287a-ffdd-45cf-bec8-f08166242eba'
      }

      window.Beacon && window.Beacon('show-message', messageId, {
        force: true
      })
    }
  }

  const queryChange = (query) => {
    props.onQueryChange(query)
  }

  const timeChange = (value) => {
    queryChange({ time: value })
  }

  const typeChange = (value) => {
    queryChange({ postType: value })
  }

  const { query, onNewClick, onClearFilters } = props

  const postsType = query.postType || ''
  const postsTime = query.time || ''

  return (
    <div className='posts-header'>
      <div className='posts-filters'>
        <Glyphicon glyph='filter' />
        <Select
          model={postsType}
          options={postTypeOptions}
          value={query.postType}
          onChange={typeChange}
          id='postsFilter-type'
          local
        />
        <Select
          model={postsTime}
          options={postTimeOptions}
          value={query.time}
          onChange={timeChange}
          id='postsFilter-time'
          local
        />
        <Button bsStyle='link' onClick={onClearFilters}>Reset filters</Button>
      </div>
      <div className='posts-actions'>
        <HelpDropdown
          handleViewHelpSelect={handleViewHelpClick}
          handleWalkthroughVideoSelect={handleWalkthroughVideoSelect}
        />
        &nbsp;
        <Button
          bsStyle='primary'
          onClick={onNewClick}
          className='posts-add shadow'
        >New Post
        </Button>
      </div>
    </div>
  )
}

PostsListHeader.propTypes = {
  query: PropTypes.object,
  onNewClick: PropTypes.func,
  onQueryChange: PropTypes.func,
  onClearFilters: PropTypes.func
}

export default PostsListHeader
