import PropTypes from 'prop-types'
import React from 'react'

import './contentLayout.css'

const ContentLayout = ({ children, className }) => {
  return (
    <div className={`contentLayout ${className}`}>
      {children}
    </div>
  )
}

ContentLayout.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
}

export default ContentLayout
