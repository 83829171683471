import { Link } from 'react-router-dom'
import { Rating } from '@mui/material'
import _ from 'lodash'
import Button from 'react-bootstrap/lib/Button'
import Carousel from 'react-bootstrap/lib/Carousel'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

import { askOuterSpatialAQuestion } from '../../../components/helpScout'
import { buildImageUploadUrl, buildProfileImageUrl } from 'services/Images'
import { CenteredLoader } from '../../../components/centeredLoader'
import { segmentTrack } from '../../../components/segment'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import Comment from './Comment'
import CommentForm from './CommentForm'
import debugMessage from 'services/Debug'
import locationValidatedCheckmarkImage from 'images/location-validated-checkmark.svg'
import placeholderProfileImage from 'images/placeholder-profile.svg'

import './postItem.scss'

const PostItem = (props) => {
  const { post = {} } = props
  const [updating, setUpdating] = useState()
  const comments = post.children || []
  const createdByUser = post.user
  let avatar = createdByUser.profile?.photo_file
    ? buildProfileImageUrl(createdByUser.profile.id, createdByUser.profile.photo_file, 'thumb_square')
    : null
  let createdBy = createdByUser.profile.name
  const likes = props.post.likers || []
  const me = useCurrentUser()
  const isSuperAdmin = me.roles.find((item) => item.role.name === 'admin')
  const liked = !!_.find(
    likes.map((item) => item.user),
    {
      id: me.id
    }
  )
  const organization = useCurrentOrganization()
  let images = []
  const postCreatedAt = moment.utc(post.created_at).tz(organization.time_zone).format('MMMM D, YYYY [at] h:mma')
  const bannerStyle = (() => {
    if (post.is_alert) {
      return 'postItem-banner-alert'
    } else if (post.is_admin) {
      return 'postItem-banner-admin'
    } else if (post.post_type === 'check-in' || post.report_data !== null || post.review_data !== null) {
      return 'postItem-banner-nonPost'
    }

    return null
  })()
  const conditions = (() => {
    if (
      post.report_data &&
      Object.keys(post.report_data).length > 0 &&
      post.report_data.conditions
    ) {
      const conditions = []

      for (const [_, value] of Object.entries(post.report_data.conditions)) {
        conditions.push(value)
      }

      return conditions
    }

    return null
  })()
  const featureName = (() => {
    let name = post.feature_type

    switch (post.feature_type) {
      case 'Area':
        name = post.area_post.area.name
        break
      case 'Outing':
        name = post.outing_post.outing.name
        break
      case 'PointOfInterest':
        name = post.point_of_interest_post.point_of_interest.name
        break
      case 'Trail':
        name = post.trail_post.trail.name
        break
    }

    return name
  })()

  const featureType = (() => {
    let name

    switch (post.feature_type) {
      case 'Area':
        name = 'Area'
        break
      case 'Organization':
        name = 'Organization'
        break
      case 'Outing':
        name = 'Outing'
        break
      case 'PointOfInterest':
        name = post.point_of_interest_post.point_of_interest.point_type

        if (name === null || name === '' || name === 'POI') {
          name = 'Point of Interest'
        }

        break
      case 'Trail':
        name = 'Trail'
        break
    }

    return name
  })()
  const featureTypeUrl = (() => {
    let name = 'areas'

    switch (post.feature_type) {
      case 'PointOfInterest':
        name = 'points-of-interest'
        break
      case 'Trail':
        name = 'trails'
        break
      case 'Outing':
        name = 'outings'
        break
    }

    return name
  })()
  const parentArea = (() => {
    let parentArea = null

    switch (post.feature_type) {
      case 'Outing':
        // TODO: We need to support multiple parent areas for outings.
        if (post.outing_post.outing.parent_areas && post.outing_post.outing.parent_areas.length > 0) {
          parentArea = post.outing_post.outing.parent_areas[0].area
        }
        break
      case 'PointOfInterest':
        parentArea = post.point_of_interest_post.point_of_interest.parent_area
        break
      case 'Trail':
        parentArea = post.trail_post.trail.parent_area
        break
    }

    return parentArea
  })()
  const postType = (() => {
    let text = ''

    if (post.is_alert) {
      text = 'Official Alert'
    } else if (post.is_admin) {
      text = 'Official Post'
    } else if (post.post_type === 'check-in') {
      text = 'Check-In'
    } else if (post.report_data !== null && Object.keys(post.report_data).length > 0) {
      text = 'Report'
    } else if (post.review_data !== null && Object.keys(post.review_data).length > 0) {
      text = 'Review'
    }

    if (post.challenge_response?.task?.challenge?.name) {
      text += ` for ${post.challenge_response.task.challenge.name}`
    }

    return text === '' ? null : text
  })()

  const deleteClick = () => {
    const { deletePost, post } = props
    const msg = post.challenge_response?.task?.challenge?.name ? 'This post is associated with a challenge response. If you delete it, you\'ll permanently delete the challenge response along with the post and all of its likes and comments. Are you sure you want to delete it?' : 'If you delete this post, you\'ll also delete all of its likes and comments. Are you sure you want to permanently delete it?'
    const conf = window.confirm(msg)

    if (conf) {
      setUpdating(true)
      deletePost({ variables: { post_id: post.id } })
        .then(
          () => {
            segmentTrack(
              'post_deleted',
              {
                post_id: post.id,
                entity_id: post.feature_id,
                entity_type: post.feature_type
              },
              me
            )
          },
          (error) => {
            debugMessage(error)
          }
        )
        .finally(() => {
          setUpdating(false)
        })
    }
  }

  const destroyComment = (commentId) => {
    const { deletePost, post } = props
    const conf = window.confirm('Are you sure you want to permanently delete this comment?')

    if (conf) {
      setUpdating(true)
      deletePost({ variables: { post_id: commentId } })
        .then(
          () => {
            toast.success('Comment deleted successfully.')
            segmentTrack(
              'comment_deleted',
              {
                entity_id: post.feature_id,
                entity_type: post.feature_type,
                parent_post_id: post.id,
                post_id: commentId
              },
              me
            )
          },
          (error) => {
            debugMessage(error)
          }
        )
        .finally(() => {
          setUpdating(false)
        })
    }
  }

  const editClick = () => {
    const { post } = props

    props.onEditClick(post)
  }

  const likeClick = () => {
    const { like, post, unlike } = props
    let segmentEventName = 'post_liked'
    let segmentEventProperties = {
      post_id: post.id,
      entity_id: post.feature?.id,
      entity_type: post.feature?.class_name
    }

    if (liked) {
      unlike(post.id, me)
      segmentEventName = 'post_unliked'
    } else {
      like(post.id, me)
      segmentEventProperties = {
        ...segmentEventProperties,
        post_user_id: post.user.id,
        post_user_name: post.user.profile.name
      }
    }

    segmentTrack(segmentEventName, segmentEventProperties, me)
  }

  const reportClick = () => {
    const { post } = props

    askOuterSpatialAQuestion('Report a Post', `I would like to report the post with id of ${post.id}.`, me)
  }

  const submitComment = (model) => {
    const { createComment } = props

    return new Promise((resolve, reject) => {
      createComment({ ...model, parent_id: post.id }).then(
        (response) => {
          resolve()
          toast.success('Comment created successfully.')
          const newPost = { parent: post, id: post.id } // TODO : actually create the comment and get reutrn

          segmentTrack(
            'comment_created',
            {
              entity_id: newPost.parent.feature_id,
              entity_type: newPost.parent.feature_type,
              parent_post_id: newPost.parent.id,
              post_id: newPost.id
            },
            me
          )
        },
        (error) => {
          debugMessage(error)
          reject(error)
        }
      )
    })
  }

  // Images
  if (post.image_attachments) {
    images = post.image_attachments.map((image) => {
      const imageData = { ...image.image }
      imageData.attachment_id = image.id // Include the image attachment ID so that we can send this with destroy requests
      return imageData
    })
  }

  // Attribution
  if (post.is_admin && organization) {
    if (organization.id && post.organization_id && post.organization && organization.id !== post.organization_id) {
      createdBy = post.organization.name

      if (post.organization.logo_image) {
        if (post.organization.logo_image.id && post.organization.logo_image.uploaded_file) {
          avatar = buildImageUploadUrl(
            post.organization.logo_image.id,
            post.organization.logo_image.uploaded_file,
            'medium_square'
          )
        } else {
          avatar = null
        }
      } else {
        avatar = null
      }
    } else if (organization.name) {
      createdBy = organization.name

      if (organization.logo_image) {
        if (organization.logo_image.id && organization.logo_image.uploaded_file) {
          avatar = buildImageUploadUrl(
            organization.logo_image.id,
            organization.logo_image.uploaded_file,
            'medium_square'
          )
        } else {
          avatar = null
        }
      } else {
        avatar = null
      }
    }
  }

  return (
    <div className='postItem'>
      {updating && <CenteredLoader overlay />}
      {(postType !== null) && (
        <div className={`postItem-banner${bannerStyle ? ' ' + bannerStyle : ''}`}>
          <p>{postType}</p>
        </div>
      )}
      <div className='postItem-header'>
        <div
          alt='Profile Image'
          className='postItem-header__avatar'
          style={{ backgroundImage: `url(${avatar || placeholderProfileImage})` }}
        />
        <div className='postItem-header__attribution'>
          <h3>{createdBy}</h3>
          <p>
            {post.feature_type && post.feature_type !== 'Organization' && (
              <span>
                <Link to={`/${organization.id}/locations/${featureTypeUrl}/${post.feature_id}`}>
                  {featureName}
                </Link>
                {(post.post_type === 'check-in' && post.location_validation_bypassed === false) && (
                  <>
                    {' '}
                    <img
                      alt='Location Validated Checkmark'
                      src={locationValidatedCheckmarkImage}
                      style={{ height: '15px', marginBottom: '2px', width: '12px' }}
                    />
                  </>
                )}
              </span>
            )}
          </p>
          <p>
            {featureType}{parentArea && (
              <>
                {' '}
                in{' '}
                <Link to={`/${organization.id}/locations/areas/${parentArea.id}`}>
                  {parentArea.name}
                </Link>
              </>
            )}
          </p>
        </div>
        <div className='postItem-header__actions'>
          {post.is_admin && (
            <DropdownButton
              bsSize='small'
              bsStyle='link'
              id='actions'
              noCaret
              pullRight
              title={<Glyphicon glyph='option-horizontal' />}
            >
              <MenuItem eventKey='edit' onClick={editClick}>
                Edit
              </MenuItem>
              <MenuItem eventKey='delete' onClick={deleteClick}>
                Delete
              </MenuItem>
            </DropdownButton>
          )}
          {!post.is_admin && (
            <DropdownButton
              bsSize='small'
              bsStyle='link'
              id='actions'
              noCaret
              pullRight
              title={<Glyphicon glyph='option-horizontal' />}
            >
              {!isSuperAdmin && (
                <MenuItem eventKey='report' onClick={reportClick}>
                  Report
                </MenuItem>
              )}
              {isSuperAdmin && (
                <MenuItem eventKey='delete' onClick={deleteClick}>
                  <Glyphicon glyph='star' />
                  {' '}
                  Delete
                </MenuItem>
              )}
            </DropdownButton>
          )}
        </div>
      </div>
      <div className='postItem-content'>
        {images && images.length > 1 && (
          <Carousel interval={null}>
            {images.map((image, index) => {
              let src = null

              if (image?.id) {
                if (image.uploaded_file) {
                  src = buildImageUploadUrl(image.id, image.uploaded_file, 'medium')
                }
              }

              return (
                <Carousel.Item key={`${image.id}${index}`}>
                  <img src={src} alt={image.caption} />
                </Carousel.Item>
              )
            })}
          </Carousel>
        )}
        {images && images.length === 1 && (
          <div className='postItem-photo'>
            {images.map((image, index) => {
              let src = null

              if (image?.id) {
                if (image.uploaded_file) {
                  src = buildImageUploadUrl(image.id, image.uploaded_file, 'medium')
                }
              }

              return <img key={index} src={src} alt={image.caption} />
            })}
          </div>
        )}
        {(post && post.review_data && typeof post.review_data.rating === 'number') && (
          <div className={`postItem-reviewRating${post && post.post_text && post.post_text !== '<p></p>' ? ' postItem-reviewRating-noBottomMargin' : ''}`}>
            <Rating
              disabled
              size='large'
              value={post.review_data.rating}
            />
          </div>
        )}
        {(post && post.report_data && Object.keys(post.report_data).length > 0) && (
          <div className='postItem-reportData'>
            {post.report_data.datetime && (
              <p>
                <strong>Date/Time:</strong>
                {' '}
                {moment.utc(post.report_data.datetime).tz(organization.time_zone).format('MMMM D, YYYY [at] h:mma')}
              </p>
            )}
            {post.report_data.point?.latitude && post.report_data.point?.longitude && (
              <p>
                <strong>Location:</strong>
                {' '}
                {post.report_data.point.latitude}
                {' '}
                {post.report_data.point.longitude}
              </p>
            )}
            {post.report_data.what3words && (
              <p>
                <strong>what3Words:</strong>{' '}
                <a href={`https://what3words.com/${post.report_data.what3words}`}>
                  {post.report_data.what3words}
                </a>
              </p>
            )}
            {post.report_data.activity?.label && (
              <p>
                <strong>Activity:</strong>
                {' '}
                {post.report_data.activity.label}
              </p>
            )}
            {conditions && conditions.length > 0 && (
              <p>
                <strong>Conditions:</strong> {conditions.join(', ')}
              </p>
            )}
            {post.report_data.incidents && post.report_data.incidents.length > 0 && (
              <p>
                <strong>Incident:</strong> {post.report_data.incidents[0]}
              </p>
            )}
            {post.report_data.hazards && post.report_data.hazards.length > 0 && (
              <p>
                <strong>Hazard:</strong> {post.report_data.hazards[0]}
              </p>
            )}
          </div>
        )}
        {post && post.post_text && typeof post.post_text === 'string' && post.post_text.length && post.post_text !== '<p></p>' && (
          <div
            className={'postItem-textContent ' + (post.post_text.length < 100 ? 'shortText' : '')}
            dangerouslySetInnerHTML={{ __html: post.post_text }}
          />
        )}
      </div>
      <div className='postItem-actions'>
        <p>{postCreatedAt}</p>
        <Button bsStyle='link' className={liked ? 'like-button-active' : ''} onClick={likeClick}>
          Like
          {likes.length > 0 && ` (${likes.length})`}
        </Button>
      </div>
      <div className='postItem-comments'>
        <div className='postItem-comments__list'>
          {comments.map((comment) => {
            return <Comment comment={comment} key={comment.id} onDestroy={destroyComment} organization={organization} />
          })}
        </div>
        <div className='postItem-comments__form'>
          <CommentForm onSubmit={submitComment} />
        </div>
      </div>
    </div>
  )
}

PostItem.propTypes = {
  createComment: PropTypes.func,
  deletePost: PropTypes.func,
  like: PropTypes.func,
  onEditClick: PropTypes.func,
  post: PropTypes.object,
  unlike: PropTypes.func
}

export default PostItem
