import Alert from 'react-bootstrap/lib/Alert'
import { gql, useQuery, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useHistory, useRouteMatch } from 'react-router'

import ActionBar from './documentsActionBar/DocumentsActionBar'
import { CenteredLoader } from '../../../components/centeredLoader'
import defaultDocument from '../../../models/document/document.model'
import DocumentsTable from './documentsTable/DocumentsTable'
import { featureByType } from 'models/feature/feature.lang'
import Uploader from '../../../components/uploader'
import { useCurrentOrganization } from 'contexts/OrganizationContext'

import './documentsList.css'

const defaultQuery = defaultDocument.documents.query

const DeleteMediaFile = gql`
  mutation DeleteMediaFile($id: Int!) {
    delete_media_files_by_pk(id: $id) {
      created_at
      id
    }
    delete_media_file_attachments(where:{media_file_id:{_eq:$id}}) {
      affected_rows
    }
  }
`

const DocumentsListQuery = gql`
  query DocumentsList($id: Int!, $limit: Int, $offset: Int, $order_by: [organization_media_file_attachments_order_by!] = {media_file: {name: asc}}, $where: organization_media_file_attachments_bool_exp = {}) {
    organizations_by_pk(id: $id) {
      media_file_attachments(order_by: $order_by) {
        media_file {
          id
          name
          uploaded_file
        }
      }
    }
  }
`

const FeatureDocumentsListQuery = gql`
  query FeatureDocumentsList($feature_type: String!, $feature_id: Int!, $order_by: [media_file_attachments_order_by!] = {media_file: {name: asc}}, $where: media_file_attachments_bool_exp = {}) {
    media_file_attachments(order_by:$order_by,where:{attacheable_type:{_eq:$feature_type},attacheable_id:{_eq:$feature_id}}) {
      media_file {
        id
        created_at
        name
        uploaded_file
      }
    }
  }
`

const DocumentsList = (props) => {
  const history = useHistory()
  const match = useRouteMatch()
  const { feature, paging } = props
  const [activeUploads] = useState([])
  const [error, setError] = useState()
  const [showUploader, setShowUploader] = useState(false)
  const [query, setQuery] = useState(defaultQuery)

  const organization = useCurrentOrganization()
  const listQuery = feature ? FeatureDocumentsListQuery : DocumentsListQuery
  const [deleteMediaFile] = useMutation(DeleteMediaFile, { refetchQueries: [listQuery] })
  const listVariables = feature ? {
    feature_id: feature.id,
    feature_type: featureByType(feature.__typename || feature.table_name).classSingular
  } : { id: organization.id }
  const { data, loading } = useQuery(listQuery, { variables: listVariables })
  let documents = null

  if (data) {
    if (feature) {
      documents = data.media_file_attachments
    } else {
      documents = data.organizations_by_pk.media_file_attachments
    }
  }

  useEffect(() => {
    document.title = `Documents | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  const handleUploadErrors = (errors) => {
    if (!errors.length) {
      setError(null)
    } else {
      setError(errors[0])
    }
  }

  const deleteDocument = (model) => {
    const conf = window.confirm('Are you sure you want to permanently delete this document?')

    if (conf) {
      deleteMediaFile({ variables: { id: model.id } }).then(() => {
        toast.success('Deleted document successfully.')
      })
    }
  }

  const queryChange = (value, resetPage = false) => {
    if (!resetPage) {
      resetPage = !value.page
    }

    value = { ...defaultQuery, ...query, ...value }
    value.page = resetPage ? 1 : value.page

    setQuery({
      ...query,
      ...value
    })
  }

  const handlePageChange = (page) => {
    queryChange({ page })
  }

  const handleUploadSuccess = (documentId) => {
    const viewLink = `${match.url}/${documentId}`
    history.push(viewLink)
  }

  const openDocument = (document) => {
    history.push(`${match.url}/${document.id}`)
  }

  return (
    <div className='documentsList-wrap'>
      <div className='documentsList-actionBar'>
        <ActionBar
          feature={feature}
          fetching={loading}
          onNewClick={() => { setShowUploader(true) }}
        />
      </div>
      <div className='documentsList-content'>
        <div className='documentsList-table'>
          {loading &&
            <div className='documentsList-loader'>
              <CenteredLoader />
            </div>}
          {data &&
            <div>
              {error && <Alert bsStyle='danger'>{error}</Alert>}
              {error && error.message && <Alert bsStyle='danger'>{error.message}</Alert>}
              <Uploader
                allowClose
                feature={feature}
                filetype='Document'
                onError={handleUploadErrors}
                onHide={() => { setShowUploader(false) }}
                onUploadSuccess={handleUploadSuccess}
                show={showUploader}
              />
              <DocumentsTable
                {...{
                  activeUploads,
                  documents: documents.map(item => item.media_file),
                  feature,
                  paging,
                  query
                }}
                onDelete={deleteDocument}
                onEdit={openDocument}
                onChangeQuery={queryChange}
                onPageChange={handlePageChange}
              />
            </div>}
        </div>
      </div>
    </div>
  )
}

DocumentsList.propTypes = {
  feature: PropTypes.object,
  paging: PropTypes.object
}

export default DocumentsList
