import { gql } from '@apollo/client'

// TODO: Also filter out "private" posts...

export const FeaturePostsList = gql`
  query FeaturePostsList($post_where: posts_bool_exp) {
    posts(
      order_by: {
        created_at: desc
      },
      where: $post_where
    ) {
      area_post {
        area {
          id
          name
        }
        id
      }
      challenge_response: public_challenge_response {
        id
        task {
          challenge {
            id
            name
            visibility
          }
          id
        }
      }
      created_at
      id
      feature_type
      feature_id
      image_attachments(order_by:{position:asc}) {
        id
        position
        image {
          id
          uploaded_file
        }
      }
      is_admin
      is_alert
      likers {
        user {
          id          
          profile{
            id
            photo_file
            name
          }
        }
      }
      location_validation_bypassed
      outing_post {
        id
        outing {
          id
          name
          parent_areas: outing_areas(order_by: { area: { name: asc }}) {
            area {
              id
              name
            }
            id
          }
        }
      }
      parent {
        id
      }
      point_of_interest_post {
        id
        point_of_interest {
          id
          name
          parent_area: area {
            id
            name
          }
          point_type
        }
      }
      post_text
      post_type
      product_update_platform
      product_update_title
      report_data
      review_data
      trail_post {
        id
        trail {
          id
          name
          parent_area: area {
            id
            name
          }
        }
      }
      user {        
        id
        profile {
          id
          photo_file
          name
        }
      }
    }
  }
`
