const paperMap = {
  paperMap: {
    error: null,
    fetched: false,
    fetching: false,
    model: {
      name: '',
      description: '',
      config: {},
      user_id: ''
    },
    updating: false
  },
  paperMaps: {
    collection: [],
    error: null,
    fetched: false,
    fetching: false,
    paging: {
      current_page: 1,
      total_pages: 0
    },
    query: {
      direction: 'asc',
      order: 'name',
      page: 1
    },
    updating: ''
  }
}

export default paperMap
