import t from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import { buildImageUploadUrl } from 'services/Images'

const renderImageUrl = (image) => {
  let imageUrl = null

  if (image?.id) {
    if (image.uploaded_file) {
      imageUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'small')
    }
  }

  return imageUrl
}

const PostFormImage = (props) => {
  const { image } = props
  const imageUrl = renderImageUrl(image)
  const handleRemoveClick = () => {
    props.onRemoveClick(image)
  }

  return (
    <div
      className='postForm-image'
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className='postForm-image__actions'>
        <Button bsStyle='link' bsSize='small' onClick={handleRemoveClick}>
          <Glyphicon glyph='remove' />
        </Button>
      </div>
    </div>
  )
}

PostFormImage.propTypes = {
  image: t.object,
  onRemoveClick: t.func
}

export default PostFormImage
