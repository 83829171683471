import { format } from 'date-fns'
import { Link, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useRouteMatch } from 'react-router'
import Alert from 'react-bootstrap/lib/Alert'
import Button from 'react-bootstrap/lib/Button'
import Col from 'react-bootstrap/lib/Col'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import fetch from 'node-fetch'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import Grid from 'react-bootstrap/lib/Grid'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Modal from 'react-bootstrap/lib/Modal'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Row from 'react-bootstrap/lib/Row'
import Table from 'react-bootstrap/lib/Table'

import { askOuterSpatialAQuestion } from '../../../components/helpScout'
import { CenteredLoader } from '../../../components/centeredLoader'
import { PageContent, PageLayout } from '../../../components/pageLayout'
import { Queries } from '../ChallengesOperations'
import { timezones as timezoneOptions } from '../../../models/time.model'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import { useAuth } from 'contexts/AuthContext'
import './challenges.css'

const defaultProps = {}
const propTypes = {
  onDeleteClick: PropTypes.func
}

const ChallengesList = ({
  onDeleteClick
}) => {
  const { accessToken } = useAuth()
  const currentUser = useCurrentUser()
  const history = useHistory()
  let isOrganizationAdmin = false
  const linkStyle = {
    color: '#41A7D0',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
  const match = useRouteMatch()
  const organization = useCurrentOrganization()

  organization.memberships.forEach((membership) => {
    if ((membership.user_email.email === currentUser.email) && (membership.role === 'admin')) {
      isOrganizationAdmin = true
    }
  })

  const { data, error, loading } = useQuery(Queries.GetChallengesByOrganizationId, {
    variables: {
      organizationId: organization.id
    }
  })
  const [downloading, setDownloading] = useState(false)
  const [showNewModal, setShowNewModal] = useState(false)
  const timezoneLabel = timezoneOptions.filter(({ value }) => value === organization.time_zone)[0].label

  const getDateTimeString = (date) => {
    const d = new Date(new Date(`${date}Z`).toLocaleString('en-US', { timeZone: organization.time_zone }))
    return format(d, "LLL d, yyyy, h':'mmaaa")
  }
  const goToOrgProfile = () => {
    history.push(`/${organization.id}/organization/profile/overview`)
  }
  const handleEditChallenge = (challenge) => {
    history.push(`${match.url}/${challenge.id}`)
  }
  const handleNewChallengeClick = () => {
    setShowNewModal(true)
  }
  const handleSignUpClick = () => {
    if (organization.license_tier === 'free') {
      history.push('./organization/billing')
    } else {
      askOuterSpatialAQuestion(
        'Publish Organization Into a Community',
        null,
        currentUser
      )
    }
  }
  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '62', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/62-creating-challenges' }).click()
    }
  }
  const isSuperAdmin = (user) => {
    return user.roles.find((item) => { return item.role.name === 'admin' })
  }
  const onDownloadEnrollments = (challenge) => {
    setDownloading(true)
    fetch(`${process.env.REACT_APP_API_SERVICE_URL}/challenges/enrollments`, {
      body: JSON.stringify({
        challenge_id: challenge.id
      }),
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      method: 'post'
    })
      .then(response => response.blob())
      .then(response => {
        const a = document.createElement('a')
        const blob = new window.Blob([
          response
        ], {
          type: 'application/csv'
        })
        const downloadUrl = URL.createObjectURL(blob)
        const name = `organization-${organization.id}_challenge-${challenge.id}_participants`

        a.download = `${name}.csv`
        a.href = downloadUrl
        document.body.appendChild(a)
        a.click()
        setDownloading(false)
      })
  }
  const onDownloadResponses = (challenge) => {
    setDownloading(true)
    fetch(`${process.env.REACT_APP_API_SERVICE_URL}/challenges/responses`, {
      body: JSON.stringify({
        challenge_id: challenge.id
      }),
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      method: 'post'
    })
      .then(response => response.blob())
      .then(response => {
        const a = document.createElement('a')
        const blob = new window.Blob([
          response
        ], {
          type: 'application/csv'
        })
        const downloadUrl = URL.createObjectURL(blob)
        const name = `organization-${organization.id}_challenge-${challenge.id}_responses`

        a.download = `${name}.csv`
        a.href = downloadUrl
        document.body.appendChild(a)
        a.click()
        setDownloading(false)
      })
  }

  document.title = `Challenges | ${process.env.REACT_APP_PAGE_TITLE}`

  return (
    <div className='challenges'>
      {organization && organization.license_tier === 'free' &&
        <div className='empty-message text-center'>
          {(!isOrganizationAdmin && !isSuperAdmin) && (
            <h3>Challenges are not available because your organization is on the Free plan.</h3>
          )}
          {(isOrganizationAdmin || isSuperAdmin) && (
            <h3>
              Challenges are not available because your organization is on the Free plan.
              <br />
              <span onClick={handleSignUpClick} style={linkStyle}>Sign up for the Team plan</span> to access Challenges now.
            </h3>
          )}
        </div>}
      {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length === 0 &&
        <Panel>
          <Panel.Body>
            <div className='empty-message text-center'>
              <h3>
                Challenges are not available because your organization has not been added to a community.
                <br />
                <span onClick={handleSignUpClick} style={linkStyle}>Reach out to customer success</span> to publish your organization into its first community.
              </h3>
            </div>
          </Panel.Body>
        </Panel>}
      {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length > 0 &&
        <span>
          <div className='challenges-titleBar'>
            <div className='challenges-actions'>
              {!loading && (
                <Button
                  bsStyle='primary'
                  className='shadow'
                  onClick={handleNewChallengeClick}
                >
                  New Challenge
                </Button>)}
              <Button
                bsStyle='primary'
                className='shadow'
                onClick={handleViewHelpClick}
              >
                View Help
              </Button>
            </div>
          </div>
          <div className='challenges-list'>
            <PageLayout>
              <PageContent>
                {loading && (
                  <CenteredLoader />
                )}
                {!loading && error && error.message &&
                  <Panel>
                    <Panel.Body>
                      <Alert bsStyle='danger'>{error.message}</Alert>
                    </Panel.Body>
                  </Panel>}
                {!loading && (!data || !data.challenges || !data.challenges.length) &&
                  <Panel>
                    <Panel.Body>
                      <h3>No challenges available.</h3>
                    </Panel.Body>
                  </Panel>}
                {!loading && (data && data.challenges && data.challenges.length > 0) &&
                  <div>
                    <Panel>
                      <Table striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Participants</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {(downloading || loading) &&
                            <CenteredLoader overlay />}
                          {data && data.challenges && data.challenges.length > 0 &&
                            data.challenges.map((challenge, index) => {
                              const type = challenge.tasks.find(task => task.task_type === 'Questionnaire') ? 'Survey' : 'Check-in'

                              return (
                                <tr key={index}>
                                  <td>
                                    <Link to={`${match.url}/${challenge.id}`}>{challenge.name}</Link>
                                  </td>
                                  <td>{type}</td>
                                  {(challenge.start_date) &&
                                    <td>{getDateTimeString(challenge.start_date)}</td>}
                                  {(!challenge.start_date) &&
                                    <td>None</td>}
                                  {(challenge.end_date) &&
                                    <td>{getDateTimeString(challenge.end_date)}</td>}
                                  {(!challenge.end_date) &&
                                    <td>None</td>}
                                  <td>{challenge.enrollments_count.aggregate.count}</td>
                                  <td className='challenge--actions'>
                                    <DropdownButton title='Actions' id='actions' bsSize='small' pullRight>
                                      <MenuItem eventKey='downloadEnrollments' onClick={() => onDownloadEnrollments(challenge)}>Download Participants</MenuItem>
                                      <MenuItem eventKey='downloadResponses' onClick={() => onDownloadResponses(challenge)}>Download Responses</MenuItem>
                                      <MenuItem
                                        eventKey='edit'
                                        key='edit'
                                        onClick={() => handleEditChallenge(challenge)}
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        eventKey='delete'
                                        key='delete'
                                        onClick={() => onDeleteClick(challenge, false)}
                                      >
                                        Delete
                                      </MenuItem>
                                    </DropdownButton>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </Panel>
                    <p style={{ marginTop: '6px' }}>
                      Note: The dates/times displayed above are in the <b>{timezoneLabel}</b> Time Zone assigned to your organization. You can change this in your <a className='btn-link' onClick={goToOrgProfile}>organization settings</a>.
                    </p>
                  </div>}
              </PageContent>
            </PageLayout>
            <Modal
              enforceFocus={false}
              onHide={() => setShowNewModal(false)}
              show={showNewModal}
            >
              <Modal.Header>
                <Modal.Title>Create a Challenge</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Grid
                  style={{
                    padding: '0',
                    width: '100%'
                  }}
                >
                  <Row>
                    <Col sm={12}>
                      <p>What kind of challenge would you like to create?</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <Panel
                        onClick={() => history.push(`${match.url}/new`)}
                        style={{
                          cursor: 'pointer',
                          textAlign: 'center'
                        }}
                      >
                        <Panel.Body>
                          <Glyphicon glyph='map-marker' />
                          <br />
                          <strong>Check-In Challenge</strong>
                          <br />
                          Get Started Now
                        </Panel.Body>
                      </Panel>
                    </Col>
                    <Col sm={4}>
                      <Panel
                        onClick={() => history.push(`${match.url}/new`)}
                        style={{
                          cursor: 'pointer',
                          textAlign: 'center'
                        }}
                      >
                        <Panel.Body>
                          <Glyphicon glyph='map-marker' />
                          <br />
                          <strong>QR Code Challenge</strong>
                          <br />
                          Get Started Now
                        </Panel.Body>
                      </Panel>
                    </Col>
                    <Col sm={4}>
                      <Panel
                        onClick={() => {
                          askOuterSpatialAQuestion(
                            'Create a Survey Challenge',
                            'I would like to create a survey challenge.',
                            currentUser
                          )
                        }}
                      >
                        <Panel.Body
                          style={{
                            cursor: 'pointer',
                            textAlign: 'center'
                          }}
                        >
                          <Glyphicon glyph='question-sign' />
                          <br />
                          <strong>Survey Challenge</strong>
                          <br />
                          Contact Customer Success
                        </Panel.Body>
                      </Panel>
                    </Col>
                  </Row>
                </Grid>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  bsStyle='primary'
                  onClick={() => setShowNewModal(false)}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </span>}
    </div>
  )
}

ChallengesList.propTypes = propTypes
ChallengesList.defaultProps = defaultProps

export default ChallengesList
