import { Field } from 'formik'
import { HelpBlock } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Queries } from '../../FeatureOperations'
import Editor from '../../../../../components/editorWithHooks'
import FeatureSeasons from './Feature.Seasons'
import FeatureTagSet from '../../../../../components/featureTagSet'

const InheritedTags = ({ organizationTags = [], labelType, tags = [] }) => {
  let organizationTagNodes = null
  let tagNodes = null

  if (tags && tags?.length > 0) {
    tagNodes = renderTags(tags)
  }

  if (organizationTags && organizationTags?.length > 0) {
    organizationTagNodes = renderTags(organizationTags)
  }

  if ((!organizationTagNodes || !organizationTagNodes.length) && (!tagNodes || !tagNodes.length)) {
    return null
  }

  return (
    <>
      {!!organizationTags?.length &&
        <span className='help-block'>
          {organizationTagNodes}
          &nbsp;automatically included from the organization.
          {tags.length === 0 && <>&nbsp;Add additional {labelType} values below.</>}
        </span>}
      {!!tags?.length &&
        <span className='help-block'>
          {tagNodes}
          &nbsp;automatically included from trail segments.{labelType === 'seasonality' && tags.length === 4 ? '' : ` Add additional ${labelType} values below.`}
        </span>}
    </>
  )
}

InheritedTags.propTypes = {
  labelType: PropTypes.string,
  organizationTags: PropTypes.array,
  tags: PropTypes.array
}

const PartialTags = ({ labelType, tags = [] }) => {
  // Return early and return null to prevent rendering
  if ((!tags || tags === undefined || tags === null || tags?.length === 0)) {
    return null
  }

  const tagNodes = renderTags(tags)

  return (
    <>
      {!!tags?.length &&
        <span className='help-block'>
          {tagNodes}
          &nbsp;available on a subset of the trail segments. "Partial" tags are called out for visitors. You can force them to show up as "full" tags by adding them manually below.
        </span>}
    </>
  )
}

PartialTags.propTypes = {
  labelType: PropTypes.string,
  tags: PropTypes.array
}

const diff = (a, b) => a.filter((v) => !b.includes(v))

const handleSeasonChange = (seasons, onSeasonChange) => {
  let newSeasons = seasons

  if (newSeasons.length === 4) {
    newSeasons = ['year_round']
  }

  if (onSeasonChange) {
    onSeasonChange(newSeasons)
  }
}

const renderTags = (tags) => {
  var output = []
  const numTags = tags.length
  tags
    // Titlecase the tag and wrap it with HTML tag
    .map((tag, i) => <span className='mark' key={i}>{toTitleCase(tag)}</span>)
    // Comma separated tags with an `and` instead of a `,` before the last item
    .forEach((tag, i) => {
      // PREFIX: 2+ tags and this is the last one, prefix with `and `
      if (numTags > 1 && i === numTags - 1) output.push('and ')

      output.push(tag)

      // All but the last tag
      if (i < numTags - 1) {
        // 3+ tags, append a comma
        if (numTags > 2) output.push(',')
        // 2+ tags, append a space
        if (numTags > 1) output.push(' ')
      }
    })

  return output
}

const toTitleCase = (str) => {
  if (!str) return str
  return str.replace(/_/g, ' ').replace(/\b\S/g, t => t.toUpperCase())
}

const ActivitiesAccessibilityTrails = ({ feature, onAccessibilityChange, onAllowedAccessChange, onAllowedActivitiesChange, onRulesAndRegulationsChange, onSeasonChange, organizationTags }) => {
  const [trailTags, setTrailTags] = useState({})
  const [loadingTrailTags, setLoadingTrailTags] = useState(true)

  useQuery(Queries.GetTrailTags, {
    onCompleted: (data) => {
      if (data?.trails[0]) {
        setTrailTags(data.trails[0])
        setLoadingTrailTags(false)
      }
    },
    skip: isNaN(feature.id),
    variables: {
      trailId: feature.id
    }
  })

  if (loadingTrailTags) {
    return null
  } else {
    const allTagsAccessibility = trailTags.all_tags_accessibility.map(({ key }) => key)
    const allTagsActivities = trailTags.all_tags_activities.map(({ key }) => key)
    const allTagsAllowedAccess = trailTags.all_tags_allowed_access.map(({ key }) => key)
    const allTagsRulesAndRegulation = trailTags.all_tags_rules_and_regulation.map(({ key }) => key)
    const allTagsSeasonality = trailTags.all_tags_seasonality.map(({ key }) => key)
    const everySegmentTagsAccessibility = trailTags.every_segment_tags_accessibility.map(({ key }) => key)
    const everySegmentTagsAllowedAccess = trailTags.every_segment_tags_allowed_access.map(({ key }) => key)
    const everySegmentTagsActivities = trailTags.every_segment_tags_activities.map(({ key }) => key)
    const everySegmentTagsSeasonality = trailTags.every_segment_tags_seasonality.map(({ key }) => key)
    const everySegmentTagsRulesAndRegulation = trailTags.every_segment_tags_rules_and_regulation.map(({ key }) => key)
    const someSegmentsTagsAccessibility = trailTags.some_segments_tags_accessibility.map(({ key }) => key)
    const someSegmentsTagsAllowedAccess = trailTags.some_segments_tags_allowed_access.map(({ key }) => key)
    const someSegmentsTagsActivities = trailTags.some_segments_tags_activities.map(({ key }) => key)
    const someSegmentsTagsRulesAndRegulation = trailTags.some_segments_tags_rules_and_regulation.map(({ key }) => key)
    const someSegmentsTagsSeasonality = trailTags.some_segments_tags_seasonality.map(({ key }) => key)

    return (
      <div>
        <label className='control-label'>Seasons of Availability</label>
        <InheritedTags
          labelType='seasonality'
          organizationTags={organizationTags.filter(t => t.tag_descriptor.category.name === 'Seasonality').map(t => t.key)}
          tags={everySegmentTagsSeasonality}
        />
        <PartialTags
          labelType='seasonality'
          tags={someSegmentsTagsSeasonality}
        />
        <FeatureSeasons
          onSeasonChange={(seasons) => handleSeasonChange(seasons, onSeasonChange)}
          selectedSeasons={diff(allTagsSeasonality, everySegmentTagsSeasonality)}
          trailSegmentSeasons={everySegmentTagsSeasonality.concat(
            organizationTags.filter(t => t.tag_descriptor.category.name === 'Seasonality').map(t => t.key)
          )}
        />
        <div className='form-group'>
          <label className='control-label'>Allowed Access</label>
          <InheritedTags
            labelType='allowed access'
            organizationTags={organizationTags.filter(t => t.tag_descriptor.category.name === 'Allowed Access').map(t => t.key)}
            tags={everySegmentTagsAllowedAccess}
          />
          <PartialTags
            labelType='allowed access'
            tags={someSegmentsTagsAllowedAccess}
          />
          <FeatureTagSet
            categoryName='Allowed Access'
            excludedTagKeys={everySegmentTagsAllowedAccess.concat(
              ...organizationTags.map(t => t.key)
            )}
            featureType={feature.class_name}
            onChange={onAllowedAccessChange}
            placeholder='Who can access this trail?'
            selectedTags={diff(allTagsAllowedAccess, everySegmentTagsAllowedAccess)}
          />
        </div>
        <div className='form-group'>
          <label className='control-label'>Accessibility</label>
          <InheritedTags
            labelType='accessibility'
            organizationTags={organizationTags.filter(t => t.tag_descriptor.category.name === 'Accessibility').map(t => t.key)}
            tags={everySegmentTagsAccessibility}
          />
          <PartialTags
            labelType='allowed access'
            tags={someSegmentsTagsAccessibility}
          />
          <FeatureTagSet
            categoryName='Accessibility'
            excludedTags={everySegmentTagsAccessibility.concat(
              ...organizationTags.map(t => t.key)
            )}
            featureType={feature.class_name}
            onChange={onAccessibilityChange}
            placeholder='Is this trail accessible for all?'
            selectedTags={diff(allTagsAccessibility, everySegmentTagsAccessibility)}
          />
        </div>
        <div className='form-group'>
          <label className='control-label'>Allowed Activities</label>
          <InheritedTags
            labelType='allowed activities'
            organizationTags={organizationTags.filter(t => t.tag_descriptor.category.name === 'Activities').map(t => t.key)}
            tags={everySegmentTagsActivities}
          />
          <PartialTags
            labelType='allowed activities'
            tags={someSegmentsTagsActivities}
          />
          <FeatureTagSet
            categoryName='Activities'
            excludedTagKeys={everySegmentTagsActivities.concat(
              ...organizationTags.map(t => t.key)
            )}
            featureType={feature.class_name}
            onChange={onAllowedActivitiesChange}
            selectedTags={diff(allTagsActivities, everySegmentTagsActivities)}
            placeholder='What activities are permitted on this trail?'
          />
        </div>
        <div className='form-group'>
          <label className='control-label'>Rules & Regulations</label>
          <InheritedTags
            labelType='rules & regulations'
            organizationTags={organizationTags.filter(t => t.tag_descriptor.category.name === 'Rules & Regulations').map(t => t.key)}
            tags={everySegmentTagsRulesAndRegulation}
          />
          <PartialTags
            labelType='rules & regulations'
            tags={someSegmentsTagsRulesAndRegulation}
          />
          <FeatureTagSet
            categoryName='Rules & Regulations'
            excludedTags={trailTags.every_segment_tags_rules_and_regulation.map(t => t.key).concat(
              ...organizationTags.map(t => t.key)
            )}
            featureType={feature.class_name}
            onChange={onRulesAndRegulationsChange}
            selectedTags={diff(allTagsRulesAndRegulation, everySegmentTagsRulesAndRegulation)}
            placeholder='Are there trail restrictions?'
          />
        </div>
        <Field name='accessibility_description'>
          {({ field, form, meta }) => (
            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
              <label className='control-label' htmlFor='accessibility_description'>Accessibility Description</label>
              <Editor
                id={field.name}
                className='form-control'
                value={field.value}
                onChange={(value) => {
                  form.setFieldValue(field.name, value)
                }}
              />
              {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
              <HelpBlock>One-to-two paragraphs about the general accessibility features of this area.</HelpBlock>
            </div>
          )}
        </Field>
      </div>
    )
  }
}

ActivitiesAccessibilityTrails.propTypes = {
  feature: PropTypes.object,
  onAccessibilityChange: PropTypes.func,
  onAllowedAccessChange: PropTypes.func,
  onAllowedActivitiesChange: PropTypes.func,
  onRulesAndRegulationsChange: PropTypes.func,
  onSeasonChange: PropTypes.func,
  organizationTags: PropTypes.array
}

export default ActivitiesAccessibilityTrails
