import { gql } from '@apollo/client'

export const UnlikePost = gql`
    mutation UnlikePost($post_id: Int!, $user_id: Int!) {  
        delete_likes(where: {
            likeable_id: {_eq: $post_id}, 
            likeable_type: {_eq: "Post"}, 
            liker_id: {_eq: $user_id}, 
            liker_type: {_eq: "User"}
        }) {
            returning {
                id
            }
            affected_rows
        }
    }
`

export default UnlikePost
