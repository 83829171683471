import React, { useEffect, useState } from 'react'
import t from 'prop-types'
import { Glyphicon, Button } from 'react-bootstrap'

import EntitySelect from '../../../components/associationSet/EntitySelect'
import UserSearch from '../../../components/userSearch/UserSearch'

const MediaGalleryFilter = (props) => {
  const defaultState = {
    currentLocation: null,
    currentUser: null,
    filtering: false,
    open: false
  }
  const [state, setState] = useState(defaultState)

  const handleUserChange = (value) => {
    const query = props.query

    setState({
      ...state,
      currentUser: value,
      filtering: value || state.currentLocation
    })

    if (state.currentLocation) {
      query.attacheable_id = state.currentLocation.attacheable_id
      query.attacheable_type = state.currentLocation.attacheable_type
    }

    if (value) {
      query.user_id = value.id
    } else {
      delete query.user_id
    }

    props.onFilter(query)
  }

  const handleLocationChange = (value) => {
    const query = props.query

    // TODO: Throwing an error.
    setState({
      ...state,
      currentLocation: value,
      filtering: value || state.currentUser
    })

    if (value && value.attacheable_id && value.attacheable_type) {
      query.attacheable_id = value.attacheable_id
      query.attacheable_type = value.attacheable_type
    } else {
      delete query.attacheable_id
      delete query.attacheable_type
    }

    if (state.currentUser) {
      query.user_id = state.currentUser.id
    }

    props.onFilter(query)
  }

  const onButtonClick = () => {
    setState({
      ...state,
      open: !state.open
    })
  }

  const onClear = () => {
    const query = props.query

    setState({
      ...state,
      currentLocation: null,
      currentUser: null,
      filtering: false
    })
    delete query.attacheable_id
    delete query.attacheable_type
    delete query.user_id

    props.onFilter(query)
  }

  const hidePanel = () => {
    setState({
      ...state,
      open: false
    })
  }
  useEffect(() => {
    if (props.selectedFeature) {
      const feature = props.selectedFeature
      const value = {
        attacheable_id: feature.id,
        attacheable_type: feature.class_name,
        value: {
          value: feature.class_name + '_' + feature.id
        }
      }

      setState({
        ...state,
        currentLocation: value,
        currentUser: null,
        filtering: true,
        open: false
      })
    }

    handleLocationChange(state.currentLocation)
  }, [state.currentLocation])

  const {
    currentLocation,
    currentUser,
    filtering
  } = state
  const filterType = (() => {
    if (currentLocation && currentUser) {
      return 'Location & User'
    } else if (currentLocation) {
      return 'Location'
    } else if (currentUser) {
      return 'User'
    }

    return undefined
  })()
  return (
    <div className='mediaGalleryFilter-wrap' style={!props.showFilters ? { display: 'none' } : {}}>
      <Button
        bsStyle={filtering ? 'primary' : 'default'}
        className='mediaGalleryFilter-filterButton'
        onClick={() => onButtonClick()}
      >
        Filter By <Glyphicon style={{ fontSize: '0.7em', margin: '0 0 0 10px' }} glyph='triangle-bottom' />
      </Button>
      {filtering &&
        <Button
          bsStyle='link'
          className='mediaGalleryFilter-clearButton'
          onClick={onClear}
        >
          Clear {filterType} Filter
        </Button>}
      <div style={!state.open ? { display: 'none' } : {}} className='mediaGalleryFilter-controlPanel' onMouseLeave={() => { hidePanel() }}>
        <div className='mediaGalleryFilter-filter'>
          Uploaded By
          <UserSearch
            alwaysList
            membersOnly
            onSelect={(value) => handleUserChange(value)}
            value={currentUser || null}
          />
        </div>
        <div className='mediaGalleryFilter-filter'>
          Attached Location
          <EntitySelect
            clearable
            entityTypes={['Features']}
            onAdd={(value) => handleLocationChange(value)}
            restrictToCurrentOrganization
            staySelected
            value={currentLocation ? { class: currentLocation.attacheable_type, id: currentLocation.attacheable_id } : null}
          />
        </div>
      </div>

    </div>
  )
}

MediaGalleryFilter.propTypes = {
  onFilter: t.func, // Callback function to pass the query to
  query: t.any,
  selectedFeature: t.object,
  showFilters: t.bool // Boolean flag, if true, to specify to hide with CSS display:none
}

export default MediaGalleryFilter
