import React from 'react'
import t from 'prop-types'
import Panel from 'react-bootstrap/lib/Panel'
import Alert from 'react-bootstrap/lib/Alert'
import { Form } from 'formik'
import FormikGroup from '../../components/fieldGroup/FormikGroup'

const UserSettingsBasic = ({ onFileSelect, imageError, photoFile }) => {
  const imageUrl = renderImageUrl(photoFile)

  return (
    <div>
      <Panel>
        <Panel.Heading>Profile Info</Panel.Heading>
        <Panel.Body>
          <Form>
            {imageError && <Alert bsStyle='danger'>{imageError}</Alert>}
            <div className='form-group userInfo-image'>
              <label className='control-label'>Profile Image</label>
              {!!imageUrl && (
                <div
                  className='userInfo-imagePreview'
                  style={{ backgroundImage: `url(${imageUrl})` }}
                />
              )}
              <label htmlFor='file-input' className='btn btn-default'>
                {imageUrl ? 'Change Image' : 'Add Image'}
              </label>
              <input
                id='file-input'
                type='file'
                multiple
                onChange={onFileSelect}
                className='uploader-fileInput hidden'
              />
            </div>
            <div className='formGroup--double'>
              <FormikGroup
                type='text'
                id='first_name'
                name='first_name'
                label='First Name *'
              />
              <FormikGroup
                type='text'
                id='last_name'
                name='last_name'
                label='Last Name *'
              />
            </div>
          </Form>
        </Panel.Body>
      </Panel>
    </div>
  )
}

const renderImageUrl = model => {
  let imageUrl = null

  if (model.photo_file_src) {
    imageUrl = model.photo_file_src
  } else if (model.photo) {
    imageUrl = model.photo
  }

  return imageUrl
}

UserSettingsBasic.propTypes = {
  imageError: t.any,
  onFileSelect: t.func,
  photoFile: t.object
}

export default UserSettingsBasic
