import PropTypes from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import { useRouteMatch } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import './heroItem.css'

const HeroItemHeader = ({ onSaveClick }) => {
  const match = useRouteMatch()
  const backPath = match.path.split(':')[0].slice(0, -1)
  return (
    <div className='heroItem-header'>
      <div className='heroItem-header__title'>
        <div className='heroItem-header__titleText'>
          <LinkContainer to={backPath}>
            <Button bsStyle='link'>
              <Glyphicon glyph='chevron-left' />Back to Bulletin Board
            </Button>
          </LinkContainer>
          {match.params.heroItemId === 'new' &&
            <h1>Create Bulletin Board Item</h1>}
          {match.params.heroItemId !== 'new' &&
            <h1>Update Bulletin Board Item</h1>}
        </div>
        <div className='heroItem-header__titleActions'>
          <Button
            bsStyle='primary'
            disabled={false}// TODO: FIXME: Hook up the logic to disable the save button if the form is invalid or incomplete
            onClick={onSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

HeroItemHeader.propTypes = {
  onSaveClick: PropTypes.func
}

export default HeroItemHeader
