import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'
import PropTypes from 'prop-types'

import { CenteredLoader } from '../../../../components/centeredLoader'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'

const SortableItem = ({ onSelect, waypoint }) => {
  const {
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: waypoint.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  const feature = waypoint.feature
  const area = feature ? feature.area : null
  let pointType = 'Unattached Stop'

  if (feature) {
    if (feature.point_of_interest_type) {
      pointType = feature.point_of_interest_type.name
    } else {
      pointType = 'POI'
    }
  }

  return (
    <li
      className='waypointListItem'
      key={waypoint.position - 1}
      onClick={() => onSelect(waypoint.id)}
      ref={setNodeRef}
      style={style}
    >
      <div className='marker'>
        <img
          alt={`Stop ${waypoint.position}`}
          src={process.env.PUBLIC_URL + `/map-markers/waypoint-${waypoint.position}.png`}
        />
      </div>
      <div className='content'>
        <div className='name'>
          {(waypoint.name && waypoint.name.length > 0) ? waypoint.name : (waypoint.feature ? waypoint.feature.name : 'Unnamed')}
        </div>
        <div className='details'>
          <span className='type'>{pointType}</span>
          {area && area.name &&
            <span className='area'>
              {' '} in {' ' + area.name}
            </span>}
        </div>
      </div>
      <div
        className='handle'
        ref={setActivatorNodeRef}
        {...listeners}
      >
        <Button
          bsSize='small'
          bsStyle='link'
          className='waypoint-handle'
        >
          <Glyphicon glyph='menu-hamburger' />
        </Button>
      </div>
    </li>
  )
}

SortableItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  waypoint: PropTypes.object.isRequired
}

const OutingWaypointList = ({ isUpdatingWaypoints, onSelectWaypoint, onSortEnd, waypoints }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = (event) => {
    const { active, over } = event

    console.log('event', event)

    if (active.id !== over.id) {
      const oldIndex = waypoints.findIndex(item => item.id === active.id)
      const newIndex = waypoints.findIndex(item => item.id === over.id)
      onSortEnd(oldIndex, newIndex)
    }
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div>
        {isUpdatingWaypoints && <CenteredLoader overlay />}
        {waypoints.length < 1 &&
          <div className='help'>
            <div className='title'>Adding a Stop</div>
            <div className='content'>Click on a marker or the map to add a stop. Click on an existing stop to remove it or add it to your route again.</div>
          </div>}
        {waypoints.length > 0 && (
          <SortableContext
            items={waypoints}
            strategy={verticalListSortingStrategy}
          >
            <ol className='waypointList'>
              {waypoints.map(waypoint =>
                <SortableItem
                  id={waypoint.id}
                  key={waypoint.id}
                  onSelect={onSelectWaypoint}
                  waypoint={waypoint}
                />
              )}
            </ol>
          </SortableContext>
        )}
      </div>
    </DndContext>
  )
}

OutingWaypointList.propTypes = {
  isUpdatingWaypoints: PropTypes.bool,
  onSelectWaypoint: PropTypes.func,
  onSortEnd: PropTypes.func,
  waypoints: PropTypes.array
}

export default OutingWaypointList
