import React from 'react'
import { PageContent, PageHeader, PageLayout } from '../../../components/pageLayout'
import './heroItem.css'
import HeroItemForm from './HeroItemForm'
import HeroItemHeader from './HeroItemHeader'
const HeroItem = () => {
  let handleSubmitForm

  const bindSubmitForm = (submitForm) => {
    handleSubmitForm = submitForm
  }

  const handleSaveClick = (e) => {
    handleSubmitForm(e)
  }

  return (
    <div className='heroItem-wrap'>
      <PageLayout>
        <PageHeader>
          <HeroItemHeader
            disableSaveButton
            // disableSaveButton={getIsLoading || heroItemForm.$form.pristine || !heroItemForm.$form.validity}
            onSaveClick={handleSaveClick}
          />
        </PageHeader>
        <PageContent>
          <HeroItemForm
            bindSubmitForm={bindSubmitForm}
          />
        </PageContent>
      </PageLayout>
    </div>
  )
}

export default HeroItem
