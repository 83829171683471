import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

import { timezones as timezoneOptions } from '../../../../models/time.model'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useHistory } from 'react-router'

import './eventsCalendar.css'

const EventsCalendar = (props) => {
  const { events, onSelectEvent, onSelectSlot } = props
  const history = useHistory()
  const organization = useCurrentOrganization()
  const timezoneLabel = timezoneOptions.filter(({ value }) => value === organization.time_zone)[0].label

  moment.tz.setDefault(organization.time_zone)

  const localizer = momentLocalizer(moment)

  const goToOrgProfile = () => {
    history.push(`/${organization.id}/organization/profile/overview`)
  }

  const calendarEvents = events
    // Filter out event items that don't have `schedule_attributes`
    .filter(event => event.schedules?.length > 0)
    // Mutate each event into the key/value pairs that FullCalendar expects
    .map((event) => (
      {
        event,
        end: new Date(`${event.schedules[0].schedule.until}Z`),
        id: event.id,
        start: new Date(`${event.schedules[0].schedule.date}T${event.schedules[0].schedule.time}Z`),
        title: event.name
      })
    )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <div
        className='eventsCalendar-wrap'
        style={{
          flexGrow: 1
        }}
      >
        <div className='eventsCalendar-calendar' id='calendar' />
        <Calendar
          defaultView='month'
          endAccessor='end'
          events={calendarEvents}
          localizer={localizer}
          onSelectEvent={onSelectEvent}
          onSelectSlot={onSelectSlot}
          selectable
          startAccessor='start'
          style={{ height: '100%' }}
        />
      </div>
      <div
        style={{
          height: '20px'
        }}
      >
        <p style={{ marginTop: '6px' }}>
          Note: The dates/times displayed above are in the <b>{timezoneLabel}</b> Time Zone assigned to your organization. You can change this in your <a className='btn-link' onClick={goToOrgProfile}>organization settings</a>.
        </p>
      </div>
    </div>
  )
}

EventsCalendar.propTypes = {
  events: PropTypes.array,
  onSelectEvent: PropTypes.func,
  onSelectSlot: PropTypes.func
}

export default EventsCalendar
