import React from 'react'
import t from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import FeatureDocumentsList from './FeatureDocumentsList'
import FeatureDocument from './FeatureDocument'

const FeatureDocuments = (props) => {
  const { match, feature } = props
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        render={({ match }) => <FeatureDocumentsList feature={feature} match={match} />}
      />
      <Route
        path={`${match.url}/:documentId`}
        render={({ match }) => <FeatureDocument match={match} />}
      />
    </Switch>
  )
}

FeatureDocuments.propTypes = {
  match: t.object,
  feature: t.object
}

export default FeatureDocuments
