import { Alert, Button, Glyphicon, HelpBlock, Panel, Table } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { gql, useMutation, useQuery } from '@apollo/client'
import { React, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import debugMessage from 'services/Debug'
import fetch from 'node-fetch'
import Select from 'react-select'
import toast from 'react-hot-toast'

import { CenteredLoader } from 'components/centeredLoader'
import { PageContent, PageHeader, PageLayout } from 'components/pageLayout'
import FormikGroup from 'components/fieldGroup/FormikGroup'

const getImportQuery = gql`
  query getImport($importId: Int!) {
    shapefile_uploads_by_pk(id: $importId) {
      created_at
      feature_imports(limit: 10) {
        destination_id
        destination_type
      }
      feature_imports_aggregate {
        aggregate {
          count
        }
      }
      geo_import_features(limit: 10) {
        feature_imports {
          destination_id
          destination_type
        }
        properties
      }
      geo_import_features_aggregate {
        aggregate {
          count
        }
      }
      geojson_url
      id
      import_params
      metadata
      name
      object_logs {
        created_at
        message
      }
      preview
      status
    }
  }
`

const updateShapefileUploadMutation = gql`
  mutation updateShapefileUpload($importId: Int!, $object: shapefile_uploads_set_input!) {
    update_shapefile_uploads(where: { id: { _eq: $importId } }, _set: $object) {
      affected_rows
    }
  }
`

export const Configure = (props) => {
  // TODO: Hook up and make sure this is case-insensitive...
  /*
  const defaultImportProperties = {
    name_field: 'name',
    u_id_field: [
      'u_id',
      'uid'
    ]
  }
  */
  const history = useHistory()
  const match = useRouteMatch()
  const importId = match.params.importId
  // TODO: Add tags...
  const locationTypeParams = {
    // Trail segments
    LineString: {
      optional: [
        'area_id',
        'description',
        'name',
        // Allows you to override the built-in "group by trail name" behavior.
        'trail_u_id'
      ],
      required: ['u_id'],
      tags: [
        // TODO
      ]
    },
    // Points of interest
    Point: {
      optional: [
        'area_id',
        'audio_description',
        'description',
        'name',
        // TODO: Rename to `parent_point_of_interest_id`...
        'parent_id',
        'point_type'
      ],
      required: ['u_id'],
      tags: [
        // TODO
      ]
    },
    // Areas
    Polygon: {
      optional: [
        'accessibility_description',
        'address',
        'area_type',
        'description',
        'email',
        'phone_number',
        'website'
      ],
      required: ['name', 'u_id'],
      tags: [
        // TODO
      ]
    }
  }
  const yesNoProperties = [
    {
      label: 'Yes',
      value: 'true'
    },
    {
      label: 'No',
      value: 'false'
    }
  ]

  const { data, loading } = useQuery(getImportQuery, {
    variables: {
      importId: importId
    }
  })
  const [importParams, setImportParams] = useState({})
  const [updateShapefileUpload] = useMutation(updateShapefileUploadMutation, {
    refetchQueries: ['getImport']
  })

  const availableProperties = data?.shapefile_uploads_by_pk?.metadata?.keys.map((key) => {
    return {
      label: key,
      value: key
    }
  })
  const geometryType = data?.shapefile_uploads_by_pk?.metadata?.geometryType
  const requiredProperties = locationTypeParams[geometryType]?.required
  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      manage_organization_ids: '',
      name: data?.shapefile_uploads_by_pk.name,
      owner_organization_ids: '',
      partner_organization_ids: '',
      update_existing_geometries: 'false',
      update_existing_properties: 'false',
      update_fields: ''
    },
    onSubmit: (values) => {
      const hasRequiredProperties = requiredProperties.every((key) => {
        return importParams[`${key}_field`]
      })

      if (hasRequiredProperties) {
        // TODO: Show loading...

        importParams.manager_organization_ids = values.manager_organization_ids === undefined || values.manager_organization_ids === '' ? null : JSON.parse(values.manager_organization_ids)
        importParams.owner_organization_ids = values.owner_organization_ids === undefined || values.owner_organization_ids === '' ? null : JSON.parse(values.owner_organization_ids)
        importParams.partner_organization_ids = values.partner_organization_ids === undefined || values.partner_organization_ids === '' ? null : JSON.parse(values.partner_organization_ids)

        if (geometryType === 'LineString') {
          importParams.additional_name_fields = values.additional_name_fields === undefined || values.additional_name_fields === '' ? null : JSON.parse(values.additional_name_fields)
        } else if (geometryType === 'Point' || geometryType === 'Polygon') {
          importParams.update_fields = values.update_fields === undefined || values.update_fields === '' ? null : JSON.parse(values.update_fields)
        }

        updateShapefileUpload({
          variables: {
            importId: data.shapefile_uploads_by_pk.id,
            object: {
              import_params: {
                ...{ id: importId },
                ...importParams
              },
              name: values.name
            }
          }
        }).then(
          () => {
            fetch(`${process.env.REACT_APP_API_SERVICE_URL}/imports/update-upload`, {
              body: JSON.stringify({ id: data.shapefile_uploads_by_pk.id }),
              method: 'POST'
            }).then((response) => {
              // TODO: Hide loading...

              if (response.ok) {
                history.push('review')
              } else {
                debugMessage(response)
                toast.error('The configuration could not be processed. Please try again.')
              }
            })
          },
          (error) => {
            // TODO: Hide loading...
            debugMessage(error)
            toast.error('The import configuration could not be saved. Please try again.')
          }
        )
      } else {
        toast.error('Please match all required properties.')
      }
    }
  }
  const importObj = data?.shapefile_uploads_by_pk
  const locationTypeProperties = locationTypeParams[geometryType]

  // TODO: If configuration has been saved, pre-populate the form with the saved values...

  return (
    // TODO: Update this class name...
    <div className='membership-wrap'>
      {loading && <CenteredLoader overlay />}
      <PageLayout>
        <PageHeader>
          <div className='import-header'>
            <div className='import-header__title'>
              <div className='import-header__titleText'>
                <Button
                  bsStyle='link'
                  onClick={() => {
                    history.push(match.path.split(':')[0].slice(0, match.path.split(':')[0].length))
                  }}
                >
                  <Glyphicon glyph='chevron-left' />
                  Back to Imports
                </Button>
                <h1>Step 1 of 3: Configure</h1>
              </div>
              <div className='challenge-header__titleActions' />
            </div>
          </div>
        </PageHeader>
        <PageContent>
          <Alert bsStyle='warning'>
            <strong>Heads up!</strong> For now, if you don't see any values in the "Import Property" dropdowns below,
            please manually refresh and try again.
          </Alert>
          <Panel>
            <Panel.Body>
              {importObj && (
                <>
                  <Formik {...formikProps}>
                    {(formik) => (
                      <Form>
                        <FormikGroup
                          id='name'
                          label='Name'
                          model='shapefile_uploads'
                          name='name'
                          readOnly
                          type='text'
                        />
                        <h3>Configs</h3>
                        <Table style={{ marginTop: '20px' }}>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key='update_existing_geometries'>
                              <td style={{ paddingTop: '19px', width: '50%' }}>Update existing geometries?</td>
                              <td style={{ width: '50%' }}>
                                <Field name='update_existing_geometries'>
                                  {({ field, form, meta }) => (
                                    <>
                                      <Select
                                        isClearable={false}
                                        name='update_existing_geometries'
                                        onChange={(value) => {
                                          const newImportParams = {
                                            ...importParams
                                          }
                                          newImportParams.update_existing_geometries = value.value
                                          formik.setFieldValue('update_existing_geometries', value)
                                          setImportParams(newImportParams)
                                        }}
                                        options={yesNoProperties}
                                        value={field.value}
                                      />
                                      <HelpBlock>Defaults to <code>No</code>. If <code>No</code>, the import will not update geometries for locations that match on <code>u_id</code>. If <code>Yes</code>, geometries will be updated.</HelpBlock>
                                    </>
                                  )}
                                </Field>
                              </td>
                            </tr>
                            {(geometryType === 'LineString') && (
                              <>
                                <tr key='additional_name_fields'>
                                  <td style={{ paddingTop: '19px', width: '50%' }}>Additional Name Fields</td>
                                  <td style={{ width: '50%' }}>
                                    <Field name='additional_name_fields'>
                                      {({ field, meta }) => (
                                        <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                          <input className='form-control' id='additional_name_fields' type='text' {...field} />
                                          {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                                          <span className='help-block'>Comma-separated list of field names to use if a segment is part of more than one trail.</span>
                                        </div>
                                      )}
                                    </Field>
                                  </td>
                                </tr>
                                <tr key='replace_trail_sections'>
                                  <td style={{ paddingTop: '19px', width: '50%' }}>Replace trail sections?</td>
                                  <td style={{ width: '50%' }}>
                                    <Field name='replace_trail_sections'>
                                      {({ field, form, meta }) => (
                                        <>
                                          <Select
                                            isClearable={false}
                                            name='replace_trail_sections'
                                            onChange={(value) => {
                                              const newImportParams = {
                                                ...importParams
                                              }
                                              newImportParams.replace_trail_sections = value.value
                                              formik.setFieldValue('replace_trail_sections', value)
                                              setImportParams(newImportParams)
                                            }}
                                            options={yesNoProperties}
                                            value={field.value}
                                          />
                                          <HelpBlock>Defaults to <code>No</code>. If <code>No</code>, all trail sections, and trail segments associated with them, will be preserved. Any new trail segments (based on <code>u_id</code>) will still get added to existing trails, but no trail segments will be deleted. If <code>Yes</code>, all trail sections associated with incoming trail segments will be wiped out and replaced with new trail sections.</HelpBlock>
                                        </>
                                      )}
                                    </Field>
                                  </td>
                                </tr>
                              </>
                            )}
                            {(geometryType === 'Point' || geometryType === 'Polygon') && (
                              <tr key='update_existing_properties'>
                                <td style={{ paddingTop: '19px', width: '50%' }}>Update existing properties?</td>
                                <td style={{ width: '50%' }}>
                                  <Field name='update_existing_properties'>
                                    {({ field, form, meta }) => (
                                      <>
                                        <Select
                                          isClearable={false}
                                          name='update_existing_properties'
                                          onChange={(value) => {
                                            const newImportParams = {
                                              ...importParams
                                            }
                                            newImportParams.update_existing_properties = value.value
                                            formik.setFieldValue('update_existing_properties', value)
                                            setImportParams(newImportParams)
                                          }}
                                          options={yesNoProperties}
                                          value={field.value}
                                        />
                                        <HelpBlock>Defaults to <code>No</code>. If <code>No</code>, the import will not update properties for locations that match on <code>u_id</code>. If <code>Yes</code>, properties will be updated using the configurations setup in the "Properties" table below. (In this case, only the configured properties will be updated, ensuring that existing properties remain unless specifically configured for update.)</HelpBlock>
                                      </>
                                    )}
                                  </Field>
                                </td>
                              </tr>
                            )}
                            <tr key='owner_organization_ids'>
                              <td style={{ paddingTop: '19px', width: '50%' }}>Owner Organization IDs</td>
                              <td style={{ width: '50%' }}>
                                <Field name='owner_organization_ids'>
                                  {({ field, meta }) => (
                                    <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                      <input className='form-control' id='owner_organization_ids' type='text' {...field} />
                                      {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                                      <span className='help-block'>An array of organization IDs to designate as <strong>owner</strong> stewardships on the created locations. Should look like this: <code>[1,2]</code>. If supplied, the organization that's currently active in Manager will be overwritten, so make sure you include the current organization id, as well.</span>
                                    </div>
                                  )}
                                </Field>
                              </td>
                            </tr>
                            <tr key='manager_organization_ids'>
                              <td style={{ paddingTop: '19px', width: '50%' }}>Manager Organization IDs</td>
                              <td style={{ width: '50%' }}>
                                <Field name='manager_organization_ids'>
                                  {({ field, meta }) => (
                                    <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                      <input className='form-control' id='manager_organization_ids' type='text' {...field} />
                                      {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                                      <span className='help-block'>An array of organization IDs to designate as <strong>manager</strong> stewardships on the created locations. Should look like this: <code>[1,2]</code>.</span>
                                    </div>
                                  )}
                                </Field>
                              </td>
                            </tr>
                            <tr key='partner_organization_ids'>
                              <td style={{ paddingTop: '19px', width: '50%' }}>Partner Organization IDs</td>
                              <td style={{ width: '50%' }}>
                                <Field name='partner_organization_ids'>
                                  {({ field, meta }) => (
                                    <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                      <input className='form-control' id='partner_organization_ids' type='text' {...field} />
                                      {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                                      <span className='help-block'>An array of organization IDs to designate as <strong>partner</strong> stewardships on the created locations. Should look like this: <code>[1,2]</code>.</span>
                                    </div>
                                  )}
                                </Field>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <h3>Properties</h3>
                        <p>
                          Match the properties from the uploaded file on the right ("Import Property") to the
                          "OuterSpatial Property" on the left. Required properties are marked with an <strong>*</strong>
                          . Once you've matched the properties, click the "Save & Continue" button below the table.
                        </p>
                        <Table style={{ marginTop: '20px' }}>
                          <thead>
                            <tr>
                              <th>OuterSpatial Property</th>
                              <th>Import Property</th>
                            </tr>
                          </thead>
                          <tbody>
                            {locationTypeProperties &&
                              locationTypeProperties.required.map((key) => {
                                const fieldName = `${key}_field`

                                return (
                                  <tr key={key}>
                                    <td style={{ verticalAlign: 'middle' }}>{key}*</td>
                                    <td>
                                      <Field name={fieldName}>
                                        {({ field, form, meta }) => (
                                          <Select
                                            isClearable={false}
                                            name={key}
                                            onChange={(value) => {
                                              const newImportParams = {
                                                ...importParams
                                              }
                                              newImportParams[fieldName] = value.value
                                              formik.setFieldValue(fieldName, value)
                                              setImportParams(newImportParams)
                                            }}
                                            options={availableProperties}
                                            value={field.value}
                                          />
                                        )}
                                      </Field>
                                    </td>
                                  </tr>
                                )
                              })}
                            {locationTypeProperties &&
                              locationTypeProperties.optional.map((key) => {
                                const fieldName = `${key}_field`

                                return (
                                  <tr key={key}>
                                    <td style={{ verticalAlign: 'middle', width: '50%' }}>{key}</td>
                                    <td style={{ width: '50%' }}>
                                      <Field name={fieldName}>
                                        {({ field, form, meta }) => (
                                          <Select
                                            isClearable={false}
                                            name={key}
                                            onChange={(value) => {
                                              const newImportParams = {
                                                ...importParams
                                              }
                                              newImportParams[fieldName] = value.value
                                              formik.setFieldValue(fieldName, value)
                                              setImportParams(newImportParams)
                                            }}
                                            options={availableProperties}
                                            value={field.value}
                                          />
                                        )}
                                      </Field>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </Table>
                        <div
                          style={{
                            textAlign: 'center'
                          }}
                        >
                          <button className='btn btn-primary' type='submit'>
                            Save Configuration & Continue to Review Import
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </Panel.Body>
          </Panel>
        </PageContent>
      </PageLayout>
    </div>
  )
}
