import { LinkContainer } from 'react-router-bootstrap'
import Nav from 'react-bootstrap/lib/Nav'
import NavItem from 'react-bootstrap/lib/NavItem'
import PropTypes from 'prop-types'
import React from 'react'

import { useCurrentOrganization } from 'contexts/OrganizationContext'

const OrganizationHeader = ({ isImportAdmin, isOrganizationAdmin, isSuperAdmin, match }) => {
  const organization = useCurrentOrganization()

  if (!organization.id) {
    return null
  }

  const getCommunityUrl = (community) => {
    return `https://www.outerspatial.com/communities/${community.slug}`
  }

  const getOrganizationUrl = (innerOrganization) => {
    return `https://www.outerspatial.com/organizations/${innerOrganization.slug}`
  }

  return (
    <div className='organization-header'>
      <div className='organization-header__title'>
        <div className='organization-header__titleText'>
          <h1>{organization.name}</h1>
          <p>
            {(organization.parent_organization && organization.parent_organization.name) &&
              <span>Parent org: <a href={getOrganizationUrl(organization.parent_organization)}>{organization.parent_organization.name}</a>,&nbsp;</span>}
            Part of:&nbsp;
            {(organization.communities.length === 0) &&
              <span>No Communities</span>}
            {(organization.communities.length === 1 && organization.communities[0].community.visibility === 'Private') &&
              <span>{organization.communities[0].community.name} community</span>}
            {(organization.communities.length === 1 && organization.communities[0].community.visibility !== 'Private') &&
              <span>
                <a href={getCommunityUrl(organization.communities[0].community)}>{organization.communities[0].community.name}</a> community
              </span>}
            {(organization.communities.length > 1) &&
              <span>
                {organization.communities.map((community, index) => {
                  if (index === organization.communities.length - 1) {
                    if (community.community.visibility === 'Private') {
                      return (
                        <span
                          key={index}
                        >
                          {community.community.name} communities
                        </span>
                      )
                    } else {
                      return (
                        <span
                          key={index}
                        >
                          <a href={getCommunityUrl(community.community)}>{community.community.name}</a> communities
                        </span>
                      )
                    }
                  } else {
                    if (community.community.visibility === 'Private') {
                      return (
                        <span
                          key={index}
                        >
                          {community.community.name},&nbsp;
                        </span>
                      )
                    } else {
                      return (
                        <span
                          key={index}
                        >
                          <a href={getCommunityUrl(community.community)}>{community.community.name}</a>,&nbsp;
                        </span>
                      )
                    }
                  }
                })}
              </span>}
          </p>
        </div>
      </div>
      <div className='organization-tabs'>
        <Nav bsStyle='pills' className='nav-borders'>
          <LinkContainer to={`${match.url}/profile`}>
            <NavItem>Profile</NavItem>
          </LinkContainer>
          <LinkContainer to={`${match.url}/team`}>
            <NavItem>Team</NavItem>
          </LinkContainer>
          <LinkContainer to={`${match.path}/campaigns`}>
            <NavItem>Campaigns</NavItem>
          </LinkContainer>
          {(isImportAdmin || isSuperAdmin) && (
            <LinkContainer to={`${match.path}/imports`}>
              <NavItem>Imports</NavItem>
            </LinkContainer>
          )}
          <LinkContainer to={`${match.path}/exports`}>
            <NavItem>Exports</NavItem>
          </LinkContainer>
          <LinkContainer to={`${match.url}/visitor-analytics`}>
            <NavItem>Visitor Analytics</NavItem>
          </LinkContainer>
          {(isOrganizationAdmin === true || isSuperAdmin) && (
            <LinkContainer to={`${match.url}/billing`}>
              <NavItem>Billing</NavItem>
            </LinkContainer>)}
        </Nav>
      </div>
    </div>
  )
}

OrganizationHeader.propTypes = {
  isImportAdmin: PropTypes.bool,
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  match: PropTypes.object
}

export default OrganizationHeader
