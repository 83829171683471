import { useMutation, useQuery } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/lib/Alert'
import { useRouteMatch } from 'react-router'
import debugMessage from 'services/Debug'
import toast from 'react-hot-toast'
import { CenteredLoader } from '../../../components/centeredLoader'
import { Mutations, Queries } from '../OutingsOperations'
import ActionBar from './outingsActionBar/OutingsActionBar'
import './outingsList.css'
import OutingsTable from './outingsTable/OutingsTable'

const OutingsList = ({
  feature,
  onSelectSearchResult
}) => {
  const match = useRouteMatch()
  const organization = useCurrentOrganization()

  const organizationId = organization ? organization.id : -1

  const [outings, setOutings] = useState([])

  const naturallySortOutingsByName = (outings) => {
    setOutings([...outings].sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
  }

  const { loading, error } = useQuery(
    Queries.GetOutings, {
      displayName: 'getOutingsForOrg',
      skip: (organizationId === null || organizationId === -1),
      variables: { organizationId },
      onCompleted: (data) => naturallySortOutingsByName(data.outings)
    }
  )

  const [deleteFeatureGeometry] = useMutation(
    Mutations.DeleteFeatureGeometry, {
      onCompleted () {
        toast.success('Deleted outing route.')
      },
      onError () {
        debugMessage(error)
      }
    }
  )

  const [deleteOuting] = useMutation(Mutations.DeleteOuting, {
    onCompleted () {
      toast.success('Deleted outing successfully.')
    },
    onError () {
      debugMessage(error)
    },
    update (cache, response) {
      const deletedOutingId = response.data.delete_outings.returning[0].id
      cache.modify({
        fields: {
          outings (existingOutings = []) {
            return existingOutings.filter((t) => {
              return t.__ref !== `outings:${deletedOutingId}`
            })
          }
        }
      })
      naturallySortOutingsByName(
        outings.filter((outing) => {
          return outing.id !== deletedOutingId
        })
      )
    }
  })

  const [updateVisibility] = useMutation(
    Mutations.UpdateVisibilityForOuting, {
      onCompleted (data) {
        toast.success('Outing visibility updated.')
        const updatedOuting = data.update_outings.returning[0]
        const updatedOutings = outings.map(o => {
          if (o.id === updatedOuting.id) {
            return { ...o, visibility: updatedOuting.visibility }
          } else {
            return o
          }
        })
        setOutings(updatedOutings)
      },
      onError (updateError) {
        toast.error(updateError)
      }
    }
  )

  const handleArchived = (outing) => {
    if (window.confirm('Are you sure you want to Archive the Outing?')) {
      updateVisibility({
        variables: {
          outingId: outing.id,
          visibility: 'Archived'
        }
      })
    }
  }

  const handleDeleteOuting = (outingId, geometryId) => {
    const confirmationMessage = 'Are you sure you want to delete the Outing rather than archive it?\n\nDeleting the Outing means it will be gone permanently and you will no longer be able to view any analytics for it. Deleted Outings cannot be recovered. Archiving the Outing will take it out of all visitor-facing contexts, but will preserve analytics and allow you to restore it if desired.'
    const conf = window.confirm(confirmationMessage)

    if (conf) {
      // Only attempt to delete the feature geometry if it exists
      if (geometryId) {
        deleteFeatureGeometry({ variables: { geometryId: geometryId } })
      }

      deleteOuting({ variables: { id: outingId } })
    }
  }

  const handlePublished = (outing) => {
    if (window.confirm('Are you sure you want to publish this Outing?')) {
      updateVisibility({
        variables: {
          outingId: outing.id,
          visibility: 'Published'
        }
      })
    }
  }

  // TODO: Remove/Clean up later with FeatureSearch
  const query = {
    page: 1,
    type: 'outings'
  }

  document.title = `Outings | ${process.env.REACT_APP_PAGE_TITLE}`

  return (
    <div className='outingsList-wrap'>
      <div className='outingsList-actionBar'>
        <ActionBar
          feature={feature}
          fetching={loading}
          match={match}
          onSelectSearchResult={onSelectSearchResult}
          query={query}
        />
      </div>
      <div className='outingsList-content'>
        <div className='outingsList-table'>
          {loading &&
            <div className='outingsList-loader'>
              <CenteredLoader />
            </div>}
          {!loading &&
            <div>
              {!!error && error.message && <Alert bsStyle='danger'>{error.message}</Alert>}
              <OutingsTable
                {...{
                  outings,
                  match,
                  feature
                }}
                onArchived={handleArchived}
                onDelete={handleDeleteOuting}
                onEdit={onSelectSearchResult}
                onPublished={handlePublished}
              />
            </div>}
        </div>
      </div>
    </div>
  )
}

OutingsList.propTypes = {
  feature: PropTypes.object,
  onSelectSearchResult: PropTypes.func
}

export default OutingsList
