import { gql } from '@apollo/client'
const OrganizationImagesQuery = gql`
  query OrganizationImagesQuery($id: Int!,$limit: Int = 10, $offset: Int = 0, $order_by: [organization_image_attachments_order_by!] = {}, $where: organization_image_attachments_bool_exp = {}) {
    organizations_by_pk(id: $id) {
      id
      image_attachments_aggregate(where: $where) {
       aggregate {
          count
        }
      }
      image_attachments(offset: $offset, limit: $limit, order_by: $order_by, where: $where) {
        id
        image {
          user_id
          caption
          attribution_url
          attribution_text
          created_at
          id
          uploaded_file
          organization_image_attachments {
            id
            position
            created_at
            organization {
              id
              name
            }
          }
          area_image_attachments {
            id
            position
            created_at
            area {
              id
              name
            }
          }
          trail_image_attachments {
            id
            position
            created_at
            trail {
              id
              name
            }
          }
          point_of_interest_image_attachments {
            id
            position
            created_at
            point_of_interest {
              id
              name
            }
          }
          image_attachments {
            attacheable_type
            attacheable_id
            id
            position
            created_at
          }
        }
      }
    }
  }
`

export default OrganizationImagesQuery
