import PropTypes from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Nav from 'react-bootstrap/lib/Nav'
import NavItem from 'react-bootstrap/lib/NavItem'
import './actionBar.css'

const tabs = [
  { label: 'Areas', to: '/land?type=areas', key: 'areas' },
  { label: 'Trails', to: '/land?type=trails', key: 'trails' },
  { label: 'Points of Interest', to: '/land?type=points-of-interest', key: 'points_of_interest' },
  { label: 'Outings', to: '/land?type=outings', key: 'outings' }
]

const FeatureChildrenActionBar = (props) => {
  const { query, onChangeQuery, hiddenFeatures = [] } = props
  const filteredTabs = tabs.filter((tab) => hiddenFeatures.indexOf(tab.key) === -1)

  const handleViewHelpClickLink = () => {
    if (window.Beacon) {
      window.Beacon('article', '78', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/78-locations' }).click()
    }
  }

  return (
    <div className='featuresActionBar-wrap'>
      {filteredTabs.length > 0 &&
        <div className='featuresActionBar-tabs'>
          <Nav bsStyle='tabs'>
            {filteredTabs.map((tab) => {
              return (
                <NavItem
                  className={tab.key === query.type ? 'active' : ''}
                  key={tab.to}
                  onClick={() => onChangeQuery({ type: tab.key })}
                >
                  {tab.label}
                </NavItem>
              )
            })}
          </Nav>
        </div>}
      <div className='featuresChildrenActionBar-actions'>
        <Button
          bsStyle='primary'
          className='shadow'
          onClick={handleViewHelpClickLink}
        >
          View Help
        </Button>
      </div>
    </div>
  )
}

FeatureChildrenActionBar.propTypes = {
  hiddenFeatures: PropTypes.array, // array of keys for tabs to hide
  onChangeQuery: PropTypes.func,
  query: PropTypes.object
}

export default FeatureChildrenActionBar
