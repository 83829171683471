import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import './featureSeasons.css'

// TODO: FIXME: Remove the notion of `year_round`!!!!!
/* ** Seasonality Tag details **
// By default, when creating an Outing/Feature, there are no Seasons specified,
//  therefore, all seasons are enabled. If the Outing/Feature is saved with all
//  seasons enabled, we don't save any seasons
//  otherwise, we save the 1-3 selected season tags individually.
// UI: We don't allow all 4 seasons to be disabled. When there is 1 left, we disable
//  changing the value to disabled in order to force the user to select at least one season
*/

const FeatureSeasons = (props) => {
  const defaultSeasons = [
    'winter',
    'spring',
    'summer',
    'fall'
  ]
  const [checkedSeasons, setCheckedSeasons] = useState((props.selectedSeasons && props.selectedSeasons.length) ? props.selectedSeasons : defaultSeasons)

  const handleSeasonChange = (event, season) => {
    const { onSeasonChange } = props
    const checked = event.target.checked
    let _newCheckedSeasons = checkedSeasons

    if (checked) {
      _newCheckedSeasons = [..._newCheckedSeasons, season]
    } else {
      _newCheckedSeasons = _newCheckedSeasons.filter(i => i !== season)
    }

    setCheckedSeasons(_newCheckedSeasons)

    if (onSeasonChange) {
      onSeasonChange(_newCheckedSeasons)
    }
  }

  const isDisabled = (season) => {
    const { trailSegmentSeasons } = props
    let disabled = false

    // If a Season exists on all trail segments, disable changing this
    if (trailSegmentSeasons?.filter(s => s === season || s.key === season).length > 0) {
      disabled = true
    } else if (checkedSeasons.length === 1) {
      if (checkedSeasons.includes(season)) {
        disabled = true
      } else {
        disabled = false
      }
    }

    return disabled
  }

  const isSelected = (season) => {
    if (!checkedSeasons || !checkedSeasons.length) {
      return true
    } else if (checkedSeasons.includes('year_round')) {
      return true
    } else if (checkedSeasons.includes(season)) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className='form-group'>
      <label>
        <Toggle
          className='season-toggle'
          defaultChecked={isSelected('winter')}
          disabled={isDisabled('winter')}
          icons={false}
          onChange={(e) => handleSeasonChange(e, 'winter')}
        />
        <span className='featureInfo-toggleSpan'>Winter</span>
      </label>
      <label>
        <Toggle
          className='season-toggle'
          defaultChecked={isSelected('spring')}
          disabled={isDisabled('spring')}
          icons={false}
          onChange={(e) => handleSeasonChange(e, 'spring')}
        />
        <span className='featureInfo-toggleSpan'>Spring</span>
      </label>
      <label>
        <Toggle
          className='season-toggle'
          defaultChecked={isSelected('summer')}
          disabled={isDisabled('summer')}
          icons={false}
          onChange={(e) => handleSeasonChange(e, 'summer')}
        />
        <span className='featureInfo-toggleSpan'>Summer</span>
      </label>
      <label>
        <Toggle
          className='season-toggle'
          defaultChecked={isSelected('fall')}
          disabled={isDisabled('fall')}
          icons={false}
          onChange={(e) => handleSeasonChange(e, 'fall')}
        />
        <span className='featureInfo-toggleSpan'>Fall</span>
      </label>
    </div>
  )
}

FeatureSeasons.propTypes = {
  onSeasonChange: PropTypes.func,
  selectedSeasons: PropTypes.array,
  trailSegmentSeasons: PropTypes.array
}

export default FeatureSeasons
