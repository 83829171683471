import React from 'react'
import { Formik, Form } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { gql, useMutation } from '@apollo/client'
import FormikGroup from 'components/fieldGroup/FormikGroup'
import Button from 'react-bootstrap/lib/Button'
import PropTypes from 'prop-types'
import Alert from 'react-bootstrap/lib/Alert'
import { useHistory } from 'react-router-dom'

import { askOuterSpatialAQuestion } from '../../components/helpScout'
import { segmentTrack } from '../../components/segment'
import { useCurrentUser } from 'contexts/CurrentUserContext'

const InsertOrganizationMutation = gql`
  mutation InsertOrganization($email_domain: String!, $name: String!, $user_id: Int!) {
    insert_memberships_one(
      object: {
        organization: { data: { email_domain: $email_domain, name: $name, customer_signup: true } }
        primary: true
        role: "admin"
        user_id: $user_id
      }
    ) {
      created_at
      id
      organization {
        id
        name
      }
    }
  }
`

// TODO: Do not let users with @gmail.com or @yahoo.com or ??? create an organization. Point them to customer success.
// TODO: Validate that the organization name is not already in the organizations table before creating.

const AddOrganizationForm = ({ currentUser, currentUserDomain }) => {
  const [insertOrganizationMutation] = useMutation(InsertOrganizationMutation, {
    refetchQueries: ['GetTokenUser']
  })
  const formikProps = {
    enableReinitialize: false,
    initialValues: {
      name: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(500, 'The organization name must be 500 characters or less.')
        .required('An organization name is required.')
    }),
    onSubmit: (values) => {
      saveForm(values)
    }
  }
  const handleGetInTouchClick = (e) => {
    e.preventDefault()
    askOuterSpatialAQuestion(
      'Adding or Requesting Access to an Organization',
      'Hello, I need help adding an organization to OuterSpatial or requesting accessing to an existing organization...',
      currentUser
    )
  }
  const history = useHistory()
  const linkStyle = {
    textDecoration: 'underline',
    color: '#41A7D0',
    cursor: 'pointer'
  }
  const me = useCurrentUser()
  const saveForm = (values) => {
    insertOrganizationMutation({
      refetchQueries: ['GetTokenUser'],
      variables: {
        ...values,
        email_domain: currentUserDomain,
        user_id: currentUser.id
      }
    })
      .then((callbackData) => {
        segmentTrack(
          'organization_added',
          {
            organization_id: callbackData.data.insert_memberships_one.organization.id,
            organization_name: callbackData.data.insert_memberships_one.organization.name
          },
          me
        )
        history.push('/login')
      })
      .catch((e) => {
        toast.error('Your organization could not be added. Please try again.')
      })
  }

  return (
    <div>
      <Alert bsStyle='info'>
        <p>
          Your organization will be attached to the following email domain:{' '}
          <b>
            <i>@{currentUserDomain}</i>
          </b>
          . If this is incorrect, click the "Log Out" button and log in again using your work email.
        </p>
        <p>
          Questions or problems?{' '}
          <span onClick={handleGetInTouchClick} style={linkStyle}>
            Get in touch
          </span>{' '}
          with our customer success team.
        </p>
      </Alert>
      <Formik {...formikProps}>
        {(formik) => (
          <Form>
            <FormikGroup type='text' id='name' label='Organization Name *' name='name' />
            <Button bsStyle='primary' disabled={!(formik.dirty && formik.isValid)} onClick={formik.handleSubmit}>
              Add to OuterSpatial
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

AddOrganizationForm.propTypes = {
  currentUser: PropTypes.object,
  currentUserDomain: PropTypes.string
}

export default AddOrganizationForm
