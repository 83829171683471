import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import React from 'react'

import MapsList from './mapsList/MapsList'
import PaperMap from './paperMap/PaperMap'

const Maps = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={match.url} render={() => <Redirect to={`${match.url}/paper`} />} />      
      <Route exact path={`${match.url}/paper/:mapId`} component={PaperMap} />
      <Route path={`${match.url}/:mapType`} component={MapsList} />
    </Switch>
  )
}

export default Maps
