import ControlLabel from 'react-bootstrap/lib/ControlLabel'
import Datetime from 'react-datetime'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import HelpBlock from 'react-bootstrap/lib/HelpBlock'
import PropTypes from 'prop-types'
import React from 'react'
import { useField, ErrorMessage } from 'formik'

import Editor from '../../components/editorWithHooks'
import LocationSelect from '../../components/locationSelect'
import PhoneInput from '../../components/phoneInput'
import Select from '../../components/select'
import './fieldGroup.css'

const FormikGroup = ({ debounce = 500, disabled = false, disabledWysiwygContent = '', help, id, label, name, options, type = 'text', ...props }) => {
  const [field, meta, helpers] = useField(name)
  let controlComponent
  const errorMessage = meta.error
  const validationState = meta.error ? 'error' : null

  const handleInputChange = (change) => {
    helpers.setValue(change.target.value)
    helpers.setTouched(true)
  }

  switch (type) {
    case 'datetime': {
      controlComponent = (
        <div className='datetime-wrap'>
          <Datetime {...props} onChange={(value) => { helpers.setValue(value); props.onChange(value) }} inputProps={{ className: 'datetime-input form-control' }} />
          <Glyphicon glyph='calendar' />
        </div>
      )
      break
    }
    case 'datetime_field': {
      controlComponent = (
        <div className='datetime-wrap'>
          <Datetime value={field.value} {...props} onChange={(value) => helpers.setValue(value)} inputProps={{ className: 'datetime-input form-control' }} />
          <Glyphicon glyph='calendar' />
        </div>
      )
      break
    }
    case 'location': {
      controlComponent = (
        <LocationSelect
          {...field}
          {...helpers}
          {...{ ...props, disabled }}
          {...{ id, options }}
        />
      )
      break
    }
    case 'number': {
      controlComponent = <input id={id} debounce={debounce} type='number' {...props} className='form-control' {...field} />
      break
    }
    case 'password': {
      controlComponent = <input id={id} debounce={debounce} type='password' {...props} className='form-control' {...field} />
      break
    }
    case 'phone': {
      controlComponent = <PhoneInput debounce={debounce} id={id} disabled={disabled} onChange={handleInputChange} {...field} {...props} />
      break
    }
    case 'radio': {
      controlComponent = (
        <div>
          {options && options.map((option) => (
            <div key={option.value} className='radio'>
              <label>
                <input value={option.value} {...props} />
                {option.label}
              </label>
            </div>
          ))}
        </div>
      )
      break
    }
    case 'select': {
      const handleChange = (event) => {
        helpers.setValue(event)

        if (props.onChange) {
          props.onChange(event)
        }
      }

      controlComponent = (
        <Select
          {...field}
          {...props}
          {...{ options, id }}
          className='form-control'
          isDisabled={disabled}
          onChange={handleChange}
        />
      )
      break
    }
    case 'textarea': {
      controlComponent = <textarea {...field} {...props} className='form-control' />
      break
    }
    case 'url': {
      controlComponent = <input className='form-control' debounce={debounce} {...props} id={id} type={type} validateOn='change' disabled={disabled} />
      break
    }
    case 'wysiwyg': {
      if (disabled) {
        controlComponent = (
          <div
            id={id}
            className='disabledWysiwygContent'
            dangerouslySetInnerHTML={{
              __html: disabledWysiwygContent
            }}
          />
        )
      } else {
        const handleEditorChange = (change) => {
          helpers.setValue(change)
          helpers.setTouched(true)
        }
        controlComponent = <Editor id={id} debounce={debounce} {...field} {...props} onChange={handleEditorChange} disabled={disabled} className='form-control' />
      }
      break
    }
    default: {
      controlComponent = <input {...field} {...props} className='form-control' />
    }
  }

  return (
    <FormGroup controlId={id} validationState={validationState}>
      {label && <ControlLabel htmlFor={id}>{label}</ControlLabel>}
      {controlComponent}
      {!errorMessage && help && <HelpBlock>{help}</HelpBlock>}
      {errorMessage && validationState &&
        <ErrorMessage name={field.name} />}
    </FormGroup>
  )
}

FormikGroup.propTypes = {
  debounce: PropTypes.number,
  disabled: PropTypes.bool,
  disabledWysiwygContent: PropTypes.string,
  help: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  messages: PropTypes.object,
  model: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  type: PropTypes.string,
  validity: PropTypes.object
}

export default FormikGroup
