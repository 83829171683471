import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { React, useState } from 'react'
import { useMutation } from '@apollo/client'
import Button from 'react-bootstrap/lib/Button'
import HelpBlock from 'react-bootstrap/lib/HelpBlock'
import Modal from 'react-bootstrap/lib/Modal'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

import { CenteredLoader } from '../../../components/centeredLoader'
import { Mutations, Queries } from '../ChallengesOperations'
import AssociationSet from '../../../components/associationSet'
import FormikGroup from '../../../components/fieldGroup/FormikGroup'
import MediaModal from '../../../components/media/modal/MediaModal'
import debugMessage from 'services/Debug'
import { buildImageUploadUrl } from 'services/Images'

const getImageUrl = (image) => {
  let imageUrl = null

  if (image?.id && image?.uploaded_file) {
    imageUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'small')
  }

  return imageUrl
}

const ChallengeTaskModal = ({ onClose, show, task, updating }) => {
  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      long_description: task.long_description || '',
      badge_image_id: task.badge_image?.id || null
    },
    validationSchema: Yup.object({
      long_description: Yup
        .string()
        .nullable()
    }),
    onSubmit: values => {
      handleSave(values)
    }
  }
  const [imageUrl, setImageUrl] = useState(null)

  const [deleteTask] = useMutation(Mutations.DeleteChallengeTask, { refetchQueries: [Queries.GetChallengeById] })
  const [updateTask] = useMutation(Mutations.UpdateChallengeTask, { refetchQueries: [Queries.GetChallengeById] })

  const [insertOutingTask] = useMutation(Mutations.InsertOutingChallengeTask, { refetchQueries: [Queries.GetChallengeById] })
  const [insertTrailTask] = useMutation(Mutations.InsertTrailChallengeTask, { refetchQueries: [Queries.GetChallengeById] })
  const [insertPOITask] = useMutation(Mutations.InsertPOIChallengeTask, { refetchQueries: [Queries.GetChallengeById] })
  const [insertAreaTask] = useMutation(Mutations.InsertAreaChallengeTask, { refetchQueries: [Queries.GetChallengeById] })

  const [insertOutingTaskOnly] = useMutation(Mutations.InsertOutingChallengeTaskOnly)
  const [insertTrailTaskOnly] = useMutation(Mutations.InsertTrailChallengeTaskOnly)
  const [insertPOITaskOnly] = useMutation(Mutations.InsertPOIChallengeTaskOnly)
  const [insertAreaTaskOnly] = useMutation(Mutations.InsertAreaChallengeTaskOnly)

  const [deleteOutingTask] = useMutation(Mutations.DeleteOutingChallengeTask)
  const [deleteTrailTask] = useMutation(Mutations.DeleteTrailChallengeTask)
  const [deletePOITask] = useMutation(Mutations.DeletePOIChallengeTask)
  const [deleteAreaTask] = useMutation(Mutations.DeleteAreaChallengeTask)

  const [fields, setFields] = useState({ ...task })

  const [showBadgeImageMediaModal, setShowBadgeImageMediaModal] = useState()

  if (task.badge_image && imageUrl === null) {
    setImageUrl(getImageUrl(task.badge_image))
  }

  const openBadgeImageMediaModal = () => {
    setShowBadgeImageMediaModal(true)
  }

  const closeBadgeImageMediaModal = () => {
    setShowBadgeImageMediaModal(false)
  }

  const handleDelete = () => {
    deleteTask({
      variables: {
        id: task.id
      }
    })
      .then(() => {
        onClose()
        toast.success('Task deleted.')
      }, error => {
        debugMessage(error)
      })
  }

  const handleUpdate = (mergedValues) => {
    const variables = {
      id: task.id,
      feature_type: mergedValues.feature_type,
      feature_id: mergedValues.feature_id,
      long_description: mergedValues.long_description,
      badge_image_id: mergedValues.badge_image_id
    }
    if ((task.feature_id !== mergedValues.feature_id) || (task.feature_type !== mergedValues.feature_type)) {
      const deleteVariables = {
        task_id: task.id
      }
      let deleteMutation = null
      if (task.feature_type === 'Outing') {
        deleteMutation = deleteOutingTask
      } else if (task.feature_type === 'Trail') {
        deleteMutation = deleteTrailTask
      } else if (task.feature_type === 'PointOfInterest') {
        deleteMutation = deletePOITask
      } else if (task.feature_type === 'Area') {
        deleteMutation = deleteAreaTask
      }

      let insertMutation = null
      let insertVariables = null
      if (mergedValues.feature_type === 'Outing') {
        insertMutation = insertOutingTaskOnly
        insertVariables = {
          outing_id: mergedValues.feature_id,
          task_id: task.id
        }
      } else if (mergedValues.feature_type === 'Trail') {
        insertMutation = insertTrailTaskOnly
        insertVariables = {
          trail_id: mergedValues.feature_id,
          task_id: task.id
        }
      } else if (mergedValues.feature_type === 'PointOfInterest') {
        insertMutation = insertPOITaskOnly
        insertVariables = {
          poi_id: mergedValues.feature_id,
          task_id: task.id
        }
      } else if (mergedValues.feature_type === 'Area') {
        insertMutation = insertAreaTaskOnly
        insertVariables = {
          area_id: mergedValues.feature_id,
          task_id: task.id
        }
      }
      deleteMutation({
        variables: deleteVariables
      }).then(() => {
        insertMutation({
          variables: insertVariables
        }).then(() => {
          updateTask({
            variables
          }).then(() => {
            onClose()
          }, error => {
            debugMessage(error)
          })
        })
      })
    } else {
      updateTask({
        variables
      }).then(() => {
        onClose()
        toast.success('Task updated.')
      }, error => {
        debugMessage(error)
      })
    }
  }

  const handleSave = (values) => {
    const mergedValues = { ...fields, ...values }
    if (mergedValues.id) {
      return handleUpdate(mergedValues)
    }
    let mutation = null
    let variables = {
      feature_type: mergedValues.feature_type,
      feature_id: mergedValues.feature_id,
      challenge_id: mergedValues.challenge_id,
      long_description: mergedValues.long_description,
      badge_image_id: mergedValues.badge_image_id
    }
    if (mergedValues.feature_type === 'Outing') {
      mutation = insertOutingTask
      variables = {
        ...variables,
        outing_id: mergedValues.feature_id
      }
    } else if (mergedValues.feature_type === 'Trail') {
      mutation = insertTrailTask
      variables = {
        ...variables,
        trail_id: mergedValues.feature_id
      }
    } else if (mergedValues.feature_type === 'PointOfInterest') {
      mutation = insertPOITask
      variables = {
        ...variables,
        point_of_interest_id: mergedValues.feature_id
      }
    } else if (mergedValues.feature_type === 'Area') {
      mutation = insertAreaTask
      variables = {
        ...variables,
        area_id: mergedValues.feature_id
      }
    }

    mutation({
      variables
    }).then(() => {
      onClose()
    }, error => {
      debugMessage(error)
    })
  }
  const setLocation = (value) => {
    updateField({
      feature_id: value.attacheable_id,
      feature_type: value.attacheable_type
    })
  }
  const updateField = (field) => {
    setFields({ ...fields, ...field })
  }

  return (
    <Formik {...formikProps}>
      {formik => (
        <div className='challengeTaskModal'>
          <Modal
            dialogClassName='challengeTaskModal-dialog'
            enforceFocus={false}
            onHide={onClose}
            show={show}
          >
            {updating &&
              <CenteredLoader overlay />}
            <Modal.Body>
              <Form className='challengeTaskModal-form'>
                <div className='form-group'>
                  <label className='control-label'>Check-in Location *</label>
                  <div className='challengeTaskModal-form-locationRow__content'>
                    <AssociationSet
                      clearable={false}
                      entityTypes={['Features']}
                      onAdd={setLocation}
                      placeholder='Search locations...'
                      restrictToCurrentOrganization
                      value={{ class: fields.feature_type, id: fields.feature_id }}
                    />
                  </div>
                </div>
                <FormikGroup
                  id='long_description'
                  label='Message'
                  name='long_description'
                  rows='6'
                  type='wysiwyg'
                />
                <HelpBlock>If you specify a message, it will show up in an intermediate screen that displays after the visitor taps on a challenge task card.</HelpBlock>
                <div>
                  <label className='control-label'>Badge Image</label>
                  <div className={'form-group challengeSection-image ' + (!imageUrl ? 'challengeImage--hasLogo' : '')}>
                    <Button className='challengeImage-imageButton' onClick={() => openBadgeImageMediaModal()} bsSize='small'>
                      Update
                    </Button>
                    {!!imageUrl &&
                      <div
                        className='challengeSection-badgeImage'
                        style={{
                          backgroundImage: `url(${imageUrl})`
                        }}
                      />}
                  </div>
                </div>
                {showBadgeImageMediaModal &&
                  <MediaModal
                    allowSelection
                    allowUploads
                    onClose={closeBadgeImageMediaModal}
                    onSubmit={(images) => {
                      const image = images[0]

                      formik.setFieldValue('badge_image_id', image.id)
                      setImageUrl(getImageUrl(image))
                      closeBadgeImageMediaModal()
                    }}
                    show={showBadgeImageMediaModal}
                  />}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {!!task.id &&
                <Button
                  bsStyle='primary'
                  className='btn-danger pull-left'
                  disabled={updating}
                  onClick={handleDelete}
                >
                  Delete Task
                </Button>}
              <Button
                bsStyle='primary'
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                bsStyle='primary'
                onClick={formik.handleSubmit}
                disabled={updating}
              >
                {task.id ? 'Save Task' : 'Add Task'}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </Formik>
  )
}

ChallengeTaskModal.propTypes = {
  formik: PropTypes.object,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  task: PropTypes.object,
  updating: PropTypes.bool
}

export default ChallengeTaskModal
