import * as Sentry from '@sentry/react'
import { useAuth0 } from '@auth0/auth0-react'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import React from 'react'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Nav from 'react-bootstrap/lib/Nav'
import Navbar from 'react-bootstrap/lib/Navbar'
import NavDropdown from 'react-bootstrap/lib/NavDropdown'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useCurrentOrganization } from '../../../contexts/OrganizationContext'
import logo from '../../../images/marker.png'
import './topbar.css'

const TopBar = () => {
  const { logout } = useAuth0()
  const currentUser = useCurrentUser()
  const organization = useCurrentOrganization()

  return (
    <Navbar className='topbar__nav' fluid>
      <Navbar.Header>
        <img className='topbar__logo' src={logo} alt='The Outerspatial logo.' />
        <Navbar.Brand>
          <Link to='/'>
            OuterSpatial Manager
          </Link>
        </Navbar.Brand>
      </Navbar.Header>
      {currentUser.id &&
        <Nav pullRight>
          <NavDropdown title={currentUser.profile.name} id='basic-nav-dropdown'>
            <LinkContainer to={`/${organization.id}/settings`}>
              <MenuItem>Settings</MenuItem>
            </LinkContainer>
            <MenuItem onClick={() => {
              logout({ returnTo: `${window.location.origin}/logout` })
              window.Beacon && window.Beacon('logout')
              Sentry.setUser(null)
            }}
            >
              Log Out
            </MenuItem>
          </NavDropdown>
        </Nav>}
    </Navbar>
  )
}

export default TopBar
