import Button from 'react-bootstrap/lib/Button'
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Modal from 'react-bootstrap/lib/Modal'
import Panel from 'react-bootstrap/lib/Panel'
import React, { useEffect, useState } from 'react'
import fetch from 'node-fetch'

import { askOuterSpatialAQuestion } from 'components/helpScout'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { segmentTrack } from 'components/segment'
import { useLanding } from 'contexts/LandingContext'

const OrganizationBillingPlan = () => {
  const couponId = window.localStorage.getItem("couponId");
  const cbInstance = window.Chargebee.getInstance()
  const currentUser = useCurrentUser()
  const organization = useCurrentOrganization()
  const [checkoutSuccess, setCheckoutSuccess] = useState(false)
  console.log(couponId)
  const handleCheckoutTest = () => {
    cbInstance.openCheckout({
      hostedPage () {
        segmentTrack('checkout_start', { plan: 'Test-USD-Monthly' }, currentUser)
        return fetch(`${process.env.REACT_APP_API_SERVICE_URL}/chargebee/checkout_new`, {
          method: 'POST',
          body: JSON.stringify({ organization_id: organization.id, plan_id: 'Test-USD-Monthly', coupon_id: couponId })
        }).then((response) => response.json())
      },
      success () {
        setCheckoutSuccess(true)
        segmentTrack('checkout_success', {}, currentUser)
      },
      close () {
        console.log('checkout new closed')
        segmentTrack('checkout_close', {}, currentUser)
      },
      step (step) {
        console.log('checkout', step)
        segmentTrack('checkout_step', { step }, currentUser)
      }
    })
  }

  const handleCheckoutAnnually = () => {
    cbInstance.openCheckout({
      hostedPage () {
        segmentTrack('checkout_start', { plan: 'Team-USD-Yearly' }, currentUser)
        return fetch(`${process.env.REACT_APP_API_SERVICE_URL}/chargebee/checkout_new`, {
          method: 'POST',
          body: JSON.stringify({ organization_id: organization.id, plan_id: 'Team-USD-Yearly', coupon_id: couponId })
        }).then((response) => response.json())
      },
      success () {
        setCheckoutSuccess(true)
        segmentTrack('checkout_success', {}, currentUser)
      },
      close () {
        console.log('checkout new closed')
        segmentTrack('checkout_close', {}, currentUser)
      },
      step (step) {
        console.log('checkout', step)
        segmentTrack('checkout_step', { step }, currentUser)
      }
    })
  }

  const handleCheckoutMonthly = () => {
    cbInstance.openCheckout({
      hostedPage () {
        segmentTrack('checkout_start', { plan: 'Team-USD-Monthly' }, currentUser)
        return window.fetch(`${process.env.REACT_APP_API_SERVICE_URL}/chargebee/checkout_new`, {
          method: 'POST',
          body: JSON.stringify({ organization_id: organization.id, plan_id: 'Team-USD-Monthly', coupon_id: couponId })
        }).then((response) => response.json())
      },
      success () {
        setCheckoutSuccess(true)
        segmentTrack('checkout_success', {}, currentUser)
      },
      close () {
        console.log('checkout new closed')
        segmentTrack('checkout_close', {}, currentUser)
      },
      step (step) {
        console.log('checkout', step)
        segmentTrack('checkout_step', { step }, currentUser)
      }
    })
  }

  const handleContactSalesClick = () => {
    askOuterSpatialAQuestion('Contact Sales', null, currentUser)
  }

  const handlePaymentPortal = () => {
    cbInstance.createChargebeePortal().open({
      visit (visit) {
        console.log('portal visit', visit)
        segmentTrack('payment_portal_visit', { visit }, currentUser)
      }
    })
  }

  const handleUpgradeToEnterpriseClick = () => {
    askOuterSpatialAQuestion(
      'Upgrade to Enterprise Plan',
      `I would like to upgrade my organization (${organization.name}) to the enterprise plan.`, // Pre-filled message
      currentUser
    )
  }

  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '110', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/110-license-tiers' }).click()
    }
  }

  useEffect(() => {
    cbInstance.setPortalSession(() => {
      return fetch(`${process.env.REACT_APP_API_SERVICE_URL}/chargebee/create_portal_session`, {
        method: 'POST',
        body: JSON.stringify({ customer_id: organization.customer.chargebee_id })
      }).then((res) => res.json())
    })
  }, [])

  return (
    <div>
      <Panel>
        <Panel.Body>
          {organization.license_tier === 'enterprise' && (
            <>
              <div>
                <h3>License Info</h3>
                <p>
                  Your organization is on the <strong>Enterprise</strong> plan. Have questions about your plan or want to
                  make changes to it?
                </p>
                <Button bsStyle='primary' onClick={handleContactSalesClick}>
                  Contact Sales
                </Button>
              </div>
              <div>
                <h3>Tax Documents</h3>
                <p>Click the button below to download up-to-date tax documents for Trailhead Labs, Inc.</p>
                <Button bsStyle='primary' href='https://cdn.outerspatial.com/docs/trailhead-labs-tax-documents.zip'>
                  Download Tax Documents
                </Button>
              </div>
            </>
          )}
          {(organization.license_tier === 'team') && (organization.customer || checkoutSuccess) && (
            <>
              <h3>License Info</h3>
              <p>
                Your organization is on the <strong>Team</strong> plan. (Read more about the available plans in our <a onClick={handleViewHelpClick} style={{ cursor: 'pointer' }}>knowledge base article</a>.)
              </p>
              <Button bsStyle='primary' onClick={handlePaymentPortal}>Payment Portal</Button>
              &nbsp;
              <Button bsStyle='primary'>Contact Sales About Enterprise Plan</Button>
            </>
          )}
          {organization.license_tier === 'free' && (
            <>
              <h3>License Info</h3>
              <p>
                Your organization is on the <strong>Free</strong> plan. (Read more about the available plans in our <a onClick={handleViewHelpClick} style={{ cursor: 'pointer' }}>knowledge base article</a>.)
              </p>
              <ButtonToolbar>
                {(organization.customer || checkoutSuccess) && (
                  <DropdownButton
                    bsStyle='primary'
                    title='Sign Up For Team Plan'
                    key={0}
                  >
                    <MenuItem eventKey='1' onClick={handleCheckoutMonthly}>Monthly Payment ($250/Month)</MenuItem>
                    <MenuItem eventKey='2' onClick={handleCheckoutAnnually}>Yearly Payment ($225/Month)</MenuItem>
                    {((currentUser.email === 'me@jmoe.com') || currentUser.email.endsWith('outerspatial.com')) && (
                      <MenuItem eventKey='3' onClick={handleCheckoutTest}>Test Payment ($5/Month)</MenuItem>
                    )}
                  </DropdownButton>)}
                <Button bsStyle='primary' onClick={handleUpgradeToEnterpriseClick}>
                  Contact Sales About Enterprise Plan
                </Button>
              </ButtonToolbar>
            </>
          )}
        </Panel.Body>
      </Panel>
      {checkoutSuccess && (
        <Modal
          backdrop='static'
          keyboard={false}
          show
        >
          <Modal.Header>
            <Modal.Title>
              Congratulations!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: '0 15px 15px 15px' }}>
            <p>Your organization has been upgraded to the Team plan. Please <a onClick={() => { window.location.reload() }}>refresh the browser</a> to continue.</p>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default OrganizationBillingPlan
