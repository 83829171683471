import { useQuery, gql, useMutation } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Image from 'react-bootstrap/lib/Image'
import Modal from 'react-bootstrap/lib/Modal'
import toast from 'react-hot-toast'

import AppQueries from 'views/app/AppQueries'
import { askOuterSpatialAQuestion } from '../../components/helpScout'
import { buildImageUploadUrl } from 'services/Images'
import NewOrganizationForm from './AddOrganizationForm'
import { segmentTrack } from '../../components/segment'
import UpdateProfileForm from './UpdateProfileForm'
import { useCurrentUser } from 'contexts/CurrentUserContext'

import './signup.css'

const InsertMembershipRequest = gql`
  mutation MyMutation($organization_id: Int) {
    insert_membership_requests_one(object: {
      organization_id: $organization_id
    }) {
      created_at
      id
      organization {
        id
        name
      }
    }
  }
`

const Welcome = ({ currentUser }) => {
  const { data } = useQuery(AppQueries.GetOrganizationDomains)
  const me = useCurrentUser()
  const handleGetInTouchClick = (e) => {
    e.preventDefault()
    askOuterSpatialAQuestion(
      'Adding or Requesting Access To an Organization',
      'Hello, I need help adding an organization to OuterSpatial or requesting accessing to an existing organization...',
      currentUser
    )
  }
  const [insertMembershipRequest] = useMutation(InsertMembershipRequest, {
    onCompleted: (callbackData) => {
      toast.success('The membership request was sent. You will receive an email once it has been approved.')
      segmentTrack('membership_requested', {
        organization_id: callbackData.insert_membership_requests_one.organization.id,
        organization_name: callbackData.insert_membership_requests_one.organization.name
      }, me)
    },
    onError: () => {
      toast.error('The membership request could not be sent. Please try again.')
    },
    refetchQueries: [
      'GetTokenUser'
    ]
  })
  const linkStyle = {
    color: '#41A7D0',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
  let matchingOrgs = []
  let currentUserDomain = null
  const { logout } = useAuth0()
  const onClickRequestAccess = (organizationId) => {
    insertMembershipRequest({
      variables: {
        organization_id: organizationId
      }
    })
  }

  if (data) {
    currentUserDomain = currentUser.email.split('@')[1]
    matchingOrgs = data.organizations.filter(item => item.email_domain === currentUserDomain)
  }

  return (
    <Modal
      backdrop='static'
      dialogClassName='mediaModal-dialog'
      keyboard={false}
      show
    >
      <Modal.Header className='mediaModal-titleBar'>
        <div className='mediaModal-titleBar__content'>
          {(!currentUser.profile || !currentUser.profile.first_name || !currentUser.profile.last_name) &&
            <Modal.Title>Update Your Profile (1/2)</Modal.Title>}
          {(currentUser.profile && currentUser.profile.first_name && currentUser.profile.last_name && matchingOrgs.length === 0) &&
            <Modal.Title>Add Your Organization (2/2)</Modal.Title>}
          {(currentUser.profile && currentUser.profile.first_name && currentUser.profile.last_name && matchingOrgs.length > 0) &&
            <Modal.Title>Request Access (2/2)</Modal.Title>}
          <div className='mediaModal-actions'>
            <Button bsStyle='default' onClick={logout}>Log Out</Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='welcomeModal-body'>
          {(!currentUser.profile || !currentUser.profile.first_name || !currentUser.profile.last_name) &&
            <UpdateProfileForm currentUser={currentUser} />}
          {(currentUser.profile && currentUser.profile.first_name && currentUser.profile.last_name && matchingOrgs.length === 0) &&
            <NewOrganizationForm currentUser={currentUser} currentUserDomain={currentUserDomain} />}
          {(currentUser.profile && currentUser.profile.first_name && currentUser.profile.last_name && matchingOrgs.length === 1) &&
            <div>
              Your email domain, <b><i>@{currentUserDomain}</i></b>, is already used by an OuterSpatial organization. You must request access to the organization to continue.
            </div>}
          {(currentUser.profile && currentUser.profile.first_name && currentUser.profile.last_name && matchingOrgs.length > 1) &&
            <div>
              Your email domain, <b><i>@{currentUserDomain}</i></b>, is already used by multiple OuterSpatial organizations. You must request access to at least one to continue.
            </div>}
          {(currentUser.profile && currentUser.profile.first_name && currentUser.profile.last_name && matchingOrgs.length > 0) &&
            <ul>
              {matchingOrgs.map(item =>
                <li key={item.id}>
                  <div>
                    {(item.logo_image) &&
                      <Image src={buildImageUploadUrl(item.logo_image.id, item.logo_image.uploaded_file, 'small_square')} />}
                    {item.name}
                  </div>
                  <div>
                    {currentUser.membership_requests.find(req => req.organization_id === item.id) &&
                      <Button disabled onClick={() => {}}>Requested</Button>}
                    {!currentUser.membership_requests.find(req => req.organization_id === item.id) &&
                      <Button onClick={() => { onClickRequestAccess(item.id) }}>Request Access</Button>}
                  </div>
                </li>
              )}
            </ul>}
          {(currentUser.profile && currentUser.profile.first_name && currentUser.profile.last_name && matchingOrgs.length > 1) &&
            <div>If you'd like to add a new organization to OuterSpatial or run into any problems requesting access to an organization, <span onClick={handleGetInTouchClick} style={linkStyle}>get in touch</span>. with our customer success team.</div>}
        </div>
      </Modal.Body>
    </Modal>
  )
}

Welcome.propTypes = {
  currentUser: PropTypes.object
}

export default Welcome
