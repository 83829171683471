import { Alert, Button, Glyphicon, Panel } from 'react-bootstrap'
import { gql, useQuery } from '@apollo/client'
import { React } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { CenteredLoader } from 'components/centeredLoader'
import { PageContent, PageHeader, PageLayout } from 'components/pageLayout'
import { useCurrentOrganization } from 'contexts/OrganizationContext'

const getImportQuery = gql`
  query getImport($importId: Int!) {
    shapefile_uploads_by_pk(id: $importId) {
      created_at
      feature_imports(limit: 10) {
        destination_type
        destination_id
      }
      feature_imports_aggregate {
        aggregate {
          count
        }
      }
      geo_import_features(limit: 10) {
        properties
        feature_imports {
          destination_type
          destination_id
        }
      }
      geo_import_features_aggregate {
        aggregate {
          count
        }
      }
      geojson_url
      id
      import_params
      metadata
      name
      object_logs(order_by: {id: asc}) {
        created_at
        message
      }
      preview
      status
    }
  }
`

export const Logs = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const importId = match.params.importId
  const organization = useCurrentOrganization()
  const { data, loading } = useQuery(getImportQuery, {
    variables: {
      importId: importId
    }
  })
  const importObj = data?.shapefile_uploads_by_pk
  const logString = importObj ? importObj.object_logs.map((log) => {
    return `${new Date(`${log.created_at}Z`).toLocaleString('en-US', { timeZone: organization.time_zone })} - ${log.message}`
  }).join('\n') : ''

  return (
    <div className='import-wrap'>
      {loading && <CenteredLoader overlay />}
      <PageLayout>
        <PageHeader>
          <div className='import-header'>
            <div className='import-header__title'>
              <div className='import-header__titleText'>
                <Button
                  bsStyle='link'
                  onClick={() => {
                    history.push(match.path.split(':')[0].slice(0, match.path.split(':')[0].length))
                  }}
                >
                  <Glyphicon glyph='chevron-left' />
                  Back to Imports
                </Button>
                <h1>Step 3 of 3: View Import Logs</h1>
              </div>
            </div>
          </div>
        </PageHeader>
        <PageContent>
          <Alert bsStyle='warning'>
            <strong>Heads up!</strong> For now, you may need to manually refresh this page after running the import to wait for it to complete on the server.
          </Alert>
          <Panel>
            <Panel.Body>
              {importObj && (
                <>
                  <h3>Logs</h3>
                  <pre>{logString}</pre>
                </>
              )}
            </Panel.Body>
          </Panel>
        </PageContent>
      </PageLayout>
    </div>
  )
}
