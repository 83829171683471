import { gql } from '@apollo/client'

export const Queries = {
  GetFeatureStatusOptions: gql`
    query GetFeatureStatusOptions($feature_type: String!) {
      tag_categories(
        where: {name: {_eq: "Status"}}
      ) {
        name
        id
        description
        tag_descriptors(
          where: {feature_type: {_eq: $feature_type}}
        ) {
          id
          name
          key
        }
      }
    }
  `
}

export const Mutations = {
  TouchArea: gql`
    mutation TouchArea($id: Int!) {
      update_areas_by_pk(pk_columns: {id: $id}, _set: {updated_at: "now()"}) {
        id
        updated_at
      }
    } 
  `,
  TouchPOI: gql`
    mutation TouchPOI($id: Int!) {
      update_points_of_interest_by_pk(pk_columns: {id: $id}, _set: {updated_at: "now()"}) {
        id
        updated_at
      }
    } 
  `,
  TouchTrail: gql`
    mutation TouchTrail($id: Int!) {
      update_trails_by_pk(pk_columns: {id: $id}, _set: {updated_at: "now()"}) {
        id
        updated_at
      }
    } 
  `,
  TouchOuting: gql`
    mutation TouchOuting($id: Int!) {
      update_outings_by_pk(pk_columns: {id: $id}, _set: {updated_at: "now()"}) {
        id
        updated_at
      }
    } 
  `,
  DeleteFeatureClosedStatus: gql`
    mutation DeleteFeatureClosedStatus(
      $featureId: Int!,
      $featureType: String!
    ) {
      delete_tags(where: {
        feature_id: {_eq: $featureId},
        feature_type: {_eq: $featureType},
        key: {_eq: "closed"}
      }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  InsertFeatureClosedStatus: gql`
    mutation InsertFeatureClosedStatus(
      $featureId: Int!,
      $featureType: String!
    ) {
      insert_tags(
        objects: {
          feature_id: $featureId,
          feature_type: $featureType,
          key: "closed"
          value: "yes"
        }
      ) {
        affected_rows
        returning {
          id
          feature_id
          feature_type
          updated_at
          value
          key
        }
      }
      delete_tags(where: {
        feature_id: {_eq: $featureId},
        feature_type: {_eq: $featureType},
        key: {_eq: "open"}
      }) {
        affected_rows
      }      
    }
  `
}
