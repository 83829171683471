import { NavLink } from 'react-router-dom'
import Label from 'react-bootstrap/lib/Label'
import PropTypes from 'prop-types'
import React from 'react'

import './sidebarNavigation.css'

const getMainNav = (organization) => {
  let navObjects = [
    {
      exact: true,
      key: 'dashboard',
      path: '',
      title: 'Dashboard'
    },
    { exact: false, key: 'organization', path: '/organization', title: 'Organization' },
    { key: 'bulletin-board', path: '/bulletin-board', title: 'Bulletin Board' },
    { key: 'challenges', path: '/challenges', title: 'Challenges' },
    { key: 'social', path: '/social', title: 'Social' }
  ]
  const navObject = {
    children: [],
    label: 'Beta',
    key: 'locations',
    path: '/locations',
    title: 'Locations'
  }

  if (organization.areas_count.aggregate.count > 0) {
    navObject.children.push({ title: 'Areas', path: '/locations/areas?page=1', key: 'locations_areas' })
  }

  if (organization.points_of_interest_count.aggregate.count > 0) {
    navObject.children.push({ title: 'Points of Interest', path: '/locations/points-of-interest?page=1', key: 'locations_points-of-interest' })
  }

  if (organization.trails_count.aggregate.count > 0) {
    navObject.children.push({ title: 'Trails', path: '/locations/trails?page=1', key: 'locations_trails' })
  }

  navObject.children.push({ title: 'Outings', path: '/locations/outings?page=1', key: 'location_outings' })

  navObjects.push(navObject)

  navObjects = [...navObjects, ...[{
    children: [
      { title: 'Articles', path: '/articles', key: 'content_articles' },
      { title: 'Events', path: '/events', key: 'content_events' }
    ],
    key: 'content',
    path: '/articles',
    title: 'Content'
  }]]

  navObjects = [...navObjects, ...[{
    children: [
      { title: 'Images', path: '/images', key: 'media-images' },
      { title: 'Documents', path: '/documents', key: 'media-documents', keyAtBase: true },
      { title: 'Maps', path: '/maps/paper', key: 'media-maps', keyAtBase: true }
    ],
    key: 'media',
    path: '/images',
    title: 'Media'
  }]]

  return navObjects
}

const isActiveFeature = (key, keyAtBase) => {
  if (key) {
    if (keyAtBase) {
      const pathParts = window.location.pathname.split('/')

      if (pathParts.length > 2) {
        return pathParts[2] === key
      }
    } else {
      return window.location.href.indexOf(`/${key}`) > -1
    }
  }

  return false
}

const SidebarNavigation = ({ match, organization }) => {
  const links = getMainNav(organization).map((link) => {
    if (link.children) {
      return (
        <div key={link.path} className='sidebarNavigation__submenu'>
          <NavLink
            className={`sidebarNavigation__link active-child ${link.key}`}
            to={`${match.url}${link.path}`}
          >
            {link.title}
            {link.label && (
              <span className='label-container'>
                &nbsp;
                <Label bsStyle='info'>{link.label.toUpperCase()}</Label>
              </span>)}
          </NavLink>
          {link.children.length &&
            <div className='sidebarNavigation__submenu-links'>
              {link.children.map((child) => {
                return (
                  <NavLink
                    className={child.key + ' sidebarNavigation__submenu-link' + (isActiveFeature(child.key, child.keyAtBase) ? ' active-child' : '')}
                    exact={child.exact}
                    key={child.path}
                    to={`${match.url}${child.path}`}
                  >
                    {child.title}
                  </NavLink>
                )
              })}
            </div>}
        </div>
      )
    }

    return (
      <NavLink
        className={`sidebarNavigation__link ${link.key}`}
        exact={link.exact}
        key={link.path}
        to={`${match.url}${link.path}`}
      >
        {link.title}
      </NavLink>
    )
  })

  return (
    <div className='sidebarNavigation__links'>
      {links}
    </div>
  )
}

SidebarNavigation.propTypes = {
  match: PropTypes.object,
  organization: PropTypes.object
}

export default SidebarNavigation
