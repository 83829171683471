import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import Button from 'react-bootstrap/lib/Button'
import Dropzone from 'react-dropzone'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import toast from 'react-hot-toast'

import { CenteredLoader } from 'components/centeredLoader'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import debugMessage from 'services/Debug'
import fetch from 'node-fetch'

import './uploader.css'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import { useAuth } from 'contexts/AuthContext'

const UPDATE_AND_ATTACH_IMAGE_MUTATION = gql`
  mutation UpdateAndAttachImage($imageId: Int!, $organizationId: Int!) { 
    update_images_by_pk(pk_columns: {id: $imageId}, _set: {uploaded: true}) {
      id
    }
    insert_image_attachments(objects: [
      {image_id: $imageId, attacheable_type: "Organization", attacheable_id: $organizationId},      
      ]) {
      affected_rows
      returning {
        image_id
      }
    }
  }
`

const ATTACH_DOCUMENT_TO_ORG = gql`
  mutation AttachDocument($mediaFileId: Int!, $organizationId: Int!) { 
    insert_media_file_attachments(objects: [
      {media_file_id: $mediaFileId, attacheable_type: "Organization", attacheable_id: $organizationId},      
      ]) {
      affected_rows
      returning {
        media_file_id
      }
    }
  }
`

const ATTACH_DOCUMENT_TO_FEATURE = gql`
  mutation AttachDocument($mediaFileId: Int!, $organizationId: Int!, $featureType: String!, $featureId: Int!) { 
    insert_media_file_attachments(objects: [      
        {media_file_id: $mediaFileId, attacheable_type: "Organization", attacheable_id: $organizationId},      
        {media_file_id: $mediaFileId, attacheable_type: $featureType, attacheable_id: $featureId},            
      ]) {
      affected_rows
      returning {
        media_file_id
      }
    }
  }
`

const updatePaperMapMutation = gql`
  mutation updatePaperMap($id: Int!, $paperMap: paper_maps_set_input!) {
    update_paper_maps_by_pk(pk_columns: { id: $id }, _set: $paperMap) {
      id
    }
  }
`

const MAX_FILE_SIZE_HUMAN = 100 // 20MB
const MAX_FILE_SIZE = 10.0e+7 // 20MB // NOTE: outerspatial-uploader has a 20MB filesize max (defined in uploader/app.js apolloOptions)
const ALLOWED_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png']
const ALLOWED_DOCUMENT_MIME_TYPES = ['application/pdf', 'image/jpeg', 'image/png']
const ALLOWED_PAPER_MAP_MIME_TYPES = ['application/zip']

function Uploader (props) {
  const { accessToken } = useAuth()
  const currentUser = useCurrentUser()
  const { allowClose, allowUploads = true, feature, files, filetype, onError, onHide, onUploadSuccess, show } = props
  const [dropzoneActive, setDropzoneActive] = useState(false)
  const organization = useCurrentOrganization()
  const [imageIsUploading, setImageIsUploading] = useState(false)
  const [paperMapIsUploading, setPaperMapIsUploading] = useState(false)
  const [documentIsUploading, setDocumentIsUploading] = useState(false)
  const [updatePaperMap] = useMutation(updatePaperMapMutation,{refetchQueries: ['MapsListQuery']})

  const newUploadImage = async (file) => {
    setImageIsUploading(true)
    console.log('filename', file.name)
    const presignedUrl = `${process.env.REACT_APP_API_SERVICE_URL}/uploads/image-presigned-url`
    console.log('presignedUrl', presignedUrl)
    const body = {
      filename: file.name,
      headers: { authorization: `Bearer ${accessToken}` },
      userId: currentUser.id      
    }
    const presignedResult = await fetch(presignedUrl, {
      method: 'POST',
      body: JSON.stringify(body)
    })
    const presignedBody = await presignedResult.json()
    console.log(presignedBody)
    const uploadUrl = presignedBody.url
    const FormData = require('form-data')

    const formData = new FormData()
    // append the fields in presignedPostData in formData
    Object.keys(presignedBody.fields).forEach(key => {
      formData.append(key, presignedBody.fields[key])
    })

    formData.append('file', file)

    const uploadResponse = await fetch(uploadUrl, { method: 'POST', body: formData })
    if (uploadResponse.status === 204) {
      console.log('Upload successful')
      console.log(await uploadResponse.text())
      updateAndAttachImage({ variables: { imageId: presignedBody.id, organizationId: organization.id } })
    }
    setImageIsUploading(false)
  }

  const newPaperMapUpload = async (file) => {
    setPaperMapIsUploading(true)

    const presignedUrl = `${process.env.REACT_APP_API_SERVICE_URL}/uploads/paper-map-presigned-url`
    
    const body = {
      filename: file.name,
      organizationId: organization.id   
    }
  
    const presignedResult = await fetch(presignedUrl, {
      method: 'POST',
      headers: { authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(body)
    })
    const presignedBody = await presignedResult.json()
    console.log(presignedBody)
    const uploadUrl = presignedBody.url

    // upload to s3
    const formData = new FormData()
    // append the fields in presignedPostData in formData
    Object.keys(presignedBody.fields).forEach((key) => {
      formData.append(key, presignedBody.fields[key])
    })

    formData.append('file', file)    

    const uploadResponse = await fetch(uploadUrl, { method: 'POST', body: formData })
    if (uploadResponse.status === 204) {
      const response = await updatePaperMap({ variables: { id: presignedBody.id, paperMap: { status: {status:'Uploaded'} } } })
      console.log(response)
      onUploadSuccess(response.data.update_paper_maps_by_pk.id)
    }
    
    setPaperMapIsUploading(true)
  }

  const newUploadDocumentToOrg = async (file) => {
    console.log('filename', file.name)
    setDocumentIsUploading(true)
    const body = {
      filename: file.name,
      userId: currentUser.id
    }

    const presignedUrl = `${process.env.REACT_APP_API_SERVICE_URL}/uploads/media-file-presigned-url`
    console.log('presignedUrl', presignedUrl)
    try {
      const presignedResult = await fetch(presignedUrl, {
        method: 'POST',
        body: JSON.stringify(body)
      })

      const presignedBody = await presignedResult.json()
      console.log(presignedBody)
      const uploadUrl = presignedBody.url
      const FormData = require('form-data')
      const formData = new FormData()

      // append the fields in presignedPostData in formData
      Object.keys(presignedBody.fields).forEach(key => {
        formData.append(key, presignedBody.fields[key])
      })

      // append the file
      formData.append('Content-Type', file.type)
      formData.append('file', file)

      const uploadResponse = await fetch(uploadUrl, { method: 'POST', body: formData })

      if (uploadResponse.status === 204) {
        console.log('Upload successful')
        console.log(await uploadResponse.text())
        attachDocumentToOrg({ variables: { mediaFileId: presignedBody.id, organizationId: organization.id } })
      }
    } catch (e) {
      console.error(e)
    }
    toast.error('Upload failed')
    setDocumentIsUploading(false)
  }

  const newUploadDocumentToFeature = async (file) => {
    console.log(file)
    setDocumentIsUploading(true)
    const body = {
      filename: file.name,
      userId: currentUser.id
    }
    const presignedResult = await fetch(`${process.env.REACT_APP_API_SERVICE_URL}/uploads/media-file-presigned-url`, {
      method: 'POST',
      body: JSON.stringify(body)
    })
    const presignedBody = await presignedResult.json()
    console.log(presignedBody)
    const uploadUrl = presignedBody.url
    const FormData = require('form-data')
    const formData = new FormData()

    // append the fields in presignedPostData in formData
    Object.keys(presignedBody.fields).forEach(key => {
      formData.append(key, presignedBody.fields[key])
    })

    // append the file
    formData.append('Content-Type', file.type)
    formData.append('file', file)

    const uploadResponse = await fetch(uploadUrl, { method: 'POST', body: formData })

    if (uploadResponse.status === 204) {
      console.log('Upload successful')
      console.log(await uploadResponse.text())
      console.log(feature)
      attachDocumentToFeature({ variables: { mediaFileId: presignedBody.id, featureType: feature.class_name, featureId: feature.id, organizationId: organization.id } })
    }
    setDocumentIsUploading(false)
  }

  // This is executed against default Hasura GraphQL Client
  // const [attachImageToOrganization, { loading: imageDataIsProcessing }] = useMutation(ATTACH_IMAGE_TO_ORG_MUTATION)

  const [updateAndAttachImage, { loading: imageDataIsProcessing }] = useMutation(UPDATE_AND_ATTACH_IMAGE_MUTATION, {
    onCompleted: (data) => {
      console.log(data)
      if (data.insert_image_attachments.affected_rows === 1) {
        toast.success('Upload complete')
        onUploadSuccess(data.insert_image_attachments.returning[0].image_id)
        onHide()
      } else {
        toast.error('There was an issue associating the uploaded image to your Organization.')
      }
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The image could not be uploaded.')
    }
  })

  const [attachDocumentToOrg, { loading: documentDataIsProcessing }] = useMutation(ATTACH_DOCUMENT_TO_ORG, {
    refetchQueries: ['DocumentsList', 'FeatureDocumentsList'],
    onCompleted: (data) => {
      console.log(data)
      if (data.insert_media_file_attachments.affected_rows === 1) {
        toast.success('Upload complete')
        onUploadSuccess(data.insert_media_file_attachments.returning[0].media_file_id)
        onHide()
      } else {
        toast.error('There was an issue associating the uploaded document to your Organization.')
      }
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The document could not be uploaded.')
    }
  })

  const [attachDocumentToFeature, { loading: documentAttachedToOrgIsLoading }] = useMutation(ATTACH_DOCUMENT_TO_FEATURE, {
    refetchQueries: ['DocumentsList', 'FeatureDocumentsList'],
    onCompleted: (data) => {
      if (data.insert_media_file_attachments.affected_rows === 2) {
        toast.success('Upload complete')
        onUploadSuccess(data.insert_media_file_attachments.returning[0].media_file_id)
        onHide()
      } else {
        toast.error('There was an issue associating the uploaded document to your Organization.')
      }
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The document could not be uploaded.')
    }
  })

  const onDragEnter = () => {
    setDropzoneActive(true)
  }

  const onDragLeave = () => {
    setDropzoneActive(false)
  }

  const onDrop = (files = []) => {
    onDragLeave()
    addFiles(files)
  }

  const validateFileForUpload = (file, mimetypes) => {
    const errors = []

    if (file.size > MAX_FILE_SIZE) {
      errors.push(`File too large. Please select an image smaller than ${MAX_FILE_SIZE_HUMAN}MB.`)
    }

    if (mimetypes.indexOf(file.type) === -1) {
      let error = ''

      if (file.type === '') {
        error = 'This file type is not supported. Please choose a '
      } else {
        error = `File with type of "${file.type}" is not allowed. Please choose a `
      }

      if (filetype === 'Image') {
        error += 'JPEG or PNG image.'
      } else if (filetype === 'Document') {
        error += 'JPEG/PNG image or PDF file.'
      } else if( filetype === 'PaperMap') {
        error += 'Zip file.'
      }

      errors.push(error)
    }

    return errors
  }

  const handleAddFiles = (event) => {
    const files = event.target.files || []
    addFiles(files)
  }

  const addFiles = (files) => {
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      let errors = []

      if (filetype === 'Image') {
        errors = validateFileForUpload(file, ALLOWED_IMAGE_MIME_TYPES)
      } else if (filetype === 'Document') {
        errors = validateFileForUpload(file, ALLOWED_DOCUMENT_MIME_TYPES)
      } else if (filetype === 'PaperMap') {
        errors = validateFileForUpload(file, ALLOWED_PAPER_MAP_MIME_TYPES)
      }

      onError(errors)

      if (!errors.length) {
        if (filetype === 'Image') {
          newUploadImage(file)
        } else if (filetype === 'Document') {
          if (feature && feature?.class_name && feature?.id) {
            newUploadDocumentToFeature(file)
          } else {
            newUploadDocumentToOrg(file)
          }
        } else if (filetype === 'PaperMap') {
          newPaperMapUpload(file)                    
        }
      }
    }
  }

  return (
    <div className={`${(show ? 'show ' : '')}uploader`}>
      {(paperMapIsUploading || imageIsUploading || imageDataIsProcessing || documentIsUploading || documentAttachedToOrgIsLoading || documentDataIsProcessing) &&
        <CenteredLoader overlay />}
      <Dropzone
        disabled={!allowUploads}
        maxSize={MAX_FILE_SIZE}
        multiple={false}
        noClick
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {({ getInputProps, getRootProps }) => (
          <div {...getRootProps()} className={(dropzoneActive ? 'over ' : '') + 'uploader-container'}>
            {allowClose &&
              <Button bsStyle='link' className='uploader-close' onClick={onHide}>
                <Glyphicon glyph='remove' />
              </Button>}
            {(paperMapIsUploading || imageIsUploading || imageDataIsProcessing || documentIsUploading || documentAttachedToOrgIsLoading || documentDataIsProcessing) &&
              <div className='uploader-container-inner'>
                Uploading file…
              </div>}
            {!paperMapIsUploading && !imageIsUploading && !imageDataIsProcessing && !documentIsUploading && !documentAttachedToOrgIsLoading && !documentDataIsProcessing &&
              <div className='uploader-container-inner'>
                <h3>Drop a file here to upload.</h3>
                <p>Or</p>
                <label className='btn btn-default' htmlFor='file-input'>Select File</label>
                <p>Maximum upload file size: {MAX_FILE_SIZE_HUMAN}MB.</p>
                <input {...getInputProps()} id='file-input' onChange={handleAddFiles} value={files} />
              </div>}
          </div>
        )}
      </Dropzone>
    </div>
  )
}

Uploader.propTypes = {
  allowClose: PropTypes.bool,
  allowUploads: PropTypes.bool,
  feature: PropTypes.object, // This used for media_file_attachments. Default is class_name = 'Organization' && id = organization.id
  files: PropTypes.array,
  filetype: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool
}

export default Uploader
