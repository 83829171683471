import React from 'react'
import PropTypes from 'prop-types'

import FeatureTagSet from '../../../../../components/featureTagSet'
import FeatureSeasons from './Feature.Seasons'

const getSelectedSeasons = (feature) => {
  let tags = feature.tags.filter(tag => (tag.tag_descriptor?.category?.name === 'Seasonality') && tag.value === 'yes').map(tag => tag.key)

  if (tags.length === 0) {
    tags = null
  }

  return tags
}
const handleSeasonChange = (seasons, onSeasonChange) => {
  let newSeasons = seasons

  if (newSeasons.length === 4) {
    newSeasons = ['year_round']
  }

  if (onSeasonChange) {
    onSeasonChange(newSeasons)
  }
}

const ActivitiesAccessibilityPointsOfInterest = ({ feature, onSeasonChange, onAllowedActivitiesChange, onAccessibilityChange }) => {
  return (
    <div>
      <label className='control-label'>Seasons of Availability</label>
      <FeatureSeasons onSeasonChange={(seasons) => handleSeasonChange(seasons, onSeasonChange)} selectedSeasons={getSelectedSeasons(feature)} />
      <div className='form-group'>
        <label className='control-label'>Allowed Activities</label>
        <FeatureTagSet
          categoryName='Activities'
          featureType={feature.class_name}
          selectedTags={feature.tags}
          onChange={onAllowedActivitiesChange}
          placeholder='What activities are permitted?'
        />
      </div>
      <div className='form-group'>
        <label className='control-label'>Accessibility</label>
        <FeatureTagSet
          categoryName='Accessibility'
          featureType={feature.class_name}
          selectedTags={feature.tags}
          onChange={onAccessibilityChange}
        />
      </div>
    </div>
  )
}

ActivitiesAccessibilityPointsOfInterest.propTypes = {
  feature: PropTypes.object,
  onAllowedActivitiesChange: PropTypes.func,
  onAccessibilityChange: PropTypes.func,
  onSeasonChange: PropTypes.func
}

export default ActivitiesAccessibilityPointsOfInterest
