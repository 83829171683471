import React from 'react'
import t from 'prop-types'

const Content = ({ children }) => {
  return (
    <div className='contentLayout-content'>
      {children}
    </div>
  )
}

Content.propTypes = {
  children: t.any
}

export default Content
