import { useFormikContext } from 'formik'
import Button from 'react-bootstrap/lib/Button'
import HelpBlock from 'react-bootstrap/lib/HelpBlock'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React from 'react'

import FormikGroup from '../../../components/fieldGroup/FormikGroup'
import HelpDropdown from '../../../components/helpDropdown'

const OrganizationProfileSocial = ({ isOrganizationAdmin, isSuperAdmin }) => {
  const { submitForm, dirty, isValid, isSubmitting } = useFormikContext()
  const enabled = dirty && isValid && !isSubmitting

  const handleWalkthroughVideoSelect = () => {
    if (window.Beacon) {
      const beaconInfo = window.Beacon('info')
      // Organizations
      var messageId = 'eabc8ca6-b023-491c-b4bc-7829bc1ed142'

      if (beaconInfo && beaconInfo.beaconId !== '8bdf21e5-c4fc-43d1-8bd3-e17a0cf162f0') {
        // Sales
        messageId = '0e48e9ee-1a83-4920-b9a7-de993fc55cf0'
      }

      window.Beacon && window.Beacon('show-message', messageId, {
        force: true
      })
    }
  }

  return (
    <div>
      <Panel>
        <Panel.Heading>
          <div
            className='pull-right'
          >
            <HelpDropdown
              handleWalkthroughVideoSelect={handleWalkthroughVideoSelect}
            />
            {(isOrganizationAdmin || isSuperAdmin) && (
              <Button
                bsStyle='primary'
                disabled={!enabled}
                form='organization-social-form'
                onClick={submitForm}
              >Save
              </Button>
            )}
          </div>
        </Panel.Heading>
        <Panel.Body>
          <h4>Social Profiles</h4>
          <FormikGroup
            id='facebook'
            name='facebook'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='Facebook'
            type='text'
          />
          <HelpBlock>Example: https://www.facebook.com/username</HelpBlock>
          <FormikGroup
            id='instagram'
            name='instagram'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='Instagram'
            type='text'
          />
          <HelpBlock>Example: https://www.instagram.com/username</HelpBlock>
          <FormikGroup
            id='linkedin'
            name='linkedin'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='LinkedIn'
            type='text'
          />
          <HelpBlock>Example: https://www.linkedin.com/company/organizationname</HelpBlock>
          <FormikGroup
            id='pinterest'
            name='pinterest'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='Pinterest'
            type='text'
          />
          <HelpBlock>Example: https://www.pinterest.com/organizationname</HelpBlock>
          <FormikGroup
            id='twitter'
            name='twitter'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='Twitter'
            type='text'
          />
          <HelpBlock>Example: https://www.twitter.com/username</HelpBlock>
          <FormikGroup
            id='flickr'
            name='flickr'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='Flickr'
            type='text'
          />
          <HelpBlock>Example: https://www.flickr.com/photos/username</HelpBlock>
          <FormikGroup
            id='youtube'
            name='youtube'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='YouTube'
            type='text'
          />
          <HelpBlock>Example: https://www.youtube.com/user/username</HelpBlock>
          <FormikGroup
            id='vimeo'
            name='vimeo'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='Vimeo'
            type='text'
          />
          <HelpBlock>Example: https://www.vimeo.com/username</HelpBlock>
          <br />
          <h4>Donations and Newsletter Outreach</h4>
          <FormikGroup
            id='donate'
            name='donate'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='Donate'
            type='text'
          />
          <FormikGroup
            id='newsletter'
            name='newsletter'
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            label='Newsletter Sign-up URL'
            type='text'
          />

        </Panel.Body>
      </Panel>
    </div>
  )
}

OrganizationProfileSocial.propTypes = {
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default OrganizationProfileSocial
