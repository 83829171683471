import { gql } from '@apollo/client'

export const LikePost = gql`
    mutation LikePost(
        $post_id: Int!, $likeable_type: String = "Post") {
        insert_likes_one(object: {likeable_id: $post_id, likeable_type: $likeable_type}) {
            created_at
            id
            likeable_id
            likeable_type
            liker_id
            liker_type
        }
    }
        
`

export default LikePost
