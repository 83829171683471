import React from 'react'
import t from 'prop-types'
import { ButtonGroup, Button, Glyphicon } from 'react-bootstrap'

import './actionBar.css'
import MediaGalleryFilter from '../../../../components/media/gallery/MediaGalleryFilter'

const MediaActionBar = ({
  allowDeleteAll = true,
  allowFilter = true,
  allowInsert,
  allowSelection,
  allowSort = true,
  allowTableMode = true,
  allowUploads = true,
  filterByFeature,
  onDeleteAll,
  onDeselectAll,
  onFilter,
  onHelpClick,
  onInsertClick,
  onSort,
  onToggleTable,
  onUploadClick,
  query,
  selectedFeature,
  selectedMediaCount,
  showUploader,
  sortOldest = false,
  tableMode = false
}) => {
  return (
    <div className='mediaActionBar-wrap'>
      {!!allowTableMode &&
        <ButtonGroup style={{ marginRight: '8px' }}>
          <Button active={!tableMode} onClick={() => onToggleTable(false)}>
            <Glyphicon glyph='th' />
          </Button>
          <Button active={tableMode} onClick={() => onToggleTable(true)}>
            <Glyphicon glyph='th-list' />
          </Button>
        </ButtonGroup>}
      <MediaGalleryFilter
        filterByFeature={filterByFeature}
        onFilter={onFilter}
        query={query}
        selectedFeature={selectedFeature}
        showFilters={!!allowFilter && (selectedMediaCount < 1)}
      />
      {!!allowSelection && (selectedMediaCount > 0) &&
        <Button
          className='mediaActionBar-deselectButton'
          bsStyle='link'
          onClick={onDeselectAll}
          disabled={!selectedMediaCount}
        >
          Deselect All
        </Button>}
      <div className='mediaActionBar-actionsRight'>
        {allowSort && (selectedMediaCount < 1) &&
          <span>
            Sort by:
            <ButtonGroup style={{ marginLeft: '8px' }}>
              <Button active={!sortOldest} onClick={() => onSort(false)}>Newest</Button>
              <Button active={sortOldest} onClick={() => onSort(true)}>Oldest</Button>
            </ButtonGroup>
          </span>}
        {allowInsert &&
          <Button bsStyle='info' onClick={onInsertClick}>Select from Media...</Button>}
        {allowUploads && (!showUploader || allowInsert) && (selectedMediaCount < 1) &&
          <Button bsStyle='primary' onClick={onUploadClick}>Upload</Button>}
        {!!allowSelection && !!allowDeleteAll && (selectedMediaCount > 0) &&
          <Button
            bsStyle='danger'
            onClick={onDeleteAll}
            disabled={!selectedMediaCount}
          >
            Delete
          </Button>}
      </div>
    </div>
  )
}

MediaActionBar.propTypes = {
  allowDeleteAll: t.bool,
  allowInsert: t.bool,
  allowFilter: t.bool,
  allowSelection: t.bool,
  allowSort: t.bool,
  allowTableMode: t.bool,
  allowUploads: t.bool,
  filterByFeature: t.bool,
  onDeleteAll: t.func,
  onDeselectAll: t.func,
  onFilter: t.func,
  onHelpClick: t.func,
  onInsertClick: t.func,
  onSort: t.func,
  onToggleTable: t.func,
  onUploadClick: t.func,
  query: t.object,
  selectedFeature: t.object,
  selectedMediaCount: t.number,
  showUploader: t.bool,
  sortOldest: t.bool,
  tableMode: t.bool
}

export default MediaActionBar
