import { Map } from '@outerspatial/outerspatial-map'
import PropTypes from 'prop-types'
import React from 'react'

import { useCurrentOrganization } from 'contexts/OrganizationContext'

const PaperMapEmbed = ({ paperMap }) => {
  const maxLat = parseFloat(paperMap.status.extent_max_lat)
  const maxLng = parseFloat(paperMap.status.extent_max_lon)
  const minLat = parseFloat(paperMap.status.extent_min_lat)
  const minLng = parseFloat(paperMap.status.extent_min_lon)
  const bounds = {
    coordinates: [
      [
        [minLng, maxLat],
        [minLng, minLat],
        [maxLng, minLat],
        [maxLng, maxLat],
        [minLng, maxLat]
      ]
    ],
    type: 'Polygon'
  }
  const currentOrganization = useCurrentOrganization()
  let tileBucket = process.env.REACT_APP_STAGE_NAME === 'development' ? 'dev-' : `staging-`
  let tileUrls = [
    `https://${tileBucket}cdn.outerspatial.com/public/maps/${paperMap.status.id}/tiles_georef/{z}/{x}/{y}.png`
  ]
  if (process.env.REACT_APP_STAGE_NAME === 'production') {
    tileUrls = [
      `https://maps-a.outerspatial.com/public/maps/${paperMap.status.id}/tiles_georef/{z}/{x}/{y}.png`,
      `https://maps-b.outerspatial.com/public/maps/${paperMap.status.id}/tiles_georef/{z}/{x}/{y}.png`,
      `https://maps-c.outerspatial.com/public/maps/${paperMap.status.id}/tiles_georef/{z}/{x}/{y}.png`,
      `https://maps-d.outerspatial.com/public/maps/${paperMap.status.id}/tiles_georef/{z}/{x}/{y}.png`
    ]
  }
  console.log('tileUrls',tileUrls)
  if (paperMap.status && ['Processed','Uploading tiles','Uploading tiles complete'].includes(paperMap.status.status))
    return (
      <Map
        accessToken={process.env.REACT_APP_MAPBOX_KEY}
        bounds={bounds}
        onLoad={(mapboxglMap) => {
          mapboxglMap.addSource('paper-map', {
            scheme: 'tms',
            tiles: tileUrls,
            tileSize: 256,
            type: 'raster'
          })
          mapboxglMap.addLayer({
            id: 'paper-map',
            maxzoom: paperMap.status.max_zoom,
            minzoom: paperMap.status.min_zoom,
            source: 'paper-map',
            type: 'raster'
          })
        }}
        organizationId={currentOrganization.id}
        outerSpatialApiUrl={process.env.REACT_APP_GRAPHQL_URL}
      />
    )
}

PaperMapEmbed.propTypes = {
  paperMap: PropTypes.object.isRequired
}

export default PaperMapEmbed
