import React from 'react'
import t from 'prop-types'

import PaperMap from 'views/maps/paperMap/PaperMap'

const FeaturePaperMap = (props) => {
  const { match, feature } = props

  return (
    <div className='featurePaperMap'>
      <PaperMap feature={feature} match={match} />
    </div>
  )
}

FeaturePaperMap.propTypes = {
  feature: t.object,
  match: t.object
}

export default FeaturePaperMap
