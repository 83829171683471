import PropTypes from 'prop-types'
import React from 'react'
import Cleave from 'cleave.js/dist/cleave-react'
// eslint-disable-next-line
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us'

const PhoneInput = (props) => {
  const { value, ...rest } = props
  const noNilValue = value || ''

  return (
    <Cleave
      {...rest}
      className='form-control'
      options={{
        delimiter: '-',
        phone: true,
        phoneRegionCode: 'US'
      }}
      value={noNilValue}
    />
  )
}

PhoneInput.propTypes = {
  value: PropTypes.string
}

export default PhoneInput
