import { Auth0Provider } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory()
  const audience = 'Hasura'
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const domain = process.env.REACT_APP_AUTH0_DOMAIN

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      audience={audience}
      clientId={clientId}
      domain={domain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={`${window.location.origin}/login`}
      screen_hint={window.location.search && window.location.search.includes('screen_hint=signup') ? 'signup' : 'login'}
    >
      {children}
    </Auth0Provider>
  )
}

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.object
}

export default Auth0ProviderWithHistory
