import { gql } from '@apollo/client'

export const InsertPostComment = gql`
    mutation InsertPostComment($post_text: String = "", $parent_id: Int!) {
        insert_posts_one(object: {post_text: $post_text, parent_id: $parent_id, is_admin: true}){
            id
            created_at
            post_text
            parent_id
            user_id
            user{
                id
                profile {
                    id
                    name
                    photo_file
                }
            }
        }
    }
`

export default InsertPostComment
