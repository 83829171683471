import { Button, Glyphicon } from 'react-bootstrap'

import { gql, useApolloClient } from '@apollo/client'
import _ from 'lodash'
import Async from 'react-select/async'
import PropTypes from 'prop-types'
import React from 'react'

import { useCurrentOrganization } from 'contexts/OrganizationContext'

import './areaDestinationSet.css'

const PointOfInterestSearchQuery = gql`
  query PointOfInterestSearchQuery($areaId: Int!, $name: String, $organizationId: Int!) {
    points_of_interest(
      order_by: {name: asc},
      where: {
        _and: [
          {area_id: {_eq: $areaId}}
          {name: {_ilike: $name}}
          {stewardships: {organization_id: {_eq: $organizationId}}}
        ]
      }
    ) {
      id
      name
    }
  }
`

const AreaDestinationSet = (props) => {
  const client = useApolloClient()
  const organization = useCurrentOrganization()
  const {
    areaDestinations = [],
    areaId,
    onAdd,
    onRemove,
    placeholder
  } = props

  const fetchOptions = (query, callback) => {
    client
      .query({
        query: PointOfInterestSearchQuery,
        variables: {
          areaId: areaId,
          name: (query && query.length > 0) ? `%${query}%` : '%%',
          organizationId: organization.id
        }
      })
      .then(({ data }) => {
        const queryResults = data.points_of_interest
          .map((pointOfInterest) => {
            return {
              content: pointOfInterest,
              label: pointOfInterest.name,
              value: pointOfInterest.id
            }
          })
        return callback(queryResults)
      })
  }

  const filter = (option) => {
    return areaDestinations.find((areaDestination) => {
      return areaDestination.point_of_interest.id === option.data.content.id
    }) === undefined
  }

  const _fetchOptions = _.debounce(fetchOptions, 200)

  const handleChange = (value) => {
    if (value.content.id !== null) {
      onAdd({
        pointOfInterest: value.content
      })
    }
  }

  const search = (query, callback) => {
    _fetchOptions(query, callback)
  }

  return (
    <div className='areaDestinationSet'>
      {areaDestinations.length > 0 && (
        <div className='areaDestinationSet-list'>
          {areaDestinations.map((areaDestination) => {
            return (
              <div
                className='areaDestinationSet-listItem'
                key={areaDestination.point_of_interest.id}
              >
                <p className='areaDestinationSet-listItem__title'>
                  {areaDestination.point_of_interest.name}
                </p>
                <div className='areaDestinationSet-listItem__actions'>
                  <Button
                    bsSize='xsmall'
                    bsStyle='link'
                    onClick={() => onRemove(areaDestination)}
                  >
                    <Glyphicon glyph='remove' />
                  </Button>
                </div>
              </div>
            )
          })}
        </div>)}
      {props.onAdd &&
        <Async
          cacheOptions
          defaultOptions
          filterOption={filter}
          isClearable={false}
          loadOptions={search}
          onChange={(value) => handleChange(value)}
          placeholder={placeholder || 'Search points of interest...'}
          value={null}
        />}
    </div>
  )
}

AreaDestinationSet.propTypes = {
  areaDestinations: PropTypes.array, // list of area destinations already set
  areaId: PropTypes.number, // id of area to search for destinations
  onAdd: PropTypes.func, // add/select callback
  onRemove: PropTypes.func, // remove callback
  placeholder: PropTypes.string // text placeholder for input
}

export default AreaDestinationSet
