import { gql } from '@apollo/client'

export const Fragments = {
  MembershipDetails: gql`
    fragment MembershipDetails on memberships {
      id
      role
      user_email {
        email
      }
      user {
        manager_logins: auth_logins_aggregate(
          where: {
            event_client_name: {
              _eq: "OuterSpatial Manager"
            }
          }
        ) {
          aggregate {
            count
          }
        }        
        id
        invitation_accepted_at
        invitation_created_at
        profile {
          first_name
          id
          last_name
          name
        }
      }
    }
  `
}

export const Mutations = {
  DeleteMembershipById: gql`
    mutation DeleteMembershipById($membershipId: Int!) {
      delete_memberships(where: {id: {_eq: $membershipId}}) {
        returning {
          id
        }
      }
    }
  `,
  UpdateMembership: gql`
    mutation UpdateMembership(
      $membershipId: Int!,
      $role: String!
    ) {
      update_memberships(
        where: {id: {_eq: $membershipId}},
        _set: {
          role: $role
        }
      ) {
        returning {
          id
          role
        }
      }
    }
  `
}

export const Queries = {
  GetMembershipById: gql`
    query GetMembershipById($membershipId: Int!) {
      memberships(where: {id: {_eq: $membershipId}}) {
        ...MembershipDetails
      }
    }
    ${Fragments.MembershipDetails}
  `,
  GetMembershipRequestsByOrganizationId: gql`
    query GetMembershipRequestsByOrganizationId($organizationId: Int!) {
      membership_requests(where: {organization_id: {_eq: $organizationId}}) {
        id
        created_at
        user_id
        organization_id
        user_email {
          email
        }
        user {
          id          
          profile {
            name
          }
        } 
        status
      }
    }
  `,
  GetMembershipsByOrganizationId: gql`
    query GetMembershipsByOrganizationId($organizationId: Int!) {
      memberships(where: {organization_id: {_eq: $organizationId}}) {
        ...MembershipDetails
      }
    }
    ${Fragments.MembershipDetails}
  `,
  GetOrganizationById: gql`
    query GetOrganizationById($id: Int!) {
      organizations(where: {id: {_eq: $id}}) {
        membership_limit
        memberships {
          id
          primary
          role
          user {            
            id
            profile {
              first_name
              id
              last_name
              name
            }
          }
          user_email {
            email
          }
          user_id
        }
      }
    }
  `
}
