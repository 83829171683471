import { useQuery } from '@apollo/client'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactSelect from 'react-select'

import { Queries } from './FeatureTagSetOperations'
import debugMessage from 'services/Debug'

const FeatureTagSet = (props) => {
  const { categoryName, excludedTagKeys, featureType, name = '', onChange, placeholder, selectedTags } = props
  const [categoryTagsOptions, setCategoryTagsOptions] = useState([])
  const [categoryTagsSelected, setCategoryTagsSelected] = useState([])

  // The goal here is to receive `selectedTags` empty on load and upon making the GraphQL call
  // the onCompleted will filter down all options to the existing `outingTags` and then
  //  perform the handleChange callback, which will set the

  const handleChange = (tags) => {
    // Ensure that the state and data sent to the call back is always an array (even if empty), not null.
    if (tags !== null) {
      setCategoryTagsSelected(tags)
      onChange(tags)
    } else {
      setCategoryTagsSelected([])
      onChange([])
    }
  }

  // The Tag Category with all associated tag Descriptors are needed before the render
  //  to pass into <ReactSelect> as `options` prop

  // Query to get Queries.GetTagData for filtering down the entire Tag array and displaying each FeatureTagSet
  // Remember, we are not populating the database with Outing tags, we are just treating Outings as Trails,
  //  so the feature_type for an Outing is `Trail`
  const { loading: loadingTagCategories } = useQuery(
    Queries.GetTagData, {
      variables: {
        feature_type: featureType,
        tag_category: categoryName
      },
      onCompleted: (data) => {
        const tagCategory = data.tag_categories[0]

        if (tagCategory.tag_descriptors && tagCategory.tag_descriptors.length > 0) {
          let availableCategories = tagCategory.tag_descriptors
            .map((descriptor) => {
              return {
                key: descriptor.key,
                label: descriptor.name,
                value: descriptor.key
              }
            })
            .sort((a, b) => {
              const nameA = a.label.toUpperCase()
              const nameB = b.label.toUpperCase()

              if (nameA < nameB) {
                return -1
              }

              if (nameA > nameB) {
                return 1
              }

              return 0
            })

          if (excludedTagKeys && excludedTagKeys.length > 0) {
            excludedTagKeys.forEach(key => {
              availableCategories = availableCategories.filter((category) => {
                return category.key !== key
              })
            })
          }

          setCategoryTagsOptions(availableCategories)

          if (selectedTags && selectedTags.length > 0) {
            const categoryTagsSelected = selectedTags
              .filter(selectedTag => {
                return availableCategories.map(t => t.key).includes(selectedTag.key || selectedTag)
              })
              .map((tag) => {
                const descriptor = _.find(tagCategory.tag_descriptors, {
                  key: tag.key || tag
                })
                return {
                  key: descriptor.key,
                  label: descriptor.name,
                  value: descriptor.key
                }
              })

            handleChange(categoryTagsSelected)
          }
        }
      },
      onError: (error) => {
        debugMessage(error)
      }
    }
  )

  return (
    <div className='featureTagSet'>
      <ReactSelect
        isClearable={false}
        isDisabled={categoryTagsOptions?.length === 0}
        isLoading={loadingTagCategories}
        isMulti
        name={name}
        onChange={handleChange}
        options={categoryTagsOptions}
        placeholder={placeholder || 'Select...'}
        styles={{ menu: styles => ({ ...styles, zIndex: 10000 }) }}
        value={categoryTagsSelected}
      />
    </div>
  )
}

FeatureTagSet.propTypes = {
  categoryName: PropTypes.string.isRequired, // tag category name to map to
  excludedTagKeys: PropTypes.array, // tag keys to exclude from the list
  featureType: PropTypes.string.isRequired, // feature.class_name
  name: PropTypes.string, // Name of the HTML Input (optional - without this, no input will be rendered)
  onChange: PropTypes.func.isRequired, // handler from the parent to send current selected categoryName tags
  placeholder: PropTypes.string, // placeholder text for the empty categoryName tag selector
  selectedTags: PropTypes.array // current selected tags (all of them)
}

export default FeatureTagSet
