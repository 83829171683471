import Dropdown from 'react-bootstrap/lib/Dropdown'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'

const HelpDropdown = (props) => {
  const {
    handleWalkthroughVideoSelect,
    handleViewHelpSelect
  } = props

  return (
    <Dropdown
      id='help-dropdown'
      pullRight
    >
      <Dropdown.Toggle
        bsStyle='default'
      >
        Help
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {typeof handleViewHelpSelect === 'function' && (
          <MenuItem
            eventKey='1'
            onSelect={handleViewHelpSelect}
          >
            <Glyphicon
              glyph='file'
            />
            &nbsp;
            View Help
          </MenuItem>)}
        {typeof handleWalkthroughVideoSelect === 'function' && (
          <MenuItem
            eventKey='2'
            onSelect={handleWalkthroughVideoSelect}
          >
            <Glyphicon
              glyph='play'
            />
            &nbsp;
            Play Walkthrough Video
          </MenuItem>)}
      </Dropdown.Menu>
    </Dropdown>
  )
}

HelpDropdown.propTypes = {
  handleWalkthroughVideoSelect: PropTypes.func,
  handleViewHelpSelect: PropTypes.func
}

export default HelpDropdown
