import PropTypes from 'prop-types'
import React from 'react'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import Label from 'react-bootstrap/lib/Label'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import { Link } from 'react-router-dom'
import FeatureStatusDropdown from 'components/featureStatusDropdown'
import './tableRow.css'

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const typeMatch = (types, featureType) => types.indexOf(featureType) > -1

const FeaturesTableRow = ({
  displayParentArea,
  feature,
  featureType,
  isAreaView,
  isSuperAdmin,
  onArchived,
  onDeleteTrail,
  onEdit,
  onPublished,
  organizationId
}) => {
  const outingLength = (typeMatch(['outings'], featureType) && typeof feature.route?.length_meters === 'number') ? numberWithCommas(Math.ceil((feature.route.length_meters * 0.000621371) * 10) / 10) : null
  const trailLength = (typeMatch(['trails'], featureType) && typeof feature.length_meters === 'number') ? numberWithCommas(Math.ceil((feature.length_meters * 0.000621371) * 10) / 10) : null
  const acres = typeMatch(['areas'], featureType) ? numberWithCommas(Math.round(feature.size?.acres)) : null
  const tdAreaName = feature?.area?.name?.length > 0 ? feature.area.name : 'none'
  const tdAreaClasses = tdAreaName !== 'none' ? 'featuresTable--area' : 'featuresTable--area none'
  const tdName = !!feature.name && feature.name.length > 0 ? feature.name : 'Unnamed'
  const tdNameClasses = tdName !== 'Unnamed' ? 'featuresTable--name' : 'featuresTable--name none'
  const simpleFeature = {
    id: feature.id,
    type: featureType === 'points_of_interest' ? 'points-of-interest' : featureType
  }
  const actions = [{ eventKey: 'edit', handleOnClick: () => onEdit(simpleFeature), text: 'Edit' }]
  const archiveAction = { eventKey: 'archived', handleOnClick: () => onArchived(feature), text: 'Archive', value: 'Archived' }
  const publishAction = { eventKey: 'published', handleOnClick: () => onPublished(feature), text: 'Publish', value: 'Published' }

  if (feature.visibility === 'Archived') {
    actions.push(publishAction)
  } else if (feature.visibility === 'Draft') {
    actions.push(archiveAction)
    actions.push(publishAction)
  } else if (feature.visibility === 'Published') {
    actions.push(archiveAction)
  }

  if (typeMatch(['trails'], featureType) && isSuperAdmin) {
    actions.push({ eventKey: 'delete', handleOnClick: () => onDeleteTrail(feature), text: 'Delete (Super Admin)' })
  }

  return (
    <tr className='featuresTable-tableRow'>
      <td className={tdNameClasses}>
        {typeMatch(['outings'], simpleFeature.type) &&
          <Link to={`/${organizationId}/outings/${simpleFeature.id}/info`}>
            {tdName}
          </Link>}
        {!typeMatch(['outings'], simpleFeature.type) &&
          <Link to={`/${organizationId}/locations/${simpleFeature.type}/${simpleFeature.id}/info`}>
            {tdName}
          </Link>}
      </td>
      {typeMatch(['points-of-interest', 'trails'], simpleFeature.type) && !isAreaView && displayParentArea &&
        <td className={tdAreaClasses}>{tdAreaName}</td>}
      {typeMatch(['points-of-interest'], simpleFeature.type) &&
        <td className='featuresTable--pointType'>{feature.point_type}</td>}
      {typeMatch(['outings'], simpleFeature.type) &&
        <td className='featuresTable--length'>{outingLength} mi</td>}
      {typeMatch(['trails'], simpleFeature.type) &&
        <td className='featuresTable--length'>{trailLength} mi</td>}
      {typeMatch(['areas'], simpleFeature.type) &&
        <td className='featuresTable--acres'>{acres}</td>}
      {feature.stewardships && (
        <td className='stewardships'>
          {feature.stewardships.map((stewardship) => {
            return (
              <Label
                bsStyle='info'
                key={stewardship.role}
              >
                {stewardship.role}
              </Label>
            )
          })}
        </td>
      )}
      <td className='visibility'>
        {(feature.visibility === 'Archived') &&
          <Label bsStyle='danger'>{feature.visibility.toUpperCase()}</Label>}
        {(feature.visibility === 'Draft') &&
          <Label bsStyle='warning'>{feature.visibility.toUpperCase()}</Label>}
        {(feature.visibility === 'Published') &&
          <Label bsStyle='info'>{feature.visibility.toUpperCase()}</Label>}
      </td>
      <td>
        <FeatureStatusDropdown feature={feature} bsSize='small' />
      </td>
      <td>
        <DropdownButton title='Actions' id='actions' bsSize='small' pullRight>
          {actions.map((action) => {
            return (
              <MenuItem
                eventKey={action.eventKey}
                key={action.eventKey}
                onClick={action.handleOnClick}
              >
                {action.text}
              </MenuItem>
            )
          })}
        </DropdownButton>
      </td>
    </tr>
  )
}

FeaturesTableRow.propTypes = {
  displayParentArea: PropTypes.bool,
  feature: PropTypes.object,
  featureType: PropTypes.string,
  isAreaView: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  onArchived: PropTypes.func,
  onDeleteTrail: PropTypes.func,
  onEdit: PropTypes.func,
  onPublished: PropTypes.func,
  organizationId: PropTypes.number
}

export default FeaturesTableRow
