import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { useRouteMatch } from 'react-router'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import Label from 'react-bootstrap/lib/Label'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React from 'react'
import Table from 'react-bootstrap/lib/Table'

import { useCurrentOrganization } from 'contexts/OrganizationContext'

import './table.scss'

const ContentBundlesTable = ({
  contentBundles,
  onArchived,
  onDelete,
  onEdit,
  onPublished
}) => {
  const match = useRouteMatch()
  const organization = useCurrentOrganization()

  const getDateTimeString = (date) => {
    const d = new Date(new Date(`${date}Z`).toLocaleString('en-US', { timeZone: organization.time_zone }))
    return format(d, "LLL d, yyyy, h':'mmaaa")
  }

  return (
    <div className='contentBundlesTable'>
      {contentBundles.length === 0 &&
        <Panel className='contentBundlesTable-empty'>
          <Panel.Body>
            <h3>No articles available.</h3>
          </Panel.Body>
        </Panel>}
      {contentBundles.length > 0 &&
        <div>
          <Panel>
            <Table striped>
              <thead>
                <tr>
                  <th className='name'>Name</th>
                  <th className='created'>Created At</th>
                  <th className='visibility'>Visibility</th>
                  <th className='actions' />
                </tr>
              </thead>
              <tbody>
                {contentBundles.map((article) => {
                  const actions = [
                    { eventKey: 'edit', handleOnClick: () => onEdit(article), text: 'Edit' },
                    { eventKey: 'delete', handleOnClick: () => onDelete(article.id), text: 'Delete' }
                  ]
                  const archiveAction = { eventKey: 'archived', handleOnClick: () => onArchived(article), text: 'Archive', value: 'Archived' }
                  const publishAction = { eventKey: 'published', handleOnClick: () => onPublished(article), text: 'Publish', value: 'Published' }
                  const viewLink = `${match.url}/${article.id}`

                  if (article.visibility === 'Archived') {
                    actions.push(publishAction)
                  } else if (article.visibility === 'Draft') {
                    actions.push(archiveAction)
                    actions.push(publishAction)
                  } else if (article.visibility === 'Published') {
                    actions.push(archiveAction)
                  }

                  return (
                    <tr className='contentBundlesTable-tableRow' key={article.id}>
                      <td className='name'>
                        <Link to={viewLink}>
                          {!!article.name && article.name.length > 0 &&
                            <span>{article.name}</span>}
                          {(!article.name || !article.name.length) &&
                            <span className='muted'>Unnamed</span>}
                        </Link>
                      </td>

                      <td className='created'>
                        {getDateTimeString(article.created_at)}
                      </td>

                      <td className='visibility'>
                        {(article.visibility === 'Archived') &&
                          <Label bsStyle='danger'>{article.visibility.toUpperCase()}</Label>}
                        {(article.visibility === 'Draft') &&
                          <Label bsStyle='warning'>{article.visibility.toUpperCase()}</Label>}
                        {(article.visibility === 'Published') &&
                          <Label bsStyle='info'>{article.visibility.toUpperCase()}</Label>}
                      </td>

                      <td className='actions'>
                        <DropdownButton
                          bsSize='small'
                          id='actions'
                          pullRight
                          title='Actions'
                        >
                          {actions.map((action) => {
                            return (
                              <MenuItem
                                eventKey={action.eventKey}
                                key={action.eventKey}
                                onClick={action.handleOnClick}
                              >
                                {action.text}
                              </MenuItem>
                            )
                          })}
                        </DropdownButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Panel>
        </div>}
    </div>
  )
}

ContentBundlesTable.propTypes = {
  contentBundles: PropTypes.array,
  onDelete: PropTypes.func,
  onArchived: PropTypes.func,
  onEdit: PropTypes.func,
  onPublished: PropTypes.func
}

export default ContentBundlesTable
