
import { Formik, Form } from 'formik'
import FormikGroup from '../../../../components/fieldGroup/FormikGroup'
import Button from 'react-bootstrap/lib/Button'
import Modal from 'react-bootstrap/lib/Modal'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import OutingRouteMap from './OutingRouteMap'
import OutingWaypointList from './OutingWaypointList'
import WaypointInfo from './WaypointInfo'
import './outingRoute.css'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import ReactFileReader from 'react-file-reader'

const defaultProps = {}
const propTypes = {
  communities: PropTypes.array,
  isUpdatingWaypoints: PropTypes.bool,
  onAddWaypoint: PropTypes.func.isRequired,
  onDeleteWaypoint: PropTypes.func.isRequired,
  onSortWaypoints: PropTypes.func.isRequired,
  onUpdateWaypoint: PropTypes.func.isRequired,
  onUpdateRoute: PropTypes.func.isRequired,
  outingWaypoints: PropTypes.array.isRequired,
  outingRouteGeoJSON: PropTypes.shape({
    type: PropTypes.string.isRequired,
    coordinates: PropTypes.array.isRequired
  })
}

const OutingRoute = (props) => {
  const {
    isUpdatingWaypoints,
    onAddWaypoint,
    onDeleteWaypoint,
    onSortWaypoints,
    onUpdateRoute,
    onUpdateWaypoint,
    outingWaypoints,
    outingRouteGeoJSON
  } = props

  const client = useApolloClient()
  const [selectedWaypointId, setSelectedWaypointId] = useState()
  const focusedWaypoint = selectedWaypointId ? outingWaypoints.find(item => item.id === selectedWaypointId) : null
  const [state, setState] = useState({
    showAddWaypointFromCoordinatesModal: false,
    updatedLatLng: null
  })
  const organization = useCurrentOrganization()
  const [featuredImage, setFeaturedImage] = useState()

  const handleMoveWaypoint = (updatedLatLng) => {
    setState({
      ...state,
      updatedLatLng: updatedLatLng
    })
  }

  const handleDeselectWaypoint = () => {
    setSelectedWaypointId(null)
    setFeaturedImage(null)
  }

  const handleSelectWaypoint = (waypoint) => {
    if (typeof waypoint === 'number') {
      setSelectedWaypointId(waypoint)
    }
  }

  const handleAddFromLatLngClick = () => {
    setState({
      ...state,
      showAddWaypointFromCoordinatesModal: true
    })
  }

  const handleCloseModal = () => {
    setState({
      ...state,
      showAddWaypointFromCoordinatesModal: false
    })
  }

  const handleFiles = async (files) => {
    const fileText = await files[0].text()
    const geojson = JSON.parse(fileText)
    let coordinates

    if (!geojson.features || !geojson.features[0]) {
      window.alert('The GeoJSON file must have at least one feature.')
      return
    }

    if (!geojson.features[0].geometry || (geojson.features[0].geometry.type !== 'LineString' && geojson.features[0].geometry.type !== 'MultiLineString')) {
      window.alert('The GeoJSON file must contain a geometry.')
      return
    }

    if (geojson.features[0].geometry.type !== 'LineString' && geojson.features[0].geometry.type !== 'MultiLineString') {
      window.alert('The GeoJSON file must contain a geometry of type LineString or MultiLineString.')
      return
    }

    if (geojson.features[0].geometry.type === 'LineString') {
      coordinates = geojson.features[0].geometry.coordinates
    }

    if (geojson.features[0].geometry.type === 'MultiLineString') {
      coordinates = geojson.features[0].geometry.coordinates[0]

      // TODO: Collapse into a LineString.
    }

    if (!coordinates) {
      window.alert('There was an error parsing the GeoJSON file. Please try again.')
      return
    }

    const newGeojson = { ...outingRouteGeoJSON, type: 'LineString', coordinates: coordinates }
    onUpdateRoute(newGeojson)
  }

  const handleCreateStopFromLatLng = (values) => {
    const { onAddWaypoint } = props
    const latitude = Number(values.latitude)
    const longitude = Number(values.longitude)

    if (Number.isNaN(values.latitude) || Number.isNaN(values.longitude)) {
      // EXIT EARLY. DO NOT SUBMIT.
    } else {
      onAddWaypoint({
        name: `New Stop (${latitude}, ${longitude})`,
        description: null,
        feature: null,
        feature_id: null,
        feature_type: null,
        location: {
          geometry: {
            type: 'Point',
            crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::4326' } },
            coordinates: [longitude, latitude]
          }
        }
      }, (waypointId) => {
        handleCloseModal()
        handleSelectWaypoint(waypointId)
      })
    }
  }

  return (
    <Formik
      initialValues={{
        latitude: '0.0',
        longitude: '0.0'
      }}
      onSubmit={handleCreateStopFromLatLng}
    >
      {formik => (
        <div className='outingRoute'>
          <div className='outingRoute-sidebar'>
            <Panel className='outingRoute-sidebar-uploadPanel'>
              <Panel.Heading>
                <ReactFileReader fileTypes={['.geojson', '.json']} handleFiles={handleFiles}>
                  <button className='btn btn-block btn-primary'>Upload GeoJSON Route</button>
                </ReactFileReader>
              </Panel.Heading>
            </Panel>
            <Panel className='outingRoute-waypointPanel'>
              <Panel.Heading className='outingRoute-waypointPanel-heading'>
                Stops
                {!focusedWaypoint &&
                  <button
                    className='btn btn-primary'
                    onClick={handleAddFromLatLngClick}
                  >
                    Add from Lat/Lng
                  </button>}
              </Panel.Heading>
              <Panel.Body>
                {!focusedWaypoint &&
                  <OutingWaypointList
                    isUpdatingWaypoints={isUpdatingWaypoints}
                    onSelectWaypoint={handleSelectWaypoint}
                    onSortEnd={onSortWaypoints}
                    waypoints={outingWaypoints}
                  />}
                {focusedWaypoint &&
                  <WaypointInfo
                    featuredImage={featuredImage}
                    focusedWaypoint={focusedWaypoint}
                    isUpdatingWaypoints={isUpdatingWaypoints}
                    onDeleteWaypoint={onDeleteWaypoint}
                    onDeselectWaypoint={handleDeselectWaypoint}
                    onSaveWaypoint={onUpdateWaypoint}
                    onUpdateImage={setFeaturedImage}
                    updatedLatLng={state.updatedLatLng}
                  />}
              </Panel.Body>
            </Panel>
          </div>
          <div className='outingRoute-content'>
            <OutingRouteMap
              client={client}
              communities={props.communities}
              organization={organization}
              focusedWaypoint={focusedWaypoint}
              geojson={outingRouteGeoJSON}
              onAddWaypoint={onAddWaypoint}
              onDeleteWaypoint={onDeleteWaypoint}
              onDeselectWaypoint={handleDeselectWaypoint}
              onMoveWaypoint={handleMoveWaypoint}
              onSelectWaypoint={handleSelectWaypoint}
              onUpdateRoute={onUpdateRoute}
              waypoints={outingWaypoints}
            />
          </div>
          <Form>
            <Modal
              backdrop='static'
              keyboard={false}
              onHide={handleCloseModal}
              show={state.showAddWaypointFromCoordinatesModal}
            >
              <Modal.Header>
                <Modal.Title>Create Stop from Lat/Lng</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Please input your latitude and longitude in decimal WGS84 (Web Mercator).</p>

                <FormikGroup
                  id='latitude'
                  name='latitude'
                  label='Latitude'
                  model='wgs84LatLngForm.latitude'
                  type='text'
                  messages={{
                    valid: 'Please input a valid latitude value between -90 and 90',
                    required: 'A valid latitude is required.'
                  }}
                />
                <FormikGroup
                  id='longitude'
                  name='longitude'
                  label='Longitude'
                  model='wgs84LatLngForm.longitude'
                  type='text'
                  messages={{
                    valid: 'Please input a valid longitude value between -180 and 180',
                    required: 'A valid longitude is required.'
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  bsStyle='primary'
                  disabled={!formik.dirty && formik.isValid}
                  onClick={formik.handleSubmit}
                >
                  Create Stop
                </Button>
                <Button bsStyle='default' onClick={handleCloseModal}>Cancel</Button>
              </Modal.Footer>
            </Modal>
          </Form>

        </div>
      )}
    </Formik>
  )
}

OutingRoute.propTypes = propTypes
OutingRoute.defaultProps = defaultProps

export default OutingRoute
