import PropTypes from 'prop-types'
import React from 'react'

import { Map } from '@outerspatial/outerspatial-map'

import '@outerspatial/outerspatial-map/dist/assets/outerspatial-map.css'
import './featureMap.css'

const FeatureMap = (props) => {
  const { bounds, center, featureId, featureType, organization } = props
  const isPoint = false

  return (
    <div className={'featureInfo-map__wrap ' + (isPoint ? 'featureInfo-map--interactive' : '')}>
      <div className='featureInfo-map__container'>
        <Map
          accessToken={`${process.env.REACT_APP_MAPBOX_KEY}`}
          bounds={bounds}
          center={center}
          clickable={false}
          geolocateControl={false}
          organizationId={organization.id}
          outerSpatialApiUrl={process.env.REACT_APP_GRAPHQL_URL}
          scrollZoom={false}
          selectedFeatureId={featureId}
          selectedFeatureType={featureType}
          zoom={13}
        />
      </div>
    </div>
  )
}

FeatureMap.propTypes = {
  bounds: PropTypes.object,
  center: PropTypes.object,
  featureId: PropTypes.number,
  featureType: PropTypes.string,
  organization: PropTypes.object
}

export default FeatureMap
