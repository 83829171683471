import React from 'react'
import t from 'prop-types'
import ProgressBar from 'react-bootstrap/lib/ProgressBar'

const DocumentsTableRowUpload = ({ upload }) => {
  const progress = Math.ceil(upload.progress * 100)

  return (
    <tr className='documentsTable-tableRow documentsTable-progress'>
      <td colSpan='2'>
        <p>{upload.file.name}</p>
        <ProgressBar bsStyle='info' striped active now={progress} />
      </td>
    </tr>
  )
}

DocumentsTableRowUpload.propTypes = {
  upload: t.object
}

export default DocumentsTableRowUpload
