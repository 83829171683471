import { useHistory, useRouteMatch } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import Alert from 'react-bootstrap/lib/Alert'
import Col from 'react-bootstrap/lib/Col'
import Grid from 'react-bootstrap/lib/Grid'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import Row from 'react-bootstrap/lib/Row'
import toast from 'react-hot-toast'

import { CenteredLoader } from '../../../components/centeredLoader'
import { Fragments, Mutations, Queries } from '../ChallengesOperations'
import { PageContent, PageHeader, PageLayout } from '../../../components/pageLayout'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import ChallengeHeader from './ChallengeHeader'
import ChallengeInfo from './ChallengeInfo'
import ChallengeTasks from './ChallengeTasks'
import debugMessage from 'services/Debug'
import Error404 from '../../../views/errors/404'

import './challenge.css'

const Challenge = ({
  onDeleteClick
}) => {
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()

  let challengeData = {
    badge_image: null,
    description: '',
    id: null,
    name: '',
    organization_id: organization.id,
    visibility: 'public'
  }
  const challengeId = match.params.challengeId
  const formRef = useRef()

  const { data: challenge, error: getError, loading: getChallengeIsLoading } = useQuery(
    Queries.GetChallengeById, {
      skip: challengeId === 'new',
      variables: {
        id: challengeId
      }
    }
  )
  const [insertChallenge, { loading: insertChallengeIsLoading }] = useMutation(
    Mutations.InsertChallenge, {
      // TODO: This query can't be found for some reason.
      // refetchQueries: [Queries.GetChallengesByOrganizationId],
      update: (cache, response) => {
        const returning = response?.data?.insert_challenges?.returning[0]

        if (returning) {
          cache.modify({
            fields: {
              challenges (existingChallenges = []) {
                const fragment = Fragments.ChallengeDetails
                const newChallengeRef = cache.writeFragment({
                  data: returning,
                  fragment: fragment
                })

                return [...existingChallenges, newChallengeRef]
              }
            }
          })
        }
      }
    }
  )
  const [updateChallenge, { loading: updateChallengeIsLoading }] = useMutation(
    Mutations.UpdateChallenge, {
      refetchQueries: [Queries.GetChallengeById]
    }
  )

  const handleSave = () => {
    // Ensure that the Formik form is hooked up.
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }
  const handleSubmitInfoForm = (newChallenge, formikBag) => {
    const badgeImageId = newChallenge?.badge_image_id
    const onboarding = typeof newChallenge?.onboarding === 'string' ? { content: newChallenge?.onboarding, title: 'Rules' } : newChallenge?.onboarding

    if (challengeId === 'new') {
      const newBrowserRoute = match.path.split(':challengeId')[0]

      insertChallenge({
        variables: {
          badgeImageId: badgeImageId,
          description: newChallenge.description,
          enableLocationValidation: newChallenge.enable_location_validation,
          endDate: newChallenge.end_date,
          name: newChallenge.name,
          onboarding: onboarding,
          organizationId: organization.id,
          requiresEmailSharing: newChallenge.requires_email_sharing,
          startDate: newChallenge.start_date,
          visibility: newChallenge.visibility
        }
      })
        .then((response) => {
          const newChallengeId = response?.data?.insert_challenges?.returning[0]?.id

          if (newChallengeId) {
            toast.success('Challenge created.')
            history.replace(`${newBrowserRoute}${newChallengeId}`)
          } else {
            toast.success('Challenge could not be created.')
          }

          formikBag.setSubmitting(false)
        })
        .catch(e => {
          toast.success('Challenge could not be created.')
          debugMessage(e)
          formikBag.setSubmitting(false)
        })
    } else {
      updateChallenge({
        variables: {
          ...{
            badgeImageId: badgeImageId,
            description: newChallenge.description,
            endDate: newChallenge.end_date,
            id: newChallenge.id,
            name: newChallenge.name,
            onboarding: onboarding,
            startDate: newChallenge.start_date,
            visibility: newChallenge.visibility
          }
        }
      })
        .then((response) => {
          toast.success('Challenge updated.')
          formikBag.setSubmitting(false)
        })
        .catch(e => {
          toast.success('Challenge could not be updated.')
          debugMessage(e)
          formikBag.setSubmitting(false)
        })
    }
  }

  if (challenge && challenge.challenges.length) {
    challengeData = challenge.challenges[0]
    document.title = `${challengeData.name} | Challenges | ${process.env.REACT_APP_PAGE_TITLE}`
  } else {
    document.title = `New Challenge | Challenges | ${process.env.REACT_APP_PAGE_TITLE}`
  }

  if (getError && getError.message) {
    return <Alert bsStyle='danger'>{getError.message}</Alert>
  } else if (getChallengeIsLoading || ((challengeId !== 'new' && challenge.id === null))) {
    return <CenteredLoader />
  } else if (challenge?.challenges) {
    if (challenge.challenges.length === 0) {
      return <Alert bsStyle='warning'>There is no Challenge with the requested ID.</Alert>
    }
  } else if (challengeId !== 'new') {
    return <Error404 />
  }

  return (
    <div className='challenge-wrap'>
      {(insertChallengeIsLoading || updateChallengeIsLoading) && (
        <CenteredLoader overlay />)}
      <PageLayout class='challenge'>
        <PageHeader>
          <ChallengeHeader
            challenge={challengeData}
            match={match}
            onDeleteClick={onDeleteClick}
            onSaveClick={handleSave}
          />
        </PageHeader>
        <PageContent>
          <Grid
            fluid
          >
            <Row>
              <Col
                xs={12}
                sm={6}
              >
                <ChallengeInfo
                  challenge={challengeData}
                  formRef={formRef}
                  handleSubmitForm={handleSubmitInfoForm}
                  history={history}
                  match={match}
                  onSaveClick={handleSave}
                  organization={organization}
                />
              </Col>
              <Col
                xs={12}
                sm={6}
              >
                <ChallengeTasks
                  challenge={challengeData}
                />
              </Col>
            </Row>
          </Grid>
        </PageContent>
      </PageLayout>
    </div>
  )
}

Challenge.propTypes = {
  onDeleteClick: PropTypes.func
}

export default Challenge
