import { useLazyQuery } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import debugMessage from 'services/Debug'
import './userSearch.css'
import { Queries } from './UserSearchOperations'

const UserSearch = (props) => {
  const {
    clearFormFieldValue,
    isDisabled,
    membersOnly,
    onSelect,
    value
  } = props

  const organization = useCurrentOrganization()
  const [searchResults, setSearchResults] = useState([])
  const [selectValue, setSelectValue] = useState({})
  const [term, setTerm] = useState(null)

  const reduceOrganizationUsersQueryToSelect = (data) => {
    let mapped = data.organizations_by_pk.memberships.map((membership) => {
      const user = membership.user
      let label = membership.user_email.email

      if (user.profile && (user.profile.first_name || user.profile.last_name)) {
        label = user.profile.first_name + ' ' + user.profile.last_name + ' (' + membership.user_email.email + ')'
      }

      return {
        content: {
          ...user,
          class_name: 'user',
          table_name: user.__typename
        },
        key: user.id,
        label: label,
        value: {
          class: 'user',
          table: user.__typename,
          id: user.id,
          email: membership.user_email.email
        }
      }
    })

    mapped = _.flatten(mapped)

    setSearchResults(mapped)

    if (isDisabled) {
      setSelectValue(mapped[0])
    } else if (value && value.email && value.userId) {
      setSelectValue(mapped.filter(option => (option.value.email === value.email && option.value.id === value.userId))[0])
    }
  }

  const [getUsersWithinOrganization, { loadingUsersWithinOrganization }] = useLazyQuery(
    Queries.SearchUsersWithinOrganization,
    {
      displayName: 'SearchUsersWithinOrganization',
      fetchPolicy: 'network-only',
      variables: {
        term: term,
        organizationId: organization.id
      },
      onError (error) {
        debugMessage(error)
      },
      onCompleted (data) {
        reduceOrganizationUsersQueryToSelect(data)
      }
    }
  )

  useEffect(() => {
    if (term && term !== '%%') {
        getUsersWithinOrganization({
          variables: {
            where: { user_email: { email: { _ilike: term } } },
            organizationId: organization?.id
          }
        })     
    } else {
      if (membersOnly) {
        getUsersWithinOrganization({
          variables:
            { organizationId: organization?.id }
        })
      }
    }
  }, [term])

  const handleInputChange = _.debounce((inputValue, actionMeta) => {
    setTerm(`%${inputValue}%`.toLowerCase())
  }, 300)

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'clear') {
      if (typeof clearFormFieldValue === 'function') {
        clearFormFieldValue()
      }

      setSelectValue({})
      setSearchResults([])
      setTerm(null)
      onSelect(null)
    } else if (actionMeta.action === 'select-option') {
      if (newValue) {
        setSelectValue(newValue)
        onSelect({
          id: newValue.content?.id,
          email: newValue.content?.email,
          first_name: newValue.content?.profile?.first_name,
          last_name: newValue.content?.profile?.last_name,
          label: newValue.content?.profile?.first_name + ' ' + newValue.content?.profile?.last_name
        })
      } else {
        setSelectValue({})
        onSelect(null)
      }
    } else if (actionMeta.action === 'create-option') {
      const newValueLowerCased = {
        email: newValue.value.toLowerCase(),
        label: newValue.label.toLowerCase(),
        __new__: true
      }

      setSelectValue(newValueLowerCased)
      onSelect(newValueLowerCased)
    }
  }

  return (
    <CreatableSelect
      // Allow options to be created while the isLoading prop is true. Useful to prevent the "create new ..." option being displayed while async results are still being loaded.
      allowCreateWhileLoading
      // Sets the position of the createOption element in your options list. Defaults to 'last'
      createOptionPosition='first'
      // Gets the label for the "create new ..." option in the menu. Is given the current input value.
      // formatCreateLabel={(inputValue) => {debugger}}
      isClearable
      isDisabled={isDisabled}
      isLoading={loadingUsersWithinOrganization}
      // Determines whether the "create new ..." option should be displayed based on the current input value, select value and options array.
      // isValidNewOption
      // Returns the data for the new option when it is created. Used to display the value, and is passed to onChange.
      // getNewOptionData={handleNewOptionData}
      // Name of the HTML Input (optional - without this, no input will be rendered)
      name='UserSearch'
      // If provided, this will be called with the input value when a new option is created, and onChange will not be called. Use this when you need more control over what happens when new options are created.
      // onCreateOption={() => {}}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={searchResults}
      value={selectValue || null} // One of <Object, Array<Object>, null, undefined>
    />
  )
}

UserSearch.propTypes = {
  clearFormFieldValue: PropTypes.func, // Callback for clearing state or values higher in the component hierarchy when the menu is closed or cleared
  isDisabled: PropTypes.bool, // Membership // Disable the Async
  membersOnly: PropTypes.bool, // MediaGalleryFilter
  onSelect: PropTypes.func.isRequired, // MediaGalleryFilter && Membership
  value: PropTypes.object // MediaGalleryFilter && Membership
}

export default UserSearch
