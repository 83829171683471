import { gql, useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import { useHistory, useRouteMatch } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import debugMessage from 'services/Debug'
import toast from 'react-hot-toast'
import { CenteredLoader } from '../../../components/centeredLoader'
import { PageContent, PageHeader, PageLayout } from '../../../components/pageLayout'
import defaultEvent from '../../../models/event/event.model'
import EventForm from './EventForm'
import './eventView.css'

const GetEventQuery = gql`
  query GetEventQuery($id: Int!) {
    events_by_pk(id: $id) {
      area_attachments {
        id
        feature: area {
          id
          name
        }
      }
      banner_image_id      
      banner_image {
        id
        uploaded_file
      }
      id
      name
      outing_attachments {
        id
        feature: outing {
          id
          name
        }
      }
      trail_attachments {
        id
        feature: trail {
          id
          name
          area {
            id
            name
          }
        }
      }
      point_of_interest_attachments {
        id
        feature: point_of_interest {
          id
          name
          area { 
            id
            name
          }
        }
      }
      
      description
      feature_type
      feature_id
      time_zone
      created_at
      location
      website
      phone_number
      cost
      schedules{
        schedule{
          id
          date
          time
          until
        }
      }
    }
  }
`

const DeleteEventMutation = gql`
  mutation DeleteEvent($id: Int!) {
    delete_schedules(where:{schedulable_type:{_eq:"Event"},schedulable_id:{_eq:$id}}) {
      returning {
        id
        created_at        
      }
    }
    delete_events_by_pk(id:$id) {
      id
      created_at
      name
    }    
  }
`

const EventView = (props) => {
  const {
    updating
  } = props
  const formRef = useRef()
  const match = useRouteMatch()
  const history = useHistory()
  const eventId = match.params.eventId
  const isNew = eventId === 'new'
  const [deleteEventMutation] = useMutation(DeleteEventMutation, { refetchQueries: ['EventsList'], fetchPolicy: 'no-cache' })

  const { data, loading } = useQuery(GetEventQuery, {
    skip: isNew,
    variables: { id: eventId }
  })
  const model = isNew ? { ...defaultEvent.event.model } : data && data.events_by_pk
  const destroy = (eventId) => {
    return deleteEventMutation({
      variables: {
        id: eventId
      }
    })
  }

  const destroyEvent = () => {
    const eventId = model.id
    const conf = window.confirm('Are you sure you want to permanently delete this event?')

    if (conf) {
      destroy(eventId).then(() => {
        toast.success('Event deleted successfully.')
        let backPath = match.path.split(':')[0]
        backPath = backPath.slice(0, backPath.length - 1)
        history.push(backPath)
      }, (error) => debugMessage(error))
    }
  }

  const duplicateEvent = () => {
    const eventId = model.id

    let backPath = match.path.split(':')[0]
    backPath = backPath.slice(0, backPath.length - 1)
    history.push(`${backPath}/new?duplicate=${eventId}`)
  }

  const submitEvent = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  if ((loading || !model)) {
    return (
      <CenteredLoader />
    )
  }

  const backPath = match.path.split(':')[0].slice(0, match.path.split(':')[0].length - 1)

  return (
    <div className='eventView-wrap'>
      {updating && !model &&
        <CenteredLoader overlay />}
      <PageLayout>
        <PageHeader>
          <div className='eventView-header'>
            <div className='eventView-header__title'>
              <div className='eventView-header__titleText'>
                <LinkContainer to={backPath}>
                  <Button bsStyle='link'>
                    <Glyphicon glyph='chevron-left' />Back to Events
                  </Button>
                </LinkContainer>
                {isNew &&
                  <h1>New Event</h1>}
                {!isNew &&
                  <h1>{model.name}</h1>}
              </div>
              <div className='eventView-header__titleActions'>
                {!!model.id &&
                  <>
                    <Button
                      bsStyle='danger'
                      disabled={updating}
                      onClick={destroyEvent}
                    >
                      Delete
                    </Button>
                    <Button
                      bsStyle='primary'
                      disabled={updating}
                      onClick={duplicateEvent}
                    >
                      Duplicate
                    </Button>
                  </>}
                <Button
                  bsStyle='primary'
                  form='eventForm-form'
                  onClick={submitEvent}
                  type='submit'
                >
                  {isNew ? 'Add' : 'Save'}
                </Button>
              </div>
            </div>
          </div>
        </PageHeader>
        <PageContent>
          <EventForm
            innerRef={formRef}
            model={model}
          />
        </PageContent>
      </PageLayout>
    </div>
  )
}

EventView.propTypes = {
  updating: PropTypes.bool
}

export default EventView
