import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import MediaModal from '../../../components/media/modal/MediaModal'
import MediaChooserItem from './MediaChooserItem'
import { Mutations } from './MediaChooserOperations'
// import OrganizationImagesQuery from '../../../queries/OrganizationImagesQuery'
import './mediaChooser.css'
import debugMessage from 'services/Debug'

const MediaChooser = (props) => {
  const { feature } = props
  const [state, setState] = useState({ showModal: false })
  const [imageAttachments, setImageAttachments] = useState(feature.image_attachments || [])

  const [insertImageAttachmentAtPosition] = useMutation(Mutations.InsertImageAttachmentAtPosition, {
    onCompleted: (data) => {
      const newImage = data.insert_image_attachments_one
      const updatedImageAttachments = [...imageAttachments, {
        id: newImage.id,
        image: newImage.image,
        position: newImage.position
      }]
      setImageAttachments(updatedImageAttachments)
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The image attachment could not be assigned.')
    }
  })

  const [updateImageAttachmentPosition] = useMutation(Mutations.UpdateImageAttachmentPosition, {
    onCompleted: (data) => {
      const updatedImageAttachments = imageAttachments.map(att => {
        if (att.id === data.update_image_attachments_by_pk.id) {
          return { ...att, position: data.update_image_attachments_by_pk.position }
        } else {
          return att
        }
      })
      setImageAttachments(updatedImageAttachments)
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The image attachment position could not be updated.')
    }
  })

  const addImage = (feature, imageId, position) => {
    insertImageAttachmentAtPosition({
      variables: {
        featureId: feature.id,
        featureType: feature.class_name,
        imageId: imageId,
        position: position
      }
    })
  }

  /* NOTE: This function is passed into MediaModal as onDelete and onUploadSuccess props, but it is never called
  const destroyImage = (feature, image) => {
    const currentAttached = imageAttachments.find(i => i.image.id === image.id)
    if (currentAttached) {
      deleteImageAttachment({
        variables: {
          attachmentId: currentAttached.attachmentId
        }
      })
    }
  }
  */

  const updateImage = (feature, attachmentId, imageId, position) => {
    updateImageAttachmentPosition({
      variables: {
        attachmentId: attachmentId,
        imageId: imageId,
        position: position
      }
    })
  }
  /* NOTE: This function is passed into MediaModal as onDelete and onUploadSuccess props, but it is never called
  const uploadImage = (feature, data) => {
    // this should create an image attachment
    // return dispatch(featureActions.update(feature.table_name, feature.id, {
    //   image_attachments: [
    //     {
    //       image_id: data.id
    //     }
    //   ]
    // }))
  }
  */

  const attachImage = (image) => {
    const { selectedPosition } = state
    // Check if the image attachment exists on the feature, but outside of the first 5 positions.
    const attachedAlready = imageAttachments.find(i => (i.image.id === image.id) && i.position > 6)

    closeMediaModal()

    if (attachedAlready) {
      toast.error('Image already in attached.')
    } else {
      const currentAttachedAtPosition = imageAttachments.find(i => i.position === selectedPosition)
      let existingAttachment = []

      if (currentAttachedAtPosition) {
        updateImage(feature, currentAttachedAtPosition.id, currentAttachedAtPosition.image.id, null)

        existingAttachment = imageAttachments.find(i => i.image.id === image.id)

        if (existingAttachment) {
          updateImage(feature, existingAttachment.id, existingAttachment.image.id, selectedPosition)
        } else {
          addImage(feature, image.id, selectedPosition)
        }
      } else {
        existingAttachment = imageAttachments.find(i => i.image.id === image.id)

        if (existingAttachment) {
          updateImage(feature, existingAttachment.id, image.id, selectedPosition)
        } else {
          addImage(feature, image.id, selectedPosition)
        }
      }
    }
  }

  const onRemoveImage = (position) => {
    const currentAttached = imageAttachments.find(i => i.position === position)
    updateImage(feature, currentAttached.id, currentAttached.image.id, null)
    return false
  }

  const closeMediaModal = () => {
    setState({ ...state, showModal: false })
  }

  const openMediaModal = (position) => {
    setState({
      ...state,
      selectedPosition: position,
      showModal: true
    })
  }

  return (
    <div className='mediaChooser-wrap'>
      {
        [1, 2, 3, 4, 5].map((position) => {
          return (
            <MediaChooserItem
              key={position}
              onItemClick={() => { openMediaModal(position) }}
              onItemRemove={onRemoveImage}
              position={position}
              selected={imageAttachments.find(i => i.position === position)}
            />
          )
        })
      }
      <MediaModal
        allowSelection
        // filterByFeature // -> MediaGallery -> MediaActionBar -> MediaGalleryFilter -> DEAD END && UNUSED
        // NOTE: Sure, these get passed into MediaModal as onDelete and onUploadSuccess props, but they are never called!
        onClose={() => closeMediaModal()}
        onSubmit={(selected) => attachImage(selected[0])}
        selectedFeature={feature}
        show={state.showModal}
        singleSelection
      />
    </div>
  )
}

MediaChooser.propTypes = {
  feature: PropTypes.object
  // updateImage: PropTypes.func
}

export default MediaChooser
