const document = {
  document: {
    error: null,
    fetched: false,
    fetching: false,
    model: {
      description: '',
      name: ''
    },
    updating: false
  },
  documents: {
    collection: [],
    error: null,
    fetched: false,
    fetching: false,
    paging: {
      current_page: 1,
      total_pages: 0
    },
    query: {
      direction: 'asc',
      order: 'name',
      page: 1
    },
    updating: null
  },
  uploads: {
    activeUploads: [],
    error: null,
    uploaded: false,
    uploading: false
  }
}

export default document
