import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { useRouteMatch } from 'react-router'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'

import { askOuterSpatialAQuestion } from 'components/helpScout'
import { useCurrentUser } from 'contexts/CurrentUserContext'

import './mapsTableRow.scss'

const TableRow = ({ feature, map, mapType, onDelete }) => {
  const match = useRouteMatch()
  const status = map.status.status === 'Uploading tiles' ? 'Uploading' : 'Completed'
  const user = useCurrentUser()
  const viewLink = `${match.url}/${map.id}`

  const handleDeletePaperMap = (map) => {
    if (isSuperAdmin(user)) {
      const conf = window.confirm('Are you sure you want to delete this paper map? Once deleted, you cannot get it back.')

      if (conf) {
        onDelete(map)
      }
    } else {
      askOuterSpatialAQuestion(
        'Delete a Paper Map',
        `I would like to delete the "${map.name}" paper map with an id of ${map.id}.`,
        user
      )
    }
  }

  const isSuperAdmin = (user) => {
    return user.roles.find((item) => { return item.role.name === 'admin' })
  }

  return (
    <tr className='mapsTable-tableRow'>
      <td>
        <Link to={viewLink}>
          {!!map.name && map.name.length > 0 &&
            <span>{map.name}</span>}
          {(!map.name || !map.name.length) &&
            <span className='muted'>Unnamed Map</span>}
        </Link>
      </td>
      {isSuperAdmin(user) && (
        <td>
          <span>
            {status}
          </span>
        </td>
      )}
      <td>
        <DropdownButton title='Actions' id='actions' bsSize='small' pullRight>
          <LinkContainer to={viewLink}>
            <MenuItem eventKey='view'>View</MenuItem>
          </LinkContainer>
          {mapType === 'paper' &&
            <MenuItem
              bsStyle={isSuperAdmin(user) ? 'warning' : 'default'}
              eventKey='delete'
              onClick={() => handleDeletePaperMap(map)}
            >
              {isSuperAdmin(user) && (
                <>
                  <Glyphicon glyph='star' />
                  {' '}
                  <span>Delete</span>
                </>
              )}
              {!isSuperAdmin(user) && (
                <span>Request Delete</span>
              )}
            </MenuItem>}
        </DropdownButton>
      </td>
    </tr>
  )
}

TableRow.propTypes = {
  map: PropTypes.object,
  mapType: PropTypes.string,
  onDelete: PropTypes.func,
  feature: PropTypes.object
}

export default TableRow
