import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/lib/Dropdown'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import toast from 'react-hot-toast'
import { Mutations } from '../../views/features/FeaturesOperations'
import '../visibilityDropdown/visibilityDropdown.css'

const FeatureVisibilityDropdown = (props) => {
  const { featureId, featureType, initialValue } = props
  const [value, setValue] = useState(initialValue || 'Published')
  let filteredOptions = []
  const visibilityOptions = [
    'Archive',
    'Publish'
  ]

  const [updateAreaVisibility] = useMutation(
    Mutations.UpdateVisibilityForArea, {
      onCompleted (data) {
        setValue()
        toast.success('Area visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  const [updateTrailVisibility] = useMutation(
    Mutations.UpdateVisibilityForTrail, {
      onCompleted (data) {
        setValue()
        toast.success('Trail visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  const [updatePOIVisibility] = useMutation(
    Mutations.UpdateVisibilityForPOI, {
      onCompleted (data) {
        setValue()
        toast.success('Point of Interest visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  const onChange = (option) => {
    if (window.confirm('Are you sure you want to update the visibility of this location?')) {
      const visibility = (() => {
        if (option === 'Archive') {
          return 'Archived'
        } else {
          return 'Published'
        }
      })()

      if (featureType === 'points-of-interest') {
        updatePOIVisibility({
          variables: {
            poiId: featureId,
            visibility: visibility
          }
        }).then(() => {
          setValue(visibility)
        })
      } else if (featureType === 'trails') {
        updateTrailVisibility({
          variables: {
            trailId: featureId,
            visibility: visibility
          }
        }).then(() => {
          setValue(visibility)
        })
      } else if (featureType === 'areas') {
        updateAreaVisibility({
          variables: {
            areaId: featureId,
            visibility: visibility
          }
        }).then(() => {
          setValue(visibility)
        })
      }
    }
  }

  if (value) {
    filteredOptions = visibilityOptions.filter((option) => (option !== value) && (`${option}d` !== value) && (`${option}ed` !== value))
  }

  return (
    <Dropdown
      className='visibilityDropdown'
      disabled={(!value || value === 'Published') && (!featureId)}
      id={`visibility-dropdown-contentBundle-${featureId}`}
      pullRight
    >
      <Dropdown.Toggle
        bsStyle='default'
      >
        <span
          className={`visibility-icon visibility-icon--${(value ? value.toLowerCase() : 'published')}`}
        />
        {value || 'Published'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filteredOptions.map((option) => {
          return (
            <MenuItem
              key={option}
              onClick={() => onChange(option)}
            >
              <span
                className={`visibility-icon visibility-icon--${option.toLowerCase()}`}
              />
              {option}
            </MenuItem>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

FeatureVisibilityDropdown.propTypes = {
  featureId: PropTypes.number,
  featureType: PropTypes.string,
  initialValue: PropTypes.string
}

export default FeatureVisibilityDropdown
