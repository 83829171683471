import { useMutation, useQuery } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { Panel } from 'react-bootstrap'
import Alert from 'react-bootstrap/lib/Alert'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import { useHistory, useRouteMatch } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import debugMessage from 'services/Debug'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import AssociationSet from 'components/associationSet'
import { CenteredLoader } from 'components/centeredLoader'
import Editor from 'components/editorWithHooks'
import { handleContentStyleGuideClick } from 'components/helpScout'
import MediaModal from 'components/media/modal/MediaModal'
import { PageContent, PageHeader, PageLayout } from 'components/pageLayout'
import Error404 from 'views/errors/404'
import { Fragments, Mutations, Queries } from '../ContentBundlesOperations'
import VisibilityDropdown from '../ContentBundleVisibilityDropdown'
import { buildImageUploadUrl } from 'services/Images'

import './contentBundle.css'

const ContentBundleHeader = ({ contentBundle, history, match, onSaveClick }) => {
  const backPath = match.path.split(':')[0].slice(0, match.path.split(':')[0].length)
  const [deleteContentBundle] = useMutation(Mutations.DeleteContentBundle, {
    onCompleted: () => {
      toast.success('The Article was deleted.')
      history.push(backPath)
    },
    onError: () => {
      toast.error('The Article could not be deleted.')
    },
    update: (cache, response) => {
      cache.modify({
        fields: {
          content_bundles (existingContentBundles = []) {
            return existingContentBundles.filter((t) => {
              return t.__ref !== `content_bundles:${response.data.delete_content_bundles.returning[0].id}`
            })
          }
        }
      })
    }
  })

  const handleDeleteClick = () => {
    const conf = window.confirm('Are you sure you want to permanently delete this Article?')

    if (contentBundle.id !== -1 && conf) {
      deleteContentBundle({
        variables: {
          contentBundleId: contentBundle.id
        }
      })
    }
  }

  return (
    <div className='contentBundle-header'>
      <div className='contentBundle-header__title'>
        <div className='contentBundle-header__titleText'>
          <LinkContainer to={backPath}>
            <Button bsStyle='link'>
              <Glyphicon glyph='chevron-left' />Back to Articles
            </Button>
          </LinkContainer>
          <h1>{contentBundle.id === -1 ? 'Create Article' : contentBundle.name}</h1>
        </div>
        <div className='contentBundle-header__titleActions'>
          {contentBundle.id !== -1 &&
            <>
              <VisibilityDropdown
                contentBundleId={contentBundle.id}
                initialValue={contentBundle.visibility}
              />
              <Button
                bsStyle='danger'
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            </>}
          <Button
            disabled={false} // TODO: Hook up this button to be disabled if the form is not complete or valid
            bsStyle='primary'
            onClick={onSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

ContentBundleHeader.propTypes = {
  contentBundle: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  onSaveClick: PropTypes.func
}

const ContentBundleForm = (props) => {
  const { contentBundle, formRef, submitForm, updateContentBundleData } = props
  const [showMediaModal, setShowMediaModal] = useState(false)
  const closeMediaModal = () => setShowMediaModal(false)
  const openMediaModal = () => setShowMediaModal(true)
  const bannerUrl = (() => {
    let url = null

    if (contentBundle && contentBundle.banner_image_id && contentBundle.banner_image?.uploaded_file) {
      url = buildImageUploadUrl(contentBundle.banner_image_id, contentBundle.banner_image.uploaded_file, 'small')
    }

    return url
  })()
  const initialValues = {
    banner_image_id: contentBundle.banner_image_id,
    description: contentBundle.id === -1 ? '' : contentBundle.text_contents?.[0]?.text ?? '',
    id: contentBundle.id,
    name: contentBundle.name,
    visibility: 'Draft'
  }

  return (
    <>
      <Formik
        initialValues={{ ...initialValues }}
        innerRef={formRef}
        onSubmit={(values, formikBag) => submitForm(values, formikBag)}
        validationSchema={Yup.object().shape({
          name: Yup
            .string()
            .trim('Name cannot include leading or trailing spaces.')
            .strict(true)
            .min(2, 'Name must have at least two characters.')
            .max(500, 'Name must not be more than 500 characters.')
            .required('Name is required.')
        })}
      >
        {(_formikProps) => {
          return (
            <Form noValidate>
              <div className='panel-header'>
                <Field name='name'>
                  {({ field, meta }) => (
                    <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                      <label className='control-label' htmlFor='name'>Name *</label>
                      <input className='form-control' id='name' type='text' {...field} />
                      {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                    </div>
                  )}
                </Field>
                <div className='form-group'>
                  <label className='control-label'>Banner Image</label>
                  <div className='contentBundleSection-image'>
                    <Button className='contentBundleImage-imageButton' onClick={() => openMediaModal()} bsSize='small'>
                      {bannerUrl ? 'Change Image' : 'Add Image'}
                    </Button>
                    {!!bannerUrl &&
                      <div className='contentBundleSection-bannerImage' style={{ backgroundImage: `url(${bannerUrl})` }} />}
                  </div>
                </div>
                <Field name='description'>
                  {({ field, form, meta }) => (
                    <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                      <label className='control-label' htmlFor='description'>Description</label>
                      <Editor
                        className='form-control'
                        fullFormattingAvailable
                        id={field.name}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value)
                        }}
                        value={field.value}
                      />
                      {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </Form>
          )
        }}
      </Formik>
      <MediaModal
        allowSelection
        onClose={() => closeMediaModal()}
        onSubmit={(selected) => {
          updateContentBundleData({
            ...contentBundle,
            banner_image: selected[0],
            banner_image_id: selected[0].id
          })
          closeMediaModal()
        }}
        show={showMediaModal}
        singleSelection
      />
    </>
  )
}

ContentBundleForm.propTypes = {
  contentBundle: PropTypes.object,
  formRef: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
  updateContentBundleData: PropTypes.func.isRequired
}

const ContentBundle = (_props) => {
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const formRef = useRef()
  const contentBundleId = isNaN(match.params.contentBundleId) ? -1 : parseInt(match.params.contentBundleId)
  const [contentBundleData, setContentBundleData] = useState({
    id: -1,
    invalidId: false,
    name: '',
    banner_image_id: -1,
    description: '',
    visibility: 'Draft'
  })
  const [contentBundleAttachments, setContentBundleAttachments] = useState([])

  // ************************************************
  // GraphQL Queries and Mutations
  const [deleteContentBundleAttachment] = useMutation(Mutations.DeleteContentBundleAttachment, {
    onCompleted: (data) => {
      setContentBundleAttachments(contentBundleAttachments.filter(cba => cba.id !== data.delete_attachments.returning[0].id))
      toast.success('The Feature relation has been removed from this Article.')
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The Feature relation could not be removed from this Article.')
    },
    update: (cache, response) => {
      cache.modify({
        fields: {
          attachments (existingAttachments = []) {
            return existingAttachments.filter((a) => {
              return a.__ref !== `attachments:${response.data.delete_attachments.returning[0].id}`
            })
          }
        }
      })
    }
  })
  const { error: getError, loading: getIsLoading } = useQuery(Queries.GetContentBundleById, {
    skip: contentBundleId === -1 || !organization?.id,
    variables: {
      contentBundleId: contentBundleId,
      organizationId: organization.id
    },
    onCompleted: (data) => {
      if (data.content_bundles.length) {
        setContentBundleData(data.content_bundles[0])
      } else {
        setContentBundleData({
          ...contentBundleData,
          invalidId: true
        })
      }
      if (data.attachments.length) {
        const attachments = data.attachments.map(attachment => {
          let tmp = attachment

          if (attachment.feature_type === 'Outing') {
            const outing = data.outing_content_bundle_attachments
              .filter(o => o.outing.id === attachment.feature_id)
              .map(o => ({ id: o.outing.id, name: o.outing.name }))[0]

            tmp = {
              ...tmp,
              contentName: outing.name,
              feature: { class_name: 'Outing' }
            }
          } else if (attachment.feature_type === 'Area') {
            const area = data.area_content_bundle_attachments
              .filter(a => a.area.id === attachment.feature_id)
              .map(a => ({ id: a.area.id, name: a.area.name }))[0]

            tmp = {
              ...tmp,
              contentName: area.name,
              feature: { class_name: 'Area' }
            }
          } else if (attachment.feature_type === 'Trail') {
            const trail = data.trail_content_bundle_attachments
              .filter(t => t.trail.id === attachment.feature_id)
              .map(t => ({ id: t.trail.id, name: t.trail.name }))[0]

            tmp = {
              ...tmp,
              contentName: trail.name,
              feature: { class_name: 'Trail' }
            }
          } else if (attachment.feature_type === 'PointOfInterest') {
            const poi = data.point_of_interest_content_bundle_attachments
              .filter(p => p.point_of_interest.id === attachment.feature_id)
              .map(p => ({
                id: p.point_of_interest.id,
                name: p.point_of_interest.name,
                areaName: p.point_of_interest.area?.name ? p.point_of_interest.area.name : ''
              }))[0]

            tmp = {
              ...tmp,
              contentName: poi.name,
              feature: {
                area: { name: poi.areaName },
                class_name: 'PointOfInterest'
              }
            }
          }

          return tmp
        })

        // Naturally sort by attachment.name
        setContentBundleAttachments([...attachments].sort((a, b) => a.contentName.localeCompare(b.contentName, undefined, { numeric: true, sensitivity: 'base' })))
      }
    }
  })
  const [insertContentBundle, { loading: insertIsLoading }] = useMutation(Mutations.InsertContentBundle, {
    onCompleted: (data) => {
      const newContentBundleId = data.insert_content_bundles_one.id
      const newRoute = match.path.split(':contentBundleId')[0]
      const attachments = contentBundleAttachments.map(cba => {
        return {
          attached_id: newContentBundleId,
          attached_type: 'ContentBundle',
          feature_id: cba.feature_id,
          feature_type: cba.feature_type
        }
      })

      if (attachments.length) {
        insertContentBundleAttachments({ variables: { newAttachments: attachments } }).then(response => {
          if (response.errors !== undefined) {
            toast.success('The Article was created, but there was an issue relating the selected Features.')
          } else {
            toast.success('The Article was created.')
          }
          history.replace(`${newRoute}${newContentBundleId}`)
        })
      } else {
        toast.success('The Article was created.')
        history.replace(`${newRoute}${newContentBundleId}`)
      }
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The Article could not be created.')
    },
    update: (cache, response) => {
      cache.modify({
        fields: {
          content_bundles (existingContentBundles = []) {
            const newContentBundleRef = cache.writeFragment({
              data: response.data.insert_content_bundles_one,
              fragment: Fragments.ContentBundleFields
            })

            return [...existingContentBundles, newContentBundleRef]
          }
        }
      })
    }
  })
  const [updateContentBundle, { loading: updateIsLoading }] = useMutation(Mutations.UpdateContentBundle, {
    onCompleted: (response) => {
      toast.success('The Article was updated.')
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The Article could not be updated.')
    }
  })
  const [insertContentBundleAttachments, { loading: insertAttachmentIsLoading }] = useMutation(Mutations.InsertContentBundleAttachments, {
    onError: (error) => {
      debugMessage(error)
      toast.error('The Feature relationship could not be created.')
    },
    update: (cache, response) => {
      cache.modify({
        fields: {
          attachments (existingAttachments = []) {
            return [
              ...existingAttachments,
              ...response.data.insert_attachments.returning.map(att =>
                cache.writeFragment({
                  data: att,
                  fragment: Fragments.AttachmentFields
                })
              )
            ]
          }
        }
      })
    }
  })

  // ************************************************
  // Handlers
  const handleUpdateContentBundleData = (data) => {
    setContentBundleData(data)
  }

  const handleSave = () => {
    // Ensure that the Formik form is hooked up
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handleSubmitForm = (values, formikBag) => {
    let data = {
      name: values.name,
      organizationId: organization.id,
      description: values.description
    }

    if (contentBundleData.banner_image_id !== -1) {
      data = {
        ...data,
        imageId: contentBundleData.banner_image_id
      }
    }

    if (contentBundleId === -1) {
      // New Content Bundle (aka Article)
      insertContentBundle({ variables: { ...data } })
    } else {
      // Existing Content Bundle (aka Article)
      data = {
        ...data,
        contentBundleId: contentBundleId,
        textContentsId: contentBundleData.text_contents[0].id
      }
      updateContentBundle({ variables: data })
    }
    // Lastly, turn off the submitting flag
    formikBag.setSubmitting(false)
  }

  const addAssociation = (association) => {
    if (contentBundleId === -1) {
      // New ContentBundle, so we'll just push these onto state and then they will be added upon save
      setContentBundleAttachments(contentBundleAttachments.concat([{
        contentName: association.attacheable.name,
        contentId: association.feature.class_name + '_' + association.feature.id,
        feature_id: association.feature.id,
        feature_type: association.feature.class_name,
        feature: {
          class_name: association.feature.class_name,
          area: association.feature.area
        }
      }]))
    } else {
      // Existing ContentBundle
      const attachment = {
        attached_id: contentBundleId,
        attached_type: 'ContentBundle',
        feature_id: association.feature.id,
        feature_type: association.feature.class_name
      }

      insertContentBundleAttachments({ variables: { newAttachments: [attachment] } })
        .then(response => {
          // If the mutation failed for some reason, it does not throw a Promise error that .catch() will receive
          //  the mutation will return an <ApolloError> (extends Error) as an object on response.errors
          if (response.errors === undefined && response.data?.insert_attachments?.returning?.[0]?.id) {
            setContentBundleAttachments(contentBundleAttachments.concat([{
              id: response.data.insert_attachments.returning[0].id,
              contentName: association.attacheable.name,
              contentId: association.feature.class_name + '_' + association.feature.id,
              feature_id: association.feature.id,
              feature_type: association.feature.class_name,
              feature: {
                class_name: association.feature.class_name,
                area: association.feature.area
              }
            }]))
          }
        })
    }
    // Else, we just keep managing the assocations in state until the ContentBundle is created
    //  Then we create the attachments when we have the ContentBundle ID
  }

  const removeAssociation = (association) => {
    deleteContentBundleAttachment({ variables: { attachmentId: association.id } })
  }

  if (getError && getError.message) {
    return <Alert bsStyle='danger'>{getError.message}</Alert>
  } else if (getIsLoading || (contentBundleId !== -1 && contentBundleData?.id === -1 && !contentBundleData.invalidId)) {
    return <CenteredLoader />
  } else if (contentBundleData?.invalidId) {
    return <Alert bsStyle='warning'>There is no Article with the requested ID.</Alert>
  } else if (contentBundleData?.id !== -1 && contentBundleData?.invalidId) {
    return <Error404 />
  }

  return (
    <div className='contentBundle-wrap'>
      {(insertIsLoading || updateIsLoading) &&
        <CenteredLoader overlay />}

      <PageLayout>
        <PageHeader>
          <ContentBundleHeader
            contentBundle={contentBundleData}
            history={history}
            match={match}
            onSaveClick={handleSave}
          />
        </PageHeader>
        <PageContent>
          <div className='contentBundleInfo-wrap'>
            <div className='contentBundleInfo-content'>
              <Panel>
                <Panel.Heading>
                  Basic Info
                  <div style={{ position: 'absolute', right: '0', top: '6px' }}>
                    <Button
                      bsStyle='link'
                      onClick={handleContentStyleGuideClick}
                    >
                      Content Style Guide
                    </Button>
                  </div>
                </Panel.Heading>
                <Panel.Body>
                  <ContentBundleForm
                    contentBundle={contentBundleData}
                    submitForm={handleSubmitForm}
                    updateContentBundleData={handleUpdateContentBundleData}
                    history={history}
                    formRef={formRef}
                    match={match}
                    organization={organization}
                  />
                </Panel.Body>
              </Panel>
              <Panel className='contentBundleInfo-associations'>
                <Panel.Heading>Related</Panel.Heading>
                <Panel.Body>
                  {insertAttachmentIsLoading && <CenteredLoader overlay />}
                  <AssociationSet
                    associations={contentBundleAttachments}
                    entityTypes={['Features']}
                    isMultipleAssociationSet
                    onAdd={(association) => addAssociation(association)}
                    onRemove={(association) => removeAssociation(association)}
                    restrictToCurrentOrganization
                  />
                </Panel.Body>
              </Panel>
            </div>
          </div>
        </PageContent>
      </PageLayout>
    </div>
  )
}

export default ContentBundle
