import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { arrayMoveImmutable } from 'array-move'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Alert, Button, Col, Panel, Row } from 'react-bootstrap'
import debugMessage from 'services/Debug'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { CenteredLoader } from '../../../../components/centeredLoader'
import { entityByTypename } from '../../../../components/entities'
import { handleContentStyleGuideClick } from '../../../../components/helpScout'
import StewardshipSet from '../../../../components/stewardshipSet'
import { Mutations, Queries } from '../FeatureOperations'
import './featureInfo.css'
import { cleanPhone } from 'services/Phone'
import * as Sections from './sections'
import { useCurrentUser } from 'contexts/CurrentUserContext'

const classMatch = (classes, model) => classes.indexOf(model.class_name) > -1

const isSuperAdmin = (user) => {
  return user.roles.find((item) => { return item.role.name === 'admin' })
}

const FeatureInfo = (props) => {
  const currentUser = useCurrentUser()
  const { formRef, feature, handleSubmitForm, organization, pointTypeOptions = [] } = props
  const [areaDestinations, setAreaDestinations] = useState(feature.area_destinations)
  const [contentBlocksData, setContentBlocksData] = useState(feature.content_blocks)
  // const [partnerPointsOfInterest, setPartnerPointsOfInterest] = useState(feature.partner_points_of_interest)
  const [positionOfNewContentBlock, setPositionOfNewContentBlock] = useState(1)
  const [selectedAccessibility, setSelectedAccessibility] = useState([])
  const [selectedAllowedActivities, setSelectedAllowedActivities] = useState([])
  const [selectedAllowedAccess, setSelectedAllowedAccess] = useState([])
  const [selectedGoodFor, setSelectedGoodFor] = useState([])
  const [selectedRulesAndRegulations, setSelectedRulesAndRegulations] = useState([])
  const [selectedSeasons, setSelectedSeasons] = useState([])
  const [stewardships, setStewardships] = useState(feature.stewardships)
  const [updatingContentBlocks, setUpdatingContentBlocks] = useState(false)
  const client = useApolloClient()
  const bounds = (() => {
    switch (feature.class_name) {
      case 'Area':
        return feature.extent.geojson
      case 'PointOfInterest':
        return null
      case 'Trail':
        return feature.extent
    }
  })()
  const center = (() => {
    switch (feature.class_name) {
      case 'Area':
        return feature.centroid.geojson
      case 'PointOfInterest':
        return feature.location.geometry
      case 'Trail':
        return null
    }
  })()
  const phone = cleanPhone(feature.phone_number)
  // For certain values, we need to transform a `null` value from GraphQL to an empty string before
  //  passing this data into Formik. If we leave it as null, React will throw a warning about changing
  //  an uncontrolled component to a controlled one
  const initialValues = {
    ...feature,
    accessibility_description: feature.accessibility_description ?? '',
    address: feature.address ?? '',
    description: feature.description ?? '',
    name: feature.name ?? '',
    parent_area_id: feature.parent_area?.id ?? -1,
    parent_poi_id: feature.parent_poi_id ?? -1,
    phone_number: phone ?? '',
    website: feature.website ?? ''
  }

  // Set initial seasons from feature.tags
  if (selectedSeasons.length === 0 && feature.tags.length > 0) {
    const seasons = feature.tags.filter(existingTag => (_.indexOf(['winter', 'spring', 'summer', 'fall'], existingTag.key) > -1))

    if (seasons.length > 0) {
      setSelectedSeasons(seasons)
    }
  }

  // Basically this is an in-page permission check that if this feature is not managed or owned by the current Organization in context, disable adding/removing Partners.
  const isFeatureManagerOrOwner = () => {
    let areaManager = false
    let manager = false

    if (feature.area) {
      areaManager = feature.area.stewardships.filter(o => { return (o.organization_id === organization.id) && (o.role === 'owner' || o.role === 'manager') }).length > 0
    }

    manager = feature.stewardships.filter(o => { return (o.organization_id === organization.id) && (o.role === 'owner' || o.role === 'manager') }).length > 0

    return areaManager || manager
  }

  // QUERY:
  useQuery(
    Queries.GetMaxPositionOfContentBlocks, {
      variables: {
        featureId: feature.id,
        featureType: feature.class_name
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const maxPosition = data.content_blocks_aggregate.aggregate?.max?.position

        if (maxPosition && (maxPosition + 1 !== positionOfNewContentBlock)) {
          setPositionOfNewContentBlock(maxPosition + 1)
        } else if (positionOfNewContentBlock !== 1) {
          setPositionOfNewContentBlock(1)
        }
      }
    }
  )

  // MUTATION:
  const [deleteContentBlock] = useMutation(Mutations.DeleteContentBlock, {
    onCompleted: (data) => {
      if (data.delete_content_blocks_by_pk?.id) {
        const removedId = data.delete_content_blocks_by_pk.id
        const newContentBlocks = contentBlocksData.filter(cb => cb.id !== removedId)
        const sortedContentBlocks = _.sortBy(newContentBlocks, 'position')

        setContentBlocksData(sortedContentBlocks)
        setPositionOfNewContentBlock(sortedContentBlocks.length + 1)

        if (sortedContentBlocks.length) {
          updateContentBlocksPositions(sortedContentBlocks)
        }

        toast.success('The additional info section was deleted.')
      }
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The additional info section could not be deleted. Please try again.')
    }
  })
  const [insertContentBlock] = useMutation(Mutations.InsertContentBlock, {
    onCompleted: (data) => {
      if (data.insert_content_blocks_one) {
        const newContentBlock = data.insert_content_blocks_one
        const updatedContentBlocks = contentBlocksData.concat(newContentBlock)

        setContentBlocksData(updatedContentBlocks)
        toast.success('The additional info section was added.')
      }
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('There was an issue adding the additional info section. Please try again.')
    }
  })
  const [updateContentBlock] = useMutation(Mutations.UpdateContentBlock, {
    onCompleted: (data) => {
      if (data.update_content_blocks_by_pk) {
        const updatedContentBlock = data.update_content_blocks_by_pk
        const newContentBlocks = contentBlocksData.map(cb => {
          if (cb.id === updatedContentBlock.id) {
            return { ...cb, ...updatedContentBlock }
          } else {
            return cb
          }
        })

        setContentBlocksData(newContentBlocks)

        toast.success('The additional info section was updated.')
      }
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The additional info section could not be updated. Please try again.')
    }
  })

  const formatTagsData = (featureId, tags) => {
    let newTags = []
    if (tags.length > 0) {
      newTags = tags.map((tag) => {
        return {
          feature_id: featureId,
          feature_type: entityByTypename(feature.__typename)?.classSingular,
          key: tag.key,
          value: 'yes'
        }
      })
    }
    return newTags
  }
  const generatePositionMutationFromContentBlocks = (contentBlocks) => {
    // TODO-DEFER: Figure out how to call a Mutation with the above dynamically created GraphQL AST WITHOUT `client`
    // We want the mutations all combined because:
    //  "If multiple mutations are part of the same request, they are executed sequentially in a single transaction.
    //  If any of the mutations fail, all the executed mutations will be rolled back." - Apollo Client documentation
    // Perform GraphQL mutation to update the position values.
    let mutationAST = 'mutation UpdateContentBlocksPositions {'
    contentBlocks.forEach((cb, index) => {
      mutationAST += `
        a${index}: update_content_blocks_by_pk(
          pk_columns: {id: ${cb.id}},
          _set: {position: ${index + 1}}
        ) {
          id
          position
        }
      `
    })
    mutationAST += '}'

    return mutationAST
  }

  const handleAddAreaDestination = (pointOfInterest) => {
    const newAreaDestination = {
      area_id: feature.id,
      point_of_interest: pointOfInterest,
      point_of_interest_id: pointOfInterest.id
    }

    // Ensure that this area_destination doesn't already exist, otherwise we duplicate it.
    if (!areaDestinations.filter(s => (s.point_of_interest_id === newAreaDestination.point_of_interest_id)).length) {
      setAreaDestinations([
        ...areaDestinations,
        newAreaDestination
      ])
    }
  }
  const handleAddStewardship = (stewardship) => {
    const newStewardship = {
      stewardable_id: feature.id,
      organization_id: stewardship.organization.id,
      organization: { name: stewardship.organization.name },
      role: stewardship.role
    }

    // Ensure that this stewardships doesn't already exist, otherwise we duplicate it.
    if (!stewardships.filter(s => (s.organization_id === newStewardship.organization_id && s.role === newStewardship.role)).length) {
      setStewardships([
        ...stewardships,
        newStewardship
      ])
    }
  }
  const handleDeleteContentBlock = (contentBundleId) => {
    const conf = window.confirm('Are you sure you want to delete this additional info section?')

    if (conf) {
      deleteContentBlock({ variables: { id: contentBundleId } })
    }
  }
  const handleDestroyAreaDestination = (areaDestination) => {
    setAreaDestinations(areaDestinations.filter(s => !(s.point_of_interest.id === areaDestination.point_of_interest.id)))
  }
  const handleDestroyStewardship = (stewardship) => {
    setStewardships(stewardships.filter(s =>
      !(s.organization_id === stewardship.organization_id &&
        s.role === stewardship.role)
    ))
  }
  const handleSaveContentBlock = (values, onComplete) => {
    if (!values.id) {
      insertContentBlock({
        variables: {
          title: values.title,
          body: values.body,
          featureType: feature.class_name,
          featureId: feature.id,
          position: positionOfNewContentBlock
        }
      })
        .then(() => setPositionOfNewContentBlock(positionOfNewContentBlock => positionOfNewContentBlock + 1))
        .finally(onComplete)
    } else {
      updateContentBlock({
        variables: {
          id: values.id,
          title: values.title,
          body: values.body
        }
      }).finally(onComplete)
    }
  }
  const handleSeasonChange = (seasons) => {
    const newSeasonTags = seasons.map(s => { return { key: s } })
    setSelectedSeasons(newSeasonTags)
  }
  const handleUpdateContentBlockOrder = (oldIndex, newIndex) => {
    const sortedContentBlocks = _.sortBy(contentBlocksData, 'position')
    const newContentBlockOrder = arrayMoveImmutable(sortedContentBlocks, oldIndex, newIndex)
    const successCallback = () => toast.success('Additional Info order saved.')
    const failureCallback = (error) => {
      // Revert local state so UI matches the state of the data
      setContentBlocksData(sortedContentBlocks)
      debugMessage(error)
      toast.error('There was an error updating the order of Additional Info')
    }

    setContentBlocksData(newContentBlockOrder)
    updateContentBlocksPositions(newContentBlockOrder, successCallback, failureCallback)
  }
  const processSubmitForm = (values, formikBag) => {
    const seasons = (selectedSeasons.length > 0 && selectedSeasons.length < 4) ? selectedSeasons : []
    const newFeature = values
    const currentFormTags = [
      ...selectedAccessibility,
      ...selectedAllowedActivities,
      ...selectedAllowedAccess,
      ...selectedGoodFor,
      ...selectedRulesAndRegulations,
      ...seasons
    ].map((tag) => ({ key: tag.key }))
    const newTags = currentFormTags.filter(newTag => {
      return !(feature.tags.map(existingTag => existingTag.key).includes(newTag.key))
    })

    if (classMatch(['Area'], feature)) {
      newFeature.areaDestinationChanges = {
        added: areaDestinations ? areaDestinations.filter(a =>
          !feature.area_destinations.filter(b => b.point_of_interest.id === a.point_of_interest.id).length
        ) : [],
        removed: feature.area_destinations.filter(a =>
          !areaDestinations.filter(b => b.point_of_interest.id === a.point_of_interest.id).length
        ).map(s => s.id)
      }
    }

    newFeature.newTags = formatTagsData(feature.id, newTags)
    newFeature.keyValuesOfTagsToDelete = feature.tags.filter(t => {
      return !currentFormTags.map(t => t.key).includes(t.key)
    }).map(t => t.key)
    newFeature.stewardshipChanges = {
      added: stewardships ? stewardships.filter(s =>
        !feature.stewardships.filter(fs => fs.organization_id === s.organization_id && fs.role === s.role).length
      ) : [],
      removed: feature.stewardships.filter(fs =>
        !stewardships.filter(s => s.role === fs.role && s.organization_id === fs.organization_id).length
      ).map(s => s.id)
    }

    handleSubmitForm(newFeature, formikBag)
  }
  const updateContentBlocksPositions = (contentBlocks, successCallback = null, failureCallback = null) => {
    const mutationAST = generatePositionMutationFromContentBlocks(contentBlocks)
    const updatedContentBlocks = contentBlocks.map((cb, index) => {
      return { ...cb, position: index + 1 }
    })

    // Update local state optimistically so UI is in sync
    setContentBlocksData(updatedContentBlocks)

    setUpdatingContentBlocks(true)
    client.mutate({ mutation: gql`${mutationAST}` })
      .then(successCallback)
      .catch(error => failureCallback(error))
      .finally(() => {
        setUpdatingContentBlocks(false)
      })
  }

  /*
  {
    organization.id === 6689 && classMatch(['PointOfInterest'], feature) &&
    <Panel>
      <Panel.Heading>Integrations</Panel.Heading>
      <Panel.Body>
        <Sections.Integrations
          feature={feature}
          partnerPointsOfInterest={partnerPointsOfInterest}
        />
      </Panel.Body>
    </Panel>
  }
  */

  return (
    <div className='featureInfo-wrap'>
      <Formik
        // TODO-DEFER: On form isValid() or NOT isValid() pass this state up to the parent to pass to
        //  <FeatureHeader> to disable/enable the Save button
        initialValues={{ ...initialValues }}
        innerRef={formRef}
        onSubmit={(values, formikBag) => {
          processSubmitForm(values, formikBag)
        }}
        validationSchema={
          Yup
            .object()
            .shape({
              accessibility_description: Yup.string().ensure(),
              address: Yup.string(),
              description: Yup.string(),
              name: classMatch(['Area'], feature) ? Yup.string().required('Name is required.') : Yup.string(),
              // NOTE: This is problematic combined with existing data and using Cleave.js.
              //  Sometimes existing data loads in and immediately displays an error because it's `(123) 123-4567` formatted.
              phone_number: Yup.string().matches('^(?:1-?)?([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})$', {
                message: 'Please enter a valid phone number',
                excludeEmptyString: true
              }),
              website: Yup.string().url('Please enter a valid web URL. Must start with http:// or https://')
            })
        }
      >
        {(formikProps) => {
          return (
            <>
              {formikProps.isSubmitting &&
                <CenteredLoader overlay />}
              {!formikProps.isValid &&
                <Alert bsStyle='danger'>Please fix the issues with the form before submitting.</Alert>}

              <Form noValidate>
                <div className='panel-wrap--double'>
                  <Panel className='featureInfo--basic'>
                    <Panel.Heading>
                      Basic Info
                      <div style={{ position: 'absolute', right: '0', top: '6px' }}>
                        <Button
                          bsStyle='link'
                          onClick={handleContentStyleGuideClick}
                        >
                          Content Style Guide
                        </Button>
                      </div>
                    </Panel.Heading>
                    <Panel.Body>
                      <Sections.BasicInfo
                        feature={feature}
                        pointTypeOptions={pointTypeOptions}
                      />
                    </Panel.Body>
                  </Panel>
                  {(bounds || center) &&
                    <Panel className='featureInfo--map'>
                      <Panel.Heading>Map</Panel.Heading>
                      <Panel.Body>
                        <Sections.Map
                          bounds={bounds}
                          center={center}
                          featureId={feature.id}
                          featureType={feature.class_name}
                          organization={organization}
                        />
                      </Panel.Body>
                    </Panel>}
                </div>
                <Panel>
                  <Panel.Heading>Details</Panel.Heading>
                  <Panel.Body>
                    <Sections.ActivitiesAccessibility
                      feature={feature}
                      onAccessibilityChange={setSelectedAccessibility}
                      onAllowedAccessChange={setSelectedAllowedAccess}
                      onAllowedActivitiesChange={setSelectedAllowedActivities}
                      onGoodForChange={setSelectedGoodFor}
                      onRulesAndRegulationsChange={setSelectedRulesAndRegulations}
                      onSeasonChange={handleSeasonChange}
                      organizationTags={organization.tags}
                    />
                  </Panel.Body>
                </Panel>
                <Panel>
                  <Panel.Heading>Stewardships</Panel.Heading>
                  <Panel.Body>
                    <Row>
                      <Col sm={4}>
                        <label
                          className='control-label'
                        >
                          Owning Organizations
                        </label>
                        <div>
                          {isSuperAdmin(currentUser) && (
                            <StewardshipSet
                              onAdd={handleAddStewardship}
                              onRemove={handleDestroyStewardship}
                              roleType='owner'
                              stewardships={stewardships}
                            />)}
                          {!isSuperAdmin(currentUser) && (
                            <ul>
                              {(feature.stewardships.filter(o => o.role === 'owner').length < 1) &&
                                <li key='none'>
                                  None
                                </li>}
                              {feature.stewardships.filter(o => o.role === 'owner').map((s) => {
                                return (
                                  <li key={s.id}>
                                    {s.organization.name}
                                  </li>
                                )
                              })}
                            </ul>)}
                        </div>
                      </Col>
                      <Col sm={4}>
                        <label
                          className='control-label'
                        >
                          Managing Organizations
                        </label>
                        <div>
                          {(isFeatureManagerOrOwner() || isSuperAdmin(currentUser)) && (
                            <StewardshipSet
                              onAdd={handleAddStewardship}
                              onRemove={handleDestroyStewardship}
                              roleType='manager'
                              stewardships={stewardships}
                            />)}
                          {!isFeatureManagerOrOwner() && !isSuperAdmin(currentUser) && (
                            <ul>
                              {(stewardships.filter(o => o.role === 'manager').length < 1) &&
                                <li key='none'>
                                  None
                                </li>}
                              {stewardships.filter(o => o.role === 'manager').map((s) => {
                                return (
                                  <li key={s.id}>
                                    {s.organization.name}
                                  </li>
                                )
                              })}
                            </ul>)}
                        </div>
                      </Col>
                      <Col sm={4}>
                        <label
                          className='control-label'
                        >
                          Partner Organizations
                        </label>
                        <div>
                          {(isFeatureManagerOrOwner() || isSuperAdmin(currentUser)) && (
                            <StewardshipSet
                              onAdd={handleAddStewardship}
                              onRemove={handleDestroyStewardship}
                              roleType='partner'
                              stewardships={stewardships}
                            />)}
                          {!isFeatureManagerOrOwner() && !isSuperAdmin(currentUser) && (
                            <ul>
                              {(stewardships.filter(o => o.role === 'partner').length < 1) &&
                                <li key='none'>
                                  None
                                </li>}
                              {stewardships.filter(o => o.role === 'partner').map((s) => {
                                return (
                                  <li key={s.id}>
                                    {s.organization.name}
                                  </li>
                                )
                              })}
                            </ul>)}
                        </div>
                      </Col>
                    </Row>
                  </Panel.Body>
                </Panel>
                {classMatch(['Area'], feature) &&
                  <Panel>
                    <Panel.Heading>Location &amp; Contact</Panel.Heading>
                    <Panel.Body>
                      <Sections.LocationContact
                        areaDestinations={areaDestinations}
                        feature={feature}
                        handleAddAreaDestination={handleAddAreaDestination}
                        handleDestroyAreaDestination={handleDestroyAreaDestination}
                      />
                    </Panel.Body>
                  </Panel>}
                {classMatch(['Trail'], feature) &&
                  <Panel>
                    <Panel.Heading>Contact</Panel.Heading>
                    <Panel.Body>
                      <Sections.LocationContact feature={feature} />
                    </Panel.Body>
                  </Panel>}
                <Panel>
                  <Panel.Heading>Additional Info</Panel.Heading>
                  <Panel.Body>
                    {!!contentBlocksData &&
                      <Sections.AdditionalInfo
                        contentBlocks={contentBlocksData}
                        onDelete={handleDeleteContentBlock}
                        onOrderUpdate={handleUpdateContentBlockOrder}
                        onSave={handleSaveContentBlock}
                        updating={updatingContentBlocks}
                      />}
                  </Panel.Body>
                </Panel>
              </Form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

FeatureInfo.propTypes = {
  feature: PropTypes.object.isRequired,
  formRef: PropTypes.object,
  handleSubmitForm: PropTypes.func,
  organization: PropTypes.object,
  pointTypeOptions: PropTypes.array
}

export default FeatureInfo
