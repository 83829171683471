import { format } from 'date-fns'
import { useQuery } from '@apollo/client'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import Select from 'react-select'
import sub from 'date-fns/sub'

import { CenteredLoader } from 'components/centeredLoader'
import { Queries } from './DashboardOperations'

const AnalyticsCommunityUniqueVisitors = (props) => {
  const { organization } = props
  const { data, error, loading } = useQuery(Queries.GetCommunityUniqueVisitors, {
    variables: {
      gte: `${format(sub(new Date(), { days: 7 }), 'yyy-MM-dd')}T00:00:00+00:00`,
      lt: `${format(new Date(), 'yyyy-MM-dd')}T00:00:00+00:00`,
      organizationId: organization.id
    }
  })
  const platforms = {
    android: 'Android',
    ios: 'iOS',
    web: 'Web'
  }
  const [chartData, setChartData] = useState()
  const [chartOptions, setChartOptions] = useState()
  const [communityOptions, setCommunityOptions] = useState()
  const [selectedCommunity, setSelectedCommunity] = useState()

  const getCategories = () => {
    const today = new Date()

    return [
      `${format(sub(today, { days: 7 }), 'yyy-MM-dd')}T00:00:00+00:00`,
      `${format(sub(today, { days: 6 }), 'yyy-MM-dd')}T00:00:00+00:00`,
      `${format(sub(today, { days: 5 }), 'yyy-MM-dd')}T00:00:00+00:00`,
      `${format(sub(today, { days: 4 }), 'yyy-MM-dd')}T00:00:00+00:00`,
      `${format(sub(today, { days: 3 }), 'yyy-MM-dd')}T00:00:00+00:00`,
      `${format(sub(today, { days: 2 }), 'yyy-MM-dd')}T00:00:00+00:00`,
      `${format(sub(today, { days: 1 }), 'yyy-MM-dd')}T00:00:00+00:00`
    ]
  }
  const getChartData = () => {
    // TODO: Still need to figure out how to add analytics for community pages that are loaded in Web without a community designated.
    const community = data.communities.find(community => community.id === selectedCommunity)
    const days = getCategories()
    const series = []

    for (const platform in platforms) {
      series.push({
        data: [],
        name: platforms[platform]
      })
    }

    days.forEach(day => {
      const androidDayViewContents = community.community_unique_view_content_by_day.find(viewContents => day === viewContents.day && viewContents.application === 'android')
      const iosDayViewContents = community.community_unique_view_content_by_day.find(viewContents => day === viewContents.day && viewContents.application === 'ios')
      const webDayViewContents = community.community_unique_view_content_by_day.find(viewContents => day === viewContents.day && viewContents.application === 'web')

      if (androidDayViewContents) {
        series.find(s => s.name === 'Android').data.push(androidDayViewContents.count)
      } else {
        series.find(s => s.name === 'Android').data.push(0)
      }

      if (iosDayViewContents) {
        series.find(s => s.name === 'iOS').data.push(iosDayViewContents.count)
      } else {
        series.find(s => s.name === 'iOS').data.push(0)
      }

      if (webDayViewContents) {
        series.find(s => s.name === 'Web').data.push(webDayViewContents.count)
      } else {
        series.find(s => s.name === 'Web').data.push(0)
      }
    })

    return series
  }
  const getChartOptions = () => {
    return {
      chart: {
        fontFamily: '"Karla", sans-serif',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
        type: 'bar',
        zoom: {
          enabled: false
        }
      },
      colors: [
        '#43a6cf',
        '#c55338',
        '#fcbc05',
        '#7da835'
      ],
      fill: {
        opacity: 1
      },
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: false
        }
      },
      tooltip: {
        marker: { show: false },
        theme: 'light'
      },
      xaxis: {
        categories: getCategories(),
        labels: {
          format: 'MMM d'
        },
        type: 'datetime'
      },
      yaxis: {
        title: {
          text: 'Visitors'
        }
      }
    }
  }
  const getCommunityOptions = () => {
    return data.communities.map(community => {
      return {
        label: community.name,
        value: community.id
      }
    })
  }
  const setCommunity = (value) => {
    setSelectedCommunity(value.value)
  }

  useEffect(() => {
    if (data) {
      setCommunity(getCommunityOptions()[0])
    }
  }, [data])
  useEffect(() => {
    if (selectedCommunity) {
      setChartData(getChartData())
      setChartOptions(getChartOptions())
      setCommunityOptions(getCommunityOptions())
    }
  }, [selectedCommunity])

  return (
    <Panel>
      {communityOptions && communityOptions.length && (
        <Panel.Heading>
          <Panel.Title>
            Community Unique Visitors
            <p>The number of unique visitors that interacted with the <strong>{communityOptions.find(communityOption => communityOption.value === selectedCommunity).label}</strong> community's screens/pages each day of the past week.</p>
          </Panel.Title>
        </Panel.Heading>
      )}
      {(!communityOptions || !communityOptions.length) && (
        <Panel.Heading>
          <Panel.Title>
            Community Unique Visitors
            <p>The number of unique visitors that interacted with the screens/pages in the community(-ies) your organization is part of each day of the past week.</p>
          </Panel.Title>
        </Panel.Heading>
      )}
      {error && (
        <Panel.Body>
          <p>Error!</p>
        </Panel.Body>
      )}
      {!error && loading && (
        <Panel.Body>
          <CenteredLoader />
        </Panel.Body>
      )}
      {!error && !loading && data && chartData && chartOptions && communityOptions && (
        <Panel.Body>
          {communityOptions.length > 1 && (
            <Select
              isClearable={false}
              onChange={setCommunity}
              options={communityOptions}
              value={communityOptions.filter(({ value }) => value === selectedCommunity)}
            />
          )}
          <ReactApexChart type='bar' series={chartData} options={chartOptions} height={364} />
        </Panel.Body>
      )}
    </Panel>
  )
}

AnalyticsCommunityUniqueVisitors.propTypes = {
  organization: PropTypes.object
}

export default AnalyticsCommunityUniqueVisitors
