import PropTypes from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Nav from 'react-bootstrap/lib/Nav'
import NavItem from 'react-bootstrap/lib/NavItem'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'

import { featureByTypename } from '../../../components/entities'
import FeatureStatusDropdown from '../../../components/featureStatusDropdown'
import FeatureVisibilityDropdown from '../../../components/featureVisibilityDropdown'
import MoreDropdown from 'components/moreDropdown'

const propTypes = {
  feature: PropTypes.object,
  match: PropTypes.object,
  onSaveClick: PropTypes.func,
  showSaveButton: PropTypes.bool
}

const classMatch = (classes, model) => classes.indexOf(model.class_name) > -1

const FeatureHeader = ({ feature = {}, match, onSaveClick, showSaveButton }) => {
  const featureLang = featureByTypename(feature.__typename)
  const featureType = (feature.__typename === 'points_of_interest') ? 'points-of-interest' : feature.__typename
  const areaLink = `/${match.url.split('/')[1]}/locations/areas/${feature.parent_area?.id}`
  const backLink = `/${match.url.split('/')[1]}/locations/${featureType}`
  const featureTypeDisplay = (() => {
    switch (featureType) {
      case 'points-of-interest':
        return feature.point_type || featureLang.singular
      default:
        return featureLang.singular
    }
  })()

  return (
    <div className='feature-header'>
      <div className='feature-header__title'>
        <div className='feature-header__titleText'>
          <h1>
            {feature.name}
          </h1>
          <h2 className='feature-header__breadcrumbs'>
            <Link to={backLink}>
              {featureTypeDisplay}
            </Link>
            {!!feature.parent_area?.id &&
              <span>
                &nbsp;in&nbsp;
                <Link to={areaLink}>
                  {feature.parent_area.name}
                </Link>
              </span>}
          </h2>
          <p>{feature._links ? feature._links.web : null}</p>
        </div>
        <div className='feature-header__titleActions'>
          <FeatureStatusDropdown
            feature={feature}
          />
          <FeatureVisibilityDropdown
            featureId={feature.id}
            featureType={featureType}
            initialValue={feature.visibility}
          />
          <MoreDropdown
            feature={feature}
            featureType={featureType}
          />
          {showSaveButton &&
            <Button
              bsStyle='primary'
              key='save'
              onClick={onSaveClick}
              type='submit'
            >
              Save
            </Button>}
        </div>
      </div>
      <div className='feature-tabs'>
        <Nav bsStyle='pills' className='nav-borders'>
          <LinkContainer to={`${match.url}/info`}>
            <NavItem>Info</NavItem>
          </LinkContainer>
          <LinkContainer to={`${match.url}/social`}>
            <NavItem>Social</NavItem>
          </LinkContainer>
          {classMatch(['Area'], feature) &&
            <LinkContainer to={`${match.url}/locations`}>
              <NavItem>Locations</NavItem>
            </LinkContainer>}
          <LinkContainer to={`${match.url}/documents`}>
            <NavItem>Documents</NavItem>
          </LinkContainer>
          <LinkContainer to={`${match.url}/maps`}>
            <NavItem>Maps</NavItem>
          </LinkContainer>
        </Nav>
      </div>
    </div>
  )
}

FeatureHeader.propTypes = propTypes

export default FeatureHeader
