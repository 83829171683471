import { useMutation, useQuery, gql } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { React, useEffect } from 'react'
import Button from 'react-bootstrap/lib/Button'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import Label from 'react-bootstrap/lib/Label'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Panel from 'react-bootstrap/lib/Panel'
import Table from 'react-bootstrap/lib/Table'
import toast from 'react-hot-toast'

import { CenteredLoader } from '../../../../components/centeredLoader'
import { Mutations, Queries } from '../MembershipsOperations'
import debugMessage from 'services/Debug'
import HelpDropdown from 'components/helpDropdown'
import MembershipRequests from './MembershipRequests'
import './memberships.css'

const ResendInvitationMutation = gql`
  mutation ResendInvitationMutation($id: Int!) {
    update_users(where: {id: {_eq: $id}}, _set: {invitation_created_at: "now()"}, _inc: {invitations_count: 1}) {
      affected_rows
      returning {
        id
        invitations_count
        invitation_created_at
      }
    }
  }
`

const Memberships = ({ isOrganizationAdmin, isSuperAdmin }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const [resendInivtationMutation] = useMutation(ResendInvitationMutation)
  const { data, error, loading } = useQuery(Queries.GetMembershipsByOrganizationId, {
    fetchPolicy: 'no-cache',
    skip: (organization === null || organization.id === null),
    variables: {
      organizationId: (organization === null) ? -1 : organization.id
    }
  })
  const [deleteMembership] = useMutation(Mutations.DeleteMembershipById, {
    refetchQueries: ['GetMembershipsByOrganizationId'],
    onCompleted: () => {
      toast.success('Successfully removed the team member.')
    },
    onError: (innerError) => {
      debugMessage(innerError)
      toast.error('The team member could not be removed.')
    }
  })
  const membersAgainstLimit = (() => {
    let count = 0

    if (data && data.memberships) {
      data.memberships.forEach((membership) => {
        if (membership.user_email.email.indexOf('outerspatial.com') === -1) {
          count++
        }
      })
    }

    return count
  })()
  const sortedMemberships = data ? _.sortBy(data.memberships, (member) => member.user?.profile?.name?.toLowerCase()) : []

  const handleCreateClick = () => {
    if (isOrganizationAdmin || isSuperAdmin) {
      history.push(`${match.path}/new`)
    } else {
      handleViewHelpClick()
    }
  }

  const handleDeleteClick = (membership) => {
    if (isOrganizationAdmin || isSuperAdmin) {
      const conf = window.confirm('Are you sure you want to remove this team member?')
      const membershipId = membership.id

      if (conf) {
        deleteMembership({
          variables: { membershipId }
        })
      }
    } else {
      handleViewHelpClick()
    }
  }

  const handleEditClick = (membership) => {
    history.push(`${match.path}/${membership.id}`)
  }

  const handleResendInviteClick = (membership) => {
    resendInivtationMutation({
      variables: {
        id: membership.user.id
      }
    })
      .then(() => {
        toast.success('Invite email resent successfully.')
      })
      .catch(() => {
        toast.error('The invite email could not be resent.')
      })
  }

  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '68', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/68-managing-your-organization' }).click()
    }
  }

  const handleWalkthroughVideoSelect = () => {
    if (window.Beacon) {
      const beaconInfo = window.Beacon('info')
      // Organizations
      var messageId = '2d49881d-6707-4f02-a143-9a40f9c9e0f8'

      if (beaconInfo && beaconInfo.beaconId !== '8bdf21e5-c4fc-43d1-8bd3-e17a0cf162f0') {
        // Sales
        messageId = '2f90f44b-4411-472d-a471-e157c248ad01'
      }

      window.Beacon && window.Beacon('show-message', messageId, {
        force: true
      })
    }
  }

  useEffect(() => {
    document.title = `Team | Organization | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  return (
    <div className='organizationMembers'>
      <div className='organizationMembers-titleBar'>
        <div className='organizationMembers-actions'>
          {(!loading) &&
            <Button
              bsStyle='link'
              onClick={handleViewHelpClick}
            >
              {membersAgainstLimit}/{organization.membership_limit} Accounts Used
            </Button>}
          <HelpDropdown
            handleViewHelpSelect={handleViewHelpClick}
            handleWalkthroughVideoSelect={handleWalkthroughVideoSelect}
          />
          {(isOrganizationAdmin || isSuperAdmin) && (
            <span>
              &nbsp;
              <Button
                bsStyle='primary'
                disabled={(membersAgainstLimit >= organization.membership_limit) && !isSuperAdmin}
                onClick={handleCreateClick}
              >
                Add Team Member
              </Button>
            </span>)}
        </div>
      </div>
      <div className='organizationMembers-list'>
        <Panel>
          <Table striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th />
              </tr>
            </thead>
            {loading &&
              <tbody>
                <tr>
                  <td colSpan='4'>
                    <CenteredLoader />
                  </td>
                </tr>
              </tbody>}
            {(!loading && error) &&
              <tbody>
                <tr>
                  <td colSpan='4'>
                    <p>Error</p>
                  </td>
                </tr>
              </tbody>}
            {(!loading && !error) &&
              <tbody>
                {sortedMemberships.map((membership, index) => {
                  let hasAcceptedInvite = true
                  const name = membership.user.profile.name ? membership.user.profile.name : 'none'
                  const nameStyle = (name !== 'none') ? '' : 'none'
                  const isOuterSpatialStaff = membership.user_email.email.indexOf('outerspatial.com') > -1
                  let role = _.capitalize(membership.role)

                  if (isOuterSpatialStaff) {
                    role = 'OuterSpatial Staff'
                  }

                  if (!isOuterSpatialStaff && membership.user.manager_logins.aggregate.count === 0 && (membership.user.invitation_sent_at !== null && membership.user.invitation_accepted_at === null)) {
                    hasAcceptedInvite = false
                  }

                  return (
                    <tr key={index}>
                      <td className={nameStyle}>
                        {/* Logic for OuterSpatial Staff (`outerspatial.com` emails) */}
                        {/* Allow editing of OuterSpatial employees only is the current user is a SuperAdmin */}
                        {isOuterSpatialStaff && isSuperAdmin &&
                          <Link to={`${match.url}/${membership.id}`}>{name}</Link>}
                        {/* Do not allow editing of OuterSpatial employees if not a Super Admin */}
                        {isOuterSpatialStaff && !isSuperAdmin &&
                          <span>{name}</span>}
                        {/* This section is the logic around actual customer permissions */}
                        {!isOuterSpatialStaff && (isOrganizationAdmin || isSuperAdmin) &&
                          <Link to={`${match.url}/${membership.id}`}>{name}</Link>}
                        {!isOuterSpatialStaff && !isOrganizationAdmin && !isSuperAdmin &&
                          <span>{name}</span>}
                        {!hasAcceptedInvite &&
                          <Label bsStyle='warning'>INVITED</Label>}
                      </td>
                      <td className='organizationMembers--email'>
                        {membership.user_email.email}
                      </td>
                      <td>
                        {role}
                      </td>
                      <td className='organizationMembers--actions'>
                        {(isSuperAdmin || (isOrganizationAdmin && !isOuterSpatialStaff)) &&
                          <DropdownButton title='Actions' id='actions' bsSize='small' pullRight>
                            <MenuItem eventKey='edit' onClick={() => handleEditClick(membership)}>Edit</MenuItem>
                            <MenuItem eventKey='delete' onClick={() => handleDeleteClick(membership)}>Remove</MenuItem>
                            {!hasAcceptedInvite &&
                              <MenuItem eventKey='invite' onClick={() => handleResendInviteClick(membership)}>Resend Invite</MenuItem>}
                          </DropdownButton>}
                      </td>
                    </tr>
                  )
                })}
              </tbody>}
          </Table>
        </Panel>
      </div>
      <div className='organizationMembershipRequests'>
        <h4>Membership Requests</h4>
        <MembershipRequests isSuperAdmin={isSuperAdmin} isOrganizationAdmin={isOrganizationAdmin} />
      </div>
    </div>
  )
}

Memberships.propTypes = {
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default Memberships
