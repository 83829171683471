import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Document from './document/Document'
import DocumentsList from './documentsList/DocumentsList'

const Documents = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${match.url}/:documentId`} component={Document} />
      <Route exact path={`${match.url}`} component={DocumentsList} />
    </Switch>
  )
}

export default Documents
