import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import { useHistory, useRouteMatch } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import debugMessage from 'services/Debug'
import toast from 'react-hot-toast'
import { Mutations } from '../MembershipsOperations'
import './membership.css'

const MembershipHeader = ({ onHandleViewHelpClick, onSaveClick }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const backPath = match.path.split(':')[0].slice(0, match.path.split(':')[0].length - 1)
  const membershipId = match.params.membershipId
  const [deleteMembership/*, { loading: deleteIsLoading } */] = useMutation(
    Mutations.DeleteMembershipById, {
      onCompleted: () => {
        history.push(backPath)
      },
      onError: (error) => {
        debugMessage(error)
        toast.error('The team member could not be removed.')
      },
      update: (cache, response) => {
        cache.modify({
          fields: {
            memberships (existingMemberships = []) {
              return existingMemberships.filter((t) => {
                return t.__ref !== `memberships:${response.data.delete_memberships.returning[0].id}`
              })
            }
          }
        })
      }
    }
  )

  const handleDeleteClick = () => {
    const conf = window.confirm('Are you sure you want to remove this team member?')

    if (conf) {
      deleteMembership({
        variables: { membershipId }
      })
    }
  }

  return (
    <div className='membership-header'>
      <div className='membership-header__title'>
        <div className='membership-header__titleText'>
          <LinkContainer to={backPath}>
            <Button bsStyle='link'>
              <Glyphicon glyph='chevron-left' />Back to Team
            </Button>
          </LinkContainer>
          {match.params.membershipId === 'new' &&
            <h1>Add Team Member</h1>}
          {match.params.membershipId !== 'new' &&
            <h1>Edit Team Member</h1>}
        </div>
        <div className='membership-header__titleActions'>
          {match.params.membershipId !== 'new' &&
            <Button
              bsStyle='danger'
              onClick={handleDeleteClick}
            >
              Remove
            </Button>}
          <Button
            bsStyle='primary'
            onClick={onSaveClick}
          >
            Save
          </Button>
          <Button
            bsStyle='primary'
            onClick={onHandleViewHelpClick}
          >
            View Help
          </Button>
        </div>
      </div>
    </div>
  )
}

MembershipHeader.propTypes = {
  onHandleViewHelpClick: PropTypes.func,
  onSaveClick: PropTypes.func
}

export default MembershipHeader
