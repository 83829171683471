import PropTypes from 'prop-types'
import React from 'react'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import './checkbox.css'

const Checkbox = (props) => {
  const { checked, ...rest } = props

  return (
    <span
      checked={checked}
      className={'os-checkbox ' + (checked ? 'os-checkbox--checked' : '')}
      {...rest}
    >
      <span className='os-checkbox-box'>
        {checked &&
          <Glyphicon glyph='ok' />}
      </span>
    </span>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool
}
export default Checkbox
