import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/lib/Alert'
import { useHistory, useRouteMatch } from 'react-router'
import ReactSelect from 'react-select'
import debugMessage from 'services/Debug'
import toast from 'react-hot-toast'
import * as Yup from 'yup'

import { CenteredLoader } from '../../../../components/centeredLoader'
import Error404 from '../../../errors/404'
import { Mutations, Queries } from '../MembershipsOperations'

import './membership.css'

const MakePrimaryMembership = gql`
  mutation MakePrimaryMembership($id: Int!) {
    update_memberships_by_pk(pk_columns: {
      id: $id
    }, _set: {
      primary: true
    }) {
      id
      primary
    }
  }
`
const InvitationMutation = gql`
  mutation InsertAuth0User(
    $email: String!,
    $first_name: String,
    $invitation_created_at: String,
    $invited_by_id: Int!,
    $last_name: String,
    $organization_id: Int!,
    $role: String = "staff"
  ) {
    insert_member_profiles_one(object: {    
      first_name: $first_name,
      last_name: $last_name,
      user: {
        data: {
          email: $email, 
          invitation_created_at: "now()",
          invited_by_id: $invited_by_id,
          invited_by_type: "User",
          memberships: {
            data: {
              organization_id: $organization_id,
              role: $role
            },
            on_conflict: {
              constraint: memberships_organization_id_user_id_key,
              update_columns: [
                role
              ]
            }
          },
          upserted_at: "now()"          
        },
        on_conflict: {
          constraint: users_email_key,
          update_columns: [
            upserted_at
          ]
        }
      }
    },
    on_conflict: {
      constraint: member_profiles_user_id_key, 
      update_columns: [
        first_name,
        last_name
      ]
    }) {    
      id
      created_at
      first_name
      last_name
      user {
        created_at
        id        
        memberships(order_by:{id: asc}) {
          id
          organization_id
          role
          user_email {
            email
          }
        }
      }
    }
  }
`

const MembershipForm = ({ formRef }) => {
  const client = useApolloClient()
  const history = useHistory()
  const match = useRouteMatch()
  const currentUser = useCurrentUser()
  const organization = useCurrentOrganization()
  const [makePrimaryMembershipMutation] = useMutation(MakePrimaryMembership, { refetchQueries: ['GetTokenUser', 'GetOrganizationById', 'GetMembershipsByOrganizationId'] })
  const [invitationMutation] = useMutation(InvitationMutation, { refetchQueries: ['GetTokenUser', 'GetOrganizationById', 'GetMembershipsByOrganizationId'] })
  const [emailValue, setEmailValue] = useState({ email: '', userId: null })
  const membershipId = isNaN(match.params.membershipId) ? -1 : parseInt(match.params.membershipId, 10)
  const [isExistingUser, setIsExistingUser] = useState(membershipId > -1)
  const { data: getData, error: getError, loading: getIsLoading } = useQuery(Queries.GetMembershipById, {
    skip: membershipId === -1,
    variables: {
      membershipId: membershipId
    }
  })

  if (getError && getError.message) {
    return <Alert bsStyle='danger'>{getError.message}</Alert>
  } else if (getIsLoading) {
    return <CenteredLoader />
  } else {
    let initialValues = {
      first_name: '',
      last_name: '',
      role: 'staff',
      user_search: ''
    }
    const validateFirstName = value => {
      if (!isExistingUser) {
        return Yup
          .string()
          .trim('Must not include leading or trailing spaces.')
          .strict(true)
          .min(2, 'Must have at least two characters.')
          .max(100, 'Must be 100 characters or less.')
          .required('First name is required.')
          .validate(value, { abortEarly: false })
          .then(_result => null)
          .catch(error => {
            return error.errors
          })
      }
    }
    const validateLastName = value => {
      if (!isExistingUser) {
        return Yup
          .string()
          .trim('Must not include leading or trailing spaces.')
          .strict(true)
          .min(2, 'Must have at least two characters.')
          .max(100, 'Must be 100 characters or less.')
          .required('Last name is required.')
          .validate(value, { abortEarly: false })
          .then(_result => null)
          .catch(error => {
            return error.errors
          })
      }
    }
    const validateRole = value => {
      return Yup
        .string()
        .required('Role selection is required.')
        .validate(value, { abortEarly: false })
        .then(_result => null)
        .catch(error => {
          return error.errors
        })
    }
    const validateUserSearch = value => {
      return Yup
        .string()
        .email('Please enter a valid email address.')
        .required('Email address is required.')
        .validate(value, { abortEarly: false })
        .then(_result => null)
        .catch(error => {
          return error.errors
        })
    }

    if (getData && getData.memberships && getData.memberships.length > 0) {
      const membership = getData.memberships[0]
      const firstName = membership.user.profile.first_name
      const lastName = membership.user.profile.last_name

      initialValues = {
        first_name: (firstName === null) ? '' : firstName,
        last_name: (lastName === null) ? '' : lastName,
        role: membership.role,
        user_search: membership.user_email.email
      }

      // Don't set this if already set because it causes a bunch of re-renders.
      if (emailValue.email !== membership.user_email.email || emailValue.userId !== membership.user.id) {
        setEmailValue({ email: membership.user_email.email.toLowerCase(), userId: membership.user.id })
      }
    } else if (membershipId !== -1) {
      return <Error404 />
    }

    return (
      <div className='membership-form'>
        {/*
        // TODO: FIXME: Pass around the deleteIsLoading from the membershipHeader component to here
        {deleteIsLoading &&
          <CenteredLoader overlay />} */}
        <Formik
          initialValues={{ ...initialValues }}
          innerRef={formRef}
          onSubmit={(values, { setSubmitting }) => {
            if (membershipId === -1) {
              if (!isExistingUser && (values.first_name === '' || values.last_name === '')) {
                toast.error('First and Last name are required.')
                return
              }

              if (typeof values.user_search !== 'string' || values.user_search.length === 0) {
                toast.error('Email address is required.')
                return
              }

              invitationMutation({
                variables: {
                  email: values.user_search.toLowerCase(),
                  first_name: values.first_name,
                  invited_by_id: currentUser.id,
                  last_name: values.last_name,
                  organization_id: organization.id,
                  role: values.role
                }
              }).then((response) => {
                const membershipsCount = response.data.insert_member_profiles_one.user.memberships.length
                const newMembership = response.data.insert_member_profiles_one.user.memberships[0]
                const newMembershipId = newMembership.id
                if (membershipsCount === 1) {
                  // make primary
                  makePrimaryMembershipMutation({ variables: { id: newMembershipId } })
                }
                const newRoute = match.path.split(':membershipId')[0]
                toast.success('Team member added successfully.')
                history.replace(`${newRoute}${newMembershipId}`)
                setSubmitting(false)
              })
                .catch((error) => {
                  setSubmitting(false)
                  debugMessage(error)
                  toast.error('The team member could not be added.')
                })
            } else {
              client.mutate({
                mutation: Mutations.UpdateMembership,
                variables: {
                  membershipId: membershipId,
                  role: values.role
                }
              }).then(
                () => {
                  toast.success('Team member updated successfully.')
                  setSubmitting(false)
                },
                (error) => {
                  debugMessage(error)
                  toast.error('The team member could not be updated.')
                  setSubmitting(false)
                }
              )
            }
          }}
        >
          {(formikProps) => {
            const roleOptions = [
              { label: 'Admin', value: 'admin' },
              { label: 'Staff', value: 'staff' }
            ]

            return (
              <Form
                noValidate
              >
                <div className='formGroup--double'>
                  <Field
                    name='user_search'
                    validate={validateUserSearch}
                  >
                    {({ field, meta }) => (
                      <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                        <label className='control-label' htmlFor='user_search'>Email *</label>
                        <input className='form-control' disabled={isExistingUser || membershipId > -1} id='email' type='text' {...field} />
                        {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                  <Field
                    name='role'
                    validate={validateRole}
                  >
                    {({ field, form, meta }) => (
                      <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                        <label className='control-label' htmlFor='role'>Role *</label>
                        <div className='select-wrap'>
                          <ReactSelect
                            className='roleSelect'
                            classNamePrefix='roleSelect'
                            clearable={false}
                            name='role'
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value)
                            }}
                            options={roleOptions}
                            value={roleOptions.find(option => option.value === field.value)}
                          />
                        </div>
                        {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                </div>
                <div className='formGroup--double'>
                  <Field
                    name='first_name'
                    validate={validateFirstName}
                  >
                    {({ field, meta }) => (
                      <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                        <label className='control-label' htmlFor='first_name'>First Name *</label>
                        <input className='form-control' disabled={isExistingUser || membershipId > -1} id='first_name' type='text' {...field} />
                        {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                  <Field
                    name='last_name'
                    validate={validateLastName}
                  >
                    {({ field, meta }) => (
                      <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                        <label className='control-label' htmlFor='last_name'>Last Name *</label>
                        <input className='form-control' disabled={isExistingUser || membershipId > -1} id='last_name' type='text' {...field} />
                        {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

MembershipForm.propTypes = {
  formRef: PropTypes.object
}

export default MembershipForm
