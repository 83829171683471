import PropTypes from 'prop-types'
import React from 'react'
import Panel from 'react-bootstrap/lib/Panel'
import Table from 'react-bootstrap/lib/Table'
import Pagination from '../../../../components/pagination'
import FeaturesTableRow from './FeaturesTableRow'
import './table.css'

const typeMatch = (types, featureType) => types.indexOf(featureType) > -1

const FeaturesTable = ({
  displayParentArea = true,
  features,
  featureType,
  isSuperAdmin,
  onArchived,
  onDeleteTrail,
  onEdit,
  onPageChange,
  onPublished,
  organizationId,
  paging = {}
}) => {
  const hasStewardships = features.filter(feature => typeof feature.stewardships !== 'undefined').length > 0
  let isAreaView = false

  if (typeMatch(['areas'], featureType)) {
    isAreaView = true
  }

  return (
    <div className='featuresTable'>
      {!features?.length &&
        <Panel className='featuresTable-empty'>
          <Panel.Body>
            {typeMatch(['areas'], featureType) &&
              <h3>No areas available.</h3>}
            {typeMatch(['outings'], featureType) &&
              <h3>No outings available.</h3>}
            {typeMatch(['points-of-interest', 'points_of_interest'], featureType) &&
              <h3>No points of interest available.</h3>}
            {typeMatch(['trails'], featureType) &&
              <h3>No trails available.</h3>}
          </Panel.Body>
        </Panel>}
      {!!features?.length &&
        <div>
          <Panel>
            <Table striped>
              <thead>
                <tr>
                  <th>Name</th>
                  {typeMatch(['points-of-interest', 'points_of_interest', 'trails'], featureType) && !isAreaView && displayParentArea &&
                    <th className='featuresTable--area'>Area</th>}
                  {typeMatch(['points-of-interest', 'points_of_interest'], featureType) &&
                    <th>Type</th>}
                  {typeMatch(['outings', 'trails'], featureType) &&
                    <th>Length</th>}
                  {typeMatch(['areas'], featureType) &&
                    <th>Acres</th>}
                  {hasStewardships && (
                    <th>Stewardships</th>
                  )}
                  <th>Visibility</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {features.map((feature) => {
                  return (
                    <FeaturesTableRow
                      key={feature.id}
                      {...{
                        displayParentArea,
                        feature,
                        featureType,
                        isAreaView,
                        isSuperAdmin,
                        onArchived,
                        onDeleteTrail,
                        onEdit,
                        onPublished,
                        organizationId
                      }}
                    />
                  )
                })}
              </tbody>
            </Table>
          </Panel>
          {paging?.total_pages > 1 &&
            <div className='featuresTable-pagination'>
              <Pagination
                activePage={parseInt(paging.current_page, 10)}
                handleOnSelect={onPageChange}
                numberOfPages={paging.total_pages}
              />
            </div>}
        </div>}
    </div>
  )
}

FeaturesTable.propTypes = {
  displayParentArea: PropTypes.bool,
  featureType: PropTypes.string,
  features: PropTypes.array,
  isSuperAdmin: PropTypes.bool,
  onArchived: PropTypes.func,
  onDeleteTrail: PropTypes.func,
  onEdit: PropTypes.func,
  onPageChange: PropTypes.func,
  onPublished: PropTypes.func,
  organizationId: PropTypes.number,
  paging: PropTypes.object
}

export default FeaturesTable
