import PropTypes from 'prop-types'
import React from 'react'

import Footer from './footer/Footer'
import OrganizationDropdown from './sidebar/OrganizationDropdown'
import SidebarNavigation from './sidebar/SidebarNavigation'
import TopBar from './topbar/TopBar'

import './sidebarlayout.css'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import { useCurrentOrganization } from 'contexts/OrganizationContext'

const SidebarLayout = ({ children, match }) => {
  const currentUser = useCurrentUser()
  const organization = useCurrentOrganization()
  return (
    <div className='sidebarLayout'>
      <div className='sidebarLayout-sidebar'>
        <OrganizationDropdown
          memberships={currentUser.memberships}
          organization={organization}
        />
        <SidebarNavigation
          match={match}
          organization={organization}
        />
      </div>
      <div className='sidebarLayout-contentWrap'>
        <div className='sidebarLayout-topbar'>
          <TopBar />
        </div>
        <div className='sidebarLayout-content'>
          {children}
        </div>
        <div className='sidebarLayout-footer'>
          <Footer />
        </div>
      </div>
    </div>
  )
}

SidebarLayout.propTypes = {
  children: PropTypes.any,
  match: PropTypes.object
}

export default SidebarLayout
