import React from 'react'
import t from 'prop-types'

import DocumentsList from '../../../../views/documents/documentsList/DocumentsList'

const FeatureDocumentsList = (props) => {
  const { match, feature } = props
  return (
    <div className='featureDocumentsList'>
      <DocumentsList feature={feature} match={match} />
    </div>
  )
}

FeatureDocumentsList.propTypes = {
  feature: t.object,
  match: t.object
}

export default FeatureDocumentsList
