import PropTypes from 'prop-types'
import { React, useEffect } from 'react'
import Redactor from './redactor-3-5-2/redactor.js'

import './redactor-3-5-2/redactor.css'
import './editor.css'

const EditorWithHooks = (props) => {
  const { fullFormattingAvailable, id, onChange, value = '' } = props
  const buttons = fullFormattingAvailable ? [
    'format',
    'bold',
    'italic',
    'deleted',
    'lists',
    'link',
    'html'
  ] : ['bold', 'italic', 'deleted', 'lists', 'link', 'html']
  const fullId = `editor-${id}`
  const pasteBlockTags = (() => {
    const blockTags = [
      'li',
      'ol',
      'p',
      'ul'
    ]

    if (fullFormattingAvailable) {
      blockTags.push('h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre')
    }

    return blockTags
  })()
  const pasteInlineTags = (() => {
    const inlineTags = [
      'a'
    ]

    if (fullFormattingAvailable) {
      inlineTags.push('strong', 'em', 'del')
    }

    return inlineTags
  })()

  const get = () => {
    if (window.$R(`#${fullId}`)) {
      let html = window.$R(`#${fullId}`, 'source.getCode')
      html = html.replace(/\r?\n?/g, '') // Remove newlines from the string.
      html = html.trim() // Remove spaces from ends of string.
      return html
    } else {
      return ''
    }
  }
  const init = () => {
    if (window.$R(`#${fullId}`)) {
      window.$R(`#${fullId}`, 'source.setCode', value)
    }
  }

  useEffect(() => {
    Redactor(`#${fullId}`, {
      buttons: buttons,
      callbacks: {
        changed: () => {
          onChange(get())
        },
        started: () => setTimeout(() => init())
      },
      linkNewTab: false,
      linkSize: 10000,
      minHeight: '150px',
      pasteBlockTags: pasteBlockTags,
      pasteImages: false,
      pasteInlineTags: pasteInlineTags,
      removeNewLines: true,
      shortcuts: false
    })
  }, []) // No dependency array to run only on mount

  return (
    <div className='editor-wrap'>
      <textarea id={fullId} />
    </div>
  )
}

EditorWithHooks.propTypes = {
  fullFormattingAvailable: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default EditorWithHooks
