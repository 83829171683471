import * as Sentry from '@sentry/react'
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import debugMessage from 'services/Debug'
import { ManagerVersion } from '../ManagerVersion'

function buildErrorLink (logout) {
  const errorLink = onError(({
    graphQLErrors,
    networkError
  }) => {
    if (graphQLErrors) {
      const firstGraphQLError = graphQLErrors[0]

      if (['jwt-invalid-claims', 'access-denied'].includes(firstGraphQLError.extensions.code)) {
        debugMessage('[GraphQL error]: access-denied')
        logout({ returnTo: `${window.location.origin}/logout?error=access-denied` })
        window.Beacon && window.Beacon('logout')
        Sentry.setUser(null)
      } else {
        const message = `[GraphQL error]: Message: ${firstGraphQLError.message}, Location: ${firstGraphQLError.locations}, Path: ${firstGraphQLError.path}`
        debugMessage(message)
      }
    }

    if (networkError) {
      const message = `[Network error]: ${networkError}`
      debugMessage(message)
    }
  })
  return errorLink
}
export function buildGraphqlClient (accessToken, logout) {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({
      headers
    }) => ({
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : ''
      }
    }))

    return forward(operation)
  })
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: process.env.REACT_APP_STAGE_NAME !== 'production',
    link: from([
      authLink,
      buildErrorLink(logout),
      new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_URL
      })
    ]),
    name: 'OuterSpatial Manager',
    version: ManagerVersion
  })
  return client
}

export default {
  buildGraphqlClient
}
