import React from 'react'
import _ from 'lodash'
import t from 'prop-types'
import Async from 'react-select/async'
import fetch from 'node-fetch'
import './locationSelect.css'

const mapResult = (location = {}) => {
  if (_.isEmpty(location)) { return null }
  return {
    label: location.place_name,
    value: location.place_name,
    location
  }
}

const mapResults = (results) => {
  const mapped = results.features.map(mapResult)
  return _.flatten(mapped)
}

const LocationSelect = (props) => {
  const { value, disabled, clearable, onChange } = props
  const setFieldValue = props.setValue

  const change = (value) => {
    const setValue = _.isArray(value) && !value.length ? null : (value ? { label: value.value, value: value.value } : null)
    if (typeof onChange === 'function') {
      onChange(setValue?.value || '')
    }
    if (typeof setFieldValue === 'function') {
      setFieldValue(setValue?.value || '', false)
    }
  }

  const fetchOptions = (query, callback) => {
    return fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`).then((response) => {
      response.json().then((data) => {
        const queryResults = mapResults(data)

        return callback(queryResults)
      })
    })
  }
  const _fetchOptions = _.debounce(fetchOptions, 500)

  const search = (query, callback) => {
    if (!query.length) {
      return Promise.resolve({ options: [] })
    }

    _fetchOptions(query, callback)
  }

  let location = null

  if (value && _.isString(value) && value.length) {
    if (value.charAt(0) === '{') {
      location = JSON.parse(value)
    } else {
      location = value
    }
  }

  if (_.isString(location)) {
    location = {
      value: value,
      label: value
    }
  }

  return (
    <div className='associationSet'>
      <Async
        autoload={false}
        backspaceRemoves={clearable}
        deleteRemoves={clearable}
        delimiter={null}
        filterOption={v => v}
        ignoreCase={false}
        isClearable={clearable}
        isDisabled={disabled}
        isLoading={false}
        loadOptions={search}
        onChange={change}
        placeholder='Enter address...'
        shouldKeyDownEventCreateNewOption={(keyCode) => [13, 9].indexOf(keyCode) > -1}
        value={location}
      />
    </div>
  )
}

LocationSelect.propTypes = {
  // props
  clearable: t.bool,
  disabled: t.bool,
  onChange: t.func,

  // state
  value: t.any,
  setValue: t.func
}

export default LocationSelect
