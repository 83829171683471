import React from 'react'
import t from 'prop-types'
import Button from 'react-bootstrap/lib/Button'
import './heroItemsActionBar.css'

const ActionBar = ({ fetching, onNewClick }) => {
  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '70', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/70-bulletin-board' }).click()
    }
  }

  return (
    <div className='heroItemsActionBar-wrap'>
      <div className='heroItemsActionBar-actionsRight'>
        {!fetching &&
          <Button bsStyle='primary' className='shadow' onClick={onNewClick}>New Bulletin Board Item</Button>}
        <Button
          bsStyle='primary'
          className='shadow'
          onClick={handleViewHelpClick}
        >
          View Help
        </Button>
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  fetching: t.bool,
  onNewClick: t.func
}

export default ActionBar
