import { gql } from '@apollo/client'

export const Mutations = {
  InsertImageAttachmentAtPosition: gql`
    mutation InsertImageAttachmentAtPosition(
      $featureId: Int!,
      $featureType: String!,
      $imageId: Int!,
      $position: Int
    ) {
      insert_image_attachments_one(object: {
        attacheable_id: $featureId,
        attacheable_type: $featureType,
        image_id: $imageId,
        position: $position
      }) {
        attacheable_id
        id
        position
        image {
          id
          uploaded_file
        }
      }
    }
  `,
  UpdateImageAttachmentPosition: gql`
    mutation UpdateImageAttachmentPosition(
      $attachmentId: Int!,
      $imageId: Int!,
      $position: Int
    ) {
      update_image_attachments_by_pk(
        pk_columns: {id: $attachmentId},
        _set: {
          image_id: $imageId
          position: $position,
        }
      ) {
        attacheable_id
        id
        position
        image_id
      }
    }
  `
}
