import { gql } from '@apollo/client'

export const OrganizationPostsList = gql`
  query OrganizationPostsList($id: Int!, $post_where: posts_bool_exp) {
    organization_all_posts_aggregate(where: {
      organization_id: {
        _eq: $id
      }
    }) {
      aggregate {
        count
      }
    }
    organization_all_posts(
      where: {
        organization_id: {
          _eq: $id
        },
        post: $post_where
      },
      order_by: {
        post: {
          created_at: desc
        }
      }
    ) {
      post {
        area_post {
          area {
            id
            name
          }
          id
        }
        challenge_response: public_challenge_response {
          id
          task {
            challenge {
              id
              name
              visibility
            }
            id
          }
        }
        children(order_by: { created_at: asc }) {
          id
          post_text
          created_at
          user {
            id            
            profile {
              id
              photo_file
              name
            }
          }
        }
        created_at
        feature_id
        feature_type
        id
        image_attachments(order_by:{position:asc}) {
          id
          position
          image {
            id
            uploaded_file
          }
        }
        is_admin
        is_alert
        likers {
          liker_id
          user {
            id                        
            profile{
              id
              photo_file
              name
            }
          }
        }
        location_validation_bypassed
        outing_post {
          id
          outing {
            id
            name
            parent_areas: outing_areas(order_by: { area: { name: asc }}) {
              area {
                id
                name
              }
              id
            }
          }
        }
        parent {
          id
        }
        point_of_interest_post {
          id
          point_of_interest {
            id
            name
            parent_area: area {
              id
              name
            }
            point_type
          }
        }
        post_text
        post_type
        product_update_platform
        product_update_title
        report_data
        review_data
        trail_post {
          id
          trail {
            id
            name
            parent_area: area {
              id
              name
            }
          }
        }
        user {          
          id          
          profile{
            id
            photo_file
            name
          }
        }
      }
    }
  }
`
