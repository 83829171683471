import { gql, useMutation, useQuery } from '@apollo/client'
import { LinkContainer } from 'react-router-bootstrap'
import { useHistory, useRouteMatch } from 'react-router'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { Form, Formik } from 'formik'
import { askOuterSpatialAQuestion } from 'components/helpScout'
import { CenteredLoader } from 'components/centeredLoader'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import AssociationSet from 'components/associationSet'
import PaperMapEmbed from 'components/paperMapEmbed'
import FormikGroup from 'components/fieldGroup/FormikGroup'
import * as Yup from 'yup'
import './paperMap.css'

const backPath = (match) => {
  let backUrl = match.path.split(':')[0]

  if (backUrl.charAt(backUrl.length - 1) === '/') {
    backUrl = backUrl.slice(0, -1)
  }

  return backUrl
}
export const DeleteAttachment = gql`
  mutation RemovePaperMapAttachment($id: Int!) {
    delete_paper_map_attachments_by_pk(id: $id) {
      created_at
      id
    }
  }
`
export const DeletePaperMap = gql`
  mutation DeletePaperMap($id: Int!) {
    delete_paper_map_attachments(where: { paper_map_id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
    delete_paper_maps_by_pk(id: $id) {
      created_at
      id
    }
  }
`
const GetPaperMapQuery = gql`
  query GetPaperMap($id: Int!) {
    paper_maps(where: { id: { _eq: $id } }) {
      description
      id
      name
      status
    }
    area_paper_map_attachments(where: { paper_map_id: { _eq: $id } }) {
      feature_id
      id
      feature: area {
        id
        name
      }
    }
    point_of_interest_paper_map_attachments(where: { paper_map_id: { _eq: $id } }) {
      feature_id
      id
      feature: point_of_interest {
        id
        name
      }
    }
    trail_paper_map_attachments(where: { paper_map_id: { _eq: $id } }) {
      feature_id
      id
      feature: trail {
        id
        name
      }
    }
    outing_paper_map_attachments(where: { paper_map_id: { _eq: $id } }) {
      feature_id
      id
      feature: outing {
        id
        name
      }
    }
  }
`
export const InsertAttachment = gql`
  mutation InsertPaperMapAttachment($featureId: Int!, $featureType: String!, $mapId: Int!) {
    insert_paper_map_attachments_one(
      object: { feature_id: $featureId, feature_type: $featureType, paper_map_id: $mapId }
    ) {
      created_at
      id
    }
  }
`
const UpdatePaperMap = gql`
  mutation UpdatePaperMap($id: Int!, $paperMap: paper_maps_set_input!) {
    update_paper_maps_by_pk(pk_columns: { id: $id }, _set: $paperMap) {
      id
    }
  }
`

const PaperMap = (props) => {
  const [updatePaperMap] = useMutation(UpdatePaperMap, { refetchQueries: ['GetPaperMap'] })

  let associations = []
  const history = useHistory()
  const match = useRouteMatch()
  const backUrl = backPath(match)
  const paperMapId = match.params.mapId
  const { data, loading } = useQuery(GetPaperMapQuery, {
    variables: {
      id: paperMapId
    }
  })
  const map = data ? { ...data.paper_maps[0] } : {}
  const [insertAttachment] = useMutation(InsertAttachment, { refetchQueries: [GetPaperMapQuery] })
  const [deleteAttachment] = useMutation(DeleteAttachment, { refetchQueries: [GetPaperMapQuery] })
  const [deletePaperMap] = useMutation(DeletePaperMap, { refetchQueries: ['MapsListQuery'] })
  const isSuperAdmin = (user) => {
    return user.roles.find((item) => {
      return item.role.name === 'admin'
    })
  }
  const user = useCurrentUser()

  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      description: map?.description || '',
      name: map?.name || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().max(500, 'Must be 400 characters or less').required(),
      description: Yup.string().max(500, 'Must be 400 characters or less').nullable()
    }),
    onSubmit: (values) => {
      handleSave(values)
    }
  }
  const handleSave = (values) => {
    updatePaperMap({
      variables: { paperMap: { ...values }, id: map.id }
    }).then(() => {
      toast.success('Updated paper map successfully.')
    })
  }

  useEffect(() => {
    document.title = `${map.name ? map.name + ' | ' : ''}Paper Maps | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  const addAssociation = (association) => {
    insertAttachment({
      variables: {
        featureId: association.attacheable_id,
        featureType: association.attacheable_type,
        mapId: paperMapId
      }
    }).then(() => toast.success('Related location added.'))
  }

  const handleDeleteMap = () => {
    if (isSuperAdmin(user)) {
      const conf = window.confirm(
        'Are you sure you want to delete this paper map? Once deleted, you cannot get it back.'
      )

      if (conf) {
        deletePaperMap({
          variables: {
            id: map.id
          }
        }).then(() => {
          history.push(backPath(match))
        })
      }
    } else {
      askOuterSpatialAQuestion(
        'Delete a Paper Map',
        `I would like to delete the ${map.name} paper map with an id of ${map.id}.`,
        user
      )
    }
  }

  const removeAssociation = (association) => {
    deleteAttachment({
      variables: {
        id: association.id
      }
    }).then(() => toast.success('Related location removed.'))
  }

  if (data) {
    associations = [
      data.area_paper_map_attachments.map((item) => {
        return { ...item, feature_type: 'Area' }
      }),
      data.point_of_interest_paper_map_attachments.map((item) => {
        return { ...item, feature_type: 'PointOfInterest' }
      }),
      data.trail_paper_map_attachments.map((item) => {
        return { ...item, feature_type: 'Trail' }
      }),
      data.outing_paper_map_attachments.map((item) => {
        return { ...item, feature_type: 'Outing' }
      })
    ]
      .flat()
      .filter(
        (att) =>
          att.feature_type !== 'Campground' && att.feature_type !== 'Organization' && att.feature_type !== 'Trailhead'
      )
      .map((att) => {
        const name = att.feature && att.feature.name ? att.feature.name : `${att.feature_type} ${att.feature_id}`

        return {
          ...att,
          contentId: att.feature_id,
          contentName: name
        }
      })
  }

  if (loading) {
    return <CenteredLoader />
  }

  return (
    <div className='paperMap'>
      {loading && <CenteredLoader overlay />}
      <div className='paperMap-sidebar'>
        <div className='paperMap-sidebarActions'>
          <LinkContainer to={backUrl}>
            <Button bsSize='small' bsStyle='link'>
              <Glyphicon glyph='chevron-left' />
              Back to Maps
            </Button>
          </LinkContainer>
        </div>
        <div className='paperMap-title'>
          <h1>{map.name}</h1>
          {typeof map.description === 'string' && map.description.length > 0 && <p>{map.description}</p>}
          <div className='paperMap-attributes'>
            <section>
              <label>Original File</label>
              <a download href={map.status.pdfPath}>
                Download
              </a>
            </section>
            <section>
              <label>Zoom Levels</label>
              <p>
                {map.status.min_zoom} - {map.status.max_zoom}
              </p>
            </section>
          </div>
          <Formik {...formikProps}>
            {(formik) => (
              <div>
                <Form>
                  <FormikGroup id='name' name='name' type='text' label='Name *' />
                  <FormikGroup
                    id='description'
                    label='Description'
                    name='description'
                    model='documentForm.description'
                    type='text'
                  />
                </Form>
                <Button
                  bsStyle='primary'
                  disabled={!formik.dirty || !formik.isValid}
                  onClick={formik.handleSubmit}
                >
                  Save
                </Button>
              </div>
            )}
          </Formik>
          <div className='paperMap-relatedLocations'>
            <section>
              <h4>Related</h4>
              <AssociationSet
                associations={associations}
                entityTypes={['Features']}
                isDisabled={loading}
                isMultipleAssociationSet
                onAdd={addAssociation}
                onRemove={removeAssociation}
                restrictToCurrentOrganization
              />
            </section>
          </div>
        </div>
        <div className='paperMap-actions'>
          <Button bsStyle='primary' className='btn-danger' onClick={() => handleDeleteMap()}>
            {isSuperAdmin(user) && (
              <>
                {' '}
                <Glyphicon glyph='star' /> Delete
              </>
            )}
            {!isSuperAdmin(user) && 'Request Delete'}
          </Button>
          {isSuperAdmin(user) && (
            <>
              <hr />
              <h4>
                <Glyphicon glyph='star' />
                {' '}
                Status
              </h4>
              <pre>{JSON.stringify(map.status, null, 2)}</pre>
            </>
          )}
        </div>
      </div>
      <div className='paperMap-content'>
        <PaperMapEmbed paperMap={map} />
      </div>
    </div>
  )
}

export default PaperMap
