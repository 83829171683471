import { Box, Button, Modal, Slider, Typography } from '@mui/material'
import { gql, useMutation } from '@apollo/client'
import AvatarEditor from 'react-avatar-editor'
import fetch from 'node-fetch'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { CenteredLoader } from 'components/centeredLoader'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import { useCurrentOrganization } from 'contexts/OrganizationContext'

import './ImageCropperModal.scss'

const UPDATE_AND_ATTACH_IMAGE_MUTATION = gql`
  mutation UpdateAndAttachImage($imageId: Int!, $organizationId: Int!) { 
    update_images_by_pk(
      pk_columns: {
        id: $imageId
      },
      _set: {
        uploaded: true
      }
    ) {
      id
      uploaded_file
    }
    insert_image_attachments(objects: [
      {
        attacheable_id: $organizationId,
        attacheable_type: "Organization",
        image_id: $imageId
      }
    ]) {
      affected_rows
      returning {
        image_id
      }
    }
  }
`

// Modal
const ImageCropperModal = ({ modalOpen, onSuccess, setModalOpen, src }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [slideValue, setSlideValue] = useState(10)
  const [updateAndAttachImage, { loading: isUpdatingAndAttaching }] = useMutation(UPDATE_AND_ATTACH_IMAGE_MUTATION)
  const cropRef = useRef(null)
  const organization = useCurrentOrganization()
  const user = useCurrentUser()

  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL()
      const result = await window.fetch(dataUrl)
      const blob = await result.blob()

      uploadImage(blob)
    }
  }

  const uploadImage = async (image) => {
    setIsUploading(true)

    const body = {
      filename: 'image.png',
      userId: user.id
    }
    const presignedResult = await fetch(`${process.env.REACT_APP_API_SERVICE_URL}/uploads/image-presigned-url`, {
      body: JSON.stringify(body),
      method: 'POST'
    })
    const presignedBody = await presignedResult.json()
    const uploadUrl = presignedBody.url
    const FormData = require('form-data')
    const formData = new FormData()

    Object.keys(presignedBody.fields).forEach(key => {
      formData.append(key, presignedBody.fields[key])
    })
    formData.append('file', image)

    const uploadResponse = await fetch(uploadUrl, { body: formData, method: 'POST' })

    if (uploadResponse.status === 204) {
      await updateAndAttachImage({
        variables: {
          imageId: presignedBody.id,
          organizationId: organization.id
        }
      })
        .then((response) => {
          setIsUploading(false)

          if (response && response.data && response.data.insert_image_attachments.affected_rows === 1 && response.data.update_images_by_pk?.uploaded_file) {
            setModalOpen(false)
            onSuccess(response.data.update_images_by_pk)
          } else {
            toast.error('The image could not be updated and attached. Please try again.')
          }
        })
        .catch(e => {
          setIsUploading(false)
          return null
        })
    } else {
      setIsUploading(false)
      return null
    }
  }

  return (
    <Modal
      aria-labelledby='modal-image-cropper-title'
      aria-describedby='modal-image-cropper-description" '
      className='modal-image-cropper'
      open={modalOpen}
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexFlow: 'column',
            height: '300px',
            justifyContent: 'center',
            width: '300px'
          }}
        >
          {(isUploading || isUpdatingAndAttaching) && (
            <CenteredLoader overlay />
          )}
          <Typography id='modal-image-cropper-title' variant='h6' component='h2'>
            Crop the Image
          </Typography>
          <Typography id='modal-image-cropper-description' sx={{ mt: 2 }}>
            Center the image in the frame and crop as much as possible off the edges.
          </Typography>
          <AvatarEditor
            border={20}
            color={[0, 0, 0, 0.72]}
            image={src}
            ref={cropRef}
            rotate={0}
            scale={slideValue / 10}
            style={{ height: '100%', width: '100%' }}
          />
          <Slider
            defaultValue={slideValue}
            max={50}
            min={10}
            onChange={(e) => setSlideValue(e.target.value)}
            size='medium'
            sx={{
              color: 'cyan',
              margin: '0 auto',
              width: '80%'
            }}
            value={slideValue}
          />
          <Box
            sx={{
              border: '3px solid white',
              display: 'flex',
              padding: '10px'
            }}
          >
            <Button
              onClick={(e) => setModalOpen(false)}
              sx={{ marginRight: '10px', color: 'white', borderColor: 'white' }}
              variant='outlined'
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              sx={{ background: '#3BAFDA' }}
              variant='contained'
            >
              Save
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  )
}

ImageCropperModal.propTypes = {
  modalOpen: PropTypes.bool,
  onSuccess: PropTypes.func,
  setModalOpen: PropTypes.func,
  src: PropTypes.object
}

export default ImageCropperModal
