import { gql } from '@apollo/client'

export default {
  GetOrganizationDomains: gql`
    query GetOrganizationDomains {
      organizations(
        order_by: {
          name: asc
        }
      ) {
        id
        email_domain
        name
        logo_image {
          id
          uploaded_file
        }
      }
    }
  `,
  GetOrganizationById: gql`
    query GetOrganizationById($orgId: Int!) {
      organizations(where: {id: {_eq: $orgId}}) {
        address
        areas_count: area_stewardships_aggregate {
          aggregate {
            count
          }
        }
        customer {
          id
          name
          chargebee_id
        }
        communities: community_memberships(order_by: {community: {name: asc}}) {
          community {
            id
            is_sponsored
            name
            slug
            visibility
          }
        }
        description
        email
        extent {
          geometry
        }
        id
        license_tier
        logo_image_id
        logo_image {
          id
          uploaded_file
        }
        membership_limit
        memberships {
          id
          primary
          role
          user {            
            id
            profile {
              first_name
              id
              last_name
              name
            }
          }
          user_email {
            email
          }
          user_id
        }
        name
        parent_organization {
          id
          name
          slug
        }
        points_of_interest_count: point_of_interest_stewardships_aggregate {
          aggregate {
            count
          }
        }
        phone
        slug
        tags {
          key
          value
          tag_descriptor {
            category {
              name
            }
          }
        }
        time_zone
        trails_count: trail_stewardships_aggregate {
          aggregate {
            count
          }
        }
        website
        web_link_attachments {
          web_link {
            id
            name
            platform
            url
          }
        }
      }
    }
  `,
  GetTokenUser: gql`
    query GetTokenUser {
      token_user (limit: 1) {
        email
        user {          
          id
          invitation_accepted_at
          memberships(order_by: {organization: {name: asc}}) {
            id
            organization {
              id
              license_tier
              membership_limit
              name
              organization_type
            }
            primary
            role
          }
          membership_requests(
            order_by: {
              created_at: asc
            }
          ) {
            id
            organization_id
            status
            updated_at
          }
          product_engagements(
            where: {
              product: {
                _eq: "OuterSpatial Manager"
              }
            }
          ) {
            feature
            id
            product
            properties
          }
          profile {
            first_name
            id
            last_name
            location
            name
            photo_file
            user_id
          }
          roles {
            role {
              name
            }
          }
        }
      }
    }
  `
}
