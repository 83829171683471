import { gql, useApolloClient, useMutation } from '@apollo/client'
import { Form } from 'formik'
import _ from 'lodash'
import t from 'prop-types'
import React, { useState } from 'react'
import AssociationSet from '../../../components/associationSet'
import { CenteredLoader } from '../../../components/centeredLoader'
import FormikGroup from '../../../components/fieldGroup/FormikGroup'
import { buildImageUploadUrl } from 'services/Images'
import './mediaForm.css'

const InsertImageAttachmentQuery = gql`
  mutation InsertImageAttachment($attacheable_type: String, $image_id: Int, $attacheable_id: Int, $position: Int) {
    insert_image_attachments_one(object: {attacheable_id: $attacheable_id, attacheable_type: $attacheable_type, image_id: $image_id, position: $position}) {
      id
      created_at
      attacheable_id
      attacheable_type
      image_id
      position
    }
  }
`

const DeleteImageAttachmentQuery = gql`
  mutation DeleteImageAttachmentQuery($id: Int!) {
    delete_image_attachments_by_pk(id: $id) {
      id
    }
  }
`

const MediaForm = ({ model, allowAssociations = true, updating, onAssociationAdded, onAssociationRemoved }) => {
  const [insertImageAttachmentMutation] = useMutation(InsertImageAttachmentQuery)
  const [deletetImageAttachmentMutation] = useMutation(DeleteImageAttachmentQuery)

  let initialAssociations = []
  if (model.image_attachments) {
    initialAssociations = [].concat(model.area_image_attachments.map(att => {
      return {
        ...att,
        contentName: att.area.name,
        contentId: att.area.id
      }
    })).concat(model.trail_image_attachments.map(att => {
      return {
        ...att,
        contentName: att.trail.name,
        contentId: att.trail.id
      }
    })).concat(model.point_of_interest_image_attachments.map(att => {
      return {
        ...att,
        contentName: att.point_of_interest.name,
        contentId: att.point_of_interest.id
      }
    }))
  }
  const [associations, setAssociations] = useState(initialAssociations)
  const client = useApolloClient()
  const removeAssociation = (association) => {
    deletetImageAttachmentMutation({
      variables: {
        id: association.id
      }
    }).then((data) => {
      client.refetchQueries({
        include:
        ['OrganizationImagesQuery']
      })
      setAssociations(associations.filter((item) => item.id !== association.id))
      if (onAssociationRemoved) {
        onAssociationRemoved(data)
      }
    })
  }

  const addAssociation = (association) => {
    insertImageAttachmentMutation({
      variables: {
        attacheable_id: association.attacheable_id,
        attacheable_type: association.attacheable_type,
        image_id: model.id,
        position: associations.length + 1
      }
    }).then((data) => {
      client.refetchQueries({
        include:
        ['OrganizationImagesQuery']
      })
      setAssociations([...associations, { ...association, id: data.data.insert_image_attachments_one.id, contentId: data.data.insert_image_attachments_one.id, contentName: association.feature.name }])
      if (onAssociationAdded) {
        onAssociationAdded(data)
      }
    })
  }

  if (_.isEmpty(model)) {
    return (<h1>Loading</h1>)
  }

  let url = null

  if (model?.id) {
    if (model.uploaded_file) {
      url = buildImageUploadUrl(model.id, model.uploaded_file, 'medium')
    }
  }

  if (!url) {
    return
  }

  return (
    <div className='mediaForm'>
      {updating &&
        <CenteredLoader overlay />}
      <Form>
        <div className='mediaForm-content'>
          <div className='mediaForm-preview'>
            <img src={url} alt='' />
          </div>
          <FormikGroup
            id='caption'
            name='caption'
            model='.caption'
            type='text'
            label='Caption'
            help='Improve accessibility by adding a descriptive caption. Not required, but HIGHLY recommended.'
          />
          <FormikGroup
            id='attribution_text'
            name='attribution_text'
            type='text'
            label='Attribution'
            help='A description of who took this photo.'
          />
          <FormikGroup
            id='attribution_url'
            name='attribution_url'
            type='text'
            label='Attribution Web Page'
            help="A link to a web page that contains more information about the photo's attribution."
          />
        </div>
      </Form>
      {allowAssociations &&
        <div className='mediaForm-sidebar'>
          <div className='mediaForm-associations'>
            <h3>Related</h3>
            <AssociationSet
              associations={associations}
              entityTypes={['Features']}
              isMultipleAssociationSet
              onRemove={removeAssociation}
              onAdd={addAssociation}
              restrictToCurrentOrganization
            />
          </div>
        </div>}

    </div>
  )
}

MediaForm.propTypes = {
  // props
  allowAssociations: t.bool,
  model: t.object,
  updating: t.bool,
  onAssociationAdded: t.func,
  onAssociationRemoved: t.func
  // dispatch
}

export default MediaForm
