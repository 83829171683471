import { useHistory, useRouteMatch } from 'react-router'
import { useMutation } from '@apollo/client'
import Button from 'react-bootstrap/lib/Button'
import Col from 'react-bootstrap/lib/Col'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import Grid from 'react-bootstrap/lib/Grid'
import isBefore from 'date-fns/isBefore'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import Panel from 'react-bootstrap/lib/Panel'
import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/lib/Row'

import { askOuterSpatialAQuestion } from 'components/helpScout'
import { CenteredLoader } from 'components/centeredLoader'
import { Mutations } from './DashboardOperations'
import { PageContent, PageHeader, PageLayout } from 'components/pageLayout'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import AnalyticsCommunityUniqueVisitors from './AnalyticsCommunityUniqueVisitors'
import AnalyticsCommunityScreenPageOpens from './AnalyticsCommunityScreenPageOpens'
import BlogUpdates from './BlogUpdates'
import ToDo from './ToDo'

import './dashboard.css'

const Dashboard = () => {
  useEffect(() => {
    document.title = `Dashboard | ${process.env.REACT_APP_PAGE_TITLE}`
  })
  const currentUser = useCurrentUser()
  const history = useHistory()
  const linkStyle = {
    color: '#41A7D0',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const isOrganizationAdmin = organization.memberships.find(
    (membership) => membership.user_email.email === currentUser.email && membership.role === 'admin'
  )
  const isSuperAdmin = currentUser.roles
    ? currentUser.roles.filter((role) => role.role.name === 'admin').length > 0
    : false

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setJoyrideState({
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
      })
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyrideState({
        run: false,
        stepIndex: 0
      })
    }
  }
  const handlePublishClick = () => {
    if (organization && organization.license_tier === 'free') {
      history.push(`${match.path}/organization/billing`)
    } else {
      askOuterSpatialAQuestion('Publish Organization Into a Community', null, currentUser)
    }
  }
  const handleSignUpClick = () => {
    history.push(`${match.path}/organization/billing`)
  }
  const handleStartWalkthroughClick = () => {
    setJoyrideState({
      run: true,
      stepIndex: 0
    })
  }

  // TODO: We need organization here, because we need to be able to grab "onboarding_message" from the organization object.

  // Analytics:
  //   - Organization Unique Visitors
  //   - Organization Impressions
  //   - Community Engagements
  //   - Organization Engagements
  //   - Organization Top Screens/Pages
  //   - Community Android vs. iOS vs. Web
  //   - Organization Android vs. iOS vs. Web
  //   - Organization Campaigns
  //   - Organization Challenges

  // TODO: Also add "How to get more usage." section.
  // TODO: Also summarize high-level stats.

  // If a user accepts an invite or has a membership request approved after this date, they'll see the product tour.
  const startProductTourDate = new Date('2022-07-29T00:00:00.000Z')
  const hasOnboarded = (() => {
    if (isSuperAdmin) {
      return true
    } else if (
      currentUser.product_engagements.find((productEngagement) => productEngagement.feature === 'onboarding')
    ) {
      return true
    } else {
      // If the user was invited before startProductTourDate, don't show the product tour automatically.
      if (
        currentUser.invitation_accepted_at &&
        isBefore(new Date(currentUser.invitation_accepted_at), startProductTourDate)
      ) {
        return true
      } else {
        const membershipRequest = currentUser.membership_requests.find(
          (membershipRequest) => membershipRequest.status === 'Approved'
        )

        if (membershipRequest && isBefore(new Date(membershipRequest.updatedAt), startProductTourDate)) {
          return true
        }
      }
    }

    return false
  })()
  const [insertOnboardingProductEngagement] = useMutation(
    Mutations.InsertOnboardingProductEngagement,
    {
      onCompleted (data) {
        // console.info(data)
      },
      onError (insertError) {
        // console.error(insertError)
      }
    },
    {
      refetchQueries: ['GetTokenUser']
    }
  )
  const [joyrideState, setJoyrideState] = useState({
    run: false,
    stepIndex: 0
  })
  const steps = [
    {
      content: (
        <>
          <p>This tour will walk you through the Manager interface.</p>
          <p>
            Use the buttons below to go to the next step in the tour or skip the tour completely if you're ready to dive
            in on your own.
          </p>
        </>
      ),
      placement: 'center',
      target: 'body',
      title: 'Welcome to OuterSpatial Manager!'
    },
    {
      content: (
        <>
          <p>First, here's a short video to give you an overview of what Manager is.</p>
          <iframe
            allow='autoplay; fullscreen'
            allowFullScreen
            src='https://player.vimeo.com/video/494267830'
            title='OuterSpatial Manager Introduction video'
          />
        </>
      ),
      placement: 'center',
      target: 'body',
      title: 'Introductory Video'
    },
    {
      content: <p>Next we'll walk through the main sections of the Manager interface.</p>,
      placement: 'center',
      target: 'body',
      title: 'Interface'
    },
    // TODO: Only show if multiple memberships.
    {
      content: <p>If you are part of multiple organizations, you can click here to switch between them.</p>,
      target: '.sidebarLayout-organizationDropdown',
      title: 'Switching Organizations'
    },
    {
      content: <p>You can view and update your OuterSpatial profile by clicking here.</p>,
      target: '.sidebarLayout-topbar .dropdown',
      title: 'Profile'
    },
    {
      content: <p>Use the sidebar to navigate between different pages in Manager.</p>,
      placement: 'auto',
      target: '.sidebarNavigation__links',
      title: 'Sidebar'
    },
    {
      content: <p>If you need help, click here to view the knowledge base and/or contact our customer success team.</p>,
      placement: 'top',
      target: '.BeaconFabButtonFrame',
      title: 'Getting Help'
    },
    {
      content: (
        <p>Now, we'll do a quick overview of the different pages that are available in the navigation sidebar.</p>
      ),
      placement: 'center',
      target: 'body',
      title: 'Pages'
    },
    {
      content: (
        <p>
          The dashboard is the first thing you'll see when loading Manager. This page gives you a condensed, real-time
          overview of what's going on with your organization in OuterSpatial. Check back often to stay on top of all the
          activity!
        </p>
      ),
      placement: 'auto',
      target: '.dashboard',
      title: 'Dashboard'
    },
    // Admin/staff branch
    (() => {
      if (isOrganizationAdmin || isSuperAdmin) {
        return {
          content: (
            <p>
              Use the organization page to view and edit your organization's contact information and social media
              accounts. You can also use this page to manage your team, create and manage deep links and QR codes for
              your marketing campaigns, and download detailed analytics reports to see how visitors are interacting with
              your community(-ies) and organization in OuterSpatial.
            </p>
          ),
          placement: 'auto',
          target: '.organization',
          title: 'Organization'
        }
      }

      return {
        content: (
          <p>
            Use the organization page to view your organization's contact information, social media accounts, and team.
            You can also use this page to create and manage deep links and QR codes for your marketing campaigns and
            download detailed analytics reports to see how visitors are interacting with your community(-ies) and
            organization in OuterSpatial.
          </p>
        ),
        placement: 'auto',
        target: '.organization',
        title: 'Organization'
      }
    })(),
    {
      content: (
        <p>
          Add bulletin board items to elevate important information for your visitors, like special events, safety
          alerts, and donation/volunteer opportunities.
        </p>
      ),
      placement: 'auto',
      target: '.bulletin-board',
      title: 'Bulletin Board'
    },
    {
      content: (
        <p>
          If your organization is running a challenge, you can use this page to download the challenge participation and
          response reports.
        </p>
      ),
      placement: 'auto',
      target: '.challenges',
      title: 'Challenges'
    },
    {
      content: <p>Use the social page to create posts and interact with posts created by your visitors.</p>,
      placement: 'auto',
      target: '.social',
      title: 'Social'
    },
    {
      content: (
        <>
          <p>
            Use the different pages under the "Locations" section to view and update information for your organization's
            areas, points of interest, trails, and outings.
          </p>
          <p>
            Note that the areas, points of interest, and trails pages will only show up if your organization has
            imported geospatial (GIS) data into OuterSpatial.
          </p>
        </>
      ),
      placement: 'auto',
      target: '.locations',
      title: 'Locations'
    },
    {
      content: <p>Use this page to create and manage articles to highlight topics of interest for your visitors.</p>,
      placement: 'auto',
      target: '.content_articles',
      title: 'Articles'
    },
    {
      content: <p>Use this page to create and manage events in your organization's/community's event calendar.</p>,
      placement: 'auto',
      target: '.content_events',
      title: 'Events'
    },
    {
      content: (
        <p>
          Now that you're familiar with the basic structure and functionality, you can get started at your own pace.
          Take a look at the cards on the Manager dashboard for recommendations on where to start.
        </p>
      ),
      placement: 'center',
      target: 'body',
      title: 'Jump on in!'
    }
  ]

  useEffect(() => {
    window.Chargebee.registerAgain()
    if (!hasOnboarded) {
      insertOnboardingProductEngagement().then(() => {
        setJoyrideState({
          run: true,
          stepIndex: 0
        })
      })
    }
  }, []) // No dependency array to run only on mount

  if (!currentUser || !organization) {
    return <CenteredLoader />
  }

  return (
    <div className='dashboard'>
      <>
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          run={joyrideState.run}
          showSkipButton
          spotlightPadding={0}
          stepIndex={joyrideState.stepIndex}
          steps={steps}
          styles={{
            options: {
              backgroundColor: '#fff',
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: '#3bafda',
              textColor: '#3e3e3f',
              zIndex: 999999
            }
          }}
        />
        <PageLayout>
          <PageHeader>
            <div className='dashboard-header'>
              <div className='dashboard-header__title'>
                <div className='dashboard-header__titleText'>
                  <h1>Dashboard</h1>
                  <p />
                </div>
              </div>
            </div>
          </PageHeader>
          <PageContent>
            <div className='dashboardActionBar-wrap'>
              <div className='dashboardActionBar-actions'>
                <Button
                  bsStyle='primary'
                  className='shadow'
                  disabled={joyrideState.run}
                  onClick={handleStartWalkthroughClick}
                >
                  <Glyphicon glyph='play' />
                  &nbsp; Start Tour
                </Button>
              </div>
            </div>
            <div>
              <Grid fluid>
                {organization.license_tier === 'free' && organization.communities.length === 0 && (
                  <Row>
                    <Col md={12}>
                      <div>
                        <Panel>
                          <Panel.Heading>
                            <Panel.Title>
                              Publish Your Organization
                              <p>
                                Visitors cannot currently access your information because your organization is not yet
                                available in any OuterSpatial communities. Publish your organization into a
                                community now by signing up for an OuterSpatial license.
                              </p>
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body>
                            <Button bsStyle='primary' onClick={handleSignUpClick}>
                              Sign Up
                            </Button>
                          </Panel.Body>
                        </Panel>
                      </div>
                    </Col>
                  </Row>
                )}
                {organization.license_tier === 'free' && organization.communities.length > 0 && (
                  <Row>
                    <Col md={12}>
                      <div>
                        <Panel>
                          <Panel.Heading>
                            <Panel.Title>
                              Sign Up for a License
                              <p>
                                Your organization is on the "Free" plan, so you only have access to a subset of
                                OuterSpatial Manager's tools. Sign up for a license now to gain access to all that
                                OuterSpatial has to offer.
                              </p>
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body>
                            <Button bsStyle='primary' onClick={handlePublishClick}>
                              Sign Up Now
                            </Button>
                          </Panel.Body>
                        </Panel>
                      </div>
                    </Col>
                  </Row>
                )}
                {organization.license_tier !== 'free' && organization.communities.length === 0 && (
                  <Row>
                    <Col md={12}>
                      <div>
                        <Panel>
                          <Panel.Heading>
                            <Panel.Title>
                              Publish Your Organization
                              <p>
                                Your organization is not yet available in any OuterSpatial communities, so visitors
                                cannot access your information. Reach out to customer success now to publish your
                                organization into a community.
                              </p>
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body>
                            <Button bsStyle='primary' onClick={handlePublishClick}>
                              Contact Customer Success
                            </Button>
                          </Panel.Body>
                        </Panel>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={6}>
                    {organization && organization.license_tier === 'free' && (
                      <Panel>
                        <Panel.Heading>Community Analytics</Panel.Heading>
                        <Panel.Body>
                          <p>
                            Visitor analytics are not available because your organization is on the "Free" plan.
                            {(isOrganizationAdmin || isSuperAdmin) && (
                              <>
                                &nbsp;
                                <span onClick={handleSignUpClick} style={linkStyle}>
                                  Sign up for a license
                                </span>{' '}
                                to access community analytics now.
                              </>
                            )}
                          </p>
                        </Panel.Body>
                      </Panel>
                    )}
                    {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length === 0 && (
                      <Panel>
                        <Panel.Heading>Community Analytics</Panel.Heading>
                        <Panel.Body>
                          <p>
                            Visitor analytics are not available because your organization has not been added to a
                            community.{' '}
                            <span onClick={handleSignUpClick} style={linkStyle}>
                              Reach out to customer success
                            </span>{' '}
                            to publish your organization into its first community.
                          </p>
                        </Panel.Body>
                      </Panel>
                    )}
                    {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length > 0 && (
                      <AnalyticsCommunityUniqueVisitors organization={organization} />
                    )}
                  </Col>
                  <Col md={6}>
                    {organization && organization.license_tier === 'free' && (
                      <Panel>
                        <Panel.Heading>Organization Analytics</Panel.Heading>
                        <Panel.Body>
                          <p>
                            Visitor analytics are not available because your organization is on the "Free" plan.
                            {(isOrganizationAdmin || isSuperAdmin) && (
                              <>
                                &nbsp;
                                <span onClick={handleSignUpClick} style={linkStyle}>
                                  Sign up for a license
                                </span>{' '}
                                to access community analytics now.
                              </>
                            )}
                          </p>
                        </Panel.Body>
                      </Panel>
                    )}
                    {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length === 0 && (
                      <Panel>
                        <Panel.Heading>Organization Analytics</Panel.Heading>
                        <Panel.Body>
                          <p>
                            Visitor analytics are not available because your organization has not been added to a
                            community.&nbsp;
                            <span onClick={handleSignUpClick} style={linkStyle}>
                              Reach out to customer success
                            </span>{' '}
                            to publish your organization into its first community.
                          </p>
                        </Panel.Body>
                      </Panel>
                    )}
                    {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length > 0 && (
                      <AnalyticsCommunityScreenPageOpens organization={organization} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <ToDo
                      currentUser={currentUser}
                      handleStartWalkthroughClick={handleStartWalkthroughClick}
                      history={history}
                      match={match}
                      organization={organization}
                    />
                  </Col>
                  <Col md={4}>
                    <BlogUpdates />
                  </Col>
                </Row>
              </Grid>
            </div>
          </PageContent>
        </PageLayout>
      </>
    </div>
  )
}

export default Dashboard
