import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import PropTypes from 'prop-types'

import EntitySelect from '../../../../components/associationSet/EntitySelect'
import { featureByType, featureByClass } from '../../../../models/feature/feature.lang'
import './actionBar.css'

const ActionBar = (props) => {
  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '78', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/78-locations' }).click()
    }
  }

  const handleSelectSearchResult = (value) => {
    props.onSelectSearchResult({
      id: value.feature.id,
      type: featureByClass(value.feature.class_name).tablePlural
    })
  }

  const { featureType, fetching, showSearch = true } = props
  const featureLang = featureByType(featureType)

  return (
    <div className='featuresActionBar-wrap'>
      <div className='featuresActionBar-actions'>
        {showSearch && !fetching &&
          <EntitySelect
            entityTypes={[featureLang.classSingular]}
            onAdd={handleSelectSearchResult}
            placeholder={`Search ${featureLang.plural}...`}
            restrictToCurrentOrganization
          />}
          &nbsp;
        <Button
          bsStyle='primary'
          className='shadow'
          onClick={handleViewHelpClick}
        >
          View Help
        </Button>
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  featureType: PropTypes.string,
  fetching: PropTypes.bool,
  onSelectSearchResult: PropTypes.func,
  showSearch: PropTypes.bool
}

export default ActionBar
