import React from 'react'
import t from 'prop-types'

import MapsList from 'views/maps/mapsList/MapsList'

const FeatureMapsList = (props) => {
  const { match, feature } = props

  return (
    <div className='featureMapsList'>
      <MapsList feature={feature} match={match} />
    </div>
  )
}

FeatureMapsList.propTypes = {
  feature: t.object,
  match: t.object
}

export default FeatureMapsList
