import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { Field, Form, Formik } from 'formik'
import fetch from 'node-fetch'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/lib/Alert'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import Panel from 'react-bootstrap/lib/Panel'
import { useHistory, useRouteMatch } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import ReactSelect from 'react-select'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import EntitySelect from '../../../../components/associationSet/EntitySelect'
import { CenteredLoader } from '../../../../components/centeredLoader'
import { PageContent, PageHeader, PageLayout } from '../../../../components/pageLayout'
import Error404 from '../../../../views/errors/404'
import { Fragments, Mutations, Queries } from '../CampaignsOperations'
import './campaign.css'

const CampaignHeader = ({ onSaveClick }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const backPath = match.path.split(':')[0].slice(0, match.path.split(':')[0].length - 1)
  const campaignId = match.params.campaignId
  const [deleteCampaign] = useMutation(Mutations.DeleteCampaignById, {
    onCompleted: () => {
      history.push(backPath)
    },
    onError: () => {
      toast.error('The campaign could not be deleted.')
    },
    update: (cache, response) => {
      cache.modify({
        fields: {
          campaigns (existingCampaigns = []) {
            return existingCampaigns.filter((t) => {
              return t.__ref !== `campaigns:${response.data.delete_campaigns.returning[0].id}`
            })
          }
        }
      })
    }
  })

  const handleDeleteClick = () => {
    const conf = window.confirm('Are you sure you want to permanently delete this campaign?')

    if (campaignId !== -1 && conf) {
      deleteCampaign({
        variables: {
          id: campaignId
        }
      })
    }
  }

  return (
    <div className='campaign-header'>
      <div className='campaign-header__title'>
        <div className='campaign-header__titleText'>
          <LinkContainer to={backPath}>
            <Button bsStyle='link'>
              <Glyphicon glyph='chevron-left' />Back to Campaigns
            </Button>
          </LinkContainer>
          <h1>{campaignId === 'new' ? 'Create' : 'Edit'} Campaign</h1>
        </div>
        <div className='campaign-header__titleActions'>
          {campaignId !== 'new' &&
            <Button
              bsStyle='danger'
              onClick={handleDeleteClick}
            >
              Delete
            </Button>}
          <Button
            disabled={false} // TODO: Hook up this button to be disabled if the form is not complete or valid
            bsStyle='primary'
            onClick={onSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

CampaignHeader.propTypes = {
  onSaveClick: PropTypes.func
}

const CampaignForm = (props) => {
  const history = useHistory()
  const organization = useCurrentOrganization()
  const match = useRouteMatch()
  const [deleteCampaign] = useMutation(Mutations.DeleteCampaignById)
  const { bindSubmitForm } = props
  const applicationOptions = (() => {
    const options = [
      { label: 'OuterSpatial', value: 'outerspatial' }
    ]

    organization.communities.forEach((community) => {
      if (community.community.name === 'Trails LA County') {
        options.push({ label: community.community.name, value: community.community.name.replace(/ /g, '').toLowerCase() })
      }
    })

    return options
  })()
  const [brandedAppSelected, setBrandedAppSelected] = useState(false)
  const campaignId = isNaN(match.params.campaignId) ? -1 : match.params.campaignId
  const [selectedCommunityId, setSelectedCommunityId] = useState(null)
  const [selectedEntityType, setSelectedEntityType] = useState(null)
  const [selectedEntityValue, setSelectedEntityValue] = useState(null)
  const [selectedOrganizationSlug, setSelectedOrganizationSlug] = useState(null)
  const { data: getData, error: getError, loading: getIsLoading } = useQuery(Queries.GetCampaignById, {
    skip: campaignId === -1,
    variables: {
      campaignId: campaignId
    }
  })
  const [getOrganizationSlug] = useLazyQuery(
    Queries.GetOrganizationSlugById,
    {
      onCompleted: (data) => {
        setSelectedOrganizationSlug(data.organizations[0].slug)
      }
    }
  )
  const [insertCampaign, { loading: insertIsLoading }] = useMutation(Mutations.InsertCampaign, {
    update: (cache, response) => {
      cache.modify({
        fields: {
          campaigns (existingCampaigns = []) {
            const fragment = Fragments.CampaignDetails
            const newCampaignRef = cache.writeFragment({
              data: response.data.insert_campaigns.returning[0],
              fragment: fragment
            })

            return [...existingCampaigns, newCampaignRef]
          }
        }
      })
    }
  })
  const [updateCampaign, { loading: updateIsLoading }] = useMutation(Mutations.UpdateCampaign, {
    onCompleted: () => {
      toast.success('Campaign updated.')
    },
    onError: () => {
      toast.error('The campaign could not be updated.')
    }
  })

  if (getError && getError.message) {
    return <Alert bsStyle='danger'>{getError.message}</Alert>
  } else if (getIsLoading) {
    return <CenteredLoader />
  } else {
    let initialValues = {
      application: 'outerspatial',
      community_id: -1,
      entity_type: selectedEntityType,
      entity_id: selectedEntityValue,
      utm_campaign: '',
      utm_medium: '',
      utm_source: ''
    }

    const validateUtmCampaign = value => {
      return Yup
        .string()
        .max(100, 'Must be 100 characters or less.')
        .required('Name is required.')
        .validate(value, { abortEarly: false })
        .then(result => null)
        .catch(error => {
          return error.errors
        })
    }

    const validateUtmMediumOrSource = value => {
      return Yup
        .string()
        .max(100, 'Must be 100 characters or less.')
        .validate(value, { abortEarly: false })
        .then(result => null)
        .catch(error => {
          return error.errors
        })
    }

    const validateApplication = value => {
      if (applicationOptions.length === 1) {
        Yup.string().nullable(true)
      } else {
        Yup.string().nullable(false).required('Application is required.')
      }
    }

    const validateCommunityId = value => {
      if (brandedAppSelected === false) {
        return Yup
          .number()
          .positive('Community selection is required.')
          .required('Community selection is required.')
          .validate(value, { abortEarly: false })
          .then(result => null)
          .catch(error => {
            return error.errors
          })
      }
    }

    const validateEntityType = value => {
      if (value === 'Community' && selectedCommunityId === null) {
        return null
      } else {
        return Yup
          .string()
          .nullable(false)
          .max(100, 'Must be 100 characters or less.')
          .required('Entity Type is required.')
          .validate(value, { abortEarly: false })
          .then(result => null)
          .catch(error => {
            return error.errors
          })
      }
    }

    const validateEntityId = value => {
      if (selectedEntityType !== 'Community') {
        return Yup
          .number()
          .positive('Entity selection is required.')
          .required('Entity selection is required.')
          .validate(value, { abortEarly: false })
          .then(result => null)
          .catch(error => {
            return error.errors
          })
      }
    }

    if (getData && getData.campaigns && getData.campaigns.length > 0) {
      initialValues = getData.campaigns[0]
      let entityType = initialValues.entity_type

      // Normalize to Manager and database type from Mobile App type.
      if (entityType === 'FeaturedContent') {
        entityType = 'ContentBundle'
      }

      if (selectedEntityType !== entityType) {
        setSelectedCommunityId(initialValues.community_id)
        setSelectedEntityValue(initialValues.entity_id)
        setSelectedEntityType(entityType)
      }

      if (brandedAppSelected === false && initialValues.application !== 'outerspatial') {
        setBrandedAppSelected(true)
      }
    } else if (campaignId !== -1) {
      return <Error404 />
    }

    return (
      <div className='campaign-form'>
        {(insertIsLoading || updateIsLoading) &&
          <CenteredLoader overlay />}

        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={(values, { setSubmitting }) => {
            let branchKey = process.env.REACT_APP_BRANCH_KEY
            let communityId = values.community_id
            let entityType = values.entity_type
            const entityId = entityType === 'Community' ? -1 : values.entity_id

            if (brandedAppSelected === true) {
              communityId = organization.communities.find(community => community.community.name.replace(/ /g, '').toLowerCase() === values.application).community.id
            }

            if (values.application && values.application !== 'outerspatial') {
              branchKey = process.env[`REACT_APP_${values.application.toUpperCase()}_BRANCH_KEY`]
            }

            if (entityType === 'ContentBundle') {
              entityType = 'FeaturedContent'
            }

            if (!entityId) {
              toast.error('Entity is required.')
              return
            }

            if (campaignId === -1) {
              (async () => {
                const desktopUrl = (() => {
                  const communitySlug = organization.communities.filter((community) => community.community.id === communityId)[0]?.community?.slug
                  let url = 'https://www.outerspatial.com/'

                  switch (entityType) {
                    case 'Area':
                      url += `areas/${entityId}?community=${communitySlug}`
                      break
                    case 'Challenge':
                      url += `challenges/${entityId}?community=${communitySlug}`
                      break
                    case 'Community':
                      url += `communities/${communitySlug}`
                      break
                    case 'Event':
                      url += `events/${entityId}?community=${communitySlug}`
                      break
                    case 'FeaturedContent':
                      url += `articles/${entityId}?community=${communitySlug}`
                      break
                    case 'Organization':
                      url += `organizations/${selectedOrganizationSlug}?community=${communitySlug}`
                      break
                    case 'Outing':
                      url += `outings/${entityId}?community=${communitySlug}`
                      break
                    case 'PointOfInterest':
                      url += `points-of-interest/${entityId}?community=${communitySlug}`
                      break
                    case 'Trail':
                      url += `trails/${entityId}?community=${communitySlug}`
                      break
                    default:
                      break
                  }

                  return url
                })()

                const appName = applicationOptions.find((item) => { return item.value === values.application }).label
                const title = `Check this out in ${appName}!`
                const canonicalId = entityType === 'Community' ? `Community-${communityId}` : `${entityType}-${entityId}`
                // Branch.io Deep Linking API
                // https://help.branch.io/developers-hub/docs/deep-linking-api#creating-a-deep-linking-url

                insertCampaign({
                  variables: {
                    application: values.application || 'outerspatial',
                    communityId: communityId,
                    entityId: entityId,
                    entityType: entityType,
                    organizationId: organization.id,
                    utmCampaign: values.utm_campaign,
                    utmMedium: values.utm_medium,
                    utmSource: values.utm_source
                  }
                }).then(async (successResponse) => {
                  const campaignId = successResponse.data.insert_campaigns.returning[0].id
                  const campaignDesktopUrl = entityType === 'Community' ? `${desktopUrl}?campaign_id=${campaignId}` : `${desktopUrl}&campaign_ud=${campaignId}`
                  const branchResponse = await fetch('https://api2.branch.io/v1/url', {
                    body: JSON.stringify({
                      branch_key: branchKey, // required
                      campaign: values.utm_campaign,
                      campaign_id: campaignId.toString(),
                      channel: values.utm_source,
                      feature: values.utm_medium,
                      data: { // branch_link_properties
                        $canonical_identifier: canonicalId,
                        $desktop_url: campaignDesktopUrl,
                        $marketing_title: values.utm_campaign,
                        // TODO: Need to grab the record and get the description and image_url...
                        // $og_description: '',
                        // $og_image_url: '',
                        $og_title: title,
                        community_id: communityId,
                        entity_id: entityId,
                        entity_type: entityType,
                        os_campaign_id: campaignId
                      },
                      type: 0 // Marketing URL - shows up on the Branch dashboard
                    }),
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    method: 'post'
                  })
                  const json = await branchResponse.json()
                  const deepLinkUrl = json.url
                  if (deepLinkUrl) {
                    updateCampaign({
                      variables: {
                        setInput: {
                          deep_link_url: deepLinkUrl
                        },
                        campaignId: campaignId
                      }
                    }).then(() => {
                      const newRoute = match.path.split(':campaignId')[0]

                      toast.success('Campaign created.')
                      history.replace(`${newRoute}${campaignId}`)
                    })
                  } else {
                    deleteCampaign({
                      variables: {
                        id: campaignId
                      }
                    }).then(() => {
                      toast.warning('Could not create campaign. Branch.io failure.')
                    })
                  }
                }, (errorResponse) => {
                  toast.error('Could not create campaign. GraphQL failure.')
                })
              })()
            } else {
              // FIXME: We don't update Branch.io with the change in campaign - do we want to?
              updateCampaign({
                variables: {
                  campaignId: campaignId,
                  setInput: {
                    utm_campaign: values.utm_campaign
                  }
                }
              })
            }

            setSubmitting(false)
          }}
        >
          {(formikProps) => {
            const communityOptions = (() => {
              const options = []

              organization.communities.forEach((community) => {
                options.push({
                  label: community.community.name,
                  value: community.community.id
                })
              })

              return options
            })()
            const entityTypeOptions = [
              { label: 'Area', value: 'Area', selected: true },
              { label: 'Article', value: 'ContentBundle' },
              { label: 'Challenge', value: 'Challenge' },
              { label: 'Community', value: 'Community' },
              { label: 'Event', value: 'Event' },
              { label: 'Organization', value: 'Organization' },
              { label: 'Outing', value: 'Outing' },
              { label: 'Point of Interest', value: 'PointOfInterest' },
              { label: 'Trail', value: 'Trail' }
            ]
            const disableEditing = (campaignId !== -1)

            bindSubmitForm(formikProps.submitForm)

            return (
              <Form
                noValidate
              >
                <div className='panel-header'>
                  <Field
                    name='utm_campaign'
                    validate={validateUtmCampaign}
                  >
                    {({ field, meta }) => (
                      <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                        <label className='control-label' htmlFor='utm_campaign'>Name *</label>
                        <input className='form-control' id='utm_campaign' type='text' {...field} />
                        {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                        <span className='help-block'>From <a href='https://en.wikipedia.org/wiki/UTM_parameters' rel='noopener noreferrer' target='_blank'>Wikipedia</a>: "Identifies a specific product promotion or strategic campaign", e.g. <code>Spring 2020 Trails Challenge</code>.</span>
                      </div>
                    )}
                  </Field>
                  <Field
                    name='utm_medium'
                    validate={validateUtmMediumOrSource}
                  >
                    {({ field, meta }) => (
                      <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                        <label className='control-label' htmlFor='utm_medium'>Medium</label>
                        <input className='form-control' disabled={disableEditing} id='utm_medium' type='text' {...field} />
                        {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                        <span className='help-block'>From <a href='https://en.wikipedia.org/wiki/UTM_parameters' rel='noopener noreferrer' target='_blank'>Wikipedia</a>: "Identifies what type of link was used", e.g. <code>qr_code</code>, <code>website_banner</code>, or <code>email</code>.</span>
                      </div>
                    )}
                  </Field>
                  <Field
                    name='utm_source'
                    validate={validateUtmMediumOrSource}
                  >
                    {({ field, meta }) => (
                      <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                        <label className='control-label' htmlFor='utm_source'>Source</label>
                        <input className='form-control' disabled={disableEditing} id='utm_source' type='text' {...field} />
                        {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                        <span className='help-block'>From <a href='https://en.wikipedia.org/wiki/UTM_parameters' rel='noopener noreferrer' target='_blank'>Wikipedia</a>: "Identifies which site sent the traffic", e.g. <code>google</code>, <code>newsletter</code>, or <code>facebook</code>.</span>
                      </div>
                    )}
                  </Field>
                </div>
                <div className='panel-body'>
                  {(applicationOptions.length > 1) &&
                    <Field
                      name='application'
                      validate={validateApplication}
                    >
                      {({ field, form, meta }) => (
                        <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                          <label className='control-label' htmlFor='application'>Application</label>
                          <div className='select-wrap'>
                            <ReactSelect
                              className='applicationSelect'
                              classNamePrefix='applicationSelect'
                              isClearable={false}
                              isDisabled={disableEditing}
                              name='application'
                              onChange={(option) => {
                                setBrandedAppSelected(option.value !== 'outerspatial')
                                organization.communities.forEach((community) => {
                                  if (community.community.name === option.label) {
                                    setSelectedCommunityId(community.id)
                                  }
                                })
                                setSelectedEntityValue(null)
                                form.setFieldValue(field.name, option.value)
                              }}
                              options={applicationOptions}
                              value={applicationOptions.find(option => option.value === field.value)} // Trigger onChange for first load.
                            />
                          </div>
                          {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                        </div>
                      )}
                    </Field>}
                  {(brandedAppSelected === false) &&
                    <Field
                      name='community_id'
                      validate={validateCommunityId}
                    >
                      {({ field, form, meta }) => (
                        <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                          <label className='control-label' htmlFor='community_id'>Community *</label>
                          <div className='select-wrap'>
                            <ReactSelect
                              className='communityIdSelect'
                              classNamePrefix='communityIdSelect'
                              isClearable={false}
                              isDisabled={disableEditing}
                              menuPlacement='top'
                              name='community_id'
                              onChange={(option) => {
                                if (option.value !== selectedCommunityId) {
                                  setSelectedCommunityId(option.value)
                                  setSelectedEntityValue(null)
                                  form.setFieldValue(field.name, option.value)
                                }
                              }}
                              options={communityOptions}
                              value={communityOptions.find(option => option.value === field.value)} // Trigger onChange for first load.
                            />
                          </div>
                          {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                        </div>
                      )}
                    </Field>}
                  <Field
                    name='entity_type'
                    validate={validateEntityType}
                  >
                    {({ field, form, meta }) => (
                      <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                        <label className='control-label' htmlFor='entity_type'>Entity Type *</label>
                        <div className='select-wrap'>
                          <ReactSelect
                            className='entityTypeSelect'
                            classNamePrefix='entityTypeSelect'
                            isClearable={false}
                            isDisabled={disableEditing}
                            menuPlacement='top'
                            name='entity_type'
                            onChange={(option) => {
                              setSelectedEntityType(option.value)
                              form.setFieldValue(field.name, option.value)
                              setSelectedEntityValue(null)
                              form.setFieldValue('entity_id', null)
                            }}
                            options={entityTypeOptions}
                            value={entityTypeOptions.find(option => option.value === field.value)} // Trigger onChange for first load.
                          />
                        </div>
                        {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                  {(selectedCommunityId !== null) && selectedEntityType !== '' && selectedEntityType !== 'Community' &&
                    <Field
                      name='entity_id'
                      validate={validateEntityId}
                    >
                      {({ field, form, meta }) => (
                        <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                          <label className='control-label' htmlFor='entity_id'>
                            {selectedEntityType ? entityTypeOptions.find(option => option.value === selectedEntityType)?.label : 'Area'} *
                          </label>
                          <div className='select-wrap'>
                            <EntitySelect
                              isClearable={false}
                              communityIdProp={selectedCommunityId}
                              entityTypes={[selectedEntityType]}
                              isDisabled={disableEditing}
                              menuPlacement='top'
                              name='entity_id' // TODO: This seems to be unused
                              onAdd={(option) => { // add/select callback
                                setSelectedEntityValue(option.value.value.id)
                                form.setFieldValue(field.name, option.value.value.id)
                                if (selectedEntityType === 'Organization') {
                                  getOrganizationSlug({ variables: { organizationId: option.value.value.id } })
                                } else {
                                  setSelectedOrganizationSlug(null)
                                }
                              }}
                              value={(selectedEntityValue && selectedEntityType)
                                ? { class: selectedEntityType, id: selectedEntityValue } : null}
                            />
                          </div>
                          {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                        </div>
                      )}
                    </Field>}
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

CampaignForm.propTypes = {
  bindSubmitForm: PropTypes.func
}

const Campaign = () => {
  let handleSubmitForm

  const bindSubmitForm = (submitForm) => {
    handleSubmitForm = submitForm
  }

  const handleSaveClick = (e) => {
    handleSubmitForm(e)
  }

  return (
    <div className='campaign-wrap'>
      <PageLayout>
        <PageHeader>
          <CampaignHeader
            onSaveClick={handleSaveClick}
          />
        </PageHeader>
        <PageContent>
          <Panel>
            <Panel.Body>
              <CampaignForm
                bindSubmitForm={bindSubmitForm}
              />
            </Panel.Body>
          </Panel>
        </PageContent>
      </PageLayout>
    </div>
  )
}

export default Campaign
