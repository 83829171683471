import React from 'react'
import t from 'prop-types'
import './centeredLoader.css'

const CenteredLoader = ({ overlay, turnOffOpacity }) => {
  return (
    <div className={'centeredLoader' + (overlay ? (' centeredLoader--overlay' + (turnOffOpacity ? '--noOpacity' : '')) : '')}>
      <div className='centeredLoader-loader' />
    </div>
  )
}

CenteredLoader.defaultProps = {
  turnOffOpacity: false
}
CenteredLoader.propTypes = {
  overlay: t.bool,
  turnOffOpacity: t.bool
}

export default CenteredLoader
