import React from 'react'
import PropTypes from 'prop-types'

const PageHeader = ({ children }) => {
  return (
    <div className='pageLayout-header'>
      {children}
    </div>
  )
}

PageHeader.propTypes = {
  children: PropTypes.any
}

export default PageHeader
