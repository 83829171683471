import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useHistory } from 'react-router'
import { useMutation } from '@apollo/client'
import React from 'react'
import toast from 'react-hot-toast'

import { Mutations, Queries } from './ChallengesOperations'
import Challenge from './challenge/Challenge'
import ChallengesList from './challenges/ChallengesList'
import debugMessage from 'services/Debug'

const Challenges = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()

  const [deleteChallenge] = useMutation(Mutations.DeleteChallenge, {
    refetchQueries: [Queries.GetChallengesByOrganizationId]
  })

  const handleDelete = (challenge, updatePath) => {
    const confirmationMessage = `Are you sure you want to delete the ${challenge.name} challenge?\n\nIf you delete the challenge, it will be gone permanently and you will no longer be able to view any reports or analytics for it. Deleted challenges cannot be recovered.`
    const conf = window.confirm(confirmationMessage)

    if (conf) {
      deleteChallenge({
        variables: {
          id: challenge.id
        }
      })
        .then(response => {
          if (response.data.delete_challenges.affected_rows) {
            if (updatePath) {
              const backPath = match.path.split(':')[0].slice(0, match.path.split(':')[0].length)
              history.push(backPath)
            }

            toast.success('Challenge deleted.')
          }
        })
        .catch(error => {
          debugMessage(error)
          toast.error('The challenge could not be deleted. Please try again.')
        })
    }
  }

  document.title = `Challenges | ${process.env.REACT_APP_PAGE_TITLE}`

  return (
    <Switch>
      <Route
        path={`${match.url}/:challengeId`}
        render={(props) => <Challenge onDeleteClick={handleDelete} />}
      />
      <Route
        exact
        path={`${match.url}`}
        render={() => <ChallengesList onDeleteClick={handleDelete} organization={organization} />}
      />
    </Switch>
  )
}

export default Challenges
