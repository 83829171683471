import { Button, Form, Glyphicon } from 'react-bootstrap'
import { gql, useMutation } from '@apollo/client'
import { React, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone-uploader'
import fetch from 'node-fetch'
import PropTypes from 'prop-types'

import 'react-dropzone-uploader/dist/styles.css'

import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useHistory } from 'react-router-dom'
import { CenteredLoader } from 'components/centeredLoader'

const Input = ({ accept, onFiles }) => {
  const history = useHistory()

  return (
    <>
      <Button bsStyle='link' className='uploader-close' onClick={() => history.goBack()}>
        <Glyphicon glyph='remove' />
      </Button>
      <div className='uploader-container-inner'>
        <h3>Drop a file here to upload.</h3>
        <p>or</p>
        <label className='btn btn-default'>
          Select a File
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            multiple
            onChange={e => {
              onFiles(Array.from(e.target.files))
            }}
          />
        </label>
      </div>
    </>
  )
}

Input.propTypes = {
  accept: PropTypes.string,
  onFiles: PropTypes.func
}

const insertShapefileUploadMutation = gql`
  mutation insertShapefileUpload($shapefileUpload: shapefile_uploads_insert_input!) {
    insert_shapefile_uploads_one(object: $shapefileUpload) {
      id
    }
  }
`

export const ImportForm = ({ match }) => {
  const [insertShapefileUpload, { data }] = useMutation(insertShapefileUploadMutation)
  const [isUploading, setIsUploading] = useState(false)
  const fileTypesAccepted = '.geojson, .ldgeojson'
  const history = useHistory()
  const organization = useCurrentOrganization()

  useEffect(() => {
    if (data?.insert_shapefile_uploads_one?.id) {
      history.push(`${match.url}/imports`)
    }
  })

  const getUploadParams = async ({ file }) => {
    const presignedUrl = `${process.env.REACT_APP_API_SERVICE_URL}/uploads/import-presigned-url`
    const path = organization && organization.id ? organization.id : null

    return fetch(presignedUrl, {
      body: JSON.stringify({
        filename: file.name,
        path: path
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Could not get AWS S3 Presigned URL.')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta }, status) => {
    // All possible values: https://react-dropzone-uploader.js.org/docs/api#status-values
    switch (status) {
      case 'getting_upload_params':
      case 'headers_received':
      case 'preparing':
      case 'started':
      case 'uploading':
        break
      case 'done':
        // Append Filename & S3 URL to table at bottom.
        insertShapefileUpload({
          variables: {
            shapefileUpload: {
              name: meta.fileUrl.split('/').pop(),
              organization_id: organization.id,
              geojson_url: meta.fileUrl,
              status: 'Uploaded'
            }
          }
        })
        setIsUploading(false)
        break
      default:
        // Error.
        setIsUploading(false)
        break
    }
  }

  return (
    <>
      <Form>
        {isUploading && <CenteredLoader overlay />}
        <Dropzone
          accept={fileTypesAccepted}
          canCancel={false}
          getUploadParams={getUploadParams}
          InputComponent={Input}
          maxFiles={1}
          multiple={false}
          noClick
          onChangeStatus={handleChangeStatus}
          styles={{
            dropzone: { border: '5px dashed #aab2bd', overflow: 'hidden', width: '100%' },
            dropzoneActive: { backgroundColor: 'rgb(254, 251, 228)' }
          }}
          submitButtonContent={null} // Disable submit button since we are auto-uploading
        />
      </Form>
    </>
  )
}

ImportForm.propTypes = {
  match: PropTypes.object
}

export default ImportForm
