import Carousel from 'react-bootstrap/lib/Carousel'
import Panel from 'react-bootstrap/lib/Panel'
import React from 'react'

const BlogUpdates = () => {
  const blogUpdates = [{
    blogUrl: 'https://organizations.outerspatial.com/blog/winter-2023-product-updates',
    category: 'Product Updates',
    date: 'December 5, 2023',
    imageUrl: 'https://assets-global.website-files.com/5e74e92c08eb936daf7aa711/656f87b5208fde46ddae46af_title-card.png',
    title: 'Winter 2023 Product Updates'
  }, {
    blogUrl: 'https://organizations.outerspatial.com/blog/the-2023-ridge-trail-challenge-is-complete',
    category: 'Partner Highlights',
    date: 'October 10, 2023',
    imageUrl: 'https://assets-global.website-files.com/5e74e92c08eb936daf7aa711/65256ddd52f7acbb92df44dd_visitors-blog-thumb.jpg',
    title: 'The 2023 Ridge Trail Challenge is Complete!'
  }, {
    blogUrl: 'https://organizations.outerspatial.com/blog/transform-your-state-park-experience-with-the-nevada-state-parks-digital-passport-program',
    category: 'Partner Highlights',
    date: 'September 21, 2023',
    imageUrl: 'https://assets-global.website-files.com/5e74e92c08eb936daf7aa711/650cb88c1b6fe57c235f8259_extra_large_Cull_Canyon_Sign_2CROP%201%20(1).png',
    title: 'Transform Your State Park Experience with the Nevada State Parks Digital Passport Program!'
  }, {
    blogUrl: 'https://organizations.outerspatial.com/blog/summer-2023-product-updates',
    category: 'Product Updates',
    date: 'June 3, 2023',
    imageUrl: 'https://assets-global.website-files.com/5e74e92c08eb936daf7aa711/6494d942239f7bc3c36cc426_title-card.png',
    title: 'Summer 2023 Product Updates'
  }, {
    blogUrl: 'https://organizations.outerspatial.com/blog/find-outerspatial-at-rmspec-2023',
    category: 'Conferences & Events',
    date: 'April 14, 2023',
    imageUrl: 'https://assets-global.website-files.com/5e74e92c08eb936daf7aa711/64399120b2c3e76c5f16847f_container%20(3).png',
    title: 'Find OuterSpatial At RMSPEC 2023'
  }]

  const handleClick = () => {}

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>
          From the Blog
          <p>Updates about the OuterSpatial product, partner highlights, tips & tricks, and more.</p>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <Carousel
          indicators={false}
          interval={0}
          onSelect={handleClick}
        >
          {blogUpdates.map((blogUpdate, index) => (
            <Carousel.Item
              key={index}
            >
              <a
                className='image-wrapper'
                href={blogUpdate.blogUrl}
                rel='noopener noreferrer'
                target='_blank'
              >
                <img
                  alt=''
                  src={blogUpdate.imageUrl}
                />
              </a>
              <div className='wrap'>
                <div className='clearfix top'>
                  <div className='type'>{blogUpdate.category}</div>
                  <div className='date'>{blogUpdate.date}</div>
                </div>
                <a
                  href={blogUpdate.blogUrl}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <h3>{blogUpdate.title}</h3>
                </a>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Panel.Body>
    </Panel>
  )
}

export default BlogUpdates
