import { React, useState } from 'react'
import Button from 'react-bootstrap/lib/Button'
import Modal from 'react-bootstrap/lib/Modal'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

import MediaGallery from '../gallery/MediaGallery'

import './mediaModal.css'

const MediaModal = ({
  allowSelection = true,
  allowUploads = true,
  filterByFeature,
  galleryProps,
  onClose,
  selectedFeature,
  singleSelection = false,
  show,
  onSubmit
}) => {
  const [selected, setSelected] = useState([])

  const changeSelection = (selection) => {
    setSelected(selection)
  }

  const submit = () => {
    if (selected && selected.length) {
      setSelected([])
      onSubmit(selected)
    } else {
      toast.error('No image selected.')
    }
  }

  return (
    <div className='mediaModal'>
      <Modal
        backdrop='static'
        dialogClassName='mediaModal-dialog'
        keyboard={false}
        onHide={onClose}
        show={show}
      >
        <Modal.Header className='mediaModal-titleBar'>
          <div className='mediaModal-titleBar__content'>
            <Modal.Title>Insert Image</Modal.Title>
            <div className='mediaModal-actions'>
              <Button bsStyle='primary' onClick={() => submit()}>Insert</Button>
              <Button bsStyle='default' onClick={onClose}>Cancel</Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <MediaGallery
            allowDeleteAll={false}
            allowEdit={false}
            allowUploads={allowUploads}
            allowSelection={allowSelection}
            filterByFeature={filterByFeature}
            onSelectionChange={changeSelection}
            scope={null}
            selectedFeature={selectedFeature}
            singleSelection={singleSelection}
            {...galleryProps}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

MediaModal.propTypes = {
  allowSelection: PropTypes.bool,
  allowUploads: PropTypes.bool,
  filterByFeature: PropTypes.bool,
  galleryProps: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedFeature: PropTypes.object,
  show: PropTypes.bool,
  singleSelection: PropTypes.bool
}

export default MediaModal
