import { useMutation } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { Form, Formik } from 'formik'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Button from 'react-bootstrap/lib/Button'
import Panel from 'react-bootstrap/lib/Panel'
import { useHistory, useLocation } from 'react-router'
import Select from 'react-select'
import { buildImageUploadUrl } from 'services/Images'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import AssociationSet from '../../../components/associationSet'
import FormikGroup from '../../../components/fieldGroup/FormikGroup'
import { handleContentStyleGuideClick } from '../../../components/helpScout'
import MediaModal from '../../../components/media/modal/MediaModal'
import { timezones as timezoneOptions } from '../../../models/time.model'
import './eventForm.css'
import * as Queries from './Queries'

const locationOptions = [
  { value: 'feature', label: 'Location' },
  { value: 'geocode', label: 'Address' },
  { value: 'string', label: 'Free-form' }
]

const propTypes = {
  allowAssociations: PropTypes.bool,
  attachImage: PropTypes.func,
  innerRef: PropTypes.object,
  model: PropTypes.object
}

const renderAssociations = (model) => {
  let associations = []

  if (model.id) {
    const attachments = [
      model.area_attachments.map((item) => { return { id: item.id, feature: { ...item.feature, class_name: 'Area' } } }),
      model.outing_attachments.map((item) => { return { id: item.id, feature: { ...item.feature, class_name: 'Outing' } } }),
      model.point_of_interest_attachments.map((item) => { return { id: item.id, feature: { ...item.feature, class_name: 'PointOfInterest' } } }),
      model.trail_attachments.map((item) => { return { id: item.id, feature: { ...item.feature, class_name: 'Trail' } } })
    ].flat()
    associations = attachments.map((att) => {
      const name = att.feature && att.feature.name ? att.feature.name : `${att.feature_type} ${att.feature_id}`
      return {
        ...att,
        contentName: name,
        contentId: att.feature_id
      }
    })
  }

  return associations
}

const renderBannerUrl = (image) => {
  let bannerUrl = null

  if (image && image.id && image.uploaded_file) {
    bannerUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'small')
  }

  return bannerUrl
}

const EventForm = (props) => {
  const {
    allowAssociations = true,
    innerRef,
    model
  } = props
  const organization = useCurrentOrganization()
  let defaultLocationType = model?.location?.charAt(0) === '{' ? 'geocode' : 'string'
  if (model.feature_type) {
    defaultLocationType = 'feature'
  }
  const history = useHistory()
  const location = useLocation()
  const [locationType, setLocationType] = useState(defaultLocationType)
  const [showModal, setShowModal] = useState()
  const [locationFeature, setLocationFeature] = useState({ class: model.feature_type, id: model.feature_id })
  const [timeZone, setTimeZoneState] = useState(model.time_zone || organization.time_zone)
  const [bannerImage, setBannerImage] = useState(model.banner_image)
  const eventSchedule = model.schedules ? model.schedules[0].schedule : null
  const [startDate, setStartDate] = useState(eventSchedule.date)
  const [startTime, setStartTime] = useState(eventSchedule.time)
  const [endDatetime, setEndDatetime] = useState(eventSchedule.until)
  const [end, setEnd] = useState(null)
  const [start, setStart] = useState(null)
  const [associations, setAssociations] = useState(model ? renderAssociations(model) : [])
  const bannerUrl = model ? renderBannerUrl(bannerImage || model.banner_image) : ''
  const [insertEventMutation] = useMutation(Queries.InsertEventMutation)
  const [updateEventMutation] = useMutation(Queries.UpdateEventMutation, { refetchQueries: ['GetEventQuery'] })
  const [insertScheduleMutation] = useMutation(Queries.InsertScheduleMutation)
  const [updateScheduleMutation] = useMutation(Queries.UpdateScheduleMutation, { refetchQueries: ['GetEventQuery'] })
  const [insertEventAttachmentMutation] = useMutation(Queries.InsertEventAttachment, { refetchQueries: ['GetEventQuery'] })
  const [removeEventAttachmentMutation] = useMutation(Queries.RemoveEventAttachment, { refetchQueries: ['GetEventQuery'] })

  const formikProps = {
    initialValues: {
      cost: model.cost,
      description: model.description || '',
      end: end,
      location: model.location || '',
      name: model.name || '',
      phone_number: model.phone_number,
      start: start,
      time_zone: timeZone,
      website: model.website || ''
    },
    innerRef: innerRef,
    onSubmit: values => {
      handleSave(values)
    },
    validate: (values) => {
      const errors = {}
      const innerEnd = moment.tz(values.end, timeZone)
      const innerStart = moment.tz(values.start, timeZone)
      let valid = true

      if ((!innerStart || !innerEnd) || (innerStart && innerEnd && innerStart.isBefore(innerEnd))) {
        valid = true
      } else {
        valid = false
      }

      if (!valid) {
        errors.start = 'The event\'s start time must be before its end time.'
      }

      return errors
    },
    validationSchema: Yup.object({
      description: Yup
        .string()
        .trim('Name cannot include leading or trailing spaces.')
        .strict(true)
        .min(2, 'Name must have at least two characters.')
        .required('Description is required.'),
      name: Yup
        .string()
        .trim('Name cannot include leading or trailing spaces.')
        .strict(true)
        .min(2, 'Name must have at least two characters.')
        .max(500, 'Name must not be more than 500 characters.')
        .required('Name is required.')
    })
  }

  const addAssociation = (association) => {
    const attachment = {
      contentId: association.feature.id,
      contentName: association.feature.name,
      feature: association.feature
    }

    setAssociations([...associations, attachment])
    if (model.id) {
      insertEventAttachmentMutation({
        variables: {
          eventId: model.id,
          featureId: association.feature.id,
          featureType: association.feature.class_name
        }
      })
    }
  }

  const removeAssociation = (association) => {
    setAssociations(associations.filter((item) => { return !((item.feature.id === association.feature.id) && (item.feature.class_name === association.feature.class_name)) }))
    if (model.id) {
      removeEventAttachmentMutation({
        variables: {
          id: association.id
        }
      })
    }
  }

  const attachImage = (image) => {
    setBannerImage(image)
    closeMediaModal()
  }

  const changeDate = (field, value) => {
    if (field === 'start') {
      if (value) {
        const iso = moment.utc(value).tz(timeZone).toISOString()

        if (iso) {
          let date = null
          let time = null

          date = iso.split('T')[0]
          time = iso.split('T')[1]
          setStartDate(date)
          setStartTime(time)
          setStart(moment.utc(iso).tz(timeZone))
        }
      } else {
        setStartDate(null)
        setStartTime(null)
        setStart(null)
      }
    } else if (field === 'end') {
      if (value) {
        setEndDatetime(moment.utc(value).tz(timeZone))
        setEnd(moment.utc(value).tz(timeZone))
      } else {
        setEndDatetime(null)
        setEnd(null)
      }
    }
  }

  const closeMediaModal = () => {
    setShowModal(false)
  }

  const handleSave = (values) => {
    const location = locationType === 'feature' ? null : values.location
    const featureType = locationType === 'feature' ? locationFeature.class : null
    const featureId = locationType === 'feature' ? locationFeature.id : null
    const eventVariables = {
      banner_image_id: bannerImage?.id,
      cost: values.cost,
      description: values.description,
      feature_id: featureId,
      feature_type: featureType,
      location,
      name: values.name,
      organization_id: organization.id,
      phone_number: values.phone_number,
      time_zone: timeZone,
      website: values.website
    }

    if (!model.id) {
      insertEventMutation({
        variables: eventVariables
      }).then((results) => {
        const eventId = results.data.insert_events_one.id
        const scheduleVariables = {
          date: startDate,
          event_id: eventId,
          time: startTime,
          until: endDatetime
        }
        insertScheduleMutation({
          variables: scheduleVariables
        }).then((results) => {
          associations.forEach((association) => {
            insertEventAttachmentMutation({
              variables: {
                eventId: eventId,
                featureId: association.feature.id,
                featureType: association.feature.class_name
              }
            })
          })
          toast.success('Event Saved')
          history.push(`/${organization.id}/events/${eventId}`)
        })
      })
    } else {
      updateEventMutation({
        variables: {
          ...eventVariables,
          id: model.id
        }
      }).then((results) => {
        const scheduleId = results.data.update_events_by_pk.schedules[0].schedule.id
        const scheduleVariables = {
          date: startDate,
          id: scheduleId,
          time: startTime,
          until: endDatetime
        }
        updateScheduleMutation({
          variables: scheduleVariables
        }).then(() => {
          toast.success('Event Saved')
          // history.push(`/${organization.id}/events/${eventId}`)
        })
      })
    }
  }

  const openMediaModal = (media) => {
    setShowModal(true)
  }

  const handleLocationFeatureChange = (value) => {
    // props.updateField(null, { feature_id: value.attacheable_id, feature_type: value.attacheable_type, feature: value.feature })
    setLocationFeature({ class: value.attacheable_type, id: value.attacheable_id })
  }

  const setTimezone = (value) => {
    setTimeZoneState(value.value || value)
  }

  if (!start) {
    if (location.state && location.state.slotInfo && location.state.slotInfo.start) {
      changeDate('start', location.state.slotInfo.start.toISOString())
    } else if (eventSchedule) {
      changeDate('start', moment.utc(`${startDate}T${startTime}`))
    }
  }

  if (!end) {
    if (location.state && location.state.slotInfo && location.state.slotInfo.end) {
      changeDate('end', moment.utc(location.state.slotInfo.end.toISOString()))
    } else if (eventSchedule) {
      changeDate('end', moment.utc(endDatetime))
    }
  }

  if (location.state && location.state.slotInfo) {
    window.history.replaceState({}, document.title)
  }

  return (
    <div className='eventForm-wrap'>
      <div className='eventForm-content'>
        <Panel>
          <Panel.Heading>
            Basic Info
            <div style={{ position: 'absolute', right: '0', top: '6px' }}>
              <Button
                bsStyle='link'
                onClick={handleContentStyleGuideClick}
              >
                Content Style Guide
              </Button>
            </div>
          </Panel.Heading>
          <Panel.Body>
            <Formik {...formikProps}>
              {_formik => (
                <Form>
                  <FormikGroup
                    id='name'
                    label='Name *'
                    name='name'
                    type='text'
                  />
                  <div className='eventForm-featuredImage__wrap'>
                    <label className='control-label'>Banner Image</label>
                    <div className={'eventForm-featuredImage ' + (bannerUrl ? 'featuredImage--hasLogo' : '')}>
                      {!!bannerUrl &&
                        <div className='eventForm-image' style={{ backgroundImage: `url(${bannerUrl})` }} />}
                      <div className='eventForm-featuredImage__controls'>
                        <Button className='eventForm-imageButton' onClick={() => openMediaModal()} bsSize='small'>
                          {bannerUrl ? 'Change Image' : 'Add Image'}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='eventForm-description'>
                    <FormikGroup
                      help='A one-to-two paragraph description.'
                      id='description'
                      name='description'
                      label='Description *'
                      rows='6'
                      type='wysiwyg'
                    />
                  </div>
                  <div className='eventForm-dateRow'>
                    <FormikGroup
                      id='startTime'
                      name='start'
                      label='Start Time *'
                      onChange={(value) => changeDate('start', value)}
                      type='datetime'
                      value={start}
                      displayTimeZone={timeZone}
                    />
                    <FormikGroup
                      id='endTime'
                      name='end'
                      label='End Time *'
                      onChange={(value) => { changeDate('end', value) }}
                      type='datetime'
                      value={end}
                      displayTimeZone={timeZone}
                    />
                    <fieldset className='eventForm-dateRow--timezone'>
                      <label htmlFor='timezone'>Timezone</label>
                      <Select
                        className='eventForm-timezoneSelect'
                        isClearable={false}
                        name='timezone'
                        onChange={setTimezone}
                        options={timezoneOptions}
                        value={timezoneOptions.filter(({ value }) => value === timeZone)}
                      />
                    </fieldset>
                  </div>
                  <div className='eventForm-locationRow'>
                    <label>Place</label>
                    <div className='eventForm-locationRow__content'>
                      <Select
                        clearable={false}
                        className='eventForm-locationSelect'
                        onChange={(e) => { setLocationType(e.value) }}
                        options={locationOptions}
                        value={locationOptions.filter(({ value }) => value === locationType)}
                      />
                      <div className='eventForm-locationRow__field'>
                        {locationType === 'feature' &&
                          <AssociationSet
                            entityTypes={['Features']}
                            onAdd={(association) => handleLocationFeatureChange(association)}
                            placeholder='Search locations...'
                            restrictToCurrentOrganization
                            staySelected
                            value={locationFeature}
                          />}
                        {locationType === 'geocode' &&
                          <FormikGroup
                            clearable
                            name='location'
                            id='locationSelect'
                            label={null}
                            type='location'
                          />}
                        {locationType === 'string' &&
                          <FormikGroup
                            name='location'
                            id='location'
                            label={null}
                            type='text'
                          />}
                      </div>
                    </div>
                  </div>
                  <FormikGroup
                    help='A link to a web page that contains more information about this event.'
                    id='website'
                    label='Web Page'
                    name='website'
                    type='text'
                  />
                  <FormikGroup
                    help='Information about how much this event costs. Put "Free" if it does not cost anything.'
                    name='cost'
                    id='Cost'
                    label='Cost'
                    model='.cost'
                    type='text'
                  />
                  <FormikGroup
                    help='Contact number for event inquiries.'
                    name='phone_number'
                    id='phoneNumber'
                    label='Phone Number'
                    type='phone'
                  />
                </Form>
              )}
            </Formik>
          </Panel.Body>
        </Panel>
        {allowAssociations &&
          <Panel className='eventForm-associations'>
            <Panel.Heading>Related</Panel.Heading>
            <Panel.Body>
              <AssociationSet
                associations={associations}
                entityTypes={['Features']}
                isMultipleAssociationSet
                onAdd={addAssociation}
                onRemove={removeAssociation}
                restrictToCurrentOrganization
              />
            </Panel.Body>
          </Panel>}
      </div>
      <MediaModal
        allowSelection
        onClose={() => closeMediaModal()}
        onSubmit={(selected) => {
          attachImage(selected[0])
        }}
        show={showModal}
        singleSelection
      />
    </div>
  )
}

EventForm.propTypes = propTypes

export default EventForm
