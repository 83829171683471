import { Field } from 'formik'
import ControlLabel from 'react-bootstrap/lib/ControlLabel'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import HelpBlock from 'react-bootstrap/lib/HelpBlock'
import PropTypes from 'prop-types'
import React from 'react'
import ReactSelect from 'react-select'

import Editor from '../../../../../components/editorWithHooks'
import EntitySelect from '../../../../../components/associationSet/EntitySelect'
import MediaChooser from '../../../../../components/media/chooser/MediaChooser'

const classMatch = (classes, model) => classes.indexOf(model.class_name) > -1

const BasicInfo = ({ feature, pointTypeOptions = [] }) => {
  return (
    <div className='featureSection-content featureSection--basicInfo'>
      <FormGroup>
        <ControlLabel>Set App Images</ControlLabel>
        <MediaChooser
          feature={feature}
        />
      </FormGroup>
      <Field name='name'>
        {({ field, meta }) => (
          <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
            <label className='control-label' htmlFor='name'>Name</label>
            <input className='form-control' id='name' type='text' {...field} />
            {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
          </div>
        )}
      </Field>
      {classMatch(['PointOfInterest'], feature) &&
        <Field
          name='point_of_interest_type_id'
        >
          {({ field, form, meta }) => (
            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
              <label className='control-label' htmlFor='point_of_interest_type_id'>Type</label>
              <div className='select-wrap'>
                <ReactSelect
                  isDisabled={!pointTypeOptions.length}
                  menuPlacement='top'
                  name='point_of_interest_type_id'
                  onChange={(option) => {
                    form.setFieldValue(field.name, option.value)
                  }}
                  options={pointTypeOptions}
                  value={pointTypeOptions.find(option => option.value === field.value)} // Trigger onChange for first load.
                />
              </div>
              {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
            </div>
          )}
        </Field>}
      {classMatch(['PointOfInterest'], feature) &&
        <Field
          name='parent_poi_id'
        >
          {({ field, form, meta }) => (
            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
              <label className='control-label' htmlFor='parent_poi'>Parent Point of Interest</label>
              <div className='select-wrap'>
                <EntitySelect
                  clearable
                  entityTypes={['PointOfInterest']}
                  menuPlacement='top'
                  onAdd={(option) => {
                    if (option) {
                      form.setFieldValue(field.name, option.value.value.id)
                    } else {
                      form.setFieldValue(field.name, -1)
                    }
                  }}
                  placeholder='Search points of interest...'
                  restrictToCurrentOrganization
                  value={(field.value !== -1) ? { class: 'PointOfInterest', id: field.value } : null}
                />
              </div>
              {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
            </div>
          )}
        </Field>}
      <Field name='description'>
        {({ field, form, meta }) => (
          <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
            <label className='control-label' htmlFor='description'>Description</label>
            <Editor
              id={field.name}
              className='form-control'
              value={field.value}
              onChange={(value) => {
                form.setFieldValue(field.name, value)
                form.setFieldTouched(field.name)
              }}
            />
            <HelpBlock>A one-to-two paragraph description.</HelpBlock>
            {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
          </div>
        )}
      </Field>
      {classMatch(['PointOfInterest', 'Trail'], feature) &&
        <Field
          name='parent_area_id'
        >
          {({ field, form, meta }) => (
            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
              <label className='control-label' htmlFor='parent_area_id'>Parent Area</label>
              <div className='select-wrap'>
                <EntitySelect
                  clearable
                  entityTypes={['Area']}
                  menuPlacement='top'
                  restrictToCurrentOrganization
                  onAdd={(option) => {
                    if (option) {
                      form.setFieldValue(field.name, option.value.value.id)
                    } else {
                      form.setFieldValue(field.name, -1)
                    }
                  }}
                  placeholder='Search areas...'
                  value={(field.value !== -1) ? { class: 'Area', id: field.value } : null}
                />
                <HelpBlock>The primary area this location is part of.</HelpBlock>
              </div>
              {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
            </div>
          )}
        </Field>}
    </div>
  )
}

BasicInfo.propTypes = {
  feature: PropTypes.object,
  pointTypeOptions: PropTypes.array
}

export default BasicInfo
