import { useApolloClient, useQuery, gql, useMutation } from '@apollo/client'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { React, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/lib/Alert'
import Panel from 'react-bootstrap/lib/Panel'
import toast from 'react-hot-toast'
import EditMediaModal from '../../../components/media/single/EditMediaModal'
import Uploader from '../../../components/uploader'
import { useCurrentOrganization } from '../../../contexts/OrganizationContext'
import OrganizationImagesQuery from '../../../queries/OrganizationImagesQuery'
import MediaActionBar from './actionBar/MediaActionBar'
import MediaList from './list/MediaList'
import './mediaGallery.css'

let totalPages = 0
const perPage = 40

const deleteImageMutation = gql`
  mutation DeleteImageAttachments($id: Int!) {
    delete_image_attachments(where: {image_id: {_eq: $id}}) {
      affected_rows
      returning {
        id
      }
    }
  }
`

const MediaGallery = ({
  allowAssociations = true,
  allowDeleteAll = true,
  allowFilter = true,
  allowInsert,
  allowSelection = false,
  allowEdit = true,
  allowSort = true,
  allowTableMode = true,
  allowUploads = true,
  deleteText = 'Delete',
  filterByFeature,
  selectedFeature,
  singleSelection,
  onSelectionChange
}) => {
  const [uploadError, setUploadError] = useState()
  const [selectedMedia, setSelectedMedia] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showUploader, setShowUploader] = useState(false)
  const [tableMode, setTableMode] = useState(false)
  const [clickedImage, setClickedImage] = useState()
  const updatingSingle = false
  const updating = false
  const client = useApolloClient()
  const [deleteImageMutationFunction] = useMutation(deleteImageMutation)

  let images = []
  const organization = useCurrentOrganization()

  const [query, setQuery] = useState({
    all: true,
    page: 1,
    direction: 'desc'
  })

  let paging = { totalPages, currentPage: query.page, perPage }

  const queryVariables = {
    id: organization.id,
    limit: perPage,
    offset: (paging.currentPage - 1) * perPage,
    order_by: {
      created_at: query.direction
    }
  }

  const whereAnds = []
  if (query.user_id) {
    whereAnds.push({ image: { user_id: { _eq: query.user_id } } })
  }

  if (query.attacheable_id && query.attacheable_type) {
    whereAnds.push({ image: { image_attachments: { attacheable_id: { _eq: query.attacheable_id } } } })
    whereAnds.push({ image: { image_attachments: { attacheable_type: { _eq: query.attacheable_type } } } })
  }

  if (whereAnds.length > 0) {
    queryVariables.where = { _and: whereAnds }
  }

  const { data, loading, error } = useQuery(OrganizationImagesQuery, {
    variables: queryVariables
  })

  if (data) {
    images = data.organizations_by_pk.image_attachments.map(item => {
      return {
        attachment_id: item.id,
        ...item.image
      }
    })
    const totalImages = data.organizations_by_pk.image_attachments_aggregate.aggregate.count
    totalPages = Math.ceil(totalImages / 50)
    paging = { totalPages, currentPage: query.page, perPage: 50 }
  }

  useEffect(() => {
    document.title = `Images | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  const toggleTableMode = (table) => {
    setTableMode(!tableMode)
  }

  const queryChange = (newQuery) => {
    setQuery({
      ...query,
      ...newQuery
    })
  }

  const deleteImages = async (images) => {
    await images.forEach((image) => {
      deleteImageMutationFunction({
        variables: {
          id: image.id
        }
      })
    })
  }

  const deleteSelectedMedia = () => {
    const conf = window.confirm('Are you sure you want to delete these images?')
    if (conf) {
      deleteImages(selectedMedia).then(() => {
        toast.success('Deleted images.')
        setSelectedMedia([])
        client.refetchQueries({
          include: ['OrganizationImagesQuery']
        })
      })
    }
  }

  const deleteFromModal = () => {
    const conf = window.confirm('Are you sure you want to delete this image?')
    if (conf) {
      deleteImages([clickedImage]).then(() => {
        toast.success('Deleted image.')
        client.refetchQueries({
          include: ['OrganizationImagesQuery']
        })
      })
      closeModal()
    }
  }

  const toggleSort = (oldest) => {
    if (oldest && (query.direction === 'asc')) {
      return false
    }

    const direction = query.direction === 'asc' ? 'desc' : 'asc'

    queryChange({ ...query, direction })
  }

  const clickInsert = () => {
    hideUploader()
    // not sure what to do here
  }

  const clickMediaItem = (image, selected, checkbox) => {
    if (checkbox) {
      toggleSelection(image, selected)
    } else {
      openModal(image)
    }
  }

  const closeModal = () => {
    setClickedImage(null)
    setShowModal(false)
  }

  const deselectAll = () => {
    setSelectedMedia([])
  }

  const hideUploader = () => {
    setShowUploader(false)
  }

  const openModal = (image) => {
    setClickedImage(image)
    setShowModal(true)
  }

  const saveMediaForm = (values) => {
    closeModal()
  }

  const uploadStateError = (errors) => {
    if (!errors.length) {
      setUploadError(null)
    } else {
      setUploadError(errors[0])
    }
  }

  const toggleSelection = (image, selected) => {
    let newSelect = []

    selected = selected === 'true' || selected === true

    // TODO: This is backwards. Fix.
    if (selected) { // remove item
      const index = _.indexOf(selectedMedia, _.find(selectedMedia, {
        id: image.id
      }))

      newSelect = [...selectedMedia.slice(0, index), ...selectedMedia.slice(index + 1)]
      setSelectedMedia(newSelect)
      if (onSelectionChange) {
        onSelectionChange(newSelect)
      }
    } else { // add item
      if (singleSelection) {
        newSelect = [image]
      } else {
        newSelect = [...selectedMedia, image]
      }
      setSelectedMedia(newSelect)
      if (onSelectionChange) {
        onSelectionChange(newSelect)
      }
    }
  }

  const handleUploadClick = () => {
    setShowUploader(true)
  }

  const handleUploadSuccess = (imageId) => {
    client.refetchQueries({
      include: ['OrganizationImagesQuery']
    })
  }

  const selectedMediaLength = selectedMedia.length || 0
  const noImageForLocation = query && query.attacheable_id && query.attacheable_type !== 'None' && !images.length

  return (
    <div className='mediaGallery-wrap'>
      <div className='mediaGallery-actionBar'>
        <MediaActionBar
          allowDeleteAll={allowDeleteAll}
          allowFilter={!showUploader && allowFilter}
          allowInsert={!showUploader && allowInsert}
          allowSelection={allowSelection}
          allowSort={!showUploader && allowSort}
          allowUploads={allowUploads}
          allowTableMode={!showUploader && allowTableMode}
          filterByFeature={filterByFeature}
          onDeleteAll={deleteSelectedMedia}
          onDeselectAll={deselectAll}
          onFilter={queryChange}
          onInsertClick={clickInsert}
          onSort={toggleSort}
          onToggleTable={toggleTableMode}
          onUploadClick={handleUploadClick}
          query={query}
          selectedFeature={selectedFeature}
          selectedMediaCount={selectedMediaLength}
          showUploader={showUploader}
          sortOldest={query.direction === 'asc'}
          tableMode={tableMode}
        />
      </div>
      {error && <Alert bsStyle='danger' id='error-alert'>{error}</Alert>}
      {!!noImageForLocation && <Alert bsStyle='danger' id='no-image-alert'>No images attached to this location.</Alert>}
      {uploadError && <Alert bsStyle='danger' id='upload-error-alert'>{uploadError}</Alert>}
      <div className='mediaGallery-content'>
        <div className={'mediaGallery-uploader__wrap ' + (showUploader ? 'mediaGallery-uploader--show' : '')}>
          <Uploader
            allowClose={images.length > 0}
            allowUploads={allowUploads}
            filetype='Image'
            onHide={hideUploader}
            show={showUploader}
            onError={uploadStateError}
            onUploadSuccess={handleUploadSuccess}
          />
        </div>
        {!loading && images.length < 1 &&
          <Panel className='outingsTable-empty'>
            <Panel.Body>
              <h3>No images available.</h3>
            </Panel.Body>
          </Panel>}

        {(!showUploader) && images.length > 0 &&
          <div className='mediaGallery-list__wrap'>
            <MediaList
              allowEdit={allowEdit}
              allowSelection={allowSelection}
              media={images}
              onClick={clickMediaItem}
              onQueryChange={queryChange}
              paging={paging}
              query={query}
              selectedMedia={selectedMedia}
              tableMode={tableMode}
              updating={updating}
              fetching={loading}
            />
          </div>}
      </div>
      {clickedImage &&
        <EditMediaModal
          allowAssociations={allowAssociations}
          deleteText={deleteText}
          model={clickedImage}
          onClose={closeModal}
          onDelete={deleteFromModal}
          onSave={saveMediaForm}
          show={showModal}
          updating={updatingSingle}
        />}
    </div>
  )
}

MediaGallery.propTypes = {
  // props
  allowAssociations: PropTypes.bool, // show image attachments / associations sidebar in single image modal?
  allowDeleteAll: PropTypes.bool,
  allowFilter: PropTypes.bool, // show selection options?
  allowInsert: PropTypes.bool, // is this a child and can you insert images from parent gallery?
  allowSelection: PropTypes.bool, // show selection options?
  allowEdit: PropTypes.bool, // show selection options?
  allowSort: PropTypes.bool, // show selection options?
  allowTableMode: PropTypes.bool,
  allowUploads: PropTypes.bool, // show uploads?
  deleteText: PropTypes.string, // text to display for delete option
  filterByFeature: PropTypes.bool,
  selectedFeature: PropTypes.object, // Force the MediaGallery > ActionBar > MediaGalleryFilter to filter to a specific feature
  singleSelection: PropTypes.bool,
  onSelectionChange: PropTypes.func
}

export default MediaGallery
