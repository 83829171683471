import Label from 'react-bootstrap/lib/Label'
import { Link } from 'react-router-dom'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React from 'react'
import Table from 'react-bootstrap/lib/Table'
import FeatureStatusDropdown from 'components/featureStatusDropdown'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import './outingsTable.css'
import './outingsTableRow.css'

const OutingsTable = ({
  outings,
  match,
  onArchived,
  onDelete,
  onEdit,
  onPublished
}) => {
  return (
    <div className='outingsTable'>
      {(outings.length === 0) &&
        <Panel className='outingsTable-empty'>
          <Panel.Body>
            <h3>No outings available.</h3>
          </Panel.Body>
        </Panel>}
      {(outings.length > 0) &&
        <div>
          <Panel>
            <Table striped>
              <thead>
                <tr>
                  <th className='name'>Name</th>
                  <th className='length'>Length</th>
                  <th>Stewardships</th>
                  <th className='visibility'>Visibility</th>
                  <th>Status</th>
                  <th className='actions'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {outings.map((outing) => {
                  const actions = [
                    { eventKey: 'edit', handleOnClick: () => onEdit(outing), text: 'Edit' },
                    { eventKey: 'delete', handleOnClick: () => onDelete(outing.id, (outing.route && outing.route.id)), text: 'Delete' }
                  ]
                  const archiveAction = { eventKey: 'archived', handleOnClick: () => onArchived(outing), text: 'Archive', value: 'Archived' }
                  const length = outing.route && outing.route.length_miles ? (Number(outing.route.length_miles).toFixed(1).toString() + ' mi') : ''
                  const publishAction = { eventKey: 'published', handleOnClick: () => onPublished(outing), text: 'Publish', value: 'Published' }
                  const viewLink = `${match.url}/${outing.id}`

                  if (outing.visibility === 'Archived') {
                    actions.push(publishAction)
                  } else if (outing.visibility === 'Draft') {
                    actions.push(archiveAction)
                    actions.push(publishAction)
                  } else if (outing.visibility === 'Published') {
                    actions.push(archiveAction)
                  }

                  return (
                    <tr className='outingsTable-tableRow' key={outing.id}>
                      <td className='name'>
                        <Link to={viewLink}>
                          {!!outing.name && outing.name.length > 0 &&
                            <span>{outing.name}</span>}
                          {(!outing.name || !outing.name.length) &&
                            <span className='muted'>Unnamed</span>}
                        </Link>
                      </td>
                      <td className='length'>
                        <span>{length}</span>
                      </td>
                      <td className='stewardships'>
                        {outing.stewardships.map((stewardship) => {
                          return (
                            <Label
                              bsStyle='info'
                              key={stewardship.role}
                            >
                              {stewardship.role}
                            </Label>
                          )
                        })}
                      </td>
                      <td className='visibility'>
                        {(outing.visibility === 'Archived') &&
                          <Label bsStyle='danger'>{outing.visibility.toUpperCase()}</Label>}
                        {(outing.visibility === 'Draft') &&
                          <Label bsStyle='warning'>{outing.visibility.toUpperCase()}</Label>}
                        {(outing.visibility === 'Published') &&
                          <Label bsStyle='info'>{outing.visibility.toUpperCase()}</Label>}
                      </td>
                      <td className='visibility'>
                        <FeatureStatusDropdown feature={outing} bsSize='small' />
                      </td>
                      <td className='actions'>
                        <DropdownButton
                          bsSize='small'
                          id='actions'
                          pullRight
                          title='Actions'
                        >
                          {actions.map((action) => {
                            return (
                              <MenuItem
                                eventKey={action.eventKey}
                                key={action.eventKey}
                                onClick={action.handleOnClick}
                              >
                                {action.text}
                              </MenuItem>
                            )
                          })}
                        </DropdownButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Panel>
        </div>}
    </div>
  )
}

OutingsTable.propTypes = {
  outings: PropTypes.array,
  match: PropTypes.object,
  onArchived: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onPublished: PropTypes.func
}

export default OutingsTable
