import { Form, Formik } from 'formik'
import _ from 'lodash'
import t from 'prop-types'
import { React, useState } from 'react'
import Button from 'react-bootstrap/lib/Button'
import Modal from 'react-bootstrap/lib/Modal'
import Nav from 'react-bootstrap/lib/Nav'
import NavItem from 'react-bootstrap/lib/NavItem'
import Tab from 'react-bootstrap/lib/Tab'
import * as Yup from 'yup'

import { CenteredLoader } from '../../../components/centeredLoader'
import AssociationSet from '../../associationSet'
import FormikGroup from '../../fieldGroup/FormikGroup'
import MediaModal from '../../media/modal/MediaModal'
import PostFormImage from '../postForm/PostFormImage'

import '../postForm/postForm.css'
import './postModal.css'

const PostModal = ({ show, onClose, onDelete, updating, post, onSubmit }) => {
  const [showMediaModal, setShowMediaModal] = useState()
  const [fields, setFields] = useState({ ...post })
  const deletePost = () => {
    onDelete(post.id)
  }

  const attachImages = (images) => {
    let attachments = fields.image_attachments ? [...fields.image_attachments] : []
    // Don't pass in image attachments that already have existing image attachments
    const newImages = images.filter(image => {
      // If found, this is not a new attachment
      return attachments.filter(att => att.image.id === image.id).length === 0
    })

    attachments = [
      ...attachments,
      ...newImages.map(img => ({ image: img }))
    ]

    updateField({ image_attachments: attachments })

    closeMediaModal()
  }

  const closeMediaModal = () => {
    setShowMediaModal(false)
  }

  const handleSave = (values) => {
    onSubmit({ ...fields, ...values })
  }

  const openMediaModal = (media) => {
    setShowMediaModal(true)
  }

  const renderDefaultLocationOptions = (post) => {
    let defaultLocationOptions = []
    if (post.feature_id && post.feature) defaultLocationOptions = [{ label: post.feature.name, value: post.feature_id }]
    else if (post.feature_id) defaultLocationOptions = [{ label: post.feature_id, value: post.feature_id }]
    return defaultLocationOptions
  }

  const removeImage = (image) => {
    let attachments = [...fields.image_attachments]
    // remove any old images that match new IDs
    attachments = _.filter(attachments, (att) => image.id !== att.image.id)

    updateField({ image_attachments: attachments })
  }

  const setLocationFeature = (value) => {
    updateField({ feature_id: value.attacheable_id, feature_type: value.attacheable_type })
  }

  const setTab = (value) => {
    const isAlert = value === 'alert'
    updateField({ is_alert: isAlert })
  }

  const updateField = (field) => {
    setFields({ ...fields, ...field })
  }

  const defaultLocationOptions = renderDefaultLocationOptions(post)
  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      post_text: post && post.post_text ? post.post_text : ''
    },
    onSubmit: values => {
      handleSave(values)
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Yup.object({
      post_text: Yup.string()
        .min(3, 'Too short!')
        .required('Required!')
    })
  }

  return (
    <Formik {...formikProps}>
      {formik => (
        <div className='postModal'>
          <Modal
            enforceFocus={false}
            dialogClassName='postModal-dialog'
            onHide={onClose}
            show={show}
          >
            {updating &&
              <CenteredLoader overlay />}
            <Modal.Body>
              <div className='postForm'>
                <Tab.Container
                  defaultActiveKey={fields.is_alert ? 'alert' : 'post'}
                  id='post-form-tabs'
                  onSelect={setTab}
                >
                  <div>
                    <Nav bsStyle='pills' className='nav-borders'>
                      <NavItem eventKey='post'>Post</NavItem>
                      <NavItem eventKey='alert'>Alert</NavItem>
                    </Nav>
                    <Form className='postForm-form'>
                      <div className='postForm-content'>
                        <div className='postForm--body'>
                          <FormikGroup
                            id='post_text'
                            label='Message'
                            name='post_text'
                            rows='6'
                            type='wysiwyg'
                          />
                        </div>
                        <div className='form-group'>
                          <label className='control-label'>Location</label>
                          <div className='postForm-locationRow__content'>
                            <AssociationSet
                              clearable={false}
                              defaultOptions={defaultLocationOptions}
                              entityTypes={['Features']}
                              onAdd={setLocationFeature}
                              placeholder='Search locations...'
                              restrictToCurrentOrganization
                              value={fields.feature_type ? { class: fields.feature_type, id: fields.feature_id } : null}
                            />
                          </div>
                        </div>
                        <div className='postForm-images form-group'>
                          <label className='control-label'>Images</label>
                          <div className='postForm-featuredImage'>
                            <div className='postForm-featuredImage__controls'>
                              <Button
                                bsSize='small'
                                className='postForm-imageButton'
                                onClick={openMediaModal}
                              >
                                Add Images
                              </Button>
                            </div>
                            {(fields.image_attachments && fields.image_attachments.length > 0) &&
                              <div className='postForm-gallery'>
                                {fields.image_attachments.map((attachment) => {
                                  return (<PostFormImage key={attachment.image.id} image={attachment.image} onRemoveClick={removeImage} />)
                                })}
                              </div>}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Tab.Container>
                {showMediaModal &&
                  <MediaModal
                    allowSelection
                    allowUploads
                    onClose={closeMediaModal}
                    onSubmit={attachImages}
                    show={showMediaModal}
                  />}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!!post.id &&
                <Button
                  bsStyle='primary'
                  className='btn-danger'
                  disabled={updating}
                  onClick={deletePost}
                >
                  Delete post
                </Button>}
              <Button bsStyle='primary' onClick={onClose}>Cancel</Button>
              <Button
                bsStyle='primary'
                disabled={updating}
                onClick={formik.handleSubmit}
              >
                {post.id ? 'Save Post' : 'Add Post'}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </Formik>
  )
}

PostModal.propTypes = {
  formik: t.object,
  post: t.object,
  show: t.bool,
  onClose: t.func,
  onDelete: t.func,
  onSubmit: t.func,
  updating: t.bool
}

export default PostModal
