import { Alert, Button, Glyphicon, Panel } from 'react-bootstrap'
import { gql, useQuery } from '@apollo/client'
import { React } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import fetch from 'node-fetch'
import toast from 'react-hot-toast'

import { CenteredLoader } from 'components/centeredLoader'
import { PageContent, PageHeader, PageLayout } from 'components/pageLayout'
import debugMessage from 'services/Debug'

const getImportQuery = gql`
  query getImport($importId: Int!) {
    shapefile_uploads_by_pk(id: $importId) {
      created_at
      feature_imports(limit: 10) {
        destination_type
        destination_id
      }
      feature_imports_aggregate {
        aggregate {
          count
        }
      }
      geo_import_features(limit: 10) {
        feature_imports {
          destination_id
          destination_type
        }
        properties
      }
      geo_import_features_aggregate {
        aggregate {
          count
        }
      }
      geojson_url
      id
      import_params
      metadata
      name
      object_logs {
        created_at
        message
      }
      preview
      status
    }
  }
`

// TODO: https://www.npmjs.com/package/react-json-view

export const Review = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const importId = match.params.importId
  const { data, loading } = useQuery(getImportQuery, {
    variables: {
      importId: importId
    }
  })
  const importObj = data?.shapefile_uploads_by_pk

  const locationType = (() => {
    switch (importObj?.metadata.geometryType) {
      case 'LineString':
        return 'Trail Segments'
      case 'Point':
        return 'Points of Interest'
      case 'Polygon':
        return 'Areas'
    }
  })()

  const handleStartImport = async (shapefileUploadId) => {
    // TODO: Show loading...

    fetch(`${process.env.REACT_APP_API_SERVICE_URL}/imports/import-features`, {
      body: JSON.stringify({ id: shapefileUploadId }),
      method: 'POST'
    }).then((response) => {
      // TODO: Hide loading...

      if (response.ok) {
        history.push('logs')
      } else {
        debugMessage(response)
        toast.error('The import could not be started. Please try again.')
      }
    })
  }

  return (
    <div className='import-wrap'>
      {loading && <CenteredLoader overlay />}
      <PageLayout>
        <PageHeader>
          <div className='import-header'>
            <div className='import-header__title'>
              <div className='import-header__titleText'>
                <Button
                  bsStyle='link'
                  onClick={() => {
                    history.push(match.path.split(':')[0].slice(0, match.path.split(':')[0].length))
                  }}
                >
                  <Glyphicon glyph='chevron-left' />
                  Back to Imports
                </Button>
                <h1>Step 2 of 3: Review Import</h1>
              </div>
            </div>
          </div>
        </PageHeader>
        <PageContent>
          <Alert bsStyle='warning'>
            <strong>Heads up!</strong> For now, if you don't see information in the panels below, please manually refresh the page and try again.
          </Alert>
          {importObj && (
            <>
              <Panel>
                <Panel.Heading>
                  <Panel.Title>Upload Details</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <h3>Location Type</h3>
                  <pre>{locationType}</pre>
                  <h3>Keys</h3>
                  <pre>{JSON.stringify(importObj.metadata.keys, null, 4)}</pre>
                  <h3>Feature Count</h3>
                  <pre>{importObj.metadata.featureCount}</pre>
                </Panel.Body>
              </Panel>
              <Panel>
                <Panel.Heading>
                  <Panel.Title>Preview</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <p>Importing this dataset will have the following impact:</p>
                  <ul>
                    <li>Create {importObj.preview.counts.creating} {locationType.toLowerCase()}</li>
                    <li>Update {importObj.preview.counts.existing} {locationType.toLowerCase()}</li>
                    <li>Skip {importObj.preview.counts.missing} {locationType.toLowerCase()}</li>
                    {locationType === 'Trail Segments' && (
                      <>
                        <li>Create {importObj.preview.counts.newTrails} trails</li>
                        <li>Update {importObj.preview.counts.existingTrails} trails</li>
                        <li>Skip {importObj.preview.counts.missingTrails} trails</li>
                      </>
                    )}
                  </ul>
                </Panel.Body>
              </Panel>
              {importObj.preview.debug && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>Debug</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <pre>{JSON.stringify(importObj.preview.debug, null, 4)}</pre>
                  </Panel.Body>
                </Panel>
              )}
              <div style={{
                textAlign: 'center'
              }}
              >
                <button
                  className='btn btn-secondary'
                  onClick={() => {
                    const backPath = match.path.split(':')[0].slice(0, match.path.split(':')[0].length - 1)
                    history.push(`${backPath}/${data.shapefile_uploads_by_pk.id}/configure`)
                  }}
                  style={{ marginRight: '10px' }}
                >
                  Back to Configure
                </button>
                <Button
                  bsStyle='primary'
                  onClick={() => handleStartImport(data.shapefile_uploads_by_pk.id)}
                >
                  Start Import & Go to Logs
                </Button>
              </div>
            </>
          )}
        </PageContent>
      </PageLayout>
    </div>
  )
}
