import PropTypes from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import './associationSet.css'
import EntitySelect from './EntitySelect'

const AssociationSet = (props) => {
  const {
    associations = [],
    onRemove,
    isMultipleAssociationSet = false,
    allowAdd = true
  } = props

  return (
    <div className='associationSet'>
      {isMultipleAssociationSet && associations.length > 0 &&
        <div className='associationSet-list'>
          {associations.map((association) => {
            return (
              <div className='associationSet-listItem' key={association.id || association.contentId}>
                <div className='associationSet-listItem-content'>
                  <div>
                    <p>
                      {association.contentName || (association.feature && association.feature.name)}
                    </p>
                  </div>
                  {association.feature &&
                    <div className='associationSet-listItem-content-sub'>
                      <p>
                        {(association.feature.class_name === 'PointOfInterest' ? 'POI' : association.feature.class_name || association.feature_type) + (association.feature.area && association.feature.area.name ? (' in ' + association.feature.area.name) : '')}
                      </p>
                    </div>}
                </div>
                <div className='associationSet-listItem__actions'>
                  <div className='associationSet-listItem__actions-wrapper'>
                    <Button bsStyle='link' bsSize='xsmall' onClick={() => onRemove(association)}>
                      <Glyphicon glyph='remove' />
                    </Button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>}
      {allowAdd &&
        <EntitySelect
          {...props}
        />}
    </div>
  )
}

AssociationSet.propTypes = {
  allowAdd: PropTypes.bool,
  associations: PropTypes.array, // WITH the single prop set to true, this list of associations already set will be displayed
  defaultOptions: PropTypes.array, // list of bootstrapped options, used for values that were previously selected
  entityTypes: PropTypes.array, // Array of strings identifying each individual entity type to include in the EntitySelect results
  // If the array is empty or missing, we will search all EntityTypes
  isMultipleAssociationSet: PropTypes.bool, // allow for multiple select or single select?
  noResultsMessage: PropTypes.string, // Override of the noOptionsMessage logic if sending in more than one entityType
  onRemove: PropTypes.func, // Remove (option) callback
  onAdd: PropTypes.func, // Remove (option) callback
  placeholder: PropTypes.string, // text placeholder for input
  staySelected: PropTypes.bool,
  value: PropTypes.any // value of input, maps to value field on options
}

export default AssociationSet
