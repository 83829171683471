import { gql, useApolloClient, useMutation } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import { React, useEffect } from 'react'
import Nav from 'react-bootstrap/lib/Nav'
import NavItem from 'react-bootstrap/lib/NavItem'
import { LinkContainer } from 'react-router-bootstrap'
import { Redirect, Route, withRouter } from 'react-router-dom'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { Content, ContentLayout, ContentSidebar } from '../../../components/contentLayout'
import './organizationProfile.css'
import OrganizationProfileContact from './OrganizationProfileContact'
import OrganizationProfileOverview from './OrganizationProfileOverview'
import OrganizationProfileSocial from './OrganizationProfileSocial'
import { capitalCase } from 'change-case'

const socialFields = [
  'facebook',
  'instagram',
  'linkedin',
  'pinterest',
  'twitter',
  'flickr',
  'youtube',
  'vimeo',
  'donate',
  'newsletter'
]

const UpdateOrganizationQuery = gql`
  mutation UpdateOrganizationQuery($id: Int!, $address: String , $email: String , $logo_image_id: Int, $phone: String , $website: String, $description: String, $time_zone: String, $name: String ) {
    update_organizations_by_pk(pk_columns: {id: $id}, _set: {
      address: $address,
      description: $description,
      email: $email,
      logo_image_id: $logo_image_id,
      name: $name,
      phone: $phone,
      time_zone: $time_zone,
      website: $website
    }) {
      address
      description
      email
      id
      logo_image_id
      name
      phone
      time_zone
      updated_at
      website
    }
  }
`

const InsertWebLinkQuery = gql`
  mutation InsertWebLinkQuery($name: String!, $platform: String!, $url: String!) {
    insert_web_links(objects: {platform: $platform, url: $url, name: $name}) {
      affected_rows
      returning {
        id
        platform
        url
        name
      }
    }
  }
`

const InsertWebLinkAttachmentQuery = gql`
  mutation InsertWebLinkAttachmentQuery($attached_id: Int!, $attached_type: String!, $feature_id: Int!, $feature_type: String!) {
    insert_attachments(objects: {attached_id: $attached_id, attached_type: $attached_type, feature_id: $feature_id, feature_type: $feature_type}) {
      affected_rows
      returning {
        attached_id
        attached_type
        created_at
        feature_id
        feature_type
        id
        updated_at
      }
    }
  }
`

const DeleteWebLinkMutation = gql`
  mutation DeleteWebLinkMutation($web_link_id: Int!, $organization_id: Int!) {
    delete_web_links_by_pk(id: $web_link_id) {
      id
    }
    delete_attachments(where: {feature_type: {_eq: "Organization"}, feature_id: {_eq: $organization_id}, attached_type: {_eq: "WebLink"}, attached_id: {_eq: $web_link_id}}){
      affected_rows
      returning {
        id
      }
    }
  }
`

const UpdateWebLinkMutation = gql`
  mutation UpdateWebLinkMutation($id: Int!, $url: String!) {  
    update_web_links_by_pk(pk_columns: {id: $id}, _set: {url: $url}) {
      url
      id
      name
      platform
    }
  }
`

const OrganizationProfile = (props) => {
  const organization = useCurrentOrganization()
  const client = useApolloClient()
  const [updateOrganizationMutation] = useMutation(UpdateOrganizationQuery)
  const [insertWebLinkMutation] = useMutation(InsertWebLinkQuery)
  const [insertWebLinkAttachmentMutation] = useMutation(InsertWebLinkAttachmentQuery)
  const [deleteWebLinkMutation] = useMutation(DeleteWebLinkMutation)
  const [updateWebLinkMutation] = useMutation(UpdateWebLinkMutation)
  const websites = Object.fromEntries(organization.web_link_attachments.map(item => {
    return [item.web_link.platform.toLowerCase(), item.web_link.url]
  }))

  const saveWebsites = (values) => {
    const websiteValues = Object.entries(values).filter(([key, value]) => { return socialFields.includes(key) })
    const websiteUpdates = organization.web_link_attachments.filter((attachment) => { return websiteValues.map(item => item[0]).includes(attachment.web_link.platform.toLowerCase()) })
    const existingWebsites = Object.fromEntries(organization.web_link_attachments.map((attachment) => { return [attachment.web_link.platform.toLowerCase(), attachment.web_link.url] }))
    const websiteUpdateData = websiteUpdates.map((item) => {
      return {
        id: item.web_link.id,
        platform: item.web_link.platform.toLowerCase(),
        url: websiteValues.filter(([key, value]) => key === item.web_link.platform.toLowerCase())[0][1]
      }
    }).filter(item => (item.url !== existingWebsites[item.platform]) && item.url !== '')
    const websiteInserts = websiteValues.filter(([key, value]) => { return !Object.entries(websites).map(item => item[0]).includes(key) })
    const websiteDeletes = websiteValues.filter(([key, value]) => { return Object.keys(existingWebsites).includes(key) && !value }).map(item => item[0])
    const websiteDeleteData = organization.web_link_attachments.filter(attachment => { return websiteDeletes.includes(attachment.web_link.platform.toLowerCase()) })
    websiteDeleteData.forEach((item) => {
      deleteWebLinkMutation({
        variables: {
          web_link_id: item.web_link.id,
          organization_id: organization.id
        }
      })
    })
    websiteInserts.forEach((item) =>
      insertWebLinkMutation({
        variables: {
          name: capitalCase(item[0]),
          platform: item[0],
          url: item[1]
        }
      }).then((data) => {
        const id = data.data.insert_web_links.returning[0].id
        insertWebLinkAttachmentMutation({
          variables: {
            attached_type: 'WebLink',
            attached_id: id,
            feature_type: 'Organization',
            feature_id: organization.id
          }
        })
      })
    )
    websiteUpdateData.forEach((item) => {
      updateWebLinkMutation({
        variables: {
          id: item.id,
          url: item.url
        }
      })
    })
  }

  const saveForm = (values) => {
    saveWebsites(values)
    updateOrganizationMutation({
      variables: {
        id: organization.id,
        ...values
      }
    }).then(() => {
      toast.success('Saved Organization')
      client.refetchQueries({
        include: ['GetOrganizationById']
      })
    })
    client.refetchQueries({
      include: ['GetOrganizationById']
    })
  }

  const { isOrganizationAdmin, isSuperAdmin, match } = props

  const tabsProps = {
    isOrganizationAdmin: isOrganizationAdmin,
    isSuperAdmin: isSuperAdmin,
    organization: organization
  }

  const websiteValidations = Object.fromEntries(socialFields.map((item) => {
    return [item, Yup.string().url('Please enter a valid web URL. Must start with http:// or https://.')]
  }))

  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      address: organization.address || '',
      description: organization.description || '',
      email: organization.email || '',
      logo_image_id: organization.logo_image_id,
      name: organization.name || '',
      phone: organization.phone || '',
      time_zone: organization.time_zone || '',
      website: organization.website || '',
      ...websites
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email address.')
        .nullable(),
      name: Yup.string()
        .max(200, 'Must be 200 characters or less.')
        .required('An organization name is required.'),
      website: Yup.string()
        .url('Please enter a valid web URL. Must start with "http://" or "https://".')
        .nullable(),
      ...websiteValidations
    }),
    onSubmit: values => {
      saveForm(values)
    }
  }

  useEffect(() => {
    document.title = `Profile | Organization | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  return (
    <ContentLayout className='organizationInfo-wrap'>
      <ContentSidebar>
        <Nav bsStyle='pills' stacked>
          <LinkContainer to={`${match.url}/overview`} exact>
            <NavItem>Overview</NavItem>
          </LinkContainer>
          <LinkContainer to={`${match.url}/contact-information`} exact>
            <NavItem>Contact Information</NavItem>
          </LinkContainer>
          <LinkContainer to={`${match.url}/social-outreach`} exact>
            <NavItem>Social Outreach</NavItem>
          </LinkContainer>
        </Nav>
      </ContentSidebar>
      <Content>
        <Formik {...formikProps}>
          {formik => (
            <Form>
              <div className='organizationInfo-content'>
                <div>
                  <Route
                    exact
                    path={`${match.url}`}
                    render={() => <Redirect to={`${match.url}/overview`} />}
                  />
                  <Route
                    exact
                    path={`${match.url}/overview`}
                    render={() => <OrganizationProfileOverview {...tabsProps} />}
                  />
                  <Route
                    exact
                    path={`${match.url}/contact-information`}
                    render={() => <OrganizationProfileContact {...tabsProps} />}
                  />
                  <Route
                    exact
                    path={`${match.url}/social-outreach`}
                    render={() => <OrganizationProfileSocial {...tabsProps} />}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Content>
    </ContentLayout>
  )
}

OrganizationProfile.propTypes = {
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  match: PropTypes.object
}

export default withRouter(OrganizationProfile)
