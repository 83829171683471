import { Well } from 'react-bootstrap'
import React, {useState, useEffect} from 'react'
import { useRouteMatch } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

export function RevokeParentalConsent() {
  const match = useRouteMatch()
  const uuid = match.params.uuid
  const [status, setStatus] = useState()

  useEffect(() => {
    const revokeUrl = `${process.env.REACT_APP_API_SERVICE_URL}/parental-consent/revoke`
    console.log(revokeUrl)
     fetch(revokeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ uuid })
    }).then((response) => {
      console.log(response)
      setStatus('revoked')
    }).catch((error) => {
      console.error(error)
      setStatus('error')
    })

  }, [status])

  if (status === 'revoked') {
    return (
      <div className='errorView'>
        <Well>
          <div className='errorView-container' style={{ textAlign: 'left' }}>
            <h2>Revoked</h2>
            <p>You have successfully revoked the parental consent for the account.</p>
          </div>
        </Well>
      </div>
    )
  } else {
    return (
      <div className='errorView'>
        <Well>
        
          <div className='errorView-container' style={{ textAlign: 'left' }}>
            <h2>Revoking...</h2>
            <p>Revoking the parental consent for the account.</p>
          </div>
        </Well>
      </div>
    )
  }
}
