import PropTypes from 'prop-types'
import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = (props) => {
  const handleOnSelect = (e) => {
    const { handleOnSelect } = props
    handleOnSelect(e.selected + 1)
  }

  const { activePage, numberOfPages } = props

  return (
    <ReactPaginate
      activeClassName='active'
      breakClassName='disabled'
      breakLabel='...'
      containerClassName='pagination'
      disableInitialCallback
      initialPage={activePage - 1}
      marginPagesDisplayed={1}
      nextLabel='›'
      onPageChange={handleOnSelect}
      pageCount={numberOfPages}
      pageRangeDisplayed={4}
      previousLabel='‹'
    />
  )
}

Pagination.propTypes = {
  activePage: PropTypes.number,
  handleOnSelect: PropTypes.func,
  numberOfPages: PropTypes.number
}

export default Pagination
