import { compareDesc, format } from 'date-fns'
import { useMutation, useQuery, gql } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Panel from 'react-bootstrap/lib/Panel'
import Table from 'react-bootstrap/lib/Table'
import toast from 'react-hot-toast'

import { CenteredLoader } from '../../../../components/centeredLoader'
import { Queries } from '../MembershipsOperations'
import { segmentTrack } from '../../../../components/segment'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useCurrentUser } from 'contexts/CurrentUserContext'

import './memberships.css'

const propTypes = {
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

const ApproveMembershipRequest = gql`
  mutation ApproveMembershipRequest($request_id: Int!, $user_id: Int!, $organization_id: Int!, $role: String! = "staff") {
    insert_memberships_one(object: {
      organization_id: $organization_id,
      role: $role,
      user_id: $user_id
    }) {
      id
      created_at
      organization_id
      user_id
    }
    update_membership_requests_by_pk(pk_columns: {
      id: $request_id
    }, _set: {
      status: "Approved"
    }) {
      id
      organization {
        id
        name
      }
      user {        
        id
        profile {
          name
        }
      }
    }
  }
`

const DenyMembershipRequest = gql`
  mutation ApproveMembershipRequest($request_id: Int! ) {
    update_membership_requests_by_pk(pk_columns: {
      id: $request_id
    }, _set: {
      status: "Denied"
    }) {
      id
      organization {
        id
        name
      }
      user {        
        id
        profile {
          name
        }
      }
    }
  }
`

const MembershipRequests = ({ isOrganizationAdmin, isSuperAdmin }) => {
  const me = useCurrentUser()
  const organization = useCurrentOrganization()
  const [approveMembershipRequest] = useMutation(ApproveMembershipRequest, {
    refetchQueries: [
      Queries.GetMembershipsByOrganizationId,
      Queries.GetMembershipRequestsByOrganizationId
    ]
  })
  const [denyMembershipRequest] = useMutation(DenyMembershipRequest, {
    refetchQueries: [
      Queries.GetMembershipRequestsByOrganizationId
    ]
  })
  const { data, error, loading } = useQuery(Queries.GetMembershipRequestsByOrganizationId, {
    fetchPolicy: 'no-cache',
    skip: (organization === null || organization.id === null),
    variables: {
      organizationId: (organization === null) ? -1 : organization.id
    }
  })
  const sortedRequests = data ? data.membership_requests.sort((a, b) => compareDesc(a.created_at, b.created_at)) : []

  const handleApproveClick = (request) => {
    approveMembershipRequest({
      variables: {
        organization_id: request.organization_id,
        request_id: request.id,
        user_id: request.user_id
      }
    })
      .then((callbackData) => {
        toast.success('Membership request approved.')
      })
      .catch(() => {
        toast.error('Membership request approval failed. Please try again.')
      })
  }

  const handleDenyClick = (request) => {
    if (isOrganizationAdmin || isSuperAdmin) {
      const conf = window.confirm('Are you sure you want to deny this request?')
      const requestId = request.id

      if (conf) {
        denyMembershipRequest({
          variables: { request_id: requestId }
        })
          .then((callbackData) => {
            toast.success('Membership request denied.')
          })
          .catch(() => {
            toast.error('Membership request denial failed. Please try again.')
          })
      }
    } else {
      handleViewHelpClick()
    }
  }

  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '68', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/68-managing-your-organization' }).click()
    }
  }

  return (
    <div className='organizationMembers'>
      {(sortedRequests.length === 0) &&
        <Panel>
          <Panel.Body>
            <p>There are no membership requests for your organization. Once someone requests membership, their request will show up here.</p>
          </Panel.Body>
        </Panel>}
      {(sortedRequests.length > 0) &&
        <div className='organizationMembers-list'>
          <Panel>
            <Table striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Requested</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              {loading &&
                <tbody>
                  <tr>
                    <td colSpan='4'>
                      <CenteredLoader />
                    </td>
                  </tr>
                </tbody>}
              {(!loading && error) &&
                <tbody>
                  <tr>
                    <td colSpan='4'>
                      <p>Error</p>
                    </td>
                  </tr>
                </tbody>}
              {(!loading && !error) &&
                <tbody>
                  {sortedRequests.map((request, index) => {
                    const name = request.user.profile.name ? request.user.profile.name : 'none'
                    const nameStyle = (name !== 'none') ? '' : 'none'
                    const isOuterSpatialStaff = request.user_email.email.indexOf('outerspatial.com') > -1

                    return (
                      <tr key={index}>
                        <td className={nameStyle}>
                          <span>{name}</span>
                        </td>
                        <td className='organizationMembers--email'>
                          {request.user_email.email}
                        </td>
                        <td>
                          {format(new Date(request.created_at), "LLL d, yyyy 'at' h':'maaa")}
                        </td>
                        <td>
                          {request.status}
                        </td>
                        <td className='organizationMembers--actions'>
                          {((isSuperAdmin || (isOrganizationAdmin && !isOuterSpatialStaff)) && (request.status === 'Requested')) &&
                            <DropdownButton title='Actions' id='actions' bsSize='small' pullRight>
                              <MenuItem eventKey='approve' onClick={() => handleApproveClick(request)}>Approve</MenuItem>
                              <MenuItem eventKey='deny' onClick={() => handleDenyClick(request)}>Deny</MenuItem>
                            </DropdownButton>}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>}
            </Table>
          </Panel>
        </div>}
    </div>
  )
}

MembershipRequests.propTypes = propTypes

export default MembershipRequests
