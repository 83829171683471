import _ from 'lodash'

const featureTypes = [
  {
    singular: 'Trail',
    plural: 'Trails',
    classSingular: 'Trail',
    __typename: 'trails'
  },
  {
    singular: 'Point of Interest',
    plural: 'Points of Interest',
    classSingular: 'PointOfInterest',
    __typename: 'points_of_interest'
  },
  {
    singular: 'Area',
    plural: 'Areas',
    classSingular: 'Area',
    __typename: 'areas'
  },
  {
    singular: 'Outing',
    plural: 'Outings',
    classSingular: 'Outing',
    __typename: 'outings'
  }
]

const entityTypes = [
  {
    singular: 'Challenge',
    plural: 'Challenges',
    classSingular: 'Challenge',
    __typename: 'challenges'
  },
  {
    singular: 'Community',
    plural: 'Communities',
    classSingular: 'Community',
    __typename: 'communities'
  },
  {
    singular: 'Event',
    plural: 'Events',
    classSingular: 'Event',
    __typename: 'events'
  },
  {
    singular: 'Article',
    plural: 'Articles',
    classSingular: 'ContentBundle',
    __typename: 'content_bundles'
  },
  {
    singular: 'Organization',
    plural: 'Organizations',
    classSingular: 'Organization',
    __typename: 'organizations'
  },
  ...featureTypes
]

export const featureByTypename = type => _.find(featureTypes, { __typename: type })
export const entityByTypename = type => _.find(entityTypes, { __typename: type })
