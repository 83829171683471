import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import Panel from 'react-bootstrap/lib/Panel'
import Table from 'react-bootstrap/lib/Table'

import Pagination from '../../../../components/pagination'
import DocumentsTableRow from './DocumentsTableRow'
import DocumentsTableRowUpload from './DocumentsTableRowUpload'
import './documentsTable.css'

const SortableTH = ({ field, label, onChangeQuery, query }) => {
  return (
    <th className={`documentsTable--${field}`}>
      <Button
        bsStyle='link'
        onClick={() => onChangeQuery({
          order: field,
          direction: query.direction === 'asc' ? 'desc' : 'asc'
        }
        )}
      >
        {label}
        {query.order === field && query.direction !== 'desc' &&
          <Glyphicon glyph='chevron-up' />}
        {query.order === field && query.direction === 'desc' &&
          <Glyphicon glyph='chevron-down' />}
      </Button>
    </th>
  )
}

SortableTH.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onChangeQuery: PropTypes.func,
  query: PropTypes.object
}

const DocumentsTable = (props) => {
  const {
    activeUploads,
    documents,
    feature,
    match,
    onDelete,
    onEdit,
    onPageChange,
    onChangeQuery,
    paging,
    query
  } = props
  const showEmpty = !documents.length && !activeUploads.length
  const showTable = (!!documents.length || !!activeUploads.length)

  return (
    <div className='documentsTable'>
      {showEmpty &&
        <Panel className='documentsTable-empty'>
          <Panel.Body>
            <h3>No documents available.</h3>
          </Panel.Body>
        </Panel>}
      {showTable &&
        <div>
          <Panel>
            <Table striped>
              <thead>
                <tr>
                  <SortableTH
                    field='name'
                    label='Name'
                    onChangeQuery={onChangeQuery}
                    query={query}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {activeUploads.map((upload) => {
                  return (
                    <DocumentsTableRowUpload
                      key={upload.tempId}
                      upload={upload}
                    />
                  )
                })}
                {documents.map((document) => {
                  return (
                    <DocumentsTableRow
                      key={document.id}
                      {...{
                        document,
                        feature,
                        match,
                        onDelete,
                        onEdit
                      }}
                    />
                  )
                })}
              </tbody>
            </Table>
          </Panel>
          {paging && paging.total_pages && paging.total_pages > 1 &&
            <div className='documentsTable-pagination'>
              <Pagination
                activePage={parseInt(paging.current_page, 10)}
                handleOnSelect={onPageChange}
                numberOfPages={paging.total_pages}
              />
            </div>}
        </div>}
    </div>
  )
}

DocumentsTable.propTypes = {
  activeUploads: PropTypes.array,
  documents: PropTypes.array,
  feature: PropTypes.object,
  match: PropTypes.object,
  onChangeQuery: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onPageChange: PropTypes.func,
  query: PropTypes.object,
  paging: PropTypes.object
}

export default DocumentsTable
