/* globals L */

import { gql } from '@apollo/client'
import _ from 'lodash'
import React from 'react'
import t from 'prop-types'

import * as controls from './CustomControls'
import STATES from 'services/States'

import './outingRouteMap.css'

const defaultProps = {}
const propTypes = {
  client: t.object,
  communities: t.array,
  focusedWaypoint: t.object,
  geojson: t.object,
  onAddWaypoint: t.func,
  onDeleteWaypoint: t.func,
  onDeselectWaypoint: t.func,
  onMoveWaypoint: t.func.isRequired,
  onSelectWaypoint: t.func,
  onUpdateRoute: t.func,
  organization: t.object,
  waypoints: t.array
}

class OutingRouteMap extends React.Component {
  constructor (props) {
    super(props)

    this.addMode = false
    this.clicks = 0
    this.cursorLine = null
    this.cursorMarker = null
    this.editMode = false
    this.element = null
    this.endMarker = null
    this.isCircleClickEvent = false
    this.locationsLayerGroup = null
    this.routeOverlay = null
    this.startMarker = null
    this.waypointsLayerGroup = null
  }

  buildLocationPopup = (feature, layer) => {
    const { onAddWaypoint, onSelectWaypoint } = this.props
    const content = document.createElement('div')
    const me = this
    const properties = feature.properties
    content.innerHTML = `
      <div class="addWaypointPopup">
        <h4>${properties.name ? properties.name : 'Unnamed'}</h4>
        <h5>${properties.point_type ? properties.point_type : 'POI'}${properties.area && properties.area.name ? (' in ' + properties.area.name) : ''}</h5>
        <hr/>
        <button class="btn btn-block btn-coral onAddWaypointButton">Add Stop</button>
      </div>
    `
    content.getElementsByClassName('onAddWaypointButton')[0].onclick = (e) => {
      e.preventDefault()
      // Force CRS on geometry in case the supplied waypoint is from static data and missing it
      var geometry = feature ? { geometry: feature.geometry } : null
      if (geometry) {
        geometry = {
          ...geometry,
          crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::4326' } }
        }
      }

      onAddWaypoint({
        name: null,
        description: null,
        feature: { ...feature.properties, geojson: feature.geometry, __typename: 'points_of_interest', location: { geometry: feature.geometry } },
        feature_id: feature.properties.id,
        feature_type: feature.properties.class_name,
        location: geometry
      }, (waypointId) => {
        onSelectWaypoint(waypointId)
      })
      me.map.closePopup()
    }

    return L.popup({
      minWidth: 300,
      offset: [
        1,
        -3
      ]
    }).setContent(content)
  }

  buildLocationsLayer = (resource, geojson) => {
    const me = this

    return L.geoJSON(geojson, {
      pointToLayer: (feature, latLng) => {
        return L.circleMarker(latLng, {
          color: 'transparent',
          fillColor: 'transparent',
          fillOpacity: 0.0,
          opacity: 0,
          radius: 10,
          weight: 0
        }).on('click', (e) => {
          const latlng = e.latlng

          this.clicks = 0

          setTimeout(function () {
            if (!me.clicks) {
              me.isCircleClickEvent = true

              me.map
                .closePopup()
                .panTo(latlng) // TODO: Use offset as well
                .openPopup(me.buildLocationPopup(feature, e.target), latlng)
            }
          }, 200)
        })
      }
    })
  }

  buildTrailSegmentLayer = (geojson) => {
    return L.geoJSON(geojson)
  }

  buildWaypointsLayer = (waypoints) => {
    let index = 0
    const me = this

    return L.geoJSON({
      features: waypoints
        .filter(waypoint => {
          return (waypoint.feature && waypoint.feature.location && waypoint.feature.location.geometry) || (waypoint.location && waypoint.location.geometry)
        })
        .map(waypoint => {
          let geometry = null

          if (waypoint.feature && waypoint.feature.location && waypoint.feature.location.geometry) {
            geometry = waypoint.feature.location.geometry
          } else if (waypoint.location && waypoint.location.geometry) {
            geometry = waypoint.location.geometry
          }

          return { ...geometry, ...{ properties: waypoint } }
        }),
      type: 'FeatureCollection'
    }, {
      onEachFeature: (feature, layer) => {
        index = index + 1

        layer
          .on('click', () => {
            const latLng = layer.getLatLng()
            const point = me.map.latLngToLayerPoint(latLng)

            point.x = point.x + 2
            point.y = point.y - 38

            me.map
              .closePopup()
              .panTo(latLng) // TODO: Use offset as well
              .openPopup(me.buildWaypointPopup(feature, layer), me.map.layerPointToLatLng(point))
          })
          .setIcon(L.icon({
            iconAnchor: [
              12.5,
              35
            ],
            iconSize: [
              27,
              38
            ],
            iconUrl: `${process.env.PUBLIC_URL}/map-markers/waypoint-${index}@2x.png`,
            popupAnchor: [
              1,
              -38
            ]
          }))
      }
    })
  }

  buildWaypointPopup = (feature) => {
    const { onDeleteWaypoint, onSelectWaypoint, waypoints } = this.props
    const content = document.createElement('div')
    const map = this.map
    let area
    let name
    let pointType = 'Unattached Stop'

    for (let i = 0; i < waypoints.length; ++i) {
      const waypoint = waypoints[i]

      if (feature.properties.id === waypoint.id) {
        if (waypoint.name) {
          name = waypoint.name
        } else if (waypoint.feature) {
          name = waypoint.feature.name
        }

        if (waypoint.feature) {
          if (waypoint.feature.point_type) {
            pointType = waypoint.feature.point_type
          } else {
            pointType = 'POI'
          }

          if (waypoint.feature.area && waypoint.feature.area.name) {
            area = waypoint.feature.area.name
          }
        }

        if (!name) {
          name = 'Unnamed'
        }

        break
      }
    }

    content.innerHTML = `
      <div class="content" style="padding:20px;">
        <h4>${name || 'Unnamed'}</h4>
        <h5>${pointType || 'POI'}${area ? (' in ' + area) : ''}</h5>
        <hr/>
        <button class="btn btn-block btn-coral onEditWaypointButton">Edit Stop</button>
        <button class="btn btn-block btn-coral onRemoveWaypointButton">Remove Stop</button>
      </div>
    `
    content.getElementsByClassName('onEditWaypointButton')[0].onclick = (e) => {
      e.preventDefault()
      map.closePopup()
      onSelectWaypoint(feature.properties.id)
    }
    content.getElementsByClassName('onRemoveWaypointButton')[0].onclick = (e) => {
      e.preventDefault()
      map.closePopup()

      if (window.confirm('Are you sure you want to permanently delete this stop?')) {
        onDeleteWaypoint(feature.properties.id)
      }
    }

    return L.popup({
      minWidth: 300
    }).setContent(content)
  }

  createMapClickPopup = (map, e) => {
    const { onAddWaypoint, onSelectWaypoint } = this.props
    const content = document.createElement('div')
    const latLng = e.latlng
    const lat = latLng.lat
    const lng = latLng.lng

    content.innerHTML = `
      <div class="addWaypointPopup">
        <h4>${lat.toFixed(3)}, ${lng.toFixed(3)}</h4>
        <hr/>
        <button class="btn btn-block btn-coral onAddWaypointButton">Add Stop</button>
      </div>
    `
    content.getElementsByClassName('onAddWaypointButton')[0].onclick = (e2) => {
      e2.preventDefault()

      const waypoint = {
        description: null,
        location: {
          geometry: {
            coordinates: [
              lng,
              lat
            ],
            crs: {
              type: 'name',
              properties: {
                name: 'urn:ogc:def:crs:EPSG::4326'
              }
            },
            type: 'Point'
          }
        },
        name: `New Stop (${lat.toPrecision(6)}, ${lng.toPrecision(6)})`
      }

      onAddWaypoint(waypoint, (innerWaypoint) => {
        onSelectWaypoint(innerWaypoint)
      })
      map.closePopup()
    }

    return L.popup({
      minWidth: 300
    }).setContent(content)
  }

  handleAddPoint = (coord) => {
    let updatedCoordinates = null

    if (this.props.geojson?.coordinates?.length > 0) {
      updatedCoordinates = [...this.props.geojson.coordinates, coord]
    } else {
      updatedCoordinates = [coord]
    }

    const { onUpdateRoute } = this.props
    onUpdateRoute({
      coordinates: updatedCoordinates,
      type: 'LineString'
    })
  }

  handleRemoveFromEnd = () => {
    const { onUpdateRoute } = this.props

    onUpdateRoute({
      coordinates: this.props.geojson.coordinates.slice(0, -1),
      type: 'LineString'
    })
  }

  handleRemoveFromStart = () => {
    const { onUpdateRoute } = this.props

    onUpdateRoute({
      coordinates: this.props.geojson.coordinates.slice(1),
      type: 'LineString'
    })
  }

  handleToggleAdd = () => {
    const el = document.getElementsByClassName('leaflet-control-plus')[0].childNodes[0]
    const hasClass = L.DomUtil.hasClass(el, 'pressed')

    if (this.editMode) {
      this.handleToggleEdit()
    }

    this.addMode = !this.addMode

    if (!this.addMode) {
      if (hasClass) {
        L.DomUtil.removeClass(el, 'pressed')
      }

      if (this.cursorLine) {
        this.cursorLine.removeFrom(this.map)
      }

      if (this.cursorMarker) {
        this.cursorMarker.removeFrom(this.map)
      }
    } else if (!hasClass) {
      L.DomUtil.addClass(el, 'pressed')
    }
  }

  handleToggleEdit = () => {
    const el = document.getElementsByClassName('leaflet-control-pencil')[0].childNodes[0]
    const hasClass = L.DomUtil.hasClass(el, 'pressed')

    if (this.addMode) {
      this.handleToggleAdd()
    }

    this.editMode = !this.editMode
    this.routeOverlay.pm.toggleEdit({
      draggable: true
    })

    if (this.editMode && !hasClass) {
      L.DomUtil.addClass(el, 'pressed')
    } else if (!this.editMode && hasClass) {
      L.DomUtil.removeClass(el, 'pressed')
    }
  }

  handleUpdateRoute = (geojson) => {
    const { onUpdateRoute } = this.props

    onUpdateRoute(geojson)
  }

  initializeRouteOverlay = (geojson) => {
    return new L.GeoJSON(geojson, {
      style: () => {
        return {
          color: '#ef6643',
          opacity: 0.8,
          weight: 5
        }
      }
    })
  }

  loadCommunityLocations = (id) => {
    const me = this
    const { waypoints } = this.props

    if (!this.locationsLayerGroup) {
      this.locationsLayerGroup = L.featureGroup()
    }
    const CommunityPointOfInterestQuery = gql`
      query CommunityPointOfInterestQuery($communityIds: [Int!] = [1]) {
        community_points_of_interest(where: {community_id: {_in: $communityIds}}) {
          point_of_interest {
            area {
              name
            }
            id
            name
            location {
              geometry
            }
            point_of_interest_type {
              name
            }
          }
        }
      }
    `
    this.props.client.query({
      query: CommunityPointOfInterestQuery,
      variables: {
        communityIds: [id]
      }
    }).then(({ data }) => {
      const features = data.community_points_of_interest.map((item) => {
        const poi = item.point_of_interest
        const properties = {
          id: poi.id,
          name: poi.name,
          point_type: poi.point_of_interest_type?.name || 'poi'
        }
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: poi.location.geometry.coordinates
          },
          properties: properties
        }
      })
      const featureCollection = {
        type: 'FeatureCollection',
        features: features
      }
      me.buildLocationsLayer('points_of_interest', featureCollection).addTo(this.locationsLayerGroup)
      if (this.locationsLayerGroup) {
        this.locationsLayerGroup.addTo(me.map)
        me.loadWaypointsLayer(waypoints)
      }
    })
  }

  loadLocationLayers = () => {
    const me = this
    const { communities } = this.props

    // TODO: You should really give the user the ability to choose between their organization and all the communities.
    if (communities.length > 0) {
      if (communities.length === 1) {
        const community = communities[0]

        if (STATES.indexOf(community.name) === -1) {
          // Sponsored community.
          this.loadCommunityLocations(community.id)
        } else {
          // Global community.
          this.loadOrganizationLocations()
        }
      } else {
        let loaded = false

        communities.forEach((community) => {
          if (!loaded && STATES.indexOf(community.name) === -1) {
            // Sponsored community.
            me.loadCommunityLocations(community.id)
            loaded = true
          }
        })

        if (!loaded) {
          // Global community.
          this.loadOrganizationLocations()
        }
      }
    } else {
      this.loadOrganizationLocations()
    }
  }

  loadOrganizationLocations = () => {
    const OrganizationPointOfInterestQuery = gql`
      query OrganizationPointOfInterestQuery($organizationIds: [Int!] = [6689]) {
        organization_point_of_interest_stewardships(where: {organization_id: {_in: $organizationIds}}) {
          point_of_interest {
            area {
              name
            }
            id
            name
            location {
              geometry
            }
            point_of_interest_type {
              name
            }
          }
        }
      }
    `

    const { waypoints } = this.props
    const me = this

    if (!this.locationsLayerGroup) {
      this.locationsLayerGroup = L.featureGroup()
    }

    this.props.client.query({
      query: OrganizationPointOfInterestQuery,
      variables: {
        organizationIds: [this.props.organization.id]
      }
    }).then(({ data }) => {
      const features = data.organization_point_of_interest_stewardships
        .filter((item) => {
          return item.point_of_interest !== null
        })
        .map((item) => {
          const poi = item.point_of_interest
          const properties = {
            id: poi.id,
            name: poi.name,
            point_type: poi.point_of_interest_type?.name || 'poi'
          }

          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: poi.location.geometry.coordinates
            },
            properties: properties
          }
        })
      const featureCollection = {
        type: 'FeatureCollection',
        features: features
      }
      me.buildLocationsLayer('points_of_interest', featureCollection).addTo(this.locationsLayerGroup)
      if (this.locationsLayerGroup) {
        this.locationsLayerGroup.addTo(me.map)
        me.loadWaypointsLayer(waypoints)
      }
    })
  }

  loadWaypointsLayer = (waypoints) => {
    if (this.waypointsLayerGroup) {
      this.map.removeLayer(this.waypointsLayerGroup)
    }

    this.waypointsLayerGroup = L.layerGroup([
      this.buildWaypointsLayer(waypoints)
    ])
    this.waypointsLayerGroup.addTo(this.map)
  }

  updateCursorLine (point) {
    if (this.cursorLine) {
      this.cursorLine.removeFrom(this.map)
      delete this.cursorLine
    }

    if (this.cursorMarker) {
      this.cursorMarker.removeFrom(this.map)
      delete this.cursorMarker
    }

    if (this.props.geojson && this.props.geojson.coordinates.length > 0) {
      this.cursorLine = L.polyline([
        L.latLng(this.props.geojson.coordinates.slice(-1)[0].slice().reverse()),
        point
      ], {
        color: '#ef6643',
        opacity: 0.8,
        weight: 5
      }).addTo(this.map)
    }

    this.cursorMarker = L.circleMarker(point, {
      color: '#fff',
      fillColor: '#e62115',
      fillOpacity: 0.0,
      opacity: 1.0,
      radius: 10
    }).addTo(this.map)
  }

  updateRouteOverlay = (geojson) => {
    if (geojson && geojson !== 'new') {
      if (this.routeOverlay) {
        this.routeOverlay.remove()
        delete this.routeOverlay
      }

      this.routeOverlay = this.initializeRouteOverlay(geojson)
    } else {
      const center = this.map.getCenter()

      this.routeOverlay = this.initializeRouteOverlay({
        geometry: {
          coordinates: [
            [
              center.lng,
              center.lat
            ]
          ],
          type: 'LineString'
        },
        properties: {
          isEditable: true
        },
        type: 'Feature'
      })
    }

    this.routeOverlay
      .addTo(this.map)
      .on('click', () => {
        this.handleToggleEdit()
      })
      .on('pm:markerdragend', () => {
        const geometry = this.routeOverlay.toGeoJSON().features[0].geometry
        const coordinates = geometry.coordinates

        if (this.endMarker) {
          this.endMarker
            .setLatLng(coordinates[coordinates.length - 1].slice().reverse())
            .addTo(this.map)
        }

        if (this.startMarker) {
          this.startMarker
            .setLatLng(coordinates[0].slice().reverse())
            .addTo(this.map)
        }

        this.handleUpdateRoute(geometry)
      })
      .on('pm:markerdragstart', () => {
        if (this.endMarker) {
          this.endMarker.remove()
        }

        if (this.startMarker) {
          this.startMarker.remove()
        }
      })
      .on('pm:vertexremoved', () => {
        this.handleUpdateRoute(this.routeOverlay.toGeoJSON().features[0].geometry)
      })

    // Make sure the route is initialized, so Outing.js has it in state.
    this.handleUpdateRoute(this.routeOverlay.toGeoJSON().features[0].geometry)

    this.map.options.bounds = this.routeOverlay.getBounds() // TODO: Also factor in stops when setting bounds

    if (geojson && geojson !== 'new') {
      const coordinates = geojson.coordinates

      if (coordinates) {
        let endCoordinate = coordinates.slice(-1)[0]
        let startCoordinate = coordinates[0]

        if (geojson.type === 'MultiLineString') {
          endCoordinate = coordinates[0].slice(-1)[0]
          startCoordinate = coordinates[0][0]
        }

        if (this.startMarker) {
          this.startMarker.remove()
          delete this.startMarker
        }

        if (this.endMarker) {
          this.endMarker.remove()
          delete this.endMarker
        }

        if (startCoordinate) {
          this.startMarker = L.circleMarker(startCoordinate.slice().reverse(), {
            color: '#fff',
            fillColor: '#15e63c',
            fillOpacity: 1.0,
            opacity: 1.0,
            radius: 10
          })
            .addTo(this.map)
            .bringToFront()
        }

        if (endCoordinate) {
          this.endMarker = L.circleMarker(endCoordinate.slice().reverse(), {
            color: '#fff',
            opacity: 1.0,
            fillColor: '#e62115',
            fillOpacity: 1.0,
            radius: 10
          })
            .addTo(this.map)
            .bringToFront()
        }
      }
    }

    document.getElementsByClassName('leaflet-control-deleteFromEnd')[0].style.display = geojson ? 'block' : 'none'
    document.getElementsByClassName('leaflet-control-deleteFromStart')[0].style.display = geojson ? 'block' : 'none'
    document.getElementsByClassName('leaflet-control-pencil')[0].style.display = geojson ? 'block' : 'none'
  }

  // Component lifecycle
  componentDidMount () {
    if (window.L) {
      const { communities, geojson, organization } = this.props
      const editButtons = [
        new controls.SvgControl({
          icon: 'plus',
          onClick: this.handleToggleAdd,
          position: 'topleft',
          title: 'Add vertices to the route line'
        }),
        new controls.SvgControl({
          hidden: true,
          icon: 'pencil',
          onClick: this.handleToggleEdit,
          position: 'topleft',
          title: 'Edit the route line'
        }),
        new controls.SvgControl({
          hidden: true,
          icon: 'deleteFromStart',
          onClick: this.handleRemoveFromStart,
          position: 'topleft',
          title: 'Delete vertices from the beginning of the route line'
        }),
        new controls.SvgControl({
          hidden: true,
          icon: 'deleteFromEnd',
          onClick: this.handleRemoveFromEnd,
          position: 'topleft',
          title: 'Delete vertices from the end of the route line'
        })
      ]
      const me = this

      this.map = window.L.outerspatial.map({
        baseLayers: [
          {
            clickable: false,
            icon: 'topo',
            id: 'trailheadlabs.cjwv5q3n901pa1csbm359whvd',
            maxZoom: 22,
            minZoom: 0.1,
            name: 'Satellite',
            popup: false,
            styled: true,
            type: 'mapbox'
          },
          {
            clickable: false,
            icon: 'topo',
            id: 'trailheadlabs.cj5wmcjku2mls2stllpydix9b',
            maxZoom: 22,
            minZoom: 0.1,
            name: 'Outdoors',
            popup: false,
            styled: true,
            type: 'mapbox'
          }
        ],
        div: this.element,
        fullscreenControl: true,
        geocoderControl: true,
        scrollWheelZoom: false
      })
        .on('click', (e) => {
          this.clicks = 0

          setTimeout(function () {
            if (!me.clicks) {
              if (me.isCircleClickEvent) {
                me.isCircleClickEvent = false
              } else {
                const latLng = e.latlng

                if (me.addMode) {
                  me.handleAddPoint([
                    latLng.lng,
                    latLng.lat
                  ])
                } else if (!me.editMode) {
                  me.map
                    .closePopup()
                    .panTo(latLng) // TODO: Use offset as well
                    .openPopup(me.createMapClickPopup(me.map, e), latLng)
                }
              }
            }
          }, 200)
        })
        .on('dblclick', () => {
          this.clicks++
        })
        .on('mousemove', (e) => {
          if (this.addMode) {
            this.updateCursorLine(e.latlng)
          }
        })
      this.loadLocationLayers()
      editButtons.map((item) => {
        return item.addTo(this.map)
      })

      // TODO: I (Nate) added the check for `coordinates` because for some reason the server is returning a "GeometryCollection" for this outing: https://manager.outerspatial.com/6704/locations/outings/2738/route.
      if (geojson && geojson.coordinates) {
        this.updateRouteOverlay(geojson) // this.map.options.bounds is setup in this method.
      } else {
        this.map.options.bounds = (() => {
          if (organization.extent && Object.keys(organization.extent).length > 0 && organization.extent.geometry !== null) {
            // Use the organization bounds.
            return L.geoJSON(organization.extent.geometry).getBounds()
          } else if (communities.length) {
            // Merge all the community bounds' together.
            const communitiesBounds = L.latLngBounds()

            communities.forEach((community) => {
              communitiesBounds.extend(L.geoJSON(community.extent.geometry).getBounds())
            })

            return communitiesBounds
          } else {
            // If no community or organization bounds is available, fallback to bounds of CONUS.
            return L.geoJSON({
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [-126.5625, 23.885837699862005],
                    [-64.6875, 23.885837699862005],
                    [-64.6875, 50.401515322782366],
                    [-126.5625, 50.401515322782366],
                    [-126.5625, 23.885837699862005]
                  ]
                ]
              }
            }).getBounds()
          }
        })()
      }

      this.map.fitBounds(this.map.options.bounds)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (!_.isEqual(this.props.geojson, nextProps.geojson)) {
      if (!this.editMode) {
        this.updateRouteOverlay(nextProps.geojson)
        this.loadWaypointsLayer(nextProps.waypoints)
      }
    }

    if (!_.isEqual(this.props.waypoints, nextProps.waypoints)) {
      this.loadWaypointsLayer(nextProps.waypoints)
    }

    if (nextProps.focusedWaypoint !== this.props.focusedWaypoint) {
      if (this.selectedWaypointMarker) {
        this.selectedWaypointMarker.removeFrom(this.map)
      }

      if (nextProps.focusedWaypoint) {
        let waypoint = null
        let waypointIndex = -1

        for (let i = 0; i < nextProps.waypoints.length; i++) {
          const obj = nextProps.waypoints[i]

          if (nextProps.focusedWaypoint.id === obj.id) {
            waypoint = obj
            waypointIndex = i
            break
          }
        }

        if (waypoint) {
          const { onDeselectWaypoint, onMoveWaypoint } = this.props
          let coords = waypoint.location && waypoint.location.geometry ? waypoint.location.geometry.coordinates : (waypoint.feature ? waypoint.feature.location.geometry.coordinates : null)

          this.map.closePopup()
          this.loadWaypointsLayer(nextProps.waypoints)

          if (coords) {
            coords = coords.slice().reverse()

            this.selectedWaypointMarker = L.marker(coords, {
              autoPan: !waypoint.feature,
              draggable: !waypoint.feature,
              icon: L.icon({
                iconAnchor: [
                  12.5,
                  35
                ],
                iconSize: [
                  27,
                  38
                ],
                iconUrl: `${process.env.PUBLIC_URL}/map-markers/waypoint-selected-${waypointIndex + 1}@2x.png`,
                popupAnchor: [
                  1,
                  -38
                ]
              })
            })
              .on('click', () => {
                onDeselectWaypoint()
              })

            if (!waypoint.feature) {
              this.selectedWaypointMarker.on('dragend', (e) => {
                onMoveWaypoint(e.target.getLatLng())
              })
            }

            this.map.addLayer(this.selectedWaypointMarker)
            this.map.setView(coords, 17)
          }
        }
      }
    }
  }

  componentWillUnmount () {
    if (this.cursorLine) {
      this.cursorLine.remove()
      delete this.cursorLine
    }

    if (this.cursorMarker) {
      this.cursorMarker.remove()
      delete this.cursorMarker
    }

    if (this.endMarker) {
      this.endMarker.remove()
      delete this.endMarker
    }

    if (this.locationsLayerGroup) {
      this.locationsLayerGroup.remove()
      delete this.locationsLayerGroup
    }

    if (this.routeOverlay) {
      this.routeOverlay.remove()
      delete this.routeOverlay
    }

    if (this.startMarker) {
      this.startMarker.remove()
      delete this.startMarker
    }

    if (this.waypointsLayerGroup) {
      this.waypointsLayerGroup.remove()
      delete this.waypointsLayerGroup
    }

    if (this.map) {
      this.map.remove()
    }
    if (this.element) {
      if (this.element.parentNode) {
        this.element.parentNode.removeChild(this.element)
      }
      delete this.element
    }
  }

  render () {
    return (
      <div className='outingRoute-map__wrap'>
        <div className='outingRoute-map__container'>
          <div
            className='outerspatialMap mapPreview map-container'
            ref={target => (this.element = target)}
          />
        </div>
      </div>
    )
  }
}

OutingRouteMap.propTypes = propTypes
OutingRouteMap.defaultProps = defaultProps

export default OutingRouteMap
