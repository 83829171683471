import { Well } from 'react-bootstrap'
import React, {useState, useEffect} from 'react'
import { useRouteMatch } from 'react-router-dom'



export function ApproveParentalConsent() {
  const match = useRouteMatch()
  const uuid = match.params.uuid
  const [status, setStatus] = useState()

  useEffect(() => {
    const approveUrl = `${process.env.REACT_APP_API_SERVICE_URL}/parental-consent/approve`
    console.log(approveUrl)
    fetch(approveUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ uuid })
    }).then((response) => {
      console.log(response)
      setStatus('approved')
    }).catch((error) => {
      console.error(error)
      setStatus('error')
    })
  }, [status])

  if (status === 'approved') {
    return (
      <div className='errorView'>
        <Well>
          <div className='errorView-container' style={{ textAlign: 'left' }}>
            <h2>Approved</h2>
            <p>You have successfully approved the parental consent for the account.</p>
          </div>
        </Well>
      </div>
    )
  } else {
    return (
      <div className='errorView'>
        <Well>
          <div className='errorView-container' style={{ textAlign: 'left' }}>
            <h2>Approving...</h2>
            <p>Approving the parental consent for the account.</p>
          </div>
        </Well>
      </div>
    )
  }
}
