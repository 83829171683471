import PropTypes from 'prop-types'
import React from 'react'
// import ProgressBar from 'react-bootstrap/lib/ProgressBar'
import { CenteredLoader } from '../../../../components/centeredLoader'
import Pagination from '../../../../components/pagination'
import './mediaList.css'
import MediaListItem from './MediaListItem'
import MediaTable from './MediaTable'

const MediaList = (props) => {
  const changePage = (page) => {
    const { query, onQueryChange } = props
    const newQuery = { ...query, page: page }
    onQueryChange(newQuery)
  }

  const {
    allowEdit = true,
    allowSelection = false,
    media,
    onClick,
    paging,
    selectedMedia,
    tableMode = false,
    updating,
    fetching
  } = props

  return (
    <div className='mediaList'>
      {fetching === true &&
        <CenteredLoader overlay />}
      <div className='mediaList-list'>
        {/* {activeUploads.map((upload) => {
            const progress = Math.ceil(upload.progress * 100)

            return (
              <div className='mediaList-listItem mediaList-listItem__upload' key={upload.tempId}>
                <ProgressBar active bsStyle='info' now={progress} striped />
              </div>
            )
          })} */}
        {!tableMode && media.map((mediaItem, index) => {
          return (
            <div className='mediaList-listItem' key={mediaItem.id + '-' + index}>
              <MediaListItem
                allowEdit={allowEdit}
                allowSelection={allowSelection}
                image={mediaItem}
                onClick={onClick}
                selectedMedia={selectedMedia}
                updating={updating}
              />
            </div>
          )
        })}
        {tableMode && media && media.length > 0 &&
          <MediaTable {...props} />}
      </div>
      {paging && paging.totalPages > 1 &&
        <div className='mediaList-pagination'>
          <Pagination
            activePage={parseInt(paging.currentPage, 10)}
            handleOnSelect={changePage}
            numberOfPages={paging.totalPages}
          />
        </div>}
    </div>
  )
}

MediaList.propTypes = {
  fetching: PropTypes.bool,
  allowSelection: PropTypes.bool,
  allowEdit: PropTypes.bool,
  media: PropTypes.array,
  onClick: PropTypes.func,
  onQueryChange: PropTypes.func,
  onToggleSelect: PropTypes.func,
  paging: PropTypes.object,
  query: PropTypes.object,
  selectedMedia: PropTypes.array,
  tableMode: PropTypes.bool,
  updating: PropTypes.any
}

export default MediaList
