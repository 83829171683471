import { gql, useMutation, useQuery } from '@apollo/client'
import { Form, Formik } from 'formik'
import t from 'prop-types'
import React, { useEffect } from 'react'
import Panel from 'react-bootstrap/lib/Panel'
import { useHistory, useRouteMatch } from 'react-router'
import toast from 'react-hot-toast'
import * as Yup from 'yup'

import { CenteredLoader } from '../../../components/centeredLoader'
import { PageContent, PageHeader, PageLayout } from '../../../components/pageLayout'
import AssociationSet from '../../../components/associationSet'
import DocumentHeader from './DocumentHeader'
import FormikGroup from '../../../components/fieldGroup/FormikGroup'

import './document.css'

const backPath = (match) => {
  let backUrl = match.path.split(':')[0]

  if (backUrl.charAt(backUrl.length - 1) === '/') {
    backUrl = backUrl.slice(0, -1)
  }

  return backUrl
}

const DeleteMediaFile = gql`
  mutation DeleteMediaFile($id: Int!) {
    delete_media_files_by_pk(id: $id) {
      id
      created_at
    }
    delete_media_file_attachments(where: { media_file_id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const DeleteMediaFileAttachment = gql`
  mutation DeleteMediaFileAttachment($id: Int!) {
    delete_media_file_attachments_by_pk(id: $id) {
      id
      created_at
    }
  }
`

const GetDocumentQuery = gql`
  query GetDocument($id: Int!){
    area_media_file_attachments(where:{media_file_id:{_eq:$id}}) {
      id
      feature: area {
        id
        name
      }
    }
    point_of_interest_media_file_attachments(where:{media_file_id:{_eq:$id}}) {
      id
      feature: point_of_interest {        
        id
        name
        area {
          id
          name
        }
      }
    }
    trail_media_file_attachments(where:{media_file_id:{_eq:$id}}) {
      id
      feature: trail {
        id
        name
        area {
          id
          name
        }
      }
    }
    outing_media_file_attachments(where:{media_file_id:{_eq:$id}}) {
      id
      feature: outing {
        id
        name
      }
    }
    media_files(where:{id:{_eq:$id}}){
      id
      name
      description
      uploaded_file
    }
  }
`

const InsertMediaFileAttachment = gql`
  mutation InsertMediaFileAttachment($attacheable_id: Int!, $attacheable_type: String!, $media_file_id: Int!) {
    insert_media_file_attachments_one(object: {attacheable_id: $attacheable_id, attacheable_type: $attacheable_type, media_file_id: $media_file_id}) {
      id
      created_at
      attacheable_type
      attacheable_id
    }
  }
`

const UpdateMediaFile = gql`
  mutation UpdateMediaFile($id:Int!,$name:String,$description:String){
    update_media_files_by_pk(
      pk_columns:{ id: $id },
      _set: {
        name: $name,
        description: $description
      }
    ) {
      id
      name
      description
      updated_at
    }
  }
`

const Document = (props) => {
  const { updating } = props
  const match = useRouteMatch()
  const history = useHistory()
  const documentId = match.params.documentId
  let model = {}
  const { data, loading } = useQuery(GetDocumentQuery, {
    variables: {
      id: documentId
    }
  })
  const [deleteMediaFile] = useMutation(DeleteMediaFile, { refetchQueries: [GetDocumentQuery] })
  const [deleteMediaFileAttachment] = useMutation(DeleteMediaFileAttachment, { refetchQueries: [GetDocumentQuery] })
  const [insertMediaFileAttachment] = useMutation(InsertMediaFileAttachment, { refetchQueries: [GetDocumentQuery] })
  const [updateMediaFile] = useMutation(UpdateMediaFile, { refetchQueries: [GetDocumentQuery] })

  if (data) {
    model = { ...data.media_files[0] }
  }

  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      description: model?.description || '',
      name: model?.name || ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(500, 'Must be 400 characters or less').required(),
      description: Yup.string()
        .max(500, 'Must be 400 characters or less').nullable()
    }),
    onSubmit: values => {
      handleSave(values)
    }
  }
  const handleSave = (values) => {
    updateMediaFile({
      variables: { ...values, id: model.id }
    }).then(() => {
      toast.success('Updated document successfully.')
    })
  }

  useEffect(() => {
    document.title = `Documents | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  const addAssociation = (association) => {
    const variables = {
      attacheable_id: association.attacheable_id,
      attacheable_type: association.attacheable_type,
      media_file_id: model.id
    }
    insertMediaFileAttachment({
      variables
    }).then(() => { toast.success('Association added') })
  }

  const destroy = () => {
    const conf = window.confirm('Are you sure you want to permanently delete this document?')

    if (conf) {
      deleteMediaFile({ variables: { id: model.id } }).then(() => {
        history.push(backPath(match))
      })
    }
  }

  const removeAssociation = (association) => {
    const variables = {
      id: association.id
    }
    deleteMediaFileAttachment({ variables }).then(() => { toast.success('Association deleted') })
  }

  let associations = []

  if (data) {
    const areaAttachments = data.area_media_file_attachments ? data.area_media_file_attachments.map((item) => { return { ...item, attacheable_type: 'Area' } }) : []
    const trailAttachments = data.trail_media_file_attachments ? data.trail_media_file_attachments.map((item) => { return { ...item, attacheable_type: 'Trail' } }) : []
    const poiAttachments = data.point_of_interest_media_file_attachments ? data.point_of_interest_media_file_attachments.map((item) => { return { ...item, attacheable_type: 'PointOfInterest' } }) : []
    const outingAttachments = data.outing_media_file_attachments ? data.outing_media_file_attachments.map((item) => { return { ...item, attacheable_type: 'Outing' } }) : []

    associations = [
      areaAttachments,
      trailAttachments,
      poiAttachments,
      outingAttachments
    ].flat()
    associations = associations.map((item) => {
      return { ...item, contentName: item.feature.name, contentId: item.attacheable_id, feature: { ...item.feature, class_name: item.attacheable_type } }
    }).filter((att) => (att.attacheable_type !== 'Campground' && att.attacheable_type !== 'Organization' && att.attacheable_type !== 'Trailhead'))
  }

  if (loading) {
    return (<CenteredLoader />)
  }

  return (
    <div className='document'>
      {loading &&
        <CenteredLoader />}
      {!loading &&
        <Formik {...formikProps}>
          {formik => (
            <PageLayout>
              <PageHeader>
                <DocumentHeader
                  model={model}
                  onSaveClick={formik.handleSubmit}
                  onDeleteClick={destroy}
                />
              </PageHeader>
              <PageContent>
                <div className='document-content'>
                  {updating &&
                    <CenteredLoader overlay />}
                  <Panel>
                    <Panel.Body>
                      <Form className='postForm-form'>
                        <FormikGroup
                          id='name'
                          name='name'
                          type='text'
                          label='Name *'
                        />
                        <FormikGroup
                          id='description'
                          name='description'
                          model='documentForm.description'
                          type='text'
                          label='Description'
                        />
                      </Form>
                    </Panel.Body>
                  </Panel>
                  <Panel className='document-associations'>
                    <Panel.Body>
                      <h3>Related</h3>
                      <AssociationSet
                        associations={associations}
                        entityTypes={['Features']}
                        isMultipleAssociationSet
                        onAdd={addAssociation}
                        onRemove={removeAssociation}
                        restrictToCurrentOrganization
                      />
                    </Panel.Body>
                  </Panel>
                </div>
              </PageContent>
            </PageLayout>
          )}
        </Formik>}
    </div>
  )
}

Document.propTypes = {
  // state
  document: t.object,
  fetched: t.bool,
  fetching: t.bool,
  match: t.object,
  form: t.object,
  model: t.object,
  updating: t.bool
}

export default Document
