import React from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { useCurrentUser } from '../../contexts/CurrentUserContext'
import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { CenteredLoader } from '../../components/centeredLoader'

const fetchPostQuery = gql`
  query FetchPost($postId: Int!) {
    posts_by_pk(id: $postId) {
      id
      organization_id
      feature_id
      feature_type
      area_post {
        area {
          stewardships {
            organization_id
          }
        }
      }
      trail_post {
        trail {
          stewardships {
            organization_id
          }
        }
      }
      point_of_interest_post {
        point_of_interest {
          stewardships {
            organization_id
          }
        }
      }      
      outing_post {
        outing {
          stewardships {
            organization_id
          }
        }
      }
    }    
  }
`

export const PostRedirect = () => {
  const match = useRouteMatch()
  const postId = match.params.postId
  const currentUser = useCurrentUser()

  const hasMembership = (orgIds) => {    
    debugger
    const memberships = currentUser.memberships.filter(membership => orgIds.includes(membership.organization.id))
    return memberships.length > 0
  }

  const postFeatureOrgs = (post) =>{
    const areaOrgs = post.area_post ? post.area_post.area.stewardships.map(stewardship => stewardship.organization_id) : []
    const trailOrgs = post.trail_post ? post.trail_post.trail.stewardships.map(stewardship => stewardship.organization_id) : []
    const poiOrgs = post.point_of_interest_post ? post.point_of_interest_post.point_of_interest.stewardships.map(stewardship => stewardship.organization_id) : []
    const outingOrgs = post.outing_post ? post.outing_post.outing.stewardships.map(stewardship => stewardship.organization_id) : []
    const uniqueOrgs = [...new Set([...areaOrgs, ...trailOrgs, ...poiOrgs, ...outingOrgs])]
    return uniqueOrgs
  }
  const orgForPost = (post) => {
    const postOrg = post.organization_id
    if(hasMembership([postOrg])) {
      return postOrg
    }
    if(hasMembership(postFeatureOrgs(post))) {
      return postFeatureOrgs(post)[0]
    }
    else {
      return null
    }
  }
  
  let url = null
    
  const { error, data } = useQuery(fetchPostQuery, { variables: { postId } })
  
  if (data) {
    const post = data.posts_by_pk
    if (!post) {
      return (<Redirect to='/' />)
    }    
    const organizationId = orgForPost(post)
    if (organizationId) {
      if(post.feature_type === 'Area') {
        url = `/${organizationId}/locations/areas/${post.feature_id}/social`
      } else if (post.feature_type === 'Trail') {
        url = `/${organizationId}/locations/trails/${post.feature_id}/social`
      } else if (post.feature_type === 'PointOfInterest') {
        url = `/${organizationId}/locations/points-of-interest/${post.feature_id}/social`
      } else if (post.feature_type === 'Outing') {
        url = `/${organizationId}/locations/outings/${post.feature_id}/social`
      } else {
        url = `/${organizationId}/social`
      }
      return (<Redirect to={url} />)
    } else {
      return (<Redirect to='/' />)
    }    
  } else if (error) {
    console.error(error)
    return (<Redirect to='/' />)
  } else {
    return (<CenteredLoader />)
  }
}
