import React from 'react'
import PropTypes from 'prop-types'
import Panel from 'react-bootstrap/lib/Panel'
import Table from 'react-bootstrap/lib/Table'

import HeroItemsTableRow from './HeroItemsTableRow'

import './heroItemsTable.css'

const HeroItemsTable = ({ heroItems, match, onDelete }) => {
  return (
    <div className='heroItemsTable'>
      {heroItems.length < 1 &&
        <Panel className='heroItemsTable-empty'>
          <Panel.Body>
            <h3>No bulletin board items available.</h3>
          </Panel.Body>
        </Panel>}
      {heroItems.length >= 1 &&
        <div>
          <Panel>
            <Table striped>
              <thead>
                <tr>
                  <th className='heroItemsTable--title'>Title</th>
                  <th className='heroItemsTable--createdAt'>Created At</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {heroItems.map((heroItem) => {
                  return (
                    <HeroItemsTableRow
                      key={heroItem.id}
                      {...{
                        heroItem,
                        match,
                        onDelete
                      }}
                    />
                  )
                })}
              </tbody>
            </Table>
          </Panel>
        </div>}
    </div>
  )
}

HeroItemsTable.propTypes = {
  heroItems: PropTypes.array,
  match: PropTypes.object,
  onDelete: PropTypes.func
}

export default HeroItemsTable
