import React from 'react'
import Well from 'react-bootstrap/lib/Well'
import './errors.css'

const Error404 = () => {
  document.title = `404 | ${process.env.REACT_APP_PAGE_TITLE}`

  return (
    <div className='errorView'>
      <Well>
        <div className='errorView-container'>
          <h2>Something's wrong. Please double-check the URL and refresh the page.</h2>
        </div>
      </Well>
    </div>
  )
}

export default Error404
