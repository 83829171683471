import React from 'react'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import App from 'views/app/App'
import Auth0ProviderWithHistory from 'auth/Auth0ProviderWithHistory'
import Login from 'views/auth/login/Login'
import Logout from 'views/errors/logout'
import ProtectedRoute from 'auth/ProtectedRoute'
import ReactGA from 'react-ga4'
import trackPage from 'services/TrackPage'
import { ApproveParentalConsent } from 'views/auth/login/ApproveParentalConsent'
import { RevokeParentalConsent } from 'views/auth/login/RevokeParentalConsent'

export function Root () {
  if (process.env.REACT_APP_STAGE_NAME !== 'development') {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID)
    trackPage(window.location)
  }

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const couponId = params.couponId

  if (couponId) {
    window.localStorage.setItem('couponId', couponId)
  }

  if (params.error) {
    return (
      <Logout state={params} />
    )
  }

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path='/' exact component={Login} />
          <Route path='/parental-consent/approve/:uuid' component={ApproveParentalConsent} />
          <Route path='/parental-consent/revoke/:uuid' component={RevokeParentalConsent} />
          <Route path='/logout' exact component={Logout} />
          <Auth0ProviderWithHistory>
            <ProtectedRoute path='/*' component={App} />
          </Auth0ProviderWithHistory>
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default Root
