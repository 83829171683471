import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/lib/Alert'
import { useHistory, useRouteMatch } from 'react-router-dom'
import debugMessage from 'services/Debug'
import { CenteredLoader } from '../../../../../components/centeredLoader'
import { useCurrentOrganization } from '../../../../../contexts/OrganizationContext'
import FeatureChildrenActionBar from '../../../../../views/features/list/actionBar/FeatureChildrenActionBar'
import FeaturesTable from '../../../../../views/features/list/table/FeaturesTable'
import { Mutations, Queries } from '../../../FeaturesOperations'
import './featureChildren.css'
import toast from 'react-hot-toast'
import { useCurrentUser } from 'contexts/CurrentUserContext'

const FeatureChildren = (props) => {
  const { feature } = props
  const history = useHistory()
  const match = useRouteMatch() // Is the match `:childFeatureId` ever used?
  const me = useCurrentUser()
  const [features, setFeatures] = useState([])
  const [query, setQuery] = useState({
    direction: 'asc',
    order: 'name',
    page: 1,
    type: 'trails'
  })
  const organization = useCurrentOrganization()
  const isSuperAdmin = me.roles.some((item) => item.role.name === 'admin')

  const handleArchive = (feature) => {
    onVisibilityChange(feature, 'Archive')
  }

  const handleDeleteTrail = (feature) => {
    if (!isSuperAdmin) {
      toast.error('You do not have permission to delete trails.')
      return
    }

    if (window.confirm('Are you sure you want to delete this trail?')) {
      deleteTrail({
        variables: {
          trailId: feature.id
        }
      })
    }
  }

  const handleOpenFeature = (innerFeature) => {
    history.push(`${match.url.split('/locations')[0]}/locations/${innerFeature.type}/${innerFeature.id}`)
  }

  const handlePublish = (feature) => {
    onVisibilityChange(feature, 'Publish')
  }

  const onVisibilityChange = (feature, option) => {
    if (window.confirm('Are you sure you want to update the visibility of this location?')) {
      const visibility = (() => {
        if (option === 'Archive') {
          return 'Archived'
        } else {
          return 'Published'
        }
      })()

      if (feature.__typename === 'points_of_interest') {
        updatePOIVisibility({
          variables: {
            poiId: feature.id,
            visibility: visibility
          }
        })
      } else if (feature.__typename === 'trails') {
        updateTrailVisibility({
          variables: {
            trailId: feature.id,
            visibility: visibility
          }
        })
      } else if (feature.__typename === 'areas') {
        updateAreaVisibility({
          variables: {
            areaId: feature.id,
            visibility: visibility
          }
        })
      }
    }
  }

  const { data, error, loading } = useQuery(Queries.GetFeaturesFromParentAreaId, {
    variables: {
      areaId: feature.id,
      organizationId: organization.id
    },
    onCompleted (data) {
      let featureData = []

      switch (query.type) {
        case 'outings':
          featureData = data.outings
          break
        case 'trails':
          featureData = data.trails
          break
        default:
          featureData = data.points_of_interest
          break
      }

      console.log('FeatureChildren.js: featureData:', featureData)

      setFeatures(featureData)
    },
    onError: (innerError) => {
      debugMessage(innerError)
    }
  })

  const [deleteTrail] = useMutation(

    // TODO: Show a confirmation dialog that lists posts, images, etc. that will be deleted as well.

    Mutations.DeleteTrail, {
      onCompleted (data) {
        const deletedTrailId = data.delete_trails.returning[0].id
        const updatedFeatures = features.filter(trail => deletedTrailId !== trail.id)
        setFeatures(updatedFeatures)
        toast.success('Trail deleted.')
      },
      onError (deleteError) {
        toast.error(deleteError)
        toast.error('The trail could not be deleted.')
      }
    }
  )

  const [updateAreaVisibility] = useMutation(
    Mutations.UpdateVisibilityForArea, {
      onCompleted (data) {
        const updatedArea = data.update_areas.returning[0]
        const updatedFeatures = features.map(area => {
          if (updatedArea.id === area.id) {
            return { ...area, visibility: updatedArea.visibility }
          } else {
            return area
          }
        })
        setFeatures(updatedFeatures)
        toast.success('Area visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  const [updatePOIVisibility] = useMutation(
    Mutations.UpdateVisibilityForPOI, {
      onCompleted (data) {
        const updatedPOI = data.update_points_of_interest.returning[0]
        const updatedFeatures = features.map(poi => {
          if (updatedPOI.id === poi.id) {
            return { ...poi, visibility: updatedPOI.visibility }
          } else {
            return poi
          }
        })
        setFeatures(updatedFeatures)
        toast.success('Point of Interest visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  const [updateTrailVisibility] = useMutation(
    Mutations.UpdateVisibilityForTrail, {
      onCompleted (data) {
        const updatedTrail = data.update_trails.returning[0]
        const updatedFeatures = features.map(trail => {
          if (updatedTrail.id === trail.id) {
            return { ...trail, visibility: updatedTrail.visibility }
          } else {
            return trail
          }
        })
        setFeatures(updatedFeatures)
        toast.success('Trail visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  if (!data) {
    return <CenteredLoader />
  }

  return (
    <div className='featureChildren-wrap'>
      <div className='featureChildren-actionBar'>
        <FeatureChildrenActionBar
          hiddenFeatures={['areas']}
          onChangeQuery={(value) => setQuery(prevState => { return { ...prevState, ...value } })}
          query={query}
        />
      </div>
      <div className='featureChildren-content'>
        <div className='featureChildren-table'>
          {loading &&
            <div className='featureChildren-loader'>
              <CenteredLoader />
            </div>}
          {!loading &&
            <>
              {!!error?.message &&
                <Alert bsStyle='danger'>{error.message}</Alert>}
              <FeaturesTable
                {...{
                  features: (() => {
                    switch (query.type) {
                      case 'outings':
                        return data.outings
                      case 'trails':
                        return data.trails
                      default:
                        return data.points_of_interest
                    }
                  })(),

                  featureType: query.type,
                  organizationId: organization.id
                }}
                displayParentArea={false}
                isSuperAdmin={isSuperAdmin}
                onArchived={handleArchive}
                onDeleteTrail={handleDeleteTrail}
                onEdit={handleOpenFeature}
                onPageChange={(value) => setQuery(prevState => { return { ...prevState, page: value } })}
                onPublished={handlePublish}
              />
            </>}
        </div>
      </div>
    </div>
  )
}

FeatureChildren.propTypes = {
  feature: PropTypes.object
}

export default FeatureChildren
