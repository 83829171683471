import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/lib/Button'
import Modal from 'react-bootstrap/lib/Modal'
import Alert from 'react-bootstrap/lib/Alert'

import { CenteredLoader } from '../../../../../../components/centeredLoader'
import { Field, Form, Formik } from 'formik'
import Editor from '../../../../../../components/editorWithHooks'
import * as Yup from 'yup'

const AdditionalInfoModal = ({ onClose, onSave, contentBlock, show }) => {
  const processSubmitForm = (values, formikBag) => {
    onSave(values, formikBag)
  }

  // Handle the case of creating a new Content Block and we need to ensure that the initial values are empty strings
  //  otherwise Formik throws a warning about changing an uncontrolled component to controlled.
  const initialValues = contentBlock ? { ...contentBlock } : {
    title: '',
    body: ''
  }

  return (
    <div className='additionalInfoModal'>
      <Modal
        backdrop='static'
        dialogClassName='additionalInfoModal-dialog'
        enforceFocus={false}
        keyboard={false}
        onHide={onClose}
        show={show}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values, formikBag) => {
            processSubmitForm(values, formikBag)
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('This field is required'),
            body: Yup.string().required('This field is required')
          })}
        >
          {(formikProps) => {
            return (
              <>
                <Modal.Header
                  className='additionalInfoModal-titleBar'
                  closeButton
                >
                  <Modal.Title>Add Additional Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='additionalInfoForm'>
                    {formikProps.isSubmitting &&
                      <CenteredLoader overlay />}
                    {!formikProps.isValid &&
                      <Alert bsStyle='danger'>Please fix the issues with the form before submitting.</Alert>}

                    <Form noValidate>
                      <div className='additionalInfoForm-content'>
                        <Field name='title'>
                          {({ field, meta }) => (
                            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                              <label className='control-label' htmlFor='title'>Title</label>
                              <input className='form-control' id='title' type='text' {...field} />
                              {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                            </div>
                          )}
                        </Field>
                        <Field name='body'>
                          {({ field, form, meta }) => (
                            <div className={`form-group ${meta.error ? 'has-error' : ''}`}>
                              <label className='control-label' htmlFor='body'>Body</label>
                              <Editor
                                id={field.name}
                                className='form-control'
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value)
                                  form.setFieldTouched(field.name)
                                }}
                              />
                              {meta.error && <div className='error-message'>{meta.error}</div>}
                            </div>
                          )}
                        </Field>
                      </div>
                    </Form>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button bsStyle='link' onClick={onClose}>Close</Button>
                  <Button
                    bsStyle='primary'
                    disabled={!formikProps.isValid || !formikProps.dirty}
                    onClick={formikProps.handleSubmit}
                  >
                    Save &amp; Close
                  </Button>
                </Modal.Footer>
              </>
            )
          }}
        </Formik>
      </Modal>
    </div>
  )
}

AdditionalInfoModal.propTypes = {
  contentBlock: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  show: PropTypes.bool
}

export default AdditionalInfoModal
