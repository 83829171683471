import React from 'react'
import PropTypes from 'prop-types'

import FeatureTagSet from '../../../../../components/featureTagSet/FeatureTagSet'
import FeatureSeasons from './Feature.Seasons'
import { Field } from 'formik'
import Editor from '../../../../../components/editorWithHooks'
import { HelpBlock } from 'react-bootstrap'

const getSelectedSeasons = (feature) => {
  let tags = feature.tags.filter(tag => (tag.tag_descriptor?.category?.name === 'Seasonality') && tag.value === 'yes').map(tag => tag.key)

  if (tags.length === 0) {
    tags = null
  }

  return tags
}
const handleSeasonChange = (seasons, onSeasonChange) => {
  let newSeasons = seasons

  if (newSeasons.length === 4) {
    newSeasons = ['year_round']
  }

  if (onSeasonChange) {
    onSeasonChange(newSeasons)
  }
}

const ActivitiesAccessibilityAreas = ({ feature, onSeasonChange, onAllowedActivitiesChange, onGoodForChange, onRulesAndRegulationsChange }) => {
  return (
    <div>
      <label className='control-label'>Seasons of Availability</label>
      <FeatureSeasons onSeasonChange={(seasons) => handleSeasonChange(seasons, onSeasonChange)} selectedSeasons={getSelectedSeasons(feature)} />
      <div className='form-group'>
        <label className='control-label'>Allowed Activities</label>
        <FeatureTagSet
          categoryName='Activities'
          featureType={feature.class_name}
          selectedTags={feature.tags}
          onChange={onAllowedActivitiesChange}
          placeholder='What activities are permitted?'
        />
      </div>
      <div className='form-group'>
        <label className='control-label'>Good For</label>
        <FeatureTagSet
          categoryName='Good For'
          featureType={feature.class_name}
          selectedTags={feature.tags}
          onChange={onGoodForChange}
          placeholder='Who would enjoy this area?'
        />
      </div>
      <div className='form-group'>
        <label className='control-label'>Rules & Regulations</label>
        <FeatureTagSet
          categoryName='Rules & Regulations'
          featureType={feature.class_name}
          selectedTags={feature.tags}
          onChange={onRulesAndRegulationsChange}
          placeholder='Are there area restrictions?'
        />
      </div>
      <Field name='accessibility_description'>
        {({ field, form, meta }) => (
          <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
            <label className='control-label' htmlFor='accessibility_description'>Accessibility Description</label>
            <Editor
              id={field.name}
              className='form-control'
              value={field.value}
              onChange={(value) => {
                form.setFieldValue(field.name, value)
              }}
            />
            {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
            <HelpBlock>One-to-two paragraphs about the general accessibility features of this area.</HelpBlock>
          </div>
        )}
      </Field>
    </div>
  )
}

ActivitiesAccessibilityAreas.propTypes = {
  feature: PropTypes.object,
  onSeasonChange: PropTypes.func.isRequired,
  onAllowedActivitiesChange: PropTypes.func.isRequired,
  onGoodForChange: PropTypes.func.isRequired,
  onRulesAndRegulationsChange: PropTypes.func.isRequired
}

export default ActivitiesAccessibilityAreas
