import { Button, Dropdown, Glyphicon, MenuItem, Modal } from 'react-bootstrap'
import { format } from 'date-fns'
import Branch from 'branch-sdk'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

import { useCurrentOrganization } from 'contexts/OrganizationContext'

// TODO: View Analytics

const MoreDropdown = (props) => {
  const {
    feature,
    featureType
  } = props
  const organization = useCurrentOrganization()
  const [show, setShow] = useState(false)

  const getDateTimeString = (date) => {
    const d = new Date(new Date(`${date}Z`).toLocaleString('en-US', { timeZone: organization.time_zone }))
    return format(d, "LLL d, yyyy, h':'mmaaa")
  }
  const handleCloseModal = () => setShow(false)
  const handleOpenInModalSelect = () => {
    const communityId = (() => {
      const sponsoredCommunities = organization.communities.filter((community) => community.is_sponsored)

      // TODO: We should really let them select which community they want to open in the app.
      if (sponsoredCommunities.length > 0) {
        return sponsoredCommunities[0].id
      } else {
        return organization.communities[0].id
      }
    })()
    const desktopUrl = `https://www.outerspatial.com/${featureType}/${feature.id}`
    const linkData = {
      channel: 'OuterSpatial Manager',
      data: {
        $canonical_identifier: `${featureType}-${feature.id}`,
        $desktop_url: desktopUrl,
        community_id: communityId,
        entity_id: feature.id,
        entity_type: featureType
      },
      feature: 'Open in Mobile',
      type: 1
    }

    // TODO: Show loading indicator.

    Branch.init(process.env.REACT_APP_BRANCH_KEY, (err, data) => {
      if (err) {
        toast.error('The deep link could not be created. Please try again.')
      } else {
        Branch.link(linkData, function (err, link) {
          if (err) {
            toast.error('The deep link could not be created. Please try again.')
          } else {
            window.open(link, '_blank')
          }

          // TODO: Hide loading indicator.
        })
      }
    })
  }
  const handleOpenInWebSelect = () => {
    window.open(`https://www.outerspatial.com/${featureType}/${feature.id}`, '_blank')
  }
  const handleShowModal = () => setShow(true)
  const handleViewMetadataSelect = () => {
    handleShowModal(true)
  }

  return (
    <>
      <Dropdown
        id='more-dropdown'
        pullRight
      >
        <Dropdown.Toggle
          bsStyle='default'
        >
          More
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem
            eventKey='1'
            onSelect={handleOpenInModalSelect}
          >
            <Glyphicon
              glyph='phone'
            />
            &nbsp;
            Open in Mobile
          </MenuItem>
          <MenuItem
            eventKey='2'
            onSelect={handleOpenInWebSelect}
          >
            <Glyphicon
              glyph='new-window'
            />
            &nbsp;
            Open in Website
          </MenuItem>
          <MenuItem
            eventKey='3'
            onSelect={handleViewMetadataSelect}
          >
            <Glyphicon
              glyph='list-alt'
            />
            &nbsp;
            View Metadata
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
      <Modal onHide={handleCloseModal} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Location Metadata</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {feature.uid && (
              <li>Organization ID: <code>{feature.uid}</code></li>
            )}
            {feature.id && (
              <li>OuterSpatial ID: <code>{feature.id}</code></li>
            )}
            {feature.created_at && (
              <li>Created: {getDateTimeString(feature.created_at)}</li>
            )}
            {feature.updated_at && (
              <li>Updated: {getDateTimeString(feature.updated_at)}</li>
            )}
            {feature.location?.geometry?.coordinates?.length === 2 && (
              <li>Latitude/Longitude: {feature.location.geometry.coordinates[1].toFixed(5)}, {feature.location.geometry.coordinates[0].toFixed(5)}</li>
            )}
            {feature.what3words?.words && (
              <li>what3words: ///{feature.what3words.words}</li>
            )}
            {feature.trail_sections?.length > 0 && (
              <li>Trail Segment Organization IDs:
                <ul>
                  {feature.trail_sections.map((trailSection, index) => (
                    <li key={index}>
                      <code>{trailSection.trail_segment?.uid ? trailSection.trail_segment.uid : 'NULL'}</code>
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

MoreDropdown.propTypes = {
  feature: PropTypes.object,
  featureType: PropTypes.string
}

export default MoreDropdown
