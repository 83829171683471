import toast from 'react-hot-toast'

export const debugMessage = (message) => {
  if (process.env.REACT_APP_STAGE_NAME !== 'production') {
    console.error('[debugError]', message)
    toast.error(message)
  }
}

export const debugInfo = (message) => {
  if (process.env.REACT_APP_STAGE_NAME !== 'production') {
    console.info('[debugInfo]', message)
    toast.success(message)
  }
}

export default debugMessage
