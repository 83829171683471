import { gql } from '@apollo/client'

export const Fragments = {
  UsersDetails: gql`
    fragment UsersDetails on users {
      id
      profile {
        first_name
        last_name
      }
    }
  `
}

export const Queries = {
  SearchUsersById: gql`
    query SearchUsersById($id: Int!, $limit: Int! = 1) {
      users(where: {id: {_eq: $id}}, limit: $limit) {
        ...UsersDetails
      }
    }
    ${Fragments.UsersDetails}
  `,
  SearchUsersWithinOrganization: gql`
    query SearchUsersWithinOrganization($organizationId: Int!, $limit: Int! = 20, $where: memberships_bool_exp) {
      organizations_by_pk(id:$organizationId) {
        memberships(where: $where,
            limit: $limit,
            order_by: {user_email: {email : asc}}
          ) {
          user_email {
            email
          }
          user {
            ...UsersDetails
          }
        }
      }
    }
    ${Fragments.UsersDetails}
  `,

}
