import { gql, useMutation, useQuery } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import toast from 'react-hot-toast'
import { CenteredLoader } from '../../../components/centeredLoader'
import ActionBar from './actionBar/HeroItemsActionBar'
import './heroItemsList.css'
import HeroItemsTable from './table/HeroItemsTable'

const DeleteHeroItemMutation = gql`
  mutation DeleteHeroItem($id: Int!) {
    delete_hero_items_by_pk(id:$id){
      id
    }
  }
`
const HeroItemsListQuery = gql`
  query HeroItemsList($id: Int!, $limit: Int, $offset: Int, $order_by: [organization_hero_items_order_by!] = {}, $where: organization_hero_items_bool_exp = {}) {
    organizations_by_pk(id: $id) {
      hero_items(order_by: { created_at: desc }) {
        hero_item {
          created_at
          id
          title
        } 
      }
    }
  }
`

const HeroItemsList = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const { data, loading } = useQuery(HeroItemsListQuery, {
    variables: {
      id: organization.id
    }
  })
  const [deleteHeroItemMutation, { loading: deleting }] = useMutation(DeleteHeroItemMutation, {
    refetchQueries: ['HeroItemsList']
  })

  const handleCreateNew = () => {
    history.push(`${match.url}/new`)
  }

  const handleDeleteHeroItem = (heroItem) => {
    const conf = window.confirm('Are you sure you want to permanently delete this Bulletin Board item?')

    if (conf) {
      deleteHeroItemMutation({
        variables: {
          id: heroItem.id
        }
      }).then(() => {
        toast.success('Successfully deleted the Bulletin Board item.')
      })
    }
  }

  return (
    <div className='heroItemsList-wrap'>
      <div className='heroItemsList-actionBar'>
        <ActionBar
          fetching={loading}
          onNewClick={handleCreateNew}
        />
      </div>
      <div className='heroItemsList-content'>
        <div className='heroItemsList-table'>
          {(loading || deleting) &&
            <CenteredLoader />}
          {data &&
            <HeroItemsTable
              {...{ heroItems: data.organizations_by_pk.hero_items.map(item => item.hero_item), match }}
              onDelete={handleDeleteHeroItem}
            />}
        </div>
      </div>
    </div>
  )
}

export default HeroItemsList
