import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/lib/Button'
import { Formik, Form } from 'formik'
import FormikGroup from '../../../../components/fieldGroup/FormikGroup'
import MediaModal from '../../../../components/media/modal/MediaModal'
import { featureByType } from '../../../../models/feature/feature.lang'
import { CenteredLoader } from '../../../../components/centeredLoader'
import { buildImageUploadUrl } from 'services/Images'

const defaultProps = {}
const propTypes = {
  featuredImage: PropTypes.object,
  focusedWaypoint: PropTypes.object.isRequired,
  isUpdatingWaypoints: PropTypes.bool,
  onDeleteWaypoint: PropTypes.func.isRequired,
  onDeselectWaypoint: PropTypes.func.isRequired,
  onSaveWaypoint: PropTypes.func.isRequired,
  onUpdateImage: PropTypes.func.isRequired,
  updatedLatLng: PropTypes.object
}

const WaypointInfo = (props) => {
  const [showModal, setShowModal] = useState()
  const attachImage = (images) => {
    const { onUpdateImage } = props
    const image = images[0]
    onUpdateImage({ uploaded_file: image.uploaded_file, ...images[0] })
    closeMediaModal()
  }

  const closeMediaModal = () => {
    setShowModal(false)
  }

  const openMediaModal = () => {
    setShowModal(true)
  }

  const { focusedWaypoint, isUpdatingWaypoints, onDeleteWaypoint, onDeselectWaypoint, onSaveWaypoint, updatedLatLng } = props
  const feature = focusedWaypoint.feature
  const currentFeaturedImage = props.featuredImage || focusedWaypoint.featured_image
  let featureImageUrl = null
  let waypointImageUrl = null

  if (feature && feature.image_attachments[0]) {
    // TODO need to refactor into a helper method for getting image URL
    const image = feature.image_attachments[0].image
    featureImageUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'small')
  }

  if (currentFeaturedImage && currentFeaturedImage.id && currentFeaturedImage.uploaded_file) {
    // TODO need to refactor into a helper method for getting image URL
    const image = currentFeaturedImage
    waypointImageUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'small')
  }

  return (
    <div className='outing-contentWrap'>
      {isUpdatingWaypoints &&
        <CenteredLoader overlay />}
      {featureImageUrl &&
        <img alt='Featured' className='outing-featureImage' src={featureImageUrl} />}
      {feature &&
        <div>
          <div className='outing-content'>
            <div className='waypoint-info'>
              <div className='waypoint-locationInfo'>
                <div className='waypoint-locationName'>{feature.name}</div>
                <div className='waypoint-locationType'>
                  {featureByType(feature.__typename).singular}
                  {feature.area && feature.area.name &&
                    <span> in {feature.area.name}</span>}
                </div>
                <div className='waypoint-locationDescription'>
                  <p>{feature.description ? feature.description : 'No description available.'}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='help'>
            <div className='title'>Localized Stop Information</div>
            <div className='content'>Use the form below to override the image, name, and/or description pulled from the Point of Interest (shown above). Any changes made below will only display within the context of this Outing.</div>
          </div>
        </div>}
      <div className='outing-content'>
        <div className='waypoint-info'>
          <label className='control-label'>Image</label>
          <div className='form-group waypoint-image'>
            <Button bsSize='small' className='waypoint-imageButton' onClick={() => openMediaModal()}>
              {`${(waypointImageUrl ? 'Change' : 'Add')} Image`}
            </Button>
            {waypointImageUrl &&
              <div
                className='waypoint-bannerImage'
                style={{
                  backgroundImage: `url(${waypointImageUrl})`
                }}
              />}
            {!waypointImageUrl &&
              <div
                className='waypoint-bannerImage'
                style={{
                  backgroundImage: 'none'
                }}
              />}
          </div>
        </div>
        <Formik
          initialValues={{
            id: focusedWaypoint.id,
            name: focusedWaypoint.name || '',
            description: focusedWaypoint.description || '<p></p>',
            featured_image_id: currentFeaturedImage?.id
          }}
          onSubmit={(values) => {
            onSaveWaypoint({
              description: values.description,
              featured_image_id: values.featured_image_id,
              id: values.id,
              name: (values.name && values.name && values.name.length) ? values.name : '',
              updatedLatLng: updatedLatLng
            })
            onDeselectWaypoint() // TODO: Should execute after delete
          }}
        >
          {formikProps => (
            <Form>
              <FormikGroup
                id='name'
                name='name'
                label='Name'
                type='text'
              />
              <FormikGroup
                id='description'
                name='description'
                label='Description'
                rows='6'
                type='wysiwyg'
              />
              <div className='waypoints-actions'>
                <Button
                  bsStyle='danger'
                  className='pull-left'
                  onClick={() => {
                    if (window.confirm('Are you sure you want to permanently delete this stop?')) {
                      onDeleteWaypoint(focusedWaypoint.id)
                      onDeselectWaypoint() // TODO: Should execute after delete
                    }
                  }}
                >
                  Delete
                </Button>
                <Button
                  bsStyle='primary'
                  disabled={!((updatedLatLng || formikProps.dirty) && formikProps.isValid)}
                  onClick={formikProps.handleSubmit}
                >
                  Save
                </Button>
                <Button
                  bsStyle='default'
                  onClick={() => {
                    if (updatedLatLng || formikProps.dirty) {
                      if (window.confirm('Are you sure you want to close the waypoint form? Your changes will be lost.')) {
                        onDeselectWaypoint()
                      }
                    } else {
                      onDeselectWaypoint()
                    }
                  }}
                >
                  Cancel
                </Button>
              </div>
              <MediaModal
                allowSelection
                onClose={closeMediaModal}
                onSubmit={(images) => {
                  formikProps.setFieldValue('featured_image_id', images[0].id, false)
                  attachImage(images)
                }}
                show={showModal}
                singleSelection
              />
            </Form>

          )}
        </Formik>

      </div>
    </div>
  )
}

WaypointInfo.propTypes = propTypes
WaypointInfo.defaultProps = defaultProps

export default WaypointInfo
