import MenuItem from 'react-bootstrap/lib/MenuItem'
import Dropdown from 'react-bootstrap/lib/Dropdown'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'

import { Mutations } from './ContentBundlesOperations'

import '../../components/visibilityDropdown/visibilityDropdown.css'

const VisibilityDropdown = (props) => {
  const { contentBundleId, initialValue } = props
  const [value, setValue] = useState(initialValue || 'Draft')
  let filteredOptions = []
  const visibilityOptions = [
    'Archive',
    'Publish'
  ]

  const [updateVisibility] = useMutation(
    Mutations.UpdateVisibilityForContentBundle, {
      onCompleted (data) {
        setValue()
        toast.success('Article visibility updated.')
      },
      onError (updateError) {
        toast.error(updateError)
        toast.error('The visibility could not be updated.')
      }
    }
  )

  const onChange = (option) => {
    if (window.confirm('Are you sure you want to update the visibility of this Article?')) {
      const visibility = (() => {
        if (option === 'Archive') {
          return 'Archived'
        } else {
          return 'Published'
        }
      })()

      updateVisibility({
        variables: {
          contentBundleId: contentBundleId,
          visibility: visibility
        }
      }).then(() => {
        setValue(visibility)
      })
    }
  }

  if (value) {
    filteredOptions = visibilityOptions.filter((option) => (option !== value) && (`${option}d` !== value) && (`${option}ed` !== value))
  }

  return (
    <Dropdown
      className='visibilityDropdown'
      disabled={(!value || value === 'Draft') && (!contentBundleId)}
      id={`visibility-dropdown-contentBundle-${contentBundleId}`}
      pullRight
    >
      <Dropdown.Toggle
        bsStyle='default'
      >
        <span
          className={`visibility-icon visibility-icon--${(value ? value.toLowerCase() : 'draft')}`}
        />
        {value || 'Draft'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filteredOptions.map((option) => {
          return (
            <MenuItem
              key={option}
              onClick={() => onChange(option)}
            >
              <span
                className={`visibility-icon visibility-icon--${option.toLowerCase()}`}
              />
              {option}
            </MenuItem>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

VisibilityDropdown.propTypes = {
  contentBundleId: PropTypes.number,
  initialValue: PropTypes.string
}

export default VisibilityDropdown
