import { gql } from '@apollo/client'

export const Fragments = {
  CampaignDetails: gql`
    fragment CampaignDetails on campaigns {
      application
      community_id
      created_at
      deep_link_url
      entity_id
      entity_type
      id
      updated_at
      user_id
      utm_campaign
      utm_medium
      utm_source
    }
  `
}

export const Mutations = {
  DeleteCampaignById: gql`
    mutation DeleteCampaignById($id: Int!) {
      delete_campaigns(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
  `,
  InsertCampaign: gql`
    mutation InsertCampaign(
      $application: String!,
      $communityId: Int!,
      $deepLinkUrl: String = "",
      $entityId: Int!,
      $entityType: String!,
      $organizationId: Int!,
      $utmCampaign: String!,
      $utmMedium: String,
      $utmSource: String
    ) {
      insert_campaigns(objects: {
        application: $application,
        community_id: $communityId,
        deep_link_url: $deepLinkUrl,
        entity_id: $entityId,
        entity_type: $entityType,
        organization_id: $organizationId,
        utm_campaign: $utmCampaign,
        utm_medium: $utmMedium,
        utm_source: $utmSource
      }) {
        returning {
          ...CampaignDetails
        }
      }
    }
    ${Fragments.CampaignDetails}
  `,
  UpdateCampaign: gql`
    mutation UpdateCampaign(
      $setInput: campaigns_set_input,
      $campaignId: Int!
    ) {
      update_campaigns(
        where: {id: {_eq: $campaignId}},
        _set: $setInput
      ) {
        returning {
          ...CampaignDetails
        }
      }
    }
    ${Fragments.CampaignDetails}
  `
}

export const Queries = {
  GetCampaignById: gql`
    query GetCampaignById($campaignId: Int!) {
      campaigns(where: {id: {_eq: $campaignId}}) {
        ...CampaignDetails
      }
    }
    ${Fragments.CampaignDetails}
  `,
  GetCampaignsByOrganizationId: gql`
    query GetCampaignsByOrganizationId($organizationId: Int!) {
      campaigns(
        where: {organization_id: {_eq: $organizationId}},
        order_by: {utm_campaign: asc}
      ) {
        ...CampaignDetails
      }
    }
    ${Fragments.CampaignDetails}
  `,
  GetOrganizationSlugById: gql`
    query GetOrganizationSlugById($organizationId: Int!) {
      organizations(where: {id: {_eq: $organizationId}}) {
        id
        slug
      }
    }
  `
}
