const askOuterSpatialAQuestion = (subject = '', message = '', currentUser) => {
  if (window.Beacon) {
    window.Beacon('prefill', {
      name: currentUser?.profile?.name ?? '',
      email: currentUser?.email ?? '',
      subject: subject,
      text: message
    })
    window.Beacon('open') // Launch the Help Scout Beacon popup
    window.Beacon('navigate', '/ask/') // Naviage to the Ask screen, where the message is pre-filled
  }
}

const handleContentStyleGuideClick = () => {
  if (window.Beacon) {
    window.Beacon('article', '66', { type: 'sidebar' })
  } else {
    Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/66-content-guidelines' }).click()
  }
}

export { askOuterSpatialAQuestion, handleContentStyleGuideClick }
