import { gql } from '@apollo/client'

export const DeletePost = gql`
    mutation DeletePost($post_id: Int!) {
        delete_challenge_responses(where: {post_id: {_eq:$post_id}}) {
            affected_rows
            returning {
                id  
            }                      
        }
        delete_posts(where:{parent_id:{_eq:$post_id}}) {
            affected_rows
            returning {
                id
            }            
        }
        delete_posts_by_pk(id: $post_id) {
            created_at
            id
            post_text
            feature_id
            feature_type
        }
        delete_likes(where: {likeable_id: {_eq:$post_id},likeable_type:{_eq:"Post"}}) {
            affected_rows
            returning {
                id  
            }                      
        }
        delete_image_attachments(where: {attacheable_id: {_eq:$post_id},attacheable_type:{_eq:"Post"}}) {
            affected_rows
            returning {
                id  
            }                      
        }
    }
        
`

export default DeletePost
