import { gql } from '@apollo/client'

export const Mutations = {
  InsertOnboardingProductEngagement: gql`
    mutation InsertOnboardingProductEngagement {
      insert_member_product_engagements_one(
        object: {
          feature: "onboarding",
          product: "OuterSpatial Manager"
        }
      ) {
        created_at
        feature
        id
        product
        properties
        updated_at
        user_id
      }
    }
  `
}

export const Queries = {
  GetCommunityUniqueVisitors: gql`
    query GetCommunityUniqueVisitors($gte: timestamptz!, $lt: timestamptz!, $organizationId: Int!) {
      communities(
        order_by: {
          is_sponsored: desc
        },
        where: {
          community_memberships: {
            member_id: {
              _eq: $organizationId
            }
          }
        }
      ) {
        id
        name
        community_unique_view_content_by_day(
          where: {
            _and: [
              {
                day: {
                  _gte: $gte
                }
              },
              {
                day: {
                  _lt: $lt
                }
              }
            ]
          }
        ) {
          application
          count
          day
        }
      }
    }
  `,
  GetCommunityScreenPageOpens: gql`
    query GetCommunityScreenPageOpens($gte: timestamptz!, $lt: timestamptz!, $organizationId: Int!) {
      communities(
        order_by: {
          is_sponsored: desc
        },
        where: {
          community_memberships: {
            member_id: {
              _eq: $organizationId
            }
          }
        }
      ) {
        id
        name
        community_view_content_by_day(
          where: {
            _and: [
              {
                day: {
                  _gte: $gte
                }
              },
              {
                day: {
                  _lt: $lt
                }
              }
            ]
          }
        ) {
          application
          count
          day
        }
      }
    }
  `
}
