import Panel from 'react-bootstrap/lib/Panel'
import { React, useEffect } from 'react'
import { useHistory } from 'react-router'

import { askOuterSpatialAQuestion } from 'components/helpScout'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import PostsList from '../../components/posts/posts/PostsList'

import './socialPosts.css'

const SocialPosts = () => {
  const currentUser = useCurrentUser()
  const history = useHistory()
  let isOrganizationAdmin = false
  const linkStyle = {
    color: '#41A7D0',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
  const organization = useCurrentOrganization()

  const handleSignUpClick = () => {
    if (organization.license_tier === 'free') {
      history.push('./organization/billing')
    } else {
      askOuterSpatialAQuestion(
        'Publish Organization Into a Community',
        null,
        currentUser
      )
    }
  }

  organization.memberships.forEach((membership) => {
    if ((membership.user_email.email === currentUser.email) && (membership.role === 'admin')) {
      isOrganizationAdmin = true
    }
  })

  const isSuperAdmin = currentUser.roles ? currentUser.roles.filter(role => role.role.name === 'admin').length > 0 : false

  useEffect(() => {
    document.title = `Social | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  return (
    <div className='socialPosts'>
      {organization && organization.license_tier === 'free' &&
        <div className='empty-message text-center'>
          <h3>Social is not available because your organization is on the "Free" plan.</h3>
          {(isOrganizationAdmin || isSuperAdmin) &&
            <h3><span onClick={handleSignUpClick} style={linkStyle}>Sign up for a license</span> to access Social now.</h3>}
        </div>}
      {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length === 0 &&
        <Panel>
          <Panel.Body>
            <div className='empty-message text-center'>
              <h3>Social is not available because your organization has not been added to a community.</h3>
              <h3><span onClick={handleSignUpClick} style={linkStyle}>Reach out to customer success</span> to publish your organization into its first community.</h3>
            </div>
          </Panel.Body>
        </Panel>}
      {organization && organization.license_tier !== 'free' && organization.communities && organization.communities.length > 0 &&
        <PostsList />}
    </div>
  )
}

export default SocialPosts
