import { Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import MapsList from './FeatureMapsList'
import PaperMap from './FeaturePaperMap'

import './featureMaps.css'

const FeatureMaps = (props) => {
  const { match, feature } = props
  return (
    <Switch>
      <Route exact path={match.url} render={() => <Redirect to={`${match.url}/paper`} />} />
      <Route exact path={`${match.url}/paper/:mapId`} render={({ match }) => <PaperMap feature={feature} match={match} />} />
      <Route path={`${match.url}/:mapType`} render={({ match }) => <MapsList feature={feature} match={match} />} />
    </Switch>
  )
}

FeatureMaps.propTypes = {
  feature: PropTypes.object,
  match: PropTypes.object
}

export default FeatureMaps
