import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/lib/Button'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { CenteredLoader } from '../../components/centeredLoader'
import { PageContent, PageHeader, PageLayout } from '../../components/pageLayout'
import Panel from 'react-bootstrap/lib/Panel'
import * as mediaValidators from '../../models/media/media.validators'
import './user.css'
import UserSettingsBasic from './UserSettings.Basic'
import PropTypes from 'prop-types'
import { gql } from '@apollo/client/core'
import { useMutation } from '@apollo/client'
import { buildProfileImageUrl } from 'services/Images'

import { askOuterSpatialAQuestion } from '../../components/helpScout'
import { useAuth } from 'contexts/AuthContext'

const User = ({ currentUser, setCurrentUser }) => {
  const { accessToken } = useAuth()
  const handleCustomerSuccessClick = () => {
    askOuterSpatialAQuestion(
      'Update the Email Address Associated With My Account',
      'Hello, I\'d like to update the email address associated with my OuterSpatial account...',
      currentUser
    )
  }
  const [imageError, setImageError] = useState()
  const [dirtyPhoto, setDirtyPhoto] = useState()
  const linkStyle = {
    textDecoration: 'underline',
    color: '#41A7D0',
    cursor: 'pointer'
  }
  const [photoFile, setPhotoFile] = useState({ photo: buildProfileImageUrl(currentUser.profile.id, currentUser.profile.photo_file, 'medium_square') })

  const UPDATE_PROFILE_QUERY = gql`
    mutation UpdateProfile($id: Int!, $last_name: String, $first_name: String, $name: String) {
      update_member_profiles_by_pk(
        pk_columns: {
          id: $id
        },
        _set: {
          first_name: $first_name,
          last_name: $last_name
        }
      ) {
        first_name
        id
        last_name
      }
    }
  `

  const UPDATE_PROFILE_IMAGE_FIELDS = gql`
    mutation UpdateProfileImageFields($id: Int!) {
      update_member_profiles_by_pk(
        pk_columns: {
          id: $id
        },
        _set: {
          uploaded: true
        }
      ) {
        id
      }
    }
  `
  const [mutateProfileFunction] = useMutation(UPDATE_PROFILE_QUERY)
  const [updateProfileImageFields] = useMutation(UPDATE_PROFILE_IMAGE_FIELDS)

  const setImage = (file) => {
    return () => {
      const reader = new FileReader()

      reader.onload = (e) => {
        setPhotoFile({
          photo_file: file,
          photo_file_src: e.target.result
        })
        setDirtyPhoto(true)
      }
      reader.readAsDataURL(file)
    }
  }

  const formikProps = {
    initialValues: {
      first_name: currentUser?.profile.first_name,
      last_name: currentUser?.profile.last_name
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(30, 'Must be 30 characters or less')
        .required('A first name is required'),
      last_name: Yup.string()
        .max(30, 'Must be 30 characters or less')
        .required('A last name is required')
    }),
    onSubmit: values => {
      handleSave(values)
    }
  }

  useEffect(() => {
    document.title = `User Settings | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  const handlePhotoUpload = async (file) => {
    console.log('handlePhotoUpload')
    const presignedResult = await fetch(`${process.env.REACT_APP_API_SERVICE_URL}/uploads/profile-presigned-url`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({filename: file.name,id: currentUser.profile.id})
      })
    const presignedBody = await presignedResult.json()
    console.log(presignedBody)
    const uploadUrl = presignedBody.url
    const FormData = require('form-data');

    const formData = new FormData();
    // append the fields in presignedPostData in formData
    Object.keys(presignedBody.fields).forEach(key => {
      formData.append(key, presignedBody.fields[key])
    })

    // append the file
    formData.append('file', file)

    const uploadResponse = await fetch(uploadUrl, { method: 'POST', body: formData })
    if (uploadResponse.status === 204) {
      console.log('Upload successful')
      console.log(await uploadResponse.text())
      updateProfileImageFields({ variables: { id: currentUser.profile.id } }).then(() => {
        toast.success('Profile photo updated successfully.')
      })
    }
  }

  const handleSave = (model) => {
    const name = [model.first_name, model.last_name].join(' ')
    mutateProfileFunction({ variables: { id: currentUser.profile.id, first_name: model.first_name, last_name: model.last_name, name: name } }).then(() => {
      const updatedUser = { ...currentUser, profile: { ...currentUser.profile, first_name: model.first_name, last_name: model.last_name } }

      if (dirtyPhoto) {
        handlePhotoUpload(photoFile.photo_file)
      }

      setCurrentUser(updatedUser)
      toast.success('Settings updated successfully.')
    })
  }

  const selectInputFiles = (event) => {
    const files = event.target.files || []
    if (files.length) {
      const errors = mediaValidators.image(files[0])
      if (errors.length) {
        setImageError(errors[0])
      } else {
        setImageError(null)
        setImage(files[0])()
      }
    }
  }

  if (!currentUser) {
    return (
      <CenteredLoader />
    )
  }

  return (
    <div className='user-wrap'>
      <Formik {...formikProps}>
        {formik => (
          <PageLayout>
            <PageHeader>
              <div className='user-header'>
                <div className='user-header__title'>
                  <div className='user-header__titleText'>
                    <h1>Settings</h1>
                  </div>
                  <div className='user-header__actions'>
                    <Button
                      bsStyle='primary'
                      onClick={formik.handleSubmit}
                      disabled={!(formik.dirty && formik.isValid) && !dirtyPhoto}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </PageHeader>
            <PageContent>
              <Panel>
                <Panel.Heading>Email Address</Panel.Heading>
                <Panel.Body>
                  <p>The email address associated with your account is <b><i>{currentUser.email}</i></b>. If you'd like to update it, <span onClick={handleCustomerSuccessClick} style={linkStyle}>reach out</span> to our customer success team.</p>
                </Panel.Body>
              </Panel>
              <UserSettingsBasic
                photoFile={photoFile}
                imageError={imageError}
                onFileSelect={selectInputFiles}
              />
            </PageContent>

          </PageLayout>
        )}
      </Formik>
    </div>
  )
}

User.propTypes = {
  currentUser: PropTypes.object,
  setCurrentUser: PropTypes.func
}

export default User
