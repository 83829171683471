export const timezones = [
  { value: 'America/Anchorage', label: 'Alaska' },
  { value: 'America/St_Thomas', label: 'Atlantic' },
  { value: 'America/Chicago', label: 'Central' },
  { value: 'Pacific/Guam', label: 'Guam' },
  { value: 'America/New_York', label: 'Eastern' },
  { value: 'Pacific/Honolulu', label: 'Hawaii' },
  { value: 'America/Denver', label: 'Mountain' },
  { value: 'America/Phoenix', label: 'Mountain (Arizona)' },
  { value: 'America/Los_Angeles', label: 'Pacific' }
]
