import { Field, Form, Formik } from 'formik'
import { useMutation, gql } from '@apollo/client'
import Button from 'react-bootstrap/lib/Button'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

import _ from 'lodash'
import * as Yup from 'yup'
import { buildImageUploadUrl } from 'services/Images'
import { CenteredLoader } from 'components/centeredLoader'
import { handleContentStyleGuideClick } from '../../../components/helpScout'
import { Queries } from '../OutingsOperations'
import AssociationSet from 'components/associationSet'
import Editor from '../../../components/editorWithHooks'
import FeatureSeasons from './Outing.Seasons'
import FeatureTagSet from '../../../components/featureTagSet/FeatureTagSet'
import FormikGroup from 'components/fieldGroup/FormikGroup'
import MediaModal from '../../../components/media/modal/MediaModal'
import StewardshipSet from 'components/stewardshipSet'

const DeleteAttachmentMutation = gql`
  mutation DeleteAttachments($ids: [Int!]!) {
    delete_attachments(where: {id: {_in: $ids}}){
      affected_rows
        returning {
          id
        }
    }
  }
`
const DeleteStewardshipsMutation = gql`
  mutation DeleteStewardships($ids: [Int!]!) {
    delete_stewardships(where: {id: {_in: $ids}}){
      affected_rows
        returning {
          id
        }
    }
  }
`
const InsertAreaAttachmentMutation = gql`
  mutation InsertAreaAttachment($object: attachments_insert_input!) {
    insert_attachments_one(object: $object) {
      id
      feature_type
      feature_id
      attached_type
      attached_id
      created_at
    }
  }  
`
const InsertStewardshipsMutation = gql`
  mutation InsertStewardships($objects: [stewardships_insert_input!]!) {
    insert_stewardships(objects: $objects) {
      affected_rows
      returning {
        id
        created_at
        role
        organization_id
        stewardable_id
        stewardable_type
        organization {
          id
          name
        }
      }
    }    
  }
`
const difficultyOptions = [
  {
    label: 'Beginner',
    value: 'Beginner'
  },
  {
    label: 'Intermediate',
    value: 'Intermediate'
  },
  {
    label: 'Expert',
    value: 'Expert'
  }
]
const routeTypeOptions = [
  {
    label: 'One-way',
    value: 'One-way'
  },
  {
    label: 'Loop',
    value: 'Loop'
  },
  {
    label: 'Out-and-back',
    value: 'Out-and-back'
  }
]

const getImageUrl = (image) => {
  let imageUrl = null

  if (image?.id && image?.uploaded_file) {
    imageUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'small')
  }

  return imageUrl
}

const OutingInfo = (props) => {
  const { handleSubmitForm, outing, formRef } = props
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [selectedAccessibility, setSelectedAccessibility] = useState([])
  const [selectedAllowedActivities, setSelectedAllowedActivities] = useState([])
  const [selectedAllowedAccess, setSelectedAllowedAccess] = useState([])
  const [selectedRulesAndRegulations, setSelectedRulesAndRegulations] = useState([])
  const [selectedSeasons, setSelectedSeasons] = useState([])
  const [stewardships, setStewardships] = useState(outing.stewardships)
  const [associations, setAssociations] = useState(outing.outing_areas)
  const [insertStewardships] = useMutation(InsertStewardshipsMutation, { refetchQueries: [Queries.GetOutingById] })
  const [insertAreaAttachment] = useMutation(InsertAreaAttachmentMutation, { refetchQueries: [Queries.GetOutingById] })
  const [deleteStewardships] = useMutation(DeleteStewardshipsMutation, { refetchQueries: [Queries.GetOutingById] })
  const [deleteAttachment] = useMutation(DeleteAttachmentMutation, { refetchQueries: [Queries.GetOutingById] })
  const [imageUrl, setImageUrl] = useState(null)
  const initialValues = { ...outing }
  const OutingValidationSchema = Yup.object().shape({
    accessibility_description: Yup.string().ensure(),
    description: Yup.string(),
    name: Yup.string().required('This field is required.')
  })

  if (outing.accessibility_description === null) {
    initialValues.accessibility_description = ''
  }

  if (outing.description === null) {
    initialValues.description = ''
  }

  if (outing.name === null) {
    initialValues.name = ''
  }

  // Set the initial state imageUrl from the existing featured image specified in the Outing
  if (outing.featured_image && imageUrl === null) {
    setImageUrl(getImageUrl(outing.featured_image))
  }

  // Set initial seasons from outing.tags
  if (selectedSeasons.length === 0 && outing.tags.length > 0) {
    const validSeasons = [
      'winter',
      'spring',
      'summer',
      'fall'
    ]
    const seasons = outing.tags.filter(tag => (_.indexOf(validSeasons, tag.key) > -1))

    if (seasons.length > 0) {
      setSelectedSeasons(seasons)
    }
  }

  const openMediaModal = () => {
    setShowMediaModal(true)
  }

  const closeMediaModal = () => {
    setShowMediaModal(false)
  }

  const onSeasonChange = (seasons) => {
    const newSeasonTags = seasons.map(s => { return { key: s } })
    setSelectedSeasons(newSeasonTags)
  }

  const handleSeasonChange = seasons => {
    const newSeasons = [...seasons]

    if (onSeasonChange) {
      onSeasonChange(newSeasons)
    }
  }

  const handleAddStewardship = (stewardship) => {
    const newStewardship = {
      stewardable_id: outing.id,
      stewardable_type: 'Outing',
      organization_id: stewardship.organization.id,
      organization: { name: stewardship.organization.name },
      role: stewardship.role
    }

    // Ensure that this stewardships doesn't already exist, otherwise we duplicate it.
    if (!stewardships.filter(s => (s.organization_id === newStewardship.organization_id && s.role === newStewardship.role)).length) {
      setStewardships([
        ...stewardships,
        newStewardship
      ])
    }
  }

  const handleDestroyStewardship = (stewardship) => {
    setStewardships(stewardships.filter(s =>
      !(s.organization_id === stewardship.organization_id &&
        s.role === stewardship.role)
    ))
  }

  const handleAddAssociation = (association) => {
    insertAreaAttachment({
      variables: {
        object: {
          attached_id: outing.id,
          attached_type: 'Outing',
          feature_id: association.feature.id,
          feature_type: 'Area'
        }
      }
    }).then(() => {
      toast.success('Area added')
    })
    setAssociations([...associations, association])
  }

  const handleDeleteAssociation = (association) => {
    deleteAttachment({
      variables: {
        ids: [association.id]
      }
    }).then(() => {
      toast.success('Area removed')
    })
    setAssociations(associations.filter(item => {
      return item.id !== association.id
    }))
  }
  const processSubmitForm = (values, formikBag) => {
    // If the user hasn't interacted with the FeatureSeasons, the state will be empty.
    //   So we will set the default to all seasons when submitted
    const seasons = (selectedSeasons.length > 0 && selectedSeasons.length < 4) ? selectedSeasons : []
    const newOuting = values

    newOuting.accessibility_description = stringIsNilOrWhitespace(newOuting.accessibility_description) ? null : newOuting.accessibility_description.trim()
    newOuting.description = stringIsNilOrWhitespace(newOuting.description) ? null : newOuting.description.trim()
    newOuting.name = stringIsNilOrWhitespace(newOuting.name) ? null : newOuting.name.trim()
    // We only need the key value to send up to use for the mutation since the other values are independent of the tag UI
    newOuting.tags = [
      ...selectedAccessibility,
      ...selectedAllowedActivities,
      ...selectedAllowedAccess,
      ...selectedRulesAndRegulations,
      ...seasons
    ].map((tag) => ({ key: tag.key }))

    handleSubmitForm(newOuting, formikBag)

    if (stewardships && stewardships.length > 0) {
      saveStewardships()
    }
  }

  const stringIsNilOrWhitespace = (input) => {
    // Handle weird Redactor bug.
    if (input === '<p><br></p>') {
      return true
    }

    return (input?.trim()?.length || 0) === 0
  }

  const saveStewardships = () => {
    const remainingStewardships = stewardships.filter(item => { return item.id })
    const newStewardships = stewardships.filter(item => {
      return !item.id
    }).map(item => {
      return {
        organization_id: item.organization_id,
        role: item.role,
        stewardable_type: 'Outing',
        stewardable_id: item.stewardable_id
      }
    })

    const existingIds = outing.stewardships.map(item => item.id)
    const newIds = stewardships.map(item => item.id)
    const deletedIds = existingIds.filter((item) => { return !newIds.includes(item) }).filter(n => n)

    if (newStewardships.length > 0) {
      insertStewardships({
        variables: {
          objects: newStewardships
        }
      }).then((results) => {
        const savedStewardships = results.data.insert_stewardships.returning
        setStewardships([...remainingStewardships, ...savedStewardships])
        toast.success('Created stewardships')
      })
    }

    if (deletedIds.length > 0) {
      deleteStewardships({
        variables: {
          ids: deletedIds
        }
      }).then(() => {
        toast.success('Removed stewardships')
      })
    }
  }

  return (
    <div className='outingInfo-wrap'>
      <div className='outingInfo-content'>
        <Formik
          initialValues={{ ...initialValues }}
          innerRef={formRef}
          onSubmit={(values, formikBag) => {
            processSubmitForm(values, formikBag)
          }}
          validationSchema={OutingValidationSchema}
        >
          {(formikProps) => {
            return (
              <>
                {formikProps.isSubmitting &&
                  <CenteredLoader overlay />}
                <Form noValidate>
                  <Panel>
                    <Panel.Heading>
                      Basic Info
                      <div style={{ position: 'absolute', right: '15px', top: '21px' }}>
                        <Button
                          bsStyle='link'
                          onClick={handleContentStyleGuideClick}
                        >
                          Content Style Guide
                        </Button>
                      </div>
                    </Panel.Heading>
                    <Panel.Body>
                      <div>
                        <label className='control-label'>Featured Image</label>
                        <div className={'form-group contentBundleSection-image ' + (!imageUrl ? 'contentBundleImage--hasLogo' : '')}>
                          <Button className='contentBundleImage-imageButton' onClick={() => openMediaModal()} bsSize='small'>
                            {(!imageUrl ? 'Change' : 'Add') + ' Image'}
                          </Button>
                          {!!imageUrl &&
                            <div
                              className='contentBundleSection-bannerImage'
                              style={{
                                backgroundImage: `url(${imageUrl})`
                              }}
                            />}
                        </div>
                      </div>
                      <Field name='name'>
                        {({ field, meta }) => (
                          <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                            <label className='control-label' htmlFor='name'>Name *</label>
                            <input className='form-control' id='name' type='text' {...field} />
                            {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                          </div>
                        )}
                      </Field>
                      <Field name='description'>
                        {({ field, form, meta }) => (
                          <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                            <label className='control-label' htmlFor='description'>Description</label>
                            <Editor
                              id={field.name}
                              className='form-control'
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              value={field.value}
                            />
                            {meta.touched && meta.error && (<div className='error-message'>{meta.error}</div>)}
                          </div>
                        )}
                      </Field>
                      <FormikGroup
                        label='Route Type'
                        type='select'
                        id='route_type'
                        name='route_type'
                        options={routeTypeOptions}
                      />
                      <FormikGroup
                        label='Difficulty'
                        type='select'
                        id='difficulty'
                        name='difficulty'
                        options={difficultyOptions}
                      />
                      <MediaModal
                        allowSelection
                        onClose={closeMediaModal}
                        onSubmit={(images) => {
                          const image = images[0]

                          formikProps.setFieldValue('featured_image', { id: image.id })
                          setImageUrl(getImageUrl(image))
                          closeMediaModal()
                        }}
                        show={showMediaModal}
                        singleSelection
                      />
                    </Panel.Body>
                  </Panel>
                  {outing.id && (
                    <Panel>
                      <Panel.Heading>Related Areas</Panel.Heading>
                      <Panel.Body>
                        <AssociationSet
                          all
                          associations={associations}
                          entityTypes={['Area']}
                          isMultipleAssociationSet
                          onAdd={handleAddAssociation}
                          onRemove={handleDeleteAssociation}
                          restrictToCurrentOrganization
                        />
                      </Panel.Body>
                    </Panel>)}
                  {outing.id && (
                    <Panel>
                      <Panel.Heading>Owning Organizations</Panel.Heading>
                      <Panel.Body>
                        <ul>
                          {(outing.stewardships.filter(o => o.role === 'owner').length < 1) && (
                            <li key='none'>
                              None
                            </li>)}
                          {outing.stewardships.filter(o => o.role === 'owner').map((s) => {
                            return (
                              <li key={s.id}>
                                {s.organization.name}
                              </li>
                            )
                          })}
                        </ul>
                      </Panel.Body>
                    </Panel>)}
                  {outing.id && (
                    <Panel>
                      <Panel.Heading>Managing Organizations</Panel.Heading>
                      <Panel.Body>
                        <StewardshipSet
                          onAdd={handleAddStewardship}
                          onRemove={handleDestroyStewardship}
                          roleType='manager'
                          stewardships={stewardships}
                        />
                      </Panel.Body>
                    </Panel>)}
                  {outing.id && (
                    <Panel>
                      <Panel.Heading>Partner Organizations</Panel.Heading>
                      <Panel.Body>
                        <StewardshipSet
                          onAdd={handleAddStewardship}
                          onRemove={handleDestroyStewardship}
                          roleType='partner'
                          stewardships={stewardships}
                        />
                      </Panel.Body>
                    </Panel>)}
                  <Panel>
                    <Panel.Heading>Details</Panel.Heading>
                    <Panel.Body>
                      <label className='control-label'>Seasons of Availability</label>
                      <FeatureSeasons
                        onSeasonChange={handleSeasonChange}
                        tags={outing.tags}
                      />
                      <div className='form-group'>
                        <label className='control-label' htmlFor='allowed_access'>Allowed Access</label>
                        <FeatureTagSet
                          categoryName='Allowed Access'
                          featureType='Trail'
                          name='allowed_access'
                          onChange={setSelectedAllowedAccess}
                          placeholder='Who can access this Outing?'
                          selectedTags={outing.tags}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label' htmlFor='allowed_activities'>Allowed Activites</label>
                        <FeatureTagSet
                          categoryName='Activities'
                          featureType='Trail'
                          name='allowed_activities'
                          onChange={setSelectedAllowedActivities}
                          placeholder='What activities are permitted on this Outing?'
                          selectedTags={outing.tags}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label' htmlFor='accessibility'>Accessibility</label>
                        <FeatureTagSet
                          categoryName='Accessibility'
                          featureType='Trail'
                          name='accessibility'
                          onChange={setSelectedAccessibility}
                          placeholder='Is this Outing accessible for all?'
                          selectedTags={outing.tags}
                        />
                      </div>
                      <div className='form-group'>
                        <label className='control-label' htmlFor='rules_and_regulations'>Rules &amp; Regulations</label>
                        <FeatureTagSet
                          categoryName='Rules & Regulations'
                          featureType='Trail'
                          name='rules_and_regulations'
                          onChange={setSelectedRulesAndRegulations}
                          placeholder='Are there Outing restrictions?'
                          selectedTags={outing.tags}
                        />
                      </div>
                      <Field name='accessibility_description'>
                        {({ field, form, meta }) => (
                          <div className={`form-group${meta.touched && meta.error ? ' has-error' : ''}`}>
                            <label className='control-label' htmlFor='accessibility_description'>Accessibility Description</label>
                            <Editor
                              id={field.name}
                              className='form-control'
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                            />
                            {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                            <span className='help-block'>One-to-two paragraphs about the general accessibility features of this Outing.</span>
                          </div>
                        )}
                      </Field>
                    </Panel.Body>
                  </Panel>
                </Form>
              </>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

OutingInfo.propTypes = {
  formRef: PropTypes.object,
  handleSubmitForm: PropTypes.func,
  outing: PropTypes.object.isRequired
}

export default OutingInfo
