import { gql } from '@apollo/client'

export const Mutations = {
  UpdateVisibilityForOuting: gql`
    mutation UpdateVisibilityForOuting(
      $outingId: Int!,
      $visibility: String!
    ) {
      update_outings(
        where: {id: {_eq: $outingId}},
        _set: {
          visibility: $visibility
        }
      ) {
        returning {
          id
          visibility
        }
      }
    }
  `
}
