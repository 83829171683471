import 'bootstrap/dist/css/bootstrap.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Replay } from '@sentry/replay'

import Root from './Root'
import { ManagerVersion } from './ManagerVersion'

import './theme.css'
import './config.css'

if (process.env.REACT_APP_STAGE_NAME === 'production') {
  Sentry.init({
    dsn: 'https://316a5c4c7d264f9f88fb851384606f1b@o851292.ingest.sentry.io/5818797',
    environment: process.env.REACT_APP_STAGE_NAME || process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing(),
      new Replay({
        sessionSampleRate: 0.1,
        errorSampleRate: 1.0
      })
    ],
    release: `outerspatial-manager@${ManagerVersion}`,
    tracesSampleRate: 0.2
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Root />
)
