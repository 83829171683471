import { useMutation, useQuery } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { FastField, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/lib/Alert'
import Button from 'react-bootstrap/lib/Button'
import Panel from 'react-bootstrap/lib/Panel'
import { useHistory, useRouteMatch } from 'react-router'
import ReactSelect from 'react-select'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import EntitySelect from '../../../components/associationSet/EntitySelect'
import { CenteredLoader } from '../../../components/centeredLoader'
import MediaModal from '../../../components/media/modal/MediaModal'
import Error404 from '../../errors/404'
import { Fragments, Mutations, Queries } from '../HeroItemOperations'
import { buildImageUploadUrl } from 'services/Images'
import './heroItem.css'

const HeroItemForm = (props) => {
  const history = useHistory()
  const organization = useCurrentOrganization()
  const match = useRouteMatch()
  const { bindSubmitForm } = props
  const heroItemId = isNaN(match.params.heroItemId) ? -1 : match.params.heroItemId
  const [heroItemType, setHeroItemType] = useState('Area')
  const [selectedEntityId, setSelectedEntityId] = useState(-1)
  const [selectedImage, setSelectedImage] = useState(null)
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [positionOfNewHeroItem, setPositionOfNewHeroItem] = useState(1)

  const { data: getData, error: getError, loading: getIsLoading } = useQuery(
    Queries.GetHeroItemById, {
      skip: heroItemId === -1,
      variables: {
        heroItemId: heroItemId
      }
    })

  const { error: getPositionError, loading: getPositionIsLoading } = useQuery(
    Queries.GetMaxPositionOfHeroItems, {
      skip: !organization,
      variables: {
        organizationId: organization.id
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const maxPosition = data.organizations && data.organizations[0] ? data.organizations[0].hero_items_aggregate?.aggregate?.max?.position : null

        if (maxPosition && (maxPosition + 1 !== positionOfNewHeroItem)) {
          setPositionOfNewHeroItem(maxPosition + 1)
        } else if (positionOfNewHeroItem !== 1) {
          setPositionOfNewHeroItem(1)
        }
      }
    }
  )

  const [insertHeroItem, { loading: insertIsLoading }] = useMutation(
    Mutations.InsertHeroItem, {
      onCompleted: (data) => {
        const newHeroItemId = data.insert_hero_items.returning[0].id
        const newRoute = match.path.split(':heroItemId')[0]

        toast.success('Bulletin Board item created.')
        history.replace(`${newRoute}${newHeroItemId}`)
      },
      onError: () => {
        toast.error('The Bulletin Board item could not be created.')
      },
      update: (cache, response) => {
        cache.modify({
          fields: {
            hero_items (existingHeroItems = []) {
              const fragment = Fragments.HeroItemDetails
              const newHeroItemRef = cache.writeFragment({
                data: response.data.insert_hero_items.returning[0],
                fragment: fragment
              })

              return [...existingHeroItems, newHeroItemRef]
            }
          }
        })
      }
    }
  )

  const [updateHeroItem, { loading: updateIsLoading }] = useMutation(
    Mutations.UpdateHeroItem, {
      onCompleted: () => {
        toast.success('Bulletin Board item updated.')
      },
      onError: () => {
        toast.error('The Bulletin Board item could not be updated.')
      }
    }
  )

  const handleAttachImage = (selection) => {
    setSelectedImage(selection[0])
    handleMediaModalClose()
  }

  const handleMediaModalClose = () => {
    setShowMediaModal(false)
  }

  if (getError?.message) {
    return <Alert bsStyle='danger'>{getError.message}</Alert>
  } else if (getPositionError?.message) {
    return <Alert bsStyle='danger'>{getPositionError.message}</Alert>
  } else if (getIsLoading || getPositionIsLoading) {
    return <CenteredLoader />
  } else {
    // TODO: Here's where all the Formik stuff goes…
    let initialValues = {
      link_id: -1,
      link_type: 'Area',
      link_url: '',
      title: ''
    }

    if (getData && getData.hero_items?.length > 0) {
      initialValues = getData.hero_items[0]

      if (initialValues.link_type) {
        // This covers the available entity types since URLs are `link_type=null`

        if (heroItemType !== initialValues.link_type) {
          setHeroItemType(initialValues.link_type)
        }

        if (initialValues.link_id && (initialValues.link_id !== selectedEntityId) && (selectedEntityId === -1)) {
          setSelectedEntityId(initialValues.link_id)
        }
      } else if (heroItemType !== 'URL') {
        // 'External Web Page' / URL types
        setHeroItemType('URL')
      }

      if (initialValues.image && (!selectedImage)) {
        setSelectedImage(initialValues.image)
      }
    } else if (heroItemId !== -1) {
      return <Error404 />
    }

    const heroItemTypes = [
      { label: 'Area', value: 'Area' },
      { label: 'Article', value: 'ContentBundle' },
      { label: 'Challenge', value: 'Challenge' },
      { label: 'Event', value: 'Event' },
      { label: 'External Web Page', value: 'URL' },
      { label: 'Organization', value: 'Organization' },
      { label: 'Outing', value: 'Outing' },
      { label: 'Point Of Interest', value: 'PointOfInterest' },
      // { label: 'Post', value: 'Post' },
      { label: 'Trail', value: 'Trail' }
    ]

    return (
      <div className='heroItemInfo-wrap'>
        {(insertIsLoading || updateIsLoading) &&
          <CenteredLoader overlay />}
        <div className='featureInfo-content'>
          <Panel>
            <Panel.Body>
              <Formik
                initialValues={{ ...initialValues }}
                onSubmit={(values, { setSubmitting }) => {
                  // Double check that we have the required data
                  if ((values.link_type !== null && values.link_type !== 'Organization' && values.link_type !== 'URL') && (!values.link_id || values.link_id === -1)) {
                    toast.error('Entity selection is required')
                    return
                  }

                  if ((values.link_type === null || values.link_type === 'URL') && (!values.link_url || values.link_id === '')) {
                    toast.error('Web URL is required')
                    return
                  }

                  if (!selectedImage?.id) {
                    toast.error('An image selection is required.')
                    return
                  }

                  // Create the base variable object for the mutations
                  let mutationVariables = {
                    feature_id: organization.id,
                    feature_type: 'Organization', // ALWAYS
                    image_id: selectedImage.id,
                    // link_text is UNUSED
                    title: values.title,
                    position: values.position || positionOfNewHeroItem // We manually manage this.
                  }

                  if (heroItemType === 'URL') {
                    mutationVariables = {
                      ...mutationVariables,
                      link_url: values.link_url,
                      link_type: null // NULL when the Bulletin Board item is a URL
                    }
                  } else if (heroItemType === 'Organization') {
                    mutationVariables = {
                      ...mutationVariables,
                      link_id: organization.id,
                      link_type: heroItemType
                    }
                  } else {
                    mutationVariables = {
                      ...mutationVariables,
                      link_id: values.link_id,
                      link_type: heroItemType // this is the entity_type (i.e. 'PointOfInterest', 'ContentBundle'…)
                    }
                  }

                  if (heroItemId === -1) {
                    insertHeroItem({
                      variables: mutationVariables
                    })
                  } else {
                    updateHeroItem({
                      variables: {
                        ...mutationVariables,
                        id: heroItemId
                      }
                    })
                  }

                  setSubmitting(false)
                }}
                validationSchema={Yup.object({
                  link_url: Yup
                    .string().min(1).nullable(true).url('Please enter a valid web URL. Must start with either http:// or https://.'),
                  title: Yup
                    .string().nullable(false).required('Please enter a title for the Bulletin Board item.')
                })}
              >
                {(formikProps) => {
                  const renderFormTypeLabel = (value) => heroItemTypes.filter((option) => option.value === heroItemType)[0].label
                  const renderImageUrl = (image) => {
                    let imageUrl = null

                    if (image?.id && image.uploaded_file) {
                      // This is the shape returned by the GraphQL Query for hero_items.image
                      imageUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'small')
                    }

                    return imageUrl
                  }
                  const disableEditing = (heroItemId !== -1)
                  const imageUrl = renderImageUrl(selectedImage)

                  bindSubmitForm(formikProps.submitForm)

                  return (
                    <Form noValidate>
                      <div className='panel-header'>
                        <label className='control-label'>Type</label>
                        <ReactSelect
                          clearable={false}
                          id='formType'
                          isDisabled={disableEditing}
                          onChange={(selected) => {
                            const newTouchedValue = !formikProps.touched?.link_id
                            // toggle the touched value of link_id to trigger a rerender since it is a FastField
                            formikProps.setFieldTouched('link_id', newTouchedValue)
                            formikProps.setFieldValue('link_id', -1)
                            formikProps.setFieldValue('link_type', selected.value)
                            formikProps.setFieldValue('link_url', '')
                            setSelectedEntityId(-1)
                            setHeroItemType(selected.value)
                          }}
                          options={heroItemTypes}
                          value={heroItemTypes.filter((option) => option.value === heroItemType)}
                        />
                      </div>
                      <div className='panel-body'>
                        {heroItemType !== 'Organization' && heroItemType !== 'URL' && (
                          <FastField name='link_id'>
                            {({ field, form, meta }) => (
                              <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                <label className='control-label' htmlFor='link_id'>{renderFormTypeLabel(heroItemType)} *</label>
                                <div className='select-wrap'>
                                  <EntitySelect
                                    entityTypes={[heroItemType]}
                                    onAdd={(option) => {
                                      setSelectedEntityId(option.value.value.id)
                                      formikProps.setFieldValue(field.name, option.value.value.id)
                                    }}
                                    restrictToCurrentOrganization
                                    value={(selectedEntityId && heroItemType)
                                      ? { class: heroItemType, id: selectedEntityId } : null}
                                  />
                                </div>
                                {meta.error && <div className='error-message'>{meta.error}</div>}
                              </div>
                            )}
                          </FastField>)}
                        {heroItemType === 'URL' &&
                          <Field name='link_url'>
                            {({ field, meta }) => (
                              <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                                <label className='control-label' htmlFor='link_url'>Web Url *</label>
                                <input className='form-control' id='link_url' type='text' {...field} />
                                {meta.error && <div className='error-message'>{meta.error}</div>}
                              </div>
                            )}
                          </Field>}
                        <Field name='title'>
                          {({ field, meta }) => (
                            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                              <label className='control-label' htmlFor='title'>Title *</label>
                              <input className='form-control' id='title' type='text' {...field} />
                              {meta.touched && meta.error && <div className='error-message'>{meta.error}</div>}
                            </div>
                          )}
                        </Field>
                        <div className={`form-group ${selectedImage ? '' : 'has-error'}`}>
                          <label className='control-label' htmlFor='image'>Image *</label>
                          <div className={'heroItemSection-image ' + (imageUrl ? 'heroItemImage--hasLogo' : '')}>
                            {imageUrl &&
                              <div className='heroItemSection-bannerImage' style={{ backgroundImage: `url(${imageUrl})` }} />}
                            <Button className='heroItemImage-imageButton' onClick={() => setShowMediaModal(true)} bsSize='small'>
                              {imageUrl ? 'Change Image' : 'Add Image'}
                            </Button>
                          </div>
                          <div className='error-message'>Please select an image.</div>
                        </div>
                        <MediaModal
                          allowSelection
                          onClose={handleMediaModalClose}
                          onSubmit={handleAttachImage}
                          show={showMediaModal}
                          singleSelection
                        />
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    )
  }
}

HeroItemForm.propTypes = {
  bindSubmitForm: PropTypes.func
}

export default HeroItemForm
