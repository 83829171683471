import { Alert, Button, DropdownButton, MenuItem, Panel, Table } from 'react-bootstrap'
import { format } from 'date-fns'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { React, useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import { CenteredLoader } from 'components/centeredLoader'
import { timezones as timezoneOptions } from 'models/time.model'
import { useCurrentOrganization } from 'contexts/OrganizationContext'

import './exports.scss'

const deleteOrganizationExportMutation = gql`
  mutation deleteOrganizationExport($id: Int!) {
    delete_organization_exports(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const insertOrganizationExportMutation = gql`
  mutation InsertOrganizationExport($organizationId: Int!) {
    insert_organization_exports_one(object: {organization_id: $organizationId}) {
      id
      updated_at
      created_at
      status
      organization_id
    }
  }
`

export const listOrganizationExportsQuery = gql`
  query ListOrganizationExports($organizationId: Int!) {
    organization_exports(where: { organization_id: { _eq: $organizationId } }, order_by: { created_at: desc }) {
      created_at
      id
      status
    }
  }
`

const Exports = () => {
  let exportsBucket = 'dev-'
  switch (process.env.REACT_APP_STAGE_NAME) {
    case 'staging':
      exportsBucket = 'staging-'
      break
    case 'production':
      exportsBucket = ''
      break
    default:
      exportsBucket = 'dev-'
      break
  }
  const client = useApolloClient()
  const history = useHistory()
  const organization = useCurrentOrganization()
  const timezoneLabel = timezoneOptions.filter(({ value }) => value === organization.time_zone)[0].label
  const { data, loading, error } = useQuery(listOrganizationExportsQuery, {
    variables: {
      organizationId: organization.id
    }
  })
  const [deleteOrganizationExport] = useMutation(deleteOrganizationExportMutation, {
    refetchQueries: ['ListOrganizationExports']
  })

  const [insertOrganizationExport] = useMutation(insertOrganizationExportMutation, {
    refetchQueries: ['ListOrganizationExports']
  })

  const getDateTimeString = (date) => {
    const d = new Date(new Date(`${date}`).toLocaleString('en-US', { timeZone: organization.time_zone }))
    return format(d, "LLL d, yyyy, h':'mmaaa")
  }

  const goToOrgProfile = () => {
    history.push(`/${organization.id}/organization/profile/overview`)
  }

  const handleCreateExport = async () => {
    await insertOrganizationExport({
      variables: {
        organizationId: organization.id
      }
    })
  }

  const handleDelete = async (organizationExportId) => {
    if (window.confirm('Are you sure you want to delete this upload? This action cannot be reversed.')) {
      await deleteOrganizationExport({
        variables: {
          id: organizationExportId
        }
      })
    }
  }

  useEffect(() => {
    document.title = `Exports | Organization | ${process.env.REACT_APP_PAGE_TITLE}`
    client.refetchQueries({ include: ['ListOrganizationExports'] })
  }, [])

  return (
    <div className='organizationExports-content'>
      <div className='organizationExports-titleBar'>
        <div className='organizationExports-actions'>
          <Button bsStyle='primary' onClick={handleCreateExport}>
            Create an Export
          </Button>
        </div>
      </div>
      <div>
        {error && (
          <Panel>
            <Panel.Body>
              <Alert bsStyle='danger'>{error.message ? error.message : 'Error'}</Alert>
            </Panel.Body>
          </Panel>
        )}
        {!error && data && data.organization_exports.length === 0 && (
          <Panel>
            <Panel.Body>
              <div className='empty-message'>
                <h3>No exports to display.</h3>
              </div>
            </Panel.Body>
          </Panel>
        )}
        {!error && data && data.organization_exports.length > 0 && (
          <>
            <Panel>
              <Table striped>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th className='centered'>Status</th>
                    <th />
                  </tr>
                </thead>
                {loading && (
                  <tbody>
                    <tr>
                      <td colSpan='7'>
                        <CenteredLoader />
                      </td>
                    </tr>
                  </tbody>
                )}
                {!loading && (
                  <tbody>
                    {data.organization_exports.map((organizationExport) => {
                      return (
                        <tr key={organizationExport.id}>
                          <td>{getDateTimeString(organizationExport.created_at)}</td>
                          <td className='centered'>{organizationExport.status}</td>
                          <td className='actions'>
                            <DropdownButton id='actions' pullRight title='Actions'>
                              <MenuItem download href={`https://${exportsBucket}cdn.outerspatial.com/exports/organizations/${organizationExport.id}/export_${organizationExport.id}_json.zip`} target='_self'>
                                Download JSON
                              </MenuItem>
                              <MenuItem download href={`https://${exportsBucket}cdn.outerspatial.com/exports/organizations/${organizationExport.id}/export_${organizationExport.id}_filegdb.zip`} target='_self'>
                                Download FileGDB
                              </MenuItem>
                              <MenuItem onClick={() => handleDelete(organizationExport.id)}>Delete</MenuItem>
                            </DropdownButton>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                )}
              </Table>
            </Panel>
            <p style={{ marginTop: '6px' }}>
              Note: The dates/times displayed above are in the <b>{timezoneLabel}</b> Time Zone assigned to your
              organization. You can change this in your{' '}
              <a className='btn-link' onClick={goToOrgProfile}>
                organization settings
              </a>
              .
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default withRouter(Exports)
