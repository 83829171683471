import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import React from 'react'

import { CenteredLoader } from '../../../components/centeredLoader'
import FormikGroup from '../../../components/fieldGroup/FormikGroup'

import './commentForm.scss'

const CommentForm = (props) => {
  const handleSave = (values) => {
    props.onSubmit(values)
  }

  const updating = false

  const formikProps = {
    initialValues: {
      post_text: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: Yup.object({
      post_text: Yup.string()
        .min(3, 'Too short!')
        .required('Required!')
    }),
    onSubmit: (values, { resetForm, validateForm }) => {
      validateForm(values)
      handleSave(values)
      resetForm()
    }
  }

  return (
    <div className='commentForm'>
      {updating &&
        <CenteredLoader overlay />}
      <Formik {...formikProps}>
        <Form>
          <FormikGroup
            className='form-control'
            id='post_text'
            name='post_text'
            placeholder='Add a comment...'
            validate='false'
          />
        </Form>
      </Formik>
    </div>
  )
}

CommentForm.propTypes = {
  onSubmit: PropTypes.func
}

export default CommentForm
