import { Route, Switch, useRouteMatch } from 'react-router-dom'
import React from 'react'

import ContentBundle from './contentBundle/ContentBundle'
import ContentBundlesList from './list/ContentBundlesList'

const ContentBundles = () => {
  const match = useRouteMatch()

  return (
    <div>
      <Switch>
        <Route
          path={`${match.url}/:contentBundleId`}
          render={(props) => <ContentBundle />}
        />
        <Route
          exact
          path={match.url}
          render={() => <ContentBundlesList />}
        />
      </Switch>
    </div>
  )
}

export default ContentBundles
