import React from 'react'
import t from 'prop-types'
import _ from 'lodash'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import { buildImageUploadUrl } from 'services/Images'
import Checkbox from 'components/checkbox/Checkbox'
import { CenteredLoader } from 'components/centeredLoader'
import './mediaListItem.css'

const isSelected = (mediaId, selectedMedia) => {
  return _.indexOf(selectedMedia, _.find(selectedMedia, { id: mediaId })) > -1
}

const TooltipIcon = ({ mediaId }) => {
  // const tooltip = <Tooltip id={`media-caption-tooltip-${mediaId}`}>This image needs a caption. Captions are important for accessibility and provide a better user experience.</Tooltip>

  return (
    <Glyphicon glyph='warning-sign' />
  )
}

TooltipIcon.propTypes = {
  mediaId: t.number
}

const MediaListItem = ({ allowSelection, image, selectedMedia, onClick, updating, allowEdit }) => {  
  const selected = isSelected(image.id, selectedMedia)
  const hasCaption = image.caption && image.caption.length
  let src = null

  if (image?.id) {
    if (image.uploaded_file) {
      src = buildImageUploadUrl(image.id, image.uploaded_file, 'small_square')
    }
  }
  return (
    <div
      className={'mediaList-mediaItem ' + (selected ? 'mediaItem--selected' : '')}
    >
      {updating &&
        <CenteredLoader overlay />}
      <div className='mediaList-mediaItem__thumbnail'>
        <span className='img' style={{ backgroundImage: `url('${src}')` }} />
      </div>
      {!hasCaption &&
        <div className='mediaList-mediaItem__captionWarning'>
          <TooltipIcon mediaId={image.id} />
        </div>}
      {allowEdit &&
        <div className='mediaList-mediaItem__buttons' onClick={() => onClick(image, selected, false)}>
          <Button bsStyle='link'>
            <Glyphicon glyph='pencil' />
          </Button>
        </div>}
      {!!allowSelection &&
        <div className='mediaList-mediaItem__select' onClick={() => onClick(image, selected, true)}>
          <Checkbox
            checked={selected}
          />
        </div>}
    </div>
  )
}

MediaListItem.propTypes = {
  allowEdit: t.bool,
  allowSelection: t.bool,
  image: t.object,
  onClick: t.func,
  selectedMedia: t.array,
  updating: t.bool
}

export default MediaListItem
