export function buildImageUploadUrl (id, filename, size = 'medium', imageKitParams) {
  const baseImageUrl = process.env.REACT_APP_IMAGEKIT_URL
  const sizePrefix = size ? `${size}` : ''

  // TODO: Also handle di-placeholder-portrait.jpg.
  return `${baseImageUrl}/tr:${size.includes('square') ? 'di-placeholder-square.jpg' : 'di-placeholder-landscape.jpg'},n-${sizePrefix}/${id}/${filename}`
}

export function buildProfileImageUrl (id, filename, size = 'medium') {
  const baseImageUrl = process.env.REACT_APP_IMAGEKIT_URL
  const sizePrefix = size ? `${size}` : ''

  return `${baseImageUrl}-profile-images/tr:di-placeholder-profile.png,n-${sizePrefix}/${id}/${filename}`
}

export default { buildImageUploadUrl, buildProfileImageUrl }
