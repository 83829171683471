import React, { useState } from 'react'
import t from 'prop-types'
import Panel from 'react-bootstrap/lib/Panel'
import toast from 'react-hot-toast'
import Button from 'react-bootstrap/lib/Button'
import { segmentTrack } from '../../../components/segment'
import { CenteredLoader } from '../../../components/centeredLoader'
import { PageLayout, PageHeader, PageContent } from '../../../components/pageLayout'
import PostItem from '../postItem/PostItem'
import PostsListHeader from './PostsListHeader'
import PostModal from '../postModal/PostModal'
import './postsList.css'
import { useCurrentUser } from 'contexts/CurrentUserContext'
import debugMessage from 'services/Debug'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useQuery, useMutation } from '@apollo/client'
import { FeaturePostsList } from './queries/FeaturePostsList'
import { OrganizationPostsList } from './queries/OrganizationPostsList'
import { InsertPostComment } from './queries/InsertPostComment'
import { InsertPost } from './queries/InsertPost'
import { LikePost } from './queries/LikePost'
import { UnlikePost } from './queries/UnlikePost'
import { UpdatePost } from './queries/UpdatePost'
import DeletePost from './queries/DeletePost'
import InsertPostImageAttachment from './queries/InsertPostImageAttachment'
import DeletePostImageAttachment from './queries/DeletePostImageAttachment'

const PostsList = (props) => {
  const [updating, setUpdating] = useState(false)
  const feature = props.feature
  const listQuery = feature ? FeaturePostsList : OrganizationPostsList
  const me = useCurrentUser()
  const organization = useCurrentOrganization()
  const organizationVariables = feature ? null : { id: organization.id }
  const currentDate = new Date()
  const [query, setQuery] = useState({ page: 1, postType: '', time: '90' })
  const [insertPostComment] = useMutation(InsertPostComment, { refetchQueries: [listQuery] })
  const [insertPost] = useMutation(InsertPost, { refetchQueries: [listQuery] })
  const [updatePost] = useMutation(UpdatePost, { refetchQueries: [listQuery] })
  const [likePost] = useMutation(LikePost, { refetchQueries: [listQuery] })
  const [unlikePost] = useMutation(UnlikePost, { refetchQueries: [listQuery] })
  const [deletePost] = useMutation(DeletePost, { refetchQueries: [listQuery] })
  const [insertPostImageAttachment] = useMutation(InsertPostImageAttachment, { refetchQueries: [listQuery] })
  const [deletePostImageAttachment] = useMutation(DeletePostImageAttachment, { refetchQueries: [listQuery] })

  const [paging] = useState({ current_page: 1 })
  const [showModal, setShowModal] = useState()
  const [selectedPost, setSelectedPost] = useState({})
  const featureVariables = feature ? {
    feature_id: { _eq: feature.id },
    feature_type: { _eq: feature.class_name }
  } : null

  const listVariables = { ...organizationVariables, post_where: { ...featureVariables, visibility: { _neq: 'private' } } }

  if (query.postType === 'posts') {
    listVariables.post_where = { ...listVariables.post_where, _or: [{ is_alert: { _is_null: true } }, { is_alert: { _neq: true } }] }
  } else if (query.postType === 'alerts') {
    listVariables.post_where = { ...listVariables.post_where, is_alert: { _eq: true } }
  }
  if (query.time !== '') {
    currentDate.setDate(currentDate.getDate() - query.time)
    listVariables.post_where.created_at = { _gt: currentDate.toISOString().substring(0, 10) }
  }

  const { data, loading } = useQuery(listQuery, {
    variables: {
      ...listVariables
    }
  })

  let posts = null
  let allPostsCount = null
  if (data) {
    if (feature) {
      posts = data.posts
    } else {
      posts = data.organization_all_posts.map(item => item.post)
      allPostsCount = data.organization_all_posts_aggregate.aggregate.count
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const createComment = async (comment) => {
    return insertPostComment({
      variables: comment
    })
  }

  const destroyPost = () => {
    const post = selectedPost
    const conf = window.confirm('Are you sure you want to permanently delete this post and all of it\'s comments?')

    if (conf) {
      setUpdating(true)
      deletePost({ variables: { post_id: post.id } }).then(() => {
        closeModal()
        segmentTrack('post_deleted', {
          post_id: post.id,
          entity_id: post.feature_id,
          entity_type: post.feature_type
        }, me)
      }, error => {
        debugMessage(error)
      }).finally(() => {
        setUpdating(false)
      })
    }
  }

  const editPost = (post) => {
    openModal(post)
  }

  const handleLikePost = (post) => {
    likePost({
      variables: {
        user_id: me.id,
        post_id: parseInt(post)
      }
    })
  }

  const handleSavePost = (values) => {
    setUpdating(true)
    savePost(values).then(() => {
      toast.success('Post saved successfully.')
      closeModal()
    }, error => debugMessage(error)).finally(() => {
      setUpdating(false)
    })
  }

  const handleUnlikePost = (post) => {
    unlikePost({
      variables: {
        post_id: parseInt(post),
        user_id: me.id
      }
    })
  }

  const newPost = () => {
    openModal({
      feature_type: 'Organization',
      feature_id: organization.id,
      organization_id: organization.id,
      image_attachments: []
    })
  }

  const nextPage = () => {
    setQuery({
      ...query,
      page: query.page + 1
    })
  }

  const onClearFilters = () => {
    resetQueryAndFetch()
  }

  const onQueryChange = (value) => {
    setQuery({ ...query, ...value })
  }

  const openModal = (post) => {
    setSelectedPost(post)
    setShowModal(true)
  }

  const resetQueryAndFetch = () => {
    setQuery({ page: 1, postType: '', time: '90' })
  }

  const savePost = async (model) => {
    // run the mutation for post and image attachments
    const imageAttachments =
      model.image_attachments.map((ia, index) => {
        return {
          ...ia,
          position: index + 1
        }
      })

    // // Filter the image_attachments (with position values) to existing ones that we can just reference by the image_attachment.id
    const existingIds = selectedPost.image_attachments.map(item => item.id)
    const currentIds = model.image_attachments.map(item => item.id)
    const missingIds = existingIds.filter(item => !currentIds.includes(item))
    let segmentEventProperties = {}
    let segmentEventName = 'post_created'

    if (model.id) {
      segmentEventName = 'post_updated'

      return updatePost({ variables: model }).then((results) => {
        segmentEventProperties = {
          entity_id: results.data.update_posts_by_pk.feature_id,
          entity_type: results.data.update_posts_by_pk.feature_type,
          post_type: 'post',
          post_id: results.data.update_posts_by_pk.id
        }
        const postId = results.data.update_posts_by_pk.id
        imageAttachments.forEach((item) => {
          if (!item.id) {
            insertPostImageAttachment({
              variables: {
                post_id: postId,
                image_id: item.image.id,
                position: item.position
              }
            })
          }
        })
        missingIds.forEach((item) => {
          deletePostImageAttachment({
            variables: {
              id: item
            }
          })
        })
        segmentTrack(segmentEventName, segmentEventProperties, me)
      })
    } else {
      return insertPost({ variables: model }).then((results) => {
        segmentEventProperties = {
          entity_id: results.data.insert_posts_one.feature_id,
          entity_type: results.data.insert_posts_one.feature_type,
          post_type: 'post',
          post_id: results.data.insert_posts_one.id
        }
        const postId = results.data.insert_posts_one.id
        imageAttachments.forEach((item) => {
          if (!item.id) {
            insertPostImageAttachment({
              variables: {
                post_id: postId,
                image_id: item.image.id,
                position: item.position
              }
            })
          }
        })
        segmentTrack(segmentEventName, segmentEventProperties, me)
      })
      // need a silent option here in case we're adding a feature that doesn't match current view query
      // return dispatch(postActions.create(modelWithImagePositions))
    }
  }

  return (
    <div className='posts-wrap'>
      <PageLayout>
        <PageHeader>
          <PostsListHeader
            onClearFilters={onClearFilters}
            onNewClick={newPost}
            onQueryChange={onQueryChange}
            query={query}
          />
        </PageHeader>
        {(!posts || loading || updating) &&
          <CenteredLoader overlay />}
        {posts &&
          <PageContent>
            {(posts.length === 0) &&
              <Panel className='featuresTable-empty'>
                <Panel.Body>
                  <h3>No posts available.</h3>
                  {(allPostsCount > 0) &&
                    <h4>
                      ({allPostsCount} posts total "All Time".)
                    </h4>}
                </Panel.Body>
              </Panel>}
            <div className='posts-content'>
              <div className='posts-list'>
                {posts
                  .map(post => {
                    return (
                      <PostItem
                        post={post}
                        like={handleLikePost}
                        unlike={handleUnlikePost}
                        deletePost={deletePost}
                        onEditClick={editPost}
                        createComment={createComment}
                        key={post.id}
                      />
                    )
                  })}
                {paging.current_page < paging.total_pages &&
                  <div className='posts-loadMore'>
                    <Button bsStyle='link' onClick={nextPage}>Load more</Button>
                  </div>}
              </div>
            </div>
          </PageContent>}
      </PageLayout>
      {showModal &&
        <PostModal
          show={showModal}
          onClose={closeModal}
          onDelete={destroyPost}
          onSubmit={handleSavePost}
          updating={updating}
          post={selectedPost}
        />}
    </div>
  )
}

PostsList.propTypes = {
  feature: t.object
}

export default PostsList
