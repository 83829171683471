import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import FeatureChildren from './locations/FeatureChildren'

const FeatureLocations = (props) => {
  const { feature } = props
  const match = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${match.url}/:childFeatureId`}
        render={() => <FeatureChildren feature={feature} />}
      />
      <Route
        path={match.url}
        render={() => <FeatureChildren feature={feature} />}
      />
    </Switch>
  )
}

FeatureLocations.propTypes = {
  feature: PropTypes.object
}

export default FeatureLocations
