import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import debugMessage from 'services/Debug'
import toast from 'react-hot-toast'
import { useLazyQuery } from '@apollo/client'

import { entityByType, entityByClass } from '../../models/feature/feature.lang'
import { Queries } from './EntitySelectOperations'

import './associationSet.css'
import { useCurrentOrganization } from 'contexts/OrganizationContext'

const Option = (props) => {
  const { data, innerProps, innerRef, isFocused } = props

  const entitySingularName = (entityType) => {
    if (entityByClass(entityType)) {
      return entityByClass(entityType).singular
    } else {
      return entityType
    }
  }

  return (
    <div ref={innerRef} {...innerProps} className={isFocused ? 'entitySelectOption isFocused' : 'entitySelectOption'}>
      {data.value !== 'None' &&
        <div style={{ textTransform: 'uppercase' }}>
          {entitySingularName(data.content.class_name)}
          {data.content.area?.name &&
            <span>
              &nbsp;&mdash;&nbsp;{data.content.area.name}
            </span>}
        </div>}
      <div style={{ fontSize: '1.2em' }}>
        {data.content.name}
      </div>
      {data.content?.stewardships && data.content.stewardships[0].organization?.name &&
        <div style={{ fontStyle: 'italic' }}>
          {data.content.stewardships[0].organization.name}
        </div>}
    </div>
  )
}

Option.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
  innerRef: PropTypes.any, // React-select specifies innerRef as `react.ElementRef<*>`
  isFocused: PropTypes.bool
}

const EntitySelect = (props) => {
  const {
    associations = [],
    clearFormFieldValue,
    clearable = false,
    communityIdProp = null,
    entityTypes = null,
    isMultipleAssociationSet = false,
    isDisabled = false,
    menuPlacement = 'auto',
    noResultsMessage = null,
    onAdd,
    placeholder,
    restrictToCurrentOrganization = false,
    showNone = false,
    value = null
  } = props
  const [communityId, setCommunityId] = useState()
  const entityPluralName = (entityType) => {
    const labels = {
      Area: 'Areas',
      Challenge: 'Challenges',
      Community: 'Communities',
      Event: 'Events',
      Features: 'Locations',
      ContentBundle: 'Articles',
      Organization: 'Organizations',
      Outing: 'Outings',
      PointOfInterest: 'Points of Interest',
      Trail: 'Trails'
    }

    return labels[entityType]
  }

  const organization = useCurrentOrganization()
  const [searchResults, setSearchResults] = useState([])
  const [selectValue, setSelectValue] = useState({})
  const [term, setTerm] = useState(null)
  const [isUserSearchingForEntity, setIsUserSearchingForEntity] = useState(false)
  let noResultsMessaging = ''

  if (noResultsMessage) {
    noResultsMessaging = noResultsMessage
  } else {
    if (entityTypes?.length === 1) {
      noResultsMessaging = `No ${entityPluralName(entityTypes[0])} found.`
    } else {
      noResultsMessaging = 'No entities found.'
    }
  }

  const [getEntityById, { loadingGetEntityById }] = useLazyQuery(
    Queries.SearchEntitiesById,
    {
      displayName: 'GetEntityById',
      fetchPolicy: 'network-only',
      onError (error) {
        debugMessage(error)
      },
      onCompleted (data) {
        reduceQueryToSelect(data, entityTypes, value?.class)
      }
    }
  )

  const [getEntitiesForCampaign, { loadingCampaignEntities }] = useLazyQuery(
    Queries.SearchAllEntitiesForCampaign,
    {
      displayName: 'SearchAllEntitiesForCampaign',
      fetchPolicy: 'network-only',
      variables: {
        term: term,
        organizationId: organization.id,
        communityId: communityIdProp || communityId
      },
      onError (error) {
        debugMessage(error)
      },
      onCompleted (data) {
        reduceQueryToSelect(data, entityTypes)
      }
    }
  )

  const [getEntitiesWithinOrganization, { loadingOrganizationEntities }] = useLazyQuery(
    Queries.SearchEntitiesWithinOrganization,
    {
      displayName: 'SearchEntitiesWithinOrganization',
      fetchPolicy: 'network-only',
      variables: {
        term: term,
        organizationId: organization.id
      },
      onError (error) {
        debugMessage(error)
      },
      onCompleted (data) {
        reduceQueryToSelect(data, entityTypes)
      }
    }
  )

  const [getFeaturesWithinOrganization, { loadingOrganizationFeatures }] = useLazyQuery(
    Queries.SearchAllFeaturesByOrganization,
    {
      displayName: 'SearchAllFeaturesByOrganization',
      fetchPolicy: 'network-only',
      variables: {
        term: term,
        organizationId: organization.id
      },
      onError (error) {
        debugMessage(error)
      },
      onCompleted (data) {
        reduceQueryToSelect(data, entityTypes)
      }
    }
  )

  const handleChange = (innerValue, event) => {
    setIsUserSearchingForEntity(false)

    if (event.action === 'clear') {
      if (typeof clearFormFieldValue === 'function') {
        clearFormFieldValue()
      }
      setSelectValue({})
      setSearchResults([])
      setTerm(null)
      onAdd(null)
    } else if (event.action === 'select-option' && typeof onAdd === 'function') {
      if (innerValue) {
        if (isMultipleAssociationSet) {
          const reducedSearchResults = searchResults.filter(entity => {
            if (entity.value.id === innerValue.value.id) {
              return (entity.value.class !== innerValue.value.class)
            } else {
              return true
            }
          })

          setSearchResults(reducedSearchResults)
          // FIXME: This introduces an edge-case bug where if the onAdd callback fails, the item will be removed
          //  from the search results/dropdown until the user searches again.
        }
        const classSingular = (() => {
          if (entityByType(innerValue.content.__typename)) {
            return entityByType(innerValue.content.__typename).classSingular
          } else {
            // Since entityTypes may have multiple options, we can't automatically just default to one
            toast.error('We have experienced an error processing your selection.')
            return null
          }
        })()

        onAdd({
          attacheable: innerValue.content,
          attacheable_id: innerValue.content.id,
          attacheable_type: classSingular,
          feature: {
            ...innerValue.content,
            area: { name: innerValue.content.area?.name }
          },
          value: innerValue
        })
      } else {
        onAdd(null)
      }
    }
  }

  const handleInputChange = (innerValue, event) => {
    setIsUserSearchingForEntity(true)
    if (event.action === 'input-change') {
      if (innerValue && innerValue.length > 0) {
        const escapedValue = innerValue.replace(/_/g, '\\_')
        setTerm('%' + escapedValue + '%')
        search('%' + escapedValue + '%')
      } else {
        setTerm(null)
      }
    } else if (event.action === 'menu-close') {
      if (isUserSearchingForEntity) {
        if (typeof clearFormFieldValue === 'function') {
          clearFormFieldValue()
        }
        // setSelectValue(null)
        setTerm(null)
      }
    }
  }

  const handleSearchMenuClose = () => {
    setIsUserSearchingForEntity(false)
    setTerm(null)
  }

  const reduceQueryToSelect = (data, entityTypes = null, innerEntityType = null) => {
    let mapped = _.keys(data).map((key) => {
      // Switch over `key` values and translate the different GraphQL
      //  response structure into the standardized format below that works with the Select component
      return data[key].map((entity) => {
        const classSingular = (() => {
          if (entityByType(key)) {
            return entityByType(key).classSingular
          } else {
            return entity
          }
        })()
        const label = (!entity.name || entity.name === '') ? 'Unnamed' : entity.name

        return {
          content: {
            ...entity,
            class_name: classSingular,
            table_name: entity.__typename
          },
          key: key + '_' + entity.id,
          label: label,
          value: {
            class: classSingular,
            table: entity.__typename,
            id: entity.id
          }
        }
      })
    })

    mapped = _.flatten(mapped)

    console.log('mapped', mapped)

    if (entityTypes?.length === 1 && entityTypes[0] === 'Features') {
      entityTypes = ['Area', 'Outing', 'PointOfInterest', 'Trail']
    }

    // If entityTypes are specified, filter the mapped content to only those specified entity types
    if (entityTypes?.length > 0) {
      mapped = mapped.filter((entity) => {
        return (entityTypes.find(type => type === entity.value.class))
      })
    }

    mapped = _.sortBy(mapped, m => m.label.toLowerCase())

    if (showNone && !innerEntityType) {
      mapped = [{ label: 'None', value: 'None', content: { id: 'None', name: 'None' }, key: 'None' }, ...mapped]
    }

    if (innerEntityType) {
      // Return single result in Select object
      mapped = mapped.filter(entity => entity.value.class === innerEntityType)

      setSearchResults(mapped)
    } else {
      setSearchResults(mapped)
    }

    console.log('mapped2', mapped)

    // This is here to support the initial component mount and an existing value is passed in.
    // We need to both get the results array as well as have a selected value with the same shape
    //  that the override `Option` component specifies in order for it to display correctly
    if (isDisabled) {
      setSelectValue(mapped[0])
    } else if (value?.id && value?.class) {
      setSelectValue(mapped.filter(option => (option.value.class === value.class && option.value.id === value.id))[0])
    }
  }

  useEffect(() => {
    // We are limiting the features to within the Community the user has access to.
    if (!communityId && organization) {
      // We will select the first (possibly only) sponsored community ID.
      // If the organization does not belong to a sponsored community, we will just pick the first community
      const sponsoredCommunities = organization.communities.filter(community => community.community.is_sponsored)

      if (sponsoredCommunities.length > 0) {
        setCommunityId(sponsoredCommunities[0].community.id)
      } else if (organization?.communities?.length > 0) {
        setCommunityId(organization.communities[0].community.id)
      } else {
        // Use organizationId instead of communityId when current organization isn't part of a community
        // We manually pass in the `restrictToCurrentOrganization` prop as true if we want to do this.
        // The only place this prop is not currently passed in is the Campaign.js form, but we explicitly require
        //  a community to be selected before we mount this component.
      }
    }

    if (value?.id) {
      getEntityById({
        variables: {
          entityId: value.id
        }
      })
    }
  }, []) // No dependency array to run only on mount

  const search = (term) => {
    if (term && organization.id) {
      if (restrictToCurrentOrganization) {
        if (entityTypes?.length === 1 && entityTypes[0] === 'Features') {
          const excludeAreas = []
          const excludeOutings = []
          const excludePointsOfInterest = []
          const excludeTrails = []

          if (associations?.length > 0) {
            associations.forEach(feature => {
              const featureType = feature.feature?.class_name || feature.feature_type || null
              const featureId = feature.feature?.id || feature.feature_id || null
              switch (featureType) {
                case 'Area':
                  excludeAreas.push(featureId)
                  break
                case 'Outing':
                  excludeOutings.push(featureId)
                  break
                case 'PointOfInterest':
                  excludePointsOfInterest.push(featureId)
                  break
                case 'Trail':
                  excludeTrails.push(featureId)
                  break
                default: // No default, as we should capture all feature_type values
                  break
              }
            })
          }

          getFeaturesWithinOrganization({
            variables: {
              term: term,
              organizationId: organization.id,
              excludeAreas: excludeAreas,
              excludeOutings: excludeOutings,
              excludePointsOfInterest: excludePointsOfInterest,
              excludeTrails: excludeTrails
            }
          })
        } else {
          getEntitiesWithinOrganization({
            variables: {
              term: term,
              organizationId: organization.id
            }
          })
        }
      } else if (communityIdProp || communityId) {
        getEntitiesForCampaign({
          variables: {
            term: term,
            organizationId: organization.id,
            communityId: communityIdProp || communityId
          }
        })
      }
    }
  }

  const getSelectValue = useCallback((searchResults, selectValue, value) => {
    let result = null
    if (value?.id && value?.class && searchResults?.length) {
      result = searchResults.filter((result) => (value.id === result.value.id) && (value.class === result.value.class))
    } else if (selectValue?.value?.id && selectValue?.value?.class) {
      result = [selectValue]
    }
    return result
  }, [])

  return (
    <div className='associationSet'>
      <ReactSelect
        cacheOptions={false}
        className='entitySelect'
        classNamePrefix='entitySelect'
        components={{ Option }}
        isClearable={clearable}
        isDisabled={isDisabled}
        isLoading={loadingCampaignEntities || loadingOrganizationEntities || loadingGetEntityById || loadingOrganizationFeatures}
        menuPlacement={menuPlacement}
        noOptionsMessage={() => noResultsMessaging}
        onChange={handleChange}
        onInputChange={_.debounce(handleInputChange, 200)}
        onMenuClose={handleSearchMenuClose}
        options={searchResults}
        placeholder={placeholder || 'Start typing to search...'}
        value={getSelectValue(searchResults, selectValue, value)}
      />
    </div>
  )
}

EntitySelect.propTypes = {
  associations: PropTypes.array, // Existing associations used to filter out from GraphQL queries when searching
  clearFormFieldValue: PropTypes.func, // Callback for clearing state or values higher in the component hierarchy when the menu is closed or cleared
  clearable: PropTypes.bool,
  communityIdProp: PropTypes.number, // Override the internal logic of selecting what community ID to use
  entityTypes: PropTypes.array, // entity_types for search query. If array is empty or null, all entity types will be searched
  isMultipleAssociationSet: PropTypes.bool, // Specifies if this is being used with an AssociationSet or not
  isDisabled: PropTypes.bool, // disable the select
  menuPlacement: PropTypes.string,
  noResultsMessage: PropTypes.string, // Override for the noOptionsMessage logic
  onAdd: PropTypes.func, // add/select callback
  placeholder: PropTypes.string, // text placeholder for input
  restrictToCurrentOrganization: PropTypes.bool, // if set, only query for entities within the current organization
  showNone: PropTypes.bool, // Inject a `None` value into the beginning of the search results list
  value: PropTypes.object // Object of { class: entityType, id: entityId (Int!) }
}

export default EntitySelect
