import _ from 'lodash'

const featureTypes = [
  {
    singular: 'Trail',
    plural: 'Trails',
    classSingular: 'Trail',
    tableSingular: 'trail',
    tablePlural: 'trails'
  },
  {
    singular: 'Point of Interest',
    plural: 'Points of Interest',
    classSingular: 'PointOfInterest',
    tableSingular: 'point_of_interest',
    tablePlural: 'points_of_interest'
  },
  {
    singular: 'Point of Interest',
    plural: 'Points of Interest',
    classSingular: 'PointOfInterest',
    tableSingular: 'point_of_interest',
    tablePlural: 'points-of-interest'
  },
  {
    singular: 'Area',
    plural: 'Areas',
    classSingular: 'Area',
    tableSingular: 'area',
    tablePlural: 'areas'
  },
  {
    singular: 'Outing',
    plural: 'Outings',
    classSingular: 'Outing',
    tableSingular: 'outing',
    tablePlural: 'outings'
  }
]

const entityTypes = [
  {
    singular: 'Challenge',
    plural: 'Challenges',
    classSingular: 'Challenge',
    tableSingular: 'challenges',
    tablePlural: 'challenges'
  },
  {
    singular: 'Community',
    plural: 'Communities',
    classSingular: 'Community',
    tableSingular: 'community',
    tablePlural: 'communities'
  },
  {
    singular: 'Event',
    plural: 'Events',
    classSingular: 'Event',
    tableSingular: 'event',
    tablePlural: 'events'
  },
  {
    singular: 'Article',
    plural: 'Articles',
    classSingular: 'ContentBundle',
    tableSingular: 'content_bundle',
    tablePlural: 'content_bundles'
  },
  {
    singular: 'Organization',
    plural: 'Organizations',
    classSingular: 'Organization',
    tableSingular: 'organization',
    tablePlural: 'organizations'
  },
  ...featureTypes
]

export const featureByType = type => _.find(featureTypes, { tablePlural: type })
export const featureByClass = className => _.find(featureTypes, { classSingular: className })
export const entityByType = type => _.find(entityTypes, { tablePlural: type })
export const entityByClass = className => _.find(entityTypes, { classSingular: className })
