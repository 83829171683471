import React from 'react'
import t from 'prop-types'
import Button from 'react-bootstrap/lib/Button'
import './documentsActionBar.css'

const ActionBar = ({ fetching, onNewClick }) => {
  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '74', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/74-documents' }).click()
    }
  }

  return (
    <div className='documentsActionBar-wrap'>
      <div className='documentsActionBar-actions'>
        {!fetching &&
          <Button
            bsStyle='primary'
            className='shadow'
            onClick={onNewClick}
          >
            New Document
          </Button>}
        &nbsp;
        <Button
          bsStyle='primary'
          className='shadow'
          onClick={handleViewHelpClick}
        >
          View Help
        </Button>
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  fetching: t.bool,
  onNewClick: t.func
}

export default ActionBar
