import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import Alert from 'react-bootstrap/lib/Alert'
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { CenteredLoader } from '../../../components/centeredLoader'
import { Mutations, Queries } from './FeatureOperations'
import { PageContent, PageHeader, PageLayout } from '../../../components/pageLayout'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import debugMessage from 'services/Debug'
import Error404 from '../../errors/404'
import FeatureDocuments from './featureDocuments/FeatureDocuments'
import FeatureHeader from './FeatureHeader'
import FeatureMaps from './featureMaps/FeatureMaps'
import FeaturePosts from './featurePosts/FeaturePosts'
import FeaturesFeatureInfo from './featureInfo/FeaturesFeatureInfo'

import './feature.css'

const PointOfInterest = (props) => {
  const formRef = useRef()
  const history = useHistory()
  const latestFeatureData = useRef()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const featureId = isNaN(match.params.point_of_interest_id) ? -1 : parseInt(match.params.point_of_interest_id)
  const showSave = window.location.pathname.indexOf('info') > -1
  const [featureData, setFeatureData] = useState({
    description: '',
    id: -1,
    invalidId: false,
    name: '',
    visibility: 'Published'
  })
  const [pointTypeOptions, setPointTypeOptions] = useState([])

  const { error: poiQueryError, loading: poiIsLoading } = useQuery(Queries.GetPointOfInterest, {
    skip: featureId === -1,
    variables: {
      poiId: featureId
    },
    onCompleted: (data) => {
      if (data.points_of_interest_by_pk) {
        const poi = data.points_of_interest_by_pk
        const contentBlocks = poi.content_blocks.map(cb => ({
          body: cb.content_block.body,
          id: cb.id,
          position: cb.content_block.position,
          title: cb.content_block.title
        }))

        setFeatureData({
          ...poi,
          area_id: poi.area_id ? parseInt(poi.parent_area_id) : null,
          class_name: 'PointOfInterest',
          content_blocks: contentBlocks,
          geojson: poi.location.geometry,
          parent_poi_id: poi.parent_poi_id ? parseInt(poi.parent_poi_id) : null, // ancestry field in GraphQL
          point_of_interest_type_id: poi.point_of_interest_type_id ? parseInt(poi.point_of_interest_type_id) : null,
          table_name: poi.__typename,
          tags: poi.tags
        })
      } else {
        setFeatureData({
          ...latestFeatureData.current,
          invalidId: true
        })
      }
    }
  })

  const { loading: poiTypesAreLoading } = useQuery(Queries.GetPointOfInterestTypes, {
    onCompleted: (data) => {
      // Translate into the shape we need for ReactSelect and store into state
      setPointTypeOptions(data.point_of_interest_types.map(
        (item) => { return { value: item.id, label: item.name } }
      ))
    }
  })

  const [updatePointOfInterest, { loading: updateIsLoading }] = useMutation(Mutations.UpdatePointOfInterest, {
    onCompleted: (data) => {
      if (data.update_points_of_interest_by_pk) {
        const poi = data.update_points_of_interest_by_pk
        let tags = latestFeatureData.current.tags

        if (data.delete_tags?.affected_rows) {
          tags.filter(t => data.delete_tags.returning.find(dt => dt.key !== t.key))
        }

        if (data.insert_tags?.affected_rows) {
          tags = [
            ...tags,
            ...data.insert_tags.returning
          ]
        }

        let updatedStewardships = latestFeatureData.current.stewardships.filter(s =>
          !data.delete_stewardships.returning.map(ds => ds.id).includes(s.id)
        )

        updatedStewardships = [
          ...updatedStewardships,
          ...data.insert_stewardships.returning
        ]

        setFeatureData({
          ...latestFeatureData.current,
          area_id: poi.area_id ? parseInt(poi.parent_area_id) : null,
          description: poi.description,
          name: poi.name,
          parent_poi_id: poi.parent_poi_id ? parseInt(poi.parent_poi_id) : null, // ancestry field in GraphQL
          point_of_interest_type_id: poi.point_of_interest_type_id ? parseInt(poi.point_of_interest_type_id) : null,
          point_type: poi.point_type,
          stewardships: updatedStewardships,
          tags: tags
        })
        toast.success('The Point of Interest was updated.')
      }
    },
    onError: (error) => {
      debugMessage(error)
      toast.error('The Point of Interest could not be updated.')
    }
  })

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handleSubmitInfoForm = (newFeature, formikBag) => {
    const feature = {
      description: stringIsNilOrWhitespace(newFeature.description) ? null : newFeature.description.trim(),
      keyValuesOfTagsToDelete: newFeature.keyValuesOfTagsToDelete,
      name: stringIsNilOrWhitespace(newFeature.name) ? null : newFeature.name.trim(),
      newStewardships: sanitizeStewardshipsForMutation(newFeature.stewardshipChanges.added),
      newTags: newFeature.newTags,
      parent_area_id: (newFeature.parent_area_id === -1) ? null : parseInt(newFeature.parent_area_id),
      parent_poi_id: (newFeature.parent_poi_id === -1) ? null : newFeature.parent_poi_id.toString(),
      point_of_interest_type_id: !newFeature.point_of_interest_type_id ? null : parseInt(newFeature.point_of_interest_type_id),
      removedStewardshipIds: newFeature.stewardshipChanges.removed
    }

    if (newFeature.point_of_interest_type_id) {
      let pointTypeName = null
      pointTypeName = pointTypeOptions.find(option => option.value === newFeature.point_of_interest_type_id)
      feature.point_type = pointTypeName?.label
    }

    updatePointOfInterest({
      variables: {
        ...feature,
        pointOfInterestId: featureData.id
      }
    }).finally(() => {
      formikBag.setSubmitting(false)
    })
  }

  const sanitizeStewardshipsForMutation = (stewardships) => {
    return stewardships.map(s => {
      return {
        organization_id: s.organization_id,
        role: s.role,
        stewardable_id: s.stewardable_id,
        stewardable_type: 'PointOfInterest'
      }
    })
  }

  const stringIsNilOrWhitespace = (input) => {
    // Handle weird Redactor bug.
    if (input === '<p><br></p>') {
      return true
    }

    return (input?.trim()?.length || 0) === 0
  }

  // Set latestFeatureData.current so it can be accessed instead of "stale" state in onComplete callbacks.
  useEffect(() => {
    latestFeatureData.current = featureData
    document.title = `${stringIsNilOrWhitespace(featureData.name) ? 'Unnamed' : featureData.name} | Points of Interest | Locations | ${process.env.REACT_APP_PAGE_TITLE}`
  }, [featureData])

  if (poiQueryError?.message) {
    return <Alert bsStyle='danger'>{poiQueryError.message}</Alert>
  } else if (poiIsLoading || poiTypesAreLoading || (featureId !== -1 && featureData?.id === -1 && !featureData.invalidId)) {
    return <CenteredLoader />
  } else if (featureData?.invalidId) {
    return <Alert bsStyle='warning'>There is no location with the requested ID.</Alert>
  } else if (featureData?.id !== -1 && featureData?.invalidId) {
    return <Error404 />
  }

  return (
    <div className='feature-wrap'>
      {((featureData.id === -1) || !pointTypeOptions.length || updateIsLoading) &&
        <CenteredLoader overlay />}
      {featureData.id !== -1 &&
        <PageLayout>
          <PageHeader>
            <FeatureHeader
              feature={featureData}
              match={match}
              onSaveClick={handleSave}
              showSaveButton={showSave}
            />
          </PageHeader>
          <PageContent>
            <Switch>
              <Route
                exact
                path={match.url}
                render={() => <Redirect to={`${match.url}/info`} />}
              />
              <Route
                path={`${match.url}/info`}
                render={(props) => {
                  return (
                    <FeaturesFeatureInfo
                      formRef={formRef}
                      feature={featureData}
                      handleSubmitForm={handleSubmitInfoForm}
                      organization={organization}
                      pointTypeOptions={pointTypeOptions}
                    />
                  )
                }}
              />
              <Route
                path={`${match.url}/documents`}
                render={({ match }) => <FeatureDocuments feature={featureData} match={match} />}
              />
              <Route
                path={`${match.url}/maps`}
                render={({ match }) => <FeatureMaps feature={featureData} history={history} match={match} />}
              />
              <Route
                path={`${match.url}/social`}
                render={({ match }) => <FeaturePosts feature={featureData} history={history} match={match} />}
              />
            </Switch>
          </PageContent>
        </PageLayout>}
    </div>
  )
}

export default PointOfInterest
