import { gql } from '@apollo/client'

export const InsertEventAttachment = gql`
  mutation InsertEventAttachment(
    $eventId: Int!,
    $featureId: Int!,
    $featureType: String!
  ) {
    insert_attachments_one(object:{
      feature_type: $featureType,
      feature_id: $featureId,
      attached_id: $eventId,
      attached_type: "Event"
    }) {
      id
      created_at
    }
  }
`

export const RemoveEventAttachment = gql`
  mutation RemoveEventAttachment(
    $id: Int!
  ) {
    delete_attachments_by_pk(id:$id)
    {
      id
      created_at
    }
  }
`

export const InsertScheduleMutation = gql`
  mutation InsertSchedule(
    $date: date!, 
    $time: time!, 
    $until: timestamp!
    $event_id: Int!
  ) {
    insert_schedules_one(object:{
      schedulable_type: "Event",
      schedulable_id: $event_id,
      date: $date,
      time: $time,
      until: $until,
      rule: "singular",
      count: 1,
      interval: "0"
    }) {
      id
      created_at
      event_id: schedulable_id
    }
  }
`

export const UpdateScheduleMutation = gql`
  mutation UpdateSchedule(
    $date: date!, 
    $time: time!, 
    $until: timestamp!
    $id: Int!
  ) {
    update_schedules_by_pk(pk_columns: {id:$id}, _set:{
      date: $date,
      time: $time,
      until: $until
    } ) {
      id
      created_at
      event_id: schedulable_id
    }
  }
`

export const InsertEventMutation = gql`
  mutation InsertEvent(
    $time_zone: String = "America/Los_Angeles"
    $name: String = "New Event"
    $location: String = ""
    $description: String = ""
    $banner_image_id: Int, 
    $cost: String = "", 
    $feature_id: Int, 
    $feature_type: String,
    $organization_id: Int, 
    $phone_number: String = "", 
    $website: String = "") {
    insert_events_one(object: {
      name: $name, 
      location: $location, 
      organization_id: $organization_id, 
      phone_number: $phone_number, 
      cost: $cost, 
      banner_image_id: $banner_image_id, 
      description: $description, 
      feature_id: $feature_id, 
      feature_type: $feature_type, 
      website: $website,
      time_zone: $time_zone
    }) {
      created_at
      id
      location
      name
      organization_id
      phone_number
      time_zone
      updated_at
      website
    }  
  }
`
export const UpdateEventMutation = gql`
  mutation UpdateEvent(
    $id: Int!
    $name: String
    $time_zone: String,
    $location: String
    $description: String
    $banner_image_id: Int, 
    $cost: String, 
    $feature_id: Int, 
    $feature_type: String,
    $organization_id: Int, 
    $phone_number: String, 
    $website: String) {
    update_events_by_pk(pk_columns: {id:$id}, _set: {  
      time_zone: $time_zone,    
      name: $name, 
      location: $location, 
      organization_id: $organization_id, 
      phone_number: $phone_number, 
      cost: $cost, 
      banner_image_id: $banner_image_id, 
      description: $description, 
      feature_id: $feature_id, 
      feature_type: $feature_type, 
      website: $website
    } ) {
      created_at
      id
      location
      name
      organization_id
      phone_number
      time_zone
      updated_at
      website      
      schedules{
        schedule{
          id
        }
      }
    }  
  }
`
