import { buildImageUploadUrl, buildProfileImageUrl } from 'services/Images'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import { askOuterSpatialAQuestion } from '../../../components/helpScout'
import { useCurrentUser } from 'contexts/CurrentUserContext'

import './comment.scss'

const Comment = (props) => {
  const { comment = {}, organization } = props
  const commentCreatedAt = moment(comment.created_at).fromNow()
  let createdBy = comment.user.profile.name
  let avatar = buildProfileImageUrl(comment.user.profile.id, comment.user.profile.photo_file)
  const user = useCurrentUser()

  const handleDestroyClick = () => {
    const conf = window.confirm('Are you sure you want to permanently delete this post along with all of it\'s likes and comments?')

    if (conf) {
      props.onDestroy(props.comment.id)
    }
  }

  const handleReportClick = () => {
    askOuterSpatialAQuestion(
      'Report a Comment',
      `I would like to report the comment with id of ${comment.id}.`,
      user
    )
  }

  if (comment.is_admin) {
    createdBy = organization.name

    if (organization.logo_image && organization.logo_image.id && organization.logo_image.uploaded_file) {
      avatar = buildImageUploadUrl(organization.logo_image.id, organization.logo_image.uploaded_file, 'medium')
    } else {
      avatar = null
    }
  }

  return (
    <div className='comment'>
      {avatar &&
        <div className='comment-avatar' style={{ backgroundImage: `url(${avatar})` }} />}
      <div className='comment-content'>
        <div className='comment-title'>
          <div className='comment-attribution'>
            <h3>{createdBy}</h3>
            <p>{commentCreatedAt}</p>
          </div>
          <div className='comment-topActions'>
            <DropdownButton
              bsSize='small'
              bsStyle='link'
              id='actions'
              noCaret
              pullRight
              title={<Glyphicon glyph='option-horizontal' />}
            >
              {comment.is_admin &&
                <MenuItem
                  eventKey='delete'
                  onClick={handleDestroyClick}
                >
                  Delete
                </MenuItem>}
              {!comment.is_admin &&
                <MenuItem
                  eventKey='report'
                  onClick={handleReportClick}
                >
                  Report
                </MenuItem>}
            </DropdownButton>
          </div>
        </div>
        <div
          className='comment-body'
          dangerouslySetInnerHTML={{ __html: comment.post_text }}
        />
      </div>
    </div>
  )
}

Comment.propTypes = {
  comment: PropTypes.object,
  onDestroy: PropTypes.func,
  organization: PropTypes.object
}

export default Comment
