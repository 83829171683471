// NOTE: This is still being used in User.js for UserSettings.Basic > Profile image validation before form submission

// const MAX_FILE_SIZE = 1e+7 // 10mb
const MAX_FILE_SIZE = 5.0e+7 // 50mb

export const image = (file) => {
  const errors = []
  const ALLOWED_MIME_TYPES = ['image/jpeg', 'image/png']

  if (ALLOWED_MIME_TYPES.indexOf(file.type) === -1) {
    errors.push(`File with type of "${file.type}" is not allowed. Please choose a JPEG or PNG image.`)
  }

  if (file.size > MAX_FILE_SIZE) {
    errors.push('File too large. Please select an image smaller than 50MB.')
  }

  return errors
}

export const maps = (file) => {
  const errors = []
  // const ALLOWED_MIME_TYPES = ['image/jpeg', 'image/png']

  // if (ALLOWED_MIME_TYPES.indexOf(file.type) === -1) {
  //   errors.push(`File with type of "${file.type}" is not allowed. Please choose a JPEG or PNG image.`)
  // }

  // if (file.size > MAX_FILE_SIZE) {
  //   errors.push('File too large. Please select an image smaller than 25Mb.')
  // }

  return errors
}
