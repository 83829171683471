import { Alert, Button, DropdownButton, MenuItem, OverlayTrigger, Panel, Table, Tooltip } from 'react-bootstrap'
import { format } from 'date-fns'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { React, useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import fetch from 'node-fetch'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

import { CenteredLoader } from 'components/centeredLoader'
import { timezones as timezoneOptions } from 'models/time.model'
import { useCurrentOrganization } from 'contexts/OrganizationContext'

import './imports.css'

const deleteShapefileUploadMutation = gql`
  mutation deleteShapefileUpload($shapefileUploadId: Int!) {
    delete_feature_imports(where: { source_id: { _eq: $shapefileUploadId } }) {
      affected_rows
    }
    delete_shapefile_uploads(where: { id: { _eq: $shapefileUploadId } }) {
      affected_rows
    }
  }
`

export const listShapefileUploadsQuery = gql`
  query ListShapefileUploads($organizationId: Int!) {
    shapefile_uploads(where: { organization_id: { _eq: $organizationId } }, order_by: { created_at: desc }) {
      created_at
      geo_import_features_aggregate {
        aggregate {
          count
        }
      }
      geojson_url
      id
      inserts_aggregate: feature_imports_aggregate(where: { was_update: { _neq: true } }) {
        aggregate {
          count
        }
      }
      metadata
      name
      preview
      status
      updates_aggregate: feature_imports_aggregate(where: { was_update: { _eq: true } }) {
        aggregate {
          count
        }
      }
    }
  }
`

const Imports = (props) => {
  const client = useApolloClient()
  const history = useHistory()
  const organization = useCurrentOrganization()
  const steps = [
    {
      completedLabel: () => {
        return 'Uploaded'
      },
      isCompleted: (upload) => {
        if (upload) {
          return true
        }

        return false
      },
      label: 'Upload',
      processingLabel: () => {
        return 'Uploading'
      }
    },
    {
      completedLabel: () => {
        return 'Configured'
      },
      isCompleted: (upload) => {
        // TODO: Need to figure out how to detect if the import has been configured.
        return true
      },
      label: 'Configure'
    },
    {
      completedLabel: () => {
        // TODO: Need to figure out how to detect if the import has been reviewed.
        return 'Reviewed'
      },
      isCompleted: (upload) => {
        return true
      },
      label: 'Review'
    },
    {
      completedLabel: (upload) => {
        return `Imported (${upload.inserts_aggregate.aggregate.count} Created, ${upload.updates_aggregate.aggregate.count} Updated)`
      },
      isCompleted: (upload) => {
        return upload.status === 'Imported'
      },
      label: 'Import'
    }
  ]
  const timezoneLabel = timezoneOptions.filter(({ value }) => value === organization.time_zone)[0].label
  const { data, loading, error } = useQuery(listShapefileUploadsQuery, {
    variables: {
      organizationId: organization.id
    }
  })
  const [deleteShapefileUpload] = useMutation(deleteShapefileUploadMutation, {
    refetchQueries: ['ListShapefileUploads']
  })

  const getDateTimeString = (date) => {
    const d = new Date(new Date(`${date}Z`).toLocaleString('en-US', { timeZone: organization.time_zone }))
    return format(d, "LLL d, yyyy, h':'mmaaa")
  }

  const getLocationType = (upload) => {
    const geometryType = upload.metadata?.geometryType

    switch (geometryType) {
      case 'LineString':
        return 'Trail Segments'
      case 'Point':
        return 'Points of Interest'
      case 'Polygon':
        return 'Areas'
    }
  }

  const getStatus = (upload) => {
    // TODO: Once we figure out how to capture the "Configured" and "Reviewed" steps, hook up to all the functions.

    if (!upload.preview) {
      return 'Processing or Imported Through Classic'
    }

    if (upload.preview && upload.preview.errors && upload.preview.errors.length > 0) {
      return 'Errors'
    }

    if (steps[steps.length - 1].isCompleted(upload)) {
      return steps[steps.length - 1].completedLabel(upload)
    } else {
      return 'Uploaded'
    }
  }

  const goToOrgProfile = () => {
    history.push(`/${organization.id}/organization/profile/overview`)
  }

  const handleDelete = async (shapefileUploadId) => {
    if (window.confirm('Are you sure you want to delete this upload? This action cannot be reversed.')) {
      await deleteShapefileUpload({
        variables: {
          shapefileUploadId: shapefileUploadId
        }
      })
    }
  }

  const handlePurge = async (shapefileUploadId) => {
    if (
      window.confirm(
        'Are you sure you want to delete all the features that were part of this import? This action cannot be reversed.'
      )
    ) {
      const response = await fetch(`${process.env.REACT_APP_API_SERVICE_URL}/imports/purge-import`, {
        body: JSON.stringify({ id: shapefileUploadId }),
        method: 'POST'
      })

      if (response.ok) {
        toast.success('Purging the import...')
      } else {
        toast.error('The features could not be deleted. Please try again.')
      }
    }
  }

  const handleStartImport = (shapefileUploadId) => {
    history.push(`${props.match.url}/${shapefileUploadId}/configure`)
  }

  const handleUpload = () => {
    history.push(`${props.match.url}/new`)
  }

  const handleViewLogs = (shapefileUploadId) => {
    history.push(`${props.match.url}/${shapefileUploadId}/logs`)
  }

  useEffect(() => {
    document.title = `Imports | Organization | ${process.env.REACT_APP_PAGE_TITLE}`
    client.refetchQueries({ include: ['ListShapefileUploads'] })
  }, [])

  return (
    <div className='organizationImports-content'>
      <div className='organizationImports-titleBar'>
        <div className='organizationImports-actions'>
          <Button bsStyle='primary' onClick={handleUpload}>
            Upload a GeoJSON File
          </Button>
        </div>
      </div>
      <div>
        {error && (
          <Panel>
            <Panel.Body>
              <Alert bsStyle='danger'>{error.message ? error.message : 'Error'}</Alert>
            </Panel.Body>
          </Panel>
        )}
        {!error && data && data.shapefile_uploads.length === 0 && (
          <Panel>
            <Panel.Body>
              <div className='empty-message'>
                <h3>No imports to display.</h3>
              </div>
            </Panel.Body>
          </Panel>
        )}
        {!error && data && data.shapefile_uploads.length > 0 && (
          <>
            <Panel>
              <Table striped>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th className='centered'>Type</th>
                    <th className='centered'># of Features</th>
                    <th className='centered'>Status</th>
                    <th />
                  </tr>
                </thead>
                {loading && (
                  <tbody>
                    <tr>
                      <td colSpan='7'>
                        <CenteredLoader />
                      </td>
                    </tr>
                  </tbody>
                )}
                {!loading && (
                  <tbody>
                    {data.shapefile_uploads.map((shapefileUpload) => {
                      const importCompleted = shapefileUpload.status === 'Imported'
                      const status = getStatus(shapefileUpload)
                      const tooltip = (() => {
                        if (
                          shapefileUpload.preview &&
                          shapefileUpload.preview.errors &&
                          shapefileUpload.preview.errors.length > 0
                        ) {
                          return <Tooltip id='tooltip'>{shapefileUpload.preview.errors[0]}</Tooltip>
                        }

                        return null
                      })()

                      return (
                        <tr key={shapefileUpload.id}>
                          <td>{getDateTimeString(shapefileUpload.created_at)}</td>
                          <td className='nowrap' title={shapefileUpload.name}>
                            {shapefileUpload.name}
                          </td>
                          <td className='centered'>{getLocationType(shapefileUpload)}</td>
                          <td className='centered'>{shapefileUpload.geo_import_features_aggregate.aggregate.count}</td>
                          <td className='centered'>
                            {shapefileUpload.preview && shapefileUpload.preview.errors && shapefileUpload.preview.errors.length > 0 && (
                              <OverlayTrigger overlay={tooltip} placement='left'>
                                <a href='#'>{status}</a>
                              </OverlayTrigger>
                            )}
                            {(!shapefileUpload.preview ||
                              !shapefileUpload.preview.errors ||
                              shapefileUpload.preview.errors.length < 1) && <span>{status}</span>}
                          </td>
                          <td className='actions'>
                            <DropdownButton id='actions' pullRight title='Actions'>
                              {!importCompleted && status !== 'Errors' && (
                                <>
                                  <MenuItem onClick={() => handleStartImport(shapefileUpload.id)}>
                                    Start Import
                                  </MenuItem>
                                  <MenuItem divider />
                                </>
                              )}
                              <MenuItem download href={shapefileUpload.geojson_url} target='_self'>
                                Download GeoJSON
                              </MenuItem>
                              {!importCompleted && (
                                <MenuItem onClick={() => handleDelete(shapefileUpload.id)}>Delete Upload</MenuItem>
                              )}
                              {importCompleted && (
                                <>
                                  <MenuItem onClick={() => handleViewLogs(shapefileUpload.id)}>View Logs</MenuItem>
                                  <MenuItem onClick={() => handlePurge(shapefileUpload.id)}>Purge</MenuItem>
                                </>
                              )}
                            </DropdownButton>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                )}
              </Table>
            </Panel>
            <p style={{ marginTop: '6px' }}>
              Note: The dates/times displayed above are in the <b>{timezoneLabel}</b> Time Zone assigned to your
              organization. You can change this in your{' '}
              <a className='btn-link' onClick={goToOrgProfile}>
                organization settings
              </a>
              .
            </p>
          </>
        )}
      </div>
    </div>
  )
}

Imports.propTypes = {
  match: PropTypes.object
}

export default withRouter(Imports)
