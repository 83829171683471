export function cleanPhone (dirty) {
  let phone = dirty
  if (phone) {
    phone = phone.replace(/\D/g, '')
    if (phone.length < 10) {
      phone = null
    } else if (phone.length > 10) {
      const start = phone.length - 10
      phone = phone.substr(start, 10)
    }
    if (phone) {
      phone = [phone.substring(0, 3), phone.substring(3, 6), phone.substring(6, 10)].join('-')
    }
  }
  return phone
}

export default { cleanPhone }
