import { useCurrentUser } from 'contexts/CurrentUserContext'
import React from 'react'
import Well from 'react-bootstrap/lib/Well'
import { askOuterSpatialAQuestion } from '../../components/helpScout'

import './errors.css'

export const ErrorLicense = () => {
  const currentUser = useCurrentUser()
  const handleClick = (e) => {
    e.preventDefault()
    askOuterSpatialAQuestion(
      'Managing My Organization', // Pre-filled subject
      'Hello, I need some help with my managing my organization...', // Pre-filled message
      currentUser
    )
  }

  document.title = `License | ${process.env.REACT_APP_PAGE_TITLE}`
  const linkStyle = {
    textDecoration: 'underline',
    color: '#41A7D0',
    cursor: 'pointer'
  }

  return (
    <div className='errorView'>
      <Well>
        <div className='errorView-container'>
          <h2>
            You need to upgrade your OuterSpatial license to access this page. If you'd like to upgrade or feel like this is a mistake, please <br />
            <span onClick={handleClick} style={linkStyle}>contact support</span>.
          </h2>
        </div>
      </Well>
    </div>
  )
}

export default ErrorLicense
