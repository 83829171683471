import { gql } from '@apollo/client'

export const Fragments = {
  OutingDetails: gql`
    fragment OutingDetails on outings {
      accessibility_description
      created_at
      description
      difficulty
      featured_image {
        id
        uploaded_file
      }
      id
      name
      outing_areas {
        created_at
        feature: area {
          id
          name
        }
        feature_type
        id
      }
      route {
        id
        length_miles
        geometry
      }
      route_type
      stewardships {
        id
        organization {
          id
          name
        }
        organization_id
        role
      }
      tags {
        key
        value
        tag_descriptor {
          id
          name
          category {
            id
            name
          }
        }
      }
      updated_at
      visibility
      waypoints(order_by: {position: asc_nulls_last}) {
        id
        name
        description
        featured_image {
          id
          uploaded_file
          uploaded_file
        }
        location {
          geometry
          waypoint_id
        }
        position
      }
    }
  `,
  OutingsDetails: gql`
    fragment OutingsDetails on outings {
      route_type
      id
      name
      route {
        id
        length_miles
      }
      stewardships(
        where: {
          organization_id: { _eq: $organizationId }
        }
      ) {
        role
      }
      visibility
      tags {
        key
        value
        tag_descriptor {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
  `
}

export const Mutations = {
  DeleteFeatureGeometry: gql`
    mutation DeleteFeatureGeometry($geometryId: Int!) {
      delete_geometry_attributes(where: {id: {_eq: $geometryId}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  DeleteOuting: gql`
    mutation DeleteOuting($id: Int!) {
      delete_outings(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
      delete_stewardships(where: {stewardable_type:{_eq:"Outing"}, stewardable_id:{_eq:$id}}){
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  DeleteWaypoint: gql`
    mutation DeleteWaypoint($waypoint_id: Int!) {
      delete_waypoints(where: {id: {_eq: $waypoint_id}}) {
        affected_rows
        returning {
          id
        }
      }  
    }
  `,
  DeleteWaypointAndGeometry: gql`
    mutation DeleteWaypointAndGeometry($waypoint_id: Int!) {
      delete_geometry_attributes(where: {
        feature_id: { _eq: $waypoint_id },
        feature_type: {_eq: "Waypoint"}
        }) {
        affected_rows
        returning {
          id
        }
      }
      delete_waypoints(where: {id: {_eq: $waypoint_id}}) {
        affected_rows
        returning {
          id
        }
      }  
    }
  `,
  InsertOuting: gql`
    mutation InsertOuting(
      $accessibility_description: String,
      $description: String,
      $difficulty: String,
      $featuredImageId: Int,
      $name: String!,
      $route_type: String
    ) {
      insert_outings(objects: {
        accessibility_description: $accessibility_description,
        description: $description,
        difficulty: $difficulty,
        featured_image_id: $featuredImageId,
        name: $name,
        route_type: $route_type,
        visibility: "Draft"
      }) {
        affected_rows
        returning {
          ...OutingDetails
        }
      }
    }
    ${Fragments.OutingDetails}
  `,
  InsertOutingStewardshipAndEmptyRoute: gql`
    mutation InsertOutingStewardshipAndEmptyRoute(
      $outingId: Int!,
      $organizationId: Int!
    ) {
      insert_stewardships(objects: {
        organization_id: $organizationId,
        stewardable_id: $outingId,
        stewardable_type: "Outing",
        role: "owner"
      }) {
        affected_rows
        returning {
          id
          created_at
          updated_at
        }
      }
      insert_geometry_attributes(objects: {
        attribute_name: "geom",
        feature_id: $outingId,
        feature_type: "Outing",
        geom: null
      }) {
        affected_rows
        returning {
          id
          feature_id
          geom
          length_miles
        }
      }
    }
  `,
  InsertWaypoint: gql`
    mutation InsertWaypoint(
      $outingId: Int!,
      $name: String,
      $position: Int!,
      $description: String,
      $feature_id: Int,
      $feature_type: String,
    ) {
      insert_waypoints(objects: {
        description: $description,
        feature_id: $feature_id,
        feature_type: $feature_type,
        outing_id: $outingId,
        name: $name,
        position: $position
      }) {
        returning {
          created_at
          description
          feature: feature_point_of_interest {
            __typename
            id
            name
            description
            area {
              id
              name
            }
            point_of_interest_type {
              name
            }
            location {
              geometry
            }            
            image_attachments {
              id
              position
              image {
                id
                uploaded_file
              }
            }
          }
          id
          name
          position
          featured_image {
            id
            uploaded_file
          }
        }
      }
    }
  `,
  InsertWaypointLocation: gql`
    mutation InsertWaypointLocation(
      $geom: geometry,
      $feature_id: Int
    ) {
      insert_geometry_attributes(objects: {
        attribute_name: "geom",
        feature_id: $feature_id,
        feature_type: "Waypoint",
        geom: $geom
      }) {
        returning {
          geom
          id
          feature_id
        }
      }
    }
  `,
  UpdateOuting: gql`
    mutation UpdateOuting(
      $accessibility_description: String,
      $description: String,
      $difficulty: String,
      $featuredImageId: Int,
      $id: Int!,
      $keyValuesOfTagsToDelete: [String!],
      $name: String,
      $newTags: [tags_insert_input!]!,
      $route_type: String
    ) {
      insert_tags(objects: $newTags) {
        affected_rows
        returning {
          id
          feature_type
          feature_id
          key
          value
        }
      }
      delete_tags(
        where: {
          feature_id: {_eq: $id},
          feature_type: {_eq: "Outing"},
          key: {_in: $keyValuesOfTagsToDelete}
        }
      ) {
        affected_rows
      }
      update_outings(
        where: {id: {_eq: $id}},
        _set: {
          accessibility_description: $accessibility_description,
          description: $description,
          difficulty: $difficulty,
          featured_image_id: $featuredImageId,
          name: $name,
          route_type: $route_type
        }
      ) {
        affected_rows
        returning {
          ...OutingDetails
        }
      }
    }
    ${Fragments.OutingDetails}
  `,
  UpdateOutingGeometry: gql`
    mutation UpdateOutingGeometry(
      $route: geometry!
      $outingId: Int!
    ){
      update_geometry_attributes(
        where: {
          feature_id: {_eq: $outingId}
          feature_type: {_eq: "Outing"}
        },
        _set: {geom: $route}
      ) {
        affected_rows
        returning {
          id
          feature_id
          geometry: geom
          length_miles
        }
      }
    }
  `,
  UpdateOutingTags: gql`
    mutation UpdateOutingTags(
      $outingId: Int!,
      $newTags: [tags_insert_input!]!,
      $keyValuesOfTagsToDelete: [String!]
    ) {
      insert_tags(objects: $newTags) {
        affected_rows
        returning {
          id
          feature_type
          feature_id
          key
          value
        }
      }
      delete_tags(
        where: {
          feature_id: {_eq: $outingId},
          feature_type: {_eq: "Outing"},
          key: {_in: $keyValuesOfTagsToDelete}
        }
      ) {
        affected_rows
      }
    }
  `,
  UpdateVisibilityForOuting: gql`
    mutation UpdateVisibilityForOuting(
      $outingId: Int!,
      $visibility: String!
    ) {
      update_outings(
        where: {id: {_eq: $outingId}},
        _set: {
          visibility: $visibility
        }
      ) {
        returning {
          id
          visibility
        }
      }
    }
  `,
  UpdateWaypoint: gql`
    mutation UpdateWaypoint(
      $waypoint_id: Int!,
      $updates: waypoints_set_input
    ) {
      update_waypoints(
        where: {
          id: {_eq: $waypoint_id}
        },
        _set: $updates
      ) {
        returning {
          id
          name
          position
          description
          feature: feature_point_of_interest {
            id
            name
            description
            area {
              id
              name
            }
            point_of_interest_type {
              name
            }
            location {
              geometry
            }
            image_attachments {
              id
              position
              image {
                id
                uploaded_file
              }
            }
          }
          featured_image {
            id
            uploaded_file
          }
          location {
            geometry
            waypoint_id
          }
          outing_id
        }
      }
    }
  `,
  UpdateWaypointLocation: gql`
    mutation UpdateWaypointLocation(
      $feature_id: Int!,
      $geom: geometry!
    ) {
      update_geometry_attributes(
        where: {
          _and: [
            {attribute_name: {_eq: "geom"}},
            {feature_id: {_eq: $feature_id}},
            {feature_type: {_eq: "Waypoint"}}
          ]
        },
        _set: {
          geom: $geom
        }
      ) {
        returning {
          geom
          id
          feature_id
        }
      }
    }
  `,
  UpdateWaypoints: gql`
    mutation UpdateWaypoints(
      $waypoint_id: Int!,
      $updates: waypoints_set_input
    ) {
      update_waypoints(
        where: {
          id: {_eq: $waypoint_id}
        },
        _set: $updates
      ) {
        returning {
          id
          name
          position
          description
          featured_image_id
          featured_image {
            id
            uploaded_file
          }
          location {
            geometry
            waypoint_id
          }
          outing_id
        }
      }
    }
  `
}

export const Queries = {
  GetCommunitiesForOrganization: gql`
    query GetCommunitiesForOrg($organizationId: Int!) {
      communities(where: {community_memberships: {community: {organization_community_memberships: {organization_id: {_eq: $organizationId}}}}}) {
        description
        extent {
          geometry
        }
        id
        is_sponsored
        name
      }
    }
  `,
  GetOutingById: gql`
    query GetOutingById($id: Int!) {
      outings(
        limit: 1,
        where: {id: {_eq: $id}}
      ) {
        ...OutingDetails
        waypoints(order_by: {position: asc_nulls_last}) {
          id
          name
          description
          position
          location {
            geometry
            waypoint_id
          }
          featured_image {
            id
            uploaded_file
          }
          feature: feature_point_of_interest {
            id
            name
            description
            area {
              id
              name
            }
            point_of_interest_type {
              name
            }
            location {
              geometry
            }
            image_attachments {
              id
              position
              image {
                id
                uploaded_file
              }
            }
          }
        }
      }
    }
    ${Fragments.OutingDetails}
  `,
  GetOutings: gql`
    query GetOutings(
      $organizationId: Int!,
      $orderBy: [outings_order_by!] = {name: asc},
      $limit: Int,
      $offset: Int
    ) {
      outings(
        where: {
          stewardships: { organization: { id: { _eq: $organizationId } } }
        }
        order_by: $orderBy,
        limit: $limit,
        offset: $offset
      ) {
        ...OutingsDetails
      }
    }
    ${Fragments.OutingsDetails}
  `
}
