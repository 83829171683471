import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import Alert from 'react-bootstrap/lib/Alert'
import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { CenteredLoader } from '../../../components/centeredLoader'
import { Fragments, Mutations, Queries } from '../OutingsOperations'
import { PageContent, PageHeader, PageLayout } from '../../../components/pageLayout'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import debugMessage from 'services/Debug'
import Error404 from '../../../views/errors/404'
import FeatureDocuments from '../../../views/features/feature/featureDocuments/FeatureDocuments'
import FeatureMaps from '../../../views/features/feature/featureMaps/FeatureMaps'
import FeaturePosts from '../../../views/features/feature/featurePosts/FeaturePosts'
import OutingHeader from './OutingHeader'
import OutingInfo from './OutingInfo'
import OutingRoute from './route/OutingRoute'

import './outing.css'

const Outing = () => {
  const [outingRouteGeoJSON, setOutingRouteGeojson] = useState()
  const client = useApolloClient()
  let defaultRouteGeoJSON = null
  const formRef = useRef()
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const outingId = match.params.outing_id
  let outingWaypoints = []
  let outingData = {
    accessibility_description: '',
    description: '',
    featured_image: null,
    id: null,
    name: '',
    route: null,
    tags: [],
    visibility: 'Draft',
    waypoints: outingWaypoints
  }
  // TODO: Remove this once we refactor the FeatureMaps/FeaturePosts/FeatureDocuments away from the V2 API
  const v2APIModel = {
    id: outingId,
    class_name: 'Outing',
    table_name: 'outings'
  }

  const { data: communitiesData, loading: getCommunitiesIsLoading } = useQuery(
    Queries.GetCommunitiesForOrganization, {
      variables: {
        organizationId: organization.id
      }
    }
  )

  const { data: outing, error: getError, loading: getOutingIsLoading } = useQuery(
    Queries.GetOutingById, {
      skip: outingId === 'new',
      variables: {
        id: outingId
      }
    }
  )

  const [deleteOuting, { loading: deleteOutingIsLoading }] = useMutation(
    Mutations.DeleteOuting, {
      onCompleted: () => {
        toast.success('Outing has been deleted')
      },
      onError: (error) => {
        debugMessage(error)
        toast.error('The Outing could not be deleted.')
      }
    }
  )

  const [deleteWaypoint, { loading: deleteWaypointIsLoading }] = useMutation(
    Mutations.DeleteWaypoint, {
      refetchQueries: [Queries.GetOutingById],
      onError: (error) => {
        debugMessage(error)
        toast.error('The Waypoint could not be deleted.')
      }
    }
  )

  const [insertOuting, { loading: insertOutingIsLoading }] = useMutation(
    Mutations.InsertOuting, {
      onError: (error) => {
        debugMessage(error)
        toast.error('The Outing could not be created.')
      },
      update: (cache, response) => {
        const returning = response?.data?.insert_outings?.returning[0]

        if (returning) {
          cache.modify({
            fields: {
              outings (existingOutings = []) {
                const fragment = Fragments.OutingDetails
                const newOutingRef = cache.writeFragment({
                  data: returning,
                  fragment: fragment
                })

                return [...existingOutings, newOutingRef]
              }
            }
          })
        }
      }
    }
  )

  const [insertOutingStewardshipAndEmptyRoute, { loading: insertOutingStewardshipAndRouteIsLoading }] = useMutation(
    Mutations.InsertOutingStewardshipAndEmptyRoute, {
      onError: (error) => {
        // Delete the Outing since we couldn't add the stewardship and Empty Route geometry
        deleteOuting({
          variables: {
            outingId: outingId
          }
        })
        debugMessage(error)
        toast.error('There was a problem creating the Outing Stewardship.')
      }
    }
  )

  const [insertWaypoint, { loading: insertWaypointIsLoading }] = useMutation(
    Mutations.InsertWaypoint, {
      refetchQueries: [Queries.GetOutingById],
      onError: (error) => {
        debugMessage(error)
        toast.error('The Waypoint could not be created.')
      }
    }
  )

  const [insertWaypointLocation, { loading: insertWaypointLocationIsLoading }] = useMutation(
    Mutations.InsertWaypointLocation, {
      refetchQueries: [Queries.GetOutingById],
      onCompleted: (response) => {
        toast.success('Waypoint created.')
      },
      onError: (error) => {
        debugMessage(error)
        toast.error('The WaypointLocation could not be created.')
      }
    }
  )

  const [updateRoute] = useMutation(
    Mutations.UpdateOutingGeometry, {
      refetchQueries: [Queries.GetOutingById],
      onCompleted: () => {
        toast.success('The Route has been updated.')
      },
      onError: (error) => {
        debugMessage(error)
        toast.error('The Route could not be updated.')
      },
      update: (cache, response) => {
        const updatedOutingGeometry = response.data.update_geometry_attributes.returning[0]
        cache.writeFragment({
          id: `outings:${updatedOutingGeometry.feature_id}`,
          fragment: gql`
            fragment Outing on outings {
              route {
                length_miles
                geometry
              }
            }
          `,
          data: {
            route: {
              length_miles: updatedOutingGeometry.length_miles,
              geometry: updatedOutingGeometry.geometry
            }
          }
        })
      }
    }
  )

  const [updateOuting, { loading: updateOutingIsLoading }] = useMutation(
    Mutations.UpdateOuting, {
      refetchQueries: [Queries.GetOutingById],
      onCompleted: () => {
        toast.success('Outing updated.')
      },
      onError: (error) => {
        debugMessage(error)
        toast.error('The Outing could not be updated.')
      }
    }
  )

  const [updateOutingTags, { loading: updateOutingTagsIsLoading }] = useMutation(
    Mutations.UpdateOutingTags, {
      onError: (error) => {
        debugMessage(error)
        toast.error('The Outing Tags could not be updated.')
      }
    }
  )

  const [updateWaypoint, { loading: updateWaypointIsLoading }] = useMutation(
    Mutations.UpdateWaypoint, {
      refetchQueries: [Queries.GetOutingById],
      onError: (error) => {
        debugMessage(error)
        toast.error('The Waypoint could not be updated.')
      }
    }
  )

  const [updateWaypointLocation, { loading: updateWaypointLocationIsLoading }] = useMutation(
    Mutations.UpdateWaypointLocation, {
      refetchQueries: [Queries.GetOutingById],
      onError: (error) => {
        debugMessage(error)
        toast.error('The Waypoint location could not be updated.')
      }
    }
  )

  const formatTagsData = (outingId, tags) => {
    let newTags = []
    if (tags.length > 0) {
      newTags = tags.map((tag) => {
        return {
          key: tag.key,
          value: 'yes',
          feature_type: 'Outing',
          feature_id: outingId
        }
      })
    }
    return newTags
  }

  const handleRouteAddWaypoint = (waypoint, callbackOnSuccess) => {
    const geojson = waypoint.location.geometry
    geojson.crs = { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::4326' } }

    insertWaypoint({
      variables: {
        description: waypoint.description,
        feature_id: waypoint.feature_id,
        feature_type: waypoint.feature_type,
        name: waypoint.name,
        outingId: outingId,
        position: outingWaypoints.length + 1
      }
    }).then((response) => {
      insertWaypointLocation({
        variables: {
          feature_id: response.data.insert_waypoints.returning[0].id,
          geom: geojson
        }
      }).then(response => {
        // Now call the passed in callback from <OutingRouteMap>
        callbackOnSuccess(response.data.insert_geometry_attributes.returning[0].feature_id)
      })
    })
  }

  const handleRouteDeleteWaypoint = (waypointId) => {
    deleteWaypoint({
      variables: {
        waypoint_id: waypointId
      }
    }).then((response) => {
      // Reorder waypoints - this is done in the onCompleted
      let updatedWaypoints = [...outingWaypoints.filter((waypoint) => (waypoint.id !== waypointId))]
      if (updatedWaypoints.length) {
        let mutation = 'mutation {'

        // TODO-DEFER: Make a new function that creates the GraphQL Mutation AST and resets the position values in local state: Also to be used in handleRouteRemoveWaypoint()
        // TODO-DEFER: There has got to be a better way to do this. It is highly discouraged to be dynamically creating GraphQL ASTs
        // After chatting with Jereme on 2021-Mar-18, the solution is likely to have a Hasura trigger that reindexes the position values for waypoints
        //   and we only need to pass in the sole waypoint change.
        updatedWaypoints.forEach((waypoint, index) => {
          mutation += `
            a${index}: update_waypoints(
              where: {
                id: {_eq: ${waypoint.id}}
              },
              _set: {
                position: ${index + 1}
              }
            ) {
              affected_rows
            }
          `
        })

        mutation += '}'

        updatedWaypoints = updatedWaypoints.map((waypoint, index) => {
          return {
            ...waypoint,
            position: index + 1
          }
        })

        // TODO-DEFER: Figure out how to call a Mutation with the above dynamically created GraphQL AST WITHOUT `client`
        // We want the mutations all combined because:
        //  "If multiple mutations are part of the same request, they are executed sequentially in a single transaction.
        //  If any of the mutations fail, all the executed mutations will be rolled back." - Apollo Client documentation
        // Perform GraphQL mutation to update the position values.
        toast.success('Updating stop order…')
        client.mutate({
          mutation: gql`${mutation}`,
          refetchQueries: [Queries.GetOutingById]
        }).then(
          () => {
            toast.success('Stop order updated.')
            // Update local state to match the successful mutation
            // setOutingWaypoints(updatedWaypoints)
          },
          (error) => {
            // We need this here catching the error from the Promise as we do not have an `onError` handler in a useMutation hook
            debugMessage(error)
          }
        )
      }
    })
  }

  const handleRouteSortWaypoints = (oldIndex, newIndex) => {
    let sortedWaypoints = [...outingWaypoints]
    const waypointToMove = sortedWaypoints[oldIndex]
    let mutation = 'mutation {'

    toast.success('Saving stop order…')
    sortedWaypoints.splice(oldIndex, 1)
    sortedWaypoints.splice(newIndex, 0, waypointToMove)

    // TODO-DEFER: Make a new function that creates the GraphQL Mutation AST and resets the position values in local state: Also to be used in handleRouteRemoveWaypoint()
    // TODO-DEFER: There has got to be a better way to do this. It is highly discouraged to be dynamically creating GraphQL ASTs
    sortedWaypoints.forEach((waypoint, index) => {
      mutation += `
        a${index}: update_waypoints(
          where: {
            id: {_eq: ${waypoint.id}}
          },
          _set: {
            position: ${index + 1}
          }
        ) {
          affected_rows
        }
      `
    })

    mutation += '}'

    sortedWaypoints = sortedWaypoints.map((waypoint, index) => {
      return {
        ...waypoint,
        position: index + 1
      }
    })

    // TODO-DEFER: Figure out how to call a Mutation with the above dynamically created GraphQL AST WITHOUT `client`
    // We want the mutations all combined because:
    //  "If multiple mutations are part of the same request, they are executed sequentially in a single transaction.
    //  If any of the mutations fail, all the executed mutations will be rolled back." - Apollo Client documentation
    // Perform GraphQL mutation to update the position values.
    client.mutate({
      mutation: gql`${mutation}`,
      refetchQueries: [Queries.GetOutingById]
    }).then(
      () => {
        toast.success('Stop order saved.')
        // Update local state to match the successful mutation
        // setOutingWaypoints(sortedWaypoints)
      },
      (error) => {
        // We need this here catching the error from the Promise as we do not have an `onError` handler in a useMutation hook
        debugMessage(error)
      }
    )
  }

  const handleRouteUpdateWaypoint = (waypoint, callbackOnSuccess = null) => {
    const handleSuccess = () => {
      toast.success('Stop saved.')
      if (callbackOnSuccess) {
        callbackOnSuccess()
      }
    }

    updateWaypoint({
      variables: {
        waypoint_id: waypoint.id,
        updates: {
          description: waypoint.description,
          featured_image_id: waypoint.featured_image_id,
          name: waypoint.name
        }
      }
    }).then((response) => {
      if (waypoint.updatedLatLng) {
        updateWaypointLocation({
          variables: {
            feature_id: waypoint.id,
            geom: {
              coordinates: [
                waypoint.updatedLatLng.lng,
                waypoint.updatedLatLng.lat
              ],
              crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::4326' } },
              type: 'Point'
            }
          }
        }).then(
          handleSuccess()
        )
      } else {
        handleSuccess()
      }
    })
  }

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    } else {
      handleSubmitRoute()
    }
  }

  const handleSubmitInfoForm = (newOuting, formikBag) => {
    const featuredImageId = newOuting?.featured_image?.id

    if (outingId === 'new') {
      const newBrowserRoute = match.path.split(':outing_id')[0]
      let newOutingId = null

      insertOuting({
        variables: {
          accessibility_description: newOuting.accessibility_description,
          description: newOuting.description,
          difficulty: newOuting.difficulty || null,
          featuredImageId: featuredImageId,
          name: newOuting.name,
          route_type: newOuting.route_type || null
        }
      })
        .then((response) => {
          newOutingId = response?.data?.insert_outings?.returning[0]?.id

          if (newOutingId) {
            const newTags = formatTagsData(newOutingId, newOuting.tags)

            insertOutingStewardshipAndEmptyRoute({
              variables: {
                organizationId: organization.id,
                outingId: newOutingId
              }
            })
              .then((response) => {
                if (newTags.length > 0) {
                  updateOutingTags({
                    variables: {
                      keyValuesOfTagsToDelete: [],
                      newTags: newTags,
                      outingId: newOutingId
                    }
                  })
                    .then((response) => {
                      toast.success('Outing created.')
                      history.replace(`${newBrowserRoute}${newOutingId}`)
                      formikBag.setSubmitting(false)
                    })
                    .catch(e => {
                      debugMessage(e)
                    })
                } else {
                  toast.success('Outing created.')
                  history.replace(`${newBrowserRoute}${newOutingId}`)
                  formikBag.setSubmitting(false)
                }
              })
              .catch(e => {
                debugMessage(e)
              })
          }
        })
        .catch(e => {
          debugMessage(e)
        })
    } else {
      let newTags = newOuting.tags.filter(t => {
        return !(outingData.tags.map(t => t.key).includes(t.key))
      })

      newTags = formatTagsData(outingId, newTags)

      const keyValuesOfTagsToDelete = outingData.tags.filter(t => {
        return !newOuting.tags.map(t => t.key).includes(t.key)
      }).map(t => t.key)

      updateOuting({
        variables: {
          ...{
            accessibility_description: newOuting.accessibility_description,
            description: newOuting.description,
            difficulty: newOuting.difficulty || null,
            featuredImageId: featuredImageId,
            id: newOuting.id,
            name: newOuting.name,
            route_type: newOuting.route_type || null
          },
          keyValuesOfTagsToDelete: keyValuesOfTagsToDelete,
          newTags: newTags
        }
      }).then(
        formikBag.setSubmitting(false)
      )
    }

    formikBag.setSubmitting(false)
  }

  const handleSubmitRoute = () => {
    if (typeof outingRouteGeoJSON === 'object' && outingRouteGeoJSON.type && outingRouteGeoJSON.coordinates?.length) {
      updateRoute({
        variables: {
          outingId: outingId,
          route: {
            coordinates: outingRouteGeoJSON.coordinates,
            crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::4326' } },
            type: outingRouteGeoJSON.type
          }
        }
      })
    } else {
      toast.error('Outing has no route data.')
    }
  }

  const handleUpdateRoute = (geojson) => {
    setOutingRouteGeojson(geojson)
  }

  if (outing && outing.outings.length) {
    const waypoints = outing.outings[0].waypoints

    if (waypoints.length > 0) {
      outingWaypoints = waypoints
    }

    outingData = outing.outings[0]
    defaultRouteGeoJSON = outingData?.route?.geometry
  }

  if (getError && getError.message) {
    return <Alert bsStyle='danger'>{getError.message}</Alert>
  } else if (getOutingIsLoading || getCommunitiesIsLoading || ((outingId !== 'new' && outingData.id === null))) {
    return <CenteredLoader />
  } else if (outing?.outings) {
    if (outing.outings.length === 0) {
      return <Alert bsStyle='warning'>There is no Outing with the requested ID.</Alert>
    }
  } else if (outingId !== 'new') {
    return <Error404 />
  }

  return (
    <div className='outing-wrap'>
      {(insertOutingIsLoading || insertOutingStewardshipAndRouteIsLoading || updateOutingIsLoading || updateOutingTagsIsLoading || deleteOutingIsLoading) && (
        <CenteredLoader overlay />)}
      <PageLayout class='outing'>
        <PageHeader>
          <OutingHeader
            match={match}
            onSaveClick={handleSave}
            outing={outingData}
          />
        </PageHeader>
        <PageContent>
          <Switch>
            <Route
              exact
              path={match.url}
              render={() => <Redirect to={`${match.url}/info`} />}
            />
            <Route
              path={`${match.url}/info`}
              render={(props) => {
                return (
                  <OutingInfo
                    formRef={formRef}
                    history={history}
                    match={match}
                    organization={organization}
                    handleSubmitForm={handleSubmitInfoForm}
                    outing={outingData}
                  />
                )
              }}
            />
            <Route
              path={`${match.url}/route`}
              render={props => {
                return (
                  <OutingRoute
                    communities={communitiesData.communities}
                    onAddWaypoint={handleRouteAddWaypoint}
                    onSortWaypoints={handleRouteSortWaypoints}
                    onUpdateWaypoint={handleRouteUpdateWaypoint}
                    onDeleteWaypoint={handleRouteDeleteWaypoint}
                    onUpdateRoute={handleUpdateRoute}
                    outingWaypoints={outingWaypoints}
                    outingRouteGeoJSON={outingRouteGeoJSON || defaultRouteGeoJSON} // PropTypes.array.isRequired,
                    isUpdatingWaypoints={
                      insertWaypointIsLoading ||
                      insertWaypointLocationIsLoading ||
                      updateWaypointIsLoading ||
                      updateWaypointLocationIsLoading ||
                    deleteWaypointIsLoading
                    }
                  />
                )
              }}
            />
            <Route
              path={`${match.url}/maps`}
              render={({
                match
              }) => (
                <FeatureMaps
                  feature={v2APIModel}
                  history={history}
                  match={match}
                />
              )}
            />
            <Route
              path={`${match.url}/social`}
              render={({
                match
              }) => (
                <FeaturePosts
                  feature={v2APIModel}
                  history={history}
                  match={match}
                />
              )}
            />
            <Route
              path={`${match.url}/documents`}
              render={({
                match
              }) => (
                <FeatureDocuments
                  feature={v2APIModel}
                  history={history}
                  match={match}
                />
              )}
            />
          </Switch>
        </PageContent>
      </PageLayout>
    </div>
  )
}

export default Outing
