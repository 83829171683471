import ReactGA from 'react-ga4'

function trackPage (location) {
  if (['production', 'staging'].includes(process.env.REACT_APP_STAGE_NAME)) {
    window.analytics.page()
    ReactGA.send('pageview')
  }
}

export default trackPage
