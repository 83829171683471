import { gql, useApolloClient } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import _ from 'lodash'
import Async from 'react-select/async'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import PropTypes from 'prop-types'
import React from 'react'

import './stewardshipSet.css'

const mapResults = (results) => {
  let mapped = results.map((organization) => {
    const label = organization.name === '' ? 'Unnamed' : organization.name
    return {
      label: label,
      value: organization.id,
      content: organization
    }
  })
  mapped = _.flatten(mapped)
  mapped = _.sortBy(mapped, m => { if (m.label) { m.label.toLowerCase() } })
  return mapped
}

const OrganizationSearchQuery = gql`
  query OrganizationSearchQuery($communityIds: [Int!], $name: String) {
    organization_community_memberships(where:{community_id:{_in:$communityIds},organization:{name:{_ilike: $name}}}, order_by:{organization:{name:asc}}) {
      organization {
        id
        name
      }
    }
  }
`

const StewardshipSet = (props) => {
  const client = useApolloClient()
  const organization = useCurrentOrganization()
  const communityIds = organization.communities.map(item => item.community.id)

  const fetchOptions = (query, callback) => {
    client.query({
      query: OrganizationSearchQuery,
      variables: {
        communityIds: communityIds,
        name: query && query.length > 0 ? `%${query}%` : '%%'
      }
    }).then(({ data }) => {
      const stewardshipIds = props.stewardships.filter(o => (o.role === props.roleType && o.organization_id !== null)).map(o => o.organization.id)
      // filter out existing stewardships
      let filtered = data ? data.organization_community_memberships.map(item => item.organization).filter(o => !stewardshipIds.includes(o.id)) : []
      filtered = _.uniqBy(filtered, 'name')
      const queryResults = props.map ? props.map(filtered) : mapResults(filtered)

      return callback(queryResults)
    })
  }

  const _fetchOptions = _.debounce(fetchOptions, 200)

  const handleChange = (value) => {
    // If the id is null, we can assume that the user provided input not in the list of options
    if (value.content.id !== null) {
      props.onAdd({
        organization: value.content,
        role: props.roleType
      })
    }
  }

  const search = (query, callback) => {
    _fetchOptions(query, callback)
  }

  const {
    onRemove,
    placeholder,
    single,
    stewardships = []
  } = props
  const filteredStewardships = stewardships.filter(s => s.organization_id !== null && s.organization_name !== null).filter(o => (o.role === props.roleType))

  return (
    <div className='stewardshipSet'>
      {!single && filteredStewardships.length > 0 &&
        <div className='stewardshipSet-list'>
          {filteredStewardships.map((stewardship) => {
            return (
              <div className='stewardshipSet-listItem' key={stewardship.id ? stewardship.id : stewardship.organization_id}>
                <p className='stewardshipSet-listItem__title'>
                  {stewardship.organization.name}
                </p>
                <div className='stewardshipSet-listItem__actions'>
                  <Button bsStyle='link' bsSize='xsmall' onClick={() => onRemove(stewardship)}><Glyphicon glyph='remove' /></Button>
                </div>
              </div>
            )
          })}
        </div>}
      {props.onAdd &&
        <Async
          cacheOptions
          defaultOptions
          isClearable={false}
          loadOptions={search}
          onChange={(value) => handleChange(value)}
          placeholder={placeholder || 'Search organizations...'}
          value={null}
        />}
    </div>
  )
}

StewardshipSet.propTypes = {
  map: PropTypes.func, // function to map results set
  onAdd: PropTypes.func, // add/select callback
  onRemove: PropTypes.func, // remove callback
  placeholder: PropTypes.string, // text placeholder for input
  roleType: PropTypes.string,
  single: PropTypes.bool, // allow for multiple select or single select?
  stewardships: PropTypes.array // list of stewardships already set
}

export default StewardshipSet
