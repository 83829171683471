import { gql, useMutation, useQuery } from '@apollo/client'
import Alert from 'react-bootstrap/lib/Alert'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import _ from 'lodash'
import t from 'prop-types'
import React, { useState } from 'react'
import { featureByType } from 'models/feature/feature.lang'
import { useParams, useRouteMatch } from 'react-router'
import toast from 'react-hot-toast'
import { CenteredLoader } from '../../../components/centeredLoader'
import defaultPaperMap from '../../../models/paperMap/paperMap.model'
import ActionBar from './mapsActionBar/MapsActionBar'
import './mapsList.css'
import MapsTable from './mapsTable/MapsTable'
import Uploader from 'components/uploader'
const defaultPaperMapQuery = defaultPaperMap.paperMaps.query

const MapsListQuery = gql`
  query MapsListQuery($id: Int!, $limit: Int, $offset: Int) {
    organizations_by_pk(id: $id) {
      paper_map_attachments(order_by: { paper_map: { name: asc } }) {
        paper_map {
          id
          name
          description
          created_at
          status
        }
      }
    }
  }
`

const FeatureMapsListQuery = gql`
  query FeatureMapsListQuery($feature_type: String!, $feature_id: Int!, $limit: Int, $offset: Int) {
    paper_map_attachments(
      where: { feature_type: { _eq: $feature_type }, feature_id: { _eq: $feature_id } }
      order_by: { paper_map: { name: asc } }
    ) {
      paper_map {
        id
        name
        description
        status
        created_at
      }
    }
  }
`

const DeletePaperMap = gql`
  mutation DeletePaperMap($id: Int!) {
    delete_paper_map_attachments(where: { paper_map_id: { _eq: $id } }) {
      affected_rows
    }
    delete_paper_maps_by_pk(id: $id) {
      id
      created_at
    }
  }
`

const MapsList = (props) => {
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const { mapType } = useParams()
  const [error, setError] = useState()
  const { feature, paging } = props
  const listQuery = feature ? FeatureMapsListQuery : MapsListQuery
  const listVariables = feature
    ? { feature_id: feature.id, feature_type: featureByType(feature.__typename || feature.table_name).singular }
    : { id: organization.id }
  const [showUploader, setShowUploader] = useState(false)
  const defaultQuery = defaultPaperMapQuery
  const [helpHidden, setHelpHidden] = useState()
  const [query, setQuery] = useState(defaultQuery)
  const [deletePaperMap] = useMutation(DeletePaperMap, { refetchQueries: [listQuery] })
  const { data, loading } = useQuery(listQuery, {
    variables: listVariables
  })

  const handleUploadErrors = (errors) => {
    if (!errors.length) {
      setError(null)
    } else {
      setError(errors[0])
    }
  }

  const handleUploadSuccess = (documentId) => {
    setShowUploader(false)
  }

  let maps = []

  if (data) {
    if (feature) {
      maps = data.paper_map_attachments.map((item) => item.paper_map)
    } else {
      maps = data.organizations_by_pk.paper_map_attachments.map((item) => item.paper_map)
    }
  }

  const deleteMap = (map) => {
    const conf = window.confirm('Are you sure you want to permanently delete this map?')

    if (conf) {
      deletePaperMap({ variables: { id: map.id } }).then(() => {
        toast.success('Deleted map successfully.')
      })
    }
  }

  const toggleHelp = () => {
    setHelpHidden(!helpHidden)
  }

  const queryChange = (value, resetPage = false) => {
    const defaultQuery = defaultPaperMapQuery

    if (!resetPage) {
      resetPage = !value.page
    }

    value = { ...defaultQuery, ...query, ...value }
    value.page = resetPage ? 1 : value.page

    // Don't do anything if the query hasn't changed
    if (_.isEqual(query, value)) {
      return false
    }
    setQuery(value)
  }

  const handlePageChange = (page) => {
    queryChange({ page })
  }

  return (
    <div className='mapsList-wrap'>
      <div>
        {error && <Alert bsStyle='danger'>{error}</Alert>}
        {error && error.message && <Alert bsStyle='danger'>{error.message}</Alert>}
        <Uploader
          allowClose
          feature={feature}
          filetype='PaperMap'
          onError={handleUploadErrors}
          onHide={() => {
            setShowUploader(false)
          }}
          onUploadSuccess={handleUploadSuccess}
          show={showUploader}
        />
      </div>
      <div className='mapsList-actionBar'>
        <ActionBar                    
          match={match}
          onHelpClick={toggleHelp}
          helpHidden={helpHidden}
          onUploadClick={() => {
            setShowUploader(true)
          }}
        />
      </div>
      <div className='mapsList-content'>
        <div className='mapsList-table'>
          {loading && (
            <div className='mapsList-loader'>
              <CenteredLoader />
            </div>
          )}
          {!loading && (
            <MapsTable
              {...{
                maps,
                paging,
                mapType,
                feature,
                query
              }}
              onChangeQuery={queryChange}
              paging={paging}
              onDelete={deleteMap}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  )
}

MapsList.propTypes = {
  feature: t.object,
  paging: t.object
}

export default MapsList
