import React from 'react'
import t from 'prop-types'
import ActivitiesAccessibilityAreas from './Feature.ActivitiesAccessibility.Areas.js'
import ActivitiesAccessibilityTrails from './Feature.ActivitiesAccessibility.Trails.js'
import ActivitiesAccessibilityPointsOfInterest from './Feature.ActivitiesAccessibility.PointsOfInterest.js'

const classMatch = (classes, feature) => classes.indexOf(feature.class_name) > -1

const ActivitiesAccessibility = (props) => {
  const { feature } = props

  return (
    <div className='featureSection-content featureSection--basicInfo'>
      {classMatch(['Area'], feature) &&
        <ActivitiesAccessibilityAreas {...props} />}

      {classMatch(['Trail'], feature) &&
        <ActivitiesAccessibilityTrails {...props} />}

      {classMatch(['PointOfInterest'], feature) &&
        <ActivitiesAccessibilityPointsOfInterest {...props} />}
    </div>
  )
}

ActivitiesAccessibility.propTypes = {
  feature: t.object,
  onSeasonChange: t.func
}

export default ActivitiesAccessibility
