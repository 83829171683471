// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/#track
const segmentTrack = (eventName, properties, me) => {
  let context = {}

  if (me?.id) {
    context = {
      traits: {
        email: me.email,
        id: me.id,
        name: me.profile.name
      }
    }
  }

  if (['production', 'staging'].includes(process.env.REACT_APP_STAGE_NAME)) {
    window.analytics?.track && window.analytics.track(eventName, properties, { context: context })
  } else {
    console.log('segmentTrack', eventName, properties, context)
  }
}

export default segmentTrack
