import { gql } from '@apollo/client'

export const Fragments = {
  AreasDetails: gql`
    fragment AreasDetails on areas {
      id
      name
      stewardships {
        organization {
          id
          name
        }
      }
    }
  `,
  ChallengesDetails: gql`
    fragment ChallengesDetails on challenges {
      id
      name
      organization {
        community_memberships {
          community_id
          id
          member_type
          member_id
        }
      }
    }
  `,
  EventsDetails: gql`
    fragment EventsDetails on events {
      id
      name
      organization {
        name
        id
      }
    }
  `,
  ContentBundleDetails: gql`
    fragment ContentBundleDetails on content_bundles {
      id
      name
      organization {
        id
        name
      }
    }
  `,
  OrganizationsDetails: gql`
    fragment OrganizationsDetails on organizations {
      id
      name
    }
  `,
  EntitySelectOutingsDetails: gql`
    fragment EntitySelectOutingsDetails on outings {
      id
      name
      stewardships {
        organization {
          id
          name
        }
      }
    }
  `,
  PointsOfInterestDetails: gql`
    fragment PointsOfInterestDetails on points_of_interest {
      id
      name
      area {
        name
      }
      stewardships {
        organization {
          id
          name
        }
      }
    }
  `,
  TrailsDetails: gql`
    fragment TrailsDetails on trails {
      id
      name
      area {
        name
      }
      stewardships {
        organization {
          id
          name
        }
      }
    }
  `
}

export const Queries = {
  // TODO: Consider accepting either communityId or organizationId, if the organization is not yet part of a community.
  SearchAllEntitiesForCampaign: gql`
    query SearchAllEntitiesForCampaign($communityId: Int!, $organizationId: Int!, $term: String!, $limit: Int! = 5) {
      areas(where: {
          community_areas: {community_id: {_eq: $communityId}},
          name: {_ilike: $term}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...AreasDetails
      }
      challenges(where: {
        name: {_ilike: $term},
        organization: {community_memberships: {community_id: {_eq: $communityId}}}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...ChallengesDetails
      }
      content_bundles(where: {
          name: {_ilike: $term},
          organization: {id: {_eq: $organizationId}}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...ContentBundleDetails
      }
      events(where: {
          organization_id: {_eq: $organizationId},
          name: {_ilike: $term}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...EventsDetails
      }
      organizations(where: {
          community_memberships: {community_id: {_eq: $communityId}},
          name: {_ilike: $term}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...OrganizationsDetails
      }
      outings(where: {
          name: {_ilike: $term},
          stewardships: {organization_id: {_eq: $organizationId}}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...EntitySelectOutingsDetails
      }
      points_of_interest(where: {
          community_points_of_interest: {community_id: {_eq: $communityId}},
          name: {_ilike: $term}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...PointsOfInterestDetails
      }
      trails(where: {
          community_trails: {community_id: {_eq: $communityId}},
          name: {_ilike: $term}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...TrailsDetails
      }
    }
    ${Fragments.AreasDetails}
    ${Fragments.ChallengesDetails}
    ${Fragments.EventsDetails}
    ${Fragments.ContentBundleDetails}
    ${Fragments.OrganizationsDetails}
    ${Fragments.EntitySelectOutingsDetails}
    ${Fragments.PointsOfInterestDetails}
    ${Fragments.TrailsDetails}
  `,
  SearchEntitiesWithinOrganization: gql`
    query SearchEntitiesWithinOrganization($organizationId: Int!, $term: String!, $limit: Int! = 5) {
      areas(where: {
          _and: [
            { stewardships: { organization_id: { _eq: $organizationId } } },
            {
              _or: [
                { name: { _ilike: $term } },
                { uid: { _ilike: $term } }
              ]
            }
          ]
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...AreasDetails
      }
      challenges(where: {
          organization: {id: {_eq: $organizationId}}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...ChallengesDetails
      }
      content_bundles(where: {
          name: {_ilike: $term},
          organization: {id: {_eq: $organizationId}}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...ContentBundleDetails
      }
      events(where: {
          organization_id: {_eq: $organizationId},
          name: {_ilike: $term}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...EventsDetails
      }
      outings(where: {
          name: {_ilike: $term},
          stewardships: {organization_id: {_eq: $organizationId}}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...EntitySelectOutingsDetails
      }
      points_of_interest(where: {
          _and: [
            { stewardships: { organization_id: { _eq: $organizationId } } },
            {
              _or: [
                { name: { _ilike: $term } },
                { uid: { _ilike: $term } }
              ]
            }
          ]
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...PointsOfInterestDetails
      }
      trails(where: {
          _and: [
            { stewardships: { organization_id: { _eq: $organizationId } } },
            {
              _or: [
                { name: { _ilike: $term } },
                { uid: { _ilike: $term } }
              ]
            }
          ]
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...TrailsDetails
      }
    }
    ${Fragments.AreasDetails}
    ${Fragments.ChallengesDetails}
    ${Fragments.EventsDetails}
    ${Fragments.ContentBundleDetails}
    ${Fragments.OrganizationsDetails}
    ${Fragments.EntitySelectOutingsDetails}
    ${Fragments.PointsOfInterestDetails}
    ${Fragments.TrailsDetails}
  `,
  SearchEntitiesById: gql`
    query SearchEntitiesById($entityId: Int!, $limit: Int! = 1) {
      areas(where: {id: {_eq: $entityId}}, limit: $limit) {
        ...AreasDetails
      }
      challenges(where: {id: {_eq: $entityId}}, limit: $limit) {
        ...ChallengesDetails
      }
      content_bundles(where: {id: {_eq: $entityId}}, limit: $limit) {
        ...ContentBundleDetails
      }
      events(where: {id: {_eq: $entityId}}, limit: $limit) {
        ...EventsDetails
      }
      organizations(where: {id: {_eq: $entityId}}, limit: $limit) {
        ...OrganizationsDetails
      }
      outings(where: {id: {_eq: $entityId}}, limit: $limit) {
        ...EntitySelectOutingsDetails
      }
      points_of_interest(where: {id: {_eq: $entityId}}, limit: $limit) {
        ...PointsOfInterestDetails
      }
      trails(where: {id: {_eq: $entityId}}, limit: $limit) {
        ...TrailsDetails
      }
    }
    ${Fragments.AreasDetails}
    ${Fragments.ChallengesDetails}
    ${Fragments.EventsDetails}
    ${Fragments.ContentBundleDetails}
    ${Fragments.OrganizationsDetails}
    ${Fragments.EntitySelectOutingsDetails}
    ${Fragments.PointsOfInterestDetails}
    ${Fragments.TrailsDetails}
  `,
  SearchAllFeaturesByOrganization: gql`
    query SearchAllFeaturesByOrganization(
      $organizationId: Int!,
      $excludeAreas: [Int!] = [],
      $excludeOutings: [Int!] = [],
      $excludePointsOfInterest: [Int!] = [],
      $excludeTrails: [Int!] = [],
      $term: String!,
      $limit: Int! = 5
    ) {
      areas(where: {
          name: {_ilike: $term}
          stewardships: {organization_id: {_eq: $organizationId}},
          id: {_nin: $excludeAreas}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...AreasDetails
      }
      outings(where: {
          name: {_ilike: $term},
          stewardships: {organization_id: {_eq: $organizationId}},
          id: {_nin: $excludeOutings}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...EntitySelectOutingsDetails
      }
      points_of_interest(where: {
          name: {_ilike: $term}
          stewardships: {organization_id: {_eq: $organizationId}},
          id: {_nin: $excludePointsOfInterest}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...PointsOfInterestDetails
      }
      trails(where: {
          name: {_ilike: $term}
          stewardships: {organization_id: {_eq: $organizationId}},
          id: {_nin: $excludeTrails}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...TrailsDetails
      }
    }
    ${Fragments.AreasDetails}
    ${Fragments.EntitySelectOutingsDetails}
    ${Fragments.PointsOfInterestDetails}
    ${Fragments.TrailsDetails}
  `,
  SearchAreasByCommunity: gql`
    query SearchAreasByCommunity($communityId: Int!, $term: String!, $limit: Int! = 20) {
      areas(where: {
          community_areas: {community_id: {_eq: $communityId}},
          name: {_ilike: $term}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...AreasDetails
      }
    }
    ${Fragments.AreasDetails}
  `,
  SearchChallengesByOrganization: gql`
    query SearchChallengesByOrganization($organizationId: Int!, $term: String!, $limit: Int! = 20) {
      challenges(where: {
          organization_id: {_eq: $organizationId},
          name: {_ilike: $term}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...ChallengesDetails
      }
    }
    ${Fragments.ChallengesDetails}
  `,
  SearchEventsByOrganization: gql`
    query SearchEventsByOrganization($organizationId: Int!, $term: String!, $limit: Int! = 20) {
      events(where: {
          organization_id: {_eq: $organizationId},
          name: {_ilike: $term}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...EventsDetails
      }
    }
    ${Fragments.EventsDetails}
  `,
  SearchArticlesByOrganization: gql`
    query SearchArticlesByOrganization($organizationId: Int!, $term: String!, $limit: Int! = 20) {
      content_bundles(where: {
          name: {_ilike: $term},
          organization: {id: {_eq: $organizationId}}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...ContentBundleDetails
      }
    }
    ${Fragments.ContentBundleDetails}
  `,
  SearchOrganizationByCommunity: gql`
    query SearchOrganizationByCommunity($communityId: Int!, $term: String!, $limit: Int! = 20) {
      organizations(where: {
          community_memberships: {community_id: {_eq: $communityId}},
          name: {_ilike: $term}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...OrganizationsDetails
      }
    }
    ${Fragments.OrganizationsDetails}
  `,
  SearchOutingsByOrganization: gql`
    query SearchOutingsByOrganization($organizationId: Int!, $term: String!, $limit: Int! = 20) {
      outings(where: {
          name: {_ilike: $term},
          stewardships: {organization_id: {_eq: $organizationId}}
        },
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...EntitySelectOutingsDetails
      }
    }
    ${Fragments.EntitySelectOutingsDetails}
  `,
  SearchPointsOfInterestByCommunity: gql`
    query SearchPointsOfInterestByCommunity($communityId: Int!, $term: String!, $limit: Int! = 20) {
      points_of_interest(where: {
          community_points_of_interest: {community_id: {_eq: $communityId}},
          name: {_ilike: $term}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...PointsOfInterestDetails
      }
    }
    ${Fragments.PointsOfInterestDetails}
  `,
  SearchTrailsByCommunity: gql`
    query SearchTrailsByCommunity($communityId: Int!, $term: String!, $limit: Int! = 20) {
      trails(where: {
          community_trails: {community_id: {_eq: $communityId}},
          name: {_ilike: $term}
        }
        limit: $limit,
        order_by: {name: asc}
      ) {
        ...TrailsDetails
      }
    }
    ${Fragments.TrailsDetails}
  `
}
