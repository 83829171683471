import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import '../../features/feature/featureInfo/sections/featureSeasons.css'
import { Queries } from '../../../components/featureTagSet/FeatureTagSetOperations'
import { useQuery } from '@apollo/client'
import { CenteredLoader } from '../../../components/centeredLoader'

/* ** Seasonality Tag details **
// By default, when creating an Outing/Feature, there are no Seasons specified,
//  therefore, all seasons are enabled. If the Outing/Feature is saved with all
//  seasons enabled, we don't save any seasons
//  otherwise, we save the 1-3 selected season tags individually.
// UI: We don't allow all 4 seasons to be disabled. When there is 1 left, we disable
//  changing the value to disabled in order to force the user to select at least one season
*/

const FeatureSeasons = ({ tags, onSeasonChange }) => {
  const [checkedSeasons, setCheckedSeasons] = useState([])

  // GraphQL Query
  const { loading: getSeasonalityTagsIsLoading } = useQuery(
    Queries.GetTagData, {
      variables: {
        tag_category: 'Seasonality',
        feature_type: 'Trail' // Hardcoded here for Outings since we are just using the same tags as Trails without duplicating all the data in the database for Outing
      },
      onCompleted: (data) => {
        const availableSeasons = data.tag_categories[0].tag_descriptors.map(tag => tag.key)
        let selectedSeasons = availableSeasons
        // If there are any tags passed in, find the Seasonality ones
        if (tags.length > 0) {
          selectedSeasons = tags.filter(tag => availableSeasons.includes(tag.key)).map(t => t.key)

          if (selectedSeasons.length === 0) {
            // No seasons were defined, so default to ALL available seasons
            selectedSeasons = availableSeasons
          }
        }
        setCheckedSeasons(selectedSeasons)
      },
      onError: () => {}
    }
  )

  const handleSeasonChange = (event, season) => {
    const checked = event.target.checked
    let _newCheckedSeasons = checkedSeasons

    if (checked) {
      _newCheckedSeasons = [..._newCheckedSeasons, season]
    } else {
      _newCheckedSeasons = _newCheckedSeasons.filter(i => i !== season)
    }

    setCheckedSeasons(_newCheckedSeasons)

    if (onSeasonChange) {
      onSeasonChange(_newCheckedSeasons)
    }
  }

  const isSeasonSelected = (season) => {
    if (tags.length === 0 && checkedSeasons.length === 0) {
      return true
    } else if (checkedSeasons.length > 0) {
      return (checkedSeasons.filter(cs => cs === season).length > 0)
    } else if (tags.filter(t => t.key === season).length > 0) {
      return true
    } else {
      return false
    }
  }

  // When there is only one season selected, don't allow it to be unchecked - effectively
  //  enforcing that the user has at least one season selected
  const isDisabled = (season) => {
    let disabled = false

    if (checkedSeasons.length === 1) {
      if (checkedSeasons.includes(season)) {
        disabled = true
      } else {
        disabled = false
      }
    }

    return disabled
  }

  if (getSeasonalityTagsIsLoading) {
    return <CenteredLoader />
  } else {
    return (
      <div className='form-group'>
        <label>
          <Toggle
            checked={isSeasonSelected('winter')}
            className='season-toggle'
            disabled={isDisabled('winter')}
            icons={false}
            onChange={(e) => handleSeasonChange(e, 'winter')}
          />
          <span className='featureInfo-toggleSpan'>Winter</span>
        </label>
        <label>
          <Toggle
            checked={isSeasonSelected('spring')}
            className='season-toggle'
            icons={false}
            onChange={(e) => handleSeasonChange(e, 'spring')}
            disabled={isDisabled('spring')}
          />
          <span className='featureInfo-toggleSpan'>Spring</span>
        </label>
        <label>
          <Toggle
            checked={isSeasonSelected('summer')}
            className='season-toggle'
            icons={false}
            onChange={(e) => handleSeasonChange(e, 'summer')}
            disabled={isDisabled('summer')}
          />
          <span className='featureInfo-toggleSpan'>Summer</span>
        </label>
        <label>
          <Toggle
            checked={isSeasonSelected('fall')}
            icons={false}
            className='season-toggle'
            onChange={(e) => handleSeasonChange(e, 'fall')}
            disabled={isDisabled('fall')}
          />
          <span className='featureInfo-toggleSpan'>Fall</span>
        </label>
      </div>
    )
  }
}

FeatureSeasons.propTypes = {
  onSeasonChange: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired
}

export default FeatureSeasons
