import Dropdown from 'react-bootstrap/lib/Dropdown'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

import { Mutations } from './VisibilityOperations'

import './visibilityDropdown.css'
import { useApolloClient } from '@apollo/client'
import debugMessage from 'services/Debug'

// NOTE: Currently only used in Outings, which is why the Mutation is hardcoded below to `UpdateVisibilityForOuting`
const VisibilityDropdown = (props) => {
  const client = useApolloClient()
  const { bsSize, entityId, entityType, initialValue, outingHasEmptyRouteGeometry = false } = props
  const [value, setValue] = useState(initialValue || 'Draft')
  let filteredOptions = []
  const visibilityOptions = [
    'Archive',
    'Publish'
  ]

  const onChange = (option) => {
    if (outingHasEmptyRouteGeometry && option === 'Publish') {
      window.alert('Outings cannot be published without a route. Please draw a route and then save before publishing.')
      toast.error('Outing was not published because there is no route.')
    } else if (window.confirm('Are you sure you want to update the visibility of this location?')) {
      const visibility = (() => {
        if (option === 'Archive') {
          return 'Archived'
        } else {
          return 'Published'
        }
      })()

      client.mutate({
        mutation: Mutations.UpdateVisibilityForOuting,
        variables: {
          outingId: entityId,
          visibility: visibility
        }
      }).then(
        () => {
          toast.success('Visibility updated successfully.')
          setValue(visibility)
        },
        (error) => {
          debugMessage(error)
          toast.error('The visibility could not be updated.')
        }
      )
    }
  }

  if (value) {
    filteredOptions = visibilityOptions.filter((option) => (option !== value) && (`${option}d` !== value) && (`${option}ed` !== value))
  }

  return (
    <Dropdown
      className='visibilityDropdown'
      disabled={(!value || value === 'Draft') && (!entityId)}
      id={`visibility-dropdown-${entityType}-${entityId}`}
      pullRight
    >
      <Dropdown.Toggle
        bsSize={bsSize}
        bsStyle='default'
      >
        <span
          className={`visibility-icon visibility-icon--${(value ? value.toLowerCase() : 'draft')}`}
        />
        {value || 'Draft'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filteredOptions.map((option) => {
          return (
            <MenuItem
              key={option}
              onClick={() => onChange(option)}
            >
              <span
                className={`visibility-icon visibility-icon--${option.toLowerCase()}`}
              />
              {option}
            </MenuItem>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

VisibilityDropdown.propTypes = {
  bsSize: PropTypes.string,
  entityId: PropTypes.number,
  entityType: PropTypes.string,
  initialValue: PropTypes.string,
  outingHasEmptyRouteGeometry: PropTypes.bool
}

export default VisibilityDropdown
