import React from 'react'
import PropTypes from 'prop-types'

const PageContent = ({ children }) => {
  return (
    <div className='pageLayout-content'>
      {children}
    </div>
  )
}

PageContent.propTypes = {
  children: PropTypes.any
}

export default PageContent
