import { LinkContainer } from 'react-router-bootstrap'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import Nav from 'react-bootstrap/lib/Nav'
import NavItem from 'react-bootstrap/lib/NavItem'
import PropTypes from 'prop-types'
import React from 'react'

import { askOuterSpatialAQuestion } from 'components/helpScout'
import { useCurrentUser } from 'contexts/CurrentUserContext'

import './mapsActionBar.css'

const tabs = [
  {
    label: 'Paper Maps',
    to: 'paper'
  }
]

const getTabs = (match) => {
  return tabs.map((tab) => {
    return {
      ...tab,
      to: match.path.split(':')[0] + tab.to
    }
  })
}

const ActionBar = ({ match, onUploadClick }) => {
  const handleNewPaperMapClick = () => {
    askOuterSpatialAQuestion(
      'Create a New Paper Map',
      'I would like to create a new paper map named: _____. I have attached a PDF or JPG of the map.',
      user
    )
  }
  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '73', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: 'https://organizations.outerspatial.help/article/73-paper-maps'
      }).click()
    }
  }
  const user = useCurrentUser()
  const isAdmin = user.roles.find((item) => item.role.name === 'admin')

  return (
    <div className='mapsActionBar-wrap'>
      <div className='mapsActionBar-tabs'>
        <Nav bsStyle='tabs'>
          {getTabs(match).map((tab) => {
            return (
              <LinkContainer to={tab.to} key={tab.to}>
                <NavItem>{tab.label}</NavItem>
              </LinkContainer>
            )
          })}
        </Nav>
      </div>
      <div className='mapsActionBar-actions'>
        {isAdmin && (
          <Button bsStyle='primary' className='shadow' onClick={onUploadClick}>
            <Glyphicon glyph='star' />{' '}
            Upload Paper Map
          </Button>
        )}
        {!isAdmin && (
          <Button bsStyle='primary' className='shadow' onClick={handleNewPaperMapClick} target='_self'>
            New Paper Map
          </Button>
        )}
        &nbsp;
        <Button bsStyle='primary' className='shadow' onClick={handleViewHelpClick}>
          View Help
        </Button>
      </div>
    </div>
  )
}

ActionBar.propTypes = {  
  match: PropTypes.object,  
  onUploadClick: PropTypes.func
}

export default ActionBar
