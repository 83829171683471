import { gql } from '@apollo/client'

export const Fragments = {
  ContentBundleFields: gql`
    fragment ContentBundleFields on content_bundles {
      banner_image {
        uploaded_file
      }
      banner_image_id
      id
      name
      text_contents {
        text
        id
      }
      visibility
    }
  `,
  ContentBundlesDetails: gql`
    fragment ContentBundlesDetails on content_bundles {
      created_at
      id
      name
      position
      visibility
    }
  `,
  AttachmentFields: gql`
    fragment AttachmentFields on attachments {
      attached_id
      attached_type
      id
      feature_id
      feature_type
    }
  `
}

export const Mutations = {
  InsertContentBundle: gql`
    mutation InsertContentBundle(
      $name: String!,
      $organizationId: Int!,
      $imageId: Int,
      $description: String
    ) {
      insert_content_bundles_one(object: {
        name: $name,
        feature_id: $organizationId,
        feature_type: "Organization",
        banner_image_id: $imageId,
        visibility: "Draft",
        text_contents: {data: {text: $description}}
      }) {
        ...ContentBundleFields
      }
    }
    ${Fragments.ContentBundleFields}
  `,
  InsertContentBundleAttachments: gql`
    mutation InsertContentBundleAttachment($newAttachments: [attachments_insert_input!]!) {
      insert_attachments(objects: $newAttachments) {
        affected_rows
        returning {
          ...AttachmentFields
        }
      }
    }
    ${Fragments.AttachmentFields}
  `,
  UpdateContentBundle: gql`
    mutation UpdateContentBundle($contentBundleId: Int!, $name: String!, $imageId: Int, $textContentsId: Int!, $description: String) {
      update_content_bundles(
        where: {id: {_eq: $contentBundleId}},
        _set: {
          banner_image_id: $imageId,
          name: $name
        }
      ) {
        affected_rows
      }
      update_text_contents(
        where: {id: {_eq: $textContentsId}},
        _set: {text: $description}
      ) {
        affected_rows
      }
    }
  `,
  DeleteContentBundle: gql`
    mutation DeleteContentBundle($contentBundleId: Int!) {
      delete_text_contents(where: {content_bundle_id: {_eq: $contentBundleId}}) {
        affected_rows
        returning {
          id
        }
      }
      delete_content_bundles(where: {id: {_eq: $contentBundleId}}) {
        affected_rows
        returning {
          id
        }
      }
      delete_attachments(where: {
        attached_type: {_eq: "ContentBundle"},
        attached_id: {_eq: $contentBundleId}
      }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  DeleteContentBundleAttachment: gql`
    mutation DeleteContentBundleAttachment ($attachmentId: Int!) {
      delete_attachments(where: {id: {_eq: $attachmentId}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  UpdateVisibilityForContentBundle: gql`
    mutation UpdateVisibilityForContentBundle(
      $contentBundleId: Int!,
      $visibility: String!
    ) {
      update_content_bundles(
        where: {id: {_eq: $contentBundleId}},
        _set: {
          visibility: $visibility
        }
      ) {
        affected_rows
        returning {
          id
          visibility
        }
      }
    }
  `
}

export const Queries = {
  GetContentBundleById: gql`
    query GetContentBundleById($contentBundleId: Int!, $organizationId: Int!) {
      content_bundles(where: {
        id: {_eq: $contentBundleId},
        organization: {id: {_eq: $organizationId}}
      }) {
        ...ContentBundleFields
      }
      area_content_bundle_attachments(where: {content_bundle_id: {_eq: $contentBundleId}}) {
        area {
          id
          name
        }
      }
      attachments(where: {attached_type: {_eq: "ContentBundle"}, attached_id: {_eq: $contentBundleId}}) {
        id
        feature_id
        feature_type
      }
      outing_content_bundle_attachments(where: {content_bundle: {id: {_eq: $contentBundleId}}}) {
        outing {
          id
          name
        }
      }
      point_of_interest_content_bundle_attachments(where: {content_bundle: {id: {_eq: $contentBundleId}}}) {
        id
        point_of_interest {
          area {
            name
          }
          id
          name
        }
      }
      trail_content_bundle_attachments(where: {content_bundle: {id: {_eq: $contentBundleId}}}) {
        id
        trail {
          area {
            name
          }
          id
          name
        }
      }
    }
    ${Fragments.ContentBundleFields}
  `,
  GetContentBundles: gql`
    query GetContentBundles($organizationId: Int!,) {
      content_bundles(
        where: {organization: {id: {_eq: $organizationId}}},
        order_by: {name: asc},
      ) {
        ...ContentBundlesDetails
      }
    }
    ${Fragments.ContentBundlesDetails}
  `
}
