import moment from 'moment'

const start = moment().add(1, 'hour').startOf('hour').toISOString()
const defaultDate = start.split('T')[0]
const defaultTime = start.split('T')[1]
const defaultEnd = moment().add(2, 'hours').startOf('hour').toISOString()

const event = {
  event: {
    model: {
      name: 'New Event',
      schedules: [{
        schedule: {
          rule: 'singular', // One of 'singular', 'daily', 'weekly', 'monthly'
          date: defaultDate, // start date YYYY-MM-DD
          time: defaultTime, // start time (end of ISO date-time)
          day: [], // description:Day of week. An array of weekday-names, i.e. ['monday', 'wednesday']
          day_of_week: {}, // description:Day of nth week. A hash of weekday-names, containing arrays with indices, i.e. {:monday => [1, -1]} ('every first and last monday in month')
          interval: 0, // Specifies the interval of the recurring rule, i.e. every two weeks
          until: defaultEnd, // Specifies the enddate of the schedule. Required for terminating events.
          count: 1 // Specifies the total number of occurrences. Required for terminating events.
        }
      }],
      location: '',
      cost: '',
      website: '',
      description: '',
      banner_image: { id: null, uploaded_file: null },
      time_zone: '',
      phone_number: ''
    },
    fetched: false,
    fetching: false,
    error: null,
    attachments: {
      collection: [],
      fetching: false,
      updating: false,
      fetched: false,
      error: null
    }
  },
  events: {
    collection: [],
    error: null,
    fetched: false,
    fetching: false,
    paging: {
      total_pages: 0,
      current_page: 1
    },
    query: {
      page: 1
    }
  }
}

export const attachments = {
  attachments: []
}

export default event
