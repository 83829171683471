import { gql } from '@apollo/client'

export const Fragments = {
  ChallengeDetails: gql`
    fragment ChallengeDetails on challenges {
      badge_image {
        id
        uploaded_file
      }
      description
      enable_location_validation
      end_date
      enrollments_count: enrollments_aggregate {
        aggregate {
          count
        }
      }
      id
      name
      onboarding
      requires_email_sharing
      start_date
      tasks {
        areas: area_challenge_tasks {
          area {
            id
            name
          }
        }
        badge_image {
          id
          uploaded_file
        }
        challenge_id
        completion_deep_link
        description
        feature_id
        feature_type
        id
        long_description
        notifications
        outings: outing_challenge_tasks {
          outing {
            id
            name
            parent_areas: outing_areas {
              area {
                id
                name
              }
            }
          }
        }
        points_of_interest: point_of_interest_challenge_tasks {
          point_of_interest {
            id
            name
            parent_area: area {
              id
              name
            }
            point_of_interest_type {
              name
            }
          }
        }
        subtitle
        task_type
        title        
        trails: trail_challenge_tasks {
          trail {
            id
            name
            parent_area: area {
              id
              name
            }
          }
        }
      }
      tasks_to_complete
      visibility
    }
  `
}

export const Mutations = {
  DeleteChallenge: gql`
    mutation DeleteChallenge($id: Int!) {
      delete_challenges(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  DeleteChallengeTask: gql`
    mutation DeleteChallengeTask($id: Int!) {
      delete_challenge_tasks_by_pk(id: $id) {        
        id
        created_at
      }
    }
  `,
  UpdateChallengeTask: gql`
    mutation UpdateChallengeTask($id: Int!, $long_description: String = "", $feature_id: Int, $feature_type: String, $badge_image_id: Int) {
      update_challenge_tasks_by_pk(pk_columns: {id: $id}, _set: {long_description: $long_description, feature_id: $feature_id, feature_type: $feature_type, badge_image_id: $badge_image_id}) {
        badge_image_id
        feature_id
        feature_type
        id
        long_description
      }
    }
  `,
  DeleteTrailChallengeTask: gql`
    mutation DeleteTrailChallengeTask($task_id: Int!) {
      delete_trail_challenge_tasks(where: {challenge_task_id: {_eq: $task_id}}) {
        affected_rows
        returning {
          challenge_task_id
        }
      }
    }
  `,
  DeleteAreaChallengeTask: gql`
    mutation DeleteAreaChallengeTask($task_id: Int!) {
      delete_area_challenge_tasks(where: {challenge_task_id: {_eq: $task_id}}) {
        affected_rows
        returning {
          challenge_task_id
        }
      }
    }
  `,
  DeletePOIChallengeTask: gql`
    mutation DeletePOIChallengeTask($task_id: Int!) {
      delete_point_of_interest_challenge_tasks(where: {challenge_task_id: {_eq: $task_id}}) {  
        affected_rows
        returning {
          challenge_task_id
        }
      }
    }
  `,
  DeleteOutingChallengeTask: gql`
    mutation DeleteOutingChallengeTask($task_id: Int!) {
      delete_outing_challenge_tasks(where: {challenge_task_id: {_eq: $task_id}}) {
        affected_rows
        returning {
          challenge_task_id
        }
      }
    }
  `,
  InsertAreaChallengeTaskOnly: gql`
    mutation InsertAreaChallengeTaskOnly($task_id: Int!, $area_id: Int! ) {
      insert_area_challenge_tasks_one(object: {
        challenge_task_id: $task_id,
        area_id: $area_id}) {
          id
          challenge_task_id
          area_id
      }
    }
  `,
  InsertTrailChallengeTaskOnly: gql`
    mutation InsertTrailChallengeTaskOnly($task_id: Int!, $trail_id: Int! ) {
      insert_trail_challenge_tasks_one(object: {
        challenge_task_id: $task_id,
        trail_id: $trail_id}) {
          id
          challenge_task_id
          trail_id
      }
    }
  `,
  InsertPOIChallengeTaskOnly: gql`
    mutation InsertPOIChallengeTaskOnly($task_id: Int!, $poi_id: Int! ) {
      insert_point_of_interest_challenge_tasks_one(object: {
        challenge_task_id: $task_id,
        point_of_interest_id: $poi_id}) {
          id
          challenge_task_id
          point_of_interest_id
      }
    }
  `,
  InsertOutingChallengeTaskOnly: gql`
    mutation InsertOutingChallengeTaskOnly($task_id: Int!, $outing_id: Int! ) {
      insert_outing_challenge_tasks_one(object: {
        challenge_task_id: $task_id,
        outing_id: $outing_id}) {
          id
          challenge_task_id
          outing_id
      }
    }
  `,




  InsertAreaChallengeTask: gql`
    mutation InsertAreaChallengeTask($challenge_id: Int!, $long_description: String = "", $feature_id: Int!, $feature_type: String!, $task_type: String = "Check-in", $area_id: Int!, $badge_image_id: Int) {
      insert_challenge_tasks_one(object: {
        challenge_id: $challenge_id, 
        long_description: $long_description, 
        feature_id: $feature_id, 
        feature_type: $feature_type,
        task_type: $task_type,
        is_entry_point: true,
        badge_image_id: $badge_image_id,
        area_challenge_tasks: {data: {area_id: $area_id}}
      }) {
        id
        created_at
        long_description
        challenge_id
        feature_type
        feature_id
        task_type
        is_entry_point
        badge_image_id
      }
    }    
  `,
  InsertTrailChallengeTask: gql`
    mutation InsertAreaChallengeTask($challenge_id: Int!, $long_description: String = "", $feature_id: Int!, $feature_type: String!, $task_type: String = "Check-in", $trail_id: Int!, $badge_image_id: Int ) {
      insert_challenge_tasks_one(object: {
        challenge_id: $challenge_id, 
        long_description: $long_description, 
        feature_id: $feature_id, 
        feature_type: $feature_type,
        task_type: $task_type,
        is_entry_point: true,
        badge_image_id: $badge_image_id,
        trail_challenge_tasks: {data: {trail_id: $trail_id}}
      }) {
        id
        created_at
        long_description
        challenge_id
        feature_type
        feature_id
        task_type
        is_entry_point
        badge_image_id
      }
    }    
  `,
  InsertPOIChallengeTask: gql`
    mutation InsertPOIChallengeTask($challenge_id: Int!, $long_description: String = "", $feature_id: Int!, $feature_type: String!, $task_type: String = "Check-in", $point_of_interest_id: Int!, $badge_image_id: Int ) {
      insert_challenge_tasks_one(object: {
        challenge_id: $challenge_id, 
        long_description: $long_description, 
        feature_id: $feature_id, 
        feature_type: $feature_type,
        task_type: $task_type,
        is_entry_point: true,
        badge_image_id: $badge_image_id,
        point_of_interest_challenge_tasks: {data: {point_of_interest_id: $point_of_interest_id}}
      }) {
        id
        created_at
        long_description
        challenge_id
        feature_type
        feature_id
        task_type
        is_entry_point
        badge_image_id
      }
    }
  `,
  InsertOutingChallengeTask: gql`
    mutation InsertOutingChallengeTask($challenge_id: Int!, $long_description: String = "", $feature_id: Int!, $feature_type: String!, $task_type: String = "Check-in", $outing_id: Int!, $badge_image_id: Int ) {
      insert_challenge_tasks_one(object: {
        challenge_id: $challenge_id, 
        long_description: $long_description, 
        feature_id: $feature_id, 
        feature_type: $feature_type,
        task_type: $task_type,
        is_entry_point: true,
        badge_image_id: $badge_image_id,
        outing_challenge_tasks: {data: {outing_id: $outing_id}}
      }) {
        id
        created_at
        long_description
        challenge_id
        feature_type
        feature_id
        task_type
        is_entry_point
        badge_image_id
      }
    }    
  `,
  InsertChallenge: gql`
    mutation InsertChallenge(
      $badgeImageId: Int,
      $description: String!,
      $enableLocationValidation: Boolean,
      $endDate: timestamp,
      $name: String!,
      $onboarding: jsonb!,
      $organizationId: Int!,
      $requiresEmailSharing: Boolean,
      $startDate: timestamp,
      $visibility: String = "public"
    ) {
      insert_challenges(objects: {
        badge_image_id: $badgeImageId,
        description: $description,
        enable_location_validation: $enableLocationValidation,
        end_date: $endDate,
        name: $name,
        onboarding: $onboarding,
        organization_id: $organizationId,
        requires_email_sharing: $requiresEmailSharing,
        start_date: $startDate,
        tasks_to_complete: "all",
        visibility: $visibility
      }) {
        affected_rows
        returning {
          ...ChallengeDetails
        }
      }
    }
    ${Fragments.ChallengeDetails}
  `,
  UpdateChallenge: gql`
    mutation UpdateChallenge(
      $badgeImageId: Int,
      $description: String!,
      $endDate: timestamp,
      $id: Int!,
      $name: String!,
      $onboarding: jsonb!,
      $startDate: timestamp,
      $visibility: String
    ) {
      update_challenges(
        where: {id: {_eq: $id}},
        _set: {
          badge_image_id: $badgeImageId,
          description: $description,
          end_date: $endDate,
          name: $name,
          onboarding: $onboarding,
          start_date: $startDate,
          visibility: $visibility
        }
      ) {
        affected_rows
        returning {
          ...ChallengeDetails
        }
      }
    }
    ${Fragments.ChallengeDetails}
  `,
  UpdateChallengeTasksToComplete: gql`
    mutation UpdateChallengeTasksToComplete(
      $id: Int!,
      $tasksToComplete: String!
    ) {
      update_challenges(
        where: {id: {_eq: $id}},
        _set: {
          tasks_to_complete: $tasksToComplete
        }
      ) {
        affected_rows
      }
    }
  `
}

export const Queries = {
  GetChallengeById: gql`
    query GetChallengeById($id: Int!) {
      challenges(
        where: {
          id: {
            _eq: $id
          }
        }
      ) {
        ...ChallengeDetails
      }
    }
    ${Fragments.ChallengeDetails}
  `,
  GetChallengesByOrganizationId: gql`
    query GetChallengesByOrganizationId($organizationId: Int!) {
      challenges(
        order_by: {
          end_date: asc_nulls_first
        },
        where: {
          organization_id: {
            _eq: $organizationId
          }
        }
      ) {
        ...ChallengeDetails
      }
    }
    ${Fragments.ChallengeDetails}
  `
}
