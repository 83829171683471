import React from 'react'
import t from 'prop-types'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import { buildImageUploadUrl } from 'services/Images'
import './mediaChooser.css'
import addImageWhite from 'images/add-image-white.svg'

const MediaChooserItem = (props) => {
  const { onItemClick, onItemRemove, position, selected } = props
  const caption = position === 1 ? 'Cover Image' : `Image ${position}`
  let src = null
  if (selected) {
    const image = selected.image

    if (image?.id) {
      if (image.uploaded_file) {
        src = buildImageUploadUrl(image.id, image.uploaded_file, 'small_square')
      }
    }
  }

  return (
    <div className='mediaChooser-item' onClick={() => { onItemClick() }}>
      <div
        className='mediaChooser-itemImage'
        style={src ? { backgroundImage: `url('${src}')` } : null}
      >
        <img
          alt='Click to Add'
          src={addImageWhite}
          style={src ? { display: 'none' } : { display: 'block', height: '50px', width: '50px', marginTop: '35px' }}
        />
        <Button
          bsSize='xsmall'
          bsStyle='link'
          className='mediaChooser-itemRemove'
          onClick={(e) => { e.stopPropagation(); onItemRemove(position) }}
          style={!src ? { display: 'none' } : null}
        >
          <Glyphicon glyph='remove' />
        </Button>
      </div>
      <div className='mediaChooser-itemCaption'>
        {caption}
      </div>
    </div>
  )
}

MediaChooserItem.propTypes = {
  onItemClick: t.func,
  onItemRemove: t.func,
  position: t.number,
  selected: t.object
}

export default MediaChooserItem
