import PropTypes from 'prop-types'
import React from 'react'
import { useHistory, useRouteMatch, withRouter } from 'react-router'
import { Redirect, Route } from 'react-router-dom'

import { Configure } from './imports/Configure'
import { ImportForm } from './imports/ImportForm'
import { Logs } from './imports/Logs'
import { PageLayout, PageHeader, PageContent } from '../../components/pageLayout'
import { Review } from './imports/Review'
import { useCurrentOrganization } from '../../contexts/OrganizationContext'
import { useCurrentUser } from '../../contexts/CurrentUserContext'
import Analytics from './analytics/Analytics'
import Campaign from './campaigns/campaign/Campaign'
import Campaigns from './campaigns/campaigns/Campaigns'
import ErrorPermissions from '../../views/errors/permissions'
import Imports from './imports/Imports'
import Exports from './exports/Exports'
import Membership from './memberships/membership/Membership'
import Memberships from './memberships/memberships/Memberships'
import OrganizationBilling from './billing/OrganizationBilling'
import OrganizationHeader from './OrganizationHeader'
import OrganizationProfile from './profile/OrganizationProfile'

import './organization.css'

const OrganizationLayout = ({ children, isImportAdmin, isOrganizationAdmin, isSuperAdmin, match }) => {
  return (
    <PageLayout>
      <PageHeader>
        <OrganizationHeader isImportAdmin={isImportAdmin} isOrganizationAdmin={isOrganizationAdmin} isSuperAdmin={isSuperAdmin} match={match} />
      </PageHeader>
      <PageContent>{children}</PageContent>
    </PageLayout>
  )
}

OrganizationLayout.propTypes = {
  children: PropTypes.object,
  isImportAdmin: PropTypes.bool,
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  match: PropTypes.object
}

const Organization = () => {
  const currentUser = useCurrentUser()
  const history = useHistory()
  let isOrganizationAdmin = false
  const match = useRouteMatch()
  const organization = useCurrentOrganization()

  organization.memberships.forEach((membership) => {
    if (membership.user_email.email === currentUser.email && membership.role === 'admin') {
      isOrganizationAdmin = true
    }
  })

  const isSuperAdmin = currentUser.roles
    ? currentUser.roles.filter((role) => role.role.name === 'admin').length > 0
    : false
  // TODO: Hardcoding GeoCompas user id for now, should migrate to LaunchDarkly, once implemented.
  const isImportAdmin = currentUser.id === 56454 ? true : isSuperAdmin
  const isOrganizationStaff = !isOrganizationAdmin && !isSuperAdmin
  const organizationLayoutProps = {
    history,
    isImportAdmin,
    isOrganizationAdmin,
    isOrganizationStaff,
    isSuperAdmin,
    match
  }

  return (
    <div className='organization-wrap'>
      <Route exact path={match.url} render={() => <Redirect to={`${match.url}/profile`} />} />
      <Route
        path={`${match.url}/profile`}
        render={() => (
          <OrganizationLayout {...organizationLayoutProps}>
            <OrganizationProfile {...organizationLayoutProps} />
          </OrganizationLayout>
        )}
      />
      <Route
        exact
        path={`${match.url}/team`}
        render={() => (
          <OrganizationLayout {...organizationLayoutProps}>
            <Memberships isOrganizationAdmin={isOrganizationAdmin} isSuperAdmin={isSuperAdmin} />
          </OrganizationLayout>
        )}
      />
      <Route
        exact
        path={`${match.url}/campaigns`}
        render={() => (
          <OrganizationLayout {...organizationLayoutProps}>
            <Campaigns
              history={history}
              isOrganizationAdmin={isOrganizationAdmin}
              isSuperAdmin={isSuperAdmin}
              match={match}
            />
          </OrganizationLayout>
        )}
      />
      {!isImportAdmin && <Route path={`${match.url}/imports`} render={() => <ErrorPermissions />} />}
      {isImportAdmin && (
        <Route
          exact
          path={`${match.url}/imports`}
          render={() => (
            <OrganizationLayout {...organizationLayoutProps}>
              <Imports
                history={history}
                isOrganizationAdmin={isOrganizationAdmin}
                isSuperAdmin={isSuperAdmin}
                match={match}
              />
            </OrganizationLayout>
          )}
        />
      )}
      <Route
        exact
        path={`${match.url}/exports`}
        render={() => (
          <OrganizationLayout {...organizationLayoutProps}>
            <Exports
              history={history}
              match={match}
            />
          </OrganizationLayout>
        )}
      />
      {!isImportAdmin && <Route path={`${match.url}/imports/new`} render={() => <ErrorPermissions />} />}
      {isImportAdmin && (
        <Route
          exact
          path={`${match.url}/imports/new`}
          render={() => (
            <OrganizationLayout {...organizationLayoutProps}>
              <ImportForm match={match} />
            </OrganizationLayout>
          )}
        />
      )}
      {!isImportAdmin && <Route path={`${match.url}/imports/:importId/configure`} render={() => <ErrorPermissions />} />}
      {isImportAdmin && <Route exact path={`${match.url}/imports/:importId/configure`} render={() => <Configure />} />}
      {!isImportAdmin && <Route path={`${match.url}/imports/:importId/logs`} render={() => <ErrorPermissions />} />}
      {isImportAdmin && <Route exact path={`${match.url}/imports/:importId/logs`} render={() => <Logs />} />}
      {!isImportAdmin && <Route exact path={`${match.url}/imports/:importId/review`} render={() => <ErrorPermissions />} />}
      {isImportAdmin && <Route exact path={`${match.url}/imports/:importId/review`} render={() => <Review />} />}
      <Route
        exact
        path={`${match.url}/visitor-analytics`}
        render={() => (
          <OrganizationLayout {...organizationLayoutProps}>
            <Analytics
              history={history}
              isOrganizationAdmin={isOrganizationAdmin}
              isSuperAdmin={isSuperAdmin}
              match={match}
            />
          </OrganizationLayout>
        )}
      />
      <Route path={`${match.url}/campaigns/:campaignId`} render={() => <Campaign />} />
      {isOrganizationStaff && <Route path={`${match.url}/team/:membershipId`} render={() => <ErrorPermissions />} />}
      {!isOrganizationStaff && (
        <Route path={`${match.url}/team/:membershipId`} render={() => <Membership isSuperAdmin={isSuperAdmin} />} />
      )}
      {isOrganizationStaff && <Route path={`${match.url}/billing`} render={() => <ErrorPermissions />} />}
      {!isOrganizationStaff && (
        <Route
          path={`${match.url}/billing`}
          render={() => (
            <OrganizationLayout {...organizationLayoutProps}>
              <OrganizationBilling />
            </OrganizationLayout>
          )}
        />
      )}
    </div>
  )
}

export default withRouter(Organization)
