import React from 'react'
import Nav from 'react-bootstrap/lib/Nav'
import NavItem from 'react-bootstrap/lib/NavItem'
import moment from 'moment'
import { ManagerVersion } from '../../../ManagerVersion'

import './footer.css'

const Footer = () => {
  const year = moment().format('YYYY')

  return (
    <footer className='footer'>
      <Nav>
        <NavItem href='https://www.outerspatial.com/terms' target='_self'>Terms of Service</NavItem>
        <NavItem href='https://www.outerspatial.com/privacy' target='_self'>Privacy Policy</NavItem>
      </Nav>
      <p className='copy'>
        v{ManagerVersion} - Copyright &copy; {year} Trailhead Labs
      </p>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
