import { LinkContainer } from 'react-router-bootstrap'
import Button from 'react-bootstrap/lib/Button'
import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import PropTypes from 'prop-types'
import React from 'react'

const ChallengeHeader = ({
  challenge = {},
  match,
  onDeleteClick,
  onSaveClick
}) => {
  const backPath = match.path.split(':')[0].slice(0, match.path.split(':')[0].length)
  const isNew = match.params.challengeId === 'new'

  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '62', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/62-creating-challenges' }).click()
    }
  }

  return (
    <div className='challenge-header'>
      <div className='challenge-header__title'>
        <div className='challenge-header__titleText'>
          <LinkContainer to={backPath}>
            <Button bsStyle='link'>
              <Glyphicon glyph='chevron-left' />Back to Challenges
            </Button>
          </LinkContainer>
          <h1>{isNew ? 'New Challenge' : challenge.name}</h1>
        </div>
        <div className='challenge-header__titleActions'>
          {!isNew && (
            <Button
              bsStyle='danger'
              onClick={() => { onDeleteClick(challenge, true) }}
            >
              Delete
            </Button>
          )}
          <Button
            bsStyle='primary'
            onClick={handleViewHelpClick}
          >
            View Help
          </Button>
          <Button
            bsStyle='primary'
            onClick={onSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

ChallengeHeader.propTypes = {
  challenge: PropTypes.object,
  match: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func,
  onSaveClick: PropTypes.func
}

export default ChallengeHeader
