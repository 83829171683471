import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import React from 'react'

import { Area, PointOfInterest, Trail } from './feature'
import FeaturesListWrapper from './list/FeaturesListWrapper'
import LocationsMap from './map/LocationsMap'
import Outing from '../outings/outing/Outing'
import OutingsList from '../outings/outingsList/OutingsList'

const Features = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()

  const handleOpenFeature = (feature) => {
    const type = (feature.type === 'points_of_interest') ? 'points-of-interest' : feature.type

    history.push(`${match.url}/${type}/${feature.id}`)
  }

  const handleOpenOuting = (outing) => {
    history.push(`${match.url}/outings/${outing.id}`)
  }

  return (
    <Switch>
      <Route
        exact
        path={match.url}
        render={({ history, match }) =>
          <LocationsMap
            history={history}
            match={match}
            organization={organization}
          />}
      />
      <Route
        path={`${match.url}/areas/:area_id`}
        render={({ history, match }) =>
          <Area
            history={history}
            match={match}
            organization={organization}
          />}
      />
      <Route
        path={`${match.url}/outings/:outing_id`}
        render={(props) => (<Outing />)}
      />
      <Route
        path={`${match.url}/outings`}
        render={() => (<OutingsList onSelectSearchResult={handleOpenOuting} />)}
      />
      <Route
        path={`${match.url}/points-of-interest/:point_of_interest_id`}
        render={({ history, match }) =>
          <PointOfInterest
            history={history}
            match={match}
            organization={organization}
          />}
      />
      <Route
        path={`${match.url}/trails/:trail_id`}
        render={({ history, match }) =>
          <Trail
            history={history}
            match={match}
            organization={organization}
          />}
      />

      <Route
        path={`${match.url}/:feature_type`}
        render={({ history, match }) =>
          <FeaturesListWrapper
            history={history}
            match={match}
            onSelectSearchResult={handleOpenFeature}
            organizationId={organization.id}
          />}
      />
    </Switch>
  )
}

export default Features
