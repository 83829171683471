import { React } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { Organization } from '.'
import { CenteredLoader } from '../../components/centeredLoader'
import { OrganizationContext } from '../../contexts/OrganizationContext'
import Queries from '../app/AppQueries'
import SidebarLayout from '../app/SidebarLayout'
import { Challenges } from '../challenges'
import { ContentBundles } from '../contentBundles'
import Dashboard from '../dashboard/Dashboard'
import Documents from '../documents/Documents'
import Error404 from '../errors/404'
import { Events } from '../events'
import Features from '../features/Features'
import HeroItem from '../heroItems/heroItem/HeroItem'
import HeroItemsList from '../heroItems/list/HeroItemsList'
import { Maps } from '../maps'
import { Media } from '../media'
import SocialPosts from '../social/SocialPosts'
import { User } from '../user'

function OrganizationRoutes ({ currentUser, setCurrentUser }) {
  let organization = null
  const match = useRouteMatch()
  const currentOrgId = parseInt(match.params.organizationId, 10)

  if (isNaN(currentOrgId)) { return (<Redirect to='/' />) }

  const inCurrentOrg = currentUser.memberships.find(item => item.organization.id === currentOrgId)
  const isAdmin = currentUser.roles.find(item => item.role.name === 'admin')

  // check that the currentUser has membership to this or is admin
  if (!inCurrentOrg && !isAdmin) {
    return (<Redirect to='/' />)
  }

  const { data, loading } = useQuery(Queries.GetOrganizationById, {
    fetchPolicy: 'no-cache',
    variables: {
      orgId: currentOrgId
    }
  })

  if (loading) {
    return <CenteredLoader overlay />
  }

  if (data) {
    organization = data.organizations[0]

    if (organization) {
      if (window.Beacon) {
        const beaconInfo = window.Beacon('info')
        let toBeaconId = '8bdf21e5-c4fc-43d1-8bd3-e17a0cf162f0'

        if (beaconInfo) {
          const currentBeaconId = beaconInfo.beaconId

          if (organization.license_tier === 'free') {
            toBeaconId = '4856f1ef-51e1-411c-a61b-ae8987bf2402'
          }

          if (currentBeaconId !== toBeaconId) {
            window.Beacon('destroy')
            window.Beacon('init', toBeaconId)
          }
        } else {
          window.Beacon('init', toBeaconId)
        }
      }
    } else {
      return (<Redirect to='/' />)
    }
  }

  return (
    <div>
      <OrganizationContext.Provider value={organization}>
        <SidebarLayout
          match={match}
        >
          <Switch>
            <Route exact path={`${match.url}`} component={Dashboard} />
            <Route path={`${match.url}/dashboard`} component={Dashboard} />
            <Route path={`${match.url}/organization`} component={Organization} />
            <Route path={`${match.url}/bulletin-board`} exact component={HeroItemsList} />
            <Route path={`${match.url}/bulletin-board/:heroItemId`} component={HeroItem} />
            <Route path={`${match.url}/challenges`} component={Challenges} />
            <Route path={`${match.url}/social`} component={SocialPosts} />
            <Route path={`${match.url}/locations`} component={Features} />
            <Route path={`${match.url}/articles`} component={ContentBundles} />
            <Route path={`${match.url}/events`} component={Events} />
            <Route path={`${match.url}/images`} component={Media} />
            <Route path={`${match.url}/documents`} component={Documents} />
            <Route path={`${match.url}/maps`} component={Maps} />
            <Route path={`${match.url}/settings`}>
              <User currentUser={currentUser} setCurrentUser={setCurrentUser} />
            </Route>
            <Route path='*' component={Error404} />
          </Switch>
        </SidebarLayout>
      </OrganizationContext.Provider>
    </div>
  )
}

OrganizationRoutes.propTypes = {
  currentUser: PropTypes.object,
  setCurrentUser: PropTypes.func
}

export default OrganizationRoutes
