import PropTypes from 'prop-types'
import React from 'react'

const ContentSidebar = ({ children }) => {
  return (
    <div className='contentLayout-sidebar'>
      {children}
    </div>
  )
}

ContentSidebar.propTypes = {
  children: PropTypes.any
}

export default ContentSidebar
