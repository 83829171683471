import { useApolloClient, useQuery } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { useHistory, useRouteMatch } from 'react-router'
import Alert from 'react-bootstrap/lib/Alert'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import { CenteredLoader } from '../../../../components/centeredLoader'
import { PageContent, PageHeader, PageLayout } from '../../../../components/pageLayout'
import ErrorLicense from '../../../../views/errors/license'
import MembershipForm from './MembershipForm'
import MembershipHeader from './MembershipHeader'
import { Queries } from '../MembershipsOperations'

import './membership.css'

const Membership = ({ isSuperAdmin }) => {
  const client = useApolloClient()
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const formRef = useRef()
  const [hasExceededMemberLimit, setHasExceededMemberLimit] = useState(false)

  const { loading: loadingOrganization, error: errorLoadingOrganization } = useQuery(Queries.GetOrganizationById, {
    variables: { id: organization.id },
    onCompleted: (data) => {
      const memberships = data.organizations?.[0]?.memberships
      const membershipLimit = data.organizations?.[0]?.membership_limit ?? 0
      let memberCount = 0

      memberships.forEach((membership) => {
        if (membership.user_email.email.indexOf('outerspatial.com') === -1) {
          memberCount++
        }
      })

      setHasExceededMemberLimit(memberCount >= membershipLimit)
    }
  })

  const handleSave = () => {
    // Ensure that the Formik form is hooked up
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '68', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/68-managing-your-organization' }).click()
    }
  }

  return (
    <div className='membership-wrap'>
      {loadingOrganization &&
        <CenteredLoader />}
      {!loadingOrganization && hasExceededMemberLimit && match.params.membershipId === 'new' && !isSuperAdmin && (
        <PageLayout>
          <PageContent>
            <ErrorLicense />
          </PageContent>
        </PageLayout>)}
      {(!loadingOrganization) && ((!hasExceededMemberLimit || isSuperAdmin) || match.params.membershipId !== 'new') && (
        <div>
          {!!errorLoadingOrganization && errorLoadingOrganization.message && <Alert bsStyle='danger'>{errorLoadingOrganization.message}</Alert>}
          <PageLayout>
            <PageHeader>
              <MembershipHeader
                history={history}
                match={match}
                onHandleViewHelpClick={handleViewHelpClick}
                onSaveClick={handleSave}
              />
            </PageHeader>
            <PageContent>
              <Panel>
                <Panel.Body>
                  <MembershipForm
                    client={client}
                    formRef={formRef}
                    history={history}
                    match={match}
                    organization={organization}
                  />
                </Panel.Body>
              </Panel>
            </PageContent>
          </PageLayout>
        </div>)}
    </div>
  )
}

Membership.propTypes = {
  isSuperAdmin: PropTypes.bool
}

export default Membership
