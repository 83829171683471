import { gql, useQuery } from '@apollo/client'
import { useCurrentOrganization } from 'contexts/OrganizationContext'
import React, { useEffect } from 'react'
import Button from 'react-bootstrap/lib/Button'
import { useHistory, useRouteMatch } from 'react-router'
import { CenteredLoader } from '../../../components/centeredLoader'
import { PageContent, PageHeader, PageLayout } from '../../../components/pageLayout'
import EventsCalendar from './calendar/EventsCalendar'
import './events.css'

const EventsListQuery = gql`
  query EventsList($id: Int!) {
    organization: organizations_by_pk(id: $id) {
      events {
        id
        name
        schedules {
          id
          schedule {
            date
            id
            until
            time
          }
        }
      }
    }
  }    
`

const EventsList = (props) => {
  const history = useHistory()
  const match = useRouteMatch()
  const organization = useCurrentOrganization()
  const { data, loading } = useQuery(EventsListQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id: organization.id
    }
  })

  useEffect(() => {
    document.title = `Events | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  const handleViewHelpClick = () => {
    if (window.Beacon) {
      window.Beacon('article', '75', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/75-events' }).click()
    }
  }

  const newEvent = () => {
    history.push(`${match.url}/new`)
  }

  const selectSlot = (slotInfo) => {
    history.push(`${match.url}/new`, { slotInfo })
  }

  const showEvent = (event) => {
    history.push(`${match.url}/${event.event.id}`)
  }

  if (loading) {
    return (
      <CenteredLoader />
    )
  }

  return (
    <div className='events-wrap'>
      <PageLayout>
        <PageHeader>
          <div className='events-header'>
            <div className='events-title'>
              <h1>Events</h1>
            </div>
            <div className='events-actions'>
              <Button bsStyle='primary' className='eventsCalendar-add shadow' onClick={newEvent} block>New Event</Button>
                &nbsp;
              <Button
                bsStyle='primary'
                className='shadow'
                onClick={handleViewHelpClick}
              >
                View Help
              </Button>
            </div>
          </div>
        </PageHeader>
        <PageContent>
          <div className='events-content'>
            <EventsCalendar
              events={data.organization.events}
              onSelectEvent={showEvent}
              onSelectSlot={selectSlot}
            />
          </div>
        </PageContent>
      </PageLayout>
    </div>
  )
}

export default EventsList
