import FeaturesList from './FeaturesList'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { CenteredLoader } from '../../../components/centeredLoader'
import { Queries } from '../FeaturesOperations'
import debugMessage from 'services/Debug'

const getAggregateQuery = (featureType) => {
  const graphQL = {
    name: null,
    query: null
  }

  switch (featureType) {
    case 'areas':
      graphQL.query = Queries.GetNumberOfAreasByOrganization
      graphQL.name = 'getNumberOfAreasByOrganization'
      break
    case 'trails':
      graphQL.query = Queries.GetNumberOfTrailsByOrganization
      graphQL.name = 'getNumberOfTrailsByOrganization'
      break
    case 'points-of-interest':
      graphQL.query = Queries.GetNumberOfPointsOfInterestByOrganization
      graphQL.name = 'getNumberOfPointsOfInterestByOrganization'
      break
    default:
      graphQL.query = null
      graphQL.name = null
      console.log('Invalid Feature Type specified.')
  }

  return graphQL
}

const FeaturesListWrapper = ({ history, match, onSelectSearchResult, organizationId }) => {
  const featureType = match.params.feature_type
  const location = useLocation()
  const graphQL = getAggregateQuery(featureType)

  const numberOfFeaturesPerPage = 25
  const [paging, setPaging] = useState({
    number_of_features: null,
    loading: true,
    current_page: 1,
    per_page: numberOfFeaturesPerPage,
    total_pages: null
  })
  const search = queryString.parse(location.search)

  const handlePageChange = (nextPage) => {
    setPaging({ ...paging, current_page: nextPage })
    history.replace(`${match.url}?page=${nextPage}`)
  }

  const { error, loading: loadingNumberOfFeatures } = useQuery(graphQL.query, {
    displayName: graphQL.name,
    onCompleted (data) {
      const numberOfFeatures = data.aggregate.aggregate.totalCount
      const totalPages = Math.ceil(numberOfFeatures / numberOfFeaturesPerPage)

      setPaging({ ...paging, number_of_features: numberOfFeatures, total_pages: totalPages })
    },
    onError () {
      debugMessage(error)
    },
    skip: !organizationId,
    variables: {
      organizationId: organizationId
    }
  })

  useEffect(() => {
    if (!loadingNumberOfFeatures && paging.number_of_features && paging.loading) {
      if (search?.page) {
        let currentPage = parseInt(search.page, 10)

        if (currentPage > paging.total_pages) {
          currentPage = 1
          history.replace(`${match.url}?page=1`)
        }

        setPaging({ ...paging, current_page: currentPage, loading: false })
      } else { // Page not set in the query string
        setPaging({ ...paging, current_page: 1, loading: false })
        if (Object.keys(search).length > 0) {
          // Append default page to query string and keep other search values
          const queries = queryString.stringify(Object.assign(search, { page: 1 }))
          history.replace(`${match.url}?${queries}`)
        } else {
          history.replace(`${match.url}?page=1`)
        }
      }
    }
  }, [loadingNumberOfFeatures, paging.number_of_features, paging.loading, paging.total_pages, search, history, match.url])

  return (
    <div>
      {loadingNumberOfFeatures &&
        <CenteredLoader />}
      {!loadingNumberOfFeatures &&
        <FeaturesList
          key={match.params.feature_type}
          {...{
            handlePageChange,
            history,
            match,
            numberOfFeatures: paging.number_of_features,
            onSelectSearchResult,
            organizationId,
            paging
          }}
        />}
    </div>
  )
}

FeaturesListWrapper.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  onSelectSearchResult: PropTypes.func,
  organizationId: PropTypes.number
}

export default FeaturesListWrapper
