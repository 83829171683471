import Glyphicon from 'react-bootstrap/lib/Glyphicon'
import Label from 'react-bootstrap/lib/Label'
import Panel from 'react-bootstrap/lib/Panel'
import PanelGroup from 'react-bootstrap/lib/PanelGroup'
import PropTypes from 'prop-types'
import React from 'react'
import Table from 'react-bootstrap/lib/Table'

const ToDo = (props) => {
  const { currentUser, handleStartWalkthroughClick, history, match, organization } = props
  const membership = currentUser.memberships.find(innerMembership => innerMembership.organization.id === organization.id)
  const role = membership && membership.role ? membership.role : 'staff'
  const toDoTasks = {
    'Getting Started': [{
      action: () => {
        history.push(`${match.path}/settings`)
      },
      description: 'Add a profile photo to personalize your OuterSpatial profile.',
      name: 'Add a Profile Photo',
      roleRequired: 'Staff',
      shouldShow: () => {
        return currentUser.profile.photo_file === null
      }
    }, {
      action: handleStartWalkthroughClick,
      description: 'Walk through a quick tour of Manager to familiarize yourself with the interface.',
      name: 'Walk Through the Manager Tour',
      roleRequired: 'Staff'
    }],
    Organization: [{
      action: () => {
        history.push(`${match.path}/organization/profile`)
      },
      description: 'Update your Organization\'s name, description, default time zone, contact information, and social media accounts.',
      name: 'Update Your Organization Profile',
      roleRequired: 'Admin',
      shouldShow: () => {
        return role === 'admin'
      }
    }, {
      action: () => {
        history.push(`${match.path}/organization/team`)
      },
      description: 'Invite others to join your Organization in Manager so they can contribute.',
      name: 'Invite Team Members',
      roleRequired: 'Admin',
      shouldShow: () => {
        return role === 'admin'
      }
    }],
    Locations: [{
      action: () => {
        history.push(`${match.path}/outings/new`)
      },
      description: 'Create your first Outing to highlight a walking tour, hike, or any type of on-the-ground experience for your Visitors.',
      name: 'Create Your First Outing',
      roleRequired: 'Staff'
    }, {
      action: () => {
        history.push(`${match.path}/locations`)
      },
      description: 'Update the open/closed status and add descriptions, tags, and photos to all your Locations — prioritizing the most heavily-used Locations first.',
      name: 'Update Your Locations',
      roleRequired: 'Staff'
    }],
    Content: [{
      action: () => {
        history.push(`${match.path}/events/new`)
      },
      description: 'Add your first Event to OuterSpatial to get it in front of your Visitors.',
      name: 'Create Your First Event',
      roleRequired: 'Staff'
    }, {
      action: () => {
        history.push(`${match.path}/articles/new`)
      },
      description: 'Write your first Article to highlight something that\'s interesting and/or important for your Visitors to read about.',
      name: 'Create Your First Article',
      roleRequired: 'Staff'
    }, {
      action: () => {
        history.push(`${match.path}/bulletin-board/new`)
      },
      description: 'Create your first Bulletin Board item to highlight one of your Organization\'s Articles, Events, or Outings in OuterSpatial Mobile.',
      name: 'Create Your First Bulletin Board Item',
      roleRequired: 'Staff'
    }],
    Promote: [{
      action: () => {
        history.push(`${match.path}/social`)
      },
      description: 'Let OuterSpatial users know about your launch by creating a "Hello World!" post.',
      name: 'Add a "Hello World!" Post',
      roleRequired: 'Staff'
    }, {
      description: 'Review the Promotion Package and use it to develop a strategy for promotion of your Organization\'s launch in OuterSpatial.',
      href: 'https://organizations.outerspatial.help/article/59-promotion-package',
      name: 'Review the OuterSpatial Promotion Package',
      roleRequired: 'Staff',
      target: '_blank'
    }, {
      action: () => {
        history.push(`${match.path}/organization/campaigns/new`)
      },
      description: 'Create a Campaign to generate a deep link and QR code to use in your first marketing campaign.',
      name: 'Create Your First Campaign',
      roleRequired: 'Staff'
    /*
    }, {
      category: 'Promote',
      description: '',
      name: 'Announce Your Launch on Social Media',
      roleRequired: 'Staff'
    */
    }],
    Analyze: [{
      action: () => {
        history.push(`${match.path}/organization/analytics`)
      },
      description: 'Monitor and review the Visitor Analytics associated with your Organization content to identify opportunities to improve your content.',
      name: 'Monitor Visitor Analytics to Prioritize Further Content Work',
      roleRequired: 'Staff'
    }],
    'Geospatial Data': [{
      description: 'Review the information and specifications laid out in the Data Guidebook before formatting your geospatial data for import into OuterSpatial.',
      href: 'https://cdn.outerspatial.com/docs/outerspatial-data-guidebook.pdf',
      name: 'Review the OuterSpatial Data Guidebook',
      optional: true,
      roleRequired: 'Admin',
      target: '_blank',
      shouldShow: () => {
        return role === 'admin'
      }
    },
    {
      action: () => {
        if (window.Beacon) {
          window.Beacon('article', '69', { type: 'sidebar' })
        } else {
          Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/69-uploading-data-to-the-outerspatial-team' }).click()
        }
      },
      description: 'Once your Organization\'s geospatial data is formatted according to the Data Guidebook, upload your dataset(s) to the OuterSpatial customer success team.',
      name: 'Upload Your Data to the OuterSpatial Team',
      optional: true,
      roleRequired: 'Admin',
      shouldShow: () => {
        return role === 'admin'
      }
    }]
  }

  return (
    <div className='checklist'>
      <Panel>
        <Panel.Heading>
          <Panel.Title>
            To Do
            <p>A checklist of recommendations on what to work on next in OuterSpatial Manager.</p>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <PanelGroup
            accordion
            defaultActiveKey='0'
            id='dashboard-checklist-panelgroup'
          >
            {Object.keys(toDoTasks).map((category, categoryIndex) => {
              let availableTaskCount = 0

              toDoTasks[category].map((task) => {
                if (task.shouldShow) {
                  if (task.shouldShow()) {
                    availableTaskCount++
                  }
                } else {
                  availableTaskCount++
                }
              })

              if (availableTaskCount) {
                return (
                  <Panel
                    eventKey={`${categoryIndex}`}
                    key={categoryIndex}
                  >
                    <Panel.Heading>
                      <Panel.Title toggle>
                        <span>{category}</span>
                        <span className='pull-right'>
                          {(availableTaskCount === 1) &&
                            <span>1 To Do</span>}
                          {(availableTaskCount > 1) &&
                            <span>{availableTaskCount} To Dos</span>}
                          &nbsp;
                          <Glyphicon
                            className='rotate'
                            glyph='chevron-down'
                          />
                        </span>
                      </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                      <Table>
                        <tbody>
                          {toDoTasks[category].map((task, taskIndex) => {
                            let shouldShow = true

                            if (task.shouldShow) {
                              shouldShow = task.shouldShow()
                            }

                            if (shouldShow) {
                              const handleClick = () => {
                                task.action()
                              }
                              return (
                                <tr key={taskIndex}>
                                  <td>
                                    <div>
                                      {(task.action && task.href) &&
                                        <a href={task.href} onClick={handleClick}>{task.name}</a>}
                                      {(task.action && !task.href) &&
                                        <button className='btn btn-link' onClick={handleClick}>{task.name}</button>}
                                      {(!task.action && task.href) &&
                                        <span>
                                          <a href={task.href} target={task.target} rel='noopener noreferrer'>{task.name}</a>
                                        </span>}
                                      {(!task.action && !task.href) &&
                                        <span>{task.name}</span>}
                                    </div>
                                    <div>
                                      <p>{task.description}</p>
                                    </div>
                                  </td>
                                  <td>
                                    {(task.roleRequired === 'Admin') &&
                                      <Label bsStyle='danger'>ADMIN-ONLY</Label>}
                                    {(task.roleRequired === 'Admin' && task.optional) &&
                                      <span>&nbsp;</span>}
                                    {(task.optional) &&
                                      <Label bsStyle='info'>OPTIONAL</Label>}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </Table>
                    </Panel.Body>
                  </Panel>
                )
              }
            })}
          </PanelGroup>
        </Panel.Body>
      </Panel>
    </div>
  )
}

ToDo.propTypes = {
  currentUser: PropTypes.object,
  handleStartWalkthroughClick: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  organization: PropTypes.object
}

export default ToDo
