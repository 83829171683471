const post = {
  post: {
    model: {
      post_text: '',
      feature_id: null,
      feature_type: null,
      parent_id: null,
      geojson: null,
      organization_id: null,
      publish_start_date: null,
      publish_end_date: null,
      is_alert: false,
      alert_color: null,
      image_attachments: [],
      image_attachments_attributes: []
    },
    fetched: false,
    fetching: false,
    error: null
  },
  posts: {
    collection: [],
    paging: {
      total_pages: 0,
      current_page: 1
    },
    query: {
      page: 1,
      before: null,
      after: null,
      is_alert: null, // boolean
      alert_color: '',
      feature_type: null,
      feature_id: null,
      nested: true,
      near_lat: '', // filter results to those near the latitude
      near_lng: '', // filter results to those near the longitude
      distance: '', // a distance in miles to near_lat and near_lng to filter results by
      distance_meters: '', // a distance in meters to near_lat and near_lng to filter results by
      bbox: '' // a bounding box in the format sw_lng,sw_lat,ne_lng,ne_lat to filter results by
    },
    fetched: false,
    fetching: false,
    error: null
  },
  likes: {
    hash: {}
  },
  images: {
    hash: {}
  }
}

export const types = [
  { label: 'All Types', value: '' },
  { label: 'Posts', value: 'posts' },
  { label: 'Alerts', value: 'alerts' }
]

export const times = [
  { label: 'All Time', value: '' },
  { label: 'Last 24 hours', value: '1' },
  { label: 'Last 3 days', value: '3' },
  { label: 'Last 7 days', value: '7' },
  { label: 'Last 30 days', value: '30' },
  { label: 'Last 90 days', value: '90' },
  { label: 'Last 180 days', value: '180' },
  { label: 'Last 365 days', value: '365' }
]

export const colors = [
  { label: 'None', value: '' },
  { label: 'Green', value: 'green' },
  { label: 'Yellow', value: 'yellow' },
  { label: 'Red', value: 'red' },
  { label: 'Blue', value: 'blue' }
]

export default post
