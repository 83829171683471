import React from 'react'
import MediaGallery from '../../components/media'
import './media.css'

const Media = () => {
  return (
    <div className='mediaView'>
      <MediaGallery
        allowEdit
        allowSelection
        allowUploads
        scope={null}
      />
    </div>
  )
}

export default Media
