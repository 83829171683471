import PropTypes from 'prop-types'
import React from 'react'
import PostsList from '../../../../components/posts/posts/PostsList'
import './featurePosts.css'

const FeaturePosts = (props) => {
  const { feature } = props

  return (
    <div className='featurePosts'>
      <PostsList
        feature={feature}
      />
    </div>
  )
}

FeaturePosts.propTypes = {
  feature: PropTypes.object
}

export default FeaturePosts
