import { gql } from '@apollo/client'

export const Fragments = {
  PointOfInterestBasicInfo: gql`
    fragment PointOfInterestBasicInfo on points_of_interest {
      description
      id
      name
      parent_area: area {
        id
        name
      }
      parent_poi_id: ancestry
      point_of_interest_type_id
      point_type
    }
  `
}

export const Queries = {
  GetArea: gql`
    query GetArea($areaId: Int!) {
      areas_by_pk(id: $areaId) {
        accessibility_description
        address
        area_destinations {
          id
          point_of_interest {
            id
            name
          }
        }
        boundary_simplified {
          geometry
        }
        centroid {
          geojson: geometry
        }
        content_blocks: area_content_blocks {
          id
          content_block {
            body
            title
            position
          }
        }
        created_at
        description
        extent {
          geojson: geometry
        }
        id
        image_attachments {
          id
          image {
            id
            uploaded_file
          }
          position
        }
        name
        parent_id
        phone_number
        stewardships {
          id
          organization_id
          role
          stewardable_id
          organization {
            name
          }
        }
        tags {
          key
          value
          tag_descriptor {            
            category {
              id
              name
            }
            key
            id
            name
          }
        }
        uid
        updated_at
        visibility
        website
      }
    }
  `,
  GetTrail: gql`
    query GetTrail($trailId: Int!) {
      trails_by_pk(id: $trailId) {
        accessibility_description
        content_blocks {
          id
          content_block {
            body
            title
            position
          }
        }
        created_at
        description
        extent
        geometry: alignment {
          geometry
        }
        id
        image_attachments {
          id
          image {
            id
            uploaded_file
          }
          position
        }
        name
        parent_area:area {
          id
          name
        }
        phone_number
        stewardships {
          id
          organization_id
          role
          stewardable_id
          organization {
            name
          }
        }
        tags {
          key
          value
          feature_type
          feature_id
          tag_descriptor {            
            category {
              id
              name
            }
            key
            id
            name
          }
        }
        trail_only_tags {
          key
          value
          feature_type
          feature_id
          tag_descriptor {            
            category {
              id
              name
            }
            key
            id
            name
          }
        }
        trail_sections {
          id
          trail_segment {
            uid
          }
        }
        uid
        updated_at
        website
        visibility
      }
    }
  `,
  GetPointOfInterest: gql`
    query GetPointOfInterest($poiId: Int!) {
      points_of_interest_by_pk(id: $poiId) {
        ...PointOfInterestBasicInfo
        content_blocks {
          id
          content_block {
            body
            title
            position
          }
        }
        created_at
        image_attachments {
          id
          image {
            id
            uploaded_file
          }
          position
        }
        location {
          geometry
        }
        partner_points_of_interest {
          data
          partner
        }
        stewardships {
          id
          organization_id
          role
          stewardable_id
          organization {
            name
          }
        }
        tags {
          key
          value
          tag_descriptor {            
            category {
              id
              name
            }
            key
            id
            name
          }
        }
        uid
        updated_at
        visibility
        what3words
      }
    }
    ${Fragments.PointOfInterestBasicInfo}
  `,
  GetMaxPositionOfContentBlocks: gql`
    query GetMaxPositionOfContentBlocks($featureType: String!, $featureId: Int!) {
      content_blocks_aggregate(where: {
        feature_id: {_eq: $featureId},
        feature_type: {_eq: $featureType}
      }) {
        aggregate {
          max {
            position
          }
        }
      }
    }  
  `,
  GetPointOfInterestTypes: gql`
    query GetPointOfInterestTypes {
      point_of_interest_types {
        id
        name
      }
    }
  `,
  GetTrailTags: gql`
    query GetTrailTags($trailId: Int!) {
      trails(where: {id: {_eq: $trailId}}) {
        id
        all_tags_accessibility: tags(
          where: {
            tag_descriptor: { category: { name: { _eq: "Accessibility" } } }
          }
        ) {
          key
        }
        all_tags_activities: tags(
          where: {
            tag_descriptor: { category: { name: { _eq: "Activities" } } }
          }
        ) {
          key
        }
        all_tags_allowed_access: tags(
          where: {
            tag_descriptor: { category: { name: { _eq: "Allowed Access" } } }
          }
        ) {
          key
        }
        all_tags_rules_and_regulation: tags(
          where: {
            tag_descriptor: {
              category: { name: { _eq: "Rules & Regulations" } }
            }
          }
        ) {
          key
        }
        all_tags_seasonality: tags(
          where: {
            tag_descriptor: { category: { name: { _eq: "Seasonality" } } }
          }
        ) {
          key
        }
        every_segment_tags_accessibility: tags_every_segment(
          where: {
            tag_descriptor: { category: { name: { _eq: "Accessibility" } } }
          }
        ) {
          key
        }
        every_segment_tags_activities: tags_every_segment(
          where: {
            tag_descriptor: { category: { name: { _eq: "Activities" } } }
          }
        ) {
          key
        }
        every_segment_tags_allowed_access: tags_every_segment(
          where: {
            tag_descriptor: { category: { name: { _eq: "Allowed Access" } } }
          }
        ) {
          key
        }
        every_segment_tags_rules_and_regulation: tags_every_segment(
          where: {
            tag_descriptor: {
              category: { name: { _eq: "Rules & Regulations" } }
            }
          }
        ) {
          key
        }
        every_segment_tags_seasonality: tags_every_segment(
          where: {
            tag_descriptor: { category: { name: { _eq: "Seasonality" } } }
          }
        ) {
          key
        }
        some_segments_tags_accessibility: tags_not_every_segment(
          where: {
            tag_descriptor: { category: { name: { _eq: "Accessibility" } } }
          }
        ) {
          key
        }
        some_segments_tags_activities: tags_not_every_segment(
          where: {
            tag_descriptor: { category: { name: { _eq: "Activities" } } }
          }
        ) {
          key
        }
        some_segments_tags_allowed_access: tags_not_every_segment(
          where: {
            tag_descriptor: { category: { name: { _eq: "Allowed Access" } } }
          }
        ) {
          key
        }
        some_segments_tags_rules_and_regulation: tags_not_every_segment(
          where: {
            tag_descriptor: {
              category: { name: { _eq: "Rules & Regulations" } }
            }
          }
        ) {
          key
        }
        some_segments_tags_seasonality: tags_not_every_segment(
          where: {
            tag_descriptor: { category: { name: { _eq: "Seasonality" } } }
          }
        ) {
          key
        }
      }
    }
  `
}

export const Mutations = {
  UpdateArea: gql`
    mutation UpdateArea(
      $accessibility_description: String,
      $address: String,
      $areaId: Int!,
      $description: String,
      $keyValuesOfTagsToDelete: [String!],
      $name: String,
      $newAreaDestinations: [area_destinations_insert_input!]!,
      $newStewardships: [stewardships_insert_input!]!,
      $newTags: [tags_insert_input!]!,
      $phone_number: String,
      $removedAreaDestinationIds: [Int!]!,
      $removedStewardshipIds: [Int!]!,
      $website: String
    ) {
      update_areas_by_pk(
        pk_columns: {id: $areaId},
        _set: {
          accessibility_description: $accessibility_description,
          address: $address,
          description: $description,
          name: $name,
          phone_number: $phone_number,
          updated_at: "now()",
          website: $website
        }
      ) {
        accessibility_description
        address
        description
        id
        name
        phone_number
        website
      }
      insert_tags(objects: $newTags) {
        affected_rows
        returning {
          id
          feature_type
          feature_id
          key
          value
        }
      }
      delete_tags(
        where: {
          feature_id: {_eq: $areaId},
          feature_type: {_eq: "Area"},
          key: {_in: $keyValuesOfTagsToDelete}
        }
      ) {
        affected_rows
        returning {
          key
        }
      }
      insert_area_destinations(objects: $newAreaDestinations) {
        affected_rows
        returning {
          area_id
          id
          point_of_interest {
            id
            name
          }
          point_of_interest_id
        }
      }
      delete_area_destinations(where: {id: {_in: $removedAreaDestinationIds}}) {
        affected_rows
        returning {
          id
        }
      }
      insert_stewardships(objects: $newStewardships) {
        affected_rows
        returning {
          id
          role
          organization_id
          stewardable_id
        }
      }
      delete_stewardships(where: {id: {_in: $removedStewardshipIds}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  UpdateTrail: gql`
    mutation UpdateTrail(
      $trailId: Int!,
      $name: String,
      $description: String,
      $parentAreaId: Int,
      $accessibility_description: String,
      $website: String,
      $phone_number: String,
      $newTags: [tags_insert_input!]!,
      $keyValuesOfTagsToDelete: [String!],
      $newStewardships: [stewardships_insert_input!]!,
      $removedStewardshipIds: [Int!]!
    ) {
      update_trails_by_pk(
        pk_columns: {id: $trailId},
        _set: {
          name: $name,
          description: $description,
          area_id: $parentAreaId
          accessibility_description: $accessibility_description,
          website: $website,
          phone_number: $phone_number,
          updated_at: "now()"
        }
      ) {
        id
        name
        description
        parent_area: area_id
        accessibility_description
        website
        phone_number
      }
      insert_tags(objects: $newTags) {
        affected_rows
        returning {
          id
          feature_type
          feature_id
          key
          value
        }
      }
      delete_tags(
        where: {
          feature_id: {_eq: $trailId},
          feature_type: {_eq: "Trail"},
          key: {_in: $keyValuesOfTagsToDelete}
        }
      ) {
        affected_rows
        returning {
          key
        }
      }
      insert_stewardships(objects: $newStewardships) {
        affected_rows
        returning {
          id
          role
          organization_id
          stewardable_id
        }
      }
      delete_stewardships(where: {id: {_in: $removedStewardshipIds}}) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  UpdatePointOfInterest: gql`
    mutation UpdatePointOfInterest(
      $pointOfInterestId: Int!,
      $name: String,
      $description: String,
      $point_type: String,
      $point_of_interest_type_id: Int,
      $parent_area_id: Int,
      $parent_poi_id: String,
      $newTags: [tags_insert_input!]!,
      $keyValuesOfTagsToDelete: [String!],
      $newStewardships: [stewardships_insert_input!]!,
      $removedStewardshipIds: [Int!]!
    ) {
      update_points_of_interest_by_pk(
        pk_columns: {id: $pointOfInterestId},
        _set: {
          name: $name,
          description: $description,
          point_type: $point_type,
          point_of_interest_type_id: $point_of_interest_type_id,
          area_id: $parent_area_id,
          ancestry: $parent_poi_id,
          updated_at: "now()"
        }
      ) {
        ...PointOfInterestBasicInfo
      }
      insert_tags(objects: $newTags) {
        affected_rows
        returning {
          id
          feature_type
          feature_id
          key
          value
        }
      }
      delete_tags(
        where: {
          feature_id: {_eq: $pointOfInterestId},
          feature_type: {_eq: "PointOfInterest"},
          key: {_in: $keyValuesOfTagsToDelete}
        }
      ) {
        affected_rows
        returning {
          key
        }
      }
      insert_stewardships(objects: $newStewardships) {
        affected_rows
        returning {
          id
          role
          organization_id
          stewardable_id
        }
      }
      delete_stewardships(where: {id: {_in: $removedStewardshipIds}}) {
        affected_rows
        returning {
          id
        }
      }
    }
    ${Fragments.PointOfInterestBasicInfo}
  `,
  UpdateFeatureTags: gql`
    mutation UpdateFeatureTags(
      $featureId: Int!,
      $featureType: String,
      $newTags: [tags_insert_input!]!,
      $keyValuesOfTagsToDelete: [String!]
    ) {
      insert_tags(objects: $newTags) {
        affected_rows
        returning {
          id
          feature_type
          feature_id
          key
          value
        }
      }
      delete_tags(
        where: {
          feature_id: {_eq: $featureId},
          feature_type: {_eq: $featureType},
          key: {_in: $keyValuesOfTagsToDelete}
        }
      ) {
        affected_rows
        returning {
          key
        }
      }
    }
  `,
  InsertContentBlock: gql`
    mutation InsertContentBlock (
      $featureId: Int!,
      $featureType: String!,
      $position: Int!,
      $title: String!,
      $body: String!
    ) {
      insert_content_blocks_one(object: {
        title: $title,
        body: $body,
        position: $position,
        feature_type: $featureType,
        feature_id: $featureId
      }) {
        id
        body
        title
        position
      }
    }
  `,
  UpdateContentBlock: gql`
    mutation UpdateContentBlock(
      $id: Int!,
      $title: String!,
      $body: String!,
      $position: Int
    ) {
      update_content_blocks_by_pk(
        pk_columns: {id: $id},
        _set: {
          body: $body,
          title: $title,
          position: $position
        }
      ) {
        id
        body
        title
        position
      }
    }
  `,
  DeleteContentBlock: gql`
    mutation DeleteContentBlock($id: Int!) {
      delete_content_blocks_by_pk(id: $id) {
        id
      }
    }
  `
}
