import PropTypes from 'prop-types'
import { React, useEffect } from 'react'
import { Route, withRouter } from 'react-router-dom'

import OrganizationBillingPlan from './OrganizationBillingPlan'

import './organizationBilling.css'

const OrganizationBilling = (props) => {
  const { isOrganizationAdmin, isSuperAdmin, match } = props
  const tabsProps = {
    isOrganizationAdmin: isOrganizationAdmin,
    isSuperAdmin: isSuperAdmin
  }

  useEffect(() => {
    document.title = `Billing | Organization | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  useEffect(() => {
    window.Chargebee.registerAgain()
  }, []) // No dependency array to run only on mount

  return (
    <div className='organizationBilling-content'>
      <div>
        <Route
          exact
          path={`${match.url}`}
          render={() => <OrganizationBillingPlan {...tabsProps} />}
        />
      </div>
    </div>
  )
}

OrganizationBilling.propTypes = {
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  match: PropTypes.object
}

export default withRouter(OrganizationBilling)
