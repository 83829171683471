import { gql } from '@apollo/client'

// export const Fragments = {}

export const Queries = {
  GetTagData: gql`
    query GetTagData($tag_category: String!, $feature_type: String!, $excluded_tags: [String!] = []) {
      tag_categories(where: {name: {_eq: $tag_category}}) {
        id
        description
        name
        tag_descriptors(where: {_and: {
          feature_type: {_eq: $feature_type},          
          key: {_nin: $excluded_tags}
        }}) {
          id
          name
          key          
        }
      }
    }  
  `
}

// export const Mutations = {}
