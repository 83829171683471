import { Redirect, Route, Switch } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import React, { useState } from 'react'

import { buildProfileImageUrl } from 'services/Images'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import debugMessage from 'services/Debug'
import OrganizationRoutes from 'views/organization/OrganizationRoutes'
import Queries from './AppQueries'
import Welcome from 'views/signup/Welcome'
import { PostRedirect } from 'views/social/PostRedirect'

const identifyMe = (newMe) => {
  if (['production', 'staging'].includes(process.env.REACT_APP_STAGE_NAME)) {
    const email = newMe.email
    const name = newMe.profile.name
    const beaconObject = {
      email: email,
      name: name,
      'user-id': newMe.id
    }
    const primaryMemberships = newMe.memberships.filter(membership => membership.primary === true)

    if (primaryMemberships && primaryMemberships[0] && primaryMemberships[0].organization && primaryMemberships[0].organization.name) {
      const primaryOrg = primaryMemberships[0].organization
      const groupId = `organization-${primaryOrg.id}`

      beaconObject.company = primaryOrg.name
      // Call identify here before group call below.
      window.analytics?.identify && window.analytics.identify(newMe.id, {
        company: {
          id: groupId
        },
        email: email,
        name: name
      })
      window.analytics?.group && window.analytics.group(groupId, {
        group_type: 'Organization',
        license_tier: primaryOrg.license_tier,
        membership_limit: primaryOrg.membership_limit,
        name: primaryOrg.name,
        organization_type: primaryOrg.organization_type
      })
    } else {
      window.analytics?.identify && window.analytics.identify(newMe.id, {
        email: email,
        name: name
      })
    }

    if (newMe.profile.id && newMe.profile.photo_file) {
      beaconObject.avatar = buildProfileImageUrl(newMe.profile.id, newMe.profile.photo_file)
    }

    window.Beacon && window.Beacon('identify', beaconObject)
    Sentry.setUser({
      email: newMe.email,
      id: newMe.id
    })
  }
}

function AppRoutes () {
  const { logout } = useAuth0()
  const [currentUser, setCurrentUser] = useState()
  const { data, loading } = useQuery(Queries.GetTokenUser)
  const [primaryOrganizationId, setPrimaryOrganizationId] = useState()

  if (data && !(currentUser || primaryOrganizationId)) {
    const newUser = { ...data.token_user[0]?.user, email: data.token_user[0]?.email }

    if (newUser) {
      const newMe = { ...newUser }
      newMe.email = data.token_user[0].email
      identifyMe(newMe)
      const primaryOrgs = newMe.memberships?.filter(m => m.primary)
      let primaryOrgId = null

      if (primaryOrgs && primaryOrgs.length > 0) {
        // Select the first primary organization membership
        primaryOrgId = primaryOrgs[0].organization.id
      } else if (newMe.memberships?.length > 0) {
        // Select the first organization membership
        primaryOrgId = newMe.memberships[0].organization.id
      }

      if (primaryOrgId === null) {
        return (<Welcome currentUser={newMe} />)
      } else {
        setCurrentUser(newMe)
        setPrimaryOrganizationId(primaryOrgId)
      }
    } else {
      debugMessage('No user')
      logout({ returnTo: `${window.location.origin}/logout?error=no_user` })
      window.Beacon && window.Beacon('logout')
      Sentry.setUser(null)
    }
  }

  if (loading) {
    return <></>
  }

  return (
    <div>
      <div className='app-wrap'>
        <Toaster />
        <CurrentUserContext.Provider value={currentUser}>
          <Switch>
            <Redirect
              from='/:url*(/+)'
              to={window.location.pathname.slice(0, -1)}
            />
            <Route
              exact
              path='/env'
            >
              <pre>{JSON.stringify(process.env, null, 4)}</pre>
            </Route>
            <Route
              exact
              path='/login'
              render={() => <Redirect to={`/${primaryOrganizationId}`} />}
            />
            <Route path='/posts/:postId' component={PostRedirect} />
            <Route
              path='/:organizationId'
              render={() => {
                return (
                  <OrganizationRoutes
                    currentUser={currentUser}
                    logout={logout}
                    primaryOrganizationid={primaryOrganizationId}
                    setCurrentUser={setCurrentUser}
                  />
                )
              }}
            />
          </Switch>
        </CurrentUserContext.Provider>
      </div>
    </div>
  )
}

export default AppRoutes
